import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-19
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-15.
 * <p> pretty 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class IblankDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProducts: Array<{}> = jsonObj.getDataByName('product');

    const orderedProducts = document.querySelectorAll('div.table-order-prd > table > tbody tr');

    let totalQty = 0;

    for (let i = 0; i < orderedProducts.length; i++) {
      const qty = NumberUtil.parseInteger(orderedProducts[i].querySelector('td:nth-child(4)')!.textContent!.trim().replace(/\D/g, ''));
      const price = NumberUtil.parseInteger(orderedProducts[i].querySelector('td:nth-child(6)')!.textContent!.trim().replace(/\D/g, ''));

      parsedProducts[i]['qty'] = qty;
      parsedProducts[i]['price'] = price;

      totalQty += qty;
    }

    jsonObj.replaceData('products', parsedProducts);
    jsonObj.replaceData('totalQty', totalQty);

    return jsonObj;
  }
}
