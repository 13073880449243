/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-14
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-07-14.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class EyecatcherDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
   protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('#cartWrap .table-cart tr td:nth-of-type(5) .price span')[i] as HTMLSpanElement).textContent!.replace(/\D/g, '').trim();
      const rawQty = (document.querySelectorAll('#cartWrap .table-cart [name=orgamount]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll([
          'div.table-cart input[name="branduid"]',
          'div.table-wrap input[name="branduid"]',
          'div#cart div.base-table table input[name="branduid"]',
          'div#cartWrap table input[name="branduid"]',
          'div.orderListArea table input[name="branduid"]',
          'div.cart-list input[name="branduid"]'
        ])[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div#cartWrap table a.name')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }
}
