/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-19
 */
import {Cafe24SmartDataValidator} from "../Cafe24SmartDataValidator";


/**
 * create on 2023-06-19
 * <p> "tmdqja1209" 광고주 데이터 검증 클래스 </p>
 * <p> {@link Cafe24SmartDataValidator} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Tmdqja1209DataValidator extends Cafe24SmartDataValidator {
    constructor() {
        super();
    }

    protected fromEditorPage(): boolean {
        /* 서브 도메인이 "skin-"으로 시작하면 에디터 페이지 */
        const fromEditorPage = window.location.hostname.indexOf('skin-') === 1;
        /*
         * 카페24 관리자 페이지의 미리보기 팝업이나 페이지 (상품코드가 "-"로 시작)
         * url 샘플 : http://dabainsang.cafe24.com/disp/admin/shop1/product/ProductPopupPreview
         */
        const containsAdmin = (location.hostname.indexOf('.cafe24.com') > -1 && location.pathname.indexOf('/admin') > -1);
        const startsWithShop = location.hostname.indexOf('shop1.') === 0;
        const fromPreview = containsAdmin || startsWithShop;
    
        return fromEditorPage || fromPreview;
      }
}
