/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-29.
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";

/**
 * create on 2023-03-29.
 * <p> {@link GodomallSelfDataParser } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Jakomo01DataParser extends GodomallSelfDataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getWebShopCollectData();

    jsonObj.data['productName'] = (document.querySelector('meta[property="og:title"]') as HTMLMetaElement).content;

    return jsonObj;
  }
}
