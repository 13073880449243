/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-02-21
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-02-21
 * <p> https://goods-namu.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class LaoganicDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
      jsonObj['totalPrice'] = document.querySelector('span.total')!.textContent!.replace(/\D/g,'');
    }
      return jsonObj;
  }

}
