/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-06-09
 */
import {FirstmallDataParser} from "../FirstmallDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import {NumberUtil} from "../../../lib/common/NumberUtil";

export class DafarmDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();

    if(document.querySelector('.org_price s span.num')){
      jsonObj.data['price'] = document.querySelector('.org_price s span.num')!.textContent!.replace(/\D/g, '');
    }

    return jsonObj;
  }
  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelector('.img_area a') as HTMLAnchorElement;
        const params = productInfo.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        let productQty = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[1].textContent!);
        let productPrice = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[2].textContent!.replace(/\D/g, ''));

        const product = {
          'productCode': paramObj['no'],
          'productName': rawProductList[i].querySelector('span.goods_name')!.textContent!.trim(),
          'qty': productQty,
          'price': productPrice / productQty
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(document.querySelector('#totalPrice span.num')!.textContent!),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelector('.img_area a') as HTMLAnchorElement;
        const params = productInfo.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        let productQty = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[2].textContent!);
        let productPrice = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[3].textContent!.replace(/\D/g, ''));

        const product = {
          'productCode': paramObj['no'],
          'productName': rawProductList[i].querySelector('.goods_name a')!.textContent!.trim(),
          'qty': productQty,
          'price': productPrice / productQty
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(document.querySelector('.price .settle_price')!.textContent!),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}
