/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-01-04
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2021-01-04
 * <p> Hiemscor - http://www.hiemscor.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class HiemscorDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
      return jsonObj;
    } else {
      if(this.ENP_OBJECT_NAME['imgUrl']) {
        jsonObj.replaceData('imageUrl',StringUtil.getProtocolDomainUrl() + this.ENP_OBJECT_NAME['imgUrl']);
      }
      return jsonObj;
    }
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    if(this.isMobile(this.commandOptions)) {
      return jsonObj;
    } else {
      if(this.ENP_OBJECT_NAME['imgUrl']) {
        jsonObj['product'][0]['imageUrl'] = StringUtil.getProtocolDomainUrl() + this.ENP_OBJECT_NAME['imgUrl'];
      }
      return jsonObj;
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div#cartWrap table td:nth-child(4) a')[i].textContent!.trim();
    }

    return jsonObj;
  }
}