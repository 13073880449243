/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-16
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-12-16
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Famenity1DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][name^="cartSno"]');
    /* 배송비 포함된 총 주문금액 가져오지 못할 경우 0원 */
    const parsedTotalPrice: number = this.getParsedTotalPrice();
    const mobile: boolean = this.isMobile(this.commandOptions);

    let totalPrice = 0;
    let totalQty = 0;


    for (let i = 0; i < inputList.length; i++) {
      const qty = mobile ?
        NumberUtil.parseInteger(document.querySelectorAll('.my_goods.v3 .info .itembody dd')[i].textContent!.replace(/\D/g, ''))
        : NumberUtil.parseInteger(document.querySelectorAll('.order_goods_num strong')[i].textContent!.replace(/\D/g, ''))
      const rawPrice = NumberUtil.parseNumber(inputList[i].dataset['price'] as string);
      const price = rawPrice / qty;

      parsedProducts.push({
        'productCode': inputList[i].dataset['goodsNo'],
        'productName': mobile ?
          (document.querySelectorAll('.my_goods.v3 .info .itemhead img')[i] as HTMLImageElement)['alt']
          : (document.querySelectorAll('.order_table_type tbody tr td:nth-of-type(2) .pick_add_img a img')[i] as HTMLImageElement)['alt'],
        'qty': qty,
        'price': price
      });

      totalPrice += rawPrice;
      totalQty += qty;
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      /* 배송비 포함된 금액을 가져오지 못하면 상품 가격들의 합으로 초기화 */
      'totalPrice': parsedTotalPrice > 0 ? parsedTotalPrice : totalPrice,
      'totalQty': totalQty
    };
  }
}