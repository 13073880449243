import { InvalidData } from "../../../../lib/ajax/InvalidData";
import { KeywordPair } from "../../dataType/KeywordPair";
import { InternalKeywordSelfHosted } from "../InternalKeywordSelfHosted";

export class StockcomInternalParser extends InternalKeywordSelfHosted {
  extract(): KeywordPair | InvalidData {
    try {
      let referrer = document.referrer.split('?')[1];
      let params = referrer.split('&');
      let resultValue = '';
      for (let i = 0; i < params.length; i++) {
        let split = params[i].split('=');
        let key = split[0];
        let value = split[1];
        if (key === 'sword') {
          resultValue = value;
          break;
        }
      }
      return {
        keyword: super.removeForbiddenChars(window['unescape'](resultValue)),
        url: document.referrer
      }
    } catch {
      return new InvalidData();
    }
  }
}