/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {ValidationRuleImpl} from '../ValidationRuleImpl';
import {NumberUtil} from '../../../lib/common/NumberUtil';

/**
 * create on 2019-09-30.
 * <p> 가격 포맷 검증 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PriceValidRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  /** 
   * 22.03.31
   * number type으로 넘어온 price 값이 0인 경우 1로 치환하던 로직 제거
   * 숫자로 변환했을 시 NaN이라면 1로 치환하던 로직 0으로 수정
   * */
  protected applyRule(property: any): any {
    if (!property) return 0;
    const validatedPrice = NumberUtil.parseRationalNumber(property);
    if (NumberUtil.isNaN(validatedPrice)) return 0;
    return validatedPrice;
  }
}
