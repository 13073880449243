/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import { JsonObject } from '../../../lib/json/JsonObject';
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';
import HTML = Mocha.reporters.HTML;


/**
 * create on 2020-12-04.
 * <p> super1 데이터 파서 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Super1DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProducts: Array<{}> = [];
    const orderedProducts = document.querySelectorAll('div.order-list > table > tbody tr.nobd');

    let totalQty = 0;

    for (let i = 0; i < orderedProducts.length; i++) {
      const productAnchorElem = document.querySelectorAll('div.order-list > table > tbody tr.nobd td:nth-child(2) a')[i] as HTMLAnchorElement;
      const qty = NumberUtil.parseNumber(document.querySelectorAll('div.order-list > table > tbody tr.nobd td:nth-child(3)')[i]!.textContent!.trim());

      parsedProducts.push({
        'productName': productAnchorElem!.textContent!.trim(),
        'productCode': new QueryStringer(productAnchorElem.search.substr(1)).getParam('branduid'),
        'price': NumberUtil.parseNumber(document.querySelectorAll('div.order-list > table > tbody tr.nobd td:nth-child(4)')[i]!.textContent!.trim()),
        'qty': qty
      });

      totalQty += qty;
    }

    jsonObj.replaceData('product', parsedProducts);
    jsonObj.replaceData('productCnt', parsedProducts.length);
    jsonObj.replaceData('totalQty', totalQty);

    return jsonObj;
  }
}
