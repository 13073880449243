/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Punycode} from '../../lib/codec/Punycode';
import {Utf8} from '../../lib/codec/Utf8';
import {Base64} from '../../lib/codec/Base64';
import {Config} from './config/Config';
import {EventType} from '../../types/GlobalEnums';
import {Transmitter} from './core/Transmitter';
import {DataParser} from '../../dataParser/DataParser';
import {CategoryParser} from "../../category/CategoryParser";
import {CategoryParserFactory} from "../../category/CategoryParserFactory";
import {GeneralTree} from "../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../../category/Category";
import {CategoryStorage} from "../../category/CategoryStorage";
import {StringUtil} from "../../lib/common/StringUtil";
import {ClientInfo} from "../../lib/common/ClientInfo";
import {AdverConfigJsonMapper} from "../../adverConfigJsonMapper/AdverConfigJsonMapper";
import {KeywordFetcher} from "../keyword/KeywordFetcher";
import {AdBlockCtrl} from "../adblock/AdBlockCtrl";
import { ReferrerDomain } from '../referrer/ReferrerDomain';

/**
 * TODO 주석
 * create on 2019-08-02.
 * <p> 유입경로 추적 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommonTracer extends Transmitter {
  protected readonly _punycode: Punycode;
  protected readonly _utf8: Utf8;
  protected readonly _base64: Base64;

  constructor(eventType: EventType, config: Config, dataParser: DataParser) {
    super(eventType, config, dataParser);
    this._punycode = new Punycode();
    this._utf8 = new Utf8();
    this._base64 = new Base64();
  }

  protected preSend(): void {
    const adverDomain = StringUtil.getDomainFromUrl(window.location.href);

    this.addExtraData(
        {
          'adverId': this.config.adverId,
          'domain': adverDomain,
          'charSet': document.characterSet,
          'url': window.location.href,
          'referrer': document.referrer,
          'referrerDomain': StringUtil.getReferrerDomain(),
          'cookieEnabled': ClientInfo.getInstance().cookieEnabled ? ClientInfo.getInstance().cookieEnabled : true,
          'solutionType': AdverConfigJsonMapper.getInstance(this.config.adverId).getHostingType(),
          'siteCode': AdverConfigJsonMapper.getInstance(this.config.adverId).getSiteCode()
        },
        false
    );

    /* 카테고리 파싱 */
    this.parseCategory();
    /* referrer click domain 추가 */
    this.addSessionReferrer();
    this.addExtraData(new KeywordFetcher().getCommonKeyword(this.eventType));
    this.addScriptVer();
  }

  /**
   * 데이터 전송 후 콜백으로 소셜링크 서비스를 실행.
   */
  protected finish(): void {
    AdBlockCtrl.getInstance().setJsonObject(this.jsonObject);
    super.finish();
  }

  /**
   * 카테고리를 파싱하여 <code>localStorage</code>에 저장한다.
   */
  private parseCategory(): void {
    try {
      const categoryParser: CategoryParser = CategoryParserFactory.createCategoryParser(this.config.adverId, '');
      const categoryStorage: CategoryStorage = new CategoryStorage();
      categoryParser.parse((tree: GeneralTree<Category>) => {
        categoryStorage.store(tree);
      }, categoryStorage.hasExpired());
    } catch (e) {
      return;
    }
  }

  private addSessionReferrer(): void {
    const referrerDomain = new ReferrerDomain();
    const referrerKey = referrerDomain.getSessionKey();
    if (referrerKey) {
      this.addExtraData({ 'referrerClick': referrerKey }, false);
    }
  }

  private addScriptVer(): void {
    this.addExtraData({ 'ver': '02' }, false);
  }
}
