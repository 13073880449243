/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-28
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2022-12-28.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class NexysDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][id^="cartSno"]');
    const parsedTotalPrice: number = this.getParsedTotalPrice();

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < inputList.length; i++) {
      const qty = NumberUtil.parseInteger(
        this.isMobile(this.commandOptions) ?
        document.querySelectorAll('div.info div.itembody dd')[2*i].textContent!.replace(/\D/g,'') :
        document.querySelectorAll('td.ta-c.count.this-product input')[i]['value']);
      
      const rawPrice = NumberUtil.parseNumber(
            this.isMobile(this.commandOptions) ?
            document.querySelectorAll('div.info strong.prc')[i].textContent!.replace(/\D/g,'') :
            document.querySelectorAll('td.ta-c.this-product strong.price')[i].textContent!.replace(/\D/g,''));

      const price = rawPrice / qty;
      const productCode =
      this.isMobile(this.commandOptions) ?
      new QueryStringer((document.querySelectorAll('li div.info a')[i] as HTMLAnchorElement).search.substring(1)).getParam('goodsNo') :
      new QueryStringer((document.querySelectorAll('td.gi.this-product div a')[i] as HTMLAnchorElement).search.substring(1)).getParam('goodsNo');
      const productName = this.isMobile(this.commandOptions) ?
      document.querySelectorAll('div.itemhead div img')[i]['title'] : document.querySelectorAll('td.gi.this-product div a')[i].textContent;
      parsedProducts.push({
        'productCode': productCode,
        'productName': productName,
        'qty': qty,
        'price': price
      });

      totalPrice += rawPrice;
      totalQty += qty;
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': parsedTotalPrice > 0 ? parsedTotalPrice : totalPrice,
      'totalQty': totalQty
    };
  }
}
