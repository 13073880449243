/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-09
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-02-09
 * <p> solepkorea - solepkorea.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class SolepkoreaDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getParsedTotalPrice(): number {
    return this.isOrderPage()
        ? NumberUtil.parseRationalNumber(document.querySelector('.order_payment_sum')!.textContent!.trim())
        : super.getParsedTotalPrice();
  }
}