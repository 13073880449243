/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-10-26
 */

import { SelfHostedDataParser } from "../SelfHostedDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Value } from "../../../lib/value/Value";

/**
 * create on 2022-10-26
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class Blackyak0800DataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    const locationUrl = location.hostname + location.pathname;

    switch (locationUrl) {
      case 'www.byn.kr/blackyak':
        jsonData.addData('domain', 'byn1.kr');
        break;
      case 'www.byn.kr/blackyakkids':
        jsonData.addData('domain', 'byn2.kr');
        break;
      case 'www.byn.kr/nau':
        jsonData.addData('domain', 'byn3.kr');
        break;
      case 'www.byn.kr/healcreek':
        jsonData.addData('domain', 'byn4.kr');
        break;
    }

    return jsonData;
  }

  
  protected getShopCollectData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['collect'];
    let superData = super.getShopCollectData();
    superData.data['brand'] = targetObj['brand'];
    return superData;
  }

  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];

    for (let i = 0; i < parsedProducts.length; i++) {
      products.push({
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'brand': parsedProducts[i]['brand']
      });
    }

    const parsedData: {} = {
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': targetObj['totalPrice'],
      'totalQty': targetObj['totalQty']
    };

    return new JsonObject(parsedData);
  }

  protected getParsedPayConvData(): {} {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'brand': parsedProducts[i]['brand']
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseRationalNumber(this.onSale(products[i]['dcPrice']) ? products[i]['dcPrice'] : products[i]['price']);
      totalQty += NumberUtil.isNumber(products[i]['qty'])
          ? products[i]['qty'] : NumberUtil.parseInteger(products[i]['qty']);
    }

    return {
      'product': products,
      'adverId': this.adverId,
      'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
      'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
    };
  }
}
