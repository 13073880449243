/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-25
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-01-25.
 * <p> hucheum 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Chapter11DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getProductPrice(): { price: number; dcPrice: number; } {
    try {
      let priceObj = super.getProductPrice();
      priceObj['dcPrice'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content);
      return priceObj;
    } catch (e) {
      return super.getProductPrice();
    }
  }
}
