/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-16
 */
/**
 * create on 2020-04-16.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ItemNotFoundError extends Error {
  constructor(itemName: string, itemDescription?: string) {
    super();
    this.name = 'ItemNotFoundError';
    this.message = itemDescription
        ? `Could not found item named as "${itemName}" means ${itemDescription}`
        : `Could not found item named as "${itemName}"`;
  }
}
