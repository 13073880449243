/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-07-06
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { StringUtil } from "../../../lib/common/StringUtil";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**0
 * create on 2023-07-06
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export abstract class DunlopilloDataParser extends GodomallRentDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    const domain = StringUtil.getDomainFromUrl(location.href);
    if (domain === 'dunlopillo.kr') {
      jsonData.addData('domain', 'dunlopillokorea.com');
    }
    return jsonData;
  }
}
