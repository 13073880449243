/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">mgpark</a>
 * @since 2022-04-28.
 */
import { GodomallSelfDataParser } from "../GodomallSelfDataParser";

/**
 * create on 2022-04-28.
 * <p> {@link GodomallSelfDataParser } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class BycliveDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    try {
      if (this.isMobile(this.commandOptions)) {
        return (document.querySelector('.goods-contents-area .swiper-wrapper li.swiper-slide.swiper-slide-active img') as HTMLImageElement).src;
      }
      return super.getImageUrl();
    } catch {
      return super.getImageUrl();
    }
  }

  protected getProductName(): string {
    try {
      if (this.isMobile(this.commandOptions)) {
        if (document.querySelector('h2.goods-nm.txtcut')) {
          return document.querySelector('h2.goods-nm.txtcut')!.textContent!.trim();
        }
      }

      return super.getProductName();
    } catch {
      return super.getProductName();
    }
  }
}
