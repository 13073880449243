/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Config } from './Config';
import {ApiFactory} from "../../api/ApiFactory";
import {ApiName} from "../../../types/GlobalEnums";
import {Identification} from "../../ident/Identification";

/**
 * create on 2019-08-05.
 * <p> 공용 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommonTracerConfig extends Config {
  constructor(adverId: string, options?: {}) {
    super(adverId, options);
    this._baseUrl = this.domain+'/aggregate/mssg/common';
    this._url = this.protocol + this.baseUrl;
    this._callback = (
      xhr: XMLHttpRequest,
      response: any,
      hasTransmitted: boolean
    ) => {
      if (hasTransmitted) {
        /* 카카오 RTB */
        ApiFactory.createApi(ApiName.I_ORB, this.adverId).call();
      }

      xhr.onload = () => {
        if(xhr.status === 200) {
          try {
            const resData = JSON.parse(response);
            new Identification().setDataInStorage(resData['parameters'], false);
          } catch (e) {}
        }
      }
    };
  }
}
