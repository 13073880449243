/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-08
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-04-08
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NanocuminDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    return {
      price: NumberUtil.parseRationalNumber((document.querySelector('input[name="set_goods_fixedPrice"]') as HTMLInputElement).value),
      dcPrice: NumberUtil.parseRationalNumber((document.querySelector('input[name="set_goods_price"]') as HTMLInputElement).value)
    };
  }
}
