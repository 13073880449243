/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-04
 */
import {ValidationRuleImpl} from '../ValidationRuleImpl';
import {StringUtil} from '../../../lib/common/StringUtil';

/**
 * create on 2019-11-04.
 * <p> 상품코드 검증 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ProductCodeRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    if (!property || StringUtil.isNotString(property.toString()) || StringUtil.isEmpty(property.toString().trim())) {
      return 1;
    }

    return property;
  }
}
