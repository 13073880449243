/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020.06.01
 */
import { Punycode } from '../../lib/codec/Punycode';
import { Utf8 } from '../../lib/codec/Utf8';
import { Base64 } from '../../lib/codec/Base64';
import { Config } from './config/Config';
import { EventType } from '../../types/GlobalEnums';
import { Transmitter } from './core/Transmitter';
import { DataParser } from '../../dataParser/DataParser';

/**
 * TODO 주석
 * create on 2020.06.01
 * <p> 리뷰데이터 수집 </p>
 * <p> {@link Transmitter} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ReviewCollector extends Transmitter {
  protected readonly _punycode: Punycode;
  protected readonly _utf8: Utf8;
  protected readonly _base64: Base64;

  constructor(eventType: EventType, config: Config, dataParser: DataParser) {
    super(eventType, config, dataParser);
    this._punycode = new Punycode();
    this._utf8 = new Utf8();
    this._base64 = new Base64();
  }

  protected preSend(): void {
    this.addExtraData({'isScript': true});
  }

  protected finish(): void {
    /* 210225 리뷰 사용 안함에 따라 gateway Ajax 전송 로직 주석 */
    // super.finish();
  }
}
