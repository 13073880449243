/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-08-18
 */
/**
 * create on 2020-08-18.
 * <p> 서버 호출을 막는 클래스 </p>
 * <p> 규칙들을 private 메소드로 추가 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Shield {
  /* 호출을 막기 위한 규칙 (배열의 요소가 되는 함수는 호출을 막아야 할 때 true를 리턴하도록 할 것) */
  private readonly blockingRules: Function[] = [];

  constructor(additionalRules: Function[] = []) {
    /* 기본 규칙 추가 */
    this.addRulesDefault();

    /* 인자로 받은 규칙 추가 */
    this.addRulesWith(additionalRules);
  }

  /**
   * 호출을 막아야 하는지에 대한 여부
   * @return {boolean}
   * <p><code>true</code> - 호출을 막아야 함</p><p><code>false</code> - 통과</p>
   */
  blocked(): boolean {
    for (let i = 0; i < this.blockingRules.length; i++) {
      if (this.blockingRules[i]()) {
        return true;
      }
    }

    return false;
  }

  /**
   * 기본 규칙 생성
   */
  private addRulesDefault(): void {
    this.blockingRules.push(this.blockByDomain);
    this.blockingRules.push(this.blockByLocalHost);
  }

  /**
   * 인자로 받은 규칙들을 기존 규칙에 추가.
   * @param {Function[]} rules  - 추가할 규칙 배열
   */
  private addRulesWith(rules: Function[]): void {
    try {
      for (let i = 0; i < rules.length; i++) {
        this.blockingRules.push(rules[i]);
      }
    } catch (e) {
    }
  }

  /**
   * 도메인을 체크 규칙
   *
   * <b>도메인 리스트</b>
   * <ul>
   *   <li>parser.bringko.com - 통합 e-커머스 기업이며, 해당 URL에서는 상품 정보들이 깨짐 현상이 나타남</li>
   *   <li>cjmallapp.cjmall.com - 데이터가 비정상으로 유입되며, 어드민 확인 결과 웹에서 호출하지 않으니 막아도 된다고 함</li>
   * </ul>
   * @return {boolean}
   * <p><code>true</code> - 호출을 막아야 하는 도메인임</p><p><code>false</code> - 통과</p>
   */
  private blockByDomain(): boolean {
    const domainList = ['parser.bringko.com', 'cjmallapp.cjmall.com'];
    for (let i = 0; i < domainList.length; i++) {
      if (location.hostname === domainList[i]) {
        return true;
      }
    }

    return false;
  }

  /**
   * localhost로 유입되는 도메인 차단
   */
  private blockByLocalHost(): boolean {
    return location.href.indexOf('http://localhost') !== -1 || 
            location.href.indexOf('https://localhost') !== -1 ||
            location.href.indexOf('127.0.0.1') !== -1;
  }
}
