/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-09
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-11-09.
 * <p> Attatt 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class AttattDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (!this.isMobile(this.commandOptions)) {
      jsonObj.data['price'] =document.querySelector('p.pricess')!['dataset']['price'];
      jsonObj.data['dcPrice'] =document.querySelector('p.pricess')!['dataset']['sale'];
    }
    return jsonObj;
  }
}
