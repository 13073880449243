/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-04
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { GlobalVariables } from "../../../types/GlobalVariables";

/**
 * create on 2021-03-04
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class CsbeautiselDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();

    const priceElement = document.querySelector('.deatil_price_area .org_price .num');

    jsonObj.replaceData('price', priceElement ? NumberUtil.parseRationalNumber(priceElement.textContent!.trim()) : NumberUtil.parseRationalNumber(document.querySelector('.deatil_price_area .sale_price .num')!.textContent!));
    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    let totalQty = document.querySelector('form[name=goodsForm] input[name^=optionEa]')
      ? NumberUtil.stringToNumber(document.querySelector('form[name=goodsForm] input[name^=optionEa]')!['value'])
      : 1;
    let totalPrice = 0;

    let productName: string = this.getProductName();
    const newLineIdx: number = productName.indexOf('\n');
    productName = newLineIdx === -1 ? productName : productName.substring(0, newLineIdx);

    this.parseCategory('');
    parsedProducts.push({
      'productCode': window['gl_goods_seq'],
      'productName': productName,
      'qty': totalQty,
      'price': document.querySelector('.deatil_price_area .org_price .num') ?
      NumberUtil.parseRationalNumber(document.querySelector('.deatil_price_area .org_price .num')!.textContent!.trim()) 
      : NumberUtil.parseRationalNumber(document.querySelector('.deatil_price_area .sale_price .num')!.textContent!),
      'productUrl': window.location.href,
      'dcPrice': window['gl_goods_price'],
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
      'imageUrl': document.querySelectorAll('div#goods_thumbs img')[0]['src'],
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    parsedProducts.forEach((obj) => {
      totalPrice += NumberUtil.parseNumber(document.querySelector('.deatil_price_area .sale_price .num')!.textContent!);
    });
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice * totalQty,
      'totalQty': totalQty
    };
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.getWebPayConvDataFromBasketPage();
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productCode = new QueryStringer((rawProductList[i].querySelector('li.img_area a')! as HTMLAnchorElement).search.substring(1)).getParam('no');
        const productQty = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[1].textContent!);
        const productPrice = NumberUtil.parseRationalNumber(rawProductList[i].querySelector('.block.block2 .price_c .num')!.textContent!.trim());
        const productName = rawProductList[i].querySelector('.goods_name')!.textContent;

        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(document.querySelector('#totalPrice .num')!.textContent!),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getConversionData(): JsonObject {
    return new JsonObject(this.getWebConversionData());
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productCode = new QueryStringer((rawProductList[i].querySelector('li.img_area a')! as HTMLAnchorElement).search.substring(1)).getParam('no');
        const productQty = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[2].textContent!);
        const productPrice = rawProductList[i].querySelector('.cart_quantity .add_txt')!.textContent!.trim().replace(/\D/g, '');
        const productName = rawProductList[i].querySelector('.goods_name')!.textContent!.trim();

        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(document.querySelector('ul.total .price .settle_price')!.textContent!),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}