/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-03-09.
 * <p> justOne 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class JustoneDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {

  const jsonObj: JsonObject = super.getShopCollectData();
  let price = '0' ;
  let dcPrice = '0';
  if(!this.isMobile(this.commandOptions)){
    if(document.querySelector('#form1 span.val  span.strike')){
      price = document.querySelector('#form1 span.val  span.strike')!.textContent!.replace(/\D/g,'');
      dcPrice = document.querySelector('#form1 span span.price')!.textContent!.replace(/\D/g,'');
    }else{
      price= document.querySelector('#form1 span span.price')!.textContent!.replace(/\D/g,'');
    }
  }else{
    if(document.querySelector('div.listPrice.topInfoPrice  span.strike')){
      price =  document.querySelector('div.listPrice.topInfoPrice  span.strike')!.textContent!.replace(/\D/g,'');
      dcPrice= document.querySelector('div.listPrice.topInfoPrice span.price')!.textContent!.replace(/\D/g,'');
    }else{
      price = document.querySelector('div.listPrice.topInfoPrice span.price')!.textContent!.replace(/\D/g,'')
    }
}
jsonObj.data['price'] = price;
jsonObj.data['dcPrice'] = dcPrice;
return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    let price = '0' ;
    let dcPrice = '0';
    if(!this.isMobile(this.commandOptions)){
      if(document.querySelector('#form1 span.val  span.strike')){
        price = document.querySelector('#form1 span.val  span.strike')!.textContent!.replace(/\D/g,'');
        dcPrice = document.querySelector('#form1 span span.price')!.textContent!.replace(/\D/g,'');
      }else{
        price= document.querySelector('#form1 span span.price')!.textContent!.replace(/\D/g,'');
      }
    
    }else{
      if(document.querySelector('div.listPrice.topInfoPrice  span.strike')){
        price =  document.querySelector('div.listPrice.topInfoPrice  span.strike')!.textContent!.replace(/\D/g,'');
        dcPrice= document.querySelector('div.listPrice.topInfoPrice span.price')!.textContent!.replace(/\D/g,'');
      }else{
        price = document.querySelector('div.listPrice.topInfoPrice span.price')!.textContent!.replace(/\D/g,'')
      } 
  }
        if(document.querySelector('#MK_p_total')!.textContent=='0'){
          jsonObj['totalPrice'] = dcPrice == '0' ? price : dcPrice;
      }
  jsonObj['product'][0]['price'] = price;
  jsonObj['product'][0]['dcPrice'] = dcPrice;
  return jsonObj;
}
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-order-prd tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-order-prd tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart>table tr td:nth-child(3), div.table-order-prd table tr td:nth-child(3)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('h3.tit-prd > span.chkPrdName')!.textContent!.trim()
            : document.querySelector('div.prdInfoCnt  h3.tit-prd > span.prd-name')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  protected getImageUrl(): string {
    if (document.querySelector('div#productDetail div.thumb img')) {
      return (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop div.thumb div.originImgArea span.imgWrap img')) {
      return (document.querySelector('div.shopdetailInfoTop div.thumb div.originImgArea span.imgWrap img') as HTMLImageElement).src;
    } else {
      return super.getImageUrl();
    }
  }
}
