/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-06-07
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-06-07.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class TheralogicDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    // iframe 등 외부 페이지에서 스크립트 기능 호출 시 document의 이미지 src를 참조하기 위해 selector 변수로 분리
    const currDocument = window.parent.document === document
      ? document
      : window.parent.document;

    if (currDocument.querySelector('.prdImgView p.thumbnail img')) {
      return (currDocument.querySelectorAll('.prdImgView p.thumbnail img')[currDocument.querySelectorAll('.prdImgView p.thumbnail img').length - 1] as HTMLImageElement).src;
    } else if (currDocument.querySelector('#imgArea .thumbnail img')) {
      return (currDocument.querySelectorAll('#imgArea .thumbnail img')[currDocument.querySelectorAll('#imgArea .thumbnail img').length - 1] as HTMLImageElement).src;
    }

    return super.getImageSrc();
  }
}
