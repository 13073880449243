/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-27
 */

import PaySelector from "../config/selector/pay.observer.selector.json";
import {PayBtnObserverEventData} from "../data/PayBtnObserverEventData";
import {EventUtil} from "../../lib/event/EventUtil";
import {PaySystem} from "../../service/paySystem/PaySystem";

/**
 * create on 2020-03-27.
 * <p> 간편결제 버튼들의 이벤트가 생성이 안될 때 동적으로 이벤트 주입하는 클래스 </p>
 * <p> {@link MutationObserver } 관련 클래스 </p><br>
 *   <pre>
 *     ※ 참고사항
 *     1. pay.observer.selector.json에 PaySystem에 맞게 데이터 정의 필요
 *        > pay.observer.selector.json
 *          - targetSelector : Observer 대상 Selector</br>
 *          - eventSelector: 간편결제 버튼들의 Selector</br>
 *     2. PayBtnObserverEventData.ts에 버튼의 이벤트 정보 선언 필요
 *   </pre>
 * @version 1.0
 * @author mgpark
 */
export abstract class PayBtnEventObserver {
  protected paySystemType: string;    //간편결제 시스템 타입
  private targetElement: any[] | undefined;   //observer 관찰 대상 Element
  private btnEventDataArr: PayBtnObserverEventData[];  //버튼 이벤트 관련 정보를 담고있는 배열
  protected adverId: string;

  constructor(paySystem: PaySystem, paySystemType: string, adverId: string) {
    this.adverId = adverId;
    this.paySystemType = paySystemType;
    this.targetElement = this.getTargetElementList();
    this.btnEventDataArr = new PayBtnObserverEventData().getPayEventData(paySystem, paySystemType, adverId);
  }

  /**
   * 화면에 존재하는 여러개의 MutationObserver를 생성하여 Observing을 시작
   */
  startingObserving(): void {
    //플러그인 객체를 담고있는 DIV을 못찾으면 페이 플러그인으로 생성되는 최상위 DIV의 부모를 찾음
    try {
      //MutationObserver callback 함수 선언
      if (this.targetElement) {
        this.targetElement.forEach((target) => {
          new MutationObserver((mutations, obs) => this.observerCallback(mutations, obs)).observe(target, this.getObserverOptions());
        });
      }
    } catch (e) {
    }
  }

  /**
   * 버튼들에 이벤트 등록
   *  @param btnParentNod 버튼의 부모 HTMLElement
   *  @return
   */
  protected injectBtnEvent(btnParentNod: HTMLElement): void {
    const btnArr = btnParentNod.querySelectorAll(PaySelector[this.paySystemType].payBtnSelector);

    for (let i = 0; i < btnArr.length; i++) {
      const btn: Element = btnArr[i];
      // STEP 1. 버튼에 해당하는 이벤트 정보 탐색
      for (let j = 0; j < this.btnEventDataArr.length; j++) {
        const btnEventData = this.btnEventDataArr[j];
        const attr: string | null = btn.getAttribute(btnEventData.attribute);

        // 버튼의 속성값이 없으면 이벤트 주입을 못하므로 for문 종료
        if (!attr) {
          break;
        }

        // STEP 2. 버튼 객체에 이벤트리스너 등록
        if (attr.search(btnEventData.regExp) > -1) {
          EventUtil.addEvent(btn, btnEventData.eventName, btnEventData.eventListener);
          break;  // 현재 버튼에 이벤트를 주입 했으므로 for문 종료
        }
      }
    }
  }

  protected abstract observerCallback(mutations: MutationRecord[], obs: MutationObserver): void;

  protected abstract getTargetElementList(): any[] | undefined;

  protected abstract getObserverOptions(): {};
}
