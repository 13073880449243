/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-09-14
 */

import {JsonObject} from "../lib/json/JsonObject";

/**
 * create on 2020-09-14.
 * <p> 상품 상세 페이지에서 모델컷 등의 이미지를 추가로 수집 </p>
 * <p> 추가로 수집하고 싶은 광고주의 DataParser 클래스에서 객체 생성 후 사용할 것. </p>
 * <p> 테스트의 성격이며, 특정 광고주만 적용 (chocomom, luvj92, ehddnsirh) </p>
 * <p> {@link DataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class DetailImageParser {
  private static PROPERTY_KEY: string = 'imageList';
  private static LIST_SIZE_MAX: number = 5;

  constructor() {
  }

  addDetailImageList(parsedProduct: JsonObject | {}, imageListSelector: string): void {
    const imageList: string[] = this.isValid(parsedProduct, imageListSelector)
      ? this.getImageList(imageListSelector)
      : [];

    this.addImageList(parsedProduct, imageList);
  }

  private getImageList(imageListSelector: string): string[] {
    const imageList: string[] = [];
    const imageElements: NodeListOf<HTMLImageElement> = document.querySelectorAll(imageListSelector);

    for (let i = 0; this.isVacantList(i, imageElements); i++) {
      imageList.push(this.getImageUrl(imageElements[i]));
    }

    return imageList;
  }

  private isValid(parsedProduct: JsonObject | {}, imageListSelector: string): boolean {
    const validProduct: boolean = this.isValidProduct(parsedProduct);
    const validSelector: boolean = document.querySelectorAll(imageListSelector).length > 0;
    return validProduct && validSelector;
  }

  private isValidProduct(parsedProduct: JsonObject | {}): boolean {
    if (parsedProduct instanceof JsonObject) {
      return parsedProduct.isNotEmpty();
    } else if (typeof parsedProduct === 'object') {
      return !!parsedProduct;
    } else {
      return false;
    }
  }

  private isVacantList(index: number, imageElements: NodeListOf<HTMLImageElement>): boolean {
    return imageElements.length > 5
      ? index < DetailImageParser.LIST_SIZE_MAX
      : index < imageElements.length;
  }

  /**
   * data scheme인 경우 ec-data-src 속성의 값을 리턴하고, 그렇지 않으면 src를 리턴
   * @param {HTMLImageElement} imageElem
   * @return {string}
   */
  private getImageUrl(imageElem: HTMLImageElement): string {
    const isNotDataScheme: boolean = imageElem.src.indexOf('data:') === -1;
    return isNotDataScheme
      ? imageElem.src
      : location.origin + imageElem.getAttribute('ec-data-src');
  }

  private addImageList(parsedProduct: JsonObject | {}, imageList: string[]): void {
    if (parsedProduct instanceof JsonObject) {
      this.addListIntoJsonObject(parsedProduct, imageList);
    } else {
      this.addListIntoObject(parsedProduct, imageList);
    }
  }

  private addListIntoJsonObject(parsedProduct: JsonObject, imageList: string[]): void {
    parsedProduct.addData(DetailImageParser.PROPERTY_KEY, imageList);
  }

  private addListIntoObject(parsedProduct: {}, imageList: string[]): void {
    parsedProduct[DetailImageParser.PROPERTY_KEY] = imageList;
  }
}
