/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">mgpark</a>
 * @since 2022-04-21
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GodomallSelfDataParser } from "../GodomallSelfDataParser";

/**
 * create on 2022-04-21
 * <p> {@link GodomallSelfDataParser } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ZipbanchanDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(): JsonObject {
    let superData: JsonObject = super.getMobileShopCollectData();
    try {
      let price: number = 0;
      let dcPrice: number = 0;
      price = document.querySelector('.banchan-price__list-price') ?
        NumberUtil.parseNumber(document.querySelector('.banchan-price__list-price')!.textContent!.replace(/\D/g, '')) :
        NumberUtil.parseNumber(document.querySelector('.banchan-price__discount')!.textContent!.replace(/\D/g, ''));
      dcPrice = NumberUtil.parseNumber(document.querySelector('.banchan-price__discount')!.textContent!.replace(/\D/g, ''));
      superData['data']['price'] = price;
      superData['data']['dcPrice'] = dcPrice;
      superData['data']['productName'] = document.querySelector('.banchan-content h1')!.textContent!.trim();
      return superData;
    } catch {
      return superData;
    }
  }

  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
      };

      products.push(product);
    }

    return new JsonObject({
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': targetObj['totalPrice'],
      'totalQty': targetObj['totalQty']
    });
  }

  protected getImageUrl(): string {
    try{
      return document.querySelector('.banchan-thum')!.getAttribute('style')!.split('url(')[1].split(');')[0];
    }catch{
      return super.getImageUrl();
    }
  }
}
