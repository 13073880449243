/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-26
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import json = Mocha.reporters.json;
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-10-26
 * <p> Yujinrobot(iclebo.com) </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class YujinrobotDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();

    const productList = jsonObj.data['product'];
    for (let i = 0; i <productList.length; i++) {
      productList[i]['price'] = document.querySelectorAll('div.table-cart>table tr td:nth-child(5)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }
}