/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-24
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-24
 * <p> Ofrakorea - ofracosmetics.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class OfrakoreaDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * ofrakorea의 경우 할인, 타임세일, 할인-타임세일 3가지의
   * 할인의 경우의 수가 존재하여 별도의 가격 수집로직을 구현
   * @param formElement
   * @protected
   */
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      const priceJsonObj = {price: formElement!['set_goods_price'].value, dcPrice: 0};

      // 할인가격의 경우 set_goods_price에 세팅이 되어 수집 하여 진행한다.
      priceJsonObj['dcPrice'] = NumberUtil.parseRationalNumber(formElement!['set_goods_price'] ? (formElement!['set_goods_price'] as HTMLInputElement).value : '0');

      // 정가(상품가) 수집
      const priceValue = NumberUtil.parseRationalNumber(formElement['set_goods_fixedPrice'] ? formElement['set_goods_fixedPrice'].value : '0');
      if (priceValue > 0) {
        // 일반적으로 form input 요소중 set_goods_fixedPrice에 정가가 존재함
        priceJsonObj['price'] = priceValue;
      } else if (document.querySelector('.time_sale_price')) {
        // 타임세일 중이나 정가의 input Tag가 존재하지 않는 경우 HTML 파싱해서 진행
        priceJsonObj['price'] = NumberUtil.parseRationalNumber(this.isMobile(this.commandOptions)
            ? document.querySelector('div.price_box>del')!.textContent!.trim()
            : document.querySelector('dl.time_sale_price dd > del')!.textContent!.trim());
      }

      return priceJsonObj;
    } catch (e) {
      return super.getProductPriceObj(formElement);
    }
  }
}
