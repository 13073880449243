/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-26
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

export class Nature0001DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
     if (document.querySelector('div.cboth.imgArea img')) {
      return (document.querySelector('div.cboth.imgArea img') as HTMLImageElement).src;
    } else {
      return super.getImageSrc();
    }
  }

   protected getProductPrice(): { price: number; dcPrice: number; } {
    try {
      const priceObj = super.getProductPrice();
      const parsedPriceValue: number = document.querySelector('span.main_price.font_bold')
      ? NumberUtil.parseRationalNumber(document.querySelector('span.main_price.font_bold')!.textContent!.replace(/\D/g, ''))
      : 0
        priceObj['dcPrice'] = parsedPriceValue && parsedPriceValue > 0 ? parsedPriceValue : priceObj['price'];

      return priceObj;
    } catch (e) {
      return super.getProductPrice();
    }
  }
}
