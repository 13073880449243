/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-04-11
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-04-11.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class EpeiosDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if(this.isMobile(this.commandOptions)){
      return super.getConversionData_prev();
    }
    const orderList = document.querySelectorAll('.orderArea:not(.displaynone) .prdBox .description .prdName a');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;
    let totalPrice = 0;

    for (let i = 0; i < orderList.length; i++) {
      const prodImgUrl = (document.querySelectorAll('.orderArea:not(.displaynone) > div:not(.displaynone) .prdBox .thumbnail a')[i] as HTMLAnchorElement).search.substring(1);
      const productCode: string = new QueryStringer(prodImgUrl).getParam('product_no');
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('.orderArea:not(.displaynone) > div:not(.displaynone) .prdBox .description ul.info .price .quantity')[i].textContent!.replace(/\D/g, ''));
      const price: number = NumberUtil.parseNumber(document.querySelectorAll('.orderArea:not(.displaynone) > div:not(.displaynone) .prdBox .description ul.info .price .priceValue')[i].textContent!.replace(/\D/g, ''));

      parsedProducts.push({
        'productCode': productCode,
        'productName': document.querySelectorAll('.orderArea:not(.displaynone) > div:not(.displaynone) .prdBox .description .prdName a.ec-product-name')[i].textContent!.trim(),
        'qty': qty,
        'price': price
      });

      totalQty += qty;
      totalPrice += price;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
      'totalPrice': document.querySelector('.totalPay strong.txtStrong') ? 
        NumberUtil.parseNumber(document.querySelector('.totalPay strong.txtStrong')!.childNodes[0].textContent!.replace(/\D/g, '')) : totalPrice,
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
