/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import { JsonObject } from '../../../lib/json/JsonObject';
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';



/**
* create on 2020-04-02.
 * <p> Revitalashkor 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class RevitalashkorDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(2), form[name="forms"]>figure>div.basketBody p.price-sell')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-child, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.txt-price')!.textContent!.replace(/\D/g, '').trim(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span, table.cart_box_a tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left > a, table.cart_box_a tr td div.tb-left > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('div.table-cart tfoot strong')!.textContent!.replace(/\D/g, '').trim(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    try {
      const productInfoLength = document.querySelectorAll('div.table-order-prd tr td div.tb-left a').length;
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < productInfoLength; i++) {
        const productAnchorElem = document.querySelectorAll('div.table-order-prd tr td div.tb-left a')[i] as HTMLAnchorElement;
        const qty = (document.querySelectorAll('div.table-order-prd table tr td:nth-child(3)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

        parsedProducts.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': productAnchorElem.textContent,
          'qty': qty,
          'price': (document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
        });

        totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
      }

      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
        'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
        'totalQty': totalQty.toString()
      };

      return new JsonObject(parsedData);
    } catch (e) {
      return super.getWebConversionData();
    }
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    try {
      const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
      const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < productInfoLength; i++) {
        const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
        const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.replace(/\D/g, '').trim();

        parsedProducts.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': productAnchorElem!.textContent!.trim(),
          'qty': qty,
          'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
        });

        totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
      }

      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': ordCode,
        'totalPrice': this.getMobileTotalPrice(),
        'totalQty': totalQty
      };

      return new JsonObject(parsedData);
    } catch (e) {
      return super.getMobileConversionData();
    }
  }

  protected getTotalQty(): string {
    try {
      return document.querySelector('input#goods_amount')!['value'].trim();
    } catch (e) {
      return super.getTotalQty();
    }
  }
}
