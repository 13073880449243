/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun1@enliple.com">sjchun</a>
 * @since 2022-01-05
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2022-01-05.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class UptownholicDataParser extends MakeshopD4DataParser {

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('.table-02 table tbody tr td:nth-of-type(6) p')[i].textContent!.replace(/\D/g, '');
      const rawQty = document.querySelectorAll('input[name="orgamount"]')[i]['value'];

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': document.querySelectorAll('input[name="branduid"]')[i]['value']!,
        'productName': document.querySelectorAll('.table-02 table tbody tr td:nth-of-type(3) a p')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.total-price')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"] .item-price')[i].textContent!.replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('form[name="forms"] .item-name a')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('.final-price .price')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    if(document.querySelector('.section.sub .thumb img')){
      return (document.querySelector('.section.sub .thumb img') as HTMLImageElement).src;
    }else if(document.querySelector('#contents section.shopdetailInfo .thumb img')){
      return (document.querySelector('#contents section.shopdetailInfo .thumb img') as HTMLImageElement).src;
    }
    return super.getImageUrl();
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents section h2')!.textContent!.trim()
            : document.querySelector('form .item-name')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }
}
