/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-08
 */
import {DataParser} from './DataParser';
import {AdverConfigJsonMapper} from '../adverConfigJsonMapper/AdverConfigJsonMapper';
import {ResourceNotFoundError} from '../error/ResourceNotFoundError';
import {NullDataParser} from "./NullDataParser";
import {NonProductDataParser} from "./NonProductDataParser";
import {CommandType, ConversionType, EventType, HostingType} from "../types/GlobalEnums";
import {ObjectUtil} from "../lib/common/ObjectUtil";

/**
 * create on 2019-11-08.
 * <p> DataParser 객체를 생성 </p>
 * <p> <code>.json</code> 파일의 내용으로 adverId를 맵핑하여 클래스의 이름을 찾아 생성한다 </p>
 * <p> Factory pattern, Flyweight pattern </p>
 * <p> {@link DataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class DataParserFactory {
  private static pool: { [eventType: string]: DataParser | undefined } = {};

  private constructor() {}

  /**
   * 생성된 {@link DataParser} 객체를 리턴.
   * @param {CommandType} commandType 커맨드 타입
   * @param {EventType} eventType     이벤트 타입
   * @param {string} adverId          광고주 ID
   * @param {{}} commandOptions       커맨드 옵션
   * @return {DataParser}
   */
  static getDataParser(commandType: CommandType, eventType: EventType, adverId: string, commandOptions: {}): DataParser {
    let dataParser: DataParser | undefined = this.prototype.getPoolContent(commandType, eventType, commandOptions);

    if (!dataParser) {
      dataParser = this.prototype.createDataParser(adverId, eventType, commandOptions);

      /* 버튼 클릭 이벤트를 통한 비쇼핑 전환인 경우 */
      if (this.prototype.isNonProductConvWithBtn(eventType, commandOptions)) {
        const convType: ConversionType = commandOptions['convType'];
        const btnSelector: string = commandOptions['btnSelector'];

        ObjectUtil.createEmptyObjectAt(this.pool, eventType);
        ObjectUtil.createEmptyObjectAt(this.pool[eventType]!, convType);

        this.pool[eventType]![convType][btnSelector] = dataParser;
      } else {
        this.pool[eventType] = dataParser;
      }
    } else {
      /* 객체 재사용 */
    }

    return dataParser;
  }

  /**
   * Pool에서 찾은 {@link DataParser}를 리턴한다.
   * <b>NOTE: </b>커맨드 타입이 <code>CREATE</code>인 경우 새로 생성하도록 한다.
   * @param {CommandType} commandType   커맨드 타입
   * @param {EventType} eventType       이벤트 타입
   * @param {{}} commandOptions         커맨드 옵션
   * @return {DataParser | undefined}
   * <p>{@link DataParser}</p> - pool에서 찾은 경우
   * <p><code>undefined</code></p> - pool에서 못찾은 경우
   */
  private getPoolContent(commandType: CommandType, eventType: EventType, commandOptions: {}): DataParser | undefined {
    /* 버튼 클릭 이벤트를 통한 비쇼핑 전환인 경우 */
    if (this.isNonProductConvWithBtn(eventType, commandOptions)) {
      try {
        const convType: ConversionType = commandOptions['convType'];
        const btnSelector: string = commandOptions['btnSelector'];
        return DataParserFactory.pool[eventType]![convType][btnSelector];
      } catch (e) {
        return undefined;
      }
    } else {
      /* create 커맨드의 경우 새로 생성시키기 위해 undefined로 초기화 */
      if (commandType === CommandType.CREATE) {
        DataParserFactory.pool[eventType] = undefined;
      }

      return DataParserFactory.pool[eventType] as DataParser;
    }
  }

  /**
   * <code>advertiser.config.json</code> 파일에 정의된 {@link DataParser} 객체를 생성한다.
   * 커맨드옵션에 <code>btnSelector</code> 프로퍼티가 있고, 컨버전의 경우만 {@link NullDataParser}를 리턴한다.
   * @param {string} adverId      광고주의 모비온 ID
   * @param eventType             이벤트 타입
   * @param {{}} commandOptions   커맨드 옵션
   * @return {DataParser} 생성된 {@link DataParser} 객체
   * @throws {@link ResourceNotFoundError}  지정된 {@link DataParser} 클래스를 찾지 못한 경우
   */
  private createDataParser(adverId: string, eventType: EventType, commandOptions: {}): DataParser {
    const parserClassInfo: {
      className: string;
      classSubPath: string;
    } = this.getParserClassInfo(adverId);
    const classSubPath: string = parserClassInfo.classSubPath;
    const className: string = parserClassInfo.className;

    try {
      switch(eventType) {
        case EventType.CONVERSION :
        //convType이 Null이면 기본값인 Product 세팅
        commandOptions = this.setConvTypeOption(commandOptions);
          if(commandOptions['convType']  === ConversionType.PRODUCT) {
            // 이벤트 타입이 Conversion이고 ConversionType이 Product이면
            // > Options에 Btnselector 값이 있으면 NullDataParser 반환, 없으면 광고주ID에 할당된 Dataparser 반환
            return this.hasBtnSelectorProperty(commandOptions) && eventType === EventType.CONVERSION
                ? new NullDataParser(adverId, commandOptions, this.getHostingType(adverId))
                : new (require(`${classSubPath + className}`)[className])(adverId, commandOptions);
          } else {
            // 이벤트 타입이 Conversion이고 ConversionType이 Product가 아니면
            //  > 비쇼핑 DataParser(NonProductDataParser) 반환
            return new NonProductDataParser(adverId, commandOptions, this.getHostingType(adverId));
          }
        default :
          return new (require(`${classSubPath + className}`)[className])(adverId, commandOptions);
      }
    } catch (e) {
      throw new ResourceNotFoundError('DataParser class', classSubPath + className);
    }
  }

  /**
   * 인스턴스로 생성할 {@link DataParser} 클래스 경로 관련된 정보를 가져온다
   * @param {string} adverId  광고주 ID
   * @return {{className: string; classSubPath: string}}
   */
  private getParserClassInfo(adverId: string): { className: string; classSubPath: string } {
    return AdverConfigJsonMapper.getInstance(adverId).getParserClassInfo();
  }

  /**
   * 솔루션 정보를 가져온다
   * @param {string} adverId  광고주 ID
   * @return {HostingType}
   */
  private getHostingType(adverId: string): HostingType {
    return AdverConfigJsonMapper.getInstance(adverId).getHostingType();
  }

  /**
   * 커맨드옵션에 <code>btnSelector</code> property가 있는지 확인.
   * @param {{}} commandOptions
   * @return {boolean}
   * <p><code>true</code> - 있음</p><p><code>false</code> - 없음</p>
   */
  private hasBtnSelectorProperty(commandOptions: {}): boolean {
    return typeof commandOptions !== 'undefined' && typeof commandOptions['btnSelector'] !== 'undefined';
  }

  /**
   * convType(전환구분)이 빈값이거나 없으면 기본값인 Product로 세팅후 반환
   * @param {{}} commandOptions
   * @return {boolean}
   * <p><code>true</code> - 있음</p><p><code>false</code> - 없음</p>
   */
  private setConvTypeOption(commandOptions: {}): {} {
    try {
      // Options이 없거나 Options이 있는데 convType이 없으면 기본값인 Product(광고주)
      if( !commandOptions || (commandOptions && !commandOptions['convType']) ) {
        commandOptions['convType'] = ConversionType.PRODUCT;
      }

      return commandOptions;
    } catch (error) {
      commandOptions['convType'] = ConversionType.PRODUCT;
      return commandOptions;
    }
  }

  /**
   * 버튼 클릭 이벤트를 통한 비쇼핑 전환인 경우
   * @param {EventType} eventType
   * @param {{}} commandOptions
   * @return {boolean}
   */
  private isNonProductConvWithBtn(eventType: EventType, commandOptions: {}): boolean {
    return eventType === EventType.CONVERSION
        && typeof commandOptions === 'object'
        && commandOptions['convType']
        && commandOptions['btnSelector']
        && commandOptions['convType'] !== 'product';
  }
}
