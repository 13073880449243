/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-04-02.
 * <p> Goqkd97 다른 광고주와 상이해서 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Goqkd97DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('.basketpackage .ec-base-table table tbody tr td:nth-child(6) span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.ec-base-table input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.ec-base-table input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.basketpackage div.ec-base-table table tbody tr td:nth-child(3) a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('div.total div.tototal div >strong > span')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.product-detail div.thumbnail img')) {
      return (document.querySelector('div.product-detail div.thumbnail img') as HTMLImageElement).src;
    } else if(document.querySelector('div.overview div.prdImgView p img')){
      return (document.querySelector('div.overview div.prdImgView p img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }

  protected getMobileTotalPrice(): string {
    try {
      return document.querySelector('#mk_totalprice')!.textContent!.trim().replace(/\D/g, "");
    } catch (e) {
      return '0';
    }
  }
}
