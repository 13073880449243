/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {DataValidator} from './DataValidator';
import {AdverConfigJsonMapper} from '../adverConfigJsonMapper/AdverConfigJsonMapper';
import {ResourceNotFoundError} from '../error/ResourceNotFoundError';

/**
 * create on 2019-08-02.
 * <p> 각 광고주에 대한 Validator 정보 </p>
 * <p> <code>.json</code> 파일의 내용으로 adverId를 맵핑하여 클래스의 이름을 찾아 생성한다 </p>
 * <p> Factory pattern </p>
 * <p> {@link DataValidator} {@link Transmitter} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class DataValidatorFactory {
  private constructor() {}

  static createDataValidator(adverId: string): DataValidator {
    const validatorClassInfo: {
      className: string;
      classSubPath: string;
    } = DataValidatorFactory.getValidatorClassInfo(adverId);
    const classSubPath: string = validatorClassInfo.classSubPath;
    const className: string = validatorClassInfo.className;

    try {
      return new (require(`${classSubPath + className}`)[className])();
    } catch (e) {
      throw new ResourceNotFoundError(
        'DataValidator class',
        classSubPath + className
      );
    }
  }

  private static getValidatorClassInfo(
    adverId: string
  ): { className: string; classSubPath: string } {
    return AdverConfigJsonMapper.getInstance(adverId).getValidatorClassInfo();
  }
}
