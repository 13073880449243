/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2020-06-15
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
/**
 * create on 2022-08-24.
 * <p> rangstable 광고주 서브클래스 추가 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */

export class RangstableDataparser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (this.isMobile(this.commandOptions)) {
      return super.getImageSrc();
    }

    if (document.querySelectorAll('.keyImg a img')[1]) {
      return (document.querySelectorAll('.keyImg a img')[1] as HTMLImageElement).src;
    }
    return super.getImageSrc();
  }
}