import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-04-05
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class MonogramgDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  // TODO 리팩토링 할 필요성이 있음
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      if(this.isMobile(this.commandOptions)){
        let price = NumberUtil.parseRationalNumber(formElement['set_goods_price'].value);
        let dcPrice = price * (1 - NumberUtil.parseRationalNumber(formElement['goodsDiscount'].value) / 100);
  
        return {'price': price, 'dcPrice': dcPrice}
      }else{
        return super.getProductPriceObj(formElement);
      }
    } catch (e) {
      return {'price': formElement!['set_goods_price'].value, 'dcPrice': 0}
    }
  }

}