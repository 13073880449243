/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-09-24
 */

import {ValidationRuleImpl} from "../ValidationRuleImpl";

/**
 * create on 2020-09-24
 * <p> URL의 해쉬를 삭제 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class UrlHashValidRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    return (property as string).replace(location.hash, '');
  }
}