/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-26
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2022-05-26
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class DukomallDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      const fix = NumberUtil.parseRationalNumber((formElement['set_goods_fixedPrice'] as HTMLInputElement).value);
      const goods = NumberUtil.parseRationalNumber((formElement['set_goods_price'] as HTMLInputElement).value);
      const price = fix > goods ? fix : goods;
      const dcPrice = fix < goods ? fix : goods;
      return { 'price': price, 'dcPrice': dcPrice };
    } catch (e) {
      return super.getProductPriceObj(formElement);
    }
  }
}