/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-30
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { ImwebDataParser } from "../ImwebDataParser";

/**
 * create on 2022-05-30
 * 상품명에 특수문자가 포함되어 전환이 잡히지 않아, 한글만 수집되도록 수정요청
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class HerbeautecokrDataParser extends ImwebDataParser {
  protected getShopCollectData(): JsonObject {
    let jsonData:JsonObject = super.getShopCollectData();
    jsonData.data['productName'] = this.replaceProductName(jsonData.data['productName']);
    return jsonData;
  }
  protected getConversionData(): JsonObject {
    let jsonObj = super.getConversionData();
    let prodList = jsonObj.data['product'];
    for(let i = 0; i < prodList.length; i++){
      prodList[i]['productName'] = this.replaceProductName(prodList[i]['productName'].split('/')[0]);
    }
    return jsonObj;
  }

  protected getParsedPayConvData(): {} {
    let obj = super.getParsedPayConvData();
    let prodList = obj['product'];
    for(let i = 0; i < prodList.length; i++){
      prodList[i]['productName'] = this.replaceProductName(prodList[i]['productName']);
    }
    return obj;
  }

  private replaceProductName(productName: string): string {
    return productName.replace(/[^\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F]/g, '');
  }
}