/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */

import {StringUtil} from './StringUtil';

/* 숫자 타입을 표현한 열거형 */
enum NumberTypeEnum {
  'RATIONAL' = 'rational', // 유리수
  'INTEGER' = 'integer', // 정수
  'FLOAT' = 'float' // 실수
}

/**
 * create on 2019-09-25.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NumberUtil {
  /* 진수 */
  static RADIX = {
    'BINARY': 2,
    'OCTAL': 8,
    'DECIMAL': 10,
    'HEXADECIMAL': 16
  };

  static NUM_TYPE = NumberTypeEnum;

  private constructor() {}

  /**
   * 입력값이 <code>number</code> 타입인지 확인
   * @param value 확인할 대상
   * @return {boolean}
   * <p><code>true</code> - <code>number</code> 타입</p>
   * <p><code>false</code> - <code>number</code> 타입 아님</p>
   */
  static isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  /**
   * 입력값이 <code>NaN</code>인지 확인
   * @param {number} target 확인할 대상
   * @return {boolean}
   * <p><code>true</code> - <code>NaN</code></p>
   * <p><code>false</code> - <code>NaN</code> 아님</p>
   */
  static isNaN(target: number): boolean {
    return Number.isNaN ? Number.isNaN(target) : typeof(target) === 'number' && isNaN(target);
  }

  /**
   * 입력된 자릿수만큼 왼쪽에 <code>0</code>을 추가하여 문자열로 반환.
   * <code>targetNumber</code>를 입력한 <code>radix</code>에 해당되는 진수로 변환한 후
   * <code>0</code>을 추가하여 <code>digit</code>만큼 자릿수가 되면 문자열로 변환하여 반환.
   * <b>NOTE: </b>
   * <code>digit</code>의 값이 진수로 변환된 <code>targetNumber</code>의 문자열 길이 이하인 경우
   * <code>targetNumber</code>를 문자열로 변환한 값이 반환됨.
   * @param {number} targetNumber 대상 숫자
   * @param {number} digit        생성될 문자열의 자릿수
   * @param {number} radix        대상 숫자의 진수
   * @return {string}             결과 문자열
   */
  static padZero(targetNumber: number, digit: number, radix = NumberUtil.RADIX.DECIMAL): string {
    let numToString: string = targetNumber.toString(radix);
    while (numToString.length < digit) {
      numToString = '0' + numToString;
    }

    return numToString;
  }

  /**
   * <code>string</code> 타입을 <code>number</code> 타입으로 변환.
   * <b>NOTE : </b>
   * 문자열에 comma(<code>,</code>)가 포함되어 있으면 <code>NaN</code>을 반환하므로,
   * {@link parseNumber}를 이용할 것.
   * @param {string} target 대상 문자열
   * @return {number} 변환된 숫자 (숫자로 변환할 수 없으면 <code>NaN</code>)
   */
  static stringToNumber(target: string): number {
    return Number(target).valueOf();
  }

  /**
   * 문자열에서 숫자를 파싱하여 반환.
   * @param {string} target 문자열
   * @param {NumberTypeEnum} [numberType=NumberUtil.NUM_TYPE.RATIONAL]  숫자 타입
   * @param {boolean} [isPositive=true] <code>true</code>: 양수, <code>false</code>: 음수
   * @return {number} 문자열로부터 추출된 숫자 (추출에 실패하면 <code>NaN</code> 반환)
   */
  static parseNumber(target: string, numberType: NumberTypeEnum = NumberUtil.NUM_TYPE.RATIONAL, isPositive = true): number {
    const regExp: RegExp = isPositive
        ? StringUtil.numberFormatRegex[numberType].positive
        : StringUtil.numberFormatRegex[numberType].negative;

    try {
      if(NumberUtil.isNumber(target)) return +target;

      const junkCharRemoved: string = StringUtil.replace(target, /[a-zA-Z\u20A9\uFFE6]|,|\s/g, StringUtil.EMPTY);
      const numberFormatString: string = StringUtil.getMatchedString(junkCharRemoved, regExp);

      return numberFormatString.trim() === StringUtil.EMPTY
          ? NaN
          : NumberUtil.stringToNumber(numberFormatString);
    } catch (e) {
      return NaN;
    }
  }

  /**
   * 문자열에서 유리수를 파싱하여 반환.
   * @param {target} target 문자열
   * @param {boolean} [isPositive=true]  <code>true</code>: 양수, <code>false</code>: 음수
   * @return {number} 문자열로부터 추출된 유리수 (추출에 실패하면 <code>NaN</code> 반환)
   */
  static parseRationalNumber(target: string, isPositive = true): number {
    return NumberUtil.parseNumber(target, this.NUM_TYPE.RATIONAL, isPositive);
  }

  /**
   * 문자열에서 정수를 파싱하여 반환.
   * @param {target} target 문자열
   * @param {boolean} [isPositive=true]  <code>true</code>: 양수, <code>false</code>: 음수
   * @return {number} 문자열로부터 추출된 정수 (추출에 실패하면 <code>NaN</code> 반환)
   */
  static parseInteger(target: string, isPositive = true): number {
    return NumberUtil.parseNumber(target, this.NUM_TYPE.INTEGER, isPositive);
  }

  /**
   * 문자열에서 실수를 파싱하여 반환.
   * @param {target} target 문자열
   * @param {boolean} [isPositive=true]  <code>true</code>: 양수, <code>false</code>: 음수
   * @return {number} 문자열로부터 추출된 실수 (추출에 실패하면 <code>NaN</code> 반환)
   */
  static parseFloat(target: string, isPositive = true): number {
    return NumberUtil.parseNumber(target, this.NUM_TYPE.FLOAT, isPositive);
  }
}
