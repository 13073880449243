/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-07-04
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

export class Hhyang2887DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    try{
      return {
        price: NumberUtil.parseNumber(document.querySelector('.price_box.spoqa_sans p.custom')!.textContent!.replace(/\D/g,'')),
        dcPrice: NumberUtil.parseNumber(document.querySelector('.price_box.spoqa_sans p.price')!.textContent!.replace(/\D/g,''))
      }
    } catch{
      return super.getProductPrice();
    } 
  }
}

