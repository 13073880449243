/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee1</a>
 * @since 2023-06-19
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2023-06-19
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class EnvylookkrDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    if (!this.isMobile(this.commandOptions)) 
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('strong span#total_order_price_front')!.textContent!.replace(/\D/g, ''));
    return jsonObj;
  }
}