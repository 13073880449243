/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2019. 5. 25
 */

import {NumberUtil} from "./NumberUtil";

/**
 * TODO: comment
 * create on 2020-05-25.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author hsju
 */
export class DateUtil {
  private constructor() {}

  static getYYYYMMDDHHMISSString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);
    const hours: string = NumberUtil.padZero(currentDate.getHours(), 2);
    const minutes: string = NumberUtil.padZero(currentDate.getMinutes(), 2);
    const seconds: string = NumberUtil.padZero(currentDate.getSeconds(), 2);
    return year + month + date + hours + minutes + seconds;
  }

  static getYYYYMMDDString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);
    return year + month + date;
  }

  static getYYYYString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const year: string = currentDate.getFullYear().toString();
    return year;
  }

  static getMMString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    return month;
  }

  static getDDString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);
    return date;
  }

  static getHHString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const hours: string = NumberUtil.padZero(currentDate.getHours(), 2);
    return hours;
  }

  static getMIString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const minutes: string = NumberUtil.padZero(currentDate.getMinutes(), 2);
    return minutes;
  }

  static getSSString(paramDate?: Date): string {
    const currentDate: Date = paramDate ? paramDate : new Date();
    const seconds: string = NumberUtil.padZero(currentDate.getSeconds(), 2);
    return seconds;
  }
}
