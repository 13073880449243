/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-30
 */

import {TelegramAlarm} from "./telegram/TelegramAlarm";
import {DeviceType, EventType, TelegramParseMode} from "../../types/GlobalEnums";
import {JsonObject} from "../json/JsonObject";

/**
 * create on 2020-10-30
 * <p> DataValidator에서 검증에 실패하거나 오류가 발생한 경우 알람전송 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class DataInvalidAlarm extends TelegramAlarm {
  /* EventType별 인스턴스 관리 */
  private static instance: DataInvalidAlarm | null = null;
  /* 메시지에 포함될 추가적인 데이터 */
  protected extraData: JsonObject;

  private constructor(adverId: string, deviceType: DeviceType, eventType: EventType) {
    super(adverId, deviceType, eventType, TelegramParseMode.HTML);
    this.extraData = new JsonObject();
  }

  /**
   * 인스턴스 생성
   * @return {StorageQueue}
   */
  static getInstance(adverId?: string, deviceType?: DeviceType, eventType?: EventType): DataInvalidAlarm | null {
    if (!DataInvalidAlarm.instance && adverId && deviceType && eventType) {
      DataInvalidAlarm.instance = new DataInvalidAlarm(adverId, deviceType, eventType);
    }

    return DataInvalidAlarm.instance;
  }

  static clearInstance() {
    DataInvalidAlarm.instance = null;
  }

  /**
   * 메시지에 포함시켜서 전송될 데이터를 단건으로 세팅
   * @param key JSON의 Key
   * @param value JSON의 Data
   */
  addExtraData(key: string, value: any): void {
    this.extraData.addData(key, value);
  }

  protected setMessage(): void {
    this.telegramMessage.setTitle(`${this.eventType} Invalid Data Alarm`);
    this.telegramMessage.setInfoMessage({'AdverId': this.adverId, 'DeviceType': this.deviceType, 'UserAgent': navigator.userAgent});
    this.telegramMessage.setBodyMessage(JSON.stringify(this.extraData.data));
    this.telegramMessage.setPageUrl();
  }

  send() {
    if (this.extraData && this.extraData.getDataLength() > 0) {
      super.send();
    }
  }
}