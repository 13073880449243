/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-21
 */
import {GeneralTree} from "../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "./Category";
import {GTNode} from "../lib/dataStructure/tree/general/GTNode";
import {MappedCategory} from "./MappedCategory";
import {StorableCategoryTree} from "./StorableCategoryTree";

/**
 * create on 2019-12-21.
 * <p> 저장할 수 있는 형태의 카테고리. </p>
 * <p> <code>GeneralTree</code>로 만들어진 카테고리 트리를 </p>
 * <p> <code>localStorage</code>에 저장할 수 있는 형태로 변환</p>
 * <p> {@link GeneralTree} and {@link Category} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class StorableCategory {
  /* 파싱이 완료되어 생성된 카테고리 트리 */
  private readonly categoryTree: GeneralTree<Category>;

  constructor(categoryTree: GeneralTree<Category>) {
    this.categoryTree = categoryTree;
  }

  /**
   * <code>localStorage</code>에 저장할 수 있는 형태로 변환하여 리턴한다.
   * @return {StorableCategoryTree}
   */
  getStorable(): StorableCategoryTree {
    /* 말단노드 묶음 */
    const leafNodeBunch: Array<GTNode<Category>> = this.emitLeafNodeFromTree(this.categoryTree);
    /* 트리의 전위순회 순서와 각 노드의 값을 갖는 맵 */
    const categoryMap: MappedCategory = this.getMappedCategory(this.categoryTree);
    /* 각 노드들의 path */
    const nodePaths: string[] = this.getPathsFromRootToLeaf(leafNodeBunch, categoryMap);

    return {
      'mappedCategory': categoryMap,
      'nodePaths': nodePaths
    };
  }

  /**
   * 트리로부터 모든 leaf node를 배열에 담아 가져온다.
   * @param {GeneralTree<Category>} categoryTree  카테고리 트리
   * @return {Array<GTNode<Category>>}  leaf node의 묶음
   */
  private emitLeafNodeFromTree(categoryTree: GeneralTree<Category>): Array<GTNode<Category>> {
    const leafNodes: Array<GTNode<Category>> = [];
    /* 전위순회를 이용해 자식 노드가 없는 노드를 탐색 */
    categoryTree.preorder(categoryTree.root, currentNode => {
      if (typeof currentNode.leftmostChild === 'undefined') {
        leafNodes.push(currentNode);
      }
    });

    return leafNodes;
  }

  /**
   * 트리가 갖는 노드 정보가 pre-order traversal 순서와 맵핑된 {@link MappedCategory} 객체를 반환.
   * @param {GeneralTree<Category>} categoryTree
   * @return {MappedCategory} 맵핑된 카테고리
   */
  private getMappedCategory(categoryTree: GeneralTree<Category>): MappedCategory {
    /* 전위순회 순서 */
    let traversalIndex = 0;
    const mappedCategory: MappedCategory = {};
    categoryTree.preorder(categoryTree.root, currentNode => {
      mappedCategory[traversalIndex++] = currentNode.value;
    });
    return mappedCategory;
  }

  /**
   * root에서 leaf 노드까지의 각 path를 배열로 생성.
   * @param {Array<GTNode<Category>>} leafNodes 말단노드 묶음
   * @param {MappedCategory[]} mappedCategory 맵핑된 카테고리
   * @return {string[]} path를 담고 있는 배열
   */
  private getPathsFromRootToLeaf(leafNodes: Array<GTNode<Category>>, mappedCategory: MappedCategory): string[] {
    const nodeLinkage: string[] = [];

    /* 각 leaf node까지의 path를 탐색 */
    leafNodes.forEach((leafNode: GTNode<Category>) => {
      const ancestors: string[] = [];
      let parentNode: GTNode<Category> | undefined = leafNode;

      while (parentNode) {
        for (const traversalIndex in mappedCategory) {
          if (mappedCategory.hasOwnProperty(traversalIndex)
              && (mappedCategory[traversalIndex].code === parentNode!.value.code
              && mappedCategory[traversalIndex].parentCode === parentNode!.value.parentCode)) {
            ancestors.push(traversalIndex);
            break;
          }
        }

        parentNode = parentNode.parent;
      }

      /* 조상 노드가 앞에 위치하도록 순서를 반전. */
      nodeLinkage.push(ancestors.reverse().join(':'));
    });

    return nodeLinkage;
  }
}
