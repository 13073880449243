/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';
import { StringUtil } from '../../../lib/common/StringUtil';
import { Value } from '../../../lib/value/Value';
import { GlobalVariables } from '../../../types/GlobalVariables';
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { JsonObject } from '../../../lib/json/JsonObject';
import { QueryStringer } from '../../../lib/url/QueryStringer';

/**
 * create on 2020-03-09.
 * <p> Ksm67929 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Ksm67929Dataparser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    try {
      if(this.isMobile(this.commandOptions)){
        return super.getShopCollectData();
      } else {
        return this.getWebShopCollectData();
      }
    } catch (error) {
      return super.getShopCollectData();
    }
  }

  protected getPayConvDataFromProductPage(): {} {
      try {
        if(this.isMobile(this.commandOptions)){
          return super.getPayConvDataFromProductPage();
        } else {
          return this.getWebPayConvDataFromProductPage();
        }
      } catch (error) {
        return super.getPayConvDataFromProductPage();
      }
  }

  protected getWebShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': (document.querySelector('input[name="branduid"]') as HTMLInputElement)!.value,
      'productName': this.getProductName(),
      'price': document.querySelector('span.mk_price') ? document.querySelector('span.mk_price')!.textContent!.trim().replace(/\D/g, '') : 0,
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('span.detail_price strong') ? document.querySelector('span.detail_price strong')!.childNodes[0]!.textContent!.trim().replace(/\D/g, '') : 0,
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'] ? 'Y': 'N',
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('td#mk_center tbody > form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('td#mk_center > table > tbody > tr > td > table > tbody > tr > td > table > tbody > tr > td[align="center"] > table > tbody >tr > td > font span[title]')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('td#mk_center > table > tbody > tr > td > table > tbody > tr > td > table > tbody > tr > td[align="center"] > table > tbody >tr > td > a[href^="/shop"]')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelectorAll('td#mk_center > table > tbody > tr > td > table > tbody > tr > td > table > tbody > tr > td[align="center"] > table:nth-child(2) tr:nth-child(2) td')[0]!.textContent!.split('=')[1].replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const price = document.querySelector('span.mk_price')
      ? NumberUtil.parseInteger(document.querySelector('span.mk_price')!.textContent!.trim().replace(/\D/g, '')) : 0;
    const dcPrice = document.querySelector('span.detail_price strong')
      ? NumberUtil.parseInteger(document.querySelector('span.detail_price strong')!.childNodes[0]!.textContent!.trim().replace(/\D/g, '')) : 0;
    parsedProducts.push({
      'productCode': (document.querySelector('input[name="branduid"]') as HTMLInputElement)!.value,
      'productName': this.getProductName(),
      'price': price,
      'productUrl': window.location.href,
      'dcPrice': dcPrice,
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'] ? 'Y': 'N',
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("goods_amount")
          ? document.getElementById("goods_amount")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseInteger(this.getTotalQty()) * (dcPrice != 0 ? dcPrice : price),
      'totalQty': this.getTotalQty()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('table#orderendtable > tbody > tr:nth-child(6) > td > table > tbody > tr > td > table > tbody > tr > td > font > b > a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('table#orderendtable > tbody > tr:nth-child(6) > td > table > tbody > tr > td > table > tbody > tr > td > font > b > a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('table#orderendtable > tbody > tr:nth-child(6) > td > table > tbody > tr > td > table > tbody > tr[bgcolor="#ffffff"] > td:nth-child(3)')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('table#orderendtable > tbody > tr:nth-child(6) > td > table > tbody > tr > td > table > tbody > tr[bgcolor="#ffffff"] > td:nth-child(4)')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('strong#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getProductName(): string {
    try {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
            : document.querySelector('span.original')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getTotalQty(): string {
    try {
      if (document.getElementById("goods_amount")) {
        return document.getElementById("goods_amount")!['value'].trim();
      } else {
        return '1';
      }
    } catch (e) {
      return super.getTotalQty();
    }
  }

  /**
   * MakeShop은 서브 카테고리가 없는 경우 mcode가 000으로 자동 매핑 됨 이에 관롼 메소드
   */
  protected getCategoryCode(): string[] {
    let mcode: HTMLInputElement | null = document.querySelector('input[name="mcode"]')! as HTMLInputElement;
    let xcode: HTMLInputElement | null = document.querySelector('input[name="xcode"]')! as HTMLInputElement;
    let mcodeValue: string = "";
    let xcodeValue: string = "";

    if (mcode != null) {
      if (mcode.value === '000') {
        mcodeValue = xcode.value;
      } else {
        xcodeValue = xcode.value;
        mcodeValue = mcode.value;
      }
    } else {
      mcodeValue = xcode.value;
    }

    return [mcodeValue, xcodeValue];
  }
}
