/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Config } from '../config/Config';
import { CommonTracer } from '../CommonTracer';
import { Converter } from '../Converter';
import { PayConverter } from '../PayConverter';
import { ShopCollector } from '../ShopCollector';
import { EventType } from '../../../types/GlobalEnums';
import { PayConverterConfig } from '../config/PayConverterConifg';
import { CartCollector } from '../CartCollector';
import { WishCollector } from '../WishCollector';
import { Transmitter } from './Transmitter';
import { DataParser } from '../../../dataParser/DataParser';
import { Tester } from '../Tester';
import { InvalidValueError } from '../../../error/InvalidValueError';
import { ReviewCollector } from '../ReviewCollector';
/**
 * create on 2019-08-21.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class TransmitterFactory {
  private constructor() {}

  static createTransmitter (
    eventType: EventType,
    config: Config,
    dataParser: DataParser,
    options?: {}
  ): Transmitter {
    switch (eventType) {
      case EventType.COMMON:
        return new CommonTracer(eventType, config, dataParser);
      case EventType.COLLECT:
        return new ShopCollector(eventType, config, dataParser);
      case EventType.CART:
        return new CartCollector(eventType, config, dataParser);
      case EventType.WISH:
        return new WishCollector(eventType, config, dataParser);
      case EventType.CONVERSION:
        return config instanceof PayConverterConfig
          ? new PayConverter(eventType, config, dataParser, options)
          : new Converter(eventType, config, dataParser, options);
      case EventType.REVIEW:
        return new ReviewCollector(eventType, config, dataParser);
      case EventType.TEST:
        return new Tester(eventType, config, dataParser);
      default:
        throw new InvalidValueError('eventType', eventType);
    }
  }
}
