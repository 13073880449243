/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-01
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { EventUtil } from "../../../lib/event/EventUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-01
 * <p>  medicube </p>
 * <p> {@Cafe24SmartDataParser } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class MedicubeDataParser extends Cafe24SmartDataParser {

  protected getCommonTraceData(): JsonObject {

    if(super.isBasketPage()||super.isProductPage()){
      if(this.isMobile(this.commandOptions)){
        this.mobileCustomNpayCall();
        }else{
          this.webCustomNpayCall();
        }
    }
    return super.getCommonTraceData();
  }


  private mobileCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector("div.go_member a#npay_gopay")!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'M', 'paySys': 'naverPay' }
      ]);
    });
  }
  private webCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector("div.go_member a#npay_chk2")!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    });
  }

}