/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-05
 */
/**
 * create on 2019-11-05.
 * <p> 리소스를 찾을 수 없는 경우에 대한 에러 처리 </p>
 * <p> {@link Error} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ResourceNotFoundError extends Error {
  constructor(resourceName?: string, resourcePath?: any) {
    let message: string;
    if (resourceName !== undefined) {
      message = `${resourceName} has not found`;

      if (resourcePath !== undefined) {
        message += ` from ${resourcePath}`;
      }
    } else {
      message = 'Resource not found.';
    }

    super(message);
    this.name = 'ResourceNotFoundError';
  }
}
