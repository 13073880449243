/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-11-27
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-27.
 * <p> dorocyshop 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class IppngirlDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    const productPrice = this.getProductPrice();

    jsonObj.replaceData('price', NumberUtil.parseNumber(productPrice['price']));
    jsonObj.replaceData('dcPrice', NumberUtil.parseNumber(productPrice['dcPrice']));

    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    const productPrice = this.getProductPrice();

    jsonObj['price'] = productPrice['price'];
    jsonObj['dcPrice'] = productPrice['dcPrice'];

    return jsonObj;
  }

  protected getPayConvDataFromBasketPage(): {} {
    let convData = super.getPayConvDataFromBasketPage();
    const product: Array<{}> = convData['product'];

    let basketPrice = this.getBasketPrice();

    for (let i = 0; i < product.length; i++) {
      product[i]['price'] = basketPrice['productPrice'][i]['price'];
      product[i]['dcPrice'] = basketPrice['productPrice'][i]['dcPrice'];
    }

    convData['totalPrice'] = basketPrice['totalPrice'];

    return convData;
  }

  private getProductPrice(): {} {
    const price = document.querySelector('div.table-opt > table > tbody > tr > td.price.sell_price > div.tb-left')
      ? document.querySelector('div.table-opt > table > tbody > tr > td.price.sell_price > div.tb-left')!.textContent!.trim()
      : document.querySelector('div.price_container > p > span.shopdetailInfoCont > del')!.textContent!.trim();

    const dcPrice = document.querySelector('div.table-opt > table > tbody > tr:nth-child(4) > td > div.prd-price > span.stp_discount_price')
      ? document.querySelector('div.table-opt > table > tbody > tr:nth-child(4) > td > div.prd-price > span.stp_discount_price')!.textContent!.trim()
      : document.querySelector('div.price_container > p.shopdetailInfoDiscount > span.shopdetailInfoCont > em')!.textContent!.trim();

    return {
      'price': NumberUtil.parseNumber(price),
      'dcPrice': NumberUtil.parseNumber(dcPrice)
    }
  }

  private getBasketPrice(): {} {
    const productPrice: Array<{}> = [];
    const basketProducts = 0 < document.querySelectorAll('div.page-body > div.table-cart > table > tbody tr')!.length
                          ? document.querySelectorAll('div.page-body > div.table-cart > table > tbody tr')
                          : document.querySelectorAll('#basket > ul.not_provider li');

    for (let i = 0; i < basketProducts.length; i++) {
      const price = basketProducts[i].querySelector('div.tb-price > span > strike.ori_prc')
                  ? basketProducts[i].querySelector('div.tb-price > span > strike.ori_prc')!.textContent!.trim()
                  : basketProducts[i].querySelector('figcaption.basketRight > p.SMSbasketPrice')!.textContent!.split(':')[1]!.trim();

      const dcPrice = basketProducts[i].querySelector('div.tb-price > span')
                    ? basketProducts[i].querySelector('div.tb-price > span')!.childNodes[2]!.textContent!.trim()
                    : price;

      productPrice.push({
        'price': price,
        'dcPrice': dcPrice
      })
    }

    const totalPrice = document.querySelector('div.table-cart > table > tfoot > tr > td > div.tb-right > strong')
                    ? document.querySelector('div.table-cart > table > tfoot > tr > td > div.tb-right > strong')!.textContent!.trim()
                    : document.querySelector('div.price-total-info-bottom > p > span.txt-price')!.textContent!.trim();

    return {
      'productPrice': productPrice,
      'totalPrice': NumberUtil.parseNumber(totalPrice)
    }
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const product = jsonObj.getDataByName('product');

    const orderedProducts = document.querySelectorAll('div.table-cart > table > tbody tr');

    for (let i = 0; i < product.length; i++) {
      product[i]['price'] = NumberUtil.parseNumber(orderedProducts[i]!.querySelector('td:nth-child(5)')!.textContent!.trim());
    }

    jsonObj.replaceData('product', product);

    return jsonObj;
  }
}

