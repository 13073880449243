/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-16
 */
/**
 * create on 2020-04-16.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class StorageItemNotFoundError extends Error {
  constructor(storageKey: string, keyDescription: string) {
    super();
    this.name = 'StorageItemNotFoundError';
    this.message = `Could not found for key "${storageKey}", ${keyDescription}`;
  }
}
