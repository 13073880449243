/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-20
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-04-20
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class MakemichicDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if(this.isMobile(this.commandOptions)){
      const parsedProducts: Array<{}> = [];
      const parsedTotalPrice: number = this.getParsedTotalPrice();
      let totalQty = 0;
  
      for (let i = 0; i < document.querySelectorAll('span[tag="i"]').length; i++) {
       
        let qty = NumberUtil.parseInteger(document.querySelectorAll('span[tag="q"]')[i]!.textContent!);

        let rawPrice =NumberUtil.parseInteger(document.querySelectorAll('span[tag="p"]')[i]!.textContent!);

        totalQty += qty;

        parsedProducts.push({
          'productCode': document.querySelectorAll('span[tag="i"]')[i].textContent,
          'productName': document.querySelectorAll('span[tag="t"]')[i].textContent,
          'qty': document.querySelectorAll('span[tag="q"]')[i].textContent,
          'price': rawPrice/qty
        });
        }
        const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': (document.querySelector('input#order_id') as HTMLInputElement).value,
        'totalPrice': NumberUtil.parseNumber(document.querySelector('#mCafe24Order  div.totalPay.gBlank10 > strong')!.textContent!.replace(/\D/g,'')),
        'totalQty': totalQty
      };
        return new JsonObject(parsedData);
    }
    return super.getConversionData_prev();
  }
}
