/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-09-10
 */
import { AdverConfigJsonMapper, } from '../../../adverConfigJsonMapper/AdverConfigJsonMapper';
import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";


/**
 * create on 2020-09-10.
 * <p> beautybetter3 광고주 </p>
 * <p> ildongmall.co.kr 도메인을 사용하는 광고주에서 카테고리마다 특정 id로 상품 수집을 원함</p>
 * <p> trello : https://trello.com/c/mWUYDqD9</p>
 * <p> {@link GodomallRentDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Beautybetter3DataParser extends GodomallRentDataParser {
  private readonly NONE: string = 'none';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    const parsedJson: JsonObject = super.getWebShopCollectData(formElement);
    parsedJson.replaceData('adverId', this.getAdverId(formElement));
    parsedJson.addData('siteCode', this.getSiteCode(formElement));
    return parsedJson;
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    const parsedJson: JsonObject = super.getMobileShopCollectData(formElement);
    parsedJson.replaceData('adverId', this.getAdverId(formElement));
    parsedJson.addData('siteCode', this.getSiteCode(formElement));
    return parsedJson;
  }

  /**
   * 광고주 ID를 결정하여 리턴
   * @param {HTMLFormElement} formElement - form 태그
   * @return {string} - 결정된 id
   */
  private getAdverId(formElement: HTMLFormElement): string {
    const brandCd: string = formElement!['brandCd'].value;
    const cateCd: string = formElement!['cateCd'].value;
    const gqlabID: string = 'gqlab';

    if (this.isGqlab(brandCd)) return gqlabID;
    if (this.isBeautybetter3(brandCd, cateCd)) return this.adverId;
    return this.NONE;
  }

  /**
   * 광고주 ID를 결정하여 리턴
   * @param {HTMLFormElement} formElement - form 태그
   * @return {string} - 결정된 siteCode(gqlab이 아닐경우 undefined반환해 기본값(기존 아이디의 사이트코드)으로 세팅)
   */
  private getSiteCode(formElement: HTMLFormElement): string {
    const brandCd: string = formElement!['brandCd'].value;
    const cateCd: string = formElement!['cateCd'].value;
    const gqlabSiteCode: string = '5e17ad5b3e50af09fc601ced81db1007';

    if(this.isGqlab(brandCd)) return gqlabSiteCode;
    if(this.isBeautybetter3(brandCd, cateCd)) return AdverConfigJsonMapper.getInstance(this.adverId).getSiteCode();
    return this.NONE;
  }

  /**
   * id를 <code>gqlab</code>으로 해야 하는지.
   * @param {string} brandCd  - form 태그에서 가져온 brandCd 값
   * @return {boolean}
   */
  private isGqlab(brandCd: string): boolean {
    return brandCd === '001';
  }

  /**
   * id를 <code>beautybetter3</code>으로 해야 하는지.
   * @param {string} brandCd  - form 태그에서 가져온 brandCd 값
   * @param {string} cateCd   - form 태그에서 가져온 cateCd 값
   * @return {boolean}
   */
  private isBeautybetter3(brandCd: string, cateCd: string): boolean {
    return cateCd === '019' || brandCd === '003';
  }
}
