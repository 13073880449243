/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-07-14
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-07-14
 * <p> Fastfive - fastfive.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export abstract class FastfiveDataParser extends SelfHostedDataParser {
  protected getShopCollectData(): JsonObject {
    try {
      const jsonObject = super.getShopCollectData();
      jsonObject.addData('productUrl',  window[this.ENP_OBJECT_NAME]['collect']['productUrl'] ? window[this.ENP_OBJECT_NAME]['collect']['productUrl'] : window.location.href);
      return jsonObject;
    } catch (e) {
      return super.getShopCollectData();
    }
  }
}
