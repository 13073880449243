/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { EventType } from '../../types/GlobalEnums';
import { Config } from './config/Config';
import { Punycode } from '../../lib/codec/Punycode';
import { Utf8 } from '../../lib/codec/Utf8';
import { Base64 } from '../../lib/codec/Base64';
import { Transmitter } from './core/Transmitter';
import { DataParser } from '../../dataParser/DataParser';

/**
 * create on 2019-09-10.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Tester extends Transmitter {
  protected readonly _punycode: Punycode;
  protected readonly _utf8: Utf8;
  protected readonly _base64: Base64;

  constructor(eventType: EventType, config: Config, dataParser: DataParser) {
    super(eventType, config, dataParser);
    this._punycode = new Punycode();
    this._utf8 = new Utf8();
    this._base64 = new Base64();
  }

  protected preSend(): void {}
}
