/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-06-14
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from '../../../lib/json/JsonObject';
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2023-06-14
 * <p> galleriaucup - https://elva.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ElvakoreaDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    const superData : JsonObject = super.getConversionData();
    superData.data['ordCode'] = new QueryStringer(window.location.search.substring(1)).getParam('orderNo');
    superData.data['totalQty'] = 1;
    return superData;
  }
}