/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-04-13
 */
import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GlobalVariables } from '../../../types/GlobalVariables';
import { QueryStringer } from '../../../lib/url/QueryStringer';
import { NumberUtil } from '../../../lib/common/NumberUtil';


/**
 * create on 2022-04-13.
 *
 * @version 1.0
 * @author sjchun
 */
export class Recipe10000DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (document.querySelector('meta[property="og:title"]') as HTMLMetaElement).content,
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': (document.querySelector('meta[property="og:image"]') as HTMLMetaElement).content,
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (document.querySelector('meta[property="og:title"]') as HTMLMetaElement).content,
      'price': priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement)
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'] && NumberUtil.parseRationalNumber(formElement!['set_total_price'].value) > 0
        ? formElement!['set_total_price'].value
        : (NumberUtil.parseRationalNumber(parsedProducts[0]['dcPrice'] > 0 ? parsedProducts[0]['dcPrice'] : parsedProducts[0]['price'])) * NumberUtil.parseNumber(parsedProducts[0]['qty']),
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }
}
