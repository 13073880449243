/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-07-01
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2019-11-12.
 * <p> 트윈시스 광고주 DataParser </p>
 * <p> 기존 메이크샵 구조와 많이 다름 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class TwinsisDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const xcode = (document.querySelector('input[name="xcode"]') as HTMLInputElement);
    const mcode = (document.querySelector('input[name="mcode"]') as HTMLInputElement);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('input[name="branduid"]')!['value'],
      'productName': ( this.isMobile(this.commandOptions)
          ? document.querySelector('div#detail-item h2:nth-child(1)')
          : document.querySelector('font center b') )!.textContent!.trim(),
      'price': ( this.isMobile(this.commandOptions)
          ? document.querySelector('span#pricevalue')
          : document.querySelector('span#mk_price_value') )!.textContent!.trim().replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': ( this.isMobile(this.commandOptions)
          ? document.querySelector('span#pricevalue')
          : document.querySelector('span#mk_price_value') )!.textContent!.trim().replace(/\D/g, ''),
      'soldOut': ( document.querySelector('div.is_soldout, img[src*="soldout"]')) ? 'Y' : 'N',
      'imageUrl': (( this.isMobile(this.commandOptions)
          ? document.querySelector('div.items img')
          : document.querySelector('img#lens_img') ) as HTMLImageElement).src,
      'topCategory': xcode ? xcode.value.trim() : '',
      'firstSubCategory': mcode ? mcode.value.trim() : '',
      'secondSubCategory': '',
      'thirdSubCategory': ''
    };

    return new JsonObject(parsedData);
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('table#orderendtable table table a[href*="branduid"]').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('table#orderendtable table table a[href*="branduid"]')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('table#orderendtable table table tr[bgcolor="#ffffff"] td:nth-child(3)')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('table#orderendtable table table tr[bgcolor="#ffffff"] td:nth-child(4) font')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value,
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('dl.item.clearfix a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('dl.item.clearfix a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('dl.item.clearfix dd')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': this.getMobildProductName(productAnchorElem),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': document.querySelector('strong#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  private getMobildProductName(element: Element): string {
    let productName = '';
    if(element.querySelector('span') != null && element.querySelector('span') != undefined){
      productName = element!.textContent!.replace(element.querySelector('span')!.textContent!.trim(), '').trim();
    } else {
      productName = productName = element!.textContent!.trim();
    }
    return productName;
  }
}
