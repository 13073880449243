/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-27
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-10-27
 * <p> nanajean(https://www.nanajean.co.kr/) </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NanajeanDataParser extends MakeshopD4DataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProductArr: Array<{}> = [];
    let totalQty = 0;

    const parsedProductNameArr: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.order-list > table > tbody  td:nth-child(2) a');
    for (let i = 0; i < parsedProductNameArr.length; i++) {
      const qty = NumberUtil.parseInteger(document.querySelectorAll('div.order-list > table > tbody  td:nth-child(3) div')[0].textContent!.replace(/\D/g, '').trim());
      parsedProductArr.push({
        'productCode': new QueryStringer(parsedProductNameArr[i].search.substring(1)).getParam('branduid'),
        'productName': parsedProductNameArr[i].textContent,
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('div.order-list > table > tbody  td:nth-child(4) div')[i].textContent!.trim()),
      });

      totalQty += qty;
    }

    jsonObj.data['product'] = parsedProductArr;
    jsonObj.data['totalQty'] = totalQty;
    return jsonObj;
  }

  protected getImageUrl(): string {
    return this.isMobile(this.commandOptions)
      ? (document.querySelector('main#shopdetail div.shopdetailInfoTop > img') as HTMLImageElement).src
      : (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src
  }
}