/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-01
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-12-01
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */

export class Imfat1DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (!this.isMobile(this.commandOptions)) {
        if(document.querySelector('span#span_product_price_custom')==null){
          jsonObj.data['price'] = document.querySelector('strong#span_product_price_text')!.textContent!.replace(/\D/g,'');
          jsonObj.data['dcPrice'] = 0;
        }
      }
    return jsonObj;
  }

}


