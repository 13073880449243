/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-16
 */
import {CategoryParser} from "../CategoryParser";
import {AJAXer} from "../../lib/ajax/AJAXer";
import {GTNode} from "../../lib/dataStructure/tree/general/GTNode";
import {GeneralTree} from "../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../Category";
import {HostingType} from "../../types/GlobalEnums";
import {StringUtil} from "../../lib/common/StringUtil";

/**
 * create on 2019-12-16.
 * <p> 카페24 광고주 카테고리 파싱 </p>
 * todo: 카페24 스크립트 자동화가 완전 적용되면 이 클래스를 이용하지 않도록 한다 (카페24의 트래픽을 이용하기 때문)
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Cafe24SmartCategoryParser extends CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  /**
   * 광고주의 페이지에서 카테고리를 파싱 후 트리를 생성.
   * 데이터 저장 기간이 만료된 경우에만 새로 파싱한다.
   * @param {(tree: GeneralTree<Category>) => void} callback  파싱으로 생성된 트리를 외부에서 접근하는 콜백
   * @param {boolean} hasExpired  데이터 만료 여부
   */
  protected parseAndMakeTree(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    if (hasExpired) {
      /* API 호출하여 카테고리 정보를 가져온다 */
      AJAXer.getInstance().get('//' + window.location.host + '/exec/front/Product/SubCategory', (xhr, response, hasTransmitted) => {
        if (hasTransmitted && callback && response) {
          /* 로우 데이터 */
          const rawCategories: Array<{}> = this.parseResponse(response);

          /* 각 카테고리를 노드로 변환 */
          const nodes: Array<GTNode<Category>> = this.createNodesFromRawData(rawCategories);

          /* 노드를 이용해 트리를 생성 */
          const tree: GeneralTree<Category> = this.getTree(nodes);

          /* 외부에서 콜백으로 트리에 접근 */
          callback(tree);
        }
      });
    }
  }

  protected createCategoryHierarchyTree(callback: Function): GeneralTree<Category> {
    return new GeneralTree<Category>({code: '', value: '', parentCode: ''});
  }

  /**
   * Response에 담긴 로우 데이터를 디코딩하여 객체로 이루어진 배열로 파싱.
   * <code>decodeURIComponent()</code>로 디코딩을 먼저 시도하고,
   * 에러가 발생하면 <code>unescape()</code>로 디코딩을 한다.
   * <b>NOTE: </b>두 함수에서 디코딩에 사용하는 문자열이 다르기 때문.
   * @param response      - Response
   * @return {Array<{}>}  - 파싱 결과
   */
  private parseResponse(response: any): Array<{}> {
    try {
      return JSON.parse(decodeURIComponent(response));
    } catch (e) {
      return JSON.parse(unescape(response));
    }
  }

  /**
   * 로우 데이터를 이용해 각 카테고리에 해당하는 노드 생성.
   * 에러 발생시 빈 배열을 리턴한다. (response 데이터가 잘못된 경우 에러가 발생할 수 있다)
   * <code>value</code>에는 html 코드를 제거한 내용을 넣는다
   * @param {Array<{}>} rawCategories 로우 데이터
   * @return {Array<GTNode<Category>>}  생성된 노드로 이루어진 배열
   */
  private createNodesFromRawData(rawCategories: Array<{}>): Array<GTNode<Category>> {
    const categoryNodes: Array<GTNode<Category>> = [];

    try {
      rawCategories.forEach((rawCategory: {}) => {
        categoryNodes.push(new GTNode<Category>({
          code: rawCategory['cate_no'].toString(),
          value: StringUtil.getNonHTMLStr(rawCategory['name'].toString()),
          parentCode: rawCategory['parent_cate_no'].toString()
        }));
      });

      return categoryNodes;
    } catch (e) {
      return [];
    }
  }

  /**
   * 각 부모와 자식 노드의 path를 연결.
   * @param {Array<GTNode<Category>>} nodes 생성된 노드 배열
   */
  private linkParentAndChild(nodes: Array<GTNode<Category>>): void {
    nodes.forEach((node: GTNode<Category>) => {
      const parentCode: string = node.value.parentCode;
      const parentNode: GTNode<Category> = nodes.filter((rawCate: GTNode<Category>) => rawCate.value.code === parentCode)[0];
      if (parentNode) {
        parentNode.insertNext(node);
      }
    });
  }

  /**
   * 모든 조상 노드를 탐색하여 반환.
   * @param {Array<GTNode<Category>>} nodes 생성한 노드의 배열
   * @return {Array<GTNode<Category>>}  탐색된 조상 노드로 이루어진 배열
   */
  private getAncestorNodes(nodes: Array<GTNode<Category>>): Array<GTNode<Category>> {
    const ancestorNodes: Array<GTNode<Category>> = [];
    this.linkParentAndChild(nodes);

    /* 모든 노드를 순회하여 부모 노드가 없는 노드들을 찾는다 */
    nodes.forEach((node: GTNode<Category>) => {
      let currNode: GTNode<Category> = node;
      while (currNode.parent) {
        currNode = currNode.parent;
      }

      /* 같은 조상 노드를 추가하지 않도록 체크 */
      if (ancestorNodes.filter((ancestor: GTNode<Category>) => ancestor.value.code === currNode.value.code).length === 0) {
        ancestorNodes.push(currNode);
      }
    });

    return ancestorNodes;
  }

  /**
   * 각 path가 연결된 노드들로 구성된 트리를 생성.
   * 트리의 루트 노드는 임의로 생성한 값을 갖는다. - <code>{code: 'ROOT', value: '', parentCode: ''}</code>
   * @param {Array<GTNode<Category>>} nodesWithPath path가 설정된 노드 배열
   * @return {GeneralTree<Category>}  트리
   */
  private getTree(nodesWithPath: Array<GTNode<Category>>): GeneralTree<Category> {
    const tree: GeneralTree<Category> = new GeneralTree<Category>({code: 'ROOT', value: '', parentCode: ''});
    this.getAncestorNodes(nodesWithPath).forEach((rootNode: GTNode<Category>) => {
      tree.createChildByNode(rootNode);
    });

    return tree;
  }
}