/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Parameter} from './Parameter';
import {Utf8} from '../codec/Utf8';
import URLParse from 'url-parse';

/**
 * create on 2019-07-30.
 * <p> URL의 Query string을 다루는 클래스 </p>
 * <p> {@link Parameter} and {@link Utf8} 관련 클래스 </p>
 * TODO 새로 생성한 메소드 검증 필요
 * @version 1.0
 * @author sghwang
 */
export class QueryStringer {
  /* URL의 query string */
  private _queryString: string | undefined;

  /**
   * 생성자.
   * 인자 <code>qryString</code>이 빈 문자열이면 <code>undefined</code>로 대입
   * @param {string | undefined} qryString - query string
   */
  constructor(qryString?: string | undefined) {
    this._queryString = qryString ? qryString!.replace('?', '') : undefined;
  }

  get queryString(): string | undefined {
    return this._queryString;
  }

  /**
   * 멤버 필드 <code>_queryString</code>에 대한 Setter.
   * 인자 <code>qryString</code>이 빈 문자열이면 <code>undefined</code>로 대입
   * @param {string | undefined} qryString - query string
   */
  set queryString(qryString: string | undefined) {
    this._queryString = typeof qryString === 'string' && qryString.length === 0
        ? undefined
        : qryString;
  }

  /**
   * query string이 비어 있는지 확인
   * <pre>
   *   // queryString = 'id=user123&pw=1q2w3e'
   *   isEmpty(); // --> false
   *
   *   // queryString = ''
   *   isEmpty(); // --> true
   *
   *   // myQueryString = 'id=user123'
   *   isEmpty(myQueryString); // --> false
   *
   *   // myQueryString = ''
   *   isEmpty(myQueryString); // --> true
   * </pre>
   * @param {string | undefined} [qryString = this._queryString] - query string
   * @return {boolean}
   * - <code>true</code>: 인자 <code>qryString</code> 또는 멤버 필드 <code>_queryString</code>이 빈 문자열이거나 <code>undefined</code>인 경우
   * - <code>false</code>: 그렇지 않은 경우
   */
  isEmpty(qryString: string | undefined = this._queryString): boolean {
    return !qryString;
  }

  /**
   * query string이 비어 있지 않은지 확인.
   * 내부적으로 private method인 {@link isEmpty}를 호출하여 그 결과에 대한 <code>NOT</code> 연산자 결과를 리턴한다.
   * <pre>
   *   // queryString = 'id=user123&pw=1q2w3e'
   *   isNotEmpty(); // --> true
   *
   *   // queryString = ''
   *   isNotEmpty(); // --> false
   *
   *   // myQueryString = 'id=user123'
   *   isNotEmpty(myQueryString); // --> true
   *
   *   // myQueryString = ''
   *   isNotEmpty(myQueryString); // --> false
   * </pre>
   * @param {string | undefined} [qryString = this._queryString] - query string
   * @return {boolean}
   * - <code>true</code>: 비어 있지 않음
   * - <code>false</code>: 비어 있음
   */
  isNotEmpty(qryString: string | undefined = this._queryString): boolean {
    return !this.isEmpty(qryString);
  }

  /**
   * 입력한 URL로 Query string을 추출하여 초기화된 인스턴스를 생성.
   * @param {string} url
   * @return {QueryStringer}
   */
  static createInstanceByUrl(url: string): QueryStringer {
    return new QueryStringer(QueryStringer.extractQryStringFromUrl(url));
  }

  /**
   * 파라미터 객체의 내용을 query string으로 변환하여 결과를 리턴.
   * 객체가 비었거나 exception 발생시 <code>undfined</code>를 리턴한다.
   * query string으로 변환시 파라미터의 value를 UTF-8로 인코딩한다.
   * <pre>
   *   // paramObj = { id: 'user123', pw: '!Q@W#E$R' }
   *   convertObjToQryString(paramObj); // --> 'id=user123&pw=!Q%40W%23E%24R'
   *
   *   // paramObj = {}
   *   convertObjToQryString(paramObj); // --> undefined
   *
   *   // paramObj = undefined
   *   convertObjToQryString(paramObj); // --> undefined
   * </pre>
   * @param {{}|undefined} paramObj - 파라미터들을 담고 있는 객체
   * @return {string | undefined} - query string 변환 결과 <p>빈 객체 또는 exception 발생시 <code>undefined</code></p>
   */
  convertObjToQryString(paramObj: {} | undefined): string | undefined {
    try {
      if (typeof paramObj === 'object' && Object.keys(paramObj).length > 0) {
        const paramArr = new Parameter(paramObj).getParamsAsArr();
        const paramStrArr: string[] = [];
        const utf8 = new Utf8();

        for (let i = 0; i < paramArr.length; i++) {
          const field = utf8.encode(paramArr[i]['field']);
          const value = utf8.encode(paramArr[i]['value']);
          const qry = field + '=' + value;
          paramStrArr.push(qry);
        }

        return paramStrArr.join('&');
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  }

  /**
   * query string을 파라미터가 담긴 객체로 변환하여 그 결과를 리턴.
   * 객체로 변환시 파라미터의 value를 UTF-8로 디코딩한다.
   * <pre>
   *   // qryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   convertQryStringToObj(qryString);  // --> { id: 'user123', pw: '!Q@W#E$R' }
   *
   *   // qryString = ''
   *   convertQryStringToObj(qryString);  // --> throws Error!
   *
   *   // qryString = undefined
   *   convertQryStringToObj(qryString);  // --> throws Error!
   *
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   convertQryStringToObj();  // --> { id: 'user123', pw: '!Q@W#E$R' }
   * </pre>
   * @param {string} [qryString = this._queryString] - query string
   * @return {{}} - 파라미터가 담긴 객체
   * @throws Error - 인자값이 유효하지 않은 query string인 경우
   */
  convertQryStringToObj(qryString: string | undefined = this._queryString): {} {
    /* 인자값이 빈 문자열인 경우 Error를 강제로 발생시킨다 */
    if (this.isEmpty(qryString)) {
      throw new Error('Invalid query string');
    }

    try {
      const parameter = new Parameter();
      const utf8 = new Utf8();
      const paramArr: string[] = qryString!.split('&');

      for (let i = 0; i < paramArr.length; i++) {
        const field = paramArr[i].split('=')[0];
        const value = paramArr[i].split('=')[1];
        parameter.addParam(utf8.decode(field), utf8.decode(value));
      }

      return parameter.params;
    } catch (e) {
      throw new Error('Invalid query string');
    }
  }

  getParam(field: string): string {
    try {
      const paramObj = this.isEmpty() ? {} : this.convertQryStringToObj();
      const parameter = new Parameter(paramObj);
      return parameter.getParam(field);
    } catch (e) {
      return '';
    }
  }

  /**
   * 현재 query string에 특정 파라미터를 추가한다.
   * 파라미터를 추가할 때 value를 UTF-8로 인코딩한다.
   * 파라미터를 추가한 후에 멤버 필드 <code>_queryString</code>을 업데이트한다.
   * <code>replace</code>가 <code>true</code>인 경우 이미 존재하는 field의 value를 새로운 파라미터로 대체.
   * <code>replace</code>가 <code>false</code>인 경우 이미 존재하는 field의 value를 그대로 사용.
   * 현재의 query string이 비어 있거나 exception 발생시 파라미터를 추가하지 않음.
   * <pre>
   *   // this.queryString = ''
   *   addParamToQryString('name', 'sghwang');  // added. --> 'name=sghwang'
   *
   *   // this.queryString = undefined
   *   addParamToQryString('name', 'sghwang');  // added. --> 'name=sghwang'
   *
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   addParamToQryString('name', 'sghwang');  // added. --> 'id=user123&pw=!Q%40W%23E%24R&name=sghwang'
   *
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   addParamToQryString('id', 'sk8erBoy');  // replaced. --> 'id=sk8erBoy&pw=!Q%40W%23E%24R'
   *
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   addParamToQryString('id', 'sk8erBoy', false);  // not replaced. --> 'id=user123&pw=!Q%40W%23E%24R'
   * </pre>
   * @param {string} field - 추가될 파라미터의 field
   * @param {string} value - 추가될 파라미터의 value
   * @param {boolean} [replace = true] - 파라미터가 이미 존재하는 경우 value의 대체 여부
   */
  addParamToQryString(field: string, value: string, replace = true) {
    try {
      const paramObj = this.isEmpty() ? {} : this.convertQryStringToObj();
      const parameter = new Parameter(paramObj);
      parameter.addParam(field, value, replace);
      this.queryString = this.convertObjToQryString(parameter.params);
    } catch (e) {
      return;
    }
  }

  /**
   * 현재 query string에서 특정 파라미터를 제거한다.
   * 현재의 query string이 비어 있거나 exception 발생시 파라미터를 제거하지 않음.
   * 존재하지 않는 파라미터 입력시 아무런 행동을 하지 않음.
   * <pre>
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   removeParamFromQryString('id');  // removed. --> 'pw=!Q%40W%23E%24R'
   *
   *   // this.queryString = ''
   *   removeParamFromQryString('id');  // nothing happened. --> 'id=user123&pw=!Q%40W%23E%24R'
   *
   *   // this.queryString = undefined
   *   removeParamFromQryString('id');  // nothing happened. --> 'id=user123&pw=!Q%40W%23E%24R'
   *
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   removeParamFromQryString('name');  // nothing happened. --> 'id=user123&pw=!Q%40W%23E%24R'
   * </pre>
   * @param {string} field - 제거될 파라미터의 field
   */
  removeParamFromQryString(field: string) {
    try {
      if (this.isNotEmpty()) {
        const parameter = new Parameter(this.convertQryStringToObj());
        parameter.removeParam(field);
        this.queryString = this.convertObjToQryString(parameter.params);
      }
    } catch (e) {
      return;
    }
  }

  /**
   * 현재 query string에서 특정 파라미터를 변경한다.
   * 파라미터를 변경할 때 value를 UTF-8로 인코딩한다.
   * 현재의 query string이 비어 있거나 exception 발생시 파라미터를 변경하지 않음.
   * 존재하지 않는 파라미터 입력시 아무런 행동을 하지 않음.
   * <pre>
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   replaceParamInQryString('pw', '!@#$password');  // replaced. --> 'id=user123&pw=!%40%23%24password'
   *
   *   // this.queryString = 'id=user123&pw=!Q%40W%23E%24R'
   *   replaceParamInQryString('name', 'sghwang');  // nothing happened. --> 'id=user123&pw=!Q%40W%23E%24R'
   *
   *   // this.queryString = ''
   *   replaceParamInQryString('name', 'sghwang');  // nothing happened. --> undefined
   *
   *   // this.queryString = undefined
   *   replaceParamInQryString('name', 'sghwang');  // nothing happened. --> undefined
   * </pre>
   * @param {string} field - 제거될 파라미터의 field
   * @param {string} value - 제거될 파라미터의 value
   */
  replaceParamInQryString(field: string, value: string) {
    try {
      if (this.isNotEmpty()) {
        const parameter = new Parameter(this.convertQryStringToObj());
        parameter.replaceParam(field, value);
        this.queryString = this.convertObjToQryString(parameter.params);
      }
    } catch (e) {
      return;
    }
  }

  /**
   * TODO URLParse.query 타입이 객체이며, 버그를 유발한다. 사용하지 않는다면 추후에 삭제가 필요
   * URL로 부터 Query String 추출. 에러 발생시 빈 문자열 반환
   * @param {string} url  - URL
   * @return {string} - Query string
   */
  static extractQryStringFromUrl(url: string): string {
    const query = new URLParse(url).query.toString();
    try {
      return query.substring(query.indexOf('?') + 1);
    } catch (e) {
      return '';
    }
  }

  /**
   * URL에서 Query string을 찾아 Object로 변환
   * @param {string} url
   * @return {{}}
   */
  static convertUrlToQryStrObj(url: string): {} {
    return this.prototype.convertQryStringToObj(
      QueryStringer.extractQryStringFromUrl(url)
    );
  }
}
