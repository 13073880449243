import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-30
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-11-09.
 * <p> nuerx 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class NeurxDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const product = jsonObj.getDataByName('product');

    const orderedProducts = document.querySelectorAll('div.table-cart > table > tbody tr');

    for (let i = 0; i < orderedProducts.length; i++) {
      product[i]['price'] = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(5)')!.textContent!.trim());
    }

    jsonObj.replaceData('product', product);

    return jsonObj;
  }

}
