/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-09-22
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";


/**
 * create on 2020-09-22.
 * <p> Zenpia 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class ZenpiaDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.productInfoWrap > div.productInfo > div.productInfoImg > img')) {
        // PC WEB
        return (document.querySelector('div.productInfoWrap > div.productInfo > div.productInfoImg > img') as HTMLImageElement).src;
    } else {
        // Mobile
        return (document.querySelector('div.shopdetailInfoTop img') as HTMLImageElement).src;
    }
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const jsonObj = super.getMobileConversionData();
    const parsedProducts = jsonObj.getDataByName('product');

    for (let i = 0; i < parsedProducts.length; i++) {
        const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
        parsedProducts[i]['productName'] = productAnchorElem!.childNodes[0]!.textContent!.trim();
    }

    return jsonObj;
  }
}
