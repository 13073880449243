/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-23
 */
import {ResourceNotFoundError} from "../error/ResourceNotFoundError";
import {AdverConfigJsonMapper} from "../adverConfigJsonMapper/AdverConfigJsonMapper";
import {CategoryParser} from "./CategoryParser";

/**
 * create on 2019-12-23.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CategoryParserFactory {
  private constructor() {
  }

  static createCategoryParser(adverId: string, productCategoryCode: string): CategoryParser {
    const adverConfigJsonMapper: AdverConfigJsonMapper = AdverConfigJsonMapper.getInstance(adverId);
    const categoryParserClassInfo: {
      className: string;
      classSubPath: string;
    } = adverConfigJsonMapper.getCategoryParserClassInfo();

    const classSubPath: string = categoryParserClassInfo.classSubPath;
    const className: string = categoryParserClassInfo.className;

    try {

      return new (require(`${classSubPath + className}`)[className])(adverConfigJsonMapper.getHostingType(), productCategoryCode);
    } catch (e) {
      throw new ResourceNotFoundError('CategoryParser class', classSubPath + className);
    }
  }
}
