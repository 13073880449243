/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-29
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-07-29
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class VivacityDataParser extends GodomallRentDataParser {
  
  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    try{
      const jsonObj: JsonObject = super.getWebShopCollectData(formElement);
      jsonObj.data['topCategory'] = document.querySelector('.item_info_box .item_detail_tit p.brand-p')!.textContent!.trim();
      return jsonObj;
    }catch{
      return super.getWebShopCollectData(formElement);
    }
    
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    try{
      const jsonObj: JsonObject = super.getMobileShopCollectData(formElement);
      jsonObj.data['topCategory'] = document.querySelector('.detail_info_top p.brand')!.textContent!.trim();
      return jsonObj;
    }catch{
      return super.getMobileShopCollectData(formElement);
    }
  }
    
}