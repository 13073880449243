/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CommandType } from '../../../types/GlobalEnums';
import { NotSupportedError } from '../../../error/NotSupportedError';
import { CommandBuilder } from './CommandBuilder';
import { Command } from './Command';
import { CommandConfig } from '../config/CommandConfig';

/**
 * create on 2019-08-27.
 * <p> <code>Command</code> 객체 생성 </p>
 * <p> Factory 패턴 </p>
 * <p> {@link CommandConfig}, {@link CommandBuilder} {@link Command} and {@link Tracker} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommandFactory {
  private constructor() {}

  static createCommand(commandConfig: CommandConfig): Command {
    switch (commandConfig.commandType) {
      case CommandType.CREATE:
        return new CommandBuilder(commandConfig.commandType, commandConfig.eventType)
          .setAdverId(commandConfig.adverId)
          .setOptions(commandConfig.options)
          .setDataParser(commandConfig.dataParser)
          .build();
      case CommandType.SET:
        return new CommandBuilder(commandConfig.commandType, commandConfig.eventType)
          .setJsonData(commandConfig.jsonData)
          .build();
      case CommandType.SEND:
        return new CommandBuilder(commandConfig.commandType, commandConfig.eventType)
          .setAdverId(commandConfig.adverId)
          .setOptions(commandConfig.options)
          .setDataParser(commandConfig.dataParser)
          .setJsonData(commandConfig.jsonData)
          .build();
      default:
        throw new NotSupportedError(commandConfig.commandType);
    }
  }
}
