import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

export class MaycoopDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getWebPayConvDataFromBasketPage(): {} {
        const parsedProducts: Array<{}> = [];
        const productAmount = document.querySelectorAll('form[name="forms"]').length;
        let totalPrice = 0;
        let totalQty = 0;

        for (let i = 0; i < productAmount; i++) {
            const rawPrice = document.querySelectorAll('.cart-row > td:nth-of-type(5)')[i].textContent!.replace(/\D/g, "");
            const rawQty = (document.querySelectorAll('input[name="orgamount"]')[i] as HTMLInputElement).value;

            const price = NumberUtil.parseNumber(rawPrice);
            const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

            totalPrice += price;
            totalQty += qty;

            parsedProducts.push({
                'productCode': (document.querySelectorAll('input[name="branduid"]')[i] as HTMLInputElement).value,
                'productName': document.querySelectorAll('.table-item-name')[i].textContent!.trim(),
                'price': price / qty,
                'qty': qty
            });
        }

        return {
            'product': parsedProducts,
            'adverId': this.adverId,
            'totalPrice': this.getBasketTotalPrice(totalPrice),
            'totalQty': totalQty.toString()
        };
    }

    protected getImageUrl(): string {
        return (document.querySelector('#product-header .main-img > img') as HTMLImageElement).src;
    }

}