/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-03
 */

import {CrmController} from "../CrmController";
import {HostingType} from "../../../../types/GlobalEnums";

/**
 * create on 2022-01-03
 * <p> 아임웹 CRM에 관한 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ImwebCrmController extends CrmController {
  constructor() {
    super(HostingType.IMWEB);
  }

  isCrmAdvertiser(): boolean {
    return true;
  }
}
