/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-04
 */
import { Api } from "./Api";
import { ApiName } from "../../types/GlobalEnums";
import { AJAXer } from "../../lib/ajax/AJAXer";

/**
 * create on 2020-03-04.
 * <p> RTB 관련 API </p>
 * <p> API를 호출하여 response에 담긴 url을 호출 </p>
 * <p> {@link Api} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class IOrbApi extends Api {
  private readonly ajaxer: AJAXer;

  constructor(apiName: ApiName, adverId: string) {
    super(apiName, adverId);
    this.ajaxer = AJAXer.getInstance();
    this.ajaxer.options.timeout = 1500;
  }

  protected validateApi(): boolean {
    return true;
  }

  protected callApi(): void {
    this.ajaxer.get(this.apiInfo.apiUrl, (xhr, response, hasTransmitted) => {
      if (hasTransmitted) {
        try{
          const data: {
            status: boolean;
            redirectUrl: string;
            msg: string;
          } = JSON.parse(response);
  
          if (data['status'] && data['redirectUrl']) {
            this.callUrlByIframe(data['redirectUrl']);
          }
        }catch{}
      }
    });
  }

  private callUrlByIframe(url: string): void {
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.width = '0';
    iframe.height = '0';

    if (document.body) {
      document.body.appendChild(iframe);
    } else {
      const html = document.getElementsByTagName('html')[0];
      const body = document.createElement('body');
      html.appendChild(body);
      body.appendChild(iframe);
    }
  }
}
