/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-05
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-05.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class JunenjulyDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name=forms]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      let tr = document.querySelectorAll('.tbl.tbl_chk tbody tr')[i];
      const rawPrice = NumberUtil.parseNumber(tr.querySelector('.txt.sale span.num')!.textContent!);
      const rawQty = NumberUtil.parseNumber((document.querySelectorAll('input[name=orgamount]')[i] as HTMLInputElement).value);

      totalPrice += rawPrice;
      totalQty += rawQty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('.tbl.tbl_chk tbody input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': tr.querySelector('.txt_info')!.textContent!.trim(),
        'price': rawPrice / rawQty,
        'qty': rawQty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('.tbl.tbl_chk tbody tr').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      let tr = document.querySelectorAll('.tbl.tbl_chk tbody tr')[i];
      const rawPrice = NumberUtil.parseNumber(tr.querySelector('.txt.sale span.num')!.textContent!);
      const rawQty = NumberUtil.parseNumber((document.querySelectorAll('input[name=orgamount]')[i] as HTMLInputElement).value);

      totalPrice += rawPrice;
      totalQty += rawQty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('.tbl.tbl_chk tbody input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': tr.querySelector('.txt_info')!.textContent!.trim(),
        'price': rawPrice / rawQty,
        'qty': rawQty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }
}
