/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Config} from './Config';
import {Identification} from "../../ident/Identification";

/**
 * TODO 주석
 * create on 2019-08-05.
 * <p> 상품 수집과 관련된 Config </p>
 * <p> {@link Config} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ShopCollectorConfig extends Config {
  constructor(adverId: string, options?: {}) {
    super(adverId, options);
    this._baseUrl = this.domain+'/aggregate/mssg/product';
    this._url = this.protocol + this.baseUrl;
    this._callback = (
      xhr: XMLHttpRequest,
      response: any,
      hasTransmitted: boolean
    ) => {
      // xhr.onerror = () => {
      //   if (xhr.status == 0){
      //     AdBlockCtrl.getInstance().reSend(this.protocol, '/aggregate/mssg/product');
      //   }
      // }

      xhr.onload = () => {
        if(xhr.status === 200) {
          try {
            const resData = JSON.parse(response);
            new Identification().setDataInStorage(resData['parameters'], false);
          } catch (e) {}
        }
      }
    };
  }
}
