/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2020-06-04
 */


import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

export class ThebagelDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('#container > div.page-cont.order-wrap > table > tbody > tr > td.price > b')[i].textContent!.replace(/\D/g, '');
      const rawQty = (this.getSelectorAll(['input[name="orgamount"]'])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll(['input[name="branduid"]'])[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('#container > div > table > tbody > tr > td.photo > a > img')[i] as HTMLImageElement).alt,
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('#container > div > table > tbody > tr > td.goods > div.price > b')[i].textContent!.replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('#container > div > table > tbody > tr > td.photo > a > img')[i] as HTMLImageElement).alt,
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }
}