/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-11-15
 */
import {FirstmallDataParser} from "../FirstmallDataParser";
import { InvalidData } from '../../../lib/ajax/InvalidData';
import { NumberUtil } from '../../../lib/common/NumberUtil';


export class MopanDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('.cart_list .cart_goods_detail');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i];
        const productName = productInfo.querySelector('.goods_name')!.textContent!.trim();
        const params = (productInfo.querySelector('.img_area a') as HTMLAnchorElement)!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = NumberUtil.parseNumber(productInfo.querySelector('.cart_quantity')!.childNodes[1].textContent!.replace(/\D/g, ''));
        const productPrice = NumberUtil.parseNumber(productInfo.querySelector('span.total_p')!.textContent!.replace(/\D/g, ''));
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('.total_sum_price .sum_price.settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('.cart_list .cart_goods_detail');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i];
        const productName = productInfo.querySelector('.goods_name')!.textContent!.trim();
        const params = (productInfo.querySelector('.img_area a') as HTMLAnchorElement)!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = NumberUtil.parseNumber(productInfo.querySelector('.cart_quantity')!.childNodes[2].textContent!.replace(/\D/g, ''));
        const productPrice = NumberUtil.parseNumber(productInfo.querySelector('.cart_quantity')!.childNodes[3].textContent!.replace(/\D/g, ''));
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('.total .settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}
