/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {DeviceType, PaySystemType} from '../../types/GlobalEnums';
import {EventUtil} from '../../lib/event/EventUtil';
import {Tracker} from '../../tracker/Tracker';
import {AlertChecker} from '../alertChecker/AlertChecker';
import * as PaySystemInfo from './paysystem.info.json';

/**
 * create on 2019-10-08.
 * <p> 간편 결제 시스템 컨버전에 대한 정보를 담는 클래스
 *     (이벤트 이름, 타겟, 이벤트 리스너 등)</p>
 * <p> {@link PaySystemFactory} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PaySystem {

  private readonly paySystemType: PaySystemType;
  private readonly adverId: string;
  private readonly deviceType: DeviceType;
  private eventName: string;
  private _eventTarget: NodeList | null;
  private readonly _paySystemConvEventListener: EventListener;
  static payButtonClicked: boolean;

  constructor(paySystemType: PaySystemType, adverId: string, deviceType: DeviceType) {
    self = this; // this 바인딩 이슈 방지
    this.paySystemType = paySystemType;
    this.adverId = adverId;
    this.deviceType = deviceType;
    this.eventName = this.createEventName(this.paySystemType);
    this._eventTarget = this.createEventTarget(this.paySystemType);
    this._paySystemConvEventListener = this.invokePaySystemConvCommand.bind(self) as EventListener;
    PaySystem.payButtonClicked = false;
  }

  get paySystemConvEventListener(): EventListener {
    return this._paySystemConvEventListener;
  }

  /**
   * 간편 결제 컨버전 이벤트 추가
   */
  addPaySystemConvEvent(): void {
    if(this._eventTarget) {
      Array.prototype.slice.call(this._eventTarget,).forEach(
          (eventTarget)=>{
            EventUtil.addEvent(
                eventTarget,
                this.eventName,
                this._paySystemConvEventListener
            );
          }
      )
    }
  }

  /**
   * 간편 결제 컨버전 이벤트 제거
   */
  removePaySystemConvEvent(): void {
    if(this._eventTarget) {
      const eventTargetList: NodeList | Element[] = this._eventTarget instanceof Element ? [this._eventTarget] : this._eventTarget;

      eventTargetList.forEach((eventTarget)=>{
        EventUtil.removeEvent(
            eventTarget,
            this.eventName,
            this._paySystemConvEventListener
        );
      });
    }
  }

  /**
   * {@link Command}를 통해 전달받은 옵션 값으로 간편 결제를 통한 컨버전인지 확인
   * @param {{}} options  - 옵션
   * @return {boolean}
   * - <code>true</code>: 간편 결제를 통한 컨버전
   * - <code>false</code>: 간편 결제를 통한 컨버전이 아님
   */
  static isPaySystemConv(options?: {}): boolean {
    if (
        typeof options === 'undefined' ||
        typeof options['paySys'] === 'undefined'
    ) {
      return false;
    } else {
      return (
          Object.keys(PaySystemType).filter(
              value => PaySystemType[value] === options['paySys']
          ).length > 0
      );
    }
  }

  /**
   * {@link Command}를 통해 전달받은 옵션 값으로 간편 결제 타입을 반환
   * @param {{}} options  - 옵션
   * @return {PaySystemType}  - 간편 결제 시스템 타입
   */
  static getPaySystemType(options?: {}): PaySystemType | undefined {
    if (PaySystem.isPaySystemConv(options)) {
      const propertyName: string = Object.keys(PaySystemType).filter(
          value => PaySystemType[value] === options!['paySys']
      )[0];
      return PaySystemType[propertyName];
    } else {
      return undefined;
    }
  }

  /**
   * 간편 결제를 통한 컨버전 이벤트를 추가할 때 대상이 되는 타겟의 이벤트 이름 생성
   * @param {PaySystemType} paySystemType
   * @return {string}
   */
  private createEventName(paySystemType: PaySystemType): string {
    try {
      const paySystemInfo: {} = PaySystemInfo as {};
      return (this.eventName = paySystemInfo[paySystemType].eventName);
    } catch (e) {
      throw new Error(`Invalid PaySystem name = ${paySystemType}`);
    }
  }

  /**
   * 간편 결제를 통한 컨버전 이벤트를 추가할 때 대상이 되는 이벤트 타겟 생성
   * @param {PaySystemType} paySystemType
   * @return {EventTarget}
   */
  private createEventTarget(paySystemType: PaySystemType): NodeList {
    try {
      const paySystemInfo: {} = PaySystemInfo as {};
      return document.querySelectorAll(paySystemInfo[paySystemType].eventTargetSelector);
    } catch (e) {
      throw new Error(`Invalid PaySystem name = ${paySystemType}`);
    }
  }

  /**
   * 간편 결제 시스템을 통한 컨버전 커맨드 수행(send 커맨드)
   */
  private invokePaySystemConvCommand(mouseEvent: MouseEvent): void {
    PaySystem.payButtonClicked = true;
    const alertChecker: AlertChecker = AlertChecker.getInstance();

    if (
        this.correctMouseButtonClicked(mouseEvent) &&
        !alertChecker.getAlertCalled()
    ) {
      /* 똑같은 백그라운드 작업을 수행하지 않도록 하여 커맨드 실행 */
      // const tracker: Tracker = new Tracker(false);
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': this.deviceType, 'paySys': this.paySystemType }
      ]);
      alertChecker.setAsNotCalled();
      PaySystem.payButtonClicked = false;
    }
  }

  /**
   * 올바른 마우스 버튼이 클릭되었는지 확인.
   * <b>NOTE : </b>
   * - IE는 다른 브라우저와 다르게 마우스 휠 클릭시 네이버페이 창이 열린다.
   * - IE 8 이하와 다른 브라우저는 button property의 값이 상이하다.
   * @param {MouseEvent} mouseEvent - 전달 받은 마우스 이벤트
   * @return {boolean}
   * - <code>true</code> : 올바른 마우스 버튼이 클릭됨.
   * - <code>false</code> : 올바르지 않은 마우스 버튼이 클릭됨.
   * @see <a href="https://www.w3schools.com/jsref/event_button.asp">w3schools.com - event.button</a>
   */
  private correctMouseButtonClicked(mouseEvent: MouseEvent): boolean {
    let correctBtnClicked = false;
    if (document['documentMode'] <= 8) {
      /* IE 8 이하 */
      switch (mouseEvent.button) {
        case 1: // left button
        case 4: // wheel button
          correctBtnClicked = true;
          break;
        default:
          break;
      }
    } else {
      /* IE 9 이상 및 다른 브라우저 */
      switch (mouseEvent.button) {
        case 0: // left button
          correctBtnClicked = true;
          break;
        case 1: // wheel button (IE는 마우스 휠 클릭시 네이버페이 창이 열린다)
          correctBtnClicked = !!document['documentMode'];
          break;
        default:
          break;
      }
    }

    return correctBtnClicked;
  }
}

/* 이벤트 등록 및 제거시 this 바인딩을 위한 변수 */
let self: PaySystem;
