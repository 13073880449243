/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-11
 */
import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {JsonObject} from '../../../lib/json/JsonObject';

/**
 * create on 2020-05-11.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NawevDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      let priceElement: Element | null = null, dcPriceElement: Element | null = null;

      if(this.isMobile(this.commandOptions)) {
        const mobPriceElement = document.querySelectorAll('strong.price');
        priceElement = mobPriceElement.length > 0 ? mobPriceElement[0] : null;
        dcPriceElement = mobPriceElement.length > 1 ? mobPriceElement[1] : null;
      } else {
        priceElement = document.querySelector('dl.item_price:nth-child(2) dd > strong');
        dcPriceElement =  document.querySelector('dl.item_price:nth-child(3) dd > strong:nth-child(1)');
      }

      return {
        'price': priceElement
            ? priceElement.textContent!.replace(/\D/g, '').trim()
            : (formElement['set_goods_fixedPrice'] as HTMLInputElement)!.value,
        'dcPrice': dcPriceElement
            ? dcPriceElement.textContent!.trim().replace(/\D/g, '')
            :(formElement!['set_goods_price'] as HTMLInputElement)!.value
      };
    } catch (e) {
      return super.getProductPriceObj(formElement);
    }
  }
}
