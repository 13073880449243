/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-24
 */

import {ValidationRuleImpl} from "../ValidationRuleImpl";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2020-03-24.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ProductNameValidRule extends ValidationRuleImpl {
  constructor(propertyName: any) {
    super(propertyName);
  }

  /**
   * 상품명에 대한 검증 진행
   *  - String 타입검사
   *  - 상품명 내부의 HTML 태그 제거
   *  - 상품명 내부의 개행문자 제거
   * @param property - 검증할 데이터(상품명)
   * @return any - 검증된 결과 데이터
   */
  protected applyRule(property: any): any {
    let productName: string = property as string;

    // null일 때 빈 문자열로 치환
    if (productName === null) {
      productName = '';
    }

    //상품명 스트링 타입검사
    if (!property || StringUtil.isNotString(productName)) {
      throw new Error();
    }

    try{
      productName = decodeURIComponent(decodeURIComponent(productName));
    }catch{
      productName = productName.trim();
    }

    productName = StringUtil.translateHTMLEntities(productName);    //문자열 안의 HTML 특수문자 치환
    productName = StringUtil.getNonHTMLStr(productName);    // 상품명 내부의 HTML 태그 삭제
    productName = StringUtil.getNonNewLineStr(productName); // 상품명 내부의 개행문자 삭제
    productName = this.escapeBracket(productName);          // 대괄호에 이스케이프 문자 추가

    return productName;
  }

  /**
   * 대괄호에 백슬래시 문자를 앞에 추가하여 반환.
   * 상품명에 대괄호가 있으면 서버에서 사용하는 net.sf.json.JSONObject 라이브러리가 배열로 인식하여 오류 발생.
   * @param {string} rawString  입력받은 문자열
   * @return {string} 이스케이핑 결과
   */
  private escapeBracket(rawString: string): string {
    try {
      return rawString.replace(/\[/g, '\\[').replace(/]/g, '\\]')
    } catch (e) {
      return rawString;
    }
  }
}
