/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-10
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2022-01-10.
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ThecandlesDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents .shopdetail .font_shopdetailA')!.textContent!.trim()
            : this.getSelector(['#form1 div.info > h3', '#form1 > div > div.info_top > h3'])!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }
}
