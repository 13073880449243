/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-06-09
 */
import {FirstmallDataParser} from "../FirstmallDataParser";
import { InvalidData } from '../../../lib/ajax/InvalidData';
import { NumberUtil } from '../../../lib/common/NumberUtil';


export class Ysj9861DataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td:not(:first-child)');
        const productName = productInfo[2].querySelector('a')!.textContent!.trim();
        const params = productInfo[2].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[4].firstElementChild!.textContent!.trim();
        const productPrice = productInfo[5].textContent!.trim().replace(/\D/g, '');
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('.totalprice')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('table>tbody>tr>td');
        const productName = document.querySelectorAll('div.goods_name')[i]!.textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[2].querySelector('div.cart_ea')!.textContent!.split(':')[1]!.trim().match(/^[\d,]+/)![0];
        const productPrice = productInfo[2].querySelector('span.cart_price_num')!.textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': '',
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('span.sum_price>span.settle_price')!.textContent!.replace(/\D/g, '').trim()
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td');
        const productName = productInfo[3].textContent!.trim();
        const params = productInfo[3].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[5].textContent!.trim();
        const productPrice = productInfo[6].textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('table>tbody>tr>td>strong.settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getMobileConversionData(): {} {
    try {
      return {
        'product': window[this.ENP_OBJECT_NAME]['product'],
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('table tbody tr font strong')!.textContent!.replace(/\D/g, '').trim()
        ),
        'totalQty': window[this.ENP_OBJECT_NAME]['totalQty']
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}
