/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun1@enliple.com">sjchun</a>
 * @since 2021-09-09
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { GlobalVariables } from "../../../types/GlobalVariables";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2021-09-09.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Dwmall2019DataParser  extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {} = {};
    let categoryNo = "";
    try{
      categoryNo = window['iCategoryNo'] ? window['iCategoryNo'] : decodeURIComponent(location.href).split('category')[1].split('/')[1];
    }catch{
      categoryNo = window['iCategoryNo'];
    }
    this.parseCategory(categoryNo);
    const priceInfo: {} = this.getProductPrice();
    parsedData = {
      'adverId': this.adverId,
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'price': priceInfo['price'],
      'productUrl': window.location.href,
      'dcPrice': priceInfo['dcPrice'],
      'soldOut': !!window['is_soldout_icon']
          ? window['is_soldout_icon'] === 'T'
              ? 'Y'
              : 'N'
          : !!window['EC_FRONT_JS_CONFIG_SHOP']
              ? window['EC_FRONT_JS_CONFIG_SHOP']['bSoldout']
                  ? 'Y'
                  : 'N'
              : 'N',
      'imageUrl': this.getImageSrc(),
      'topCategory': this.category['firstSubCategory'] ? this.category['firstSubCategory'] : 
          (this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory),
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };
    return new JsonObject(parsedData);
  }
}
