/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-07
 */
import {EventType, HostingType, PaySystemType} from '../types/GlobalEnums';
import {JsonObject} from '../lib/json/JsonObject';
import {InvalidValueError} from '../error/InvalidValueError';
import {PaySystem} from '../service/paySystem/PaySystem';
import {NotSupportedError} from '../error/NotSupportedError';
import {CategoryData} from "../category/CategoryData";
import {Queue} from "../lib/dataStructure/queue/Queue";
import {CategoryStorage} from "../category/CategoryStorage";
import {GeneralTree} from "../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../category/Category";
import {GTNode} from "../lib/dataStructure/tree/general/GTNode";
import {GlobalVariables} from "../types/GlobalVariables";
import {ReviewParser} from "../service/review/ReviewParser";
import {Review} from "../service/review/dataType/Review";
import {Tracker} from "../tracker/Tracker";
import {KeywordManager} from '../service/keyword/KeywordManager';
import {NullParingData} from "../lib/ajax/NullParingData";
import {ParsingExceptionAlarm} from "../lib/alarm/ParsingExceptionAlarm";
import {InvalidData} from "../lib/ajax/InvalidData";

/**
 * create on 2019-11-07.
 * <p> 수집을 위해 필요한 항목을 파싱하는 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class DataParser {
  /* 광고주의 모비온 ID */
  protected readonly adverId: string;
  /* 호스팅사 타입 */
  protected readonly hostingType: HostingType;
  /* 간편결제시스템 타입 */
  protected readonly paySystemType: PaySystemType | undefined;
  /* 카테고리 */
  protected category: CategoryData;
  /* 카테고리 레벨 */
  protected categoryHierarchyLevel = [
    'topCategory',
    'firstSubCategory',
    'secondSubCategory',
    'thirdSubCategory'
  ];
  /* 클라이언트 코드에서 받은 옵션 */
  protected readonly commandOptions: {};

  protected constructor(adverId: string, commandOptions: {}, hostingType: HostingType) {
    this.adverId = adverId;
    this.commandOptions = commandOptions;
    this.hostingType = hostingType;
    this.paySystemType = PaySystem.getPaySystemType(this.commandOptions);
    this.category = {
      'topCategory': GlobalVariables.unknownCategory,
      'firstSubCategory': undefined,
      'secondSubCategory': undefined,
      'thirdSubCategory': undefined
    };
  }

  /**
   * 호스팅사 타입 반환
   * @return {HostingType}  - 호스팅사 타입
   */
  getHostingType(): HostingType {
    return this.hostingType;
  }

  /**
   * 이벤트 타입에 해당되는 파싱된 데이터를 <code>JsonObject</code>로 반환
   * @param {EventType} eventType - 이벤트 타입
   * @return {JsonObject} - 파싱된 데이터
   * @throws InvalidValueError  - 정의되지 않은 이벤트 타입을 입력한 경우
   */
  getParsedData(eventType: EventType): JsonObject |InvalidData {
    switch (eventType) {
      case EventType.COMMON:
        return this.getCommonTraceData();
      case EventType.COLLECT:
        const parseData: JsonObject = this.getShopCollectData();

        // 파싱동작 외의 기타명령어 수행
        this.postCollect(parseData);

        return parseData;
      case EventType.CART:
        return this.getCartCollectData();
      case EventType.WISH:
        return this.getWishCollectData();
      case EventType.CONVERSION:
        // return PaySystem.isPaySystemConv(this.commandOptions)
        //     ? this.getPayConversionData()
        //     : this.getConversionData();
        return this.getConversionDataWithArarm();
      case EventType.REVIEW:
        return this.getReviewData();
      case EventType.TEST:
        return this.getTestData();
      default:
        throw new InvalidValueError('eventType', eventType);
    }
  }

  /**
   * conversion데이터 파싱 중 exception시 텔레그램 알람 서비스를 이용해 알람 전송
   * @return JsonObject | InvalidData
   */
  protected getConversionDataWithArarm(): JsonObject | InvalidData {
    try {
      return PaySystem.isPaySystemConv(this.commandOptions)
          ? this.getPayConversionData()
          : this.getConversionData();
    } catch (e) {
      const alarm: ParsingExceptionAlarm = new ParsingExceptionAlarm(this.adverId, this.commandOptions['device'], EventType.CONVERSION);
      alarm.exceptionMessage = `${e.name} : ${e.message}`;
      alarm.send();
      return new InvalidData();
    }
  }

  /**
   * 간편결제를 통한 전환 데이터를 파싱하여 리턴.
   * "상품 상세 페이지"의 경우 {@link getPayConvDataFromProductPage} 메소드로 파싱.
   * "장바구니 페이지"의 경우 {@link getPayConvDataFromBasketPage} 메소드로 파싱.
   * @return {{}} - 파싱된 데이터
   * @throws NotSupportedError  - 미리 정의되지 않은 페이지에서 CONVERSION 이벤트가 호출된 경우
   */
  protected getParsedPayConvData(): {} {
    if (this.isProductPage()) {
      return this.getPayConvDataFromProductPage();
    } else if (this.isBasketPage()) {
      return this.getPayConvDataFromBasketPage();
    } else {
      throw new NotSupportedError('PayConversion on current page');
    }
  }

  /**
   * 간편결제를 통한 전환 데이터 리턴.
   * {@link getParsedPayConvData} 메소드를 통해 파싱된 <code>JSON</code> 데이터에 <code>paySys</code>를 추가.
   * <b>NOTE :</b> 미리 정의된 간편결제시스템 타입과 다른 경우 <code>paySys</code>의 값은 빈 문자열이 된다
   * @return {JsonObject} - 파싱 후 <code>paySys</code>가 추가된 데이터.
   */
  protected getPayConversionData(): JsonObject {
    const parsedData: {} = this.getParsedPayConvData();

    let paySysData: string;
    switch (this.paySystemType) {
      case PaySystemType.NAVER_PAY:
        paySysData = 'nPay';
        break;
      // case PaySystemType.KAKAO_PAY:
      //   paySysData = 'kakao';
      //   break;
      case PaySystemType.PAYCO:
        paySysData = 'payco';
        break;
      default:
        throw new InvalidValueError('paySys', this.paySystemType);
    }

    // 간편결제시 주문번호 Script에서 생성되기 때문에
    // Conversion 데이터에 간편결제 정보 삭제
    parsedData['paySys'] = paySysData;
    return new JsonObject(parsedData);
  }

  /**
   * 테스트를 위한 데이터
   * @return {JsonObject}
   */
  private getTestData(): JsonObject {
    return new JsonObject({
      'adverId': this.adverId,
      'event': 'test'
    });
  }

  protected abstract isProductPage(): boolean;

  protected abstract isBasketPage(): boolean;

  protected abstract getCommonTraceData(): JsonObject;

  protected abstract getShopCollectData(): JsonObject;

  protected abstract getCartCollectData(): JsonObject;

  protected abstract getWishCollectData(): JsonObject;

  protected abstract getConversionData(): JsonObject;

  protected abstract getPayConvDataFromProductPage(): {};

  protected abstract getPayConvDataFromBasketPage(): {};

  /**
   * 카테고리 파싱
   * @param {string} currentCategoryCode  현재 보고 있는 상품의 카테고리 코드
   */
  protected parseCategory(currentCategoryCode: string): void {
    const tree: GeneralTree<Category> | undefined = this.receiveTreeFromStorage();
    this.initCategory(tree, currentCategoryCode);
  }

  /**
   * <code>localStorage</code>에서 가져온 데이터를 트리로 변환.
   * @return {GeneralTree<Category> | undefined}  생성된 트리
   */
  protected receiveTreeFromStorage(): GeneralTree<Category> | undefined {
    try {
      return new CategoryStorage().receive();
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 전송할 카테고리 데이터를 트리를 이용해 초기화.
   * <ul>
   *   <li>현재 상품에 대한 카테고리를 찾지 못하면 이름값이 아닌 코드값으로 초기화 한다.</li>
   *   <li><code>localStorage</code>의 데이터를 트리로 변환하지 못하면 카테고리 값은
   *   <code>undefined</code>로 초기화 된다.</li>
   * </ul>
   * @param {GeneralTree<Category> | undefined} tree  트리
   * @param {string} currentCategoryCode  현재 보고 있는 상품의 카테고리 코드
   */
  protected initCategory(tree: GeneralTree<Category> | undefined, currentCategoryCode: string): void {
    try {
      const leafNode: GTNode<Category> | undefined = this.searchCategoryNode(tree, currentCategoryCode);
      if (typeof leafNode === 'undefined') {
        this.category.topCategory = currentCategoryCode;
      } else {
        const hierarchyQueue: Queue<string> = this.createHierarchyQueue(leafNode);
        this.setAllOfCategoryData(this.category, hierarchyQueue);
      }
    } catch (e) {
      this.category.topCategory = GlobalVariables.unknownCategory;
      this.category.firstSubCategory = undefined;
      this.category.secondSubCategory = undefined;
      this.category.thirdSubCategory = undefined;
    }
  }

  /**
   * 코드에 해당되는 노드를 트리에서 탐색 후 반환. 찾지 못하면 <code>undefined</code>
   * @param {GeneralTree<Category> | undefined} tree  트리
   * @param {string} currentCategoryCode  현재 보고 있는 상품의 카테고리 코드
   * @return {GTNode<Category> | undefined} 찾은 노드
   */
  protected searchCategoryNode(tree: GeneralTree<Category> | undefined, currentCategoryCode: string): GTNode<Category> | undefined {
    try {
      return tree!.search(currentNode => currentNode.value.code === currentCategoryCode);
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 카테고리의 계층을 생성.
   * 카테고리의 이름값으로 상위부터 하위까지에 해당하는 계층을 큐로 생성.
   * @param {GTNode<Category> | undefined} leafNode 말단 노드 (현재 상품에 해당하는 노드)
   * @return {Queue<string>}  큐
   */
  protected createHierarchyQueue(leafNode: GTNode<Category> | undefined): Queue<string> {
    const hierarchy: Queue<string> = new Queue<string>();
    const categoryValueArr: string[] = new Array<string>();
    while (leafNode) {
      const categoryValue: string = leafNode.value.value;
      if (categoryValue) {
        categoryValueArr.push(categoryValue);
      }
      leafNode = leafNode.parent;
    }

    categoryValueArr.reverse().forEach((obj) =>{
      hierarchy.enqueue(obj);
    })
    return hierarchy;
  }

  /**
   * 생성된 계층 큐를 이용해 카테고리 데이터 설정
   * @param {CategoryData} categoryData 설정 대상이 되는 데이터
   * @param {Queue<string>} hierarchyQueue  계층 큐
   */
  protected setAllOfCategoryData(categoryData: CategoryData, hierarchyQueue: Queue<string>): void {
    this.categoryHierarchyLevel.forEach((categoryLevel) => {
      categoryData[categoryLevel] = hierarchyQueue.dequeue();
    });
  }

  /**
   * 상품수집(collect) 데이터 파싱 한 후 수행되어야 하는 기타 동작들을 수행 하는 메소드
   * @param data 파싱된 데이터
   * @private
   */
  private postCollect(data: JsonObject): void {
    if (data instanceof NullParingData) {
      return;
    }

    //리뷰파싱이 스크립트로 가능한 광고주의 경우 토픽전송을 위해 커맨드 새로 전송
    this.appendReviewCommand(data.data['productCode']);
    //내부 키워드 저장을 위해 상품코드 저장
    new KeywordManager().storeInternalKeyword(this.adverId, data.data['productCode']);
  }

  /**
   * 리뷰파싱을 위해 상품코드 저장 및 스크립트 파싱 광고주일 경우 review 명령 대기열에 추가
   * @param productCode 상품코드
   * @private
   */
  private appendReviewCommand(productCode) {
    //리뷰파싱이 스크립트로 가능한 광고주의 경우 토픽전송을 위해 커맨드 새로 전송
    ReviewParser.getInstance().productCode = productCode;
    if(ReviewParser.getInstance().isScriptParsing()) {
      Tracker.getInstance(false).executeCommand([ 'send', 'review', this.adverId ]);
    }
  }

  /**
   * 리뷰 데이터를 파싱
   * @private
   */
  private getReviewData(): JsonObject {
    const reviewData: Review | null = ReviewParser.getInstance().getReviewData();
    return JSON.parse(JSON.stringify(reviewData));
  }

  protected getSelector(selectorArr: string[]): Element | null {
    for (let i = 0; i < selectorArr.length; i++) {
      const element = document.querySelector(selectorArr[i]);
      if (element) {
        return element;
      }
    }

    return null;
  }

  protected getSelectorAll(selectorArr: string[]): NodeListOf<Element> {
    for (let i = 0; i < selectorArr.length; i++) {
      const nodes = document.querySelectorAll(selectorArr[i]);
      if (nodes.length > 0) {
        return nodes;
      }
    }

    return new NodeList<>();
  }
}
