/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-02-23
 */

import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2023-02-23
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */

export abstract class FitnessstoreDataParser extends SelfHostedDataParser {

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getCommonTraceData(): JsonObject {
    const jsonData: JsonObject = super.getCommonTraceData();
    this.startConversionByBtnClick();
    return jsonData;
  }
 
  private startConversionByBtnClick(): void {
    let selector = '';  //document.querySelector로 선택할 요소
    selector = '.payment-wrap .naverpay-box'
        //비쇼핑의 경우 한페이지에서 여러개의 버튼에 컨버전 이벤트가 발생될 가능성도 있다.
        let eventAdd = false;
        let addCount = 0;
        const etcEventAdd = () => {
          const btnArr = document.querySelectorAll(selector);
          for (let i = 0; i < btnArr.length; i++) {
            eventAdd = true;
            this.invokeConversionService();
            return;
          }
          //비쇼핑의 경우 전환 잡을 버튼이 늦게 호출되는 경우가 발생하여 1초마다 제한 횟수까지 시도한다.
          if(!eventAdd){
            setTimeout(() => {
              if (addCount++ === 10) return;
              etcEventAdd();
            }, 1000);
          }
        }
        etcEventAdd();
      }
      
      private invokeConversionService(): void {
        EventUtil.addEvent(document.querySelector('.payment-wrap .naverpay-box')!, 'click', () => {
          const tracker: Tracker = Tracker.getInstance(false);
          tracker.executeCommand([
            'send',
            'conversion',
            this.adverId,
            { 'device': 'W', 'paySys': 'naverPay' }
          ]);
        });
    }
}






  

