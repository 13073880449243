/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-11-27
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-11-27.
 * <p> yh1116 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Yh1116DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    const image: HTMLImageElement | null = document.querySelector('.product-detail-top > .xans-product-image img');
    if (image) {
      return image.src;
    } else {
      return super.getImageSrc();
    }
  }
}
