/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-04-18
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2022-04-18
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class MustitDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    jsonData.addData('domain', 'mustit.co.kr');
    return jsonData;
  }
}
