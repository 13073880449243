/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {CommandConfig} from './CommandConfig';
import {ArgumentType, CommandType, ConversionType, EventType} from '../../../types/GlobalEnums';
import {CommandConfigBuilder} from './CommandConfigBuilder';
import {NotSupportedError} from '../../../error/NotSupportedError';
import {DataParserFactory} from '../../../dataParser/DataParserFactory';
import {JsonObject} from '../../../lib/json/JsonObject';
import {ObjectUtil} from "../../../lib/common/ObjectUtil";

/**
 * create on 2019-08-28.
 * <p> <code>CommandConfig</code> 객체 생성 및 재사용 </p>
 * <p> 하나의 <code>eventType</code>으로  </p>
 * <p> Flyweight 패턴 </p>
 * <p> {@link CommandConfig}, {@link CommandConfigBuilder} and {@link JsonObject} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommandConfigFactory {
  private readonly pool: { [eventType: string]: CommandConfig | undefined | InnerPoolObject };
  private readonly jsonData: JsonObject;

  constructor(jsonData: JsonObject) {
    this.pool = {};
    this.jsonData = jsonData;
  }

  /**
   * 클라이언트 코드로 입력 받은 값에 따라 pool에서 {@link CommandConfig} 객체를 찾아 리턴.
   * @param {{}} extractedArgObj  - 클라이언트 코드에서 추출한 인자
   * @return {CommandConfig}
   */
  getCommandConfig(extractedArgObj: {}): CommandConfig {
    const commandType = extractedArgObj[ArgumentType.COMMAND_TYPE];
    const eventType: EventType = extractedArgObj[ArgumentType.EVENT_TYPE];
    const options: {} = extractedArgObj[ArgumentType.OPTIONS];

    let commandConfig: CommandConfig | undefined = this.getPoolContent(commandType, eventType, options);

    if (!commandConfig) {
      commandConfig = this.createCommandConfig(this.jsonData, extractedArgObj);

      /* 버튼 클릭 이벤트를 통한 비쇼핑 전환인 경우 */
      if (this.isNonProductConvWithBtn(eventType, options)) {
        const convType: ConversionType = options['convType'];
        const btnSelector: string = options['btnSelector'];

        ObjectUtil.createEmptyObjectAt(this.pool, eventType);
        ObjectUtil.createEmptyObjectAt(this.pool[eventType]!, convType);

        this.pool[eventType]![convType][btnSelector] = commandConfig;
      } else {
        this.pool[eventType] = commandConfig;
      }
    } else {
      /* 객체 재사용 */
    }

    return commandConfig;
  }

  /**
   * pool에서 입력된 인자에 해당하는 내용을 찾아서 반환.
   * @param {CommandType} commandType - 커맨드 타입
   * @param {EventType} eventType - 이벤트 타입
   * @param {{}} options  - 커맨드 옵션
   * @return {CommandConfig}
   * <p>{@link CommandConfig} - pool에서 찾은 경우</p><p><code>undefined</code> - pool에서 찾지 못한 경우</p>
   */
  private getPoolContent(commandType: CommandType, eventType: EventType, options: {}): CommandConfig | undefined {
    /* 버튼 클릭 이벤트를 통한 비쇼핑 전환인 경우 */
    if (this.isNonProductConvWithBtn(eventType, options)) {
      try {
        const convType: ConversionType = options['convType'];
        const btnSelector: string = options['btnSelector'];
        return this.pool[eventType]![convType][btnSelector];
      } catch (e) {
        return undefined;
      }
    } else {
      /* create 커맨드의 경우 새로 생성시키기 위해 undefined로 초기화 */
      if (commandType === CommandType.CREATE) {
        this.pool[eventType] = undefined;
      }

      return this.pool[eventType] as CommandConfig;
    }
  }

  private createCommandConfig(jsonData: JsonObject, extractedArgObj: {}): CommandConfig {
    const commandType = extractedArgObj[ArgumentType.COMMAND_TYPE];
    switch (commandType) {
      case CommandType.CREATE:
        return new CommandConfigBuilder(commandType)
          .setEventType(extractedArgObj[ArgumentType.EVENT_TYPE])
          .setAdverId(extractedArgObj[ArgumentType.ADVER_ID])
          .setOptions(extractedArgObj[ArgumentType.OPTIONS])
          .setDataParser(
              DataParserFactory.getDataParser(
                  commandType,
                  extractedArgObj[ArgumentType.EVENT_TYPE],
                  extractedArgObj[ArgumentType.ADVER_ID],
                  extractedArgObj[ArgumentType.OPTIONS]
              )
          )
          .build();
      case CommandType.SET:
        return new CommandConfigBuilder(commandType)
          .setEventType(extractedArgObj[ArgumentType.EVENT_TYPE])
          .setJsonData((jsonData = this.mergeJsonData(jsonData, extractedArgObj[ArgumentType.DATA])))
          .build();
      case CommandType.SEND:
        return new CommandConfigBuilder(commandType)
          .setEventType(extractedArgObj[ArgumentType.EVENT_TYPE])
          .setAdverId(extractedArgObj[ArgumentType.ADVER_ID])
          .setOptions(extractedArgObj[ArgumentType.OPTIONS])
          .setDataParser(
              DataParserFactory.getDataParser(
                  commandType,
                  extractedArgObj[ArgumentType.EVENT_TYPE],
                  extractedArgObj[ArgumentType.ADVER_ID],
                  extractedArgObj[ArgumentType.OPTIONS]
              )
          )
          .setJsonData(jsonData)
          .build();
      default:
        throw new NotSupportedError(commandType);
    }
  }

  /**
   * SET 커맨드로 설정한 데이터를 이전의 SET 커맨드의 데이터와 병합.
   * @param {JsonObject} jsonObject 이전 SET 커맨드의 데이터
   * @param {{}} newData  새로운 SET 커맨드의 데이터
   * @return {JsonObject} 병합 결과.
   */
  private mergeJsonData(jsonObject: JsonObject, newData: {}): JsonObject {
    if (jsonObject.isNotEmpty()) {
      jsonObject.appendData(newData);
      return jsonObject;
    } else {
      return jsonObject.initWith(newData);
    }
  }

  /**
   * 버튼 클릭 이벤트를 통한 비쇼핑 전환인 경우
   * @param {EventType} eventType
   * @param {{}} options
   * @return {boolean}
   */
  private isNonProductConvWithBtn(eventType: EventType, options: {}): boolean {
    return eventType === EventType.CONVERSION
        && typeof options === 'object'
        && options['convType']
        && options['btnSelector']
        && options['convType'] !== 'product';
  }
}

/**
 * pool 내에 중첩하여 객체를 담은 타입
 */
type InnerPoolObject = { [convType in ConversionType]?: { [btnSelector: string]: CommandConfig | undefined } };