/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Jdoubleu01DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    try{
      return this.isMobile(this.commandOptions)
      ?  (document.querySelector('div.slick-track div:nth-child(2) img') as HTMLImageElement).src
      :  (document.querySelector('div.thumb ul li img:nth-child(2)') as HTMLImageElement).src;
    }catch{
      return super.getImageUrl();
    }
  }

  protected getProductName(): string {
    try{
      if(!this.isMobile(this.commandOptions)){
        return (document.querySelector('form#form1 div.info div.dname h3')!.textContent!.trim());
      }else{
        return (document.querySelector('article.option_area span.name')!.textContent!.trim());
      }
    }catch{
      return super.getProductName();
    }
  }
  
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalQty = 0;
    let totalPrice = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(2)')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);
   
      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

}
