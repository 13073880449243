/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-07-29
 */

import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2022-07-29
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class DibabambiDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    try {
      const formElement: HTMLFormElement | null = this.isMobile(this.commandOptions) ? document.querySelector('.detail_info form') : document.querySelector('form#frmView');
      return this.isMobile(this.commandOptions)
          ? this.getMobileShopCollectData(formElement!)
          : this.getWebShopCollectData(formElement!);
    } catch (e) {
      return super.getShopCollectData();
    }
  }

  protected getPayConvDataFromProductPage(): {} {
    const formElement: HTMLFormElement | null = this.isMobile(this.commandOptions) ? document.querySelector('.detail_info form') : document.querySelector('form#frmView');

    return this.isMobile(this.commandOptions)
        ? this.getMobilePayConvDataFromProductPage(formElement!)
        : this.getWebPayConvDataFromProductPage(formElement!);
  }
}
