/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-11
 */
import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-05-11.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PackstoreDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    if (super.isMobile(this.commandOptions)) {
      return super.getPayConvDataFromBasketPage();
    } else {
      const parsedProducts: Array<{}> = [];
      const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][id^="cartSno"]');

      /* 제품의 수량 */
      const qtyList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="goodsCnt[]"]');

      /* 배송비 포함된 총 주문금액 가져오지 못할 경우 0원 */
      const parsedTotalPrice: number = super.getParsedTotalPrice();

      let totalPrice = 0;
      let totalQty = 0;

      for (let i = 0; i < inputList.length; i++) {
        const qty = NumberUtil.parseInteger(qtyList[i].value);
        const rawPrice = NumberUtil.parseNumber(inputList[i].dataset['price'] as string);
        const price = rawPrice / qty;

        parsedProducts.push({
          'productCode': qtyList[i].dataset['goodsNo'],
          'productName': qtyList[i].dataset['goodsNm'],
          'qty': qty,
          'price': price,
          'dcPrice': price,
          'topCategory': this.category['topCategory'],
          'firstSubCategory': this.category['firstSubCategory'],
          'secondSubCategory': this.category['secondSubCategory'],
          'thirdSubCategory': this.category['thirdSubCategory']
        });

        totalPrice += rawPrice;
        totalQty += qty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        /* 배송비 포함된 금액을 가져오지 못하면 상품 가격들의 합으로 초기화 */
        'totalPrice': parsedTotalPrice > 0 ? parsedTotalPrice : totalPrice,
        'totalQty': totalQty
      };
    }
  }
}
