/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 *
 * [License of base-64]
 * Copyright Mathias Bynens <https://mathiasbynens.be/>
 *
 * Permission is hereby granted, free of charge, to any person obtaining
 * a copy of this software and associated documentation files (the
 * "Software"), to deal in the Software without restriction, including
 * without limitation the rights to use, copy, modify, merge, publish,
 * distribute, sublicense, and/or sell copies of the Software, and to
 * permit persons to whom the Software is furnished to do so, subject to
 * the following conditions:
 *
 * The above copyright notice and this permission notice shall be
 * included in all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
 * MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
 * NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE
 * LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
 * OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION
 * WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
import { Codec } from './Codec';
import * as base64 from 'base-64';

/**
 * create on 2019-07-29.
 * <p> Base64 인코딩/디코딩 모듈 </p>
 * <p> {@link Codec} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Base64 implements Codec {
  /**
   * 인코딩.
   * <pre>
   *   encode('abc'); // 'YWJj'
   * </pre>
   * @param {target} target - 인코딩할 문자열
   * @return {target} 인코딩 결과
   */
  encode(target: string): string {
    return base64.encode(target);
  }

  /**
   * 디코딩
   * <pre>
   *   decode('YWJj'); // 'abc'
   * </pre>
   * @param {target} target - 디코딩할 문자열
   * @return {target} 디코딩 결과
   */
  decode(target: string): string {
    return base64.decode(target);
  }
}
