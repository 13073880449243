/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-24
 */
import {ValidationRuleImpl} from "../ValidationRuleImpl";
import {DeviceType} from "../../../types/GlobalEnums";

/**
 * create on 2019-12-24.
 * <p> 디바이스 타입 검증 </p>
 * <p> {@link } and {@link DeviceType} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class DeviceValidRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    /* enum을 object로 변환 */
    const deviceTypes = Object(DeviceType);

    /* 입력한 값이 DeviceType에 포함되는지 확인 */
    const isValid = Object.keys(deviceTypes).filter((device: string) =>
        DeviceType[device] === property.toUpperCase()).length > 0;

    /* DeviceType이 아니면 "B"로 초기화 */
    return isValid
        ? property.toUpperCase() as DeviceType
        : DeviceType.BOTH;
  }
}
