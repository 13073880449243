/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CommandType, EventType } from '../../../types/GlobalEnums';
import { CreateCommand } from '../CreateCommand';
import { SetCommand } from '../SetCommand';
import { SendCommand } from '../SendCommand';
import { NotSupportedError } from '../../../error/NotSupportedError';
import { Command } from './Command';
import { DataParser } from '../../../dataParser/DataParser';
import { JsonObject } from '../../../lib/json/JsonObject';

/**
 * create on 2019-08-27.
 * <p> 클래스 설명 </p>
 * <p> {@link Command} and {@link CommandFactory} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommandBuilder {
  private readonly commandType: CommandType;
  private readonly _eventType: EventType;
  private _adverId!: string;
  private _options!: {};
  private _dataParser!: DataParser;
  private _jsonData!: JsonObject;

  constructor(commandType: CommandType, eventType: EventType) {
    this.commandType = commandType;
    this._eventType = eventType;
  }

  get eventType(): EventType {
    return this._eventType;
  }

  get adverId(): string {
    return this._adverId;
  }

  setAdverId(value: string): CommandBuilder {
    this._adverId = value;
    return this;
  }

  get options(): {} {
    return this._options;
  }

  setOptions(value: {}): CommandBuilder {
    this._options = value;
    return this;
  }

  get dataParser(): DataParser {
    return this._dataParser;
  }

  setDataParser(value: DataParser): CommandBuilder {
    this._dataParser = value;
    return this;
  }

  get jsonData(): JsonObject {
    return this._jsonData;
  }

  setJsonData(value: JsonObject): CommandBuilder {
    this._jsonData = value;
    return this;
  }

  build(): Command {
    switch (this.commandType) {
      case CommandType.CREATE:
        return this.createInstance(CreateCommand);
      case CommandType.SET:
        return this.createInstance(SetCommand);
      case CommandType.SEND:
        return this.createInstance(SendCommand);
      default:
        throw new NotSupportedError(this.commandType);
    }
  }

  private createInstance<T extends Command>(
    obj: new (builderClass: CommandBuilder) => T
  ): T {
    return new obj(this);
  }
}
