/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-05-21
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

export class MomoflinDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductQty(): string {
    return (document.getElementById('quantity') as HTMLInputElement)!.value
        ? (document.getElementById('quantity') as HTMLInputElement)!.value
        : "1";
  }

  protected getImageSrc(): string {
    if (this.isMobile(this.commandOptions)) {
      return super.getImageSrc();
    }
    if (document.querySelector('div#prdDetail img[src]')) {
      return (document.querySelector('div#prdDetail img[src]') as HTMLImageElement).src;
    } else {
      return super.getImageSrc();
    }
  }
}
