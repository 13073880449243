/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 */

import { SelfHostedDataParser } from "../SelfHostedDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { DeviceType } from "../../../types/GlobalEnums";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class DsjungoneDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (this.commandOptions['device'] === DeviceType.WEB) {
      jsonObj.data['reviewCount'] = document.querySelector('.crema-product-reviews-count.crema-applied') ? NumberUtil.parseNumber(document.querySelector('.crema-product-reviews-count.crema-applied')!.textContent!) : 0;
      jsonObj.data['reviewAverage'] = document.querySelector('strong.crema-product-reviews-score.crema-applied div') ? document.querySelector('strong.crema-product-reviews-score.crema-applied div')!.textContent!.trim() : 0;
    }
    return jsonObj;
  }
}
