/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {MakeshopD4CategoryParser} from "../MakeshopD4CategoryParser";
import {Category} from "../../Category";

/**
 * create on 2020-04-02.
 * <p> 라비에라메종 카테고리 파싱 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class RmkoreaCategoryParser extends MakeshopD4CategoryParser {
  protected parseRawCategories(): Category[] {
    const topCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#gnb a, div.navCategory>nav>ul>li>a');
    return this.getCategories(topCategories);
  }
}
