/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-22
 */
import {MappedCategory} from "./MappedCategory";
import {StorableCategoryTree} from "./StorableCategoryTree";
import {GeneralTree} from "../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "./Category";
import {GTNode} from "../lib/dataStructure/tree/general/GTNode";

/**
 * create on 2019-12-22.
 * <p> 파싱할 수 있는 형태의 카테고리 </p>
 * <p> <code>localStorage</code>에 저장된 데이터를 이용해</p>
 * <p> <code>GeneralTree</code>로 생성하여 파싱할 수 있는 형태로 변환. </p>
 * <p> {@link GeneralTree} and {@link Category} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ParsableCategory {
  /* localStorage에서 읽어온 저장할 수 있는 형태의 카테고리 */
  private readonly storableCategoryTree: StorableCategoryTree;

  constructor(storableCategoryTree: StorableCategoryTree) {
    this.storableCategoryTree = storableCategoryTree;
  }

  /**
   * 파싱할 수 있는 <code>GeneralTree<Category></code> 타입의 트리로 변환하여 리턴.
   * @return {GeneralTree<Category>}
   */
  getParsable(): GeneralTree<Category> {
    /* 순회 순서를 이용해 생성된 노드 */
    const nodesByTraversalIdx: Array<GTNode<Category>> = this.emitNodesByTraversalIdx(this.storableCategoryTree);
    /* 생성된 노드를 이용해 트리 생성 */
    return this.emitTree(nodesByTraversalIdx);
  }

  /**
   * <code>localStorage</code>에 저장된 데이터에 따라서
   * 전위순회 순서를 이용해 트리를 구성할 모든 노드를 생성하고,
   * 모든 노드 간의 path도 설정한다.
   * @param {StorableCategoryTree} storableCategoryTree localStorage에서 가져온 카테고리 데이터
   * @return {Array<GTNode<Category>>}  트리를 구성할 path가 설정된 모든 노드의 배열.
   */
  private emitNodesByTraversalIdx(storableCategoryTree: StorableCategoryTree): Array<GTNode<Category>> {
    const mappedCategory: MappedCategory = storableCategoryTree.mappedCategory;
    const nodePaths: string[] = storableCategoryTree.nodePaths;

    /* 모든 노드 생성 */
    const nodesByTraversalIdx: Array<GTNode<Category>> = [];
    for (const traversalIndex in mappedCategory) {
      if (mappedCategory.hasOwnProperty(traversalIndex)) {
        const node: GTNode<Category> = new GTNode<Category>(mappedCategory[traversalIndex]);
        nodesByTraversalIdx.push(node);
      }
    }

    /* 각 노드의 path를 설정 */
    this.setPathsOfNodes(nodesByTraversalIdx, nodePaths);
    return nodesByTraversalIdx;
  }

  /**
   * 생성된 노드들의 각 path를 설정한다.
   * path 정보는 <code>localStorage</code>에서 가져온 데이터를 이용한다.
   * @param {Array<GTNode<Category>>} nodesByTraversalIdx 생성한 모든 노드로 구성된 배열
   * @param {string[]} nodePaths  <code>localStorage</code>에서 가져온 path 정보
   */
  private setPathsOfNodes(nodesByTraversalIdx: Array<GTNode<Category>>, nodePaths: string[]): void {
    /* 이미 생성한 노드를 담을 배열 (중복 생성 방지) */
    const existingNodes: Array<GTNode<Category>> = [];

    /* path 설정 */
    nodePaths.forEach((pathsOfEachNode: string) => {
      const paths: string[] = pathsOfEachNode.split(':');

      for (let i = 0; i < paths.length - 1; i++) {
        const parentIndex: string = paths[i];
        const childIndex: string = paths[i + 1];
        const parentNode: GTNode<Category> = nodesByTraversalIdx[parentIndex];
        const childNode: GTNode<Category> = nodesByTraversalIdx[childIndex];

        if (existingNodes.filter((node: GTNode<Category>) =>
            node.value.code === childNode.value.code
            && node.value.parentCode === childNode.value.parentCode
        ).length === 0) {
          parentNode.insertNext(childNode);
          existingNodes.push(childNode);
        }
      }
    });
  }

  /**
   * path 설정이 완료된 노드를 이용해 트리를 구성하고 반환.
   * @param {Array<GTNode<Category>>} nodesByTraversalIdx path 설정이 완료된 노드 배열
   * @return {GeneralTree<Category>}  카테고리 트리
   */
  private emitTree(nodesByTraversalIdx: Array<GTNode<Category>>): GeneralTree<Category> {
    return new GeneralTree<Category>(nodesByTraversalIdx[0]);
  }
}
