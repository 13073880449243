/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-18
 */
import {Cafe24SmartDataValidator} from "../Cafe24SmartDataValidator";
import {FormatValidator} from "../../formatValidator/FormatValidator";
import {NonHtmlElementRule} from "../../formatValidator/rule/NonHtmlElementRule";

/**
 * create on 2020-03-18.
 * <p> "abmt" 광고주 데이터 검증 클래스 </p>
 * <p> {@link Cafe24SmartDataValidator} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class AbmtDataValidator extends Cafe24SmartDataValidator {
    constructor() {
        super();
    }

    /**
     * COLLECT 이벤트에 대한 검증
     * @param {{}} data - 검증할 데이터
     * @return {{}} - 검증된 결과 데이터
     */
    protected collectValidate(data: {}): {} {
        data = FormatValidator.createRule(false)
            .addRule(new NonHtmlElementRule('productName'))
            .validate(data);

        return super.collectValidate(data);
    }

    /**
     * CONVERSION 이벤트에 대한 검증
     * @param {{}} data - 검증할 데이터
     * @return {{}} - 검증된 결과 데이터
     */
    protected conversionValidate(data: {}): {} {
        const productArr: Array<{}> = data['product'];

        /* 주문한 개별 상품에 대한 검증 */
        for (let i = 0; i < productArr.length; i++) {
            FormatValidator.createRule(false)
                .addRule(new NonHtmlElementRule('productName'))
                .validate(productArr[i]);
        }

        return super.collectValidate(data);
    }
}
