/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {DeviceType} from '../../types/GlobalEnums';
import isMobile from 'ismobilejs';

/**
 * TODO
 * create on 2019-09-27.
 * <p> 클라이언트의 정보를 추출하여 담는 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ClientInfo {
  private static instance: ClientInfo;

  /* 디바이스 타입 */
  private _deviceType: DeviceType = DeviceType.BOTH;
  /* 국가 코드(ISO-3166-1-alpha-2) */
  private _nation!: string;
  /* 쿠키 허용여부 */
  private _cookieEnabled: boolean = true;
  /* iOS 여부 */
  private _isIOS: boolean = false;

  private constructor() {
    this.detectDeviceType();
    this.detectNation();
    this.detectCookieEnabled();
    this.detectIOS();
  }

  get deviceType(): DeviceType {
    return this._deviceType;
  }

  get nation(): string {
    return this._nation;
  }

  get cookieEnabled(): boolean {
    return this._cookieEnabled;
  }

  get isIOS(): boolean {
    return this._isIOS;
  }

  /**
   * 인스턴스 생성
   * @return {ClientInfo}
   */
  static getInstance(): ClientInfo {
    if (!ClientInfo.instance) {
      ClientInfo.instance = new ClientInfo();
    }

    return ClientInfo.instance;
  }

  /**
   * 디바이스 타입 확인
   */
  private detectDeviceType(): void {
    this._deviceType = isMobile(window.navigator.userAgent).any
        ? DeviceType.MOBILE
        : DeviceType.WEB;
  }

  /**
   * TODO 정책 정해지면 다시 구현
   * <b>ISO-3166-1-alpha-2</b>에 해당하는 국가 코드 추출
   */
  private detectNation(): void {
    // const countryCode = localeCode.getCountryCode(window.navigator.language);
    // this._nation = StringUtil.isEmpty(countryCode) ? 'KR' : countryCode;
    this._nation = 'KR';
  }

  /**
   * 브라우저의 쿠키 허용여부
   * 에러 발생시 <code>true</code>
   */
  private detectCookieEnabled(): void {
    try {
      this._cookieEnabled = typeof navigator.cookieEnabled === 'undefined'
          ? true
          : navigator.cookieEnabled;
    } catch (e) {
      this._cookieEnabled = true;
    }
  }

  /**
   * 디바이스가 iOS인지 확인
   */
  private detectIOS(): void {
    try {
      this._isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    } catch (e) {
      this._isIOS = false;
    }
  }
}

