/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-07
 */
import {DataParser} from '../DataParser';
import {JsonObject} from '../../lib/json/JsonObject';
import {DeviceType, HostingType} from '../../types/GlobalEnums';
import {NumberUtil} from '../../lib/common/NumberUtil';
import {GlobalVariables} from "../../types/GlobalVariables";
import {StringUtil} from "../../lib/common/StringUtil";
import {Value} from "../../lib/value/Value";
import {NotSupportedError} from "../../error/NotSupportedError";
import DataParserSelector from "./config/dataParser.selector.json";

/**
 * create on 2019-11-07.
 * <p> 카페24 데이터 파싱 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Cafe24SmartDataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.CAFE24_SMART);
  }

  protected parseCategory(currentCategoryCode: string): void {
    /*
     * 카페24 스마트의 경우 메인 페이지에서 상품을 클릭해 상세 페이지로 이동하면
     * 카테고리가 항상 고정된 '1'로 초기화 된다.
     * 하지만 이것은 실제로 사용하는 코드가 아니라 단순히 다른 코드들의 루트로 표현하는 것이므로
     * 현재 상품의 카테고리가 '1' 또는 '0'이면 알 수 없는 코드로 설정한다.
     */
    if (currentCategoryCode === '1' || currentCategoryCode === '0') {
      this.category.topCategory = GlobalVariables.unknownCategory;
      this.category.firstSubCategory = undefined;
      this.category.secondSubCategory = undefined;
      this.category.thirdSubCategory = undefined;
    } else {
      super.parseCategory(currentCategoryCode);
    }
  }

  protected isProductPage(): boolean {
    try {
      return (0 === window.location.pathname.indexOf('/product')
          || 0 === window.location.pathname.indexOf('/surl/P')
          || 0 === window.location.pathname.indexOf('/front/php/product.php'));
    } catch (e) {
      return false;
    }
  }

  private isOrderPage(): boolean {
    return window.location.pathname === '/order/order_result.html';
  }

  protected isBasketPage(): boolean {
    try {
      return window.location.pathname === '/order/basket.html';
    } catch (e) {
      return false;
    }
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  /*
   * 카페24 스마트 스크립트 자동화 요청으로 작업을 진행함
   * 제품 상세페이지의 데이터를 파싱하기 위한 메소드
   */
  protected getProductTargetObj(): {} {
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['collect']) {
      return window[this.ENP_OBJECT_NAME]['collect'];
    } else {
      return {};
    }
  }

  /**
   * 제품상세 페이지 상품 수집시 호출되는 메소드
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 if(1), else(1)의 제거 작업이 필요함
   * @returns {JsonObject}
   * @protected
   */
  protected getShopCollectData(): JsonObject {
    let parsedData: {} = {};
    //if(1)
    if (Object.keys(this.getProductTargetObj()).length > 0) {
      const targetObj: {} = this.getProductTargetObj();

      this.refindImgSrc(targetObj);

      parsedData = {
        'adverId': this.adverId,
        'productCode': targetObj['productCode'],
        'productName': targetObj['productName'],
        'price': targetObj['price'],
        'productUrl': window.location.href,
        'dcPrice': targetObj['dcPrice'],
        'soldOut': targetObj['soldOut'],
        'imageUrl': targetObj['imageUrl'],
        'secondImageUrl': targetObj['secondImageUrl'],
        'thirdImageUrl': targetObj['thirdImageUrl'],
        'fourthImageUrl': targetObj['fourthImageUrl'],
        'topCategory': Value.getValue(targetObj['topCategory'], this.category.topCategory),
        'firstSubCategory': Value.getValue(targetObj['firstSubCategory'], this.category.firstSubCategory),
        'secondSubCategory': Value.getValue(targetObj['secondSubCategory'], this.category.secondSubCategory),
        'thirdSubCategory': Value.getValue(targetObj['thirdSubCategory'], this.category.thirdSubCategory)
      };
    }
    //else(1)
    else {
      this.parseCategory(window['iCategoryNo']);
      const priceInfo: {} = this.getProductPrice();
      parsedData = {
        'adverId': this.adverId,
        'productCode': window['iProductNo'],
        'productName': window['product_name'],
        'price': priceInfo['price'],
        'productUrl': window.location.href,
        'dcPrice': priceInfo['dcPrice'],
        'soldOut': !!window['is_soldout_icon']
            ? window['is_soldout_icon'] === 'T'
                ? 'Y'
                : 'N'
            : !!window['EC_FRONT_JS_CONFIG_SHOP']
                ? window['EC_FRONT_JS_CONFIG_SHOP']['bSoldout']
                    ? 'Y'
                    : 'N'
                : 'N',
        'imageUrl': this.getImageSrc(),
        'topCategory': this.category['topCategory']
            ? this.category['topCategory']
            : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
    }
    return new JsonObject(parsedData);
  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  /**
   * 간편결제(NPay, Payco 등)를 통한 전환 처리시 호출되는 메소드
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 else(1)을 제거하여 Conversion 통합을 해야함
   * TODO: if(1)의 조건을 제거하여 현재 메소드는 getConversionProductDataAuto만 리턴 할 수있도록 해야함
   * @returns {{}}
   * @protected
   */
  protected getParsedPayConvData(): {} {
    //if(1)
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['conversion']) {
      return this.getConversionProductDataAuto();
    }
    //else(1)
    else {
      if (this.isProductPage()) {
        return this.getPayConvDataFromProductPage();
      } else if (this.isBasketPage()) {
        return this.getPayConvDataFromBasketPage();
      } else {
        throw new NotSupportedError('PayConversion on current page');
      }
    }
  }

  /**
   * Conversion 이벤트 데이터 파싱
   * 제품상세, 장바구니, 주문완료 페이지에서 Converison 파싱 통합
   * @returns {{}}
   * @protected
   */
  protected getConversionProductDataAuto(convPosition: string = ''): {} {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalQty: number = 0;
    let totalPrice: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseInteger(products[i]['price']);
      totalQty += NumberUtil.parseInteger(products[i]['qty']);
    }

    switch (convPosition) {
      case 'order' :
        return {
          'product': products,
          'adverId': this.adverId,
          'ordCode': targetObj['ordCode'],
          'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
          'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
        };
      default :
        return {
          'product': products,
          'adverId': this.adverId,
          'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
          'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
        };
    }

  }

  protected getConversionData(): JsonObject {
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['conversion']) {
      return new JsonObject(this.getConversionProductDataAuto('order'));
    } else {
      return this.getConversionData_prev();
    }
  }

  protected getConversionData_prev(): JsonObject {

    const rawConvData: {} = window['EC_FRONT_EXTERNAL_SCRIPT_VARIABLE_DATA'];
    const rawProductDataArr: Array<{}> = rawConvData['order_product'];
    const parsedProducts: Array<{}> = [];
    const parsedTotalPrice: number = this.getParsedTotalPrice();
    let totalQty = 0;
    let totalPrice = 0;

    for (let i = 0; i < rawProductDataArr.length; i++) {
      const productCode: string = NumberUtil.isNumber(rawProductDataArr[i]['product_no'])
          ? rawProductDataArr[i]['product_no'].toString()
          : rawProductDataArr[i]['product_no'];

      parsedProducts.push({
        'productCode': productCode,
        'productName': rawProductDataArr[i]['product_name'],
        'qty': rawProductDataArr[i]['quantity'],
        'price': rawProductDataArr[i]['product_price']
      });

      totalQty += rawProductDataArr[i]['quantity'];
      totalPrice += rawProductDataArr[i]['product_price'];
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': rawConvData['order_id'],
      'totalPrice': parsedTotalPrice === 0 || NumberUtil.isNaN(parsedTotalPrice)
          ? (
              rawConvData['payed_amount'] 
              ? rawConvData['payed_amount'] : totalPrice
            )
          : parsedTotalPrice.toString(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }


  /* 카페24 스마트 스크립트 자동화 요청으로 작업을 진행함
   * 제품 상세페이지에서 NPay Conversion
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 해당 메소드 제거
  */
  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const qty: number = NumberUtil.stringToNumber(this.getProductQty());
    const priceInfo: { price: number, dcPrice: number } = this.getProductPrice();
    const totalPrice = this.getTotalPriceByOpt(priceInfo, qty);

    this.parseCategory(window['iCategoryNo']);
    parsedProducts.push({
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'qty': qty,
      'price': priceInfo['price'] > priceInfo['dcPrice'] ? (priceInfo['dcPrice'] > 0 ? priceInfo['dcPrice'] : priceInfo['price']) : priceInfo['price'],
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': qty
    };
  }

  /* 카페24 스마트 스크립트 자동화 요청으로 작업을 진행함
   * 장바구니 페이지에서 NPay Conversion
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 해당 메소드 제거
  */
  protected getPayConvDataFromBasketPage(): {} {
    const rawProductDataArr: Array<{}> = window['aBasketProductData'];
    const parsedProducts: Array<{}> = [];
    const parsedTotalPrice: number = this.getParsedTotalPrice();
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < rawProductDataArr.length; i++) {
      this.parseCategory(rawProductDataArr[i]['main_cate_no']);
      parsedProducts.push({
        'productCode': rawProductDataArr[i]['product_no'],
        'productName': rawProductDataArr[i]['product_name'],
        'qty': rawProductDataArr[i]['quantity'],
        'price': rawProductDataArr[i]['product_price'],
      });

      totalPrice += rawProductDataArr[i]['sum_price_org'];
      totalQty += rawProductDataArr[i]['quantity'];
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': parsedTotalPrice === 0 || NumberUtil.isNaN(parsedTotalPrice)
          ? totalPrice
          : parsedTotalPrice.toString(),
      'totalQty': totalQty
    };
  }

  /**
   * 모바일 여부 판단
   * @param {{}} commandOptions
   * @return {boolean}
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 해당 메소드 제거 (검토필요)
   */
  protected isMobile(commandOptions: {}): boolean {
    try {
      /* 커맨드옵션으로 입력된 값 */
      const deviceFromOpt: string = commandOptions['device'].toUpperCase();

      /* enum을 object로 변환 */
      const deviceTypes = Object(DeviceType);

      /* 입력한 값이 DeviceType에 포함되는지 확인 */
      const isValid = Object.keys(deviceTypes).filter((device: string) =>
          DeviceType[device] === deviceFromOpt).length > 0;

      return isValid
          ? deviceFromOpt === DeviceType.MOBILE
          : false;
    } catch (e) {
      return false;
    }
  }

  /**
   * 가격, 할인가격를 파싱하여 객체로 리턴
   * TODO - 화폐기호 제거하는 로직을 추후에 parseNumber()로 옮겨야 하지만 그렇게 되면 다른 의존된 부분 모두 확인 필요
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 해당 메소드 제거 (검토필요)
   * @return {{price: number dcPrice: number}} 파싱된 가격, 할인가격
   */
  protected getProductPrice(): { price: number; dcPrice: number; } {
    const arrSelector: Array<{}> = DataParserSelector['productPrice'];
    let price;
    let dcPrice;

    for (let i = 0; i < arrSelector.length; i++) {
      const priceSelector: string = arrSelector[i]['price'];
      const dcPriceSelector: string = arrSelector[i]['dcPrice'];

      try {
        const priceElem = window[priceSelector]
          ? window[priceSelector]
          : document.querySelector(priceSelector);

        const dcPriceElem = window[dcPriceSelector]
          ? window[dcPriceSelector]
          : document.querySelector(dcPriceSelector);

        price = priceElem instanceof HTMLElement
          ? StringUtil.isNotEmpty(priceElem.textContent)
            ? priceElem.textContent!.trim()
            : (priceElem as HTMLInputElement).value
          : priceElem;

        dcPrice = dcPriceElem instanceof HTMLElement
          ? (dcPriceElem.childNodes[0] && StringUtil.isNotEmpty(dcPriceElem.childNodes[0].textContent))
            ? dcPriceElem.childNodes[0].textContent!.trim()
            : (dcPriceElem as HTMLInputElement).value
          : dcPriceElem
            ? dcPriceElem
            : 0;

        if (price && dcPrice) {
          break;
        }
      } catch (e) {
      }
    }

    price = this.getProductPriceCheck();

    return price && StringUtil.isNotEmpty(price) && NumberUtil.parseNumber(String(price)) > 0 && NumberUtil.parseNumber(String(price)) > NumberUtil.parseNumber(String(dcPrice))
      ? {
        price: NumberUtil.parseNumber(String(price)),
        dcPrice: NumberUtil.parseNumber(String(dcPrice))
      }
      : {
        price: NumberUtil.parseNumber(String(dcPrice)),
        dcPrice: NumberUtil.parseNumber(String(dcPrice))
      };
  }
  /**
   * price 가격을 제대로 못가져 오는 경우가 많아 커스텀 파일이 생성되는 경우가 많이 발생하여 추가
   * @returns 파싱된 컨텐츠 중 가장 높은 가격
   */
  private getProductPriceCheck(): number {
    try {
      const checkPrice: Array<string> = DataParserSelector['checkPrice'];
      const checkPriceSelector: NodeList = document.querySelectorAll(checkPrice.toString());
      const priceMax = (priceArr: number[]) => {
        let al: number = priceArr.length;
        let maximum = priceArr[al - 1];
        while (al--) {
          if (priceArr[al] > maximum) {
            maximum = priceArr[al]
          }
        }
        return maximum;
      }

      let priceArray: number[] = [];
      let priceText = '';
      let price = 0;

      for (let i = 0, len = checkPriceSelector.length; i < len; i++) {
        priceText = checkPriceSelector[i].textContent!.trim().replace(/\,/g, '');
        if (
          /(\D*\d+)+(?![^\d]*\.\d)+(\D+\d+)/.test(priceText) ||
          priceText.length > 30 ||
          priceText.indexOf('%') !== -1
        ) {
          continue;
        }
        price = NumberUtil.parseNumber(priceText);
        if (price && price > 0 && !NumberUtil.isNaN(price)) priceArray.push(price);
      }
      return priceMax(priceArray);
    } catch {
      return 0;
    }
  }

  protected getProductQty(): string {
    try {
      if (document.querySelectorAll('tbody.option_products>tr').length > 0) {
        let qty = 0;
        const products = document.querySelectorAll('tbody.option_products>tr');

        let inputSelector = 'td:nth-child(2)>span.quantity>input';
        if (this.isMobile(this.commandOptions)) {
          inputSelector = 'input[type="number"].quantity_opt';
        }

        for (let i = 0; i < products.length; i++) {
          qty += NumberUtil.parseInteger(products[i].querySelector(inputSelector)!['value']);
        }

        return String(qty);
      } else if (document.querySelectorAll('div#totalProducts>table>tbody>tr').length > 0) {
        return this.isMobile(this.commandOptions)
            ? document.querySelectorAll('div#totalProducts>table>tbody>tr')[0].querySelector('input[type="number"].quantity_opt')!['value']
            : document.querySelectorAll('div#totalProducts>table>tbody>tr')[0].querySelector('td:nth-child(2)>span.quantity>input, td:nth-child(2) input#quantity')!['value'];
      } else if (document.querySelectorAll("div#contents div.mSpec input#quantity").length > 0) {
        return document.querySelectorAll("div#contents div.mSpec input#quantity")[0]!['value'];
      } else if (document.querySelectorAll("span#NewProductQuantityDummy>span.quantity>input#quantity").length > 0) {
        return (document.querySelectorAll("span#NewProductQuantityDummy>span.quantity>input#quantity")[0] as HTMLInputElement).value;
      } else {
        /* 웹의 경우를 발견하면 여기에 추가할 것*/
        return (document.querySelectorAll('tr.quantity>td input#quantity')[0] as HTMLInputElement).value;
      }
    } catch (e) {
      //Exception 발생시 1로 고정 리턴
      return '1';
    }
  }

  protected getImageSrc(): string {
    // iframe 등 외부 페이지에서 스크립트 기능 호출 시 document의 이미지 src를 참조하기 위해 selector 변수로 분리
    const currDocument = window.parent.document === document
        ? document
        : window.parent.document;
        
    if (currDocument.querySelector('meta[property="og:image"][content*="product"][content*="big"]')) {
      return (currDocument.querySelector('meta[property="og:image"][content*="product"][content*="big"]') as HTMLMetaElement).content;
    } else if (currDocument.querySelector('img.BigImage[src]')) {
      return (currDocument.querySelector('img.BigImage[src]') as HTMLImageElement).src;
    } else if (currDocument.querySelector('img.bigImage[src]')) {
      return (currDocument.querySelector('img.bigImage[src]') as HTMLImageElement).src;
    } else if (currDocument.querySelector('li.flex-active-slide > img')) {
      return (currDocument.querySelector('li.flex-active-slide > img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('img.ThumbImage[src]')) {
      return (currDocument.querySelector('img.ThumbImage[src]') as HTMLImageElement).src;
    } else if (currDocument.querySelector('.imgArea .thumbnail img[src]')) {
      return (currDocument.querySelector('.imgArea .thumbnail img[src]') as HTMLImageElement).src;
    } else if (currDocument.querySelector('div.prdImgView img')) {
      return (currDocument.querySelector('div.prdImgView img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('.keyImg img')) {
      return (currDocument.querySelector('.keyImg img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('div.thumb img')) {
      return (currDocument.querySelector('div.thumb img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('div.thumb2 img')) {
      return (currDocument.querySelector('div.thumb2 img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('ul.detail_left_big>li img')) {
      return (currDocument.querySelector('ul.detail_left_big>li img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('.detailArea > .imgArea > center > img')) {
      return (currDocument.querySelector('.detailArea > .imgArea > center > img') as HTMLImageElement).src;
    } else if (currDocument.querySelectorAll('div#prdDetail>div.cont>p>img').length > 0) {
      return (currDocument.querySelectorAll('div#prdDetail>div.cont>p>img')[0] as HTMLImageElement).src;
    } else if (currDocument.querySelector('.imgArea .productDetail-thunmb__big img[src]')) {
      return (currDocument.querySelector('.imgArea .productDetail-thunmb__big img[src]') as HTMLImageElement).src;
    } else if (currDocument.querySelector('div.swiper-wrapper > div.swiper-slide:first-child > img')) {
      return (currDocument.querySelector('div.swiper-wrapper > div.swiper-slide:first-child > img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('.detail_left_big > img')) {
      return (currDocument.querySelector('.detail_left_big > img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('.detailArea .imgArea img')) {
      return (currDocument.querySelector('.detailArea .imgArea img') as HTMLImageElement).src;
    } else if (currDocument.querySelector('div#prdDetail img') as HTMLImageElement) {
      return (currDocument.querySelector('div#prdDetail img') as HTMLImageElement).src;
    } else if(currDocument.querySelector('.detail_left_pc_ver img:first-child')) {
      return (currDocument.querySelector('.detail_left_pc_ver img:first-child') as HTMLImageElement).src; //doublelover
    } else if (currDocument.querySelector('div.detail_left_wrap img:first-child')) {
      return (currDocument.querySelector('div.detail_left_wrap img:first-child') as HTMLImageElement).src;
    } else if (currDocument.querySelector('#imgArea .keyImg a img.BigImage')) {
      return (currDocument.querySelector('#imgArea .keyImg a img.BigImage') as HTMLImageElement).src;
    } else {
      /* 빈 문자열을 전송하면 서버에서 수집 안함 */
      return '';
    }
  }

  /**
   * 상품의 할인여부를 판단하는 메소드
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 해당 메소드 제거 (검토필요)
   * @param {{price: number, dcPrice: number}} priceInfo
   * @returns {boolean}
   * @protected
   */
  protected onSale(priceInfo: { price: number; dcPrice: number; }): boolean {
    return priceInfo['dcPrice'] > 0 && priceInfo['price'] > priceInfo['dcPrice'];
  }

  /**
   * 추가된 상품옵션을 고려하여 총 주문금액 계산
   * @param {{price: number dcPrice: number}} priceInfo
   * @param {number} qty
   * @return {number}
   */
  protected getTotalPriceByOpt(priceInfo: { price: number, dcPrice: number }, qty: number): number {
    let totalPrice = 0;

    try {
      //전체 결제금액 HTML 파싱
      const parsedTotalPrice: number = document.querySelector('#totalPrice span.total strong')
        ? NumberUtil.parseRationalNumber(document.querySelector('#totalPrice span.total strong')!.textContent!.trim()) : 0;

      if (parsedTotalPrice && !NumberUtil.isNaN(parsedTotalPrice) && parsedTotalPrice > 0) {
        totalPrice = parsedTotalPrice;
      } else {
        const chkQty = qty ? qty : 1;
        totalPrice = (this.onSale(priceInfo) ? priceInfo['dcPrice'] : priceInfo['price']) * chkQty;
      }

      return totalPrice;
    } catch (e) {
      return priceInfo['dcPrice'] && priceInfo['dcPrice'] > 0 ? priceInfo['dcPrice'] : priceInfo['price'];
    }
  }


  /**
   * 주문 완료, 장바구니 페이지에서 배송비가 포함된 주문금액을 파싱하여 리턴.
   * <b>NOTE: </b>필요한 element를 참조하지 못하면 0을 리턴.
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 해당 메소드 제거 (검토필요)
   * @return {number} 배송비 포함 주문금액
   */
  protected getParsedTotalPrice(): number {
    const parsedTotalPrice = (): number => {
      if (this.isBasketPage()) {
        /* 장바구니 페이지 */
        const totalPrice: string = this.isMobile(this.commandOptions)
            ? (document.querySelector('.paymentPrice, #total_order_price_front, div.total.order strong, .price_sum_menu .c_total_order_menu_rt strong') as Element)!.textContent!.trim()
            : (document.querySelector('tfoot td strong.txtEm span.txt18, #normal_normal_ship_fee_sum, tfoot td strong.total span, #total_order_price_front, .total tbody.center td:nth-of-type(4) div.box strong .txt18, .w_last_price dl dd strong') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      } else {
        /* 다른 페이지는 0원으로 처리 */
        return 0;
      }
    };

    try {
      return parsedTotalPrice();
    } catch (e) {
      return 0;
    }
  }

  /**
   * 다중이미지 URL 정재 후 반환
   * 1. cafe24 기본 이미지 세팅되었을시 빈값으로 세팅
   * 2. imageUrl 빈값일때 다중이미지 값중 존재하는 값으로 세팅
   * @param jsonData
   * @protected
   */
  protected refindImgSrc(jsonData: {}) {
    jsonData['imageUrl'] = this.getReplaceEmptyImgSrc(jsonData['imageUrl']);
    jsonData['secondImageUrl'] = this.getReplaceEmptyImgSrc(jsonData['secondImageUrl']);
    jsonData['thirdImageUrl'] = this.getReplaceEmptyImgSrc(jsonData['thirdIma']);
    jsonData['fourthImageUrl'] = this.getReplaceEmptyImgSrc(jsonData['fourthImageUrl']);

    if(!jsonData['imageUrl'] || StringUtil.isEmpty(jsonData['imageUrl'])) {
      const subImgSrcList = [jsonData['secondImageUrl'], jsonData['thirdImageUrl'], jsonData['fourthImageUrl']];
      const resultFilter = subImgSrcList.filter(imgSrc => imgSrc && StringUtil.isNotEmpty(imgSrc));
      jsonData['imageUrl'] = resultFilter && resultFilter.length > 0 ? resultFilter[0] : '';
    }
  }

  /**
   * Cafe24 엑박 이미지값인 경우 빈 값으로 치환
   * @param {string} url
   * @return {string} url값이 없거나 해당 url인 경우 빈 값 치환
   */
  protected getReplaceEmptyImgSrc(url: string): string {
    try {
      if (url) {
        const defaultImgSrcReg = /\/\/img.echosting.cafe24.com\/thumb\/img_product_(big|small|medium).gif/;
        return url.search(defaultImgSrcReg) > -1 ? ''
            : url.search(/^http(s?):\/{2}/) > -1 ? url : location.protocol+url;
      } else {
        return '';
      }
    } catch (e) {
      return url;
    }
  }
}
