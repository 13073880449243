/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-01
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-12-01
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */

export class Vogue2718DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
        this.mobileCustomNpayCall();
    }
    return jsonObj;
  }

  protected getPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();
    const rawProductDataArr: Array<{}> = window['aBasketProductData'];
    let price =0;
    for (let i = 0; i < rawProductDataArr.length; i++) {
      if (this.isMobile(this.commandOptions)) {
      price =NumberUtil.parseInteger(document.querySelectorAll('ul.info li ul.price strong')[i]!.textContent!.replace(/\D/g,''));
    }else{
      price =NumberUtil.parseInteger(document.querySelectorAll('table td:nth-child(5) div strong')[i]!.textContent!.replace(/\D/g,''));
    }
    jsonObj['product'][i]['price'] = price/ NumberUtil.parseInteger(jsonObj['product'][i]['qty'])
  }
  
    return jsonObj;
  }

  private mobileCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector('div.npay_button')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'M', 'paySys': 'naverPay' }
      ]);
    });
  }

}