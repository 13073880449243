/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-02-17
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2022-02-17
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ImeleofficialDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    let superData = super.getProductPriceObj(formElement);
    try {
      if (this.isMobile(this.commandOptions)) {
        superData['dcPrice'] = NumberUtil.parseNumber(document.querySelector('.price_box strong.price:not(.p_hide)')!.textContent!.replace(/\D/g, ''));
      }
      return superData;
    } catch {
      return superData
    }
  }
}