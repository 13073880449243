/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-01-13
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2021-01-13
 * <p> build - build.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BuildDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    try {
      if(this.isMobile(this.commandOptions)) {
        return super.getImageSrc() && StringUtil.isNotEmpty(super.getImageSrc())
            ? this.getImageSrc()
            : document.querySelectorAll('.xans-product-image > ul > li:first-child')[0]['style'].backgroundImage.replace(/url\(\"|\"\)/g, '');
      } else {
        return super.getImageSrc();
      }
    } catch (e) {
      return this.getImageSrc();
    }
  }
}