/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">mgpark</a>
 * @since 2021-09-29.
 */
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";

/**
 * create on 2021-09-29.
 * <p> {@link GodomallSelfDataParser } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Campingpapa1DataParser extends GodomallSelfDataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  
  protected getProductName(): string {
    try{
      if (this.isMobile(this.commandOptions)) {
        if(document.querySelector('#goodsview2 > div.goods-contents-area > div.goods-contents-area-top > div:nth-child(3) > div:nth-child(1) > div:nth-child(2)')){
          return document.querySelector('#goodsview2 > div.goods-contents-area > div.goods-contents-area-top > div:nth-child(3) > div:nth-child(1) > div:nth-child(2)')!.childNodes[2].textContent!.trim();
        }
      }
  
      return super.getProductName();
    }catch{
      return super.getProductName();
    }
  }
}
