/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-08-22
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { EventUtil } from "../../../lib/event/EventUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-08-22
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WadintDataParser extends Cafe24SmartDataParser {

  protected getShopCollectData(): JsonObject {
    if(this.isMobile(this.commandOptions)){
    this.mobileCustomNpayCall();
    }
    return super.getShopCollectData();
  }

  private mobileCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector('div[roll="purchase_now_npay"]')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'M', 'paySys': 'naverPay' }
      ]);
    });
  }


}