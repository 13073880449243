/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-09-02
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-09-02.
 * <p> Annadiva(안나디바) 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class AnnadivaDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productAnchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('#content .one-table.typeList tr td > div > a');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;
    for (let i = 0; i < productAnchorList.length; i++) {
      let qty = NumberUtil.parseNumber(document.querySelectorAll('#content .one-table.typeList tr td:nth-child(3)')[i].textContent!.trim());
      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorList[i].search.substring(1)).getParam('branduid'),
        'productName': productAnchorList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('#content .one-table.typeList tr td:nth-child(4)')[i].textContent!.trim()),
      });
      totalQty += qty;
    }
    jsonObj.replaceData('product', parsedProducts);
    jsonObj.replaceData('totalQty', totalQty);
    return jsonObj;
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      let productNm = productAnchorElem!.textContent!.trim();
      if(document.querySelectorAll('div.product_info tr td div span.Go_linkpro')[i]){
        productNm = productAnchorElem!.textContent!.replace(document.querySelectorAll('div.product_info tr td div span.Go_linkpro')[i]!.textContent!, '').trim();
      }

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productNm,
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

}
