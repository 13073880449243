import { NumberUtil } from "../../../lib/common/NumberUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-11-13
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-12-02.
 * <p> crazyskin 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class CrazyskinDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const qty = !!document.getElementById("MS_amount_basic_0")
      ? document.getElementById("MS_amount_basic_0")!['value']
      : 1;

    const dcPrice = document.querySelector('div.shopdetailInfoValue > p > span.shopdetailInfoCont > em.bold > #pricevalue')
      ? NumberUtil.parseNumber(document.querySelector('div.shopdetailInfoValue > p > span.shopdetailInfoCont > em.bold > #pricevalue')!.textContent!.trim())
      : NumberUtil.parseNumber(document.querySelector('div.table-opt > table > tbody > tr:nth-child(2) > td.sell_price > div.tb-left')!.textContent!.trim());

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': document.querySelector('span.shopdetailInfoCont > del')
        ? NumberUtil.parseNumber(document.querySelector('span.shopdetailInfoCont > del')!.textContent!.trim())
        : NumberUtil.parseNumber(document.querySelector('div.table-opt > table > tbody > tr > td.price > div.tb-left')!.textContent!.trim()),
      'productUrl': window.location.href,
      'dcPrice': dcPrice / qty,
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': qty,
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': dcPrice,
      'totalQty': this.getTotalQty()
    };
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProducts = jsonObj.getDataByName('product');
    const orderedProducts = document.querySelectorAll('div.table-cart > table > tbody tr');

    let totalQty = 0;

    for (let i = 0; i < orderedProducts.length; i++) {
      const qty = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(4)')!.textContent!.trim());

      parsedProducts[i]['qty'] = qty;
      parsedProducts[i]['price'] = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(5)')!.textContent!.trim());

      totalQty += qty;
    }

    jsonObj.replaceData('product', parsedProducts);
    jsonObj.replaceData('totalQty', totalQty);

    return jsonObj;
  }
}
