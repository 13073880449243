/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-29
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-29.
 * <p> phhubby 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class PhhubbyDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      return {
        'price' : NumberUtil.parseNumber(window['product_price']),
        'dcPrice' : NumberUtil.parseNumber(window['product_price'])
      }

    } catch (e) {
     return super.getProductPrice();
    }
  }
}
