/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">jmlee</a>
 * @since 2022-09-23
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2022-09-23
 * <p> Msco2022 광고주 파싱 </p>
 * <p> 다른 고도몰 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link GodomallRentDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Msco2022DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromBasketPage(): {} {
    const superData = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
     let totalQty = 0;
     const parsedProducts: Array<{}> = [];
     const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('td.ta-c.count.this-product');
 
    for (let i = 0; i < inputList.length; i++) {
      const qty = NumberUtil.parseNumber(window['productList'][i].quantity);
      const price = window['productList'][i].price;
      parsedProducts.push({
        'productCode': window['productList'][i].id,
        'productName': window['productList'][i].name,
        'qty': qty,
        'price': price
      });
      totalQty += qty;
    }

    superData['product'] = parsedProducts;
    superData['totalQty'] = totalQty;
    superData['productCnt'] = inputList.length;
    }
    return superData;
  }

}