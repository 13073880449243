/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-14
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2021-12-14
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Skrs2021DataParser extends GodomallRentDataParser {

  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
      };

      products.push(product);
    }

    return new JsonObject({
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': targetObj['totalPrice'],
      'totalQty': targetObj['totalQty']
    });
  }
}