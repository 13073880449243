/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-14
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-04-14.
 * <p> 데이셀 광고주의 다른 광고주들과 상이한 데이터 파싱 함수 재정의</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class EdaycellDataParser extends Cafe24SmartDataParser {

  /**
   * 해당 광고주는 conversion 호출시 Cafe24에서 제공하는
   * 시스템 변수인 EC_FRONT_EXTERNAL_SCRIPT_VARIABLE_DATA를 모바일에서
   * 사용을 안해서 모바일인 경우 웹에서 파싱해서 JSON을 생성해야 한다.
   */
  protected getConversionData(): JsonObject {
    if (this.isMobile(this.commandOptions)) {
      const productNoArr: NodeListOf<Element> = document.querySelectorAll('div#tgg_order_complete_script span[tag="i"]');
      const productNameArr: NodeListOf<Element> = document.querySelectorAll('div#tgg_order_complete_script span[tag="t"]');
      const productQtyArr: NodeListOf<Element> = document.querySelectorAll('div#tgg_order_complete_script span[tag="q"]');
      const productPriceArr: NodeListOf<Element> = document.querySelectorAll('div#tgg_order_complete_script span[tag="p"]');
      const parsedProducts: Array<{}> = [];

      let totalPrice = 0;
      let totalQty = 0;

      for (let i = 0; i < productNoArr.length; i++) {
        const productCode: string = productNoArr[i].textContent!.trim();
        let qty: string = productQtyArr[i].textContent!.trim();
        let price: string = productPriceArr[i].textContent!.trim();

        parsedProducts.push({
          'productCode': productCode,
          'productName': productNameArr[i].textContent!.trim(),
          'qty': qty,
          'price': price
        });


        totalQty += +qty;
        totalPrice += +price;
      }

      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': (document.querySelector('form#frm_order_result input#order_id')! as HTMLInputElement).value.trim(),
        'totalPrice': totalPrice,
        'totalQty': totalQty
      };

      return new JsonObject(parsedData);
    } else {
      return super.getConversionData();
    }
  }
}
