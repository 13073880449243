/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Config} from './Config';
import {StorageQueue} from '../../../storageQueue/StorageQueue';
import {ClientInfo} from "../../../lib/common/ClientInfo";
import {QueueManager} from "../../../storageQueue/QueueManager";
import {Identification} from "../../ident/Identification";

/**
 * create on 2019-08-08.
 * <p> 전환과 관련된 Config </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ConverterConfig extends Config {
  constructor(adverId: string, options?: {}) {
    super(adverId, options);
    this._baseUrl = this.domain+'/aggregate/mssg/conversion';
    this._url = this.protocol + this.baseUrl;
    this._callback = (xhr: XMLHttpRequest, response: any, hasTransmitted: boolean) => {
      QueueManager.getInstance().toggle = true; // conversion 중복 전송 방지를 위한 토글 설정
      if (hasTransmitted && ClientInfo.getInstance().cookieEnabled) {
        StorageQueue.getInstance().dequeue();
      }

      xhr.onload = () => {
        if(xhr.status === 200) {
          try {
            const resData = JSON.parse(response);
            new Identification().setDataInStorage(resData['parameters'], false);
          } catch (e) {}
        }
      }
      // xhr.onerror = () => {
      //   if (xhr.status == 0){
      //     AdBlockCtrl.getInstance().reSend(this.protocol, '/aggregate/mssg/conversion');
      //   }
      // }

    };
  }
}
