/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-11
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-11-11
 * <p> https://sizeof.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SizeofDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getPayConvDataFromBasketPage();
      jsonObj['totalPrice'] = document.querySelector('span.paymentPrice strong#total_order_price_front')!.textContent!.replace(/\D/g,'');
      return jsonObj;
  }

}
