/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-07-25
 */
import { ValidationRuleImpl } from '../ValidationRuleImpl';

/**
 * create on 2022-07-25.
 * <p> 광고주 아이디 검증 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class AdverIdValidRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    return property.toString().trim();
  }
}
