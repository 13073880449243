/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-06-17
 */

import { InvalidValueError } from "../../error/InvalidValueError";
import { DateUtil } from "../../lib/common/DateUtil";
import { NumberUtil } from "../../lib/common/NumberUtil";
import { StringUtil } from "../../lib/common/StringUtil";
import { UniId } from "../unid/UniId";

/**
 * create on 2021-06-17
 * <p> 개인식별 데이터인 auid 및 ip_info 데이터 관리 class </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Identification {
  //localStorage key 값
  private readonly IDENT_DATA = 'identData';
  //identData 내부의 auid key 값
  private readonly AUID_STORAGE_KEY = 'identKey';
  //identData 내부의 ip key 값
  private readonly IP_INFO_STORAGE_KEY = 'identNumber';
  //identData 만료일자
  private readonly expiryDay = 40;
  private storage: Storage = window.localStorage;

  constructor() {
  }

  /**
   * ip_info, auid를 저정하는 메소드
   *
   * @param data auid, ip_info 가 담겨있는 reponse 데이터
   * @param isMerged 데이터 덮어쓰기 여부
   */
  setDataInStorage(data: {}, isMerged: boolean = false): void {
    try {
      if (!this.storage) return;
      if(isMerged) throw new InvalidValueError();
      
      this.setUni1Id(data['uni_auid']);
      const oldIdentData = JSON.parse(this.storage.getItem(this.IDENT_DATA)!);
      this.validateConfig(oldIdentData);
    } catch (e) {
      let identJsonData: Object = {};
      const auid = data['_auid'];
      const ipInfo = data['_ip_info'];

      if(auid && ipInfo){
        identJsonData[this.AUID_STORAGE_KEY] = auid;
        identJsonData[this.IP_INFO_STORAGE_KEY] = ipInfo;
        this.setIdentExpiryDate(identJsonData);
      }
    }
  }

  //uni1id 호출
  private setUni1Id(uni_auid: string): void {
    if (uni_auid) new UniId().detectIncognitoMode(uni_auid);
  }

  //local Storage 만료기간 키값 설정
  private setIdentExpiryDate(identJsonData: Object) {
    const expiry: Date = new Date(Date.now() + (1000 * 60 * 60 * 24 * this.expiryDay));
    identJsonData['expiry'] = DateUtil.getYYYYMMDDString(expiry);
    this.storage.setItem(this.IDENT_DATA, JSON.stringify(identJsonData));
  }

  //데이터 존재여부 및 만료기간 검증
  private validateConfig(identJsonData: Object): void {
    const hasDefined: boolean = typeof identJsonData !== 'undefined';
    const notExpired: boolean = !this.hasExpired(identJsonData);

    if (hasDefined && notExpired /*hasAuid*/) {
      /* 검증됨 */
    } else {
      /* 검증되지 않음 */
      throw new InvalidValueError();
    }
  }

  /**
   * localStorage의 설정 정보가 만료되었는지 확인.
   * <p><code>true</code> - 만료됨</p>
   * <p><code>false</code> - 만료되지 않음</p>
   */
  private hasExpired(identJsonData: Object): boolean {
    try {
      const expiry: string = identJsonData['expiry'];
      const today: string = DateUtil.getYYYYMMDDString();
      const isInvalidExpiry: boolean = !(expiry && expiry.length === 8 && StringUtil.isPositiveIntegerFormat(expiry));
      /** 
       * 배포일자에 맞춰서 date 일자 조정
       * 2022.04.18(+40d) 이하인 경우 true
       * 2022.05.29 이후 limitDate 제거
      */
      const limitDate: boolean = NumberUtil.stringToNumber(expiry) < 20220529;

      return isInvalidExpiry || NumberUtil.stringToNumber(expiry) < NumberUtil.stringToNumber(today) || limitDate;
    } catch (e) {
      return true;
    }
  }

  /**
   * auid를 스토리지에서 가져옴
   * @return string auid 없으면 빈값
  */
  getAuIdDataFromStorage(): string {
    try {
      const identJsonData: Object = JSON.parse(this.storage.getItem(this.IDENT_DATA)!);
      return identJsonData[this.AUID_STORAGE_KEY];
    } catch (e) {
      return '';
    }
  }

  /**
   * ip_info를 스토리지에서 가져옴
   * @return string ip_info 없으면 빈값
   */
  getIpInfoFromStorage() {
    try {
      const identJsonData: Object = JSON.parse(this.storage.getItem(this.IDENT_DATA)!);
      return identJsonData[this.IP_INFO_STORAGE_KEY];
    } catch (e) {
      return '';
    }
  }
}
