/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
/**
 * create on 2020-03-09.
 * <p> blancdecor 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class BlancdecorDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonData = super.getWebConversionData();
    const productList = jsonData.getDataByName('product');

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('tbody tr td:nth-child(5)')[i].textContent!.replace(/\D/g,'').trim());
    }

    jsonData.replaceData('product', productList);
    return jsonData;
  }

}
