/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-03-29
 */

import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-29
 * <p> sneaksoul - sneaksoul.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class SneaksoulDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromProductPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromProductPage();
    const productList = jsonObj['product'];
    const parsedQty: number = NumberUtil.parseRationalNumber((document.querySelector('input[name="order_cnt"]') as HTMLInputElement).value)
    productList[0]['qty'] = parsedQty;
    jsonObj['totalPrice'] = productList[0]['dcPrice'] > 0 ? productList[0]['dcPrice'] : productList[0]['price'] * parsedQty;
    return jsonObj;
  }

  protected getWebPayConvDataFromProductPage(): {} {
    const jsonObj = super.getWebPayConvDataFromProductPage();
    const productList = jsonObj['product'];
    jsonObj['totalPrice'] = productList[0]['dcPrice'] > 0 ? productList[0]['dcPrice'] : productList[0]['price'] * productList[0]['qty'];
    return jsonObj;
  }
}
