/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-03-09.
 * <p> Codenature 광고주 파싱 </p>
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class CodenatureDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart>table tr td:nth-child(3) div')[i] as HTMLDivElement)!.textContent!.trim().replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart>table tr td:nth-child(5) div')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.p_img img')) {
      return (document.querySelector('div.p_img img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }
}
