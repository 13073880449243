/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-03
 */
import {CategoryParser} from "../CategoryParser";
import {HostingType} from "../../types/GlobalEnums";
import {GeneralTree} from "../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../Category";

/**
 * create on 2020-04-03.
 * <p> 독립몰 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class SelfHostedCategoryParser extends CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  parse(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    return;
  }

  protected parseAndMakeTree(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    return;
  }

  protected createCategoryHierarchyTree(rawCategory: any): GeneralTree<Category> {
    return new GeneralTree<Category>({code: '', value: '', parentCode: ''});
  }
}