/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-07-23
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-07-23
 * <p> logona1 - logona.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Logona1DataParser extends MakeshopD4DataParser {
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('.table-cart > table > tbody > tr > td.ta_left > a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('.table-cart > table > tbody > tr > td.ta_left > a')[i] as HTMLAnchorElement;
      const qty = NumberUtil.parseNumber(document.querySelectorAll('div.table-cart>table tr td:nth-child(3)')[i].textContent!.trim());
      const price = NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-cart>table tr td:nth-child(5)')[i].textContent!.trim());

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('td.price_wrap strong')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }
}
