/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-06-23
 */
import {PQElement} from "./element/PQElement";

/**
 * create on 2020-06-23.
 * <p> 우선순위 큐 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PriorityQueue<T> {
  /* 큐의 데이터 */
  protected data: PQElement<T>[];

  constructor() {
    this.data = [];
  }

  /**
   * 현재 큐에 들어 있는 데이터의 갯수
   * @return {number}
   */
  size(): number {
    return this.data.length;
  }

  /**
   * 큐가 비어 있는지 확인
   * @return {boolean}
   */
  isEmpty(): boolean {
    return this.data.length === 0;
  }

  /**
   * 큐가 비어 있지 않은지 확인
   * @return {boolean}
   */
  isNotEmpty(): boolean {
    return this.data.length > 0;
  }

  peek(): PQElement<T> | undefined {
    return this.data[0];
  }

  /**
   * 큐에 요소를 삽입.
   * 이미 존재하는 요소의 우선순위보다 높은 경우 그 요소의 앞에 삽입.
   * @param {T} value - 값
   * @param {number} priority - 우선순위
   */
  enqueue(value: T, priority: number): void {
    let contains: boolean = false;
    const element: PQElement<T> = {
      value: value,
      priority: priority
    };

    /* 삽입할 요소의 우선순위가 더 높은 경우 앞에 삽입 */
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].priority > element.priority) {
        this.data.splice(i, 0, element);
        contains = true;
        break;
      }
    }

    /* 우선순위가 가장 낮은 경우 맨 뒤에 삽입 */
    if (!contains) {
      this.data.push(element);
    }
  }

  /**
   * 큐 내용의 앞에서부터 데이터를 반환하고 제거
   * 큐가 비어 있으면 <code>undefined</code>
   * @return {PQElement<T> | undefined}
   */
  dequeue(): PQElement<T> | undefined {
    return this.data.shift();
  }

  /**
   * 큐의 모든 내용을 삭제
   */
  clear(): void {
    this.data = [];
  }

  /**
   * 현재 <code>PriorityQueue</code> 객체를 deep clone하여 새로운 <code>PriorityQueue</code> 객체를 생성.
   * @return {PriorityQueue<T>} - deep clone 된 객체
   */
  deepClone(): PriorityQueue<T> {
    const clonedQueue: PriorityQueue<T> = new PriorityQueue<T>();
    for (let i = 0; i < this.data.length; i++) {
      clonedQueue.enqueue(this.data[i].value, this.data[i].priority);
    }

    return clonedQueue;
  }

  getElements(): PQElement<T>[] {
    return this.data;
  }
}
