/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-08
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-12-23.
 * <p> beginning01 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Beginning01DataParser extends MakeshopD4DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('div.cart-ct > table > tbody > tr')[i]
                      .querySelector('td:nth-child(6) > div.tb-center > span')!.textContent!.trim()
      const rawQty = (document.querySelectorAll('div.cart-ct > table > tbody > tr')[i]
                      .querySelector('td:nth-child(4) > div.tb-center > div.amount > input[name=amount]') as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]')[i]['branduid'] as HTMLInputElement).value,
        'productName': document.querySelectorAll('div.cart-ct > table > tbody > tr')[i].querySelector('td:nth-child(3) > div.prd-name > a')!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('div.cart-ct > table > tfoot > tr > td > div.tb-right > p > strong')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.order-box > div.order-box2 > div.order-list > table > tbody tr').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.order-box > div.order-box2 > div.order-list > table > tbody > tr')[i].querySelector('td:nth-child(2) > div.tb-left > p.prd-name > a') as HTMLAnchorElement;
      const qty = document.querySelectorAll('div.order-box > div.order-box2 > div.order-list > table > tbody > tr')[i].querySelector('td:nth-child(3) > div.tb-center')!.textContent!.trim();
      const price = document.querySelectorAll('div.order-box > div.order-box2 > div.order-list > table > tbody > tr')[i].querySelector('td:nth-child(4) > div.tb-center')!.textContent!.trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"]')[i].querySelector('p.SMSbasketPrice')!.textContent!.trim()!.replace(/\D/g, '');
      const rawQty = document.querySelectorAll('form[name="forms"]')[i].querySelector('p.custom_bq > b')!.textContent!.trim();

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('div.price-total-info-bottom > p > span')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };
  }
}
