/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-26
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2022-05-26
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class DecorosoDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    let jsonObj: JsonObject = super.getWebShopCollectData(formElement);
    jsonObj.data['productName'] = document.querySelector('.item_detail_tit h3')!.textContent!.trim();
    return jsonObj;
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let obj = super.getWebPayConvDataFromProductPage(formElement);
    obj['product'][0]['productName'] = document.querySelector('.item_detail_tit h3')!.textContent!.trim();
    return obj;
  }
}