/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-11
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";

/**
 * create on 2022-05-11
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class JosammosaDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('.cart_list .cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productName = rawProductList[i].querySelector('.goods_name')!.textContent!.trim();
        const params = (rawProductList[i].querySelector('.block1 .img_area a') as HTMLAnchorElement).search.substring(1).split('&');
        const paramObj = {};
        for (let j = 0; j < params.length; j++) {
          const key = params[j].split('=')[0];
          const val = params[j].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[2].textContent!.replace(/\D/g, ''));
        const productPrice = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[3].textContent!.replace(/\D/g, '')) / productQty;
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
          document.querySelector('.price, .settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}
