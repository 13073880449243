/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-09-02
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-09-02
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class PegasikoreaDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="naver-common-inflow-script-order-item"]');
    const ordCode: string = this.getConvJsonData(inputList, 0)['ordno'];
    const parsedTotalPrice: number = this.getParsedTotalPrice();

    let totalQty = 0;
    let totalPrice = 0;

    for (let i = 0; i < inputList.length; i++) {
      const convInfo: {} = this.getConvJsonData(inputList, i);
      const qty: number = convInfo['ea'];
      const rawPrice: number = convInfo['price'];
      if(!qty || !rawPrice) break;
      const price: number = rawPrice / qty;

      parsedProducts.push({
        'productCode': convInfo['goodsno'],
        'productName': convInfo['goodsnm'],
        'qty': qty.toString(),
        'price': price.toString()
      });

      totalQty += qty;
      totalPrice += rawPrice;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      /* 주문금액을 파싱하지 못하면 상품가격을 더한 금액을 전송 */
      'totalPrice': parsedTotalPrice === 0 || NumberUtil.isNaN(parsedTotalPrice)
          ? totalPrice.toString()
          : parsedTotalPrice.toString(),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }
}
