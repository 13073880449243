/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-08
 */

import {HostingType} from "../../types/GlobalEnums";

/**
 * create on 2020-04-08.
 * <p> FunctionExecuteObserver를 수행하는데 필요한 데이터 </p>
 * <p> {@link FunctionExecuteObserver } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class FunctionExecuteData {
  targetSelector: string;
  tagName: string;
  regExp: RegExp;
  attribute: string;

  constructor() {
    this.targetSelector = "";
    this.tagName = "";
    this.regExp = /()/;
    this.attribute = "";
  }
}
