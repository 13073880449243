/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-06-28
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-06-28.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Fitskr12DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
     try {
       if (this.isMobile(this.commandOptions)) {
         const ecProduct = document.querySelector('#ec-product-price-info')!;
         return {
           price: NumberUtil.parseNumber(ecProduct.getAttribute('ec-data-custom')!),
           dcPrice: NumberUtil.parseNumber(ecProduct.getAttribute('ec-data-price')!)
         }
       } 

       return super.getProductPrice();
     } catch (e) {
       return super.getProductPrice();
     }
  }
}
