/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-12
 */

import {CrmController} from "../CrmController";
import {HostingType} from "../../../../types/GlobalEnums";

/**
 * create on 2021-04-12
 * <p> 고도몰 CRM에 관한 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class GodoMallCrmController extends CrmController {
  constructor() {
    super(HostingType.GODOMALL_RENT);
  }

  isCrmAdvertiser(): boolean {
    return true;
  }
}
