/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-10-04.
 * <p> 삽입 정렬 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class InsertionSort<T> {
  /* 정렬 기준 */
  static CRITERIA = {
    'NUMBER': {
      'ASC': (firstVal: any, secondVal: any) => firstVal < secondVal,
      'DESC': (firstVal: any, secondVal: any) => firstVal > secondVal
    },
    'STRING': {
      'ASC': (firstVal: any, secondVal: any) => firstVal < secondVal,
      'DESC': (firstVal: any, secondVal: any) => firstVal > secondVal
    }
  };

  private constructor() {}

  /**
   * 정렬.
   * <b>NOTE : </b>새로운 배열을 생성하지 않고 입력된 배열을 정렬 후 리턴합니다.
   * @param {T[]} array - 정렬 대상이 되는 배열
   * @param {SortingCriteria} criteria  - 정렬 기준
   * @return {T[]}  - 정렬이 완료된 배열
   */
  static sort<T>(array: T[], criteria: SortingCriteria): T[] {
    let current: T;
    let j: number;
    for (let i = 1; i < array.length; i += 1) {
      current = array[i];
      j = i - 1;
      while (j >= 0 && criteria(current, array[j])) {
        array[j + 1] = array[j];
        j -= 1;
      }
      array[j + 1] = current;
    }
    return array;
  }
}

/* 정렬 기준이 되는 함수 타입 정의 */
type SortingCriteria = (firstVal: any, secondVal: any) => boolean;
