/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-24
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-12-24
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class GongcampDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    try{
      let superData:JsonObject = super.getShopCollectData();
      let prodUrlSplit = window.location.href.split('.asp');
      let prodUrl = prodUrlSplit.length > 1 ? prodUrlSplit[0] + '.asp' : prodUrlSplit[0];
      superData.replaceData('productUrl', prodUrl);
      return superData;
    }catch{
      return super.getShopCollectData();
    }
  }
}
