/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-07
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2021-04-07
 * <p> sainthome1 - http://sainthome.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Sainthome1DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    try {
      return this.isMobile(this.commandOptions) ? this.getMobileConversionData() : this.getWebConversionData();
    } catch (e) {
      return super.getConversionData_prev();
    }
  }

  private getMobileConversionData(): JsonObject {
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.ec-product-name');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('.xans-order-normalresultlist span.quantity')[i]!.textContent!.trim());

      parsedProducts.push({
        'productCode': productNameList[i].href.replace(location.origin+'/', '').split('/')[2],
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.xans-order-normalresultlist span.priceValue')[i]!.textContent!.trim())
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(location.search.substring(1)).getParam('order_id'),
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.totalPay strong.txtStrong')!.childNodes[0].textContent!.trim()),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  protected getWebConversionData(): JsonObject {
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('tbody.xans-order-normalresultlist strong.name a');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('tbody.xans-order-normalresultlist tr td:nth-child(4)')[i]!.textContent!.trim());

      parsedProducts.push({
        'productCode': productNameList[i].href.replace(location.origin+'/', '').split('/')[2],
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('tbody.xans-order-normalresultlist tr td:nth-child(3)')[i]!.textContent!.trim())
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(location.search.substring(1)).getParam('order_id'),
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.ec-base-table[nspace="normal"] table:first-child tfoot .txtEm span.txt18')!.textContent!.trim()),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
