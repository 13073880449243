/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {CartType, EventType} from "../../types/GlobalEnums";
import * as NaverTargetingConfig from './naverTargeting.config.json';
import {Tracker} from "../../tracker/Tracker";
import {EventUtil} from "../../lib/event/EventUtil";

/**
 * create on 2020-03-09.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NaverTargeting {
  private readonly adverId: string;
  private readonly eventType: EventType;
  private readonly cartType: CartType;
  private readonly eventName: string;
  private readonly eventTarget: EventTarget;
  private readonly _eventListener: EventListener;

  constructor(adverId: string, cartType: CartType) {
    self = this; // this 바인딩 이슈 방지
    this.adverId = adverId;
    this.cartType = cartType;
    this.eventType = EventType.CART;
    this.eventName = this.getParsedEventName();
    this.eventTarget = this.getParsedEventTarget();
    this._eventListener = this.invokeEvent.bind(self);
  }

  get eventListener(): EventListener {
    return this._eventListener;
  }

  addTargetingEvent(): void {
    EventUtil.addEvent(this.eventTarget, this.eventName, this._eventListener);
  }

  removeTargetingEvent(): void {
    EventUtil.removeEvent(this.eventTarget, this.eventName, this._eventListener);
  }

  private getParsedEventName(): string {
    return NaverTargetingConfig[this.cartType]['eventName'];
  }

  private getParsedEventTarget(): EventTarget {
    return document.querySelector(NaverTargetingConfig[this.cartType]['eventTargetSelector']);
  }

  private invokeEvent(): void {
    try {
      /* 똑같은 백그라운드 작업을 수행하지 않도록 하여 커맨드 실행 */
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand(['set', this.eventType, { 'cartType': this.cartType }]);
      tracker.executeCommand(['send', this.eventType, this.adverId]);
    } catch (e) {
      console.error(e);
    }
  }
}

/* 이벤트 등록 및 제거시 this 바인딩을 위한 변수 */
let self: NaverTargeting;
