/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-23
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-08-23
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class GeritcorpDataParser extends Cafe24SmartDataParser {
  
  protected getProductPrice(): { price: number; dcPrice: number } {
    try{
      if(this.isMobile(this.commandOptions)){
        let price = NumberUtil.parseNumber((document.querySelector('input.custom') as HTMLInputElement).value);
        let dcPrice = NumberUtil.parseNumber((document.querySelector('input.price') as HTMLInputElement).value);
        return {
          price: price > dcPrice ? price : dcPrice,
          dcPrice: dcPrice
        }
      }
      return super.getProductPrice();
    }catch(e){
      return super.getProductPrice();
    }
  }
}
