/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2022-08-11
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
* create on 2022-08-11.
 * <p> Nzorigin 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class NzoriginDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-order-prd tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a, .table-cart > table > tbody > tr > td.ta_left > a')[i] as HTMLAnchorElement;
      const qty = NumberUtil.parseNumber(document.querySelectorAll('div.table-cart.table-order-prd table tbody tr td:nth-child(4) div.tb-right')[i]!.textContent!.replace(/\D/g,''), NumberUtil.NUM_TYPE.INTEGER);
      const rawPrice =NumberUtil.parseNumber(document.querySelectorAll('div.table-cart.table-order-prd table tbody tr td:nth-child(5) div.tb-right')[i]!.textContent!.replace(/\D/g,''), NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': rawPrice /qty
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

}
