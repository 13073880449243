/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-06-18
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

export class PkyulDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {

    try {
      if (this.isMobile(this.commandOptions)) {
        return super.getProductPrice();
      }
      const priceList = document.querySelectorAll('table>tbody>tr>td');

      const price = priceList[1] ? NumberUtil.parseNumber(priceList[1].textContent!.replace(/\D/g, '')) : 0;
      const dcPrice = priceList[2] ? NumberUtil.parseNumber(priceList[2].textContent!.replace(/\D/g, '')) : 0;

      return {
        price: price,
        dcPrice: dcPrice
      }
    } catch (e) {
      return super.getProductPrice();
    }
  }
}