/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Command } from './core/Command';
import { CommandBuilder } from './core/CommandBuilder';
import { JsonObject } from '../../lib/json/JsonObject';

/**
 * create on 2019-08-25.
 * <p> SET 커맨드. 추가적으로 전송할 데이터를 세팅 </p>
 * <p> {@link CommandBuilder} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class SetCommand extends Command {
  /* 클라이언트 코드에서 전달 받은 추가 데이터 */
  private readonly jsonData: JsonObject;

  constructor(builder: CommandBuilder) {
    super(builder);
    this.jsonData = builder.jsonData;
  }

  executeCommand(): void {
    /* 현재는 아무런 작업을 하지 않지만, 추후에 수행할 작업이 생기면 여기서 구현. */
  }
}
