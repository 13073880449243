/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-09
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-11-09.
 * <p> Clomount 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ClomountDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    let soldout = "N";
    if (this.isMobile(this.commandOptions)) {
      soldout = document.querySelector('div.box-wrap a.btn-soldout:not(.displaynone)') != null ? "Y": "N";
    }else{
      soldout = document.querySelector('span.btn-soldout:not(.displaynone)') != null ? "Y": "N";
    }
    jsonObj.data['soldOut'] =soldout;
    return jsonObj;
  }
}
