/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CommandBuilder } from './CommandBuilder';
import { EventType } from '../../../types/GlobalEnums';

/**
 * create on 2019-08-23.
 * <p> 클라이언트 코드에서 전달받은 커맨드를 수행 </p>
 * <p> {@link CommandConfig}, {@link CommandBuilder} and {@link CommandFactory} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class Command {
  /* 이벤트 타입 */
  protected readonly eventType: EventType;

  protected constructor(builder: CommandBuilder) {
    this.eventType = builder.eventType;
  }

  /**
   * 커맨드 실행
   */
  abstract executeCommand(): void;
}
