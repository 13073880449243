/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-07-17
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2023-07-17
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class CycleanDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
      const parsedProducts: Array<{}> = [];
      const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="text"][name^="goodsCnt[]"]');
      let totalPrice = 0;
      let totalQty = 0;
        for (let i = 0; i < inputList.length; i++) {
        const qty = NumberUtil.parseInteger(inputList[i].dataset['defaultGoodsCnt'] as string);
        const rawPrice = NumberUtil.parseNumber(document.querySelectorAll('#frmCart td strong.price')[i].textContent!.replace(/\D/g,''));
        const price = rawPrice / qty;
  
        parsedProducts.push({
          'productCode': inputList[i].dataset['goodsNo'],
          'productName': inputList[i].dataset['goodsNm'],
          'qty': qty,
          'price': price
        });
  
        totalPrice += rawPrice;
        totalQty += qty;
      }
      jsonObj['product'] = parsedProducts;
      jsonObj['totalQty'] = totalQty;
    }
    return jsonObj;
  }

}