/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-29
 */
import {ValidationRuleImpl} from "../ValidationRuleImpl";
import {StringUtil} from "../../../lib/common/StringUtil";
import {InvalidValueError} from "../../../error/InvalidValueError";

/**
 * create on 2020-04-29.
 * <p> URL의 scheme 검증 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class SchemeValidRule extends ValidationRuleImpl {
  private schemeRegex = /^(http:|https:)/;

  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    const url: string = property as string;
    if (StringUtil.contains(url, this.schemeRegex)) {
      return url;
    } else {
      throw new InvalidValueError('URL scheme', url);
    }
  }
}