/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-14
 */

/**
 * create on 2019-11-14.
 * <p> 값의 타입을 확인하거나 기타 기능이 포함된 유틸. </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Value {
  private constructor() {}

  /**
   * 입력한 값이 <code>undefined</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>undefined</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>undefined</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfUndefined(value: any): boolean {
    return this.isTypeOf(value, TYPE.undefined);
  }

  /**
   * 입력한 값이 <code>string</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>string</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>string</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfString(value: any): boolean {
    return this.isTypeOf(value, TYPE.string);
  }

  /**
   * 입력한 값이 <code>number</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>number</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>number</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfNumber(value: any): boolean {
    return this.isTypeOf(value, TYPE.number);
  }

  /**
   * 입력한 값이 <code>object</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>object</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>object</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfObject(value: any): boolean {
    return this.isTypeOf(value, TYPE.object);
  }

  /**
   * 입력한 값이 <code>function</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>function</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>function</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfFunction(value: any): boolean {
    return this.isTypeOf(value, TYPE.function);
  }

  /**
   * 입력한 값이 <code>bigint</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>bigint</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>bigint</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfBigint(value: any): boolean {
    return this.isTypeOf(value, TYPE.bigint);
  }

  /**
   * 입력한 값이 <code>symbol</code> 타입인지 확인
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>symbol</code> 타입일 경우</p>
   * <p><code>false</code> - 값이 <code>symbol</code> 타입일이 아닐 경우</p>
   */
  static isTypeOfSymbol(value: any): boolean {
    return this.isTypeOf(value, TYPE.symbol);
  }

  /**
   * 값이 <code>null</code>인지 확인.
   * @param value - 확인할 값
   * @return {boolean}
   * <p><code>true</code> - 값이 <code>null</code>일 경우</p>
   * <p><code>false</code> - 값이 <code>null</code>이 아닐 경우</p>
   */
  static isNull(value: any): boolean {
    return value === null;
  }

  static isEmpty(value: any): boolean {
    return value === '';
  }

  /**
   * 가져와야 하는 값이 없을 경우 기본값으로 대체.
   * @param requiredValue 가져올 값
   * @param defaultValue  가져올 값이 <code>undefined</code> 또는 <code>null</code>일 때 초기화될 기본값
   * @return {any}        최종 값
   */
  static getValue(requiredValue: any, defaultValue: any): any {
    return this.isTypeOfUndefined(requiredValue) || this.isNull(requiredValue) || this.isEmpty(requiredValue)
        ? defaultValue
        : requiredValue;
  }

  /**
   * <code>typeof</code> 키워드를 이용해 입력한 값이 입력한 타입에 해당하는지 확인.
   * @param value - 값
   * @param {TYPE} type - 타입
   * @return {boolean}  - 값이 타입에 해당하는지
   * <p><code>true</code> - 값이 타입에 해당함</p><p><code>false</code> - 값이 타입에 해당하지 않음</p>
   */
  private static isTypeOf(value: any, type: TYPE): boolean {
    return typeof value === type;
  }
}

/**
 * <code>typeof</code> 키워드로 사용할 수 있는 타입 정의
 */
enum TYPE {
  undefined = 'undefined',
  string = 'string',
  number = 'number',
  object = 'object',
  function = 'function',
  bigint = 'bigint',
  symbol = 'symbol'
}
