/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-10-07.
 * <p> 브라우저에 경고창이 표시되었는지 체크하는 클래스 </p>
 * <p> (간편 결제 시스템 컨버전을 위해 생성된 클래스) </p>
 * <p> {@link PaySystem} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class AlertChecker {
  /* 인스턴스 */
  private static instance: AlertChecker;
  /* Alert 표시 여부 */
  private static alertCalled: boolean;

  private constructor() {
    AlertChecker.alertCalled = false;
  }

  /**
   * 인스턴스 생성
   * @return {AlertChecker}
   */
  static getInstance(): AlertChecker {
    if (!AlertChecker.instance) {
      AlertChecker.instance = new AlertChecker();
    }

    return AlertChecker.instance;
  }

  /**
   * Alert 표시 여부 체크
   * @param {boolean} alertCalledCondition  - alert이 표시되었는지 체크할 수 있는 조건
   */
  check(alertCalledCondition: boolean): void {
    this.modifyDefaultAlertFunc(alertCalledCondition);
  }

  /**
   * Alert 표시 여부를 표시되지 않음으로 변경.
   */
  setAsNotCalled(): void {
    AlertChecker.alertCalled = false;
  }

  /**
   * Alert 표시 여부를 반환
   * @return {boolean}
   * - <code>true</code> : Alert 표시 되었음.
   * - <code>false</code> : Alert 표시 되지 않았음.
   */
  getAlertCalled(): boolean {
    return AlertChecker.alertCalled;
  }

  /**
   * 기존의 <code>window.alert()</code> 함수를 수정
   * @param {boolean} alertCalledCondition  - alert이 표시되었는지 체크할 수 있는 조건
   */
  private modifyDefaultAlertFunc(alertCalledCondition: boolean): void {
    const defaultAlertFunc = window.alert;
      window.alert = function(message?: any) {
      AlertChecker.alertCalled = alertCalledCondition;
      defaultAlertFunc.apply(window, [message]);
    };
  }
}
