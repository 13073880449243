import { NumberUtil } from "../../../lib/common/NumberUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-29
 */
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";

/**
 * create on 2022-01-29.
 * <p> dentiluv 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SleepgonggamDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productRows = document.querySelectorAll('.orderListArea .xans-order-list tr');
    let totalQty = 0;

    for (let i = 0; i < productRows.length; i++) {
      const rawPrice = productRows[i].querySelector('.total strong')!.textContent!.replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('.orderListArea .xans-order-list [name=orgamount]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('.orderListArea .xans-order-list input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': productRows[i].querySelector('.product strong.name')!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.MK_total_price span.MK_chg_total_price')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  /**
     * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
     * @return {{}} 파싱 결과
     */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productRows = document.querySelectorAll('.prdInfo form[name="forms"]');

    let totalQty = 0;

    for (let i = 0; i < productRows.length; i++) {
      const rawPrice = productRows[i].querySelector('.info .price span')!.textContent!.replace(/\D/g, '');
      const rawQty = (productRows[i].querySelector('input[name="orgamount"]') as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (productRows[i].querySelector('input[name="branduid"]') as HTMLInputElement).value,
        'productName': (productRows[i].querySelector('.ec-product-name') as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('.totalSummary .total.order strong')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

}
