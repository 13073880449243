/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-06-17
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-06-17
 * <p> amicogen - http://www.knutra.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class AmicogenDataParser extends MakeshopD4DataParser {
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('div.table-cart tr td div.tb-price span')[i]!.textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll([
          'div.table-cart input[name="branduid"]',
          'div.table-wrap input[name="branduid"]',
          'div#cart div.base-table table input[name="branduid"]',
          'div#cartWrap table input[name="branduid"]',
          'div.orderListArea table input[name="branduid"]',
          'div.cart-list input[name="branduid"]'
        ])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll([
          'div.table-cart tr td div.tb-left > a',
          'table.cart_box_a tr td div.tb-left > a',
          '.PHPS_cartWrap .PHPS_tableRow .name',
          'div.table-cart tr td div.tb-left a', // pippin11
          'div.table-cart tr td.goods_opt>div.goods_title>a', // chicfox
          'div.table-wrap div.cart-product li.name > a', // sappun
          'div#cart div.base-table table tr td:nth-child(3) > div > a',
          'div#cartWrap table a.name',
          'div.orderListArea table td.product a',
          'div#cartWrap table td:nth-child(4) > div > a',
          'div.cart-list > table a.name',
          'div.table-cart tbody tr td:nth-child(3) a.fc_bk'
        ])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productFormElement = document.querySelectorAll('form[name="forms"]');

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productFormElement.length; i++) {
      const rawPrice = productFormElement[i].querySelector('figure>figcaption>p:nth-child(2)')!.textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (productFormElement[i].querySelector('figure>figcaption .amount-input') as HTMLInputElement).value;

      const price = NumberUtil.parseRationalNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (productFormElement[i].querySelector('figure>figcaption>p.pname>a') as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.txt-price')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj:JsonObject = super.getWebConversionData();
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    const productNameAchor:NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.order-list>table>tbody tr a');
    for (let i = 0; i < productNameAchor.length; i++) {
      const qty = NumberUtil.parseNumber(document.querySelectorAll('div.order-list>table>tbody tr td:nth-child(3) div')[i]!.textContent!.trim());
      parsedProducts.push({
        'productCode': new QueryStringer(productNameAchor[i].search.substring(1)).getParam('branduid'),
        'productName': productNameAchor[i].textContent,
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('div.order-list>table>tbody tr td:nth-child(4) div')[i]!.textContent!.trim()),
      });

      totalQty += qty;
    }

    jsonObj.addData('product', parsedProducts);
    jsonObj.addData('totalQty', totalQty);
    return jsonObj;
  }
}
