/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {EventUtil} from '../../lib/event/EventUtil';
import {AJAXer} from "../../lib/ajax/AJAXer";
import {StringUtil} from "../../lib/common/StringUtil";
import {ClientInfo} from "../../lib/common/ClientInfo";

/**
 * TODO test case
 * create on 2019-09-19.
 * <p> 사용자가 페이지에 머무른 체류시간(Time on Page) 계산하여 전송 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class TimeOnPage {
  /* 광고주 ID */
  private readonly adverId: string;
  /* 상품코드 */
  private readonly productCode: string;
  /* 페이지 방문 시점의 시간 */
  private hitTime!: Date;
  /* 페이지 이탈 시점의 시간 */
  private bounceTime!: Date;
  /* 페이지 체류시간 (초) */
  private timeOnPageSec: number;
  /* 방문 시점이 기록되었는지 여부 */
  private hitRecorded: boolean;
  /* 이탈 시점이 기록되었는지 여부 */
  private bounceRecorded: boolean;
  /* 페이지 체류시간이 기록되었는지 여부 */
  private timeOnPageRecorded: boolean;
  /* 호출 URL */
  private readonly _url: string;

  /* 이벤트 리스너 */
  private readonly recordHitTimeEventListener: EventListener;
  private readonly recordBounceTimeEventListener: EventListener;

  /*
   * 최대 시간 (초)
   * 이 시간이 지나면 바로 서버로 전송한다
   */
  private static readonly MAX_TIME_SEC: number = 60;

  constructor(adverId: string, productCode: string) {
    self = this; // this 바인딩 이슈 방지
    this.adverId = adverId;
    this.productCode = productCode;
    this.timeOnPageSec = 0;
    this.hitRecorded = false;
    this.bounceRecorded = false;
    this.timeOnPageRecorded = false;
    this.recordHitTimeEventListener = this.recordHitTime.bind(self);
    this.recordBounceTimeEventListener = this.recordBounceTime.bind(self);
    this._url = 'https://tk.mediacategory.com/aggregate/mssg/duration';
  }

  get url(): string {
    return this._url;
  }

  /**
   * 페이지 방문시 수행되는 이벤트 등록
   * <b>NOTE: </b>이벤트를 사용하려면 {@link invokeRecordHitTime} 메소드 이용.
   */
  addRecordHitTimeEvent(): void {
    EventUtil.addEvent(window, 'load', this.recordHitTimeEventListener);
  }

  /**
   * 페이지 이탈시 수행되는 이벤트 등록
   * <b>NOTE: </b>이벤트를 사용하려면 {@link invokeRecordBounceTime} 메소드 이용.
   */
  addRecordBounceTimeEvent(): void {
    EventUtil.addEvent(
        window,
        'beforeunload',
        this.recordBounceTimeEventListener
    );
  }

  /**
   * 방문 시점을 이벤트를 이용하지 않고 바로 기록.
   * <b>NOTE: </b>이벤트를 사용하려면 {@link addRecordHitTimeEvent} 메소드 이용.
   */
  invokeRecordHitTime(): void {
    this.recordHitTime();
  }

  /**
   * 이탈 시점을 이벤트를 이용하지 않고 바로 기록.
   * <b>NOTE: </b>이벤트를 사용하려면 {@link addRecordBounceTimeEvent} 메소드 이용.
   */
  invokeRecordBounceTime() : void {
    this.recordBounceTime();
  }

  /**
   * 페이지 방문시 수행되도록 등록한 이벤트 제거
   */
  private removeRecordHitTimeEvent(): void {
    EventUtil.removeEvent(window, 'load', this.recordHitTimeEventListener);
  }

  /**
   * 페이지 이탈시 수행되도록 등록한 이벤트 제거
   */
  private removeRecordBounceTimeEvent(): void {
    EventUtil.removeEvent(
        window,
        'beforeunload',
        this.recordBounceTimeEventListener
    );
  }

  /**
   * 방문 시점의 시간 기록
   */
  private recordHitTime(): void {
    if (!this.hitRecorded) {
      this.hitTime = new Date();
      this.hitRecorded = true;

      /* 일정 시간이 지나면 체류시간 전송 */
      window.setTimeout(() => {
        /* 우연히 시간초과와 'beforeunload' 이벤트가 발생한 시점이 같은 경우를 방지 */
        if (!this.timeOnPageRecorded) {
          this.sendTimeOnPage();
        }
      }, TimeOnPage.MAX_TIME_SEC * 1000);
    }
  }

  /**
   * 이탈 시점의 시간 기록
   */
  private recordBounceTime(): void {
    if (!this.bounceRecorded) {
      this.bounceTime = new Date();
      this.bounceRecorded = true;

      /* 페이지 이탈시 체류시간 전송 */
      this.sendTimeOnPage();
    }
  }

  /**
   * 페이지 체류시간 전송
   */
  private sendTimeOnPage(): void {
    this.calculateTimeOnPage();

    if (this.timeOnPageRecorded) {
      AJAXer.getInstance().post(this._url, {
        'adverId': this.adverId,
        'timeOnPage': this.timeOnPageSec,
        'productCode': this.productCode,
        'domain': StringUtil.getDomainFromUrl(window.location.href),
        'cookieEnabled': ClientInfo.getInstance().cookieEnabled
      });
      this.removeRecordHitTimeEvent();
      this.removeRecordBounceTimeEvent();
    }
  }

  /**
   * 페이지 체류시간 계산
   */
  private calculateTimeOnPage(): void {
    const hitTimeMilli: number = this.hitTime.getTime();

    /* 체류시간 계산 (반올림) */
    if (this.hasUserBounce()) {
      /* 페이지를 이탈한 경우 */
      const bounceTimeMilli: number = this.bounceTime.getTime();
      this.timeOnPageSec = Math.round((bounceTimeMilli - hitTimeMilli) / 1000);
      this.timeOnPageRecorded = true;
    } else {
      /* 페이지를 이탈하지 않은 경우 (시간초과) */
      const currentTimeMilli: number = new Date().getTime();
      this.timeOnPageSec = Math.round((currentTimeMilli - hitTimeMilli) / 1000);
      this.timeOnPageRecorded = true;
    }
  }

  /**
   * 사용자의 페이지 이탈 여부
   * @return {boolean}
   * <p><code>true</code> - 이탈하였음</p><p><code>false</code> - 이탈하지 않았음</p>
   */
  private hasUserBounce(): boolean {
    return this.hitRecorded ? this.hitRecorded && this.bounceRecorded : false;
  }
}

/* 이벤트 등록 및 제거시 this 바인딩을 위한 변수 */
let self: TimeOnPage;
