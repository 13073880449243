/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-25
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-25
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Iw0441DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 상품 상세페이지에서 금액정보를 파싱후 리턴
   * @param formElement 상품정보가 담겨있는 formElement
   * @protected
   * @return {price:number, dcPrice: number}
   */
  // TODO 리팩토링 할 필요성이 있음
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      let price = 0, dcPrice = 0;

      const discountPriceValue = NumberUtil.parseRationalNumber(formElement['set_goods_fixedPrice']
        ? formElement['set_goods_fixedPrice'].value : '0');
      if (discountPriceValue > 0) {
        price = discountPriceValue;
        dcPrice = NumberUtil.parseRationalNumber((formElement!['set_goods_price'] as HTMLInputElement).value);

        return { 'price': price, 'dcPrice': dcPrice };
      }

      return { 'price': formElement!['set_goods_price'].value, 'dcPrice': 0 }
    } catch (e) {
      return { 'price': formElement!['set_goods_price'].value, 'dcPrice': 0 }
    }
  }
}