/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-26
 */
import {NotSupportedError} from "../../error/NotSupportedError";
import {EventType} from "../../types/GlobalEnums";
import {PriorityQueue} from "../../lib/dataStructure/queue/PriorityQueue";
import {PQElement} from "../../lib/dataStructure/queue/element/PQElement";

/**
 * create on 2020-05-26.
 * <p> 상품코드를 저장하는 스토리지 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ProductStorage {
  /* 상품코드 배열의 저장 가능한 최대 요소 갯수 */
  private readonly maxItemLength: number;

  /* 최대 가능한 우선순위 */
  private readonly maxPriority: number;

  /* 스토리지 */
  private readonly storage: Storage;

  constructor() {
    if (ProductStorage.whetherLocalStorageSupported()) {
      this.storage = window.localStorage;
      this.maxItemLength = 5;
      this.maxPriority = 100;
    } else {
      throw new NotSupportedError('Storage API');
    }
  }

  /**
   * 스토리지에 상품코드 배열을 저장.
   * 오류 발생시 아무런 동작 하지 않음.
   * @param {EventType} eventType - 이벤트 타입
   * @param {string[]} productCodeArr - 저장할 상품코드 배열
   */
  save(eventType: EventType, productCodeArr: string[]): void {
    try {
      const storageKey: string = ProductStorage.createStorageKey(eventType);
      const collectedArr: string[] = this.load(eventType);
      const elements: { [productCode: string]: PQElement<string> } = {};

      /* 이미 저장되어 있던 상품코드들의 우선순위 지정 */
      for (let i = 0; i < collectedArr.length; i++) {
        const productCode: string = collectedArr[i];
        const priority = this.maxPriority - (collectedArr.length - 1) + i;
        elements[productCode] = {
          value: productCode,
          priority: priority
        };
      }

      /* 새로 수집한 코드에 대한 우선순위 지정 */
      productCodeArr.forEach((productCode: string) => {
        if (elements[productCode]) {
          elements[productCode].priority = 0;
        } else {
          elements[productCode] = {
            value: productCode,
            priority: 0
          };
        }
      });

      /* 우선순위 큐 생성 */
      const priorityQueue: PriorityQueue<string> = new PriorityQueue<string>();
      for (let productCode in elements) {
        const element: PQElement<string> = elements[productCode];
        priorityQueue.enqueue(element.value, element.priority);
      }

      /* 우선순위 큐에 담긴 상품코드만 배열로 생성*/
      let resultArr: string[] = [];
      const currentSize: number = priorityQueue.size();
      for (let i = 0; i < currentSize; i++) {
        const item: PQElement<string> = priorityQueue.dequeue()!;

        if (item) {
          resultArr.push(item.value);
        }
      }

      /* 최대 갯수가 넘었을 때 넘는 요소들을 지운다 */
      resultArr = resultArr.slice(0, this.maxItemLength);

      /* storage에 저장 */
      this.storage.setItem(storageKey, JSON.stringify(resultArr));
    } catch (e) {}
  }

  /**
   * 스토리지에 저장된 상품코드 배열을 리턴.
   * 오류 발생시 아무런 동작 하지 않음.
   * @param {EventType} eventType - 이벤트 타입
   * @return {string[]} - 저장된 상품코드 배열
   */
  load(eventType: EventType): string[] {
    try {
      const storageKey: string = ProductStorage.createStorageKey(eventType);
      const rawItem: string | null = this.storage.getItem(storageKey);

      if (rawItem === null || rawItem.toString().length === 0) {
        return [];
      } else {
        return JSON.parse(this.storage.getItem(storageKey)!) as string[];
      }
    } catch (e) {
      return [];
    }
  }

  /**
   * 브라우저의 Storage 지원 여부
   * @return {boolean}
   * <p><code>true</code> - 지원함</p><p><code>false</code> - 지원하지 않음</p>
   */
  private static whetherLocalStorageSupported(): boolean {
    try {
      return !!window.localStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * 이벤트 타입에 따른 스토리지 키 생성
   * @param {EventType} eventType - 이벤트 타입
   * @return {string} 스토리지 키 (이벤트 타입이 잘못되면 빈 값)
   */
  private static createStorageKey(eventType: EventType): string {
    switch (eventType) {
      case EventType.COLLECT:
        return 'ENP_COLLECT';
      case EventType.CART:
        return 'ENP_CART';
      case EventType.WISH:
        return 'ENP_WISH';
      case EventType.CONVERSION:
        return 'ENP_CONVERSION';
      default:
        return '';
    }
  }

  private isMaximum(array: string[]): boolean {
    try {
      return array.length >= this.maxItemLength;
    } catch (e) {
      return false;
    }
  }

  /**
   * 새로 추가될 요소의 갯수에 대해 먼저 추가된 요소들을 제거
   * @param {string[]} array  - 대상 배열
   * @param {number} lengthToAdd  - 추가될 요소의 갯수
   */
  private removeEarlierItems(array: string[], lengthToAdd: number): void {
    try {
      const lengthToRemove: number = array.length + lengthToAdd - this.maxItemLength;
      for (let i = 0; i < lengthToRemove; i++) {
        array.shift();
      }
    } catch (e) {}
  }
}
