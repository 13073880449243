import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-30
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-10-30.
 * <p> everfree 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class EverfreeDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    return (document.querySelector('#gallery > div.rsOverflow > div.rsContainer > div.rsSlide:first-child > img') as HTMLImageElement)!.src;
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return document.querySelector('div.product-info > div.name > h3')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();

    const price = document.querySelector('span.price-was')
                ? NumberUtil.parseNumber(document.querySelector('span.price-was')!.textContent!.trim())
                : NumberUtil.parseNumber(document.querySelector('span.price-now')!.textContent!.trim());

    const dcPrice = document.querySelector('span.price-now')
                  ? NumberUtil.parseNumber(document.querySelector('span.price-now')!.textContent!.trim())
                  : NumberUtil.parseNumber(document.querySelector('span.price-was')!.textContent!.trim());

    jsonObj.replaceData('price', price);
    jsonObj.replaceData('dcPrice', dcPrice);

    return jsonObj;
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();

    jsonObj.replaceData('product', window[this.ENP_OBJECT_NAME]['product']);
    jsonObj.replaceData('totalQty', window[this.ENP_OBJECT_NAME]['totalQty'])

    return jsonObj;
  }

  protected getMobileConversionData(): JsonObject {
    const jsonObj = super.getMobileConversionData();

    jsonObj.replaceData('product', window[this.ENP_OBJECT_NAME]['product']);
    jsonObj.addData('totalQty', window[this.ENP_OBJECT_NAME]['totalQty']);

    return jsonObj;
  }

}
