/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-17
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-08-17.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ConchwearDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();

    if(this.isMobile(this.commandOptions)){
      jsonObj.replaceData('productName', document.querySelector('#contents .xans-product-detail > h1')!.textContent!.trim());
    }else{
      jsonObj.replaceData('productName', document.querySelector('#contents .xans-product-detail div.detailArea div.infoArea div.prd_info div.headingArea h2')!.textContent!.trim());
    }
    
    return jsonObj;
  }
  
}
