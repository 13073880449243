import {JsonObject} from "../../../lib/json/JsonObject";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-05
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-10-05.
 * <p> clicknfunny 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class ClicknfunnyDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i].textContent!.split(':')[1].trim());
    }

    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('span.txt-price')!.textContent!.trim());
    return jsonObj;
  }

  protected getImageUrl(): string {
    if (document.querySelector('#detail > div.topwrap > div.left > div.det_prd_img > img')) {
      // PC WEB
      return (document.querySelector('#detail > div.topwrap > div.left > div.det_prd_img > img') as HTMLImageElement).src;
    } else {
      // Mobile
      return (document.querySelector('section.shopdetailInfo > div.SMS_ShopDetail_layout > div.SMS_ShopDetail_layout_left > div.shopdetailInfoTop > figure > img') as HTMLImageElement).src;
    }
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();

    const convProduct = window[this.ENP_OBJECT_NAME]['conversion']['product'];
    const products: Array<{}> = [];

    let totalQty = 0;

    for (let i = 0; i < convProduct.length; i++) {
      const qty = convProduct[i]['qty'];
      const price = convProduct[i]['price'];

      const parsedData: {} = {
        'productCode': convProduct[i]['productCode'],
        'productName': convProduct[i]['productName'],
        'price': convProduct[i]['price'],
        'qty': convProduct[i]['qty']
      }

      products.push(parsedData);

      totalQty += qty;
    }

    jsonObj.replaceData('totalPrice', window[this.ENP_OBJECT_NAME]['conversion']['totalPrice']);
    jsonObj.replaceData('totalQty', totalQty);
    jsonObj.replaceData('product', products);

    return jsonObj;
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('section.shopdetailInfo > div.SMS_ShopDetail_layout > div.SMS_ShopDetail_layout_right > div.shop_de_name > div.shop_name_in > span.name')!.textContent!.trim()
            : document.querySelector('#form1 > div.titname')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }
}
