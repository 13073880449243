/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-07-24
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-07-27.
 * <p> Shoesko 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class ShoeskoDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(2), form[name="forms"]>figure>div.basketBody p.price-sell')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-child, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }
    if (document.querySelector("div.price-total-info-top div:nth-child(10)")) {
      totalPrice = NumberUtil.parseNumber(document.querySelector("div.price-total-info-top div:nth-child(10)")!.textContent!.trim().replace(/\D/g, ''));
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }


  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-right')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-bold')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span, table.cart_box_a tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left > a, table.cart_box_a tr td div.tb-left > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }
    if (document.querySelector('div.tb-center > div > div > strong')) {
      totalPrice = NumberUtil.parseNumber(document.querySelector('div.tb-center > div > div > strong')!.textContent!.trim()!.replace(/\D/g, ''));
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
        : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
        : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }


  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
          ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
          : window[this.ENP_OBJECT_NAME]['producName'];
      }
    } catch (e) {
      return '';
    }
  }

}
