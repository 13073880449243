/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-06-04
 */


import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {NumberUtil} from "../../../lib/common/NumberUtil";

export class WhodingsDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }


  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = document.querySelector('span.txt-price')
                    ? (document.querySelector('span.txt-price') as HTMLSpanElement).textContent!.replace(/\D/g,'')
                    : 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('p.SMSbasketPrice')[i] as HTMLAnchorElement).textContent!.trim().replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('p.custom_bq')[i] as HTMLAnchorElement).textContent!.trim().replace(/\D/g,'');

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice === 0 ? totalPrice += price : totalPrice;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('li input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('li p.pname')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

}