/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-07-29.
 * <p> Request 파라미터를 객체로 다루는 모듈 </p>
 *
 * TODO: deepClone 메소드를 ObjectUtil 클래스로 분리???
 * @version 1.0
 * @author sghwang
 */
export class Parameter {
  private _params: {}; /* 모든 파라미터를 나타내는 객체 */
  private _length: number; /* 갖고 있는 파라미터의 갯수 */

  /**
   * 생성자.
   * <code>paramObj</code>이 <code>undefined</code>면 <code>_params</code>는 빈 객체가 되며,
   * 그렇지 않을 경우 <code>paramObj</code>를 <b>deep clone</b> 한다.
   * <code>paramObj</code>에 따라 <code>_length</code>를 설정.
   * @param {{}} [paramObj] - parameter 객체
   */
  constructor(paramObj?: {}) {
    this._params =
      typeof paramObj === 'undefined' ? {} : this.deepClone(paramObj);
    this._length = this.getParamsLength();
  }

  /* Getter and Setter */
  get params(): {} {
    return this._params;
  }

  set params(paramObj: {}) {
    this._params =
      typeof paramObj === 'undefined' ? {} : this.deepClone(paramObj);
    this._length = this.getParamsLength();
  }

  get length(): number {
    return this._length;
  }

  /* ES 5를 위한 Getter와 Setter */
  /* sub class에서 super 키워드로 super class의 Setter와 Getter에 접근하는 것은 ES 6부터 가능하다 */
  getParams(): {} {
    return this.params;
  }

  setParams(paramObj: {}) {
    this.params = paramObj;
  }

  getLength(): number {
    return this.length;
  }

  /**
   * 이 Setter에는 접근할 수 없음
   * @param {number} value
   */
  set length(value: number) {
    throw new Error('No one can access to "Setter" of this member field.');
  }

  /**
   * 파라미터 객체가 비어있는지 확인
   * <pre>
   *   // _params = { id: 'user123', pw: '1q2w3e4r' }
   *   hasParams();  // -> true
   *
   *   // _params = {}
   *   hasParams();  // -> false
   * </pre>
   * @return {boolean} - <code>true</code>: 비어있지 않음. <p><code>false</code>: 비어 있음.</p>
   */
  private hasParams(): boolean {
    return (
      typeof this._params === 'object' && Object.keys(this._params).length > 0
    );
  }

  /**
   * 특정 파라미터가 있는지 확인
   * <pre>
   *   // _params = { id: 'user123', pw: '1q2w3e4r' }
   *   hasParam('id');   // -> true
   *   hasParam('age');  // -> false
   * </pre>
   * @param {string} field - 비어 있는지 확인할 파라미터의 field
   * @return {boolean} - <code>true</code>: 해당 파라미터가 있음. <p><code>false</code>: 해당 파라미터가 없음.</p>
   */
  private hasParam(field: string): boolean {
    return this.hasParams() && this._params[field];
  }

  /**
   * 파라미터 객체가 갖고 있는 파라미터 갯수
   * <pre>
   *   // _params = { id: 'user123', pw: '1q2w3e4r' }
   *   getParamsLength(); // -> 2
   *
   *   // _params = {}
   *   getParamsLength(); // -> 0
   * </pre>
   * @return {number} - 파라미터 갯수
   */
  private getParamsLength(): number {
    return this.hasParams() ? Object.keys(this._params).length : 0;
  }

  /**
   * TODO ObjectUtil 클래스의 메소드를 이용해서 구현할 것.
   * <code>copyFrom</code> 객체를 deep clone 후 새로운 객체를 만든다.
   * <pre>
   *   let copyFrom = { name: 'sghwang', age: '29' };
   *   deepClone(copyFrom);
   *
   *   // _params = {}
   *   getParamsLength(); // -> 0
   * </pre>
   * @param {{}} copyFrom - 복사할 객체
   * @return {{}} - deep clone 하여 만들어진 새로운 객체. <p>exception 발생시 빈 객체 리턴</p>
   */
  private deepClone(copyFrom: {}): {} {
    try {
      const copyTo = {};
      const paramKeysArr = Object.keys(copyFrom);

      for (let i = 0; i < paramKeysArr.length; i++) {
        const objKey = paramKeysArr[i];
        copyTo[objKey] = copyFrom[objKey];
      }

      return copyTo;
    } catch (e) {
      return {};
    }
  }

  /**
   * 현재 파라미터에 새로운 파라미터를 추가.
   * <code>replace</code>가 <code>true</code>인 경우 이미 존재하는 field의 value를 새로운 파라미터로 대체.
   * <code>replace</code>가 <code>false</code>인 경우 이미 존재하는 field의 value를 그대로 사용.
   * exception 발생시 파라미터를 추가하지 않음.
   * <pre>
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   addParam('name', 'sghwang'); // added. --> { id: 'user11', pw: '1q2w3e4r', name: 'sghwang' }
   *
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   addParam('id', 'sghwang0608'); // replaced. --> { id: 'sghwang0608', pw: '1q2w3e4r' }
   *
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   addParam('id', 'sghwang0608', false); // not replaced. --> { id: 'user11', pw: '1q2w3e4r' }
   * </pre>
   * @param {string} field - 추가될 파라미터의 field
   * @param {string} value - 추가될 파라미터의 value
   * @param {boolean} [replace = true] - 파라미터가 이미 존재하는 경우 value의 대체 여부
   */
  addParam(field: string, value: string, replace = true) {
    try {
      if (this.hasParam(field) && !replace) {
        return;
      }

      this._params[field] = value;
      this._length = this.getParamsLength();
    } catch (e) {
      console.debug(e);
      return;
    }
  }

  /**
   * 현재 파라미터에서 특정 파라미터를 제거.
   * 존재하지 않는 파라미터 입력시 아무런 행동을 하지 않음.
   * exception 발생시 파라미터를 제거하지 않음.
   * <pre>
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   removeParam('id'); // removed. --> { pw: '1q2w3e4r' }
   *
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   removeParam('name'); // there is nothing to remove. --> { id: 'user11', pw: '1q2w3e4r' }
   * </pre>
   * @param {string} field - 제거될 파라미터의 field
   */
  removeParam(field: string) {
    try {
      if (this.hasParam(field)) {
        const paramKeysArr = Object.keys(this._params);
        const newParams = {};

        for (let i = 0; i < this._length; i++) {
          const key = paramKeysArr[i];
          if (key === field) {
            continue;
          }

          newParams[key] = this._params[key];
        }

        this._params = newParams;
        this._length = this.getParamsLength();
      }
    } catch (e) {
      console.debug(e);
      return;
    }
  }

  /**
   * 특정 파라미터의 value를 다른 값으로 변경.
   * 인자값이 존재하지 않는 파라미터인 경우 아무런 행동을 하지 않음.
   * <pre>
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   replaceParam('id', 'sghwang0608'); // replaced. --> { id: 'sghwang0608', pw: '1q2w3e4r' }
   *
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   replaceParam('name', 'sghwang'); // there is nothing to replace. --> { id: 'user11', pw: '1q2w3e4r' }
   * </pre>
   * @param {string} field - 변경하고 싶은 파라미터의 field
   * @param {string} value - 새로운 value
   */
  replaceParam(field: string, value: string) {
    if (this.hasParam(field)) {
      this._params[field] = value;
    }
  }

  /**
   * 특정 파라미터의 value를 리턴
   * TODO 주석 채우기
   * @param {string} field - 파라미터의 field
   * @return {string} - 파라미터의 value. <p>찾지 못하면 빈 문자열</p>
   */
  getParam(field: string): string {
    return this.hasParam(field) ? this._params[field] : '';
  }

  /**
   * 파라미터 객체를 배열로 변환하여 리턴
   * exception 발생시 빈 배열 리턴.
   * <pre>
   *   // _params = { id: 'user11', pw: '1q2w3e4r' }
   *   getParamsAsArr(); // --> [ {field: 'id', value: 'sghwang0608'}, {field: 'pw', value: '1q2w3e4r'} ]
   *
   *   // _params = {}
   *   getParamsAsArr(); // --> []
   * </pre>
   * @return {Array<{}>} - 파라미터 배열 <p>파라미터가 비어 있으면 빈 배열을 리턴.</p>
   */
  getParamsAsArr(): Array<{}> {
    try {
      if (this.hasParams()) {
        const paramKeysArr = Object.keys(this._params);
        const paramsArr: Array<{}> = [];

        for (let i = 0; i < this._length; i++) {
          const param = {};
          param['field'] = paramKeysArr[i];
          param['value'] = this._params[paramKeysArr[i]];

          paramsArr.push(param);
        }

        return paramsArr;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  }
}
