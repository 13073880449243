/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-04-04
 */
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-04-04.
 *
 * @version 1.0
 * @author sjchun
 */
export class HeodakDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    let superData = super.getWebPayConvDataFromBasketPage();
    try {
      superData['totalPrice'] = document.querySelector('span.MK_total_price span.MK_chg_total_price')!.textContent!.replace(/\D/g, '');
      return superData;
    } catch {
      return superData;
    }
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productList = document.querySelectorAll('#basket [name="forms"]');
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const rawPrice = productList[i].querySelector('.price-val')!.textContent!.replace(/\D/g, '');
      const rawQty = (productList[i].querySelector('[name="orgamount"]') as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (productList[i].querySelector('[name="branduid"]') as HTMLInputElement).value,
        'productName': productList[i].querySelector('.item-pname')!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.MK_total_price span.MK_chg_total_price')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }
}
