/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {CommandConfig} from './command/config/CommandConfig';
import {Command} from './command/core/Command';
import {CommandFactory} from './command/core/CommandFactory';
import {CommandConfigFactory} from './command/config/CommandConfigFactory';
import {ArgumentExtractor} from './argument/ArgumentExtractor';
import {QueueManager} from '../storageQueue/QueueManager';
import {JsonObject} from '../lib/json/JsonObject';
import {OrderCodeManager} from "../OrderCode/OrderCodeManager";

/**
 * create on 2019-08-23.
 * <p> 클라이언트 코드에서 사용될 기능이 담긴 클래스 </p>
 * <p> 이 클래스를 객체로 생성하여 사용한다. </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Tracker {
  /* 추출된 명령어 인자 */
  protected extractedArgs!: {};

  /* 전송할 JSON 데이터 */
  protected jsonData: JsonObject;

  /* 커맨드 객체 */
  protected command!: Command;

  /* 커맨드 객체의 생성을 위한 설정 */
  protected commandConfig!: CommandConfig;

  /* 인스턴스 */
  private static instance: Tracker;

  /**
   * 생성자.
   * 인자값으로 background 작업을 수행할지 지정한다.
   * @param {boolean} enableBackgroundJob 백그라운드 작업
   */
  private constructor(enableBackgroundJob = true) {
    this.jsonData = new JsonObject();

    if (enableBackgroundJob) {
      this.backgroundJob();
    }
  }

  static getInstance(enableBackgroundJob = true): Tracker {
    if (!Tracker.instance) {
      Tracker.instance = new Tracker(enableBackgroundJob)
    }

    // 주문번호 생성시 사용되는 세션 키 생성
    OrderCodeManager.getInstance().createSessionKey();
    return Tracker.instance;
  }

  executeCommand(rawArguments: {}): void {
    this.parseArguments(rawArguments);
    this.command = this.getCommand(this.commandConfig);
    this.command.executeCommand();
  }

  private parseArguments(rawArguments: {}): void {
    this.extractedArgs = this.getExtractedArgs(rawArguments);
    this.instantiateCommandConfig(this.extractedArgs);
  }

  protected backgroundJob(): void {
    QueueManager.getInstance().resend();
  }

  private getExtractedArgs(rawArguments: {}): {} {
    return ArgumentExtractor.createExtractedArgs(rawArguments);
  }

  private instantiateCommandConfig(extractedArgObj: {}): void {
    const commandConfigFactory = new CommandConfigFactory(this.jsonData);
    this.commandConfig = commandConfigFactory.getCommandConfig(extractedArgObj);
  }

  private getCommand(commandConfig: CommandConfig): Command {
    return CommandFactory.createCommand(commandConfig);
  }
}
