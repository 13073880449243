import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-21
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-26.
 * <p> sohome 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class SohomeDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const product = jsonObj.getDataByName('product');

    let totalQty = 0;

    for (let i = 0; i < product.length; i++) {
      const orderedProduct = document.querySelectorAll('#order > div.page-body > div.table-order-prd > table > tbody tr');
      const qty = orderedProduct[i].querySelector('td:nth-child(4)')!.textContent!.trim().replace(/\D/g, '');

      product[i]['qty'] = qty;
      product[i]['price'] = orderedProduct[i].querySelector('td:nth-child(6)')!.textContent!.trim().replace(/\D/g, '');

      totalQty += NumberUtil.parseInteger(qty);
    }

    jsonObj.replaceData('product', product);
    jsonObj.replaceData('totalQty', totalQty);

    return jsonObj;
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const basketItem = document.querySelectorAll('#cart > div.page-wrap > div.cart-ct > table > tbody tr')[i];
      const rawPrice = (basketItem.querySelector('td:nth-child(6) > div.tb-right > span') as HTMLSpanElement).textContent!.trim().replace(/\D/g, '');
      const rawQty = (basketItem.querySelector('td:nth-child(4) > div.tb-center > div.amount > input[name=amount]') as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelector('#cart > div.page-wrap > div.cart-ct > table > tbody > input[name=branduid]') as HTMLInputElement).value,
        'productName': (basketItem.querySelector('td:nth-child(3) > div.tb-left > a') as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }
}
