/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2020-05-18
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-05-18.
 * <p> alljp 광고주 서브클래스 추가 </p>
 * <p> 카페24에서 메이크샵으로 이전하여 파싱하는 부분이 상이함 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class LivingpickDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"] >figure>figcaption p.price')[i].textContent!.replace(/\D/g, '').trim();
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

}
