/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-26
 */
import {DataParser} from "./DataParser";
import {HostingType} from "../types/GlobalEnums";
import {JsonObject} from "../lib/json/JsonObject";

/**
 * create on 2020-03-26.
 * <p> Null Object 패턴으로 아무런 행동을 하지 않기 위한 클래스 </p>
 * <p> {@link DataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NullDataParser extends DataParser {
  constructor(adverId: string, commandOptions: {}, hostingType: HostingType) {
    super(adverId, commandOptions, hostingType);
  }

  protected getCartCollectData(): JsonObject {
    return new JsonObject();
  }

  protected getCommonTraceData(): JsonObject {
    return new JsonObject();
  }

  protected getConversionData(): JsonObject {
    return new JsonObject();
  }

  protected getPayConvDataFromBasketPage(): {} {
    return {};
  }

  protected getPayConvDataFromProductPage(): {} {
    return {};
  }

  protected getShopCollectData(): JsonObject {
    return new JsonObject();
  }

  protected getWishCollectData(): JsonObject {
    return new JsonObject();
  }

  protected isBasketPage(): boolean {
    /*
     * isBasketPage()와 isProductPage()가 모두 false를 리턴하면
     * 분기에서 에러를 발생시키므로 true로 리턴한다.
     */
    return true;
  }

  protected isProductPage(): boolean {
    return false;
  }
}
