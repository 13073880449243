/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Maison5460DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
}

protected getImageUrl(): string {
  try{
    if(this.isMobile(this.commandOptions)){
      return (document.querySelector('span.img_photo_big a#mainImage > img') as HTMLImageElement).src;
    }else{
      return super.getImageUrl();
    }
  }catch{
    return super.getImageUrl();
  }
}
}
