/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="yjlee:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-12
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-07-12
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WowbiotechDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      let price = 0, dcPrice = 0;
      const discountPriceValue = NumberUtil.parseRationalNumber(
        formElement['set_goods_fixedPrice']
          ? formElement['set_goods_fixedPrice'].value : '0');

      if (discountPriceValue > 0) {
        price = discountPriceValue;
        dcPrice = this.isMobile(this.commandOptions)
        ? NumberUtil.parseRationalNumber(document.querySelector('div.sale_order_info:nth-child(4) table.inner_table tbody tr td:nth-child(2) strong.price span')!.textContent!.trim()!.match(/^[\d,]+/)![0])
        :NumberUtil.parseRationalNumber(document.querySelector('div.item_tit_detail_cont div.item_detail_list dl.item_price.subscription_price dd strong')!.textContent!.match(/^[\d,]+/)![0]);
        return {'price': price, 'dcPrice': dcPrice};
      }
      return {'price': formElement!['set_goods_price'].value, 'dcPrice': 0}
    } catch (e) {
      return {'price': formElement!['set_goods_price'].value, 'dcPrice': 0}
    }
  }
}
