/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-20
 */

import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-20.
 * <p> {@Breatheb } and {@Cafe24Smart } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class BreathebDataParser extends Cafe24SmartDataParser {

 
  protected getCommonTraceData(): JsonObject {

    if(super.isBasketPage()||super.isProductPage()){
      if(!this.isMobile(this.commandOptions)){
          this.webCustomNpayEvent();
        }
    }
    return super.getCommonTraceData();
  }

  private webCustomNpayEvent(): void {
    EventUtil.addEvent(document.querySelector('#naver_pay_button')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    });
  }

}
