/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';


/**
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Huit8DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
   protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]');

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount.length; i++) {
      const rawPrice = productAmount[i].querySelector('.basket-product p.price span')!.textContent!.replace(/\D/g, '');
      const rawQty = (productAmount[i].querySelector('input[name="orgamount"]') as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (productAmount[i].querySelector('input[name="branduid"]') as HTMLInputElement).value,
        'productName': (productAmount[i].querySelector('p.name a') as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }
}
