/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-05-11
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-05-11
 * 탑툰의 경우 특정 카테고리 페이지 접속시 공통 모수를 따로 수집
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class ToptoonDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 탑툰의 경우 특정 카테로리 페이지 접속시 모수를 따로 측정하기 위해
   * 도메인을 toptoon_fitness.com으로 변경 후 수집
   * @protected
   */
  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();

    const filterUrlList = [
      'toptoon.com/comic/ep_list/fitness'
    ];

    let hostname = location.hostname;
    let pathname = location.pathname.substring(location.pathname.length-1, location.pathname.length) === '/' ? 
      location.pathname.substring(0, location.pathname.length-1) : location.pathname;

    let isUrl = filterUrlList.some((url)=>url === hostname+pathname);
    if(isUrl){
      jsonData.addData('domain', 'toptoon_fitness.com');
    }

    return jsonData;
  }
}
