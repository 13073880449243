import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-06
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-06.
 * <p> iehome 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class IehomeDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getProductName(): string {
        try {
            if (window['product_name']) {
                return window['product_name'];
            } else {
                return window.location.pathname.indexOf('/m/') === 0
                    ? document.querySelector('section.shopdetailInfo > div.shopdetailInfoTop > figure > h2')!.textContent!.trim()
                    : document.querySelector('#form1 div.info > div.dname > h3 > p')!.textContent!.trim();
            }
        } catch (e) {
            return '';
        }
    }

    protected getWebConversionData(): JsonObject {
        const jsonObj = super.getWebConversionData();
        const parsedProducts = jsonObj.getDataByName('product');

        const orderedProducts = document.querySelectorAll('div.table-order-prd > table > tbody tr:nth-child(odd)');

        let totalQty = 0;

        for (let i = 0; i < parsedProducts.length; i++) {
            const qty = orderedProducts[i]!.querySelectorAll('td')[2].textContent!.trim().replace(/\D/g, '');
            const price = (orderedProducts[i]!.querySelectorAll('td')[3].textContent!.trim().replace(/\D/g, ''));

            parsedProducts[i]['qty'] = qty;
            parsedProducts[i]['price'] = price;

            totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
        }

        jsonObj.replaceData('product', parsedProducts);
        jsonObj.replaceData('totalQty', totalQty);

        return jsonObj;
    }
}
