/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">hsju</a>
 * @since 2022-08-19
 */
import {FirstmallDataParser} from "../FirstmallDataParser";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2022-08-19.
 * <p> Ivenet01 광고주 별도 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */


export class Ivenet01DataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }


  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_contents div li div.cart_goods_detail');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const qty =  document.querySelectorAll('div.cart_contents div li div.cart_goods_detail li.img_area span[tag="q"]')[i].textContent!;
        const rawPrice = document.querySelectorAll('div.cart_contents div li div.cart_goods_detail li.img_area span[tag="p"]')[i].textContent!;
        const productName = document.querySelectorAll('div.cart_contents div li div.cart_goods_detail li.img_area span[tag="t"]')[i].textContent!;      
        const productCode = document.querySelectorAll('div.cart_contents div li div.cart_goods_detail li.img_area span[tag="i"]')[i].textContent!;

        let productQty = NumberUtil.stringToNumber(qty);;
        let productPrice = NumberUtil.stringToNumber(rawPrice);

        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice / productQty
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(document.querySelector('.price .settle_price')!.textContent!),
        'totalQty': totalQty,
        'productCnt' : rawProductList.length
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}
