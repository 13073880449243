/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-11-15
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { GlobalVariables } from "../../../types/GlobalVariables";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-11-15
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Garasani9825DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected isBasketPage(): boolean {
    try {
      return window.location.pathname === '/order/order.php' || window.location.pathname === '/order/cart.php';
    } catch (e) {
      return false;
    }
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageSrc = (document.querySelector('meta[property="og:image"]') as HTMLMetaElement).content;
    
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (document.querySelector('meta[property="og:title"]') as HTMLMetaElement).content,
      'price': priceObj['price'] === '0' ? '1' : priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': imageSrc,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    return this.getWebShopCollectData(formElement);
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (document.querySelector('meta[property="og:title"]') as HTMLMetaElement).content,
      'price': priceObj['price'] === '0' ? '1' : priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement)
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'].value,
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (document.querySelector('meta[property="og:title"]') as HTMLMetaElement).content,
      'price': priceObj['price'] === '0' ? '1' : priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement)
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'] && NumberUtil.parseRationalNumber(formElement!['set_total_price'].value) > 0
          ? formElement!['set_total_price'].value
          : (NumberUtil.parseRationalNumber(parsedProducts[0]['dcPrice'] > 0 ? parsedProducts[0]['dcPrice'] : parsedProducts[0]['price'])) * NumberUtil.parseNumber(parsedProducts[0]['qty']),
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }
}
