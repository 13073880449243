/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-07-04
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

export class OnoffinterDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let price = NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content);
    let dcPrice = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content);
    if(price == dcPrice){
     if(document.querySelectorAll('#span_product_price_custom').length>0){
      price =  NumberUtil.parseNumber(document.querySelector('#span_product_price_custom')!.textContent!.replace(/\D/g,''));
     }else{
      dcPrice=0;
     }
    }
    try{
      return {
        price: price,
        dcPrice: dcPrice
      }
    } catch{
      return super.getProductPrice();
    } 
  }
}