/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-07-19
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
* create on 2023-07-19
* <p> {@link } and {@link } 관련 클래스 </p>
*
* @version 1.0
* @author yjlee
*/
export class ChoandkangDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number;dcPrice: number } {
    try{
      let price= NumberUtil.parseNumber(document.querySelector('#span_product_price_text')!.textContent!.replace(/\D/g, ''));
      let dcPrice = NumberUtil.parseNumber(document.querySelector('#contents div.info_wrap > div > div.product_custom')!.textContent!.replace(/\D/g, ''));
      return {
        price: price > dcPrice ? price : dcPrice  ,
        dcPrice: dcPrice < price ? dcPrice : price
      }
    }catch{
      return super.getProductPrice();
    }
  }
}
