/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-04-08
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2021-04-08.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SsenypDataParser extends Cafe24SmartDataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }
    
    protected getProductPrice(): { price: number; dcPrice: number; } {
        if(this.isMobile(this.commandOptions)){
            return super.getProductPrice();
        }else{
            let dcPrice = document.querySelector('#span_product_price_sale') ? NumberUtil.parseNumber(document.querySelector('#span_product_price_sale')!.textContent!) : 0;
            return {
                price: NumberUtil.parseNumber(document.querySelector('#span_product_price_text')!.textContent!),
                dcPrice: dcPrice
            }
        }

    }
}
