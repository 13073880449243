/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-05-11
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
* create on 2023-05-11
 * <p> Nzorigin 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class HkfnbDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();

    jsonObj['totalPrice'] = document.querySelector('#basket p.price-total-val strong')!.textContent!.replace(/\D/g,'');

    return jsonObj;
  }
}
