/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-30
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";

/**
 * create on 2022-09-30
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Youil1993DataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('ul.shipping_group_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = document.querySelectorAll('li.cart_goods li.img_area');
        const params = productInfo[i].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = document.querySelectorAll('li.cart_goods div.cart_quantity')[i].textContent!.split('(')[0].replace(/\D/g, '').trim();
        const productName = document.querySelectorAll('li.cart_goods div.goods_name')[i].textContent!.trim();
        const rowProductPrice =document.querySelectorAll('li.cart_goods div.cart_quantity')[i].textContent!.split('(')[1].replace(/\D/g, '').trim();
        const productPrice = NumberUtil.parseNumber(rowProductPrice)/NumberUtil.parseNumber(productQty);
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
          document.querySelector('.price, .settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty,
        'productCnt' : rawProductList.length
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
      const rawProductList = document.querySelectorAll('ul.shipping_group_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = document.querySelectorAll('li.cart_goods li.img_area');
        const params = productInfo[i].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = document.querySelectorAll('li.cart_goods div.cart_quantity')[i].textContent!.split('(')[0].replace(/\D/g, '').trim();
        const productName = document.querySelectorAll('li.cart_goods span.goods_name')[i].textContent!.trim();
        const rowProductPrice =document.querySelectorAll('li.cart_goods div.cart_quantity')[i].textContent!.split('(')[1].replace(/\D/g, '').trim();
        const productPrice = NumberUtil.parseNumber(rowProductPrice)/NumberUtil.parseNumber(productQty);
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(document.querySelector('div.total_sum_price span.sum_price.settle_price')!.textContent!.replace(/\D/g, '').trim()),
        'totalQty': totalQty
      };

  }

}
