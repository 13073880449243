/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-05
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2023-01-05.
 * <p> degrey 광고주 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class DegreyDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const superData: JsonObject = super.getShopCollectData();
    let priceValue=0 ;
    let dcPriceValue=0;
    if (this.isMobile(this.commandOptions)) {
      priceValue = NumberUtil.parseRationalNumber(document.querySelector('div.-discount-item')!['dataset']['custom'].split('.')[0]);
      dcPriceValue = NumberUtil.parseRationalNumber(document.querySelector('div.-discount-item')!['dataset']['price'].split('.')[0]);
    } else {
     priceValue = NumberUtil.parseRationalNumber(document.querySelector('div.sp-detail-title')!['dataset']['custom'].split('.')[0]);
     dcPriceValue = NumberUtil.parseRationalNumber(document.querySelector('div.sp-detail-title')!['dataset']['price'].split('.')[0]);
    } 
    superData['_data']['price'] = priceValue;
    superData['_data']['dcPrice'] =  dcPriceValue;
    return superData;
  }

protected getConversionProductDataAuto() {
  const superData = super.getConversionProductDataAuto();
   if (!this.isMobile(this.commandOptions)) {
    superData['totalPrice'] =  document.querySelectorAll('td div strong span')[2].textContent!.replace(/\D/g, '');
   }else{
    superData['totalPrice'] =  document.querySelectorAll('div div.totalPay strong')[0].textContent!.replace(/\D/g,'');
   }
   return superData;
  }
}
