/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-08
 */

import { SelfHostedDataParser } from "../SelfHostedDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-11-08
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @author yjlee
 */
export abstract class ZizleDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let superData: JsonObject = super.getShopCollectData();
    superData.data['imageUrl'] = (document.querySelector('meta[property="og:image"]') as HTMLMetaElement).content;
    return superData;
  }

}

