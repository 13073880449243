/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { GlobalVariables } from "../../../types/GlobalVariables";
import { Value } from "../../../lib/value/Value";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
* create on 2020-04-02.
 * <p> Bylogin11 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Bylogin11DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('form[name="forms"]>p.pname')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.txt-price')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {} = {};
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);
    //if(1)
    if (Object.keys(this.getProductTargetObj()).length > 0) {
      const targetObj: {} = this.getProductTargetObj();
      parsedData = {
        'adverId': this.adverId,
        'productCode': targetObj['productCode'],
        'productName': targetObj['productName'],
        'price': NumberUtil.parseNumber(targetObj['price']),
        'productUrl': window.location.href,
        'dcPrice': NumberUtil.parseNumber(targetObj['dcPrice']),
        'soldOut': targetObj['soldOut'],
        'imageUrl': targetObj['imageUrl'],
        'secondImageUrl': targetObj['secondImageUrl'],
        'thirdImageUrl': targetObj['thirdImageUrl'],
        'fourthImageUrl': targetObj['fourthImageUrl'],
        'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
    } else {
      let price = 0;
      let dcPrice = 0;
      if (this.isMobile(this.commandOptions)) {
        price = document.querySelector('.shopdetailInfoCont del') ?
          NumberUtil.parseNumber(document.querySelector('.shopdetailInfoCont del')!.textContent!)
          : NumberUtil.parseNumber(document.querySelector('.shopdetailInfoCont em')!.textContent!);
        dcPrice = NumberUtil.parseNumber(document.querySelector('.shopdetailInfoCont em')!.textContent!);
      } else {
        price = document.querySelector('div.infoWrap .table-opt table tbody tr td strike') ?
          NumberUtil.parseNumber(document.querySelector('div.infoWrap .table-opt table tbody tr td strike')!.textContent!)
          : NumberUtil.parseNumber(document.querySelector('div.infoWrap .table-opt table tbody tr td .tb-left.price')!.textContent!);
        dcPrice = NumberUtil.parseNumber(document.querySelector('div.infoWrap .table-opt table tbody tr td .tb-left.price')!.textContent!);
      }
      parsedData = {
        'adverId': this.adverId,
        'productCode': document.querySelector('form#form1')!['branduid']['value'],
        'productName': this.getProductName(),
        'price': price,
        'productUrl': window.location.href,
        'dcPrice': dcPrice,
        'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
        'imageUrl': this.getImageUrl(),
        'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
    }

    return new JsonObject(parsedData);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    let price = 0;
    let dcPrice = 0;
    if (this.isMobile(this.commandOptions)) {
      price = document.querySelector('.shopdetailInfoCont del') ?
        NumberUtil.parseNumber(document.querySelector('.shopdetailInfoCont del')!.textContent!)
        : NumberUtil.parseNumber(document.querySelector('.shopdetailInfoCont em')!.textContent!);
      dcPrice = NumberUtil.parseNumber(document.querySelector('.shopdetailInfoCont em')!.textContent!);
    } else {
      price = document.querySelector('div.infoWrap .table-opt table tbody tr td strike') ?
        NumberUtil.parseNumber(document.querySelector('div.infoWrap .table-opt table tbody tr td strike')!.textContent!)
        : NumberUtil.parseNumber(document.querySelector('div.infoWrap .table-opt table tbody tr td .tb-left.price')!.textContent!);
      dcPrice = NumberUtil.parseNumber(document.querySelector('div.infoWrap .table-opt table tbody tr td .tb-left.price')!.textContent!);
    }

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': price,
      'productUrl': window.location.href,
      'dcPrice': dcPrice,
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
        ? document.getElementById("MS_amount_basic_0")!['value']
        : 1,
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }
}
