/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-06-08
 */
import { FirstmallDataParser } from "../FirstmallDataParser";
import { InvalidData } from "../../../lib/ajax/InvalidData";

/**
 * create on 2023-06-08
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class BdkoreaDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): {} {
    try {
      return {
        'product': window[this.ENP_OBJECT_NAME]['product'],
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': this.getMobileConvTotalPrice(),
        'totalQty': window[this.ENP_OBJECT_NAME]['totalQty']
      };
    } catch (e) {
      return new InvalidData();
    }

  }
}
