/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-12
 */

import {CrmService} from "../CrmService";
import {HostingType} from "../../../../types/GlobalEnums";

/**
 * create on 2021-04-12
 * <p> 메이크샵 Crm Service </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class MakeshopCrmService extends CrmService {
  constructor() {
    super(HostingType.MAKESHOP_D4);
  }

  protected isLoginPage(): boolean {
    return location.pathname.search(/member.html|login.html/) > -1;
  }

  protected isOrderFormPage(): boolean {
    return location.pathname.search('order.html') > -1;
  }

  protected isOrderEndPage(): boolean {
    return location.pathname.search(/order_complete.html|orderend.html/) > -1;
  }
}
