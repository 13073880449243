/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mapark@enliple.com">mapark</a>
 * @since 2020.06.16
 */


 /**
  * create on 2020.06.16
  * <p> 각종 이벤트들이 전송이 완료 되었는지 검사하는 클래스 </p>
  *
  * @version 1.0
  * @author mapark
  */
export class DepatureInspector {
  private static instance: DepatureInspector;
  private _collectState: boolean;

  private constructor() {
    this._collectState = false;
  }

  static getInstance(): DepatureInspector {
    if (!DepatureInspector.instance) {
      DepatureInspector.instance = new DepatureInspector()
    }
    return DepatureInspector.instance;
  }

  get collectState(): boolean {
    return this._collectState;
  }

  set collectState(value: boolean) {
    this._collectState = value;
  }
}
