/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-15
 */
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';
import { NumberUtil } from '../../../lib/common/NumberUtil';

/**
 * create on 2023-03-15.
 * <p> gs1234 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Gs1234DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice =document.querySelectorAll('li.price > span > strong')[i].textContent!.replace(/\D/,'');
      const rawQty = (document.querySelectorAll('div.description ul li.quantity input')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('strong.prdName')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector(' div.toggleArea.type1.eToggle div p strong')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };
  }
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('input[type="checkbox"]#basketchks').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('tbody tr td.right strong')[i].textContent!.replace(/\D/,'');
      const rawQty = (document.querySelectorAll('table tbody tr td:nth-child(4) span span input')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('td.left.gClearLine a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('tbody tr td:nth-child(3) div strong:nth-child(2) span')!.textContent!.replace(/\D/,''),
      'totalQty': totalQty.toString()
    };
  }

}
