/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-06-10
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-06-10
 * <p> sulsungfarm - sulsungmall.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SulsungfarmDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      let superData = super.getProductPriceObj(formElement);
      if (this.isMobile(this.commandOptions)) {
        superData['price'] = NumberUtil.parseRationalNumber(document.querySelector('.price_box .member_icon span')!.textContent!.replace(/\D/g, ''));
        superData['dcPrice'] = NumberUtil.parseRationalNumber(document.querySelector('strong.price')!.textContent!)
      }
      return superData;
    } catch (e) {
      return super.getProductPriceObj(formElement);
    }
  }
}