/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-03-09.
 * <p> Naingirl 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NaingirlDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
   protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a')[i] as HTMLAnchorElement;
      const qty = (this.getSelectorAll([
        '#order > div > div.table-cart.table-order-prd > table > tbody > tr > td:nth-child(3) > div'
      ])[i] as HTMLDivElement).textContent!.replace(/\D/g, '').trim();
      const price = (this.getSelectorAll([
        '#order > div > div.table-cart.table-order-prd > table > tbody > tr > td:nth-child(5) > div'
      ])[i] as HTMLElement).textContent!.replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobileTotalPrice(): string {
    try {
      return document.querySelector('#mk_totalprice')!.textContent!.trim().replace(/\D/g, "");;
    } catch (e) {
      return '0';
    }
  }
}
