import { JsonObject } from "../../../lib/json/JsonObject";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-08
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-08.
 * <p> cellborn 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class CellbornDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getWebConversionData(): JsonObject {
        const jsonObj = super.getWebConversionData();
        const parsedProducts = jsonObj.getDataByName('product');

        const orderedProducts = document.querySelectorAll('div.table-order-prd > table > tbody tr');

        for (let i = 0; i < parsedProducts.length; i++) {
            const price = (orderedProducts[i]!.querySelectorAll('td')[4].textContent!.trim().replace(/\D/g, ''));

            parsedProducts[i]['price'] = price;
        }

        jsonObj.replaceData('product', parsedProducts);

        return jsonObj;
    }
}
