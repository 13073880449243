/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-30
 */

import {DeviceType, EventType, TelegramParseMode} from "../../../types/GlobalEnums";
import {TelegramMessage} from "./TelegramMessage";
import {DateUtil} from "../../common/DateUtil";
import {NumberUtil} from "../../common/NumberUtil";

/**
 * create on 2020-10-30
 * <p> 텔레그램에 Alarm 전송하는 class </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export abstract class TelegramAlarm {
  /* 알람을 보내는 BOT의 토근 */
  private readonly TOKEN = '1250716898:AAFLu9skLIDuGzDHuMCUNSPJROBrecVsEtY';
  /* 알람을 보낼 방의 ID (테스트 : 1296684704 운영 : -1001483731231) */
  // private readonly CHAT_ID = '1296684704';
  private readonly CHAT_ID = '-1001483731231';

  /* 전체 메시지 */
  protected telegramMessage: TelegramMessage;

  /* 광고주 아이디 */
  protected adverId: string;
  /* 현재 디바이스 타입 */
  protected deviceType: DeviceType;
  /* 이벤트 타입 */
  protected eventType: EventType;

  /* 텔레그램 메시지 구문 모드 */
  private parseMode: TelegramParseMode;

  constructor(adverId: string, deviceType: DeviceType, eventType: EventType, parseMode: TelegramParseMode) {
    this.adverId = adverId;
    this.deviceType = deviceType;
    this.eventType = eventType;
    this.parseMode = parseMode;
    this.telegramMessage = new TelegramMessage(parseMode);
  }

  /**
   * 전송될 메시지를 생성하는 메소드
   * @protected
   */
  protected abstract setMessage(): void;

  /**
   * 내부 함수 호출을 통해 전송을 할 조건을 검사하는 메소드
   * 1. 전송이 예외되는 광고주 아이디 검사
   * 2. 전송되는 시간대를 검사
   * @private
   */
  protected checkSendRule() {
    // 알람의 ON(true)/Off(false)
    const checkOnOff = () => {
      return false;
    };

    // 알람을 제외할 광고주 목록을 검사
    const checkAdverIdFilter = () => {
      const adverIdFilterList: string[] = []; // 전송을 안할 광고주 아이디 목록
      return adverIdFilterList.indexOf(this.adverId) < 0;
    };

    // 알람을 전송할 시간대를 검사
    const checkHourBetween9And14 = () => {
      const currentHour = NumberUtil.parseInteger(DateUtil.getHHString());
      return currentHour > 10 && currentHour < 15; //오전 10 ~ 오후 3시 사이에서만 전송
    };

    return checkOnOff() && checkAdverIdFilter() && checkHourBetween9And14();
  }

  /**
   * 텔레그램 API에 알람을 전송함
   */
  send(): void {
    this.setMessage();
    const alarmUrl = `//api.telegram.org/bot${this.TOKEN}/sendMessage?chat_id=${this.CHAT_ID}&text=${this.telegramMessage.message}&parse_mode=${this.parseMode}`;
    if(this.checkSendRule()) {
      navigator.sendBeacon(alarmUrl);
      this.telegramMessage.resetMessage();
    }
  }
}