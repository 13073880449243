/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-01-25
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-01-25.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class FlatperDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let superData: JsonObject = super.getShopCollectData();
    superData.data['soldOut'] = window['bIsDisplaySoldoutOption'] ? 'Y' : 'N';
    return superData;
  }
}
