/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-01-19.
 *
 * @version 1.0
 * @author sjchun
 */
export class CharmzoneDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(3) div.tb-center')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(5) div.tb-center.tb-bold')[i].textContent!.replace(/\D/g, '').trim()
      totalQty += NumberUtil.stringToNumber(qty);
    }

    jsonObj.data['totalQty'] = totalQty;

    return jsonObj;
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('table.product-info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('table.product-info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('table.product-info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
