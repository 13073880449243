/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-20
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-20
 * <p> ean02 관련 광고주 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Ean02DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      return this.isMobile(this.commandOptions)
          ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
          : document.querySelector('div.prd-detail-title h3')!.textContent!.trim()
    } catch (e) {
      return super.getProductName();
    }
  }
}