/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-01-26
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-01-26.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Amadam4060DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();

    jsonObj.data['dcPrice'] = document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, '');

    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const obj = super.getPayConvDataFromProductPage();

    const product = obj['product'];

    for (let i = 0, len = product.length; i < len; i++) {
      product[i]['dcPrice'] = document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, '');
    }

    return obj;
  }

  protected getProductName(): string {
    try {
      return this.isMobile(this.commandOptions)
          ? super.getProductName()
          : document.querySelector('.infoArea .table-opt tr.name div')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getMobileConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getMobileConversionData();

    const product = jsonObj.data['product'];

    for (let i = 0, len = product.length; i < len; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;

      product[i]['productName'] = productAnchorElem!.childNodes[0].textContent!.trim();
    }

    return jsonObj;
  }
}
