/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-12-29
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-12-29
 * <p> Lusida33 - http://lusida.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Lusida33DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    return this.isMobile(this.commandOptions) ? super.getProductName() : document.querySelector('div.info h3')!.textContent!.trim();
  }
}