/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-03-27
 */

import { FirstmallDataParser } from "../FirstmallDataParser";

/**
 * create on 2023-03-27
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ProslabDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      if (this.isMobile(this.commandOptions)) {
        return document.querySelector('.goods_name')!.textContent!.trim();
      } else {
        return document.querySelector('.goods_name h3')!.childNodes[0].textContent!.trim();
      }
    } catch {
      return super.getProductName();
    }
  }
}
