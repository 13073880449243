/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import { JsonObject } from '../../../lib/json/JsonObject';
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';



/**
 * create on 2020-03-09.
 * <p> Lohacell 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class LohacellDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();
    const priceInfo = this.getPriceInfo();

    jsonObj.replaceData('price', priceInfo.price);
    jsonObj.replaceData('dcPrice', priceInfo.dcPrice);

    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
      const jsonObj: {} = super.getPayConvDataFromProductPage();
      const priceInfo = this.getPriceInfo();
      const totalPrice: string = document.querySelector('strong#MK_p_total')!.textContent!.replace(/\D/g, '')!.trim();
      jsonObj['product'][0]['price'] = priceInfo.price;
      jsonObj['product'][0]['dcPrice'] =  priceInfo.dcPrice;
      jsonObj['totalPrice'] = totalPrice === '0'
        ? priceInfo.dcPrice === 0 ? priceInfo.price : priceInfo.dcPrice
        : totalPrice;
      return jsonObj;
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (this.getSelectorAll([
        'div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'table.cart_box_a tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'div.table-cart>table tr td:nth-child(3), div.table-order-prd table tr td:nth-child(3)'
      ])[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else if (document.querySelector('div#productDetail div .thumb-wrap > ul > li > img')) {
      return (document.querySelector('div#productDetail div .thumb-wrap > ul > li > img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }

  private getPriceInfo() {
    try {
      const priceElement = document.querySelector('div.shopdetailInfoBottom > form > div.shopdetailInfoValue > p > span > del, td.opt_data_1')
      const dcPriceElement = document.querySelector('div.shopdetailInfoBottom > form > div.shopdetailInfoValue > p.shopdetailInfoDiscount > span:nth-child(2), td.opt_data_2')

      let dcPrice: number  = 0, price: number = 0;

      price = NumberUtil.stringToNumber(priceElement!.textContent!.replace(/\D/g, '').trim());
      if(dcPriceElement) {
        dcPrice = NumberUtil.stringToNumber(dcPriceElement!.textContent!.replace(/\D/g, '').trim());
      }

      return {'price' : price, 'dcPrice' : dcPrice};
    } catch (e) {
      return {'price' : 0, 'dcPrice' : 0};
    }
  }
}
