/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-18
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";

import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-07-17
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class RiahDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    const superData = super.getProductPriceObj(formElement);
  
    let discount =  NumberUtil.parseNumber((document.querySelector('div form input[name="goodsDiscount"]') as HTMLInputElement).value);
    let price =  NumberUtil.parseNumber((document.querySelector('div form input[name="set_goods_price"]') as HTMLInputElement).value);
    const  discountUnit =  (document.querySelector('div form input[name="goodsDiscountUnit"]') as HTMLInputElement).value;

    if(discountUnit=="percent"){
      let dcprice= (1-(discount*0.01)) * price;
      superData['dcPrice'] =  dcprice;
    }  

    return superData;
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let superData = super.getWebPayConvDataFromProductPage(formElement);
    superData['totalPrice'] = this.getProductPriceObj(formElement)['dcPrice'];
    return superData;
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let superData = super.getMobilePayConvDataFromProductPage(formElement);
    superData['totalPrice'] = this.getProductPriceObj(formElement)['dcPrice'];
    return superData;
  }

}