/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-08
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-12-23.
 * <p> beginning01 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class PonuDataParser extends MakeshopD4DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const orderedProduct = document.querySelectorAll('#order > div.page-wrap > div.order-list > table > tbody tr');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < orderedProduct.length; i++) {
      const productAnchorElem = document.querySelectorAll('#order > div.page-wrap > div.order-list > table > tbody tr td:nth-child(2) a')[i] as HTMLAnchorElement;
      const qty = NumberUtil.parseNumber(document.querySelectorAll('#order > div.page-wrap > div.order-list > table > tbody tr td:nth-child(3)')[i]!.textContent!.trim());
      const price = NumberUtil.parseNumber(document.querySelectorAll('#order > div.page-wrap > div.order-list > table > tbody tr td:nth-child(4)')[i]!.textContent!.trim());

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.detailImage_wrap > div.detailImage > div > img')) {
      return (document.querySelector('div.detailImage_wrap > div.detailImage > div > img') as HTMLImageElement).src;
    } else {
      return (document.querySelector('div.thumb-info > div.thumb-wrap > div.thumb > img') as HTMLImageElement).src;
    }
  }
}
