/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-31
 */
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';
import { NumberUtil } from '../../../lib/common/NumberUtil';

/**
 * create on 2022-05-31.
 * <p> Akiii 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class AkiiiDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * 
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['#cartWrap table tr .info .price p.sell'])[i] as HTMLParagraphElement).textContent!.trim();
      const rawQty = (this.getSelectorAll(['div#cartWrap table input[name="orgamount"]'])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll(['div#cartWrap table input[name="branduid"]'])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll(['div#cartWrap table tr .info .name a'])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }
}
