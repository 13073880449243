/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-31
 */

import {MakeshopD4CategoryParser} from "../MakeshopD4CategoryParser";
import {Category} from "../../Category";
import {HostingType} from "../../../types/GlobalEnums";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-03-31.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class SappunCategoryParser extends MakeshopD4CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  protected parseRawCategories(): Category[] {
    let status;
    let topCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.header-bottom>ul>li>a');
    if (topCategories != null && topCategories.length > 0) {
      status = "W";
    } else {
      status = "M"
      topCategories = document.querySelectorAll('div.cate-menu-wrap>ul>li>a');
    }
    // let topCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.header-bottom>ul>li>a, div.cate-menu-wrap>ul>li>a');
    return this.makeCategories(topCategories, status);
  }

  /**
   * 사뿐의 경우 메뉴 구조가 다른 광고주와 다름
   * 최상위 카테고리에
   * @param anchorList
   * @param status
   */
  protected makeCategories(anchorList: NodeListOf<HTMLAnchorElement>, status: string): Category[] {
    let categories: Category[] = [];

    for (let i = 0; i < anchorList.length; i++) {
      let node = anchorList[i];
      categories.push(this.getCategory(node));
      if (node.parentNode != null && status === "W") {
        let subNode: NodeListOf<HTMLAnchorElement> = node.parentNode.querySelectorAll('ul>li>ul>li>a');
        for (let j = 0; j < subNode.length; j++) {
          categories.push(this.getCategory(subNode[j], node));
        }
      }
    }

    return categories;
  }

  protected getCategory(anchor: HTMLAnchorElement, parentCode?: HTMLAnchorElement): Category {
    const queryString = new QueryStringer(anchor.search.substring(1));
    let parentQueryString: QueryStringer | null = null;
    let parentTextContent: string | null = null
    let category: Category;

    if (parentCode != null) {
      parentQueryString = new QueryStringer(parentCode.search.substring(1));
      parentTextContent = parentCode.textContent;
    }

    if (parentQueryString === null) {
      category = {
        code: queryString.getParam('xcode'),
        parentCode: 'ROOT',
        value: anchor.textContent!.trim()
      };
    } else {
      let mcode = queryString.getParam('mcode');
      let xcode = parentQueryString.getParam('xcode');
      if (mcode === null || mcode === "") {
        //사뿐의 메뉴중 SPECIAL의 서브메뉴는 상품 상세 페이지에서 mcode가 000으로 넘어와서 상단메뉴 취급 처리
        if (parentTextContent != null && parentTextContent.trim() === 'SPECIAL') {
          xcode = 'ROOT';
        }
        mcode = queryString.getParam('xcode');
      }

      category = {
        code: mcode,
        parentCode: xcode,
        value: anchor.textContent!.trim()
      };
    }

    return category;
  }
}
