/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Cafe24SmartDataValidator } from '../Cafe24SmartDataValidator';

/**
 * create on 2019-08-06.
 * <p> 개발자 테스트용 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class DabagirlDataValidator extends Cafe24SmartDataValidator {
  constructor() {
    super();
  }

  commonTraceValidate(data: {}): {} {
    return super.commonTraceValidate(data);
  }

  collectValidate(data: {}): {} {
    return super.collectValidate(data);
  }

  cartValidate(data: {}): {} {
    return super.cartValidate(data);
  }

  wishValidate(data: {}): {} {
    return super.wishValidate(data);
  }

  conversionValidate(data: {}): {} {
    return super.conversionValidate(data);
  }
}
