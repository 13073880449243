/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import { GlobalVariables } from '../../../types/GlobalVariables';
import { JsonObject } from '../../../lib/json/JsonObject';
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';
import { StringUtil } from '../../../lib/common/StringUtil';
import { Value } from '../../../lib/value/Value';




/**
 * create on 2020-03-09.
 * <p> Rrbbsss 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class RrbbsssDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 해당 광고주는 웹의 경우 기존의 MAKESHOP_D4와는 다른 유형의 광고주라서 분기처리가 필요
   * @return [description]
   */
  protected getShopCollectData(): JsonObject {
    try {
      if(this.isMobile(this.commandOptions)) {
        return super.getShopCollectData();
      } else {
        return this.getWebShopCollectData();
      }
    } catch (e) {
      return super.getShopCollectData();
    }
  }

  /**
   * 해당 광고주는 웹의 경우 기존의 MAKESHOP_D4와는 다른 유형의 광고주라서 분기처리가 필요
   * @return [description]
   */
  protected getPayConvDataFromProductPage(): {} {
    try {
      if(this.isMobile(this.commandOptions)) {
        return super.getPayConvDataFromProductPage();
      } else {
        return this.getWebPayConvDataFromProductPage();
      }
    } catch (e) {
      return super.getPayConvDataFromProductPage();
    }
  }

  protected getWebShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(location.search.substring(1)).getParam('branduid'),
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': this.getSoldOutData(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': new QueryStringer(location.search.substring(1)).getParam('branduid'),
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': this.getSoldOutData(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  protected getWebConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalQty: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      products.push({
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'soldOut': parsedProducts[i]['soldOut'],
      });

      totalQty += NumberUtil.stringToNumber(parsedProducts[i]['qty']);
    }

    const parsedData: {} = {
      'product': products,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': targetObj['totalPrice'],
      'totalQty': totalQty && totalQty > 0 ? totalQty : 1
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];
    for (let i = 0; i < productList.length; i++) {
      const rawPrice = (document.querySelectorAll( '#cartWrap div.tb-center.tb-bold.tb-price > span > span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll( 'div.tb-center > input')[i] as HTMLInputElement).value;
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);
      const price = NumberUtil.parseNumber(rawPrice)/qty;
      productList[i]['productName'] = (document.querySelectorAll('div.tb-left>a.tb-bold')[i] as HTMLAnchorElement).textContent!.trim();
      productList[i]['price'] = price;
      productList[i]['qty'] = qty;
    }
    jsonObj['totalPrice'] = document.querySelector('span > span.MK_chg_total_price ')!.textContent!.replace(/\D/g, '').trim();

    return jsonObj;
  }
  
  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
            : document.querySelector('td.tdname')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  protected getTotalQty(): string {
    try {
      if (document.querySelector('input#goods_amount')) {
        return document.querySelector('input#goods_amount')!['value'].trim();
      } else if (document.querySelector('input.MK_count')) {
        return document.querySelector('input.MK_count')!['value'].trim();
      } else {
        return '1';
      }
    } catch (e) {
      return '1';
    }
  }

  /**
   * MakeShop은 서브 카테고리가 없는 경우 mcode가 000으로 자동 매핑 됨 이에 관롼 메소드
   */
  protected getCategoryCode(): string[] {
    const queryParams: QueryStringer = new QueryStringer(location.search.substring(1));
    const mcode: string = queryParams.getParam("mcode");
    const xcode: string = queryParams.getParam("xcode");
    let mcodeValue = "";
    let xcodeValue = "";

    if (StringUtil.isNotEmpty(mcode)) {
      if (mcode === '000') {
        mcodeValue = xcode;
      } else {
        xcodeValue = xcode;
        mcodeValue = mcode;
      }
    } else {
      mcodeValue = xcode;
    }

    return [mcodeValue, xcodeValue];
  }

  /**
   * 해당 광고주의 경우 웹에서 지원하는 품절관련 가상변수가 [SOLDOUT]으로서 기존과 다른 광고주이므로 분기가 필요
   * (모바일은 기존과 동일하다)
   * @param  soldOut [description]
   * @return         [description]
   */
  private getSoldOutData(soldOut: string): string {
    try {
      if(this.isMobile(this.commandOptions)) {
        return soldOut;
      } else {
        return StringUtil.isNotEmpty(soldOut) && soldOut.search("soldout") > -1 ? 'Y' : 'N';
      }
    } catch (e) {
      return 'N'
    }
  }
}
