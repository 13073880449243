/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-11
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-11-11
 * <p> https://goods-namu.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Goodstree1DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    
    if(this.isMobile(this.commandOptions)){
      jsonObj['data']['price'] = document.querySelector('div.DB_rate')!['dataset']['price'].split('.')[0];
    }
    return jsonObj;
  }
  protected getPayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
      jsonObj['totalPrice'] = document.querySelector('span#oversea_total_order_price_front')!.textContent!.replace(/\D/g,'');
    }
      return jsonObj;
  }

}
