/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { StringUtil } from "../../../lib/common/StringUtil";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class RadianceDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('#basket figure figcaption.basketRight p:first-child span.r-txt')[i]!.textContent!;
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

    /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
     protected getWebConversionData(): JsonObject {
      const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a').length;
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;
  
      for (let i = 0; i < productInfoLength; i++) {
        const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a')[i] as HTMLAnchorElement;
        const qty = (this.getSelectorAll([
          'div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)',
          'table.cart_box_a tr td:nth-child(3) div.tb-right:not(.tb-bold)',
          'div.table-cart>table tr td:nth-child(3), div.table-order-prd table tr td:nth-child(3)',
          '#order > div > div.order-list > table > tbody > tr td:nth-child(3) div'
        ])[i] as HTMLDivElement).textContent!.replace(/\D/g, '').trim();
        const price = (this.getSelectorAll([
          'div.table-order-prd table tr td:nth-child(5)'
        ])[i] as HTMLElement).textContent!.replace(/\D/g, '').trim();
  
        parsedProducts.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': productAnchorElem.textContent,
          'qty': qty,
          'price': price,
        });
  
        totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
      }

      let total1 = NumberUtil.parseNumber(document.querySelectorAll('.total_price_wrap strong')[0].textContent!)
      let total2 = NumberUtil.parseNumber(document.querySelectorAll('.total_price_wrap strong')[1].textContent!)

      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': document.querySelector('form[name="orderdetail"]')
            ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
            : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
        'totalPrice': total1 >= total2 ? total1 : total2,
        'totalQty': totalQty.toString()
      };
  
      return new JsonObject(parsedData);
    }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
   protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
