/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-19
 */

import {Category} from "../../Category";
import {HostingType} from "../../../types/GlobalEnums";
import { Cafe24SmartCategoryParser } from "../Cafe24SmartCategoryParser";
import { GeneralTree } from "../../../lib/dataStructure/tree/general/GeneralTree";

/**
 * create on 2023-04-19.
 * <p> 세계금융연구원 카테고리 관련 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */

export class Gif777CategoryParser extends Cafe24SmartCategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }
  parse(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    return;
  }

  protected parseAndMakeTree(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    return;
  }

  protected createCategoryHierarchyTree(rawCategory: any): GeneralTree<Category> {
    return new GeneralTree<Category>({code: '', value: '', parentCode: ''});
  }
}
