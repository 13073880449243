/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-09-15
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-15.
 * <p> pettitude 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class PettitudeDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let superData = super.getPayConvDataFromBasketPage();
    try {
      if (!this.isMobile(this.commandOptions)) {
        superData['totalPrice'] = NumberUtil.parseNumber(document.querySelector('#total_order_price_front')!.textContent!.replace(/\,/g, ''));
      }
      return superData;
    } catch {
      return superData;
    }
  }
}
