/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-05-11
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2023-05-11
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class OjurangDataParser extends Cafe24SmartDataParser {
  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      const priceObj = super.getProductPrice();
      priceObj['price'] = this.isMobile(this.commandOptions)
          ? NumberUtil.parseRationalNumber(document.querySelector('#contents span.product_custom')!.textContent!.replace(/\D/g,''))
          : priceObj.price;
      return priceObj;
    }catch (e) {
      return super.getProductPrice();
    }
  }
}
