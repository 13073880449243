import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

export class ThenaplusDataParser extends Cafe24SmartDataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getProductPrice(): { price: number; dcPrice: number; } {
        let superData = super.getProductPrice();
        try {
            if (!this.isMobile(this.commandOptions)) {
                superData['dcPrice'] = NumberUtil.parseNumber((document.querySelector("#span_product_price_sale") as HTMLSpanElement).childNodes[0].textContent!);
            }
            return superData;
        } catch {
            return superData;
        }
    }

}