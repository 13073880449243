/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-02-17
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";


/**
 * create on 2023-02-17
 * <p> Ddasum 광고주 파싱 </p>
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class MongtanDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getConversionData_prev(): JsonObject {
    if(this.isMobile(this.commandOptions)) {
      return super.getConversionData_prev();
    }
    const convProductName=document.querySelectorAll('tbody.xans-order tr td strong.name a');
    let totalQty = 0;
    const parsedProducts: Array<{}> = [];

    for (let i = 0; i < convProductName.length; i++) {
      const params = document.querySelectorAll('tbody.xans-order tr td:nth-child(1)')[i].querySelector('a')!.search.substring(1).split('&');
      const paramObj = {};
      for (let i = 0; i < params.length; i++) {
        const key = params[i].split('=')[0];
        const val = params[i].split('=')[1];
        paramObj[key] = val;
      }
      const productCode = paramObj['product_no'];
      parsedProducts.push({
        'productCode': productCode,
        'productName': document.querySelectorAll('tbody.xans-order tr td:nth-child(2) a')[i].textContent!.trim(),
        'qty': document.querySelectorAll('tbody.xans-order tr td:nth-child(4)')[i].textContent!.replace(/\D/,''),
        'price': document.querySelectorAll('tbody.xans-order tr td:nth-child(3)')[i].textContent!.replace(/\D/,''),
      });

      totalQty +=NumberUtil.parseNumber(document.querySelectorAll('tbody.xans-order tr td:nth-child(4)')[i].textContent!.replace(/\D/,''))
    }
    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('input#order_id')!['value'],
      'totalPrice': document.querySelectorAll('table td div.box strong span')[2].textContent!.replace(/\D/,''),
      'totalQty': totalQty
    };
    return new JsonObject(parsedData);
  }
}