/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-03-24
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2022-03-24
 * <p> Happyprince - https://www.happyprince.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class HappyprinceDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let superData: JsonObject = super.getShopCollectData();
    superData.data['dcPrice'] = (document.querySelector('#disprice') as HTMLInputElement).value.replace(/\D/g, '');
    return superData;
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getWebPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('#cartWrap tfoot div.tb-right strong')!.textContent!.replace(/\D/g, ''));
    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i].textContent!.split(':')[1].trim());
    }

    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('span.txt-price')!.textContent!.trim());
    return jsonObj;
  }


}