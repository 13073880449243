/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-05
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-11-05
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NakeupfaceDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * MakeShop은 서브 카테고리가 없는 경우 mcode가 000으로 자동 매핑 됨 이에 관롼 메소드
   */
  protected getCategoryCode(): string[] {
    return [
      (document.querySelector('input[name="xcode"]') as HTMLInputElement)!.value!.trim(),
      (document.querySelector('input[name="mcode"]') as HTMLInputElement)!.value!.trim()
    ];
  }

  protected getShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form[name="allbasket"]')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form[name="allbasket"]')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form[name="allbasket"]')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': document.querySelector('form[name="allbasket"]')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form[name="allbasket"]')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form[name="allbasket"]')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('span.current-price')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('table.shop-table input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('table.shop-table input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.product-info a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    const productAnchorElem: NodeList = document.querySelectorAll('div.product-info h4 a');
    for (let i = 0; i < productAnchorElem.length; i++) {
      const qty = (document.querySelectorAll('td.qty')[i] as HTMLDivElement).textContent!.replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer((productAnchorElem[i] as HTMLAnchorElement).search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem[i]!.textContent,
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('td.price b')[i].textContent!.trim()),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    jsonObj.data['totalQty'] = totalQty;
    jsonObj.data['product'] = parsedProducts;
    return jsonObj;
  }
}