/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-10-17
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2022-10-17
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SupplyrouteDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    try{
      const jsonObj: JsonObject = super.getMobileShopCollectData(formElement);
      jsonObj.data['dcPrice'] = document.querySelector("div.price_box strong.price")!.textContent!.trim().replace(/\D/g, '');
      jsonObj.data['price'] = document.querySelector("div.price_box del")!.textContent!.trim().replace(/\D/g, '');
      return jsonObj;
    }catch{
      return super.getMobileShopCollectData(formElement);
    }
  }

  
  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let jsonObj = super.getMobilePayConvDataFromProductPage(formElement);
    jsonObj['product'][0]['dcPrice'] = document.querySelector("div.price_box strong.price")!.textContent!.trim().replace(/\D/g, '');
    jsonObj['product'][0]['price'] = document.querySelector("div.price_box del")!.textContent!.trim().replace(/\D/g, '');
    jsonObj['totalPrice'] = document.querySelector(".total_price")!.textContent!.trim().replace(/\D/g, '');
    return jsonObj;
  }

}