/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-15
 */

import {CrmController} from "../controller/CrmController";
import {HostingType} from "../../../types/GlobalEnums";
import {Cafe24CrmController} from "../controller/hosting/Cafe24CrmController";
import {CrmService} from "../service/CrmService";
import {Cafe24CrmService} from "../service/hosting/Cafe24CrmService";
import {Cafe24CrmDao} from "../dao/hosting/Cafe24CrmDao";
import {CrmDao} from "../dao/CrmDao";
import {SelfHostedCrmController} from "../controller/hosting/SelfHostedCrmController";
import {SelfHostedCrmService} from "../service/hosting/SelfHostedCrmService";
import {SelfHostedCrmDao} from "../dao/hosting/SelfHostedCrmDao";
import {MakeshopCrmController} from "../controller/hosting/MakeshopCrmController";
import {MakeshopCrmService} from "../service/hosting/MakeshopCrmService";
import {MakeshopCrmDao} from "../dao/hosting/MakeshopCrmDao";
import {GodoMallCrmDao} from "../dao/hosting/GodoMallCrmDao";
import {GodoMallCrmController} from "../controller/hosting/GodoMallCrmController";
import {GodoMallCrmService} from "../service/hosting/GodoMallCrmService";
import { ImwebCrmController } from "../controller/hosting/ImwebCrmController";
import { ImwebCrmService } from "../service/hosting/ImwebCrmService";
import { ImwebCrmDao } from "../dao/hosting/ImwebCrmDao";

/**
 * create on 2021-02-15
 * <p> CRM Service에 대한 instance를 생성하는 class </p>
 * <p> 차후 다른 솔루션 사에 CRM이 적용될시 내용 추가 필요 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class CrmFactory {
  constructor() {
  }

  createController(hostingType: HostingType): CrmController | undefined {
    switch (hostingType) {
      case HostingType.CAFE24_SMART:
        return new Cafe24CrmController();
      case HostingType.MAKESHOP_D2:
      case HostingType.MAKESHOP_D4:
        return new MakeshopCrmController();
      case HostingType.GODOMALL_RENT:
      case HostingType.GODOMALL_SELF:
        return new GodoMallCrmController();
      case HostingType.SELF_HOSTED:
        return new SelfHostedCrmController();
      case HostingType.IMWEB:
        return new ImwebCrmController();
      default:
        return undefined;
    }
  }

  createService(hostingType: HostingType): CrmService | undefined {
    switch (hostingType) {
      case HostingType.CAFE24_SMART:
        return new Cafe24CrmService();
      case HostingType.MAKESHOP_D2:
      case HostingType.MAKESHOP_D4:
        return new MakeshopCrmService();
      case HostingType.GODOMALL_RENT:
      case HostingType.GODOMALL_SELF:
        return new GodoMallCrmService();
      case HostingType.SELF_HOSTED:
        return new SelfHostedCrmService();
      case HostingType.IMWEB:
        return new ImwebCrmService();
      default:
        return undefined;
    }
  }

  createDao(hostingType: HostingType): CrmDao | undefined {
    switch (hostingType) {
      case HostingType.CAFE24_SMART:
        return new Cafe24CrmDao();
      case HostingType.MAKESHOP_D2:
      case HostingType.MAKESHOP_D4:
        return new MakeshopCrmDao();
      case HostingType.GODOMALL_RENT:
      case HostingType.GODOMALL_SELF:
        return new GodoMallCrmDao();
      case HostingType.SELF_HOSTED:
        return new SelfHostedCrmDao();
      case HostingType.IMWEB:
        return new ImwebCrmDao();
      default:
        return undefined;
    }
  }
}
