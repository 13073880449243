/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-14
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-06-14
 * <p> tmdqja1209 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Tmdqja1209DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();

   if(this.isMobile(this.commandOptions)){
    jsonObj['product'][0]['price'] = NumberUtil.parseNumber(document.querySelector('meta[property="product:price:amount"')!['content'].replace(/\D/g, ''));

    if(document.querySelectorAll('#totalProducts .option_products tr').length==0){
      jsonObj['totalPrice'] =NumberUtil.parseNumber(document.querySelector('meta[property="product:price:amount"')!['content'].replace(/\D/g, ''));
    }
  }
    return jsonObj;
  }

}
