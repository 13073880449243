/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-10-13
 */
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import { StringUtil } from "../../../lib/common/StringUtil";
import { GlobalVariables } from "../../../types/GlobalVariables";

/**
 * create on 2021-10-13.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Kdb2002DataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const cartDataArr: Array<{}> = window['nsGodo_CartAction']['data'];
    let totalPrice = 0;
    let totalQty = 0;

    const getProductName = (index: number): string => {
      try {
        return document.querySelectorAll('#input-contents form table tbody tr td.order-info dl dt')[index]!.textContent!.trim();
      } catch (e) {
        return '';
      }
    };

    const getProductCode = (index: number): string => {
      const anchorElem: HTMLAnchorElement = document.querySelectorAll('td.order-info p.thumbs-item a')[index] as HTMLAnchorElement;
      let queryString: string = anchorElem.search.substring(1);

      /* querystring에 '?' 바로 뒤에 '&'가 붙은 경우가 있는 경우 방지 */
      queryString = queryString.indexOf('&') > 0
          ? queryString.substring(1)
          : queryString;

      return new QueryStringer(queryString).getParam('goodsno');
    };

    for (let i = 0; i < cartDataArr.length; i++) {
      const price = cartDataArr[i]['price'];
      const qty = cartDataArr[i]['ea'];

      totalQty += qty;
      totalPrice += (price * qty);

      parsedProducts.push({
        'productCode': getProductCode(i),
        'productName': getProductName(i),
        'qty': qty,
        'price': price
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': totalQty
    };
  }

  protected getMobileShopCollectData(): JsonObject {
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': this.getProductCode(),
      'productName': this.getProductName(),
      'price': this.getMobilePriceInfo().price,
      'productUrl': window.location.href,
      'dcPrice': this.getMobilePriceInfo().dcPrice,
      'soldOut': !!window[this.ENP_OBJECT_NAME]['soldOut']
          ? window[this.ENP_OBJECT_NAME]['soldOut'] === '1'
              ? 'Y'
              : 'N'
          : 'N',
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  private getMobilePriceInfo(): { price: number; dcPrice: number; } {
    let price: number = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['price']);
    let dcPrice: number = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['dcPrice']);

    price = NumberUtil.isNaN(price) || StringUtil.isEmpty(price) ? 0 : price;
    dcPrice = NumberUtil.isNaN(dcPrice) || StringUtil.isEmpty(dcPrice) ? 0 : dcPrice;

    return price === 0
        ? {price: dcPrice, dcPrice: price}
        : {price: price, dcPrice: dcPrice};
  }
}
