/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Command} from './core/Command';
import {CommandBuilder} from './core/CommandBuilder';
import {AlertChecker} from '../../service/alertChecker/AlertChecker';
import {PaySystem} from '../../service/paySystem/PaySystem';
import {
  ConversionType,
  DeviceType,
  EventType,
  HostingType,
  PaySystemType
} from '../../types/GlobalEnums';
import {PaySystemFactory} from '../../service/paySystem/PaySystemFactory';
import {ProductTargeting} from '../../service/productTargeting/ProductTargeting';
import {DataParser} from '../../dataParser/DataParser';
import {Tracker} from "../Tracker";
import {EventUtil} from "../../lib/event/EventUtil";
import {NaverCartTargeting} from "../../service/naverTargeting/NaverCartTargeting";
import {NaverWishTargeting} from "../../service/naverTargeting/NaverWishTargeting";
import {NaverTalkTargeting} from "../../service/naverTargeting/NaverTalkTargeting";
import {BtnEventObserver} from "../../observer/button/BtnEventObserver";
import * as TargetingConfigObj from './../../service/productTargeting/productTargeting.config.json';
import {KeywordManager} from "../../service/keyword/KeywordManager";
import {ReviewParser} from "../../service/review/ReviewParser";
import {DepatureInspector} from "../data/DepatureInspector";
import {JsonObject} from "../../lib/json/JsonObject";
import {NaverPayBtnEventObserver} from "../../observer/pay/NaverPay/NaverPayBtnEventObserver";
import {StringUtil} from "../../lib/common/StringUtil";
import {CrmFactory} from "../../service/crm/factory/CrmFactory";
import { ReferrerDomain } from '../../service/referrer/ReferrerDomain';

/**
 * create on 2019-08-23.
 * <p> CREATE 커맨드 </p>
 * <p> 프로젝트에서 정의한 이벤트들을 수행하기 위해 준비하는 역할을 한다 </p>
 * <p> 예를 들어 파싱을 위한 {@link DataParser} 객체의 인스턴스 생성이나 </p>
 * <p> <code>JavaScript</code>의 이벤트로 수행되는 동작을 위한 이벤트 등록 등을 한다. </p>
 * <p> {@link CommandBuilder} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CreateCommand extends Command {
  /* 광고주의 모비온 ID */
  private readonly adverId: string;
  /* 클라이언트 코드에서 받은 옵션 */
  private readonly options: {};
  /* 데이터 파싱 클래스 */
  private readonly dataParser: DataParser;
  /* 간편결제시스템 타입 */
  private readonly paySystemType: PaySystemType | undefined;
  private paySystem: PaySystem | null;

  constructor(builder: CommandBuilder) {
    super(builder);
    self = this; // this 바인딩 이슈 방지
    this.adverId = builder.adverId;
    this.options = builder.options;
    this.dataParser = builder.dataParser;
    this.paySystemType = PaySystem.getPaySystemType(this.options);
    this.paySystem = null;
  }

  executeCommand(): void {
    this.setDevice();
    this.startService();
  }

  /**
   * <code>set</code> 커맨드를 이용하여 디바이스 타입을 초기화
   * <code>create</code> 커맨드의 커맨드 옵션으로 전달받은 <code>device</code> 프로퍼티를 이용한다.
   * <b>NOTE: </b><code>device</code> 프로퍼티가 없는 경우 <code>B</code>로 초기화.
   */
  private setDevice(): void {
    try {
      /* 똑같은 백그라운드 작업을 수행하지 않도록 하여 커맨드 실행 */
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'set',
        this.eventType,
        {'device': this.options && this.options['device'] ? this.options['device'] : 'B'}
      ]);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * CREATE 커맨드에서 시작할 서비스를 정의
   */
  private startService(): void {
    this.startAlertChecker();
    this.startProductTargeting(this.eventType);
    this.startNaverTargetEvent();
    this.startShopCollectService(this.eventType);
    this.startPaySystemConv();
    this.startConversionByBtnClick(this.eventType, this.options);
    this.setConfigReviewParser();
    this.feedToMbris(this.eventType);
    this.extractExternalKeyword();
    this.excuteCrmScript();
    this.setSessionReferrer();
  }

  /**
   * Alert 팝업 표시 여부 확인
   */
  private startAlertChecker(): void {
    const enableEventType = this.eventType === EventType.COLLECT || this.eventType === EventType.CONVERSION;
    if (enableEventType && PaySystem.isPaySystemConv(this.options)) {
      AlertChecker.getInstance().check(PaySystem.payButtonClicked);
    }
  }

  /**
   * 간편 결제 시스템 컨버전 서비스 시작
   */
  private startPaySystemConv(): void {
    /*
     * 네이버페이 버튼이 광고주 페이지 내에서 JS 함수를 이용해 비동기적으로 그려지므로
     * DOM이 로드된 후에 네이버페이 버튼에 이벤트를 등록한다
     */
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      this.initPaySystem();
    } else {
      EventUtil.addEvent(window, 'DOMContentLoaded', this.initPaySystem.bind(self));
    }
  }

  /**
   * 간편 결제 시스템 장바구니,찜,톡톡 서비스 시작
   */
  private startNaverTargetEvent(): void {
    /*
     * 네이버페이 버튼이 광고주 페이지 내에서 JS 함수를 이용해 비동기적으로 그려지므로
     * DOM이 로드된 후에 네이버페이 버튼에 이벤트를 등록한다
     */
    if (document.readyState === 'interactive' || document.readyState === 'complete') {
      this.startNaverTargeting();
    } else {
      EventUtil.addEvent(window, 'DOMContentLoaded', this.startNaverTargeting.bind(self));
    }
  }

  /**
   * 간편 결제 시스템 컨버전 관련 객체 생성 및 이벤트 등록
   */
  private initPaySystem(): void {
    const enableEventType = this.eventType === EventType.CONVERSION;
    if (enableEventType && PaySystem.isPaySystemConv(this.options)) {
      const paySystem: PaySystem = PaySystemFactory.getPaySystem(
          this.adverId,
          this.paySystemType!,
          this.options['device']
      );

      paySystem.addPaySystemConvEvent();
      this.setPayBtnObserver(paySystem);
    }
  }

  /**
   * 간편결제 유형에 따라 observer 생성
   */
  private setPayBtnObserver(paySystem: PaySystem): void {
    switch (this.paySystemType) {
      case PaySystemType.KAKAO_PAY:
        break;
      case PaySystemType.NAVER_PAY:
        new NaverPayBtnEventObserver(paySystem, this.paySystemType, this.adverId).startingObserving();
        break;
      case PaySystemType.PAYCO:
        break;
    }
  }

  /**
   * 상품 타겟팅 객체 생성 및 이벤트 등록
   * @param {EventType} eventType - 이벤트 타입 (cart, wish, ...)
   */
  private startProductTargeting(eventType: EventType): void {
    const enableEventType = this.eventType === EventType.CART || this.eventType === EventType.WISH;
    if (enableEventType && ProductTargeting.isAvailableEventType(eventType)) {

      const productTargeting: ProductTargeting = new ProductTargeting(
          this.adverId,
          this.eventType,
          this.dataParser.getHostingType(),
          this.options
      );
      productTargeting.addTargetingEvent();

      // 버튼이 늦게 화면에 로드되거나 변경되는 경우를 대비하여 Observing 시작
      this.startBtnObserver(productTargeting.eventListener, productTargeting.eventName);
    }
  }

  /**
   * 화면의 변경요소를 지속적으로 관찰을 통해서 버튼이 재생성 되거나 변경됨을 감지하여 이벤트 재 바인딩
   * @param eventListener 바인딩 할 listener
   * @param eventName 바인딩한 이벤트 이름(ex: click...)
   * @private
   */
  private startBtnObserver(eventListener: EventListener, eventName: string) {
    try {
      const hostingType = this.dataParser.getHostingType();
      const device = this.options['device'] === DeviceType.MOBILE ? "mobile" : "web";
      const btnSelector = this.options['btnSelector'] && StringUtil.isNotEmpty(this.options['btnSelector'])
          ? this.options['btnSelector'] : TargetingConfigObj[hostingType][device][this.eventType]['btnSelector'];

      const btnObserver = new BtnEventObserver(hostingType, this.adverId, this.eventType, this.options['device']);
      if (btnObserver.isMuatationAdvertiser()) {
        btnObserver.startingObserving(eventName, eventListener, btnSelector);
      }
    } catch (e) {

    }
  }

  /**
   * 네이버 체크아웃 버튼 타게팅 (네이버 장바구니, 찜, 톡톡 버튼 등)
   * 해당 버튼을 클릭하면 트래커 gateway에 cart 토픽으로 버튼 구분값 전송.
   * <b>NOTE: </b>eventType이 <code>cart</code>인 경우에만 동작하도록 구현됨.
   */
  private startNaverTargeting(): void {
    const enableEventType = this.eventType === EventType.CART;
    if (enableEventType) {
      new NaverCartTargeting(this.adverId).addTargetingEvent();
      new NaverWishTargeting(this.adverId).addTargetingEvent();
      new NaverTalkTargeting(this.adverId).addTargetingEvent();
    }
  }

  /**
   * 상품수집 서비스.
   * <code>load</code> 이벤트에 상품수집 데이터를 전송하도록 등록한다.
   * @param {EventType} eventType - 이벤트 타입
   */
  private startShopCollectService(eventType: EventType): void {
    if (eventType !== EventType.COLLECT) {
      return;
    }

    DepatureInspector.getInstance().collectState = false;

    if (document.readyState === "complete") {
      this.invokeShopCollectEvent();
    } else {
      EventUtil.addEvent(window, 'load', this.invokeShopCollectEvent.bind(self));
      window.setTimeout(() => {
        this.invokeShopCollectEvent();
      }, 5000);
    }
  }

  /**
   * 이벤트로 bind 하여 실행할 상품수집 로직
   * 2020.06.01 리뷰 타겟팅시 사용 되는 리뷰 데이터 수집을 위해 Review 토픽 추가
   */
  private invokeShopCollectEvent(): void {
    if (DepatureInspector.getInstance().collectState) {
      return;
    }

    /* 똑같은 백그라운드 작업을 수행하지 않도록 하여 커맨드 실행 */
    Tracker.getInstance(false).executeCommand([
      'send',
      'collect',
      this.adverId,
      this.options
    ]);
  }

  /**
   * 버튼 클릭 이벤트로 실행되는 전환 서비스
   * todo 테스트
   */
  private startConversionByBtnClick(eventType: EventType, options: {}): void {
    const enableToStart = (eventType: EventType, options: {}): boolean => {
      /* 컨버전 이벤트 여부 */
      const isConversionEvent: boolean = eventType === EventType.CONVERSION;
      /* 옵션에 "btnSelector" 있는지 */
      const hasBtnSelector: boolean = typeof options !== 'undefined' && typeof options['btnSelector'] === 'string';
      /* 간편결제 시스템이 아닌지 */
      const isNotPaySystem: boolean = typeof options !== 'undefined' && typeof options['paySys'] === 'undefined';
      return isConversionEvent && hasBtnSelector && isNotPaySystem;
    };

    if (enableToStart(eventType, options)) {
      const convType = options['convType'] ? options['convType'] : ConversionType.PRODUCT;
      if (convType === ConversionType.PRODUCT) {
        EventUtil.addEvent(document.querySelector(options['btnSelector']), 'click', this.invokeConversionService.bind(self));
      } else {
        //비쇼핑의 경우 한페이지에서 여러개의 버튼에 컨버전 이벤트가 발생될 가능성도 있다.
        let eventAdd = false;
        let addCount = 0;
        const etcEventAdd = () => {
          const btnArr = document.querySelectorAll(options['btnSelector']);
          for (let i = 0; i < btnArr.length; i++) {
            eventAdd = true;
            EventUtil.addEvent(btnArr[i], 'click', this.invokeConversionService.bind(self));
          }
          //비쇼핑의 경우 전환 잡을 버튼이 늦게 호출되는 경우가 발생하여 1초마다 제한 횟수까지 시도한다.
          if(!eventAdd){
            setTimeout(() => {
              if (addCount++ === 3) return;
              etcEventAdd();
            }, 1000);
          }
        }
        etcEventAdd();
      }
    }
  }

  /**
   * 전환 서비스 수행
   */
  private invokeConversionService(): void {
    /* 똑같은 백그라운드 작업을 수행하지 않도록 하여 커맨드 실행 */
    Tracker.getInstance(false).executeCommand([
      'send',
      'conversion',
      this.adverId,
      this.options
    ]);
  }

  /**
   * 키워드 추출 및 저장
   */
  private extractExternalKeyword(): void {
    /* 전환 키워드 */
    const keywordManager = new KeywordManager();
    keywordManager.storeExternalKeyword();
    keywordManager.storeInternalKeyword(this.adverId, '');
  }

  /**
   * 상품 수집시에 리뷰 수집을 위한 instance 생성
   * @private
   */
  private setConfigReviewParser() {
    if (this.eventType === EventType.COLLECT) {
      ReviewParser.getInstance(this.adverId, this.options && this.options['device'] ? this.options['device'] : 'B');
    }
  }

  /**
   * mbris(인사이트 마케팅)에서 사용하기 위한 상품코드를 전역 객체에 저장시킨다.
   * @param {EventType} eventType - 이벤트 타입
   */
  private feedToMbris(eventType: EventType): void {
    try {
      if (eventType === EventType.COLLECT) {
        window['ENP_MBRIS_PRD_CODE'] = (this.dataParser.getParsedData(eventType) as JsonObject).data['productCode'];
      }
    } catch (e) {
    }
  }


  /**
   * CRM 기능 수행을 위하여 스크립트 공통 호출
   *  !!) 광고주측에 CRM 스크립트, 모비온 스크립트가 삽입되면
   *      너무 많은 스크립트가 삽입이 되어 최선의 조치로 4.0에서 Crm 스크립트를 호출
   * @private
   */
  private excuteCrmScript(): void {
    try {
      if (this.eventType !== EventType.COMMON) return;
      const crmCtrl = new CrmFactory().createController(this.dataParser.getHostingType());

      if ((this.dataParser.getHostingType() === HostingType.CAFE24_SMART
          || this.dataParser.getHostingType() === HostingType.GODOMALL_RENT
          || this.dataParser.getHostingType() === HostingType.GODOMALL_SELF
          || this.dataParser.getHostingType() === HostingType.MAKESHOP_D2
          || this.dataParser.getHostingType() === HostingType.MAKESHOP_D4
          || this.dataParser.getHostingType() === HostingType.IMWEB)
          && crmCtrl) {
        crmCtrl.executeScript(this.adverId, this.dataParser.getHostingType());
      }
    } catch (e) {
    }
  }

  /**
   * referrer domain에 해당하는 값인 경우 sessionStorage에 삽입한다.
   */
  private setSessionReferrer(){
    try {
      const referrerDomain = new ReferrerDomain();
      referrerDomain.setSessionKey();
    } catch { }
  }
}

/* 이벤트 등록 및 제거시 this 바인딩을 위한 변수 */
let self: CreateCommand;
