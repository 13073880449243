/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {JsonObject} from "../../../lib/json/JsonObject";
import {Value} from "../../../lib/value/Value";
import {StringUtil} from "../../../lib/common/StringUtil";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2020-04-02.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Pippin11DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    const price: string = document.querySelector('form#form1')!['price']['value'].replace(/\D/g,'');
    const dcPrice: string = StringUtil.isEmpty(document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g,''))
                              ? '0' : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g,'');

    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': price,
      'productUrl': window.location.href,
      'dcPrice': dcPrice,
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': dcPrice === '0' ? price : dcPrice,
      'totalQty': this.getTotalQty()
    };
  }

  protected getShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': document.querySelector('form#form1')!['price']['value'].replace(/\D/g,''),
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g,''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) { 
        return window['product_name'];
      } else {
        if(window.location.pathname.indexOf('/m/') === 0) {
          let productName = document.querySelector('div.shop_name_in')!.textContent!.trim();
          let productDesc = document.querySelector('div.shop_name_in div.shop_de_subname') != null
              ? document.querySelector('div.shop_name_in div.shop_de_subname')!.textContent!.trim() : '';
          let hashTag = document.querySelector('div.shop_name_in div.hash') != null
              ? document.querySelector('div.shop_name_in div.hash')!.textContent!.trim() : '';

          return productName.replace(productDesc, '').replace(hashTag, '').trim();
        } else {
          return document.querySelector('div.info > div.tit-prd>h2')!.textContent!.trim();
        }
      }
    } catch (e) {
      return '';
    }
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('figure figcaption p.price span')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': window['psrmedia_params']['item'][i]['name'].trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];
    for (let i = 0; i < productList.length; i++) {
      const rawPrice = window['psrmedia_params']['item'][i]['price'];
      const rawQty =(document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;
      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);
      productList[i]['productName'] = window['psrmedia_params']['item'][i]['name'].trim();
      productList[i]['price'] =  price / qty;
      productList[i]['qty'] = qty;
    }
    return jsonObj;
  }

}
