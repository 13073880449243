import { JsonObject } from "../../../lib/json/JsonObject";
import { GlobalVariables } from "../../../types/GlobalVariables";
/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-06
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2023-04-06.
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Jionmeditech1DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {};
    const targetObj: {} = this.getProductTargetObj();
      parsedData = {
        'adverId': this.adverId,
        'productCode': window['product_uid'],
        'productName': window['product_name'],
        'price': window['product_price'],
        'productUrl': window.location.href,
        'dcPrice': window['product_price'],
        'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
        'imageUrl': (document.querySelector('#lens_img') as HTMLImageElement).src,
        'secondImageUrl': targetObj['secondImageUrl'],
        'thirdImageUrl': targetObj['thirdImageUrl'],
        'fourthImageUrl': targetObj['fourthImageUrl'],
        'topCategory': this.category['topCategory']
            ? this.category['topCategory']
            : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
    return new JsonObject(parsedData);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];

    const price =window['product_price'];

    const dcPrice = window['product_price'];

    parsedProducts.push({
      'productCode': window['product_uid'],
      'productName':window['product_name'],
      'price': price && dcPrice && price < dcPrice ? price : (dcPrice ? dcPrice : price),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  } 
}