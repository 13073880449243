/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-31
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { ImwebDataParser } from "../ImwebDataParser";

/**
 * create on 2021-12-31
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class AineuninDataParser extends ImwebDataParser {
  protected getShopCollectData(): JsonObject {
    let jsonData:JsonObject = super.getShopCollectData();
    const price: number = NumberUtil.parseNumber(jsonData.data['price']);
    jsonData.data['price'] = price === 0 ? 1 : price;
    return jsonData;
  }
}