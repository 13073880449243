/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-09
 */
import {JsonObject} from '../../../lib/json/JsonObject';
import {EventType, HostingType} from '../../../types/GlobalEnums';
import {Config} from '../config/Config';
import {AJAXer} from '../../../lib/ajax/AJAXer';
import {DataValidator} from '../../../dataValidator/DataValidator';
import {DataParser} from '../../../dataParser/DataParser';
import {CallbackFunction} from '../../../types/GlobalTypes';
import {DataValidatorFactory} from '../../../dataValidator/DataValidatorFactory';
import {FunctionExecuteObserver} from "../../../observer/function/FunctionExcuteObserver";
import {NullParingData} from "../../../lib/ajax/NullParingData";
import {CrmFactory} from "../../crm/factory/CrmFactory";
import {AdBlockCtrl} from "../../adblock/AdBlockCtrl";
import {Identification} from "../../ident/Identification";

/**
 * create on 2019-11-09.
 * <p> 각 API 서버로 데이터를 전송하는 역할을 갖는 클래스 </p>
 * <p> {@link TransmitterFactory} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class Transmitter {
  /* 이벤트 타입 */
  protected eventType: EventType;
  /* 전송할 서버 정보 */
  protected config: Config;
  /* DOM을 이용해 데이터 추출기 */
  protected dataParser: DataParser;
  /* AJAX 통신기 */
  protected ajaxer: AJAXer;
  /* 서버로 전송될 JSON 데이터 */
  protected jsonObject: JsonObject;
  /* 데이터 검증기 */
  protected dataValidator: DataValidator;
  /* 전송 후 실행할 콜백 함수 */
  protected callback?: CallbackFunction;

  protected constructor(eventType: EventType, config: Config, dataParser: DataParser) {
    this.eventType = eventType;
    this.config = config;
    this.dataParser = dataParser;
    this.ajaxer = AJAXer.getInstance();
    this.jsonObject = new JsonObject();
    this.dataValidator = DataValidatorFactory.createDataValidator(this.config.adverId);
    this.initAJAXerOption(this.ajaxer, this.eventType);
  }

  /**
   * 파싱한 데이터와 추가로 받은 데이터를 서버로 데이터를 전송 후 콜백 수행.
   * @param {JsonObject} extraData  - 추가로 전송할 데이터
   * @param {CallbackFunction} callback - 전송이 완료된 후 실행될 콜백 함수
   */
  send(extraData?: JsonObject, callback?: CallbackFunction): void {
    this.callback = callback;
    this.parseAndSendData(extraData);
  }


  /**
   * 부가적인 데이터를 추가
   * <b>NOTE: 데이터가 <code>undefined</code>인 경우 아무런 동작을 하지 않음.</b>
   * @param {JsonObject|{}} data - 추가할 데이터
   * @param {boolean} [replace=true] - 중복 데이터 대체 여부
   */
  protected addExtraData(data?: JsonObject | {}, replace = true): void {
    if (typeof data === 'undefined') {
      return;
    }

    this.jsonObject.appendData(data, replace);
  }

  /**
   * 서버로 데이터 전송
   * <b>NOTE: </b>다른 작업이 필요한 경우 Subclass에서 Override 할 것.
   */
  protected finish(): void {
    AdBlockCtrl.getInstance().setJsonObject(this.jsonObject);
    this.ajaxer.post(this.config.url, this.jsonObject.data, this.callback);
  }

  /**
   * AJAX 통신시 각 이벤트에 해당하는 옵션을 지정한다.
   * {@link AJAXer} 클래스에 초기화되며, 포맷은 {@link AJAXerOption}이다.
   * 이 옵션은 {@link XMLHttpRequest}의 옵션으로 초기화될 값이다.
   * @param {AJAXer} ajaxer         <code>AJAXer</code> 인스턴스
   * @param {EventType} eventType   이벤트 타입
   */
  private initAJAXerOption(ajaxer: AJAXer, eventType: EventType): void {
    /* 해당 인스턴스는 singleton 패턴이므로 다른 곳에서 변경되었을 설정을 다시 초기화 */
    ajaxer.options.withCredentials = true;
    ajaxer.options.setHeader = true;
    ajaxer.options.timeout = 1500;

    /* 장바구니, 찜 버튼, 전환의 경우만 timeout을 3초로 */
    switch (eventType) {
      case EventType.CART:
      case EventType.WISH:
      case EventType.CONVERSION:
        ajaxer.options.timeout = 3000;
        break;
      case EventType.COMMON:
        ajaxer.options.timeout = 1800;
        break;
      default:
        /* AJAXer 클래스에서 기본값으로 설정된 옵션을 따른다 */
        break;
    }
  }

  /**
   * 데이터를 파싱하여 검증을 이후 서버로 전송
   * @param {JsonObject} extraData - 전송할 추가 데이터
   */
  private parseAndSendData(extraData?: JsonObject): void {
    /* 현재 멤버 변수를 파싱한 데이터로 초기화 파싱중 오류가 발생한 경우 늦게 로드되서 발생할 수도 있기 때문에 */
    if (this.addParsedData(extraData) instanceof NullParingData) {
      return;
    } else {
      /* 인자로 받은 데이터를 추가 */
      this.addExtraData(extraData);

      /* 전송 전에 선행되어야 할 작업 */
      this.preSend();

      /*localstorage에서 au_id, _ip_info 저장*/
      this.addIndentification();

      /* CRM 전송 데이터 추가 */
      this.appendCrmData(this.config.adverId, this.dataParser.getHostingType(), this.eventType);

      /* 전송하기 전 데이터 검증 */
      this.jsonObject.data = this.validate(this.jsonObject.data);

      /* 전송 처리 */
      this.finish();
    }

  }

  /**
   * 데이터 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 데이터
   */
  private validate(data: {}): {} {
    return this.dataValidator.validate(this.eventType, data);
  }

  /**
   * 광고주의 웹 애플리케이션에서 파싱한 데이터를 추가
   */
  private addParsedData(extraData?: JsonObject): JsonObject | NullParingData {
    const parsedData: JsonObject | NullParingData = this.dataParser.getParsedData(this.eventType);

    if (parsedData instanceof NullParingData) {
      this.triggerFunctionObserver(extraData);
    }

    this.jsonObject.appendData(parsedData);
    return parsedData;
  }

  /**
   * 로드가 늦게되어서 상품 파싱이 실패할시 Observer 동작을 위한 메소드
   * @param extraData 추가데이터
   * @protected
   */
  protected triggerFunctionObserver(extraData): void {
    const observer: FunctionExecuteObserver = new FunctionExecuteObserver(this.dataParser.getHostingType());
    observer.startingObserving(this, "parseAndSendData", extraData);
  }

  /**
   * Gateway Service에서 CRM API로 전송될 데이터 추가 후 반환
   * @param adverId
   * @param hostingType
   * @param eventType
   * @private
   */
  private appendCrmData(adverId: string, hostingType: HostingType, eventType: EventType): void {
    try {
      const crmCtrl = new CrmFactory().createController(hostingType);

      if (crmCtrl && crmCtrl.isCrmAdvertiser()) {
        const crmAppendData: {} = crmCtrl.getExtraData(eventType);
        if (crmAppendData && Object.keys(crmAppendData).length > 0) {
          this.jsonObject.addData('crmData', crmAppendData);
        }
      }
    } catch (e) {
    }
  }

  /**
   * auid, ip_info 정보를 저장
   * @private
   */
  private addIndentification(): void {
    const idenrifiObj = new Identification();
    const auId = idenrifiObj.getAuIdDataFromStorage();
    const ipInfo = idenrifiObj.getIpInfoFromStorage();
    if(auId === 'undefined' || auId === 'null') return;
    if (auId && ipInfo) {
      this.jsonObject.addData('identKey', auId);
      this.jsonObject.addData('identNumber', ipInfo);
    }
  }

  /**
   * 데이터를 전송하기 전에 필요한 작업을 수행.
   * <b>Note : </b>필요한 작업이 있다면 이 메소드를 구현할 것.
   */
  protected abstract preSend(): void;
}
