/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-28
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-03-28.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class JnkscienceDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('section.sub_total > span.right')!.textContent!.replace(/\D/g, ''));
    return jsonObj;
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    try{
      superData['price'] = NumberUtil.parseNumber(document.querySelector('div.prices > div > span.price_custom')!.textContent!.replace(/\D/g, ''));
      superData['dcPrice'] = NumberUtil.parseNumber(document.querySelector('div.prices > div > span.price_price')!.textContent!.replace(/\D/g, ''));
      return superData;
    }catch{
      return superData;
    }
  }
}
