/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-09-15
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-09-15.
 * <p> 11may 광고주 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MayDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (document.querySelector('div.detailArea>div.imgArea>img')) {
      return (document.querySelector('div.detailArea>div.imgArea>img') as HTMLImageElement).src;
    } else {
      return super.getImageSrc();
    }
  }
}
