/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-11-09
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { GlobalVariables } from "../../../types/GlobalVariables";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-11-09.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SinpoongDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getParsedTotalPrice(): number {
    const parsedTotalPrice = (): number => {
      /* 주문완료 페이지 */
      if (this.isOrderPage()) {

        const totalPrice: string = this.isMobile(this.commandOptions)
            ? (document.querySelector('.prc') as Element)!.textContent!.trim()
            : (document.querySelector('.order_end_cont .order_payment_sum') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 장바구니 페이지 */
      else if (this.isBasketPage()) {
        const totalPrice: string = (document.querySelector('#totalSettlePrice') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 다른 페이지는 0원으로 처리 */
      else {
        return 0;
      }
    };

    try {
      return parsedTotalPrice();
    } catch (e) {
      return 0;
    }
  }
}
