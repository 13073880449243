/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-25
 */

import {PayBtnEventObserver} from "../PayBtnEventObserver";
import {PaySystem} from "../../../service/paySystem/PaySystem";
import PaySelector from "../../config/selector/pay.observer.selector.json";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * @class NaverPayBtnEventObserver
 *
 * create on 2020-03-25.
 * <p> 네이버페이의 경우 페이지 로드가 완료 된 후 재생성 되는 경우가 있어서
 *     재생성 되었을 경우 Oberver로 관찰해서 이벤트 재 할당을 한다.</p>
 * <p> {@link PayBtnEventObserver } and {@link MutationObserver } 관련 클래스 </p>
 *
 * @param PaySystem
 * @param paySystemType
 * @param adverId
 *
 * @version 1.0
 * @author mgpark
 */
export class NaverPayBtnEventObserver extends PayBtnEventObserver {
  private removeNodes: Node[] = [];
  private addedNodes: Node[] = [];

  constructor(paySystem: PaySystem, paySystemType: string, adverId: string) {
    super(paySystem, paySystemType, adverId);
  }

  /**
   * MutationObser의 Callback 함수를 정의한 메소드
   *  - 네이페이가 다시 그려지는 시점
   *    1. MutationRecord 하나의 객체에 RemoveNodes, AddedNodes가 존재(바로 삭제되고 추가됨)
   *    2. MutationRecord에 RemoveNodes가 탐지되고 이후 MutationRecord에 AddedNodes가 탐지됨(삭제된 후 시간이 흐른뒤 탐지)
   * @param mutations
   * @param obs
   * @protected
   */
  protected observerCallback(mutations: MutationRecord[], obs: MutationObserver) {
    mutations.forEach((mutation) => {
      //삭제된 요소에서 네이버페이 Element를 특정함
      this.removeNodes = mutation.removedNodes.length > 0 ? this.getNaverPayNode(mutation.removedNodes) : this.removeNodes;
      //추가된 요소에서 네이버페이 Element를 특정함
      this.addedNodes = mutation.addedNodes.length > 0 ? this.getNaverPayNode(mutation.addedNodes) : this.addedNodes;

      //추가된 노드(addedNodes), 삭제된 노드(removedNodes)가 둘다 존재하는 경우 다시 그려지는 현상으로 확인
      if (this.removeNodes.length > 0 && this.addedNodes.length > 0) {
        //mutation.addedNodes에서 네이버에서 생성되는 DIV(NC_ID)를 찾음
        const npayDivList: Node[] = this.addedNodes;

        if (npayDivList && npayDivList.length > 0) {
          // 버튼 담고있는 객체에서 버튼들을 가져옴
          // - 버튼들의 Selector은 pay.observer.selector.json 참조
          this.injectBtnEvent(npayDivList[0] as HTMLElement);
          // obs.disconnect();
          return;
        }
      }
    });
  }

  /**
   * MutationObserver의 관찰 대상이 되는 객체를 반환
   * 1. pay.observer.selector.json targetSelector의 HTML 객체의 배열 반환
   * 2. 화면에서 네이버페이 HTML 요소중 최상위 DIV인 NC_ID_~~의 부보 DIV를 탐색 후 반환
   * @protected
   */
  protected getTargetElementList(): any[] | undefined {
    try {
      const targetSelectorFromJson: string = PaySelector[this.paySystemType]['targetSelector'][this.adverId];
      if (targetSelectorFromJson && StringUtil.isNotEmpty(targetSelectorFromJson)) {
        return Array.prototype.slice.call(document.querySelectorAll(targetSelectorFromJson));
      }

      const paySubDivList: NodeListOf<HTMLElement> = document.querySelectorAll("div[id^='NC_ID']");
      const parentElementList: any[] = [];
      for (let i = 0; i < paySubDivList.length; i++) {
        if (paySubDivList[i].parentNode) {
          parentElementList.push(paySubDivList[i].parentNode);
        }
      }
      if (parentElementList.length > 0) {
        return parentElementList;
      }

      return undefined;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * MutationObserver의 Option
   * @protected
   */
  protected getObserverOptions(): {} {
    return {
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false
    }
  }

  /**
   * 네이버페이와 관련된 Element Node인지 판별
   *  1. 네이버페이 구성 Element 중 최상위 DIV인 NC_ID_로 시작하는 ID값을 가진 Element인지 우선 판별
   *  2. 네이버페이 구성중 버튼의 DIV인 class가 npay_button_box인 Element를 탐지
   * @param nodeList
   * @private
   */
  private getNaverPayNode(nodeList: NodeList): Node[] {
    const isTopNodeElement = Array.prototype.slice.call(nodeList).filter(
        (node) => {
          try {
            const nodeId = (node as Element).id ? (node as Element).id : '';
            return nodeId!.search(/NC_ID_*/) > -1;
          } catch {
            return false;
          }
        }
    );
    if (isTopNodeElement && isTopNodeElement.length > 0) {
      return isTopNodeElement;
    }

    const isButtonDivElement = Array.prototype.slice.call(nodeList).filter(
        (node) => {
          try {
            const nodeClass = (node as Element).classList ? (node as Element).classList.toString() : '';
            return nodeClass!.search(/npay_button_box/) > -1
          } catch {
            return false;
          }
        }
    );
    if (isButtonDivElement && isButtonDivElement.length > 0) {
      return isButtonDivElement;
    }
    return [];
  }
}
