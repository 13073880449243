/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-08
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-06-08
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class GwinDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    const product: Array<{}> = jsonObj['product'];
    if(!this.isMobile(this.commandOptions)){
      jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('td.total div.box strong:nth-child(2)')!.textContent!.replace(/\D/g,''));
    }    
    return jsonObj;
  }
  
}
