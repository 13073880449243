/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-05-18
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2023-05-18
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SevenchallengDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    try{
      return {
        price: NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content),
        dcPrice: NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content)
      }
    } catch{
      return super.getProductPrice();
    } 
  }
}