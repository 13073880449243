/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-06-02
 */

import { InvalidData } from "../../../lib/ajax/InvalidData";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {FirstmallDataParser} from "../FirstmallDataParser";

/**
 * create on 2021-06-02
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class NutricoreDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      return this.isMobile(this.commandOptions)
          ? document.querySelector('#goods_view_quick > table > tbody td > div.goods_name')!.textContent!.trim()
          : document.querySelector('#goods_view ul li.goods_name h3')!.textContent!.replace(document.querySelector('#goods_view ul li.goods_name h3 span.hide')!.textContent!, '').trim();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td:not(:first-child)');
        const productName = productInfo[0].textContent!.replace(productInfo[0].querySelector('.order_option')!.textContent!, '').trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[1].firstElementChild!.textContent!.trim();
        const productPrice = productInfo[2].textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(this.getSelector(['.price','.clearbox dd.total > div.ft_red > span'])!.textContent!.match(/^[\d,]+/)![0]),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('table>tbody>tr>td');
        const productName = productInfo[0].querySelector('div.goods_name')!.textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[0].querySelector('div.cart_ea')!.textContent!.split(':')[1]!.trim().match(/^[\d,]+/)![0];
        const productPrice = productInfo[0].querySelector('span.cart_price_num')!.textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': '',
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('span.sum_price>span.settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}