/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-12
 */

import {CrmDao} from "../CrmDao";
import {CrmStorageKey, CrmStorageType} from "../../types/CrmEnum";
import { StringUtil } from "../../../../lib/common/StringUtil";

/**
 * create on 2021-04-12
 * <p> 고도몰 CRM 데이터 Dao </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class GodoMallCrmDao extends CrmDao {
  constructor() {
    super(CrmStorageType.LOCAL);
  }

  /**
   * 회원 아이디 수집 후 반환
   *  고도몰의 경우 CRM에서 회원 아이디 파싱 후 스토리지에 아이디를 저장
   */
  getUserId(): {} {
    const storageLoginId = this.storage!.getItem(CrmStorageKey.LOGIN_ID);
    return storageLoginId ? {'userId': atob(storageLoginId)} : {};
  }

  /**
   * 무통장 주문 전환 시 계좌번호 정보, 입금자명 수집 후 반환
   * @returns 
   */
  getBankInfo(): {} {
    try {
      let bankInfo = this.getLocalPaymentInfo();
      delete bankInfo['paymentMethod'];
      if (bankInfo['bankNo']) {
        return {
          bankNo: decodeURIComponent(bankInfo['bankNo']),
          bankAccountHolder: decodeURIComponent(bankInfo['bankAccountHolder']),
          bank: decodeURIComponent(bankInfo['bank']),
          bankDepositor: decodeURIComponent(bankInfo['bankDepositor'])
        };
      } else if (document.querySelector('.pay_with_list ul li')) {
        //웹 가상계좌 주문 전환 시
        return {
          bankNo: document.querySelector('.pay_with_list ul li:nth-of-type(2)')!.textContent!.split(':')[1].trim(),
          bankAccountHolder: decodeURIComponent(bankInfo['bankAccountHolder']),
          bank: document.querySelector('.pay_with_list ul li:nth-of-type(1)')!.textContent!.split(':')[1].trim(),
          bankDepositor: decodeURIComponent(bankInfo['bankDepositor'])
        };
      } else if (document.querySelector('.order_end_box ul li:nth-of-type(2)')) {
        //모바일 가상계좌 주문 전환 시
        return {
          bankNo: document.querySelector('.order_end_box ul li:nth-of-type(2)')!.childNodes[4].textContent!.split(':')[1].trim(),
          bankAccountHolder: decodeURIComponent(bankInfo['bankAccountHolder']),
          bank: document.querySelector('.order_end_box ul li:nth-of-type(2)')!.childNodes[2].textContent!.split(':')[1].trim(),
          bankDepositor: decodeURIComponent(bankInfo['bankDepositor'])
        }
      }

      return {};
    } catch {
      return {}
    }
  }

  /**
   * 결제수단 수집
   */
  getPayMethodInfo(): {} {
    try {
      let bankInfo = this.getLocalPaymentInfo();
      return bankInfo['paymentMethod'] ? { paymentMethod: bankInfo['paymentMethod'] } : {};
    } catch {
      return {};
    }
  }

  getLocalPaymentInfo(): {} {
    try {
      const paymentInfo = window.localStorage.getItem('mobtune_payment_info');
      return paymentInfo ? JSON.parse(window.atob(paymentInfo)) : {};
    } catch {
      return {};
    }
  }

  setOrderFormSessionStorage(): void { }
}
