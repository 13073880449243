/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-05
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { StringUtil } from "../../../lib/common/StringUtil";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-05.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class AaronshopDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('figure figcaption.basketRight .SMSbasketPrice')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebConversionData(): JsonObject{
    const jsonObj: JsonObject = super.getWebConversionData();

    const product = jsonObj.data['product'];

    for(let i = 0, len = product.length; i < len; i++){
      product[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }

  protected getMobileConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getMobileConversionData();

    const product = jsonObj.data['product'];

    for(let i = 0, len = product.length; i < len; i++){
      product[i]['productName'] = document.querySelectorAll('div.product_info td div.title a')[i].childNodes[0].textContent!.trim();
    }

    return jsonObj;
  }
}
