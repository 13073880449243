/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-12-16
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-12-16
 * <p> Tenevis - tenevis.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class TenevisDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();

    if (!this.isMobile(this.commandOptions)) {
      const priceValueObject = this.getPriceValues();
      jsonObj.replaceData('price', priceValueObject['price']);
      jsonObj.replaceData('dcPrice', priceValueObject['dcPrice']);
    }

    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();

    if (!this.isMobile(this.commandOptions)) {
      const priceValueObject = this.getPriceValues();
      jsonObj['product'][0]['price'] =  priceValueObject['price'];
      jsonObj['product'][0]['dcPrice'] =  priceValueObject['dcPrice'];
    }

    return jsonObj;
  }

  private getPriceValues(): { price: number, dcPrice: number } {
    if(document.querySelector('span.consumer')) {
      return {
        price: NumberUtil.parseRationalNumber(document.querySelector('span.consumer')!.textContent!.trim()),
        dcPrice : NumberUtil.parseRationalNumber(document.querySelector('.sell_price')!.childNodes[0].textContent!.trim())
      };
    } else {
      return {
        price: NumberUtil.parseRationalNumber(document.querySelector('.sell_price')!.childNodes[0].textContent!.trim()),
        dcPrice : 0
      };
    }
  }
}
