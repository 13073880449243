/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-09-02
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-09-02.
 * <p> Apharmhealth 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class ApharmhealthDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (document.querySelector('div.prdImgView > p > img')) {
      return (document.querySelector('div.prdImgView > p > img') as HTMLImageElement).src;
    } else if (document.querySelector('div#prdDetail img[src*="petit_n"]')) {
      return (document.querySelector('div#prdDetail img[src*="petit_n"]') as HTMLImageElement).src;
    } else if (window['mobImg']) {
      return window['mobImg'];
    } else {
      /* 빈 문자열을 전송하면 서버에서 수집 안함 */
      return super.getImageSrc();
    }
  }
}
