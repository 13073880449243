/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-09-29
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2021-09-29.
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class TwinkringDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let jsonData: JsonObject = super.getShopCollectData();
    jsonData.data['price'] = NumberUtil.parseRationalNumber(document.querySelector('form#form1')!['price']['value']);
    jsonData.data['dcPrice'] = NumberUtil.parseRationalNumber(window['productPrice'] ? window['productPrice'] : document.querySelector('form#form1')!['disprice']['value']);

    return jsonData;
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const price = NumberUtil.parseRationalNumber(document.querySelector('form#form1')!['price']['value']);
    const dcPrice = NumberUtil.parseRationalNumber(window['productPrice'] ? window['productPrice'] : document.querySelector('form#form1')!['disprice']['value']);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': price && dcPrice && price < dcPrice ? price : (dcPrice ? dcPrice : price),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  protected getImageUrl(): string {
    if(!this.isMobile(this.commandOptions)){
      if (document.querySelector('.detailArea .keyImg img.BigImage')) {
        return (document.querySelector('.detailArea .keyImg img.BigImage') as HTMLImageElement).src;
      }
    }

    return super.getImageUrl();
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll([
        '.orderList tbody.xans-order-list tr td.total'
      ])[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (this.getSelectorAll([
        '.orderList tbody.xans-order-list [name=orgamount]'
      ])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll([
          '.orderList tbody.xans-order-list [name=branduid]'
        ])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll([
          '.orderList tbody.xans-order-list tr .prduct a'
        ])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }
}
