
/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-27
 */
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";


/**
 * create on 2022-01-27.
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class HakeemDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if (this.isMobile(this.commandOptions) && document.querySelector('.swiper-slide.swiper-slide-active img')) {
      return (document.querySelector('.swiper-slide.swiper-slide-active img') as HTMLImageElement).src;
    }
    return super.getImageUrl();
  }
}
