/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-12
 */

import {CrmDao} from "../CrmDao";
import {CrmStorageKey, CrmStorageType} from "../../types/CrmEnum";

/**
 * create on 2021-04-12
 * <p> 메이크샵에 CRM 데이터 Dao </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class MakeshopCrmDao extends CrmDao {
  constructor() {
    super(CrmStorageType.LOCAL);
  }

  /**
   * 회원 아이디 수집 후 반환
   * (솔루션별로 회원아이디 수집법이 다를수 있어 추상화)
   */
  getUserId(): {} {
    const storageLoginId = this.storage!.getItem(CrmStorageKey.LOGIN_ID);
    return storageLoginId ? {'userId': atob(storageLoginId)} : {};
  }

  /**
   * 무통장 주문 전환 시 계좌번호 정보, 입금자명 수집 후 반환
   * @returns 
   */
  getBankInfo(): {} {
    try {
      let bankInfo = this.getLocalPaymentInfo();
      delete bankInfo['paymentMethod'];
      if (bankInfo['bankNo']) {
        return {
          bankNo: decodeURIComponent(bankInfo['bankNo']),
          bankAccountHolder: decodeURIComponent(bankInfo['bankAccountHolder']),
          bank: decodeURIComponent(bankInfo['bank']),
          bankDepositor: decodeURIComponent(bankInfo['bankDepositor'])
        };
      } else if (document.querySelector('#bankname_banker')) {
        const virtualAccount = document.querySelector('#bankname_banker')!.parentElement!.childNodes[0].textContent!.split(' [');
        return {
          bankNo: virtualAccount[1].trim().replace(/\D/g, ''),
          bankAccountHolder: decodeURIComponent(bankInfo['bankAccountHolder']),
          bank: virtualAccount[0].trim(),
          bankDepositor: decodeURIComponent(bankInfo['bankDepositor'])
        };
      }
      return {};
    } catch {
      return {}
    }
  }

  /**
   * 결제수단 수집
   */
  getPayMethodInfo(): {} {
    try {
      let bankInfo = this.getLocalPaymentInfo();
      return bankInfo['paymentMethod'] ? { paymentMethod: bankInfo['paymentMethod'] } : {};
    } catch {
      return {};
    }
  }

  getLocalPaymentInfo(): {} {
    try {
      const paymentInfo = window.localStorage.getItem('mobtune_payment_info');
      return paymentInfo ? JSON.parse(window.atob(paymentInfo)) : {};
    } catch {
      return {};
    }
  }

  setOrderFormSessionStorage(): void { }
}
