/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-16
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { Tracker } from "../../../tracker/Tracker";
import { EventUtil } from "../../../lib/event/EventUtil";

/**
 * create on 2021-12-16
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class StorebomDataParser extends GodomallRentDataParser {
  
  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.webCustomNpayEvent();
    return super.getWebShopCollectData(formElement);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    //모바일에서 상품 스크립트를 호출하면서 npay observer 호출
    this.mobileMutaionObserver();

    let superData = super.getMobileShopCollectData(formElement);
    superData.data['productName'] = (document.querySelector('meta[property="og:title"]') as HTMLMetaElement)['content'];
    superData.data['imageUrl'] = (document.querySelector('meta[property="og:image"]') as HTMLMetaElement)['content'];
    return superData;
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (document.querySelector('meta[property="og:title"]') as HTMLMetaElement)['content'],
      'price': priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement)
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'] && NumberUtil.parseRationalNumber(formElement!['set_total_price'].value) > 0
          ? formElement!['set_total_price'].value
          : (NumberUtil.parseRationalNumber(parsedProducts[0]['dcPrice'] > 0 ? parsedProducts[0]['dcPrice'] : parsedProducts[0]['price'])) * NumberUtil.parseNumber(parsedProducts[0]['qty']),
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }

  protected getParsedTotalPrice(): number {
    const parsedTotalPrice = (): number => {
      /* 주문완료 페이지 */
      if (this.isOrderPage()) {

        const totalPrice: string = this.isMobile(this.commandOptions)
            ? window['ENP_VAR']['totalPrice']
            : (this.getSelectorAll(['.order_payment_sum', '.table1 .c-red'])[1] as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 장바구니 페이지 */
      else if (this.isBasketPage()) {
        const totalPrice: string = this.isMobile(this.commandOptions) 
            ? document.querySelector('.price span[total-settle-price] price-amount')!.textContent!
            : (document.querySelector('#totalSettlePrice') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 다른 페이지는 0원으로 처리 */
      else {
        return 0;
      }
    };

    try {
      return parsedTotalPrice();
    } catch (e) {
      return 0;
    }
  }

  private webCustomNpayEvent(){
    EventUtil.addEvent(document.querySelector('button-npay button')!, 'click', ()=>{
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    })
  }

  //해당광고주만 npay custom으로 인해 임시 조치
  private mobileMutaionObserver(): void {
    // 변화를 감지할 노드를 선택합니다.
    const targetNode = document.querySelector('.st-buy-content')!;
    // 변화 감지 설정입니다.
    const config = { 
      childList: true,
      subtree: true,
      attributes: false,
      characterData: false 
    };
    const observer = new MutationObserver((mutaions) => {
      mutaions.forEach((mutaion) => {
        for(let i = 0; i < mutaion.addedNodes.length; i++){
          try{
            let npay = (mutaion.addedNodes[i] as HTMLElement).querySelector('#oder-popup-npay');
            if(npay) {
              EventUtil.addEvent(npay, 'click', ()=>{
                const tracker: Tracker = Tracker.getInstance(false);
                tracker.executeCommand([
                  'send',
                  'conversion',
                  this.adverId,
                  { 'device': 'M', 'paySys': 'naverPay' }
                ]);
              })
            }
          }catch{}
        }
      })
    });
    observer.observe(targetNode, config);
  }

  protected getPayConvDataFromBasketPage(): {} {  
  const superData = super.getPayConvDataFromBasketPage();

  const parsedProducts: Array<{}> = [];
  const inputList: NodeListOf<HTMLInputElement> =  document.querySelectorAll('div.product-cnt-wrapper div.cnt.input input[type="text"]');
  const inputPriceList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][id^="cartSno"]');

  for (let i = 0; i < inputList.length; i++) {
    const qty = NumberUtil.parseInteger(inputList[i].dataset['defaultGoodsCnt'] as string);
    const rawPrice = NumberUtil.parseNumber(inputPriceList[i].dataset['price'] as string);
    const price = rawPrice / qty;
    parsedProducts.push({
      'productCode': inputList[i].dataset['goodsNo'],
      'productName': inputList[i].dataset['goodsNm'],
      'qty': qty,
      'price': price
    });
  }
  
  superData['product'] = parsedProducts;
  return superData;
  }

}