/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-22
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { GlobalVariables } from "../../../types/GlobalVariables";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2021-02-22
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class FoodologyDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][id^="cartSno"]');
    /* 배송비 포함된 총 주문금액 가져오지 못할 경우 0원 */
    const parsedTotalPrice: number = this.getParsedTotalPrice();

    let totalPrice = 0;
    let totalQty = 0;

    if(this.isMobile(this.commandOptions)){
      for (let i = 0; i < inputList.length; i++) {
        const checkedEl = inputList[i]!.parentElement!.parentElement!;
        
        const qty = NumberUtil.parseInteger((checkedEl!.querySelector('div.info .itembody dd')!.textContent!.split(':')[1].trim()));
        const rawPrice = NumberUtil.parseNumber(inputList[i].dataset['price'] as string);
        const price = rawPrice / qty;
  
  
        parsedProducts.push({
          'productCode': new QueryStringer((checkedEl.querySelector('div.info a')! as HTMLAnchorElement).search.substring(1)).getParam('goodsNo'),
          'productName': (checkedEl.querySelector('div.info a .itemhead img')! as HTMLImageElement).title,
          'qty': qty,
          'price': price,
          'dcPrice': price,
          'topCategory': this.category['topCategory'] || '',
          'firstSubCategory': this.category['firstSubCategory'] || '',
          'secondSubCategory': this.category['secondSubCategory'] || '',
          'thirdSubCategory': this.category['thirdSubCategory'] || ''
        });
  
        totalPrice += rawPrice;
        totalQty += qty;
      }
    }else{
      for (let i = 0; i < inputList.length; i++) {
        const checkedEl = inputList[i]!.parentElement!.parentElement!.parentElement!;
        
        const qty = NumberUtil.parseInteger((checkedEl!.querySelector('td.ta-c.count input')! as HTMLInputElement).value as string);
        const rawPrice = NumberUtil.parseNumber(inputList[i].dataset['price'] as string);
        const price = rawPrice / qty;
  
  
        parsedProducts.push({
          'productCode': new QueryStringer((checkedEl.querySelector('td span a')! as HTMLAnchorElement).search.substring(1)).getParam('goodsNo'),
          'productName': (checkedEl.querySelector('td span a img')! as HTMLImageElement).title,
          'qty': qty,
          'price': price,
          'dcPrice': price,
          'topCategory': this.category['topCategory'] || '',
          'firstSubCategory': this.category['firstSubCategory'] || '',
          'secondSubCategory': this.category['secondSubCategory'] || '',
          'thirdSubCategory': this.category['thirdSubCategory'] || ''
        });
  
        totalPrice += rawPrice;
        totalQty += qty;
      }
    }
    

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      /* 배송비 포함된 금액을 가져오지 못하면 상품 가격들의 합으로 초기화 */
      'totalPrice': parsedTotalPrice > 0 ? parsedTotalPrice : totalPrice,
      'totalQty': totalQty
    };
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageElement: HTMLImageElement = this.getImageFromWebDetail();

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': imageElement ? imageElement.alt : '',
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': imageElement.alt.indexOf('품절') > 0 ? 'Y' : 'N',
      'imageUrl': imageElement ? imageElement.src : '',
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageElement: HTMLImageElement = this.getImageFromMobileDetail();

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': imageElement ? imageElement.alt : '',
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': imageElement.alt.indexOf('품절') > 0 ? 'Y' : 'N',
      'imageUrl': imageElement ? imageElement.src : '',
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }
}