/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-01-27
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { GlobalVariables } from "../../../types/GlobalVariables";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-01-27.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class VikingsmarketDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (this.getSelector(['div.slick-track img']) as HTMLImageElement).alt,
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': (this.getSelector(['div.item_photo_info_sec > div > div > div.item_photo_big > span > img']) as HTMLImageElement).src,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': (this.getSelector(['div.slick-track img']) as HTMLImageElement).alt,
      'price': priceObj['price'],
      'qty': this.getParsedTotalQty(formElement),
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
      'imageUrl': (this.getSelector(['div.item_photo_info_sec > div > div > div.item_photo_big > span > img']) as HTMLImageElement).src,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'].value,
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }
}
