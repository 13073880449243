/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee1</a>
 * @since 2023-06-08
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";


export class Sslkh70DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
    
  }

  protected isProductPage(): boolean {
    try {
      return (0 === window.location.pathname.indexOf('/product')
          || 0 === window.location.pathname.indexOf('/skin-skin117/product')
          || 0 == window.location.pathname.indexOf('/skin-mobile137/product'));
          ;
    } catch (e) {
      return false;
    }
  }
  protected isBasketPage(): boolean {
    try {
      return (0 === window.location.pathname.indexOf('/order/basket.html')
          || 0 === window.location.pathname.indexOf('/skin-skin117/order/basket.html')
          || 0 == window.location.pathname.indexOf('/skin-mobile137/order/basket.html'));
          

    } catch (e) {
      return false;
    }
  }
  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    let qty = 1;
    let totalPrice =  NumberUtil.parseInteger(document.querySelector('#span_product_price_text')!.textContent!.replace(/\D/g,''));
    let totalQty = 1;
    let price =NumberUtil.parseInteger(document.querySelector('#span_product_price_text')!.textContent!.replace(/\D/g,''));
     if(document.querySelector('#span_product_price_sale')!=null){
      price =NumberUtil.parseInteger(document.querySelector('#span_product_price_sale')!.textContent!.split('(')[0].replace(/\D/g,''));
      totalPrice=NumberUtil.parseInteger(document.querySelector('#span_product_price_sale')!.textContent!.split('(')[0].replace(/\D/g,''));
     }
    if(document.querySelectorAll('#totalProducts tbody.option_products > tr').length>0 ){
      qty =  NumberUtil.parseInteger(document.querySelector('#totalPrice > span')!.textContent!.split('(')[1].replace(/\D/g,''));
      totalPrice =  NumberUtil.parseInteger(document.querySelector('#totalPrice > span')!.textContent!.split('(')[0].replace(/\D/g,''));
      totalQty =  NumberUtil.parseInteger(document.querySelector('#totalPrice > span')!.textContent!.split('(')[1].replace(/\D/g,''));
    }
      parsedProducts.push({
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'qty': qty,
      'price': price
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': totalQty
    };
  }

  protected getPayConvDataFromBasketPage(): {} {
    const rawProductDataArr: Array<{}> = window['aBasketProductData'];
    const parsedProducts: Array<{}> = [];
    let totalPrice = 0;
    let totalQty = 0;
    for (let i = 0; i < rawProductDataArr.length; i++) {
      parsedProducts.push({
        'productCode': rawProductDataArr[i]['product_no'],
        'productName': rawProductDataArr[i]['product_name'],
        'qty': rawProductDataArr[i]['quantity'],
        'price': rawProductDataArr[i]['product_price'],
      });

      totalPrice += rawProductDataArr[i]['sum_price_org'];
      totalQty += rawProductDataArr[i]['quantity'];
    }

    if (0 == window.location.pathname.indexOf('/skin-mobile137/order/basket.html')) {
      totalPrice = NumberUtil.parseInteger(document.querySelector('#total_order_price_front')!.textContent!.replace(/\D/g,''));
    }
    else if (this.isMobile(this.commandOptions)) {
      totalPrice = NumberUtil.parseInteger(document.querySelector('div.total.order strong')!.textContent!.replace(/\D/g,''));
    }
    else{
      totalPrice = NumberUtil.parseInteger(document.querySelector('#total_order_price_front')!.textContent!.replace(/\D/g,''));
    }
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': totalQty
    };
  }
}
