/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-10-13
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-10-13
 * <p> seohung - https://m.fashionstart.net/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class KohasidfasDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {

    let superData = super.getMobileShopCollectData(formElement);
    superData.data['dcPrice'] = document.querySelectorAll('div.price_box Strong.price')[1].textContent!.replace(/\D/g,'');

    return superData;
  }
}