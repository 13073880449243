/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-29
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-04-29
 * <p> marketonj.co.kr - wisihealthy / samjin1 </p>
 * <p>
 *   삼진제약의 경우 전환 페이지에 2개의 스크립트가
 *   삽입 되어있어 사이트코드 세팅을 위한 별도 로직 추가
 * </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class WisihealthyDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonObj = super.getCommonTraceData();
    jsonObj.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonObj;
  }

  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    jsonObj.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonObj;
  }

  private getSiteCodeByAdverId(): string {
    switch (this.adverId) {
      case 'samjin1':
        return 'd5f923c3cd7ba05214aa5c15c20213a7';
      case 'wisihealthy':
        return '592f9acf5e350306c9a3040aa835b182';
      default:
        return '592f9acf5e350306c9a3040aa835b182';
    }
  }
}
