/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-08-25
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {Value} from "../../../lib/value/Value";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-08-25.
 * <p> Wltns7979 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class Wltns7979DataParser  extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedObject: {} = super.getMobilePayConvDataFromBasketPage();
    if (document.querySelector('div.price-total-info-bottom span.txt-price')) {
      parsedObject['totalPrice'] = NumberUtil.parseNumber(document.querySelector('div.price-total-info-bottom span.txt-price')!.textContent!.trim().replace(/\D/g, '')).toString();
    }
    return parsedObject;
  }

  /**
   * 제품상세 페이지에서 NPay 전환
   * @returns {{}}
   * @protected
   */
  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
        : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
        ? document.getElementById("MS_amount_basic_0")!['value']
        : 1,
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }
  
  
  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)')[i].textContent!.trim().replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': document.querySelectorAll('div.table-cart tr td:nth-child(4) > div.tb-bold')[i].textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }


  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span, table.cart_box_a tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left:nth-child(1) > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    if(document.querySelector('div.table-cart tfoot div.tb-right strong')){
      totalPrice = NumberUtil.parseNumber(document.querySelector('div.table-cart tfoot div.tb-right strong')!.textContent!.trim().replace(/\D/g, ''));
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
        : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  private onSale(): boolean {
    return StringUtil.isNotEmpty((document.querySelector('form#form1')!['discount_type'] as HTMLInputElement).value);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.thumb-wrap div[data-slick-index="0"] img')) {
      return (document.querySelector('div.thumb-wrap div[data-slick-index="0"] img') as HTMLImageElement).src;
    } else if (document.querySelector('img.detail_image')) {
      return (document.querySelector('img.detail_image') as HTMLImageElement).src;
    } else if (document.querySelector('div#productDetail div.thumb img')) {
      return (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src;
    } else if (document.querySelector('div#productDetail ul.swiper-wrapper>li>img')) {
      return (document.querySelector('div#productDetail ul.swiper-wrapper>li>img') as HTMLImageElement).src;
    } else if (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child')) {
      return (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child')) {
      return (document.querySelector('div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
    } else if (document.querySelector('div.detailThumb>img')) {
      return (document.querySelector('div.detailThumb>img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else if (document.querySelector('main.shopdetail div.swiper-wrapper img')) {
      return (document.querySelector('main.shopdetail div.swiper-wrapper img') as HTMLImageElement).src;
    } else if (document.querySelector('div.product-detail div.thumbnail img')) {
      return (document.querySelector('div.product-detail div.thumbnail img') as HTMLImageElement).src;
    } else if(document.querySelector('div#productDetail ul li.left_img>img')){
      return (document.querySelector('div#productDetail ul li.left_img>img') as HTMLImageElement).src;
    } else if (document.querySelector('main.shopdetail div.swiper-container1 img')) {
      return (document.querySelector('main.shopdetail div.swiper-container1 img') as HTMLImageElement).src;
    } else if (document.querySelector('div.thumb-wrap div.ori img')) {
      return (document.querySelector('div.thumb-wrap div.ori img') as HTMLImageElement).src;
    } else if (document.querySelector('div.visual-rolling ul.swiper-wrapper li.swiper-slide img')) {
      return (document.querySelector('div.visual-rolling ul.swiper-wrapper li.swiper-slide img') as HTMLImageElement).src;
    } else if (document.querySelector('.swiper-container .swiper-wrapper .swiper-slide:first-child img')) {
      return (document.querySelector('.swiper-container .swiper-wrapper .swiper-slide:first-child img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }

}
