/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-01
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-02-01
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class EssocoDataParser extends GodomallRentDataParser {
  
  /**
   * 상품 상세페이지에서 금액정보를 파싱후 리턴
   * @param formElement 상품정보가 담겨있는 formElement
   * @protected
   * @return {price:number, dcPrice: number}
   */
  // TODO 리팩토링 할 필요성이 있음
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
        let price = NumberUtil.parseNumber(formElement!['set_goods_fixedPrice'].value);
        let dcPrice = NumberUtil.parseNumber(formElement!['set_goods_price'].value);

        if(price < dcPrice){
            let tmpPrice = dcPrice;
            dcPrice = price;
            price = tmpPrice;
        }

      return {'price': price, 'dcPrice': dcPrice}
    } catch (e) {
      return {'price': formElement!['set_goods_fixedPrice'].value, 'dcPrice': 0}
    }
  }
}