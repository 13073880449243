/*
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-19
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2022-01-19.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class FlosyDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    const orderList = document.querySelectorAll('.item_wrap .item .item_contents_wrap');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < orderList.length; i++) {
      const productAnchor = (orderList[i].querySelector('.item_contents p a') as HTMLAnchorElement)
      const productCode: string = new QueryStringer(productAnchor.search.substring(1)).getParam('product_no');
      const qty: number = NumberUtil.parseNumber(orderList[i].querySelector('.item_contents p:nth-of-type(2)')!.textContent!.replace(/\D/g, ''));

      parsedProducts.push({
        'productCode': productCode,
        'productName': productAnchor.textContent,
        'qty': qty,
        'price': orderList[i].querySelector('.item_contents p:nth-of-type(4)')!.textContent!.replace(/\D/g,'')
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
      'totalPrice': document.querySelector('.detail_order_total .right .order_total_price strong')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
