/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-03
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2023-01-03
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class DaesungeproDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromBasketPage(): {} {
    const superData = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
     let totalQty = 0;
     const parsedProducts: Array<{}> = [];
     const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('table td.gi div a');
     for (let i = 0; i < inputList.length; i++) {
      const qty = NumberUtil.parseNumber(document.querySelectorAll('table tr td.count input')[i]['value']);
      const rowprice = NumberUtil.parseNumber(document.querySelectorAll('table tr td.ta-c.this-product strong.price')[i].textContent!);
      parsedProducts.push({
        'productCode':  new QueryStringer(inputList[i].getAttribute('href')!.split('?')[1]).getParam('goodsNo'),
        'productName': inputList[i].textContent,
        'qty': qty,
        'price': rowprice/qty
      });
      totalQty += qty;
    }

    superData['product'] = parsedProducts;
    superData['totalQty'] = totalQty;
    superData['productCnt'] = inputList.length;
    }
    return superData;
  }
}
