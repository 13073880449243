import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-21
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-21.
 * <p> botoacai 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class BotoacaiDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents > main.shopdetail > section.shopdetailInfo > div.shopdetailInfoTop > figure > h2')!.textContent!.trim()
            : document.querySelector('#form1 > div.tab_cate > div.tab_cate_area > div.info > div.inner_info > h3.tit-prd > b')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  protected getImageUrl(): string {
    if (document.querySelector('#content > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > a > img')) {
      // PC WEB - 단일 이미지
      return (document.querySelector('#content > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > a > img') as HTMLImageElement).src;
    }  else {
      // MO
      return (document.querySelector('#contents > main.shopdetail > section.shopdetailInfo > div.shopdetailInfoTop > figure > img') as HTMLImageElement).src;
    }
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(3) div.tb-center')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(4) div.tb-center')[i].textContent!.replace(/\D/g, '').trim()
      totalQty += NumberUtil.stringToNumber(qty);
    }

    jsonObj.data['totalQty'] = totalQty;

    return jsonObj;
  }
}
