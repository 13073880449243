/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-10
 */
import {DataValidator} from "../DataValidator";
import {HostingType} from "../../types/GlobalEnums";
import { StringUtil } from '../../lib/common/StringUtil';

/**
 * create on 2020-01-10.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GodomallRentDataValidator extends DataValidator {
  constructor() {
    super(HostingType.GODOMALL_RENT);
  }

  /**
   * 광고주의 소스를 수정하는 에디터 페이지인지 확인
   * @return {boolean}
   * <p><code>true</code> - 에디터 페이지</p><p><code>false</code> - 에디터 페이지 아님</p>
   */
  protected fromEditorPage(): boolean {
    /* 현재 알 수 없으므로 false를 리턴. 추후에 작업 */
    return false;
  }

  /**
   * 이전 페이지(referrer)가 상품상세페이지 인지 확인
   * 외부키워드의 경우 이전페이지가 상품상세페이지인 경우 전송을 안한다.
   * @return {boolean}
   * <p><code>true</code> - 상세페이지 </p><p><code>false</code> - 상세페이지 아님</p>
   */
  protected chkAfterPageIsDetail(): boolean {
    try {
      return StringUtil.getPathNameFromUrl(document.referrer) === '/goods/goods_view.php';
    } catch (e) {
      return false;
    }
  }
}
