/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun1@enliple.com">sjchun</a>
 * @since 2021-11-23
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GlobalVariables } from "../../../types/GlobalVariables";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-11-23.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Cabigma2DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {} = {};
    this.parseCategory(window['iCategoryNo']);
    const priceInfo: {} = this.getProductPrice();
    parsedData = {
      'adverId': this.adverId,
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'price': priceInfo['price'],
      'productUrl': window.location.href,
      'dcPrice': priceInfo['dcPrice'],
      'soldOut': document.querySelector('#span_product_price_text')!.textContent!.trim() === '품절' ? 'Y' : 'N',
      'imageUrl': this.getImageSrc(),
      'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };
    return new JsonObject(parsedData);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    try{
      return {
        price: NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content),
        dcPrice: NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content)
      }
    } catch{
      return super.getProductPrice();
    } 
  }
}
