/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-02
 */

import {TelegramHTMLBuilder} from "./TelegramHTMLBuilder";
import {TelegramMakrdownBuilder} from "./TelegramMarkdownBuilder";
import {TelegramParseMode} from "../../../types/GlobalEnums";

/**
 * create on 2020-11-02
 * <p> Telegram에 전송될 메시지를 관리하는 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class TelegramMessage {
  private _message: string;
  private textBuilder: TelegramHTMLBuilder | TelegramMakrdownBuilder;

  constructor(parseMode: TelegramParseMode) {
    this._message = '';
    this.textBuilder = parseMode === TelegramParseMode.HTML ? new TelegramHTMLBuilder() : new TelegramMakrdownBuilder();
  }

  get message(): string {
    return this._message;
  }

  /**
   * 메시지의 제목을 세팅한다.
   * @param title
   */
  setTitle(title: string) {
    this.addMessage(this.textBuilder.text(title).bold().build());
  }

  /**
   * 메시지의 정보란을 세팅한다.
   * @param infoData 표시될 정보들의 JSON
   */
  setInfoMessage(infoData: {}) {
    const keys: string[] = Object.keys(infoData);

    for (let i = 0; i < keys.length; i++) {
      this.addMessage(this.textBuilder.text(` ${keys[i]} : ${infoData[keys[i]]}`).italic().build());
    }
  }

  setBodyMessage(bodyText: string) {
    this.addMessage(this.textBuilder.text(bodyText).code().build());
  }

  setPageUrl() {
    this.addMessage(this.textBuilder.text(`link page`).hyferLInk(location.href).build());
  }

  /**
   * 메시지에 text를 추가한다.
   * 추가 후 text의 길이가 4000이상이면 4000번째 자리 이후 문자열은 삭제
   * @param text
   * @private string 추가 성공 여부
   */
  addMessage(text: string): boolean {
    this._message += text;

    if (this._message.length > 4000) {
      this._message = this._message.slice(0, 4000);
      return false;
    }

    return true;
  }

  /**
   * 생성된 메시지를 초기화한다.
   */
  resetMessage(): void {
    this._message = '';
  }
}
