/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-06-15
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

export class DnlsjtmzlsDataparser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (this.isMobile(this.commandOptions)) {
      return super.getImageSrc();
    }

    if (document.querySelector('div.flexslider ul.slides li.-thumbfix img')) {
      return (document.querySelector('div.flexslider ul.slides li.-thumbfix img') as HTMLImageElement).src;
    }
    return super.getImageSrc();
  }
}