/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-07
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-04-07
 * <p> hairplus - hairplus.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class HairplusDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageFromMobileDetail(): HTMLImageElement | undefined {
    try {
      return document.querySelector('.goods-view-image-slider .slick-slide div > img') as HTMLImageElement;
    } catch (e) {
      return super.getImageFromMobileDetail();
    }
  }
}
