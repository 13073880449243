/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-23
 */
import {ValidationRuleImpl} from "../ValidationRuleImpl";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2019-12-23.
 * <p> 카테고리 검증 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CategoryNameRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  /**
   * 아래의 데이터 검증 작업 실행
   * +예외. 카프카 에러로 인해 string 'null' 이 넘어올 경우 빈 값 치환
   * 1. HTML 코드 삭제
   * 2. 개행문자 삭제
   * 3. tap, space(2번 이상) space(공백)으로 변경
   * @param property
   * @protected
   */
  protected applyRule(property: any): any {
    try {
      if (property as string === 'null') {
        return '';
      }
      return StringUtil.getNonMultiTab(StringUtil.getNonNewLineStr(StringUtil.getNonHTMLStr(property as string)));
    } catch (e) {
      return property;
    }
  }
}
