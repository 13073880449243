/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-01-16
 */

import { InvalidData } from "../../../lib/ajax/InvalidData";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { FirstmallDataParser } from "../FirstmallDataParser";

/**
 * create on 2023-01-16
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Myoung119DataParser extends FirstmallDataParser {
  protected parseCategory(currentCategoryCode: string): void {
    if(this.isMobile(this.commandOptions)){
      const cateList = (document.querySelector('meta[property=":category"]') as HTMLMetaElement).content.split('>');
      let value: string = '';
      for (let i = 0; i < 3; i++) {
        value = cateList[i].trim();
        this.category[this.categoryHierarchyLevel[i]] = value;
      }
    }
    return super.parseCategory('');
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td');
        if(!productInfo[0].querySelector('a')) continue;
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = this.getSingleProductFrom(productInfo).qty;
        const productPrice = this.getSingleProductFrom(productInfo).price;
        const product = {
          'productCode': productCode,
          'productName': productInfo[0].querySelector('a.order_name')!.textContent!.trim(),
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('.price, .settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}