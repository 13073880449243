/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-06-22
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-06-22.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ZaksimlabDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    // iframe 등 외부 페이지에서 스크립트 기능 호출 시 document의 이미지 src를 참조하기 위해 selector 변수로 분리
    const currDocument = window.parent.document === document
        ? document
        : window.parent.document;

    if (currDocument.querySelector('#prdDetailImg > img.bigImage[src]')) {
      return (currDocument.querySelector('#prdDetailImg > img.bigImage[src]') as HTMLImageElement).src;
    } else if (currDocument.querySelector('#layout > div.bsSideMenu > div.link > div.xans-element-.xans-layout.xans-layout-productrecent > ul > li:nth-child(1) > a > img')) {
      return (currDocument.querySelector('#layout > div.bsSideMenu > div.link > div.xans-element-.xans-layout.xans-layout-productrecent > ul > li:nth-child(1) > a > img') as HTMLImageElement).src;
    }

    return super.getImageSrc();
  }
}
