/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-04-02.
 * <p> Jfgmall 광고주 별도 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class JfgmallDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductQty(): string {
    if (document.querySelectorAll('div#totalProducts>table>tbody>tr').length > 0) {
      return this.isMobile(this.commandOptions)
          ? document.querySelectorAll('div#totalProducts>table>tbody>tr')[0].querySelector('input#quantity')!['value']
          : document.querySelectorAll('div#totalProducts>table>tbody>tr')[0].querySelector('td:nth-child(2)>span.quantity>input, td:nth-child(2) input#quantity')!['value'];
    } else {
      /* 웹의 경우를 발견하면 여기에 추가할 것*/
      return (document.querySelectorAll('tr.quantity>td input#quantity')[0] as HTMLInputElement).value;
    }
  }
}
