/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-23
 */
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-05-23.
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class BanulDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (
        this.getSelectorAll([
          'form[name="forms"]>figure>div.basket_cont>figcaption>p.basket_price'
        ])[i] as HTMLParagraphElement).textContent!.replace(/\D/g, '')
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('form[name="forms"] div.chk_basket a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  protected getProductName(): string {
    try {
      const el = document.querySelector('#contentWrapper section div.detail_info li.subject_area') as HTMLLIElement;
      if(el){
        return el.textContent!.trim();
      }
      return super.getProductName();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getImageUrl(): string {
    if (!this.isMobile(this.commandOptions)) {
      if (document.querySelector('#contentWrapper section div.detail_thumb img#lens_img')) {
        return (document.querySelector('#contentWrapper section div.detail_thumb img#lens_img') as HTMLImageElement).src!;
      }
    }
    return super.getImageUrl();
  }
}
