/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-18
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-04-18.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class UncoatedDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let superData = super.getPayConvDataFromBasketPage();
    superData['totalPrice'] =  NumberUtil.parseNumber(document.querySelector('ul > li:nth-child(3) > span > b')!.textContent!.replace(/\D/g,''));
    return superData;
  }
 
  protected getCommonTraceData(): JsonObject {
    if(super.isBasketPage()||super.isProductPage()){
    this.customNpayCall();
  }
      return super.getShopCollectData();

}

  //해당광고주만 npay custom으로 인해 임시 조치
  private customNpayCall(): void {
    EventUtil.addEvent(document.querySelector('.btn-npay')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'B', 'paySys': 'naverPay' }
      ]);
    });
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    try{
      superData['price'] = NumberUtil.parseNumber(document.querySelector('span.product-custom.origin_price')!.textContent!.replace(/\D/g,''));
      superData['dcPrice'] = NumberUtil.parseNumber(document.querySelector('span#span_product_price_text.sale_price')!.textContent!.replace(/\D/g,''));
      return superData;
    }catch{
      return superData;
    }
  }
}
