/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-05
 */
import {Product} from '../../tracker/data/Product';
import {StringUtil} from '../common/StringUtil';
import {InvalidData} from "../ajax/InvalidData";

/**
 * create on 2019-11-05.
 * <p> JSON Object를 다루는 모듈 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class JsonObject {
  private _data!: {};
  private _length!: number;

  constructor(dataObject?: {}) {
    this.initialize(dataObject);
  }

  get data(): {} {
    return this._data as {};
  }

  set data(dataObject: {}) {
    this._data = typeof dataObject === 'undefined' ? {} : JsonObject.deepClone(dataObject);
    this._length = this.getDataLength();
  }

  /**
   * 입력된 객체로 내용을 초기화 후 인스턴스를 리턴
   * @param {{} | Product} dataObject - 객체 (이 객체로 내용을 초기화)
   * @return {JsonObject} - 인스턴스
   */
  initWith(dataObject?: {} | Product): JsonObject {
    this.initialize(dataObject);
    return this;
  }

  /**
   * 데이터를 JSON 객체 형태로 반환
   * @return {{}}
   */
  getDataAsJsonObject(): {} {
    return this._data;
  }

  /**
   * 데이터를 문자열화 된 형태로 반환
   * @return {string}
   */
  getDataAsJsonString(): string {
    return JSON.stringify(this._data);
  }

  /**
   * 데이터 추가.
   * <b>NOTE : </b>비어 있는 데이터 또는 오류 발생시 아무런 일을 하지 않음.
   * @param {string} name - 이름
   * @param value - 값
   * @param {boolean} [replace = true] - 이름 중복시 입력된 값으로 대체하는지
   */
  addData(name: string, value: any, replace = true): void {
    /* null 여부 */
    const isNull: boolean = value === null;
    /* undefined 여부 */
    const isUndefined: boolean = typeof value === 'undefined';
    /* 추가 가능한 데이터인지 */
    const isAddible: boolean = this.hasDataWithName(name) && !replace;

    try {
      if (isNull || isUndefined || isAddible) {
        return;
      }

      this._data[name] = value;
      this._length = this.getDataLength();
    } catch (e) {
      return;
    }
  }

  /**
   * 입력한 이름에 해당하는 데이터 제거.
   * <b>NOTE : </b>오류 발생시 아무런 일을 하지 않음.
   * @param {string} name - 제거할 데이터의 이름
   */
  removeData(name: string): void {
    try {
      if (this.hasDataWithName(name)) {
        const nameArr = Object.keys(this._data);
        const newJsonData = {};

        for (let i = 0; i < this._length; i++) {
          const existingName = nameArr[i];
          if (existingName === name) {
            continue;
          }

          newJsonData[existingName] = this._data[existingName];
        }

        this._data = newJsonData;
        this._length = this.getDataLength();
      }
    } catch (e) {
      return;
    }
  }

  /**
   * 입력한 이름에 해당하는 데이터를 새로운 값으로 변경.
   * @param {string} name - 변경될 데이터의 이름
   * @param value - 새로운 값
   */
  replaceData(name: string, value: any): void {
    /* null 여부 */
    const isNull: boolean = value === null;
    /* undefined 여부 */
    const isUndefined: boolean = typeof value === 'undefined';
    /* 변경 가능한 데이터인지 */
    const isReplaceable: boolean = this.hasDataWithName(name);

    if (isNull || isUndefined || isReplaceable) {
      this._data[name] = value;
    }
  }

  /**
   * 입력한 이름에 해당하는 데이터를 반환. (찾지 못하면 빈 문자열)
   * @param {string} name - 찾을 데이터의 이름
   * @return {any}  - 찾은 데이터 (찾지 못하면 빈 문자열을 반환)
   */
  getDataByName(name: string): any {
    return this.hasDataWithName(name) ? this._data[name] : StringUtil.EMPTY;
  }

  /**
   * 입력한 객체의 모든 프로퍼티를 현재 <code>JsonObject</code> 내의 데이터로 덧붙인다.
   * @param {JsonObject | {}} from  - 덧붙일 원본
   * @param {boolean} replace - 데이터 중복시 대체 여부
   */
  appendData(from: JsonObject | {}, replace = true): void {
    const keys: string[] = from instanceof JsonObject ? from.getKeyArray() : Object.keys(from);

    for (let i = 0; i < keys.length; i++) {
      const name: string = keys[i];
      const value: any = from instanceof JsonObject ? from.getDataByName(name) : from[name];
      this.addData(name, value, replace);
    }
  }

  /**
   * 데이터가 비어 있지 않은지 확인
   * @return {boolean}
   * <p><code>true</code> - 비어 있지 않음.</p><p><code>false</code> - 비어 있음.</p>
   */
  isNotEmpty(): boolean {
    return typeof this._data === 'object' && Object.keys(this._data).length > 0;
  }

  /**
   * 데이터가 비어 있는지 확인
   * @return {boolean}
   * <p><code>true</code> - 비어 있음.</p><p><code>false</code> - 비어 있지 않음.</p>
   */
  isEmpty(): boolean {
    return !this.isNotEmpty();
  }

  /**
   * 입력한 이름에 해당하는 데이터가 있는지 확인
   * @param {string} name - 이름
   * @return {boolean}
   * <p><code>true</code> - 데이터 존재.</p><p><code>false</code> - 존재하지 않음.</p>
   */
  hasDataWithName(name: string): boolean {
    return this.isNotEmpty() && !!this._data[name];
  }

  /**
   * 현재 <code>JsonObject</code> 내에 존재하는 데이터의 수
   * @return {number} - 데이터 수
   */
  getDataLength(): number {
    return this.isNotEmpty() ? Object.keys(this._data).length : 0;
  }

  /**
   * <code>JsonObject</code> 내 데이터의 모든 이름을 배열로 반환.
   * @return {Array<string>}  - 데이터의 이름들이 들어있는 배열.
   */
  getKeyArray(): string[] {
    return Object.keys(this._data);
  }

  /**
   * 입력한 데이터로 초기화.
   * <b>NOTE : </b>원본 데이터가 비어 있거나 존재하지 않으면 빈 객체로 초기화.
   * @param {{}} dataObject - 원본 데이터
   */
  private initialize(dataObject?: {}): void {
    this._data = typeof dataObject === 'undefined' ? {} : JsonObject.deepClone(dataObject);
    this._length = this.getDataLength();
  }

  /**
   * 입력한 <code>Object</code>를 deep clone으로 복사.
   * @param {{}} copyFrom - 복사할 원본 객체
   * @return {{}} - 복사된 결과 (에러 발생시 빈 객체)
   */
  private static deepClone(copyFrom: {}): {} {
    try {
      const copyTo = {};
      const nameArr = Object.keys(copyFrom);

      for (let i = 0; i < nameArr.length; i++) {
        const objKey = nameArr[i];
        copyTo[objKey] = copyFrom[objKey];
      }

      /* InvalidData 인스턴스일 때는 InvalidData 타입인 데이터 그대로 리턴  */
      return copyFrom as InvalidData instanceof InvalidData
          ? copyFrom
          : copyTo;
    } catch (e) {
      return {};
    }
  }
}
