/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-08-18
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {Value} from "../../../lib/value/Value";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-08-18.
 * <p> Beisiks1 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class Beisiks1DataParser  extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': document.querySelector('div.pay_info p.complete-price em')!.textContent!.trim()!.replace(/\D/g, ''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (this.getSelectorAll([
        'div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'table.cart_box_a tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'div.table-cart>table tr td:nth-child(3), div.table-order-prd table tr td:nth-child(3)'
      ])[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right')[i] as HTMLDivElement).textContent!.trim()!.replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span, table.cart_box_a tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('div.table-cart tbody tr td div.tb-left p.subject')![i].textContent!.trim()!,
        'price': price / qty,
        'qty': qty
      });
    }

    if(document.querySelector('strong span.MK_chg_total_price')){
      totalPrice = NumberUtil.parseNumber(document.querySelector('strong span.MK_chg_total_price')!.textContent!.trim()!.replace(/\D/g, ''));
    }
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }


}
