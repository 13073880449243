/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import { JsonObject } from '../../../lib/json/JsonObject';
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';



/**
 * create on 2020-03-09.
 * <p> Anais 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class AnaisDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 상품 상세 페이지에서 상품데이터 파싱
   * @return {JsonObject}
   */
  protected getShopCollectData(): JsonObject {
    if (this.isMobile(this.commandOptions)) {
      const parsedJsonObject: JsonObject = super.getShopCollectData();
      parsedJsonObject.addData('dcPrice', document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''));
      return parsedJsonObject;
    } else {
      return super.getShopCollectData();
    }
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-left > a')[i]!.textContent!.trim();
    }

    jsonObj['totalPrice'] = document.querySelector('div.table-cart tfoot strong')!.textContent!.replace(/\D/g, '')

    return jsonObj;
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const jsonObj = super.getMobileConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div.product_info tr td div a')[i].childNodes[0].textContent!.trim();
    }

    return jsonObj;
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i] as HTMLParagraphElement).textContent!.replace(/\D/g, '').trim();
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('div.price-total-info-bottom span.txt-price')!.textContent!.replace(/\D/g, '').trim(),
      'totalQty': totalQty.toString()
    };
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
        ? document.querySelector('main.shopdetail div.shop_de_name span.name')!.textContent!.trim()
        : document.querySelector('div.info > div.dname > h3')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }
}
