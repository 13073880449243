/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-10-19
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2021-10-19
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Owoo77DataParser extends Cafe24SmartDataParser {

  protected getConversionData_prev(): JsonObject {
    const productList = document.querySelectorAll('.orderArea [nspace="normal"] .ec-base-prdInfo .prdBox');

    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const productCode: string = new QueryStringer((productList[i].querySelector('.thumbnail a') as HTMLAnchorElement).search.substring(1)).getParam('product_no');
      const qty: number = NumberUtil.parseNumber(productList[i].querySelector('ul.info li:nth-of-type(4)')!.textContent!.split(':')[1].replace(/\D/g, ''));
      const price: number = NumberUtil.parseNumber(productList[i].querySelector('ul.info li:nth-of-type(5) span')!.textContent!.split(':')[1].replace(/\D/g, ''));
      parsedProducts.push({
        'productCode': productCode,
        'productName': productList[i].querySelector('.prdName .ec-product-name')!.textContent,
        'qty': qty,
        'price': price
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
      'totalPrice': document.querySelector('div.totalPay > strong.txtStrong')!.childNodes[0].textContent!.replace(/\D/g, ''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
