/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-23
 */
import {NotSupportedError} from "../error/NotSupportedError";
import {StorableCategoryTree} from "./StorableCategoryTree";
import {GeneralTree} from "../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "./Category";
import {StorableCategory} from "./StorableCategory";
import {ParsableCategory} from "./ParsableCategory";
import {NumberUtil} from "../lib/common/NumberUtil";
import {StringUtil} from "../lib/common/StringUtil";

/**
 * create on 2019-12-23.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CategoryStorage {
  /* 브라우저의 로컬 스토리지 지원 여부 */
  private static localStorageSupported: boolean = CategoryStorage.whetherLocalSorageSupported();

  /* 스토리지 key의 prefix */
  private static readonly keyPrefix: { mappedCategory: string; nodePaths: string; expiry: string; } = {
    'mappedCategory': 'ENP_CATE',
    'nodePaths': 'ENP_CATE_PATHS',
    'expiry': 'ENP_CATE_EXP'
  };

  /* 스토리지 */
  private readonly storage: Storage;

  constructor() {
    if (CategoryStorage.localStorageSupported) {
      this.storage = window.localStorage;
    } else {
      throw new NotSupportedError('localStorage');
    }
  }

  static whetherLocalSorageSupported(): boolean {
    try {
      return !!window.localStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * 스토리지에 저장 가능한 트리를 변환하여 저장.
   * <code>ENP_CATE_EXP</code>에 들어있는 유효기간이 만료되었으면 새로 저장.
   * @param {GeneralTree<Category>} categoryTree  - 저장할 수 있는 형태의 카테고리 트리
   */
  store(categoryTree: GeneralTree<Category>): void {
    const storableCategory: StorableCategory = new StorableCategory(categoryTree);
    const storableCategoryTree: StorableCategoryTree = storableCategory.getStorable();
    this.storage.setItem(CategoryStorage.keyPrefix.expiry, this.getYYYYMMDDString());
    this.storage.setItem(CategoryStorage.keyPrefix.mappedCategory, JSON.stringify(storableCategoryTree.mappedCategory));
    this.storage.setItem(CategoryStorage.keyPrefix.nodePaths, JSON.stringify(storableCategoryTree.nodePaths));
  }

  /**
   * 스토리지에 저장된 트리를 가져와서 파싱 가능한 트리로 변환.
   * 스토리지에 저장된 정보가 없으면 <code>undefined</code> 반환.
   * @return {GeneralTree<Category> | undefined}  파싱가능한 트리.
   */
  receive(): GeneralTree<Category> | undefined {
    try {
      const storableCategoryTree: StorableCategoryTree = this.convertToStorableFromStorage();
      const parsableCategory: ParsableCategory = new ParsableCategory(storableCategoryTree);
      return parsableCategory.getParsable();
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 트리를 저장하기 위한 고유 키의 만료 여부.
   * <b>만료 조건</b>
   * <ol>
   *   <li>날짜가 지남</li>
   *   <li>
   *     <code>localStorage</code>에 잘못된 만료 정보가 들어 있음.
   *     <ul>
   *       <li>만료 정보가 없음.</li>
   *       <li>YYYYMMDD 형태가 아님.</li>
   *       <li>만료 정보를 가져오는 과정에서 에러 발생.</li>
   *     </ul>
   *   </li>
   *
   * </ol>
   * @return {boolean}
   * <p><code>true</code> - 만료됨</p><p><code>false</code> - 만료되지 않음</p>
   */
  hasExpired(): boolean {
    try {
      const expiry: string | null = this.storage.getItem(CategoryStorage.keyPrefix.expiry);
      const today: string = this.getYYYYMMDDString();
      const isInvalidExpiry: boolean = !(expiry && expiry.length === 8 && StringUtil.isPositiveIntegerFormat(expiry));

      return isInvalidExpiry || NumberUtil.stringToNumber(expiry!) < NumberUtil.stringToNumber(today);
    } catch (e) {
      return true;
    }
  }

  /**
   * <code>localStorage</code>에서 데이터를 가져와서 저장 가능한 트리로 변환.
   * @return {StorableCategoryTree} 저장 가능한 트리 형태.
   * @throws Error  변환이 실패할 때 에러 발생.
   */
  private convertToStorableFromStorage(): StorableCategoryTree {
    const rawData = {
      mappedCategory: this.storage.getItem(CategoryStorage.keyPrefix.mappedCategory),
      nodePaths: this.storage.getItem(CategoryStorage.keyPrefix.nodePaths)
    };

    if (rawData.mappedCategory && rawData.nodePaths) {
      return {
        mappedCategory: JSON.parse(rawData.mappedCategory),
        nodePaths: JSON.parse(rawData.nodePaths)
      };
    } else {
      throw new Error('Invalid storage data');
    }
  }

  /**
   * 현재 날짜에 대한 <code>YYYYMMDD</code> 문자열을 반환.
   * @return {string}
   */
  private getYYYYMMDDString(): string {
    const currentDate: Date = new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);

    return year + month + date;
  }
}
