/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-02-04
 */
import {ApiName} from "../../types/GlobalEnums";
import {Api} from "./Api";
import {InvalidValueError} from "../../error/InvalidValueError";
import {IOrbApi} from "./IOrbApi";

/**
 * create on 2020-02-04.
 * <p> Api 객체 생성을 위임하는 클래스 </p>
 * <p> {@link Api} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ApiFactory {
  static createApi(apiName: ApiName, adverId: string): Api {
    switch (apiName) {
      case ApiName.I_ORB:
        return new IOrbApi(apiName, adverId);
      default:
        throw new InvalidValueError('apiName', apiName);
    }
  }
}
