/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-23
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-23
 * <p> greyraise - https://www.greyraise.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class GreyraiseDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();

    let totalQty = 0;
    const productList: Array<{}> = [];
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#order div.base-table table tr td:nth-child(2) > div > a');
    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('div#order div.base-table table tr td:nth-child(3)')[i].textContent!.trim());

      productList.push({
        'productCode': new QueryStringer(productNameList[i].search.substring(1)).getParam('branduid'),
        'productName': productNameList[i]['productName'] = productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('div#order div.base-table table tr td:nth-child(4)')[i].textContent!.trim())
      });

      totalQty += qty;
    }

    jsonObj.replaceData('product', productList);
    jsonObj.replaceData('totalQty', totalQty);
    return jsonObj;
  }
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = document.querySelector('div#content strong.mob_price')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }
}