/*
* COPYRIGHT (c) Enliple 2019
* This software is the proprietary of Enliple
*
* @author <a href="mailto:mgpark@enliple.com">mgpark</a>
* @since 2020-05-19
*/
import { KeywordManager } from './KeywordManager';
import { JsonObject } from '../../lib/json/JsonObject';
import { EventType , KeywordType } from '../../types/GlobalEnums';
import URLParse from 'url-parse';

/**
 * create on 2020-05-19.
 * <p> 장바구니, 찜, 전환 키워드의 데이터 세팅 및 클릭키워드의 데이터 세팅을 담당하는 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class KeywordFetcher {
  private keywordManager: KeywordManager;

  constructor() {
    this.keywordManager = new KeywordManager();
  }

  getCommonKeyword(eventType: EventType): JsonObject | undefined {
    try {
      const keywordData: JsonObject = new JsonObject();
      const externalKeyword = new JsonObject(this.keywordManager.takeOutExternalKeyword(eventType));
      const commonInternalKeyword = new JsonObject(this.keywordManager.takeOutCommonInternalKeyword());

      if(externalKeyword.data['keywordValue'] && externalKeyword.data['keywordValue'] !== 'null') {
        keywordData.addData('externalKeyword', externalKeyword.data['keywordValue']);
        keywordData.addData('externalKeywordHost', new URLParse(externalKeyword.data['keywordUrl'])['host']);
      }
      if(commonInternalKeyword.data['keywordValue'] && commonInternalKeyword.data['keywordValue'] !== 'null') {
        keywordData.addData('internalKeyword', commonInternalKeyword.data['keywordValue']);
      }

      return keywordData;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 상품 상세페이지 에서 collect 이벤트 호출시 키워드 데이터 형식에 맞게 설정 후 반환
   * @param  eventType   이벤트타입(Cart)
   * @param  productCode 상품코드
   * @return jsonObject
   */
  getCollectKeyword(eventType: EventType, productCode: string): JsonObject | undefined {
    try {
      const keywordData: JsonObject = new JsonObject();

      const externalKeyword = new JsonObject(this.keywordManager.takeOutExternalKeyword(eventType));
      const internalKeyword = new JsonObject(this.keywordManager.takeOutInternalKeyword(eventType, productCode));


      if(externalKeyword.data['keywordValue'] && externalKeyword.data['keywordValue'] !== 'null') {
        keywordData.addData('externalKeyword', externalKeyword.data['keywordValue']);
        keywordData.addData('externalKeywordHost', new URLParse(externalKeyword.data['keywordUrl'])['host']);
      }
      if(internalKeyword.data['keywordValue'] && internalKeyword.data['keywordValue'] !== 'null') {
        keywordData.addData('internalKeyword', internalKeyword.data['keywordValue']);
      }

      return keywordData;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 장바구니 버튼 클릭시 키워드 데이터 형식에 맞게 설정 후 반환
   * @param  eventType   이벤트타입(Cart)
   * @param  productCode 상품코드
   * @return jsonObject
   */
  getCartKeyword(eventType: EventType, productCode: string): JsonObject | undefined {
    try {
      const keywordData: JsonObject = new JsonObject();

      const externalKeyword = new JsonObject(this.keywordManager.takeOutExternalKeyword(eventType));
      if(externalKeyword['_data']['keywordValue'] && externalKeyword['_data']['keywordValue'] !== 'null') {
        keywordData.addData('externalKeyword', KeywordType.EXTERNAL);
        keywordData.addData('externalKeywordValue', externalKeyword.data['keywordValue']);
        keywordData.addData('externalKeywordHost', new URLParse(externalKeyword.data['keywordUrl'])['host']);
      }

      const internalKeyword = new JsonObject(this.keywordManager.takeOutInternalKeyword(eventType, productCode));
      if(internalKeyword['_data']['keywordValue'] && internalKeyword['_data']['keywordValue'] !== 'null') {
        keywordData.addData('internalKeyword', KeywordType.INTERNAL);
        keywordData.addData('internalKeywordValue', internalKeyword.data['keywordValue']);
      }

      return keywordData;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 찜 버튼 클릭시 키워드 데이터 형식에 맞게 설정 후 반환
   * @param  eventType   이벤트타입(Cart)
   * @param  productCode 상품코드
   * @return jsonObject
   */
  getWishKeyword(eventType: EventType, productCode: string): JsonObject | undefined {
    try {
      const keywordData: JsonObject = new JsonObject();

      const externalKeyword = new JsonObject(this.keywordManager.takeOutExternalKeyword(eventType));
      if(externalKeyword['_data']['keywordValue'] && externalKeyword['_data']['keywordValue'] !== 'null') {
        keywordData.addData('externalKeyword', KeywordType.EXTERNAL);
        keywordData.addData('externalKeywordValue', externalKeyword.data['keywordValue']);
        keywordData.addData('externalKeywordHost', new URLParse(externalKeyword.data['keywordUrl'])['host']);
      }

      const internalKeyword = new JsonObject(this.keywordManager.takeOutInternalKeyword(eventType, productCode));
      if(internalKeyword['_data']['keywordValue'] && internalKeyword['_data']['keywordValue'] !== 'null') {
        keywordData.addData('internalKeyword', KeywordType.INTERNAL);
        keywordData.addData('internalKeywordValue', internalKeyword.data['keywordValue']);
      }

      return keywordData;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 컨버전시에 키워드 데이터 형식에 맞게 설정 후 반환
   * 외부키워드의 경우 어떤 경우에서든 한개만 존재
   * @param  eventType   이벤트 타입
   * @return             [description]
   */
  getConversionExternalKeyword(eventType: EventType): JsonObject | undefined {
    try {
      return new JsonObject(new KeywordManager().takeOutExternalKeyword(eventType));
    } catch (e) {
        return undefined;
    }
  }

  /**
   * 컨버전시에 키워드 데이터 형식에 맞게 설정 후 반환
   * 컨버전은 구매한 여러개의 상품들에 해당되는 내부키워드 데이터를 세팅 해야함
   * @param  eventType   이벤트 타입
   * @param  productArr  상품 배열
   * @return             [description]
   */
  getConversionInternalKeyword(eventType: EventType, productArr?: Array<{}>): Array<{}> | undefined {
    try {
      const parsedProducts: Array<{}> = [];

      if(productArr){
        for(let i = 0; i < productArr!.length; i++) {
          const product: JsonObject = new JsonObject(productArr![i]);
          const internalKeyword = new JsonObject(new KeywordManager().takeOutInternalKeyword(eventType, productArr![i]['productCode']));

          if(internalKeyword.data['keywordSessionType']) {
            product.appendData(internalKeyword.data, true);
          }

          parsedProducts.push(product.data);
        }

        return parsedProducts;
      } else {
        return undefined;
      }
    } catch (e) {
        return productArr;
    }
  }
}
