/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-18
 */
import {KeywordSessionType, KeywordType} from "../../types/GlobalEnums";
import {NumberUtil} from "../../lib/common/NumberUtil";
import {Value} from "../../lib/value/Value";
import {StorableKeyword} from "./dataType/StorableKeyword";
import {NotSupportedError} from "../../error/NotSupportedError";
import {Utf8} from "../../lib/codec/Utf8";
import {JsonObject} from "../../lib/json/JsonObject";
import {StringUtil} from "../../lib/common/StringUtil";
import {KeywordPair} from "./dataType/KeywordPair";
import {InvalidData} from "../../lib/ajax/InvalidData";

/**
 * create on 2020-05-18.
 * <p> 전환 키워드 저장 및 가져오는 기능을 담은 클래스 </p>
 * <p> {@link KeywordManager} and {@link StorableKeyword} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class KeywordStorage {
  /* 스토리지 */
  protected readonly storage: {
    'local': Storage;
    'session': Storage;
  } = {
    'local': window.localStorage,
    'session': window.sessionStorage
  };

  /* storage 저장을 위한 키 */
  protected readonly key: string = 'ENP_KEYWORD';

  /* 만료기간 (일) */
  private readonly expiry: number = 2;

  /**/
  private storableKeyword?: StorableKeyword;

  /**
   * 생성자.
   * 인스턴스화할 때 만료된 키를 제거한다.
   * @throws NotSupportedError  - 브라우저가 {@link Storage} API를 지원하지 않을 때
   */
  constructor() {
    if (KeywordStorage.whetherStorageSupported()) {
      this.removeExpiredItem();
    } else {
      throw new NotSupportedError('Storage API');
    }
  }

  /**
   * 브라우저의 Storage 지원 여부
   * @return {boolean}
   * <p><code>true</code> - 지원함</p><p><code>false</code> - 지원하지 않음</p>
   */
  static whetherStorageSupported(): boolean {
    try {
      return !!window.localStorage && !!window.sessionStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * {@link KeywordSessionType}에 따라 각 Storage에 키워드를 저장.
   * <ul>
   *   <li>{@link KeywordSessionType.SESSION} - {@link sessionStorage}에 저장.</li>
   *   <li>{@link KeywordSessionType.INDIRECT} - {@link localStorage}에 저장.</li>
   * </ul>
   * <b>NOTE:</b> 키워드가 없거나 오류 발생시 아무런 동작을 하지 않음.
   * @param {KeywordSessionType} keywordSessionType
   * @param {string} keywordPair  - 추출한 키워드
   * @param {KeywordType} keywordType - 키워드 타입(내부/외부)
   * @param {string} productCode - 상품코드
   */
  setItem(keywordSessionType: KeywordSessionType, keywordPair: KeywordPair[] | InvalidData, keywordType: KeywordType, productCode?: string): void {
    const vacancy: boolean = Object.keys(keywordPair).length === 0;
    const emptyKeywordValue: boolean = Value.getValue((keywordPair as KeywordPair).keyword, '').toString().length === 0;
    const emptyKeywordUrl: boolean = Value.getValue((keywordPair as KeywordPair).url, '').toString().length === 0;
    const invalidData: boolean = keywordPair === new InvalidData();

    if (vacancy || invalidData || emptyKeywordValue || emptyKeywordUrl) {
      return;
    }

    try {

      this.storableKeyword = {
        'keywordValue': (keywordPair as KeywordPair).keyword,
        'keywordType': keywordType,
        'keywordSessionType': keywordSessionType,
        'keywordUrl': (keywordPair as KeywordPair).url,
        'productCode': productCode ? productCode : '',
        'expired': this.getExpiredTimestring(keywordSessionType, this.expiry)
      };

      // 외부유입 키워드의 경우 어처피 한개의 키워드만 저장이 되기 때문에 단일 객체로 저장하고
      // 내부유입 키워드의 경우 상품-키워드 데이터가 여러개 저장이 되기 때문에 배열의 형태로 저장한다.
      const storedData: JsonObject | string = this.getItem(keywordSessionType);
      let storedExternalKeyword = storedData[KeywordType.EXTERNAL];
      let storedInternalKeyword = storedData[KeywordType.INTERNAL];

      // 키워드 타입별 데이터 가공
      switch (keywordType) {
        case KeywordType.EXTERNAL:
          storedExternalKeyword = this.storableKeyword;
        break;
        case KeywordType.INTERNAL:
          storedInternalKeyword = this.setInternalKeyword(this.storableKeyword, keywordSessionType);
        break;
      }

      return this.storingKeyword(keywordSessionType, storedExternalKeyword, storedInternalKeyword);
    } catch (e) {
      return;
    }
  }

  /**
   * {@link KeywordSessionType}에 따라 각 Storage에서 키워드를 가져온다.
   * UTF-8로 인코딩된 로우 데이터를 디코딩 후 사용할 수 있는 객체 형태로 변환한다.
   * @param {KeywordSessionType} keywordSessionType   - 전환 키워드 타입
   * @return {StorableKeyword | string}
   * <p>{@link StorableKeyword} - 유효한 키워드의 경우</p>
   * <p><code>string</code> - 유효하지 않는 키워드의 경우 (빈 문자열로 리턴)</p>
   */
  getItem(keywordSessionType: KeywordSessionType): JsonObject | string {
    const rawItem: string = Value.getValue(this.getStorage(keywordSessionType).getItem(this.key), '').toString();
    if (rawItem.length > 0) {
      return JSON.parse(this.decodeItem(rawItem));
    } else {
      return '';
    }
  }

  /**
   * 내부키워드 데이터 생성 후 반환
   * @param storedKeyword
   * @param keywordSessionType
   */
  setInternalKeyword(storedKeyword: {}, keywordSessionType: KeywordSessionType): {} {
    const storedData: JsonObject | string = this.getItem(keywordSessionType);

    try {
      // 상품별 내부검색 키워드(클릭키워드) 저장하는 클로저 함수
      //  * 내부키워드 검색 후 상품조회시 상품코드와 키워드 1:1매칭 후 내부키워드 데이터 생성 후 반환
      const storingInternalKeyword = (internalKeyword: Array<{}>, storedKeyword: {}): {} => {
        let isDuplData = false;

        // 이미 저장된 내부키워드중 동일한 상품 코드가 존재하면
        // 연관된 내부키워드 데이터를 검색한 데이터로 변경한다.
        for(let i = 0; i < internalKeyword.length; i++) {
          if(internalKeyword[i]['productCode'] === storedKeyword['productCode']) {
            internalKeyword[i] = storedKeyword;
            isDuplData = true;
            break;
          }
        }
        // 저장된 데이터중 동일한 상품코드가 없으면 새로 추가한다.
        if(!isDuplData) {
          internalKeyword.push(storedKeyword);
        }
        return internalKeyword;
      };

      // 공통키워드 저장 후 반환하는 클로저 함수
      const storingCommonKeyword = (internalKeyword: Array<{}>, storedKeyword: {}, keywordSessionType: KeywordSessionType): {} => {
        // 공통키워드가 기존에 저장되어 있는지 구분
        const isStoredCommonKeyword = internalKeyword[0] && internalKeyword[0]['keywordType'] === KeywordType.COMMON;
        // 저장될 키워드에 구분값 공통키워드 저장
        storedKeyword['keywordType'] = KeywordType.COMMON;
        
        // 세션스토리지에만 공통 키워드 저장
        if(keywordSessionType === KeywordSessionType.SESSION) {
          // 내부키워드 배열에 첫번째에 공통키워드 저장
          // 저장되는 데이터 형식
          // internal: [{공통 키워드(ENP_COMMON_KEYWORD), {내부키워드}, {내부키워드} ...}]
          return new Array().concat(storedKeyword, isStoredCommonKeyword ? internalKeyword.slice(1) : internalKeyword);
        } else {
          return internalKeyword;
        }
      };

      let internalKeyword = !storedData[KeywordType.INTERNAL] || Object.keys(storedData).length < 1 ? [] : storedData[KeywordType.INTERNAL];

      // 상품코드가 존재할 시에 내부 키워드(상품:키워드-1:1 매칭), 존재하지 않을시 공통키워드로 기존 데이터에 추가 후 반환
      if(storedKeyword['productCode'] && StringUtil.isNotEmpty(storedKeyword['productCode'])) {
        return storingInternalKeyword(internalKeyword, storedKeyword);
      } else {
        return storingCommonKeyword(internalKeyword, storedKeyword, keywordSessionType);
      }
    } catch (e) {
      return storedData[KeywordType.INTERNAL];
    }

  }


  /**
   * 만료된 키워드를 찾아 삭제한다.
   * 잘못된 형식의 키워드가 저장되어 오류 발생시 그 키워드를 삭제한다.
   */
  removeExpiredItem(): void {
    try {
      const storedData: JsonObject | string = this.getItem(KeywordSessionType.INDIRECT);
      let externalKeyword = storedData[KeywordType.EXTERNAL];
      if (externalKeyword && Object.keys(externalKeyword).length > 0 && this.hasExpired((externalKeyword as StorableKeyword).expired)) {
        externalKeyword = {};
      }

      const internalKeyword = storedData[KeywordType.INTERNAL];
      if(internalKeyword && Object.keys(internalKeyword).length > 0) {
        for(let i = 0; i < internalKeyword.length; i++) {
          if (internalKeyword[i] && Object.keys(internalKeyword[i]).length > 0 && this.hasExpired((internalKeyword[i] as StorableKeyword).expired)) {
            internalKeyword.splice(internalKeyword.indexOf(internalKeyword[i]), 1);
          }
        }
      }

      this.storingKeyword(KeywordSessionType.INDIRECT, externalKeyword, internalKeyword);
    } catch (e) {
      this.storage.local.removeItem(this.key);
    }
  }

  private storingKeyword(keywordSessionType: KeywordSessionType, externalKeyword: {}, internalKeyword: {}): void {
    const encodeKeywordData = this.encodeItem(JSON.stringify({ 'external': externalKeyword, 'internal': internalKeyword }));
    this.getStorage(keywordSessionType).setItem(this.key, encodeKeywordData);
  }

  /**
   * {@link KeywordSessionType}에 따른 {@link Storage}를 반환.
   * @param {KeywordSessionType} keywordSessionType - 전환 키워드 타입
   * @return {Storage}  - Storage API
   * @throws NotSupportedError  - 미리 정의되지 않은 타입을 입력한 경우
   */
  private getStorage(keywordSessionType: KeywordSessionType): Storage {
    switch (keywordSessionType) {
      case KeywordSessionType.SESSION:
        return this.storage.session;
      case KeywordSessionType.INDIRECT:
        return this.storage.local;
      default:
        throw new NotSupportedError(keywordSessionType);
    }
  }

  /**
   * 입력한 time string을 현재 날짜와 비교하여 만료되었는지 확인.
   * 오류 발생시 <code>true</code> 반환
   * @param {string} expiredTimestring  - 확인할 키워드의 만료 time string
   * @return {boolean}
   * <p><code>true</code> - 만료됨</p><p><code>false</code> - 만료되지 않음.</p>
   */
  private hasExpired(expiredTimestring: string): boolean {
    try {
      const today: string = this.getYYYYMMDDString();
      const isInvalidExpiry: boolean = !(expiredTimestring && expiredTimestring.length === 8 && StringUtil.isPositiveIntegerFormat(expiredTimestring));
      return isInvalidExpiry || NumberUtil.stringToNumber(expiredTimestring!) < NumberUtil.stringToNumber(today);
    } catch (e) {
      return true;
    }
  }

  /**
   * 입력된 만료일에 해당하는 time string을 반환
   * @param {KeywordSessionType} keywordSessionType - 전환 키워드 타입
   * @param {number} expiryDate 만료일
   * @return {string} 계산된 time string
   */
  private getExpiredTimestring(keywordSessionType: KeywordSessionType, expiryDate: number): string {
    switch (keywordSessionType) {
      case KeywordSessionType.SESSION:
        return '';
      case KeywordSessionType.INDIRECT:
        const expired: Date = new Date(Date.now() + (1000 * 60 * 60 * 24 * expiryDate));
        return this.getFormattedTimestring(expired);
      default:
        return '';
    }
  }

  /**
   *
   * @param {Date} targetDate
   * @return {string}
   */
  private getFormattedTimestring(targetDate: Date): string {
    const year: string = targetDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(targetDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(targetDate.getDate(), 2);
    return year + month + date;
  }

  private encodeItem(keyword: string, times = 2): string {
    let encoded = keyword;
    const utf8 = new Utf8();

    for (let i = 0; i < times; i++) {
      encoded = utf8.encode(encoded);
    }

    return encoded;
  }

  private decodeItem(encoded: string, times = 2): string {
    let decoded = encoded;
    const utf8 = new Utf8();

    for (let i = 0; i < times; i++) {
      decoded = utf8.decode(decoded);
    }

    return decoded;
  }

  /**
   * 현재 날짜에 대한 <code>YYYYMMDD</code> 문자열을 반환.
   * @return {string}
   */
  private getYYYYMMDDString(): string {
    const currentDate: Date = new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);

    return year + month + date;
  }
}
