/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-07-16
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-07-16.
 * <p> hec2725 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class Hec2725DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': this.getMobileConversionProdtName(productAnchorElem),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('form[name="forms"]>figure>figcaption.basketRight p.SMSbasketPrice')[i] as HTMLParagraphElement).textContent!.trim()!.replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('form[name="forms"] > p.pname a:nth-child(2)')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.txt-price')!.textContent!.trim()!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }


  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.order-list tbody tr').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.order-list > table tbody tr p.orderlist-info-title >a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.order-list tbody tr div.orderlist-info-icons div.fr span.option-view')[i] as HTMLSpanElement).textContent!.trim().replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent!.trim(),
        'qty': qty,
        'price': (document.querySelectorAll('div.order-list > table tbody tr td.order-price-cell span.sell-price-inner')[i] as HTMLSpanElement).textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.order-list > table tbody tr td.order-price-cell span.sell-price-inner')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.order-list input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.order-list input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.order-list > table tbody tr p.orderlist-info-title >a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('div.order-price-info dl.order-total-price dd')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    let productName:string = "";

    if(this.isMobile(this.commandOptions)){
      productName = document.querySelector('span.shopdetailInfoCont')!.textContent!.trim();
    }else{
      productName = this.getProductName();
    }


    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': productName,
      'price': this.getProductPrice(),
      'productUrl': window.location.href,
      'dcPrice': this.getProductDcPrice(),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }
  protected getShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    let productName:string = "";

    if(this.isMobile(this.commandOptions)){
      productName = document.querySelector('span.shopdetailInfoCont')!.textContent!.trim();
    }else{
      productName = this.getProductName();
    }

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      //'productName': this.getProductName(),
      'productName': productName,
      'price': this.getProductPrice(),
      'productUrl': window.location.href,
      'dcPrice': this.getProductDcPrice(),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }


  private onSale(): boolean {
    return StringUtil.isNotEmpty((document.querySelector('form#form1')!['discount_type'] as HTMLInputElement).value);
  }

  private getProductDcPrice(): string {
    try {
      if(window.location.pathname.indexOf('/m/') === 0){
        return document.querySelector('div.price_container p:nth-child(1) >span.shopdetailInfoCont del') != null
            ? document.querySelector('div.price_container p:nth-child(1) >span.shopdetailInfoCont del')!.textContent!.trim().replace(/\D/g, '')
            : document.querySelector('div.price_container p.shopdetailInfoDiscount span.shopdetailInfoCont em')!.textContent!.trim().replace(/\D/g, '');
      } else {
        return document.querySelector('div.table-opt > table > tbody > tr:nth-child(1) td > div.tb-left') != null
            ? document.querySelector('div.table-opt > table > tbody > tr:nth-child(1) td > div.tb-left')!.textContent!.trim().replace(/\D/g, '')
            : '0';
      }
    } catch {
      return document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, '');
    }
  }

  private getProductPrice(): string {
    let price:string = "0";
    try {
      if(window.location.pathname.indexOf('/m/') === 0){
        if(StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)){
          if(StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '') == ""){
            price = document.querySelector('form#form1')!['price']['value'].replace(/\D/g, '');
          }else{
            price =StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '');
          }
        }else{
          price = document.querySelector('form#form1')!['price']['value'].replace(/\D/g, '');
        }
        return price;
      } else {

        if(document.querySelector('div.table-opt > table > tbody > tr:nth-child(2) td.bgs > div.tb-left') != null){
          if(document.querySelector('div.table-opt > table > tbody > tr:nth-child(2) th > div.tb-left')!.textContent!.trim() == '판매가' || document.querySelector('div.table-opt > table > tbody > tr:nth-child(2) th > div.tb-left')!.textContent!.trim() == '소비자가'){
            price = document.querySelector('div.table-opt > table > tbody > tr:nth-child(2) td.bgs > div.tb-left')!.textContent!.trim().replace(/\D/g, '');
          }else{
            price = document.querySelector('form#form1')!['price']['value'].replace(/\D/g, '');

          }
        }else{
          price = document.querySelector('form#form1')!['price']['value'].replace(/\D/g, '');
        }

        return price;
      }
    } catch {
      return document.querySelector('form#form1')!['price']['value'].replace(/\D/g, '');
    }
  }

}
