/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {EventType, HostingType} from '../types/GlobalEnums';
import {FormatValidator} from './formatValidator/FormatValidator';
import {DomainValidRule} from './formatValidator/rule/DomainValidRule';
import {PriceValidRule} from './formatValidator/rule/PriceValidRule';
import {UrlValidRule} from './formatValidator/rule/UrlValidRule';
import {QuantityValidRule} from './formatValidator/rule/QuantityValidRule';
import {StringValidRule} from './formatValidator/rule/StringValidRule';
import {ProductCodeRule} from './formatValidator/rule/ProductCodeRule';
import {InvalidValueError} from '../error/InvalidValueError';
import {CategoryNameRule} from "./formatValidator/rule/CategoryNameRule";
import {DeviceValidRule} from "./formatValidator/rule/DeviceValidRule";
import {ProductNameValidRule} from "./formatValidator/rule/ProductNameValidRule";
import {SoldOutValidRule} from "./formatValidator/rule/SoldOutValidRule";
import {SchemeValidRule} from "./formatValidator/rule/SchemeValidRule";
import {OrderCodeManager} from "../OrderCode/OrderCodeManager";
import {StringUtil} from "../lib/common/StringUtil";
import {InvalidData} from "../lib/ajax/InvalidData";
import {Shield} from "../shield/Shield";
import {UrlHashValidRule} from "./formatValidator/rule/UrlHashValidRule";
import {DataInvalidAlarm} from "../lib/alarm/DataInvalidAlarm";
import {DcPriceValidRule} from "./formatValidator/rule/DcPriceValidRule";
import {TopCategoryNameRule} from "./formatValidator/rule/TopCategoryNameRule";
import {ConvPrdtNameValidRule} from "./formatValidator/rule/ConvPrdtNameValidRule";
import { TotalPriceValidRule } from './formatValidator/rule/TotalPriceValidRule';
import { AdverIdValidRule } from './formatValidator/rule/AdverIdValidRule';
import { NumberUtil } from '../lib/common/NumberUtil';

/**
 * TODO comment
 * create on 2019-08-02.
 * <p> 서버에 보낼 데이터에 대한 검증을 하는 클래스 </p>
 * <p> {@link DataValidatorFactory} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class DataValidator {
  /* 광고주의 호스팅사 타입 */
  protected hostingType: HostingType;

  protected constructor(hostingType: HostingType) {
    this.hostingType = hostingType;
  }

  /**
   * 이벤트 타입에 따른 데이터 검증 후 검증된 데이터를 반환.
   * 에디터 페이지, 블록 로직에 포함된 경우엔 {@link InvalidData}, 이외에는 검증된 데이터를 반환.
   * @param {EventType} eventType - 이벤트 타입
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   * @throws InvalidValueError  - 잘못된 이벤트 타입을 입력받은 경우.
   */
  validate(eventType: EventType, data: {}): {} {
    const shield: Shield = new Shield([
      this.fromEditorPage,
      /** 
       * 한글이 포함된 광고주 아이디 차단
       * */
      function blockByAdverIdKorean(): boolean {
        const koreanRegex = /[\u3131-\u314e|\u314f-\u3163|\uac00-\ud7a3]/g;
        return StringUtil.contains(data['adverId'], koreanRegex);
      },
      /* minishlab 광고주의 경우 전환 유입만 차단 (추후 설치된 스크립트 수정되면 이 로직 제거) */
      function isMinishlab(): boolean {
        return data['adverId'] === 'minishlab' && eventType === EventType.CONVERSION;
      },
      /**
       * 결제 전환시 결제 실패이거나 결제 취소인 경우 차단
       * @returns boolean
       */
      function conversionURIVaildate(): boolean {
        const wordList = ['mode=pgFail', 'mode=pgUserStop'];
        let result = false;
        
        /**
         * 고도몰_임대이면서 'mode=pgFail', 'mode=pgUserStop' 값이 포함된 경우 차단
         */
        if (data['solutionType'] === HostingType.GODOMALL_RENT && eventType === EventType.CONVERSION) {
          for (let i = 0, len = wordList.length; i < len; i++) {
            result = new RegExp(wordList[i]).test(data['fromUrl']);
            if (result) break;
          }
        }
        return result;
      },
      function pobsmbsoolTotalPriceCheck(): boolean {
        if(data['adverId'] === 'pobsmbsool' && eventType === EventType.CONVERSION){
          return Number(data['totalPrice'].toString()) === 0;
        }
        return false;
      }
    ]);
    // todo 테스트 후 주석 코드 제거
    if (shield.blocked()) {
    // if (this.fromEditorPage() && new Shield().shouldBeBlocked()) {
      return new InvalidData();
    } else {
      switch (eventType) {
        case EventType.COMMON:
          return this.commonTraceValidate(data);
        case EventType.COLLECT:
          return this.collectValidate(data);
        case EventType.CART:
          return this.cartValidate(data);
        case EventType.WISH:
          return this.wishValidate(data);
        case EventType.CONVERSION:
          return this.conversionValidate(data);
        case EventType.REVIEW:
          return this.reviewValidate(data);
        case EventType.TEST:
          return this.testValidate(data);
        default:
          throw new InvalidValueError('eventType', eventType);
      }
    }
  }

  /**
   * COMMON 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected commonTraceValidate(data: {}): {} {
    data = FormatValidator.createRule(false)
      .addRule(new AdverIdValidRule('adverId'))
      .addRule(new UrlHashValidRule('url'))
      .validate(data);

    return data;
  }

  /**
   * COLLECT 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected collectValidate(data: {}): {} {
    data = FormatValidator.createRule(false)
      .addRule(new AdverIdValidRule('adverId'))
      .addRule(new DeviceValidRule('device'))
      .addRule(new DomainValidRule('domain'))
      .addRule(new ProductNameValidRule('productName'))
      .addRule(new PriceValidRule('price'))
      .addRule(new DcPriceValidRule('dcPrice'))
      .addRule(new UrlValidRule('productUrl'))
      .addRule(new UrlHashValidRule('productUrl'))
      .addRule(new SoldOutValidRule('soldOut'))
      .addRule(new StringValidRule('siteCode'))
      .addRule(new SchemeValidRule('imageUrl'))
      .addRule(new TopCategoryNameRule('topCategory'))
      .addRule(new CategoryNameRule('firstSubCategory'))
      .addRule(new CategoryNameRule('secondSubCategory'))
      .addRule(new CategoryNameRule('thirdSubCategory'))
      .validate(data);

    // 클릭키워드의 경우 최초로 클릭한 상품만 수집 상세페이지에서 상페 페이지로 이동한 경우는 수집안한다.
    if (this.chkAfterPageIsDetail()) {
      delete data['externalKeyword'];
    }

    return data;
  }

  /**
   * CART 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected cartValidate(data: {}): {} {
    return this.collectValidate(data);
  }

  /**
   * WISH 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected wishValidate(data: {}): {} {
    return this.collectValidate(data);
  }

  /**
   * CONVERSION 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected conversionValidate(data: {}): {} {
    const productArr: Array<{}> = data['product'];
    DataInvalidAlarm.getInstance(data['adverId'], data['device'], EventType.CONVERSION);

    /* 주문한 개별 상품에 대한 검증 */
    for (let i = 0; i < productArr.length; i++) {
      FormatValidator.createRule(false)
        .addRule(new QuantityValidRule('qty'))
        .addRule(new PriceValidRule('price'))
        .addRule(new DcPriceValidRule('dcPrice'))
        .addRule(new ProductCodeRule('productCode'))
        .addRule(new ConvPrdtNameValidRule('productName'))
        .validate(productArr[i]);
    }

    // 주문번호가 없으면 주문번호 생성
    if (StringUtil.isEmpty(data['ordCode']) || data['ordCode'] === null || data['ordCode'] === undefined) {
      data['ordCode'] = OrderCodeManager.getInstance().createOrderCode(data);
    }

    /* 주문된 건에 대한 검증 */
    FormatValidator.createRule(false)
      .addRule(new AdverIdValidRule('adverId'))
      .addRule(new DeviceValidRule('device'))
      .addRule(new DomainValidRule('domain'))
      .addRule(new QuantityValidRule('totalQty'))
      .addRule(new TotalPriceValidRule('totalPrice'))
      .validate(data);

    DataInvalidAlarm.getInstance()!.send();
    return data;
  }

  /**
   * 광고주의 소스를 수정하는 에디터 페이지인지 확인
   * @return {boolean}
   * <p><code>true</code> - 에디터 페이지</p><p><code>false</code> - 에디터 페이지 아님</p>
   */
  protected abstract fromEditorPage(): boolean;

  /**
   * 이전 페이지(referrer)가 상품상세페이지 인지 확인
   * 외부키워드의 경우 이전페이지가 상품상세페이지인 경우 전송을 안한다.
   * @return {boolean}
   * <p><code>true</code> - 상세페이지 </p><p><code>false</code> - 상세페이지 아님</p>
   */
  protected abstract chkAfterPageIsDetail(): boolean;

  private reviewValidate(data: {}): {} {
    return data;
  }

  /**
   * TEST 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  private testValidate(data: {}): {} {
    return data;
  }
}
