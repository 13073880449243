/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-14.
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";

/**
 * create on 2023-04-14.
 * <p> {@link GodomallSelfDataParser } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class MujinaraDataParser extends GodomallSelfDataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  
  protected getConversionData(): JsonObject {
    const parsedProducts: Array<{}> = [];
    const ordCode: string = new QueryStringer(window.location.search.substring(1)).getParam('ordno');

    const delivery = (window[this.ENP_OBJECT_NAME] && StringUtil.isNotEmpty(window[this.ENP_OBJECT_NAME]['delivery']))
        ? NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['delivery'])
        : 0;

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': document.querySelector(' table > tbody > tr:nth-child(15) > td:nth-child(2)')!.textContent!.replace(/\D/g,''),
      'totalQty': 1
    };

    return new JsonObject(parsedData);
  }
}
