/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-30
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-03-30.
 * <p> Andersson 광고주 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class AnderssonDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected isProductPage(): boolean {
    try {
      return window.location.pathname.substring(1).split('/')[1].search('detail.html') >= 0;
    } catch (e) {
      return false;
    }
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
     try {
       let priceValue: number;
       let dcPriceValue: number;

       if (this.isMobile(this.commandOptions)) {
         const priceObj = document.querySelectorAll('div.prdDesc tbody.priceArea td.price > strong.sale');
         priceValue = priceObj && priceObj.length > 0 ? NumberUtil.parseRationalNumber(priceObj[0].textContent!.trim()) : 0;
         dcPriceValue = priceObj && priceObj.length > 2 ? NumberUtil.parseRationalNumber(priceObj[2].textContent!.trim()) : 0;
       } else {
         priceValue = NumberUtil.parseRationalNumber(document.querySelector('#span_product_price_custom')!.textContent!.trim());
         dcPriceValue = document.querySelector('#span_product_price_sale')
             ? NumberUtil.parseRationalNumber(document.querySelector('#span_product_price_sale')!.childNodes[0].textContent!.trim()) : 0;
       }

       return {price: priceValue, dcPrice: dcPriceValue};
     } catch (e) {
       return {price: 0, dcPrice: 0};
     }
  }

  protected getImageSrc(): string {
    try {
      if(this.isMobile(this.commandOptions)) {
        const imgDiv = document.getElementById('section1');
        const style = window.getComputedStyle(imgDiv!);
        return style!.backgroundImage!.slice(4, -1).replace(/"/g, "");
      } else {
        return (document.querySelector('.imgArea .thumbnail img[src]') as HTMLImageElement).src;
      }
    } catch (e) {
      return super.getImageSrc();
    }
  }
}
