/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-08-17
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2023-08-17.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Styleonme1DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();

    const product = jsonObj.data['product'];

    for (let i = 0, len = product.length; i < len; i++) {
      product[i]['price'] = document.querySelectorAll('.table-cart.table-order-prd tbody tr td:nth-of-type(5) div.tb-center.tb-bold')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }
}
