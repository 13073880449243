/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-03-10
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-10
 * <p> merrellkorea - https://merrellkorea.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class MerrellkoreaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.replaceData('price', NumberUtil.parseRationalNumber(document.querySelector('#span_product_price_custom')!.textContent!.trim()));
    return jsonObj
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    const productList = jsonObj['product'];
    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelector('#span_product_price_custom')!.textContent!.trim());
    }
    return jsonObj;
  }
}
