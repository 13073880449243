/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-05-06
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-05-06.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Onetreasure2DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    if(this.isMobile(this.commandOptions)){
      if(document.querySelector('.priceArea td.strike strong')){
        return {
          price: NumberUtil.parseNumber(document.querySelector('.priceArea td.strike strong')!.textContent!) ? 
          NumberUtil.parseNumber(document.querySelector('.priceArea td.strike strong')!.textContent!) : 
          NumberUtil.parseNumber(document.querySelector('#span_product_price_text.ProductPrice')!.textContent!),
          dcPrice: NumberUtil.parseNumber(document.querySelector('#span_product_price_text.ProductPrice')!.textContent!),
        }
      }
      return super.getProductPrice();
    }
    return super.getProductPrice();
  }

  protected getImageSrc(): string {
    try {
      if (this.isMobile(this.commandOptions)) {
        return super.getImageSrc();
      }

      return (document.querySelector('.detailArea .infoArea .thumbnail img') as HTMLImageElement).src;
    } catch (e) {
      return super.getImageSrc();
    }
  }
}
