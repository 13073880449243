/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-07
 */
import {DataParser} from '../DataParser';
import {JsonObject} from '../../lib/json/JsonObject';
import {HostingType} from '../../types/GlobalEnums';
import {NumberUtil} from '../../lib/common/NumberUtil';
import {GlobalVariables} from "../../types/GlobalVariables";
import {QueryStringer} from "../../lib/url/QueryStringer";
import {StringUtil} from "../../lib/common/StringUtil";
import {Value} from "../../lib/value/Value";
import {NullParingData} from "../../lib/ajax/NullParingData";

/**
 * create on 2019-11-07.
 * <p> 고도몰 임대형 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GodomallRentDataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.GODOMALL_RENT);
  }

  protected isProductPage(): boolean {
    try {
      return window.location.pathname === '/goods/goods_view.php';
    } catch (e) {
      return false;
    }
  }

  protected isBasketPage(): boolean {
    try {
      return window.location.pathname === '/order/cart.php';
    } catch (e) {
      return false;
    }
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  /**
   * 고도몰의 경우 서브 카테고리가 붙어서 파싱(001002003) 되어 예외로 수집 로직적용
   * (카테고리 3개씩 짤라서 수집)
   * @param currentCategoryCode
   * @protected
   */
  protected parseCategory(currentCategoryCode: string) {
    try {
      super.parseCategory(currentCategoryCode);
      if(this.category.topCategory === currentCategoryCode) {
        this.category.topCategory = currentCategoryCode.substring(0,3);
        this.category.firstSubCategory = currentCategoryCode.substring(3,6);
        this.category.secondSubCategory = currentCategoryCode.substring(6,9);
        this.category.thirdSubCategory = currentCategoryCode.substring(9,12);
      }
    } catch (e) {
      this.category.topCategory = currentCategoryCode;
      this.category.firstSubCategory = undefined;
      this.category.secondSubCategory = undefined;
      this.category.thirdSubCategory = undefined;
    }
  }

  protected getShopCollectData(): JsonObject {
    try {
      const formElement: HTMLFormElement | null = document.querySelector('form#frmView');
      return this.isMobile(this.commandOptions)
          ? this.getMobileShopCollectData(formElement!)
          : this.getWebShopCollectData(formElement!);
    } catch (e) {
      return new NullParingData();
    }
  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  /**
   * todo 리팩토링 필요
   * @return {JsonObject}
   */
  protected getConversionData(): JsonObject {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="naver-common-inflow-script-order-item"]');
    const ordCode: string = this.getConvJsonData(inputList, 0)['ordno'];
    const parsedTotalPrice: number = this.getParsedTotalPrice();

    let totalQty = 0;
    let totalPrice = 0;

    for (let i = 0; i < inputList.length; i++) {
      const convInfo: {} = this.getConvJsonData(inputList, i);
      const qty: number = convInfo['ea'];
      const rawPrice: number = convInfo['price'];
      const price: number = rawPrice / qty;

      parsedProducts.push({
        'productCode': convInfo['goodsno'],
        'productName': convInfo['goodsnm'],
        'qty': qty.toString(),
        'price': price.toString()
      });

      totalQty += qty;
      totalPrice += rawPrice;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      /* 주문금액을 파싱하지 못하면 상품가격을 더한 금액을 전송 */
      'totalPrice': parsedTotalPrice === 0 || NumberUtil.isNaN(parsedTotalPrice)
          ? totalPrice.toString()
          : parsedTotalPrice.toString(),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getConvJsonData(inputList: NodeListOf<HTMLInputElement>, pos: number): {} {
    try {
      return inputList && inputList.length > 0 ? eval(`(${inputList[pos].value.replace(/\\\\'/g, '\\\'')})`) : {};
    } catch (e) {
      return inputList[pos].value;
    }
  }

  protected getPayConvDataFromProductPage(): {} {
    const formElement: HTMLFormElement | null = document.querySelector('form#frmView');

    return this.isMobile(this.commandOptions)
        ? this.getMobilePayConvDataFromProductPage(formElement!)
        : this.getWebPayConvDataFromProductPage(formElement!);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][id^="cartSno"]');
    /* 배송비 포함된 총 주문금액 가져오지 못할 경우 0원 */
    const parsedTotalPrice: number = this.getParsedTotalPrice();

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < inputList.length; i++) {
      const qty = NumberUtil.parseInteger(inputList[i].dataset['defaultGoodsCnt'] as string);
      const rawPrice = NumberUtil.parseNumber(inputList[i].dataset['price'] as string);
      const price = rawPrice / qty;

      parsedProducts.push({
        'productCode': inputList[i].dataset['goodsNo'],
        'productName': inputList[i].dataset['goodsNm'],
        'qty': qty,
        'price': price
      });

      totalPrice += rawPrice;
      totalQty += qty;
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      /* 배송비 포함된 금액을 가져오지 못하면 상품 가격들의 합으로 초기화 */
      'totalPrice': parsedTotalPrice > 0 ? parsedTotalPrice : totalPrice,
      'totalQty': totalQty
    };
  }

  /**
   * 모바일 여부 판단
   * @param {{}} commandOptions
   * @return {boolean}
   */
  protected isMobile(commandOptions: {}): boolean {
    try {
      const subDomain: string = location.hostname.split('.')[0].toLowerCase();
      const pathArr: string[] = location.pathname.substring(1).split('/');

      return subDomain === 'm'
          ? true
          : pathArr.length > 0 && StringUtil.matchExactly(pathArr[0], /^m\d*$/i);
    } catch (e) {
      return false;
    }
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageElement: HTMLImageElement = this.getImageFromWebDetail();

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': imageElement ? imageElement.alt : '',
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': imageElement ? imageElement.src : '',
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageElement: HTMLImageElement = this.getImageFromMobileDetail();

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': imageElement ? imageElement.alt : '',
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': imageElement ? imageElement.src : '',
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageElement: HTMLImageElement = this.getImageFromWebDetail();

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': imageElement ? imageElement.alt : '',
      'price': priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement)
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'].value,
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);
    const imageElement: HTMLImageElement = this.getImageFromMobileDetail();

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': imageElement ? imageElement.alt : '',
      'price': priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement)
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'] && NumberUtil.parseRationalNumber(formElement!['set_total_price'].value) > 0
          ? formElement!['set_total_price'].value
          : (NumberUtil.parseRationalNumber(parsedProducts[0]['dcPrice'] > 0 ? parsedProducts[0]['dcPrice'] : parsedProducts[0]['price'])) * NumberUtil.parseNumber(parsedProducts[0]['qty']),
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }

  /**
   * 상품의 할인 여부
   * @param {HTMLFormElement} formElement
   * @return {boolean}
   */
  protected onSale(formElement: HTMLFormElement): boolean {
    const price: number = NumberUtil.parseRationalNumber(formElement['set_goods_fixedPrice']!.value);
    return price > 0;
  }

  /**
   * 주문 완료 페이지 여부
   * @return {boolean}
   * <p><code>true</code> - 주문 완료 페이지</p><p><code>false</code> - 주문 완료 페이지 아님</p>
   */
  protected isOrderPage(): boolean {
    return window.location.pathname === '/order/order_end.php';
  }

  /**
   * 주문 완료, 장바구니 페이지에서 배송비가 포함된 주문금액을 파싱하여 리턴.
   * <b>NOTE: </b>필요한 element를 참조하지 못하면 0을 리턴.
   * @return {number} 배송비 포함 주문금액
   */
  protected getParsedTotalPrice(): number {
    const parsedTotalPrice = (): number => {
      /* 주문완료 페이지 */
      if (this.isOrderPage()) {

        const totalPrice: string = this.isMobile(this.commandOptions)
            ? (document.querySelector('.prc') as Element)!.textContent!.trim()
            : (this.getSelectorAll(['.order_payment_sum', '.table1 .c-red'])[1] as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 장바구니 페이지 */
      else if (this.isBasketPage()) {
        const totalPrice: string = (document.querySelector('#totalSettlePrice') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 다른 페이지는 0원으로 처리 */
      else {
        return 0;
      }
    };

    try {
      return parsedTotalPrice();
    } catch (e) {
      return 0;
    }
  }

  /**
   * 상품상세 페이지에서 총 주문 수량을 파싱하여 리턴.
   * <b>NOTE: </b>필요한 element를 참조하지 못하면 1을 리턴.
   * @param {HTMLFormElement} formElement <code>form</code> element
   * @return {number} 총 주문 수량
   */
  protected getParsedTotalQty(formElement: HTMLFormElement): number {
    /* 필요한 element를 추출 */
    const getElements = (formElement: HTMLFormElement): NodeListOf<HTMLInputElement> => {
      if (this.isProductPage()) {
        if (formElement.querySelector('span.goods_qty input') !== null) {
          return formElement.querySelectorAll('span.goods_qty input');
        } else if (document.querySelectorAll('*[id^="option_display_item"] *.check span.count input')) {
          return document.querySelectorAll('*[id^="option_display_item"] *.check span.count input');
        } else {
          return new NodeList<>();
        }
      } else {
        return new NodeList<>();
      }
    };

    try {
      const elements: NodeListOf<HTMLInputElement> = getElements(formElement);
      /* element가 있으면 수량 계산을 위해 0으로 초기화 */
      let totalQty: number = elements.length > 0 ? 0 : 1;
      elements.forEach((element: HTMLInputElement) => totalQty += +(element.value));
      return totalQty;
    } catch (e) {
      return 1;
    }
  }

  /**
   * 품절 여부를 정제해서 반환
   * @param {string} rawData
   * @return {string}
   */
  protected getRefinedSoldOut(rawData: string): string {
    try {
      const soldOut: string = Value.getValue(rawData, 'N');
      return soldOut.length === 0 ? 'N' : soldOut;
    } catch (e) {
      return 'N';
    }
  }

  /**
   * 상품 상세페이지에서 금액정보를 파싱후 리턴
   * @param formElement 상품정보가 담겨있는 formElement
   * @protected
   * @return {price:number, dcPrice: number}
   */
  // TODO 리팩토링 할 필요성이 있음
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      let price = 0, dcPrice = 0;

      const calcSaleValue: number = NumberUtil.parseRationalNumber((formElement['set_dc_price'] as HTMLInputElement)
          ? (formElement['set_dc_price'] as HTMLInputElement).value : '0');
      if (calcSaleValue > 0) {
        price = NumberUtil.parseRationalNumber((formElement!['set_goods_price'] as HTMLInputElement).value);
        dcPrice = price > calcSaleValue ? price - calcSaleValue : 0;

        return {'price': price, 'dcPrice': dcPrice};
      }

      const discountPriceValue = NumberUtil.parseRationalNumber(formElement['set_goods_fixedPrice']
          ? formElement['set_goods_fixedPrice'].value : '0');
      if (discountPriceValue > 0) {
        price = discountPriceValue;
        dcPrice = NumberUtil.parseRationalNumber((formElement!['set_goods_price'] as HTMLInputElement).value);

        return {'price': price, 'dcPrice': dcPrice};
      }

      return {'price': formElement!['set_goods_price'].value, 'dcPrice': 0}
    } catch (e) {
      return {'price': formElement!['set_goods_price'].value, 'dcPrice': 0}
    }
  }

  protected getImageFromWebDetail(): HTMLImageElement | undefined {
    try {
      return this.getSelector([
        '#mainImage>img',
        'div.slick-track li.view-thumnail img',
        'div.item_photo_info_sec .img_photo_big img',
        '.goods_view li.slick-slide div.img-wrap img',
        'div.slick-track li.slick-active img',
        '.main-img img',
        '.item_photo_slide .item img',
        '.swiper-slide-active .trxGdsVeuImgWrp img'
      ]) as HTMLImageElement;
    } catch (e) {
      return undefined;
    }
  }

  protected getImageFromMobileDetail(): HTMLImageElement | undefined {
    try {
      return this.getSelector([
        'div.cont_detail li>img',
        'div.thumbnail-img > img',
        'div.slick-track > div.thum-img > img',
        '.goods_main_image .slick-track .slick-slide img',
        'div.cont_detail img',
        '.goods_view li.slick-slide div.img-wrap img',
        '#mainImage img',
        '.goods_view li.swiper-slide-active img',
        '.goods_intro_image div.slick-active img'
      ]) as HTMLImageElement;
    } catch (e) {
      return undefined;
    }
  }
}
