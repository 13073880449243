/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjleeajflzk</a>
 * @since 2023-02-02
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-02-02
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SejungmallDataParser extends Cafe24SmartDataParser {
  protected parseCategory(currentCategoryCode: string): void {
    if(this.isMobile(this.commandOptions)){
      this.category[this.categoryHierarchyLevel[0]] = document.querySelector('tr.prd_brand_css td span')!.textContent;
    }
    else{
      this.category[this.categoryHierarchyLevel[0]] = document.querySelector('p.brand')!.textContent;
    }
  }
}