/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-07-16
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-07-16.
 * <p> soxhouse 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class SoxhouseDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedObject: {} = super.getMobilePayConvDataFromBasketPage();
    parsedObject['totalPrice'] = document.querySelector('div.price-total-info-bottom span.txt-price')!.textContent!.trim().replace(/\D/g, '');
    return parsedObject;
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-order-prd tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-order-prd tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-order-prd table tr td:nth-child(3)')[i] as HTMLTableElement).textContent!.trim().replace(/\D/g, '');
      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-order-prd table tr td:nth-child(4)')[i] as HTMLTableElement).textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedObject: {} = super.getWebPayConvDataFromBasketPage();
    parsedObject['totalPrice'] = document.querySelector('div.table-cart tfoot div.tb-right strong')!.textContent!.trim()!.replace(/\D/g, '');
    return parsedObject;
  }


  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': this.getProductDcPrice(),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  protected getShopCollectData(): JsonObject {
    const parsedJsonObject: JsonObject = super.getShopCollectData();
    parsedJsonObject.addData('dcPrice', this.getProductDcPrice());
    return parsedJsonObject;
  }


  protected getImageUrl(): string {
    if (document.querySelector('div.thumb-wrap div.slick-list div.slick-slide:nth-child(2) img')) {
      return (document.querySelector('div.thumb-wrap div.slick-list div.slick-slide:nth-child(2) img') as HTMLImageElement).src;
    } else {
      return super.getImageUrl();
    }
  }

  private getProductDcPrice(): string {
    try {
      const dcPriceElement: Element | null = this.isMobile(this.commandOptions)
        ? document.querySelector('div.goods-price li.dc_li span.price-desc')
        : document.querySelector('div.table-opt td.price div.item-price');

      return dcPriceElement
        ? dcPriceElement!.textContent!.trim().replace(/\D/g, '')
        : '0';
    } catch (e) {
      return StringUtil.isEmpty(document.querySelector('form#form1')!['disprice']['value'])
        ? '0'
        : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, '');
    }
  }
}
