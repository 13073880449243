/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-19
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-04-19
 * <p> imbecky2 - https://imbecky.net/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Imbecky2DataParser extends MakeshopD4DataParser {
  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    if(!this.isMobile(this.commandOptions)) {
      const productList = jsonObj['data']['product'];
      for (let i = 0; i < productList.length; i++) {
        productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-cart tbody tr td:nth-child(5) div')[i].textContent!.trim());
      }
    }
    return jsonObj;
  }
}
