/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-09-14
 * awmr_wiisnt
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

export class AwmrWiisntDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    try {
      if (!this.isMobile(this.commandOptions)) {
        superData['price'] = NumberUtil.parseNumber(document.querySelector('.detailArea .priceInfo')!.getAttribute('data-price')!);
      }
      return superData;
    } catch {
      return superData;
    }
  }


}

