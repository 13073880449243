/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */

/**
 * TODO: comment
 * create on 2019-09-11.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class StringUtil {
  /* 숫자 포맷 정규표현식 */
  static numberFormatRegex = {
    // 유리수
    'rational': {
      'positive': /^((?!-)[\d]+[,\d]*\.*[\d]+)|^[\d]/,
      'negative': /(-{1}[\d]+[,\d]*\.*[\d]+)|-{1}[\d]/
    },
    // 정수
    'integer': {
      'positive': /^[0-9,]+$/,
      'negative': /^-{1}[0-9,]+$/
    },
    // 실수
    'float': {
      'positive': /^((?!-)[\d]+[,\d]*\.+[\d]+)/,
      'negative': /-{1}[\d]+[,\d]*\.+[\d]+/
    }
  };

  /* URL 포맷 정규표현식 */
  static urlFormatRegex = {
    'permittedUrl': /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w-._~:/?#[\]@!$&'()*+,;=%]+$/,
    'domain': /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/
  };

  /* HTML 관련 정규표현식 */
  static htmlRegex = {
    'elementContent': /[^><]+(?=<|$)/
  };

  /*
   * 화폐 기호
   * 20A9, FFE6 - 원
   */
  static currencyRegex = /[\u20A9\uFFE6]/g;

  static EMPTY = '';
  static COMMA = ',';

  /* istanbul ignore next: private constructor */
  private constructor() {}

  static padLeft(srcString: string, strToPad: string, lengthAfterPadded: number): string {
    while (srcString.toString().length < lengthAfterPadded) {
      srcString = strToPad + srcString;
    }

    return srcString;
  }

  static padRight(srcString: string, strToPad: string, lengthAfterPadded: number): string {
    while (srcString.toString().length < lengthAfterPadded) {
      srcString = srcString + strToPad;
    }

    return srcString;
  }

  static isString(value: any): boolean {
    return typeof value === 'string';
  }

  static isNotString(value: any): boolean {
    return !StringUtil.isString(value);
  }

  static isEmpty(value: any): boolean {
    return value === StringUtil.EMPTY;
  }

  static isNotEmpty(value: any): boolean {
    return !StringUtil.isEmpty(value);
  }

  static contains(targetString: string, regExp: RegExp): boolean {
    return regExp.test(targetString);
  }

  static getMatchedString(fromString: string, searchValue: string | RegExp): string {
    const matchedArray: any[] | null = fromString.match(searchValue);
    if (matchedArray !== null && typeof matchedArray[0] === 'string') {
      return matchedArray[0];
    } else {
      throw new Error('Not matched.');
    }
  }

  static matchExactly(targetString: string, searchValue: string | RegExp): boolean {
    const matchedArray: any[] | null = targetString.match(searchValue);
    return matchedArray !== null && typeof matchedArray[0] === 'string';
  }

  static replace(srcStr: string, searchValue: string | RegExp, replaceValue: string): string {
    return srcStr.replace(searchValue, replaceValue);
  }

  static isRationalFormat(targetString: string): boolean {
    return this.isPositiveRationalFormat(targetString)
        || this.isNegativeRationalFormat(targetString);
  }

  static isPositiveRationalFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.numberFormatRegex.rational.positive);
  }

  static isNegativeRationalFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.numberFormatRegex.rational.negative);
  }

  static isIntegerFormat(targetString: string): boolean {
    return StringUtil.isPositiveIntegerFormat(targetString)
        || StringUtil.isNegativeIntegerFormat(targetString);
  }

  static isPositiveIntegerFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.numberFormatRegex.integer.positive);
  }

  static isNegativeIntegerFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.numberFormatRegex.integer.negative);
  }

  static isFloatFormat(targetString: string): boolean {
    return StringUtil.isPositiveFloatFormat(targetString)
        || StringUtil.isNegativeFloatFormat(targetString);
  }

  static isPositiveFloatFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.numberFormatRegex.float.positive);
  }

  static isNegativeFloatFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.numberFormatRegex.float.negative);
  }

  static isPermittedUrlFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.urlFormatRegex.permittedUrl);
  }

  static isDomainFormat(targetString: string): boolean {
    return StringUtil.matchExactly(targetString, StringUtil.urlFormatRegex.domain);
  }

  /**
   * URL에서 domain을 추출하여 반환. (host가 아닌 Mobon에서 사용하는 패턴)
   * domain을 추출할 수 없으면 <code>Error</code>를 발생시킨다.
   * @param {string} url  URL
   * @return {string} 추출된 도메인
   * @throws Error  도메인을 추출할 수 없을 경우
   */
  static getDomainFromUrl(url: string): string {
    try {
      return StringUtil.isDomainFormat(url)
          ? url.match(/^(http[s]?:\/{2})?(www\.|m\.|mw\.)*([^/?:]+)/)![3]
          : StringUtil.EMPTY;
    } catch (e) {
      throw new Error('Invalid Domain format');
    }
  }

  /**
   * referrer에서 Mobon에서 사용하는 도메인을 추출하여 반환.
   * 에러 발생시 빈 문자열을 반환.
   * 내부적으로 {@link #getDomainFromUrl} 호출.
   * @return {string} 추출된 도메인
   */
  static getReferrerDomain(): string {
    try {
      return StringUtil.getDomainFromUrl(document.referrer);
    } catch (e) {
      return StringUtil.EMPTY;
    }
  }

  /**
   * URL에서 pathname만 추출해 반환한다.
   * @return pathname: string
   */
  static getPathNameFromUrl(url: string): string {
    try {
      const domain: string = StringUtil.getDomainFromUrl(url);

      if(url && domain && StringUtil.isNotEmpty(url) && StringUtil.isNotEmpty(domain)) {
        return url.substring(url.indexOf(domain) + domain.length, url.indexOf('?'));
      } else {
        return StringUtil.EMPTY;
      }
    } catch (e) {
      return StringUtil.EMPTY;
    }
  }

  static getPermittedUrl(url: string): string {
    if (StringUtil.isPermittedUrlFormat(url)) {
      return StringUtil.getMatchedString(url, StringUtil.urlFormatRegex.permittedUrl);
    } else {
      throw new Error('Invalid URL format');
    }
  }

  /**
   * Url에서 http(s)가 붙은 도메인을 추출 한다.
   */
  static getProtocolDomainUrl(): string {
    const url = location.href;
    const pathNamePosition = location.pathname && location.pathname === '/' ? url.length : url.search(location.pathname);
    return url.substring(0, pathNamePosition);
  }

  /**
   * 문자열 안의 HTML 코드 제거
   * @param targetString
   * @return string
   */
  static getNonHTMLStr(targetString: string): string {
    return targetString.replace(/(<([^>]+)>)/ig, '');
  }

  /**
   * 문자열 안의 개행문자(\n,\\n) 제거
   * @param targetString
   * @return string
   */
  static getNonNewLineStr(targetString: string): string {
    return targetString.replace(/\n|\\n/g, ' ');
  }

  /**
   * 문자열 안의 HTML 특수문자 치환
   * @param targetString
   */
  static translateHTMLEntities(targetString: string): string {
    targetString = targetString.replace(/&amp;nbsp;/g, ' ').replace(/&nbsp;/g, ' ');
    targetString = targetString.replace(/&amp;quot;/g, '"').replace(/&quot;/g, '"');
    targetString = targetString.replace(/&amp;lt;/g, '<').replace(/&lt;/g, '<');
    targetString = targetString.replace(/&amp;gt;/g, '>').replace(/&gt;/g, '>');
    targetString = targetString.replace(/&amp;amp;/g, '&').replace(/&amp;/g, '&');

    //ASCII HTML 문자 치환
    targetString = targetString.replace(new RegExp(String.fromCharCode(34),"g"), '"');
    targetString = targetString.replace(new RegExp(String.fromCharCode(38),"g"), '&');
    targetString = targetString.replace(new RegExp(String.fromCharCode(60),"g"), '<');
    targetString = targetString.replace(new RegExp(String.fromCharCode(62),"g"), '>');
    targetString = targetString.replace(new RegExp(String.fromCharCode(160),"g"), ' ');
                    
    return targetString;
  }

  /**
   * 2번이상 space 또는 tap이 있을경우 한개의 space(공백)로 변환
   * @param targetString
   */
  static getNonMultiTab(targetString: string): string {
    return targetString.replace(/\s\s+|\t+/gm, ' ');
  }
}
