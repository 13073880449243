/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class women09DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    try {
      return this.isMobile(this.commandOptions)
      ? (document.querySelector('div.detail-item div.item-thumb img') as HTMLImageElement).src
      : (this.getSelector(['div#productDetail div.thumb img:nth-child(2)']) as HTMLImageElement).src;
    } catch {
      return '';
    }
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
   protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('div.price-wrap span.price')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('div.count-box input[name="amount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"] input[name="brandcode"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('div.txt-box p.item-name')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('p.total-price')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
   protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('div.cart-ct table tr td:nth-child(5)')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('div.cart-ct table tr td div.amount input')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('tbody input[name="brandcode"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('div.pd-info a')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('div.price-wrap span.cont span.price.bold')!.textContent!.replace(/\D/g,'')),
      'totalQty': totalQty.toString()
    };
  }


}
