/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CommandConfig } from './CommandConfig';
import { CommandType, EventType } from '../../../types/GlobalEnums';
import { DataParser } from '../../../dataParser/DataParser';
import { JsonObject } from '../../../lib/json/JsonObject';

/**
 * create on 2019-08-23.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommandConfigBuilder {
  private _commandType: CommandType;
  private _eventType!: EventType;
  private _adverId!: string;
  private _jsonData!: JsonObject;
  private _dataParser!: DataParser;
  private _options!: {};

  constructor(commandType: CommandType) {
    this._commandType = commandType;
  }

  get commandType(): CommandType {
    return this._commandType;
  }

  setCommand(value: CommandType): CommandConfigBuilder {
    this._commandType = value;
    return this;
  }

  get eventType(): EventType {
    return this._eventType;
  }

  setEventType(value: EventType): CommandConfigBuilder {
    this._eventType = value;
    return this;
  }

  get adverId(): string {
    return this._adverId;
  }

  setAdverId(value: string): CommandConfigBuilder {
    this._adverId = value;
    return this;
  }

  get jsonData(): JsonObject {
    return this._jsonData;
  }

  setJsonData(value: JsonObject): CommandConfigBuilder {
    this._jsonData = value;
    return this;
  }

  get dataParser(): DataParser {
    return this._dataParser;
  }

  setDataParser(value: DataParser): CommandConfigBuilder {
    this._dataParser = value;
    return this;
  }

  get options(): {} {
    return this._options;
  }

  setOptions(value: {}): CommandConfigBuilder {
    this._options = value;
    return this;
  }

  build(): CommandConfig {
    return new CommandConfig(this);
  }
}
