/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-12-20
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2022-12-20.
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SecretlabelDataparser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      const rawPrice = document.querySelectorAll('tr td.product-price span span')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('td.product-quantity div input[name="amount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      productList[i]['productName'] = document.querySelectorAll('tr td.product-name a')[i]!.textContent!.trim();
      productList[i]['price'] = price/qty;
    }
    jsonObj['totalPrice'] =document.querySelector('tr td font#total_price')!.textContent!.replace(/\D/g,'').trim();
    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['form[name="forms"] figure figcaption .price'])[i] as HTMLParagraphElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('form[name="forms"] input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('form[name="forms"] figure label.chk_box span')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('li.total span.num')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    if (document.querySelector('div#productDetail div.product-image img')) {
      return (document.querySelector('div#productDetail div.product-image img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else {
      return super.getImageUrl();
    }
  }
}
