/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { ConverterConfig } from './ConverterConfig';
import { PaySystem } from '../../paySystem/PaySystem';
import { PaySystemType } from '../../../types/GlobalEnums';

/**
 * create on 2019-10-08.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PayConverterConfig extends ConverterConfig {
  private readonly _paySystemType: PaySystemType | undefined;

  constructor(adverId: string, options?: {}) {
    super(adverId, options);
    this._paySystemType = PaySystem.getPaySystemType(this.options);
  }

  get paySystemType(): PaySystemType | undefined {
    return this._paySystemType;
  }
}
