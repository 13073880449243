/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-09-10
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2021-09-10.
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SonaturalDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    try{
      if(!this.isMobile(this.commandOptions)){
        return super.getImageUrl();
      }
      return (document.querySelector('.product-detail-major .slick-active img') as HTMLImageElement).src;
    }catch{
      return super.getImageUrl();
    }
  }
}
