/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-11-08
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2021-11-08.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ParklonDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    try {
      let parsedData: {} = {};
      this.parseCategory(window['iCategoryNo']);
      const priceInfo: {} = this.getProductPrice();
      parsedData = {
        'adverId': this.adverId,
        'productCode': window['iProductNo'],
        'productName': window['product_name'],
        'price': priceInfo['price'],
        'productUrl': window.location.href,
        'dcPrice': priceInfo['dcPrice'],
        'soldOut': !!window['is_soldout_icon']
          ? window['is_soldout_icon'] === 'T'
            ? 'Y'
            : 'N'
          : !!window['EC_FRONT_JS_CONFIG_SHOP']
            ? window['EC_FRONT_JS_CONFIG_SHOP']['bSoldout']
              ? 'Y'
              : 'N'
            : 'N',
        'imageUrl': this.getImageSrc(),
        'topCategory': document.querySelector('tr[rel="브랜드"] td span')!.textContent!.trim(),
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };

      return new JsonObject(parsedData);
    } catch {
      return super.getShopCollectData();
    }

  }
}
