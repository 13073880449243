/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-18
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2021-08-18.
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class WindygirlDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
      ? document.querySelector('main.shopdetail section div.shop_de_name div.shop_name_in span')!.textContent!.trim()
      : document.querySelector('#form1 > div > div.dname > h3')!.textContent!.trim();
    } catch (e) {
      return '';
    }
  }
}
