/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {CallbackFunction, HttpMethod} from '../../types/GlobalTypes';
import {NotSupportedError} from '../../error/NotSupportedError';
import {Value} from '../value/Value';
import {AJAXerOption} from './AJAXerOption';
import {InvalidData} from "./InvalidData";
import {QueueManager} from "../../storageQueue/QueueManager";
import {NullParingData} from "./NullParingData";

/**
 * create on 2019-07-31.
 * <p> <code>AJAX</code> 통신을 위한 {@link XMLHttpRequest}의 Wrapper 클래스 </p>
 * <p> 새로운 Request를 생성할 때마다 항상 이 클래스의 객체를 새로 생성할 것. </p>
 * <p> {@link XMLHttpRequest} 관련 클래스 </p>
 * TODO edit comments, TDD
 *
 * @version 1.0
 * @author sghwang
 */
export class AJAXerCore {
  /* XMLHttpRequest 인스턴스 */
  private xhr!: XMLHttpRequest;
  /* XMLHttpRequest 객체 생성시 적용되는 옵션 */
  private _options: AJAXerOption;

  /**
   * 생성자.
   * 전달받은 옵션으로 옵션을 초기화.
   * @param {{timeout: number, contentType: string}} [options] - 옵션
   */
  constructor(options: AJAXerOption) {
    this._options = options;
  }

  get options(): AJAXerOption {
    return this._options;
  }

  set options(value: AJAXerOption) {
    this._options = value;
  }

  /**
   * Request를 중단시킨다.
   * 내부적으로 <code>XMLHttpRequest.abort()</code>를 호출시킨다.
   * <pre>
   *   abort();
   * </pre>
   */
  abort() {
    this.xhr.abort();
  }

  /**
   * GET 방식을 이용한 비동기 통신.
   * {@link open()} 메소드를 이용해 Connection을 열고, GET 방식으로 비동기 통신을 한다.
   * exception 발생시 {@link abort()}를 호출 시킨다
   * <pre>
   *   get('/data?id=user123', (xhr, response, hasTransmitted) => {
   *     if (hasTransmitted) {
   *       console.log('Transmission completed.');
   *     } else {
   *       console.log('Transmission failed.');
   *       console.log(xhr.status + ' ' + xhr.statusText);
   *     }
   *   });
   * </pre>
   * @param {string} url - 대상 URL
   * @param {CallbackFunction} [callback] - 콜백 함수
   * @param {boolean} [async = true] - 비동기 여부
   */
  get(url: string, callback?: CallbackFunction, async = true) {
    try {
      this.open('GET', url, async, callback);
      /*
       * GET 메소드일 때는 body 파라미터가 무시되며, request body는 null이 된다
       * https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/send
       */
      this.xhr.send(null);
    } catch (e) {
      console.error(e);
      this.abort();
    }
  }

  /**
   * TODO data가 배열일 경우에 대한 테스트케이스 작성
   * POST 방식을 이용한 비동기 통신. (객체를 JSON 데이터로 변환 후 전송한다)
   * <b>전송할 데이터가 비어 있지 않은 경우에만 수행.</b>
   * {@link open()} 메소드를 이용해 Connection을 열고, POST 방식으로 비동기 통신을 한다.
   * exception 발생시 {@link abort()}를 호출 시킨다
   * <pre>
   *   post('/data', { id: 'user123', pw: '!Q@W#E$R' }, (xhr, response, hasTransmitted) => {
   *     if (hasTransmitted) {
   *       console.log('Transmission completed.');
   *     } else {
   *       console.log('Transmission failed.');
   *       console.log(xhr.status + ' ' + xhr.statusText);
   *     }
   *   });
   * </pre>
   * @param {string} url - 대상 URL
   * @param {{}} data - 전송할 데이터
   * @param {CallbackFunction} [callback] - 콜백 함수
   * @param {boolean} [async = true] - 비동기 여부
   */
  post(url: string, data: {}, callback?: CallbackFunction, async = true) {
    try {
      /* InvalidData의 인스턴스 타입인지 */
      const isNotInstanceOfInvalidData: boolean = !(data as InvalidData instanceof InvalidData);
      /* NullParingData 인스턴스 타입인지 */
      const isNotInstanceOfNullParingData: boolean = !(data as NullParingData instanceof NullParingData);
      /* 빈 객체인지 */
      const isNotEmptyObject: boolean = typeof data === 'object' && Object.keys(data).length > 0;

      this.open('POST', url, async, callback);

      if (isNotInstanceOfInvalidData && isNotInstanceOfNullParingData && isNotEmptyObject) {
        this.xhr.send(JSON.stringify(data));
      }
    } catch (e) {
      console.error(e);
      QueueManager.getInstance().toggle = true;
      this.abort();
    }
  }

  /**
   * <code>XMLHttpRequest</code> 객체를 인스턴스화 한다.
   * 에러가 발생할 경우 <code>undefined</code>로 초기화.
   * TODO: sample code
   */
  private instantiateXHR(): void {
    try {
      this.xhr = new XMLHttpRequest();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * <code>XMLHttpRequest</code> 인스턴스로 Connection을 열고, 콜백 함수를 설정한다.
   * 내부적으로 <code>XMLHttpRequest.open()</code>을 호출하며,
   * HTTP method 중 <code>GET</code>, <code>POST</code> 이외의 method는 Error가 발생한다.
   * <pre>
   *   try {
   *     open('GET', 'data?id=abc123', true, (xhr, response, hasTransmitted) => {
   *       if (hasTransmitted) {
   *         console.log('Transmission completed.');
   *       } else {
   *         console.log('Transmission failed.');
   *         console.log(xhr.status + ' ' + xhr.statusText);
   *       }
   *     });
   *   } catch (e) {
   *     console.error(e);
   *   }
   * </pre>
   * @param {HttpMethod} method - <a href="https://developer.mozilla.org/ko/docs/Web/HTTP/Methods">HTTP method</a>
   * @param {string} url - Connection 대상 URL
   * @param {boolean} [async = true] - 비동기 사용 여부
   * @param {CallbackFunction} [callback] - 콜백 함수. {@link CallbackFunction}
   * @throws Error - <code>GET</code>, <code>POST</code> 이외의 HTTP method 이용할 경우
   */
  private open(
      method: HttpMethod,
      url: string,
      async = true,
      callback?: CallbackFunction
  ) {
    this.instantiateXHR();

    const callbackListener = () => {
      let hasTransmitted = false; /* 데이터 전송완료 여부 */

      switch (this.xhr.readyState) {
        case this.xhr.UNSENT:
          /* open() has not been called yet. Request not initialized. */
          break;
        case this.xhr.OPENED:
          /* open() has been called. Server connection established. */
          break;
        case this.xhr.HEADERS_RECEIVED:
          /* send() has been called, and headers and status are available. */
          break;
        case this.xhr.LOADING:
          /* Processing request. */
          break;
        case this.xhr.DONE:
          /* Request finished and response is ready. */
          hasTransmitted = this.hasTransmitted(method, this.xhr.status);
          if (callback) {
            callback(this.xhr, this.xhr.response, hasTransmitted);
          }
          break;
        default:
          break;
      }
    };

    this.xhr.open(method, url, async);
    if (async) {
      /* XMLHttpRequest에서 timeout은 async의 경우에만 허용된다 */
      this.xhr.timeout = this._options.timeout;
    }
    this.xhr.withCredentials = this.options.withCredentials;

    switch (method) {
      case 'GET':
        if (this.options.setHeader) {
          this.xhr.setRequestHeader('ENP-Referrer', encodeURIComponent(document.referrer));
        }
        this.xhr.onreadystatechange = callbackListener;
        break;
      case 'POST':
        if (this.options.setHeader) {
          this.xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
          this.xhr.setRequestHeader('ENP-Referrer', encodeURIComponent(document.referrer));
        }
        this.xhr.onreadystatechange = callbackListener;
        break;
      case 'PUT':
        throw new NotSupportedError('PUT method');
      case 'DELETE':
        throw new NotSupportedError('DELETE method');
      default:
        throw new TypeError('Invalid HTTP method type or not supported yet.');
    }
  }

  /**
   * HTTP Method에 따른 전송 여부 확인
   * @param {HttpMethod} method
   * @param {number} xhrStatus
   * @return {boolean}
   * @throws NotSupportedError  - 아직 지원하지 않는 HTTP Method를 입력할 경우
   * @throws TypeError  - 허용하지 않은 HTTP Method를 입력할 경우.
   */
  private hasTransmitted(method: HttpMethod, xhrStatus: number): boolean {
    switch (method) {
      case 'GET':
        return xhrStatus === 200;
      case 'POST':
        return xhrStatus === 200 || xhrStatus === 201;
      case 'PUT':
        throw new NotSupportedError('PUT method');
      case 'DELETE':
        throw new NotSupportedError('DELETE method');
      default:
        throw new TypeError('Invalid HTTP method type or not supported yet.');
    }
  }

  /**
   * TODO: comment
   * 전송할 데이터가 비어 있는지 확인
   * @param {{}} data
   * @return {boolean}
   * <p><code>true</code> - 비어 있지 않음.</p><p><code>false</code> - 비어 있거나 오류 발생.</p>
   */
  private hasData(data: {}): boolean {
    try {
      return Value.isTypeOfUndefined(data) || Value.isNull(data)
          ? false
          : Object.keys(data).length > 0;
    } catch (e) {
      return false;
    }
  }
}
