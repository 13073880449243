/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-16
 */
import {HostingType} from "../types/GlobalEnums";
import {GeneralTree} from "../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "./Category";

/**
 * create on 2019-12-16.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class CategoryParser {
  /* 광고주의 호스팅사 타입 */
  protected hostingType: HostingType;

  protected rawCategoryData: any;

  protected constructor(hostingType: HostingType) {
    this.hostingType = hostingType;
  }

  /**
   * 카테고리 파싱.
   *
   * <b>NOTE :</b><br/>
   * 호스팅사에서 제공하는 API를 통해 카테고리를 가져오는 경우도 있기 때문에
   * 생성된 카테고리 트리를 외부에서 콜백으로 접근할 수 있도록 구현함.
   * @param {(tree: GeneralTree<Category>) => void} callback  파싱으로 생성된 트리를 외부에서 접근하는 콜백
   * @param {boolean} hasExpired  데이터 만료 여부
   */
  parse(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    this.parseAndMakeTree(callback, hasExpired);
  }

  /**
   * 광고주의 페이지에서 카테고리를 파싱 후 트리를 생성.
   * 데이터 저장 기간이 만료된 경우에만 새로 파싱한다.
   * @param {(tree: GeneralTree<Category>) => void} callback  파싱으로 생성된 트리를 외부에서 접근하는 콜백
   * @param {boolean} hasExpired  데이터 만료 여부
   */
  protected abstract parseAndMakeTree(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void;

  /**
   * 파싱한 카테고리에서 카테고리 계층을 생성.
   * @param rawCategory - 파싱된 카테고리
   * @return {GeneralTree<Category>} - 카테고리 계층
   */
  protected abstract createCategoryHierarchyTree(rawCategory: any): GeneralTree<Category>;
}