/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-08
 */

/**
 * create on 2020-10-08
 * <p> 퍄싱에 실패할 경우 임의로 반환하기 위해 생성 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NullParingData {

}