/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-05-22
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

export class Yo23DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (this.isMobile(this.commandOptions)) {
      return super.getImageSrc();
    }

    if (document.querySelectorAll('div.imgArea li.add_image > img[src]')[1]) {
      return (document.querySelectorAll('div.imgArea li.add_image > img[src]')[1] as HTMLImageElement).src;
    } else {
      return super.getImageSrc();
    }
  }
}

