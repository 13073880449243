/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-15
 */

import {CrmDao} from "../dao/CrmDao";
import {HostingType} from "../../../types/GlobalEnums";
import {JsonObject} from "../../../lib/json/JsonObject";
import {AJAXer} from "../../../lib/ajax/AJAXer";
import {CrmFactory} from "../factory/CrmFactory";
import {BehaviorsType} from "../types/CrmEnum";
import { EventUtil } from "../../../lib/event/EventUtil";

/**
 * create on 2021-02-15
 * <p> CRM 시스템에 전송될 데이터에 대한 Service 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export abstract class CrmService {
  private readonly ajaxer: AJAXer;
  protected crmDao: CrmDao | undefined;

  constructor(hostingType: HostingType) {
    this.crmDao = new CrmFactory().createDao(hostingType);
    this.ajaxer = AJAXer.getInstance();
  }

  /**
   * 로그인 화면인지 판별
   * @protected
   */
  protected abstract isLoginPage(): boolean;

  /**
   * 주문서 작성 화면인지 판별
   * @protected
   */
  protected abstract isOrderFormPage(): boolean;

  /**
   * 주문완료 화면인지 판별
   * @protected
   */
  protected abstract isOrderEndPage(): boolean;

  /**
   * common 데이터 전송시 부가적인 데이터 및 아이디 추가 후 반환
   */
  getCommonExtraData(): {} {
    const commonExtraJson = new JsonObject();

    // 구매완료 페이지일시 주문시도(orderTryRoot) 데이터 삭제
    if(this.isOrderEndPage()) {
      this.crmDao!.removeOrderTryData();
    }

    // 로그인 화면, 주문서 작성 화면일 시에는 CRM측에서 세팅한 extra데이터 파싱을 안한다
    if (!this.isLoginPage() && !this.isOrderFormPage()) {
      commonExtraJson.appendData(this.crmDao!.getCommonExtra());
    }

    /**
     * 주문 작성 화면에서 sessionStorage에 정보 셋팅
     */
    if (this.isOrderFormPage()) {
      this.crmDao!.setOrderFormSessionStorage();      
    }

    this.addUserInfo(commonExtraJson);
    return commonExtraJson['data'];
  }

  /**
   * cart 데이터 전송시 부가적인 데이터 및 아이디 추가 후 반환
   */
  getCartExtraData(): {} {
    const cartExtraJson = new JsonObject();
    this.addUserInfo(cartExtraJson);
    return cartExtraJson['data'];
  }

  /**
   * conversion 데이터 전송시 부가적인 데이터 및 아이디 추가 후 반환
   */
  getConversionExtraData(): {} {
    const convExtraJson = new JsonObject();
    this.addUserInfo(convExtraJson);
    this.addBankInfo(convExtraJson);
    this.addPayMethodInfo(convExtraJson);
    return convExtraJson['data'];
  }

  excuteScript(adverId: string, hostingType: HostingType): void {
    this.appendScript(adverId, hostingType);
    this.excuteCommand(adverId);
  }

  /**
   * 사용자 아이디정보를 가공 후 jsonObject에 추가
   * @param extraData
   * @private
   */
  private addUserInfo(extraData: JsonObject): void {
    const userInfo: {} = this.crmDao!.getUserId();
    if(userInfo && Object.keys(userInfo).length > 0) {
      extraData.appendData(userInfo);
    }
  }

  /**
   * 무통장 입금 주문전환 시 계좌번호 정보, 입금자명 jsonObject에 추가
   * @param 
   * @private 
   */
  private addBankInfo(extraData: JsonObject): void {
    const bankInfo: {} = this.crmDao!.getBankInfo();
    if (bankInfo && Object.keys(bankInfo).length > 0) {
      extraData.appendData(bankInfo);
    }
  }

  /**
   * 주문 전환 시 결제수단 수집
   * @param extraData 
   */
  private addPayMethodInfo(extraData: JsonObject): void {
    const payMethodInfo: {} = this.crmDao!.getPayMethodInfo();
    if (payMethodInfo && Object.keys(payMethodInfo).length > 0) {
      extraData.appendData(payMethodInfo);
    }
  }

  /**
   * Crm 스크립트 HTML Head에 추가
   * @param hostingType
   */
  private appendScript(adverId: string, hostingType: HostingType): void {
    window['mobtune'] = window['mobtune'] || function () {
      (window['mobtune']['q'] = window['mobtune']['q'] || []).push(arguments)
    };

    const mobtuneScriptElems = document.querySelectorAll('script[src*=mobtune_crm]');
    if (mobtuneScriptElems.length < 1) {
      //devAdverIds에 포함된 광고주인 경우 dev 경로의 crm script 호출
      const devAdverIds = ['shopbot2', 'bhjungmake'];

      const head = document.getElementsByTagName('script')[0].parentNode;
      const script = document.createElement('script');
      script.src = devAdverIds.some(id => id === adverId) ? this.crmDao!.getDevCrmScriptUrl(hostingType) : this.crmDao!.getCrmScriptUrl(hostingType);
      script.type = 'text/javascript';
      script.defer = true;
      script.async = true;
      head!.appendChild(script);
    }
  }

  /**
   *
   * @param adverId
   */
  private excuteCommand(adverId: string): void {
    window['mobtune'](adverId, BehaviorsType.COMMON);
  }
}
