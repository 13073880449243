/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-03
 */
import {DataParser} from "../DataParser";
import {HostingType} from "../../types/GlobalEnums";
import {GlobalVariables} from "../../types/GlobalVariables";
import {JsonObject} from "../../lib/json/JsonObject";
import {Value} from "../../lib/value/Value";
import {NumberUtil} from "../../lib/common/NumberUtil";

/**
 * create on 2020-04-03.
 * <p> 아임웹 </p>
 * <p> 아임웹의 경우는 수집할 항목을 <code>ENP_VAR</code> 전역 객체에 저장시키도록 가이드 한다.</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class EtcDataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  private ENP_OBJECT_NAME = 'ENP_VAR';

  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.ETC);
  }

  protected parseCategory(currentCategoryCode: string): void {
    this.category.topCategory = GlobalVariables.unknownCategory;
    this.category.firstSubCategory = GlobalVariables.unknownCategory;
    this.category.secondSubCategory = GlobalVariables.unknownCategory;
    this.category.thirdSubCategory = GlobalVariables.unknownCategory;
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  protected getShopCollectData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['collect'];
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': targetObj['productCode'],
      'productName': targetObj['productName'],
      'price': targetObj['price'],
      'productUrl': window.location.href,
      'dcPrice': targetObj['dcPrice'],
      'soldOut': targetObj['soldOut'],
      'imageUrl': targetObj['imageUrl'],
      'topCategory': Value.getValue(targetObj['topCategory'], this.category.topCategory),
      'firstSubCategory': Value.getValue(targetObj['firstSubCategory'], this.category.firstSubCategory),
      'secondSubCategory': Value.getValue(targetObj['secondSubCategory'], this.category.secondSubCategory),
      'thirdSubCategory': Value.getValue(targetObj['thirdSubCategory'], this.category.thirdSubCategory)
    };

    return new JsonObject(parsedData);
  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];

    for (let i = 0; i < parsedProducts.length; i++) {
      products.push({
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'soldOut': parsedProducts[i]['soldOut'],
        'imageUrl': parsedProducts[i]['imageUrl'],
        'topCategory': Value.getValue(parsedProducts[i]['topCategory'], this.category.topCategory),
        'firstSubCategory': Value.getValue(parsedProducts[i]['firstSubCategory'], this.category.firstSubCategory),
        'secondSubCategory': Value.getValue(parsedProducts[i]['secondSubCategory'], this.category.secondSubCategory),
        'thirdSubCategory': Value.getValue(parsedProducts[i]['thirdSubCategory'], this.category.thirdSubCategory)
      });
    }

    const parsedData: {} = {
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': targetObj['totalPrice'],
      'totalQty': targetObj['totalQty']
    };

    return new JsonObject(parsedData);
  }

  /**
   * 간편결제를 통한 전환 데이터를 파싱하여 리턴.
   * @return {{}} - 파싱된 데이터
   */
  protected getParsedPayConvData(): {} {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalPrice: number = 0;
    let totalQty: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'soldOut': parsedProducts[i]['soldOut'],
        'imageUrl': parsedProducts[i]['imageUrl'],
        'topCategory': Value.getValue(parsedProducts[i]['topCategory'], this.category.topCategory),
        'firstSubCategory': Value.getValue(parsedProducts[i]['firstSubCategory'], this.category.firstSubCategory),
        'secondSubCategory': Value.getValue(parsedProducts[i]['secondSubCategory'], this.category.secondSubCategory),
        'thirdSubCategory': Value.getValue(parsedProducts[i]['thirdSubCategory'], this.category.thirdSubCategory)
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseRationalNumber(products[i]['price']);
      totalQty += NumberUtil.parseInteger(products[i]['qty']);
    }

    return {
      'product': products,
      'adverId': this.adverId,
      'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
      'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
    };
  }
}
