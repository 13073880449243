/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-15
 */

import {CrmService} from "../CrmService";
import {HostingType} from "../../../../types/GlobalEnums";

/**
 * create on 2021-02-15
 * <p> 카페24 Crm Service </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Cafe24CrmService extends CrmService {
  constructor() {
    super(HostingType.CAFE24_SMART);
  }

  protected isLoginPage(): boolean {
    return location.pathname === '/member/login.html';
  }

  protected isOrderFormPage(): boolean {
    return location.pathname === '/order/orderform.html';
  }

  protected isOrderEndPage(): boolean {
    return location.pathname === 'order/order_result.html';
  }
}
