import { NumberUtil } from "../../../lib/common/NumberUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-12-14
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-12-14.
 * <p> onlymoonyw 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class OnlymoonywDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const orderedProducts = document.querySelectorAll('div.base-table > table > tbody tr.nobd');

    const parsedProduct: Array<{}> = [];

    let totalQty = 0;

    for (let i = 0; i < orderedProducts.length; i++) {
      const productAnchorElem = orderedProducts[i].querySelector('td:nth-child(2) > div.tb-left > a') as HTMLAnchorElement;

      const qty = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(3)')!.textContent!.trim());
      const price = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(4)')!.textContent!.trim());

      parsedProduct.push({
        'productCode': new QueryStringer(productAnchorElem.search.substr(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent,
        'price': price / qty,
        'qty': qty
      })

      totalQty += qty;
    }

    jsonObj.addData('product', parsedProduct);
    jsonObj.addData('totalQty', totalQty);
    jsonObj.addData('productCnt', orderedProducts.length);

    return jsonObj;
  }
}
