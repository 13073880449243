/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-27
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-11-27
 * <p> Dapojang - http://www.xn--2j1bp70bn3i.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class DapojangDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions) ? super.getPayConvDataFromBasketPage() : this.getWebPayConvDataFromBasketPage();
  }

  protected getParsedTotalPrice(): number {
    if (this.isOrderPage()) {
      return this.isMobile(this.commandOptions) ? super.getParsedTotalPrice() : NumberUtil.parseRationalNumber(document.querySelector('div.table1 tr:nth-child(11) strong')!.textContent!.trim());
    } else {
      return super.getParsedTotalPrice();
    }
  }

  private getWebPayConvDataFromBasketPage() {
    const parsedProducts:Array<{}> = [];
    let totalQty = 0;
    const productNameList = this.getSelectorAll(['td.this-product > div > a[href*="goods"]']);
    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseNumber(this.getSelectorAll(['td.count.this-product input'])[i].textContent!.trim());
      parsedProducts.push({
        'productCode': new QueryStringer((productNameList[i] as HTMLAnchorElement).search.substring(1)).getParam('goodsNo'),
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(this.getSelectorAll(['.ta-c.this-product .price'])[i].textContent!.trim())
      });

      totalQty += qty;
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      /* 배송비 포함된 금액을 가져오지 못하면 상품 가격들의 합으로 초기화 */
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('strong#totalSettlePrice')!.textContent!.trim()),
      'totalQty': totalQty
    };
  }
}