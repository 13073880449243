/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2019. 4. 23
 */
import { Config } from './Config';

/**
 * TODO 주석
 * create on 2010-06-01
 * <p> 리뷰정보 수집과 관련된 Config </p>
 * <p> {@link Config} 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ReviewCollectorConfig extends Config {
  constructor(adverId: string, options?: {}) {
    super(adverId, options);
    this._baseUrl = 'tk.mediacategory.com/aggregate/mssg/review';
    this._url = this.protocol + this.baseUrl;
    this._callback = (
      xhr: XMLHttpRequest,
      response: any,
      hasTransmitted: boolean
    ) => {
      // console.log(response);
    };
  }
}
