/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-03-23
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2023-03-23.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class TprojectDataParser extends SelfHostedDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    jsonData.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonData;
  }

  protected getShopCollectData(): JsonObject {
    const jsonData = super.getShopCollectData();
    jsonData.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonData;
  }

  protected getConversionData(): JsonObject {
    const jsonData: JsonObject = super.getConversionData();
    jsonData.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonData;
  }

  private getSiteCodeByAdverId(): string {
    switch (this.adverId) {
      case 'tproject':
        return '7c6f1816ffb8f0dfa95afae8ebcab34d';
      case 'mproject':
        return '19490cc94fa9a31268b8cea9c7b33c4c';
      default:
        return '';
    }
  }
}
