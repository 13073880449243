
/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import { JsonObject } from '../../../lib/json/JsonObject';
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";


/**
 * create on 2020-03-09.
 * <p> wvproject 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class WvprojectDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalQty = 0;
    for (let i = 0; i < productAmount; i++) {
      const price = NumberUtil.parseNumber(document.querySelectorAll('form[name="forms"]>figure>figcaption>p.optionNm b')[i].textContent!.trim());
      const qty = NumberUtil.parseNumber((document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('span.txt-price')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }
}
