/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-07-28
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { EventUtil } from "../../../lib/event/EventUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-07-28
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Horangi0330DataParser extends Cafe24SmartDataParser {
  protected getShopCollectData(): JsonObject {
    //반응형 모바일에서 상품 스크립트를 호출하면서 npay observer 호출
    this.mobileCustomNpayCall();
    return super.getShopCollectData();
  }

  //해당광고주만 npay custom으로 인해 임시 조치
  private mobileCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector('.npay_btn')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'B', 'paySys': 'naverPay' }
      ]);
    });
  }

}