/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-28
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-28.
 * <p> Andersson 광고주 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SmartowlDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {

    const rawConvData: {} = window['EC_FRONT_EXTERNAL_SCRIPT_VARIABLE_DATA'];
    const rawProductDataArr: Array<{}> = rawConvData['order_product'];
    const parsedProducts: Array<{}> = [];
    const parsedTotalPrice: number = this.getParsedTotalPrice();
    let totalQty = 0;
    let totalPrice = 0;
    let price = 0;

    for (let i = 0; i < rawProductDataArr.length; i++) {
      
      const productCode: string = NumberUtil.isNumber(rawProductDataArr[i]['product_no'])
          ? rawProductDataArr[i]['product_no'].toString()
          : rawProductDataArr[i]['product_no'];

          if (this.isMobile(this.commandOptions)) {
            price = NumberUtil.parseNumber(document.querySelectorAll('div.prdInfo.xans-record- p.prdTotal strong')[i].textContent!.replace(/\D/g, ''));
          } else {
            price= NumberUtil.parseNumber(document.querySelectorAll('tr.xans-record- td.right div:nth-child(2) strong')[i].textContent!.replace(/\D/g, ''));
          }
      parsedProducts.push({
        'productCode': productCode,
        'productName': rawProductDataArr[i]['product_name'],
        'qty': rawProductDataArr[i]['quantity'],
        'price': price
      });

      totalQty += rawProductDataArr[i]['quantity'];
      totalPrice += price;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': rawConvData['order_id'],
      'totalPrice': parsedTotalPrice === 0 || NumberUtil.isNaN(parsedTotalPrice)
          ? (
              rawConvData['payed_amount'] 
              ? rawConvData['payed_amount'] : totalPrice
            )
          : parsedTotalPrice.toString(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);

  }
}
