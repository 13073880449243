/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { ValidationRuleImpl } from '../ValidationRuleImpl';
import { StringUtil } from '../../../lib/common/StringUtil';

/**
 * create on 2019-09-30.
 * <p> URL 형식에 대한 검증 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class UrlValidRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    return StringUtil.getPermittedUrl(property as string);
  }
}
