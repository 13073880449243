/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-03-25
 */

import {CrmService} from "../CrmService";
import {HostingType} from "../../../../types/GlobalEnums";
import {SelfHostedCrmDao} from "../../dao/hosting/SelfHostedCrmDao";

/**
 * create on 2021-03-25
 * <p> 독립몰 Crm Service </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class SelfHostedCrmService extends CrmService {
  constructor() {
    super(HostingType.SELF_HOSTED);
  }

  protected isLoginPage(): boolean {
    return false;
  }

  protected isOrderFormPage(): boolean {
    return false;
  }

  protected isOrderEndPage(): boolean {
    return location.href.search((this.crmDao! as SelfHostedCrmDao).getOrderEndPageUrl()) > -1;
  }
}
