/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Punycode} from '../../lib/codec/Punycode';
import {Utf8} from '../../lib/codec/Utf8';
import {Base64} from '../../lib/codec/Base64';
import {EventType} from '../../types/GlobalEnums';
import {Config} from './config/Config';
import {ClientInfo} from '../../lib/common/ClientInfo';
import {Transmitter} from './core/Transmitter';
import {DataParser} from '../../dataParser/DataParser';
import {AdverConfigJsonMapper} from "../../adverConfigJsonMapper/AdverConfigJsonMapper";
import {StringUtil} from "../../lib/common/StringUtil";
import {ProductStorage} from "../productHistory/ProductStorage";
import {KeywordFetcher} from '../keyword/KeywordFetcher';

/**
 * create on 2019-08-02.
 * <p> 장바구니 수집 </p>
 * <p> {@link Transmitter} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class WishCollector extends Transmitter {
  protected readonly _punycode: Punycode;
  protected readonly _utf8: Utf8;
  protected readonly _base64: Base64;

  constructor(eventType: EventType, config: Config, dataParser: DataParser) {
    super(eventType, config, dataParser);
    this._punycode = new Punycode();
    this._utf8 = new Utf8();
    this._base64 = new Base64();
  }

  protected preSend(): void {
    const clientInfo: ClientInfo = ClientInfo.getInstance();
    const adverDomain = StringUtil.getDomainFromUrl(window.location.href);

    this.addExtraData(
      {
        'adverId': this.config.adverId,
        'domain': adverDomain,
        'charSet': document.characterSet,
        'referrer': document.referrer,
        'referrerDomain': StringUtil.getReferrerDomain(),
        'cookieEnabled': clientInfo.cookieEnabled ? clientInfo.cookieEnabled : true,
        'nation': clientInfo.nation,
        'siteCode': AdverConfigJsonMapper.getInstance(this.config.adverId).getSiteCode(),
        'solutionType': AdverConfigJsonMapper.getInstance(this.config.adverId).getHostingType()
      },
      false
    );

    this.addExtraData(new KeywordFetcher().getWishKeyword(this.eventType, this.jsonObject.data['productCode']));
  }

  protected finish(): void {
    /* localStorage에 상품코드 저장 */
    this.saveProductCode(this.jsonObject.data['productCode']);

    /* 데이터 전송 */
    super.finish();
  }

  /**
   * localStorage에 상품코드 저장
   * @param {string} productCode  - 저장할 상품코드
   */
  private saveProductCode(productCode: string): void {
    if (productCode) {
      new ProductStorage().save(this.eventType, [productCode.toString()]);
    }
  }
}
