/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 */
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { JsonObject } from '../../../lib/json/JsonObject';
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';

/**
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class AnygearDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div.table-cart tr td div.tb-left a.tb-bold')[i].textContent!.trim();
    }

    jsonObj['totalPrice'] = document.querySelector('div.table-cart tfoot strong')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();

    jsonObj['totalPrice'] = document.querySelector('div.price-total-info-bottom p span.txt-price')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
   protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(3) div.tb-center')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(5) div.tb-center.tb-bold')[i].textContent!.replace(/\D/g, '').trim()
      totalQty += NumberUtil.stringToNumber(qty);
    }

    jsonObj.data['totalQty'] = totalQty;

    return jsonObj;
  }
}
