/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-30
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-03-30.
 * <p> BlingCook 광고주 파싱 </p>
 * <p> 다른 광고주와 다르게 상세페이지 URL이 기존(product)가 아닌(surl)임 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BlingcookDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 블링쿡(BlingCook) 상품상세 페이지 URL이 기존의 product가 아니라 surl 여서 별도 생성
   */
  protected isProductPage(): boolean {
    try {
      return this.isMobile(this.commandOptions)
          ? window.location.pathname.substring(1).split('/')[0] === "product"
          : window.location.pathname.substring(1).split('/')[0] === 'surl';
    } catch (e) {
      return false;
    }
  }
}
