/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-10
 */

import {CrmDao} from "../CrmDao";
import {CrmStorageType} from "../../types/CrmEnum";
import {StringUtil} from "../../../../lib/common/StringUtil";
import { EventUtil } from "../../../../lib/event/EventUtil";

/**
 * create on 2021-02-10
 * <p> Cafe24 CRM 데이터 Dao </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Cafe24CrmDao extends CrmDao {
  constructor() {
    super(CrmStorageType.SESSION);
  }

  /**
   * 회원 아이디 스토리지에서 수집 후 반환
   * @return string : 회원 아이디
   */
  getUserId(): {} {
    try {
      const memberJsonData = JSON.parse(atob(this.storage!.getItem('mobtune_member_common')!));
      return memberJsonData['userId'] && StringUtil.isNotEmpty(memberJsonData['userId'])
          ? { 'userId': memberJsonData['userId'] } : {};
    } catch (e) {
      return {};
    }
  }

  /**
   * 무통장 주문 전환 시 계좌번호 정보, 입금자명 수집 후 반환
   * @returns 
   */
  getBankInfo(): {} {
    try {
      const bankInfoText = sessionStorage.getItem('mobonBankInfo')!;
      const bankSplit = bankInfoText.split(':');
      let bankInfo = {};
      
      bankInfo['bankNo'] = bankSplit[1]; //입금계좌
      bankInfo['bankAccountHolder'] = bankSplit[2]; //예금주명
      bankInfo['bank'] = bankSplit[3];  //입금은행
      bankInfo['bankDepositor'] = bankSplit[5].trim();  //입금자명

      return bankInfo;
    } catch {
      return {};
    }
  }

  /**
   * 결제수단 수집
   */
  getPayMethodInfo(): {} {
    try {
      return sessionStorage.getItem('mobonPayMethod') ? { paymentMethod: sessionStorage.getItem('mobonPayMethod') } : {};
    } catch {
      return {}
    }
  }

  /**
   * 주문화면에서 결제 버튼 누를 시 sessionStorage
   */
  setOrderFormSessionStorage(): void {
    try {
      const target = document.querySelector('#btn_payment')!;
      EventUtil.addEvent(target, 'click', this.orderFormInfoSetting);
    } catch { }
  }

  private orderFormInfoSetting(): void {
    try {
      /**
       * 적립금 또는 쿠폰으로 0원 결제시 실결제금액이 없어 선택한 버튼이 disabled 될 경우 은행 및 결제수단 정보 추가안함
       */
      const totalPriceZero: boolean = document.querySelector('[name="addr_paymethod"]:checked')!['disabled'];
      if (document.querySelector('[name="addr_paymethod"]:checked')!['value'] === 'cash' && !totalPriceZero) {
        const bankText = (document.querySelector('#bankaccount') as HTMLSelectElement).selectedOptions[0]['value'] + ':' + document.querySelector('#pname')!['value'];
        sessionStorage.setItem('mobonBankInfo', bankText);
      } else if (sessionStorage.getItem('mobonBankInfo')) {
        sessionStorage.removeItem('mobonBankInfo');
      }
      if(!totalPriceZero) sessionStorage.setItem('mobonPayMethod', document.querySelector('[name="addr_paymethod"]:checked')!['value']);
    } catch { }
  }
}
