/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-08-25.
 * <p> Agent 프로젝트에서 사용되는 열거형 정의 </p>
 *
 * @version 1.0
 * @author sghwang
 */
/* 커맨드 타입 */
export enum CommandType {
  CREATE = 'create',
  SET = 'set',
  SEND = 'send'
}

/* 이벤트 타입 */
export enum EventType {
  COMMON = 'common',
  COLLECT = 'collect',
  CART = 'cart',
  WISH = 'wish',
  CONVERSION = 'conversion',
  REVIEW = 'review',
  TEST = 'test'
}

/* 호스팅사 타입 */
export enum HostingType {
  CAFE24_SMART = 'cafe24_smart', // Cafe24 스마트
  MAKESHOP_D2 = 'makeshop_d2', // 메이크샵 D2
  MAKESHOP_D4 = 'makeshop_d4', // 메이크샵 D4
  FIRSTMALL = 'firstmall', // 퍼스트몰
  GODOMALL_RENT = 'godomall_rent', // 고도몰 임대형
  GODOMALL_SELF = 'godomall_self', // 고도몰 독립형
  IMWEB = 'imweb', // 아임웹
  SELF_HOSTED = 'self_hosted', // 독립몰
  ETC = 'etc', // 기타
  NAVER_SMART = 'naver_smart',
  SHOPBY ='shopby' //샵바이
}

/* 이벤트 타입 */
export enum ArgumentType {
  COMMAND_TYPE = 'commandType',
  EVENT_TYPE = 'eventType',
  ADVER_ID = 'adverId',
  OPTIONS = 'options',
  DATA = 'data'
}

/* 클라이언트의 디바이스 타입 */
export enum DeviceType {
  MOBILE = 'M', // Mobile. e.g phone, tablet
  WEB = 'W', // Web. e.g pc
  BOTH = 'B' // Mobile and Web.
}

/* 간편 결제 시스템 타입 */
export enum PaySystemType {
  NAVER_PAY = 'naverPay',
  KAKAO_PAY = 'kakaoPay',
  PAYCO = 'payco'
}

/* 상품 타겟팅 타입 */
export enum ProductTargetingType {
  CART = 'cart', // 장바구니 버튼
  WISH = 'wish' // 찜 버튼
}

/* API 이름 */
export enum ApiName {
  I_ORB = 'iOrb'
}

/* 장바구니 타입 */
export enum CartType {
  OWN_CART = 'ownCart',
  OWN_WISH = 'ownWish',
  NAVER_CART = 'naverCart',
  NAVER_WISH = 'naverWish',
  NAVER_TALK = 'naverTalk'
}

/* 키워드 타입 */
export enum KeywordType {
  EXTERNAL = 'external',  // 포털 등 외부
  INTERNAL = 'internal',  // 광고주 사이트 내부 검색 상품 키워드
  COMMON = 'common' // 광고주 내부 검색 공통 키워드
}

/* 키워드 전환 타입 */
export enum KeywordSessionType {
  SESSION = 'session',  // 세션
  INDIRECT = 'indirect' // 간접
}

/* 전환 타입 */
export enum ConversionType {
  PRODUCT = 'product',  // 상품
  ETC = 'etc',          // 전환완료
  JOIN = 'join',        // 회원가입
  LOGIN = 'login',      // 로그인
  CALL = 'call'         // 전화연결
}

/* 인코딩 목록 */
export enum EncodingList {
  ASCII = 'ascii',
  UNICODE = 'unicode',
  UTF_8 = 'utf-8',
  EUC_KR = 'euc-kr',
  ISO_8859_8 = 'iso-8859-8',
  ISO_8859_16 = 'iso-8859-16'
}

export enum TelegramParseMode {
  MARKDOWN = 'MarkdownV2',
  HTML = 'HTML'
}
