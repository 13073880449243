/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-06-17
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2021-06-17
 * <p> page1 - 1pagestudio.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Page1DataParser extends Cafe24SmartDataParser {
  protected getImageSrc(): string {
    return this.isMobile(this.commandOptions) ? super.getImageSrc()
        : (document.querySelector('div.keyImg div.listImg li:nth-child(2) img') as HTMLImageElement).src
  }
}
