/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-25
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * @version 1.0
 * @author sjchun
 */
export abstract class StockcomDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let jsonData = super.getShopCollectData();
    jsonData.addData('brand', document.querySelector('.goods_tit .goods_brand_name') ?
      document.querySelector('.goods_tit .goods_brand_name')!.textContent!.trim()
      : document.querySelector('.brand_name .brand_more strong')!.textContent!.trim()
    );
    return jsonData;
  }
}
