/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-27
 */

import * as PaySystemInfo from "../../service/paySystem/paysystem.info.json";
import {PaySystemType} from "../../types/GlobalEnums";
import {NaverCartTargeting} from "../../service/naverTargeting/NaverCartTargeting";
import {NaverWishTargeting} from "../../service/naverTargeting/NaverWishTargeting";
import {NaverTalkTargeting} from "../../service/naverTargeting/NaverTalkTargeting";
import {PaySystem} from "../../service/paySystem/PaySystem";

/**
 * create on 2020-03-27.
 * @class PayBtnObserverEventData
 * <p> 버튼에 추가할 이벤트에 대한 기본 정보를 담고있는 클래스 </p>
 * <pre>
 *   1. eventListener : 버튼에 등록될 이벤트 콜백함수
 *   2. eventName : 버튼에 등록될 이벤트 타입(click, load...)
 *   3. attribute : 버튼을 식별할때 쓰이는 속성
 *   4. regExp : 버튼에 속성에서 검색할 정규표현식
 * </pre>
 * @version 1.0
 * @author mgpark
 */
export class PayBtnObserverEventData {
  eventListener: EventListener; // 버튼에 등록될 이벤트 콜백함수
  eventName: string;            // 버튼에 등록될 이벤트 타입(click, load...)
  attribute: string;            // 버튼을 식별할때 쓰이는 속성
  regExp: RegExp;               // 버튼에 속성에서 검색할 정규표현식

  constructor() {
    this.eventListener = () => {
    };
    this.eventName = ""
    this.attribute = "id";
    this.regExp = /()/;
  }

  private setPayEventData(eventListener: EventListener, eventName: string, attribute: string, regExp: RegExp): PayBtnObserverEventData {
    let eventData: PayBtnObserverEventData = new PayBtnObserverEventData();
    eventData.eventListener = eventListener;
    eventData.eventName = eventName;
    eventData.attribute = attribute;
    eventData.regExp = regExp;
    return eventData;
  }

  /**
   * @method getPayEventData
   * <p>페이시스템에 따라 버튼들의 이벤트에대한 데이터 Array를 가져옴</p>
   * @param paySystem
   * @param paySystemType
   * @param adverId
   */
  getPayEventData(paySystem: PaySystem, paySystemType: string, adverId: string): Array<PayBtnObserverEventData> {
    let dataArr = new Array<PayBtnObserverEventData>();

    switch (paySystemType) {
      case PaySystemType.NAVER_PAY:
        dataArr = this.getNPayEventData(paySystem, adverId);
        break;
      case PaySystemType.KAKAO_PAY:
        dataArr = this.getKakaoPayEventData();
        break;
      case PaySystemType.PAYCO:
        dataArr = this.getPaycoEventData();
        break;
    }

    return dataArr;
  }

  /**
   * @method getNPayEventData
   * 네이버페이 버튼 구매, 장바구니(찜), 톡톡 버튼에 등록될 각각의 이벤트 정보를 담고있는 배열을 생성
   * @param paySystem
   * @param adverId
   */
  private getNPayEventData(paySystem: PaySystem, adverId: string): Array<PayBtnObserverEventData> {
    let eventDataArr: Array<PayBtnObserverEventData> = new Array<PayBtnObserverEventData>();


    //구매 버튼 이벤트 정보
    eventDataArr.push(this.setPayEventData(
        paySystem.paySystemConvEventListener,
        PaySystemInfo[PaySystemType.NAVER_PAY].eventName,
        "id",
        /NPAY_BUY_LINK_ID/
    ));

    //장바구니, 찜버튼 이벤트 정보
    eventDataArr.push(this.setPayEventData(
        new NaverCartTargeting(adverId).eventListener,
        PaySystemInfo[PaySystemType.NAVER_PAY].eventName,
        "id",
        /NPAY_CART_LINK_ID/
    ));

    //찜버튼 이벤트 정보
    eventDataArr.push(this.setPayEventData(
        new NaverWishTargeting(adverId).eventListener,
        PaySystemInfo[PaySystemType.NAVER_PAY].eventName,
        "id",
        /NPAY_WISH_LINK_ID/
    ));

    //톡톡 버튼 이벤트 정보
    eventDataArr.push(this.setPayEventData(
        new NaverTalkTargeting(adverId).eventListener,
        PaySystemInfo[PaySystemType.NAVER_PAY].eventName,
        "id",
        /NPAY_TALK_LINK_ID/
    ));

    return eventDataArr;
  }

  /**
   * @method getKakaoPayEventData
   * 추후 카카오페이 버튼 이벤트 Observer 개발시 로직 추가
   */
  private getKakaoPayEventData(): Array<PayBtnObserverEventData> {
    let eventDataArr: Array<PayBtnObserverEventData> = new Array<PayBtnObserverEventData>();
    // 추후 카카오페이 버튼 이벤트 Observer에 대한 기능 구현시 버튼 이벤트 정보 및 버튼들을 구분할 수 있는 객체 생성 로직 구현예정
    return eventDataArr;
  }

  private getPaycoEventData(): Array<PayBtnObserverEventData> {
    let eventDataArr: Array<PayBtnObserverEventData> = new Array<PayBtnObserverEventData>();
    // 추후 페이코 버튼 이벤트 Observer에 대한 기능 구현시 버튼 이벤트 정보 및 버튼들을 구분할 수 있는 객체 생성 로직 구현예정
    return eventDataArr;
  }

}
