/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-27
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2022-09-27.
 * <p> Wfthink 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WfthinkDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    const rawProductDataArr: Array<{}> = window['jgo_products'];
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;
    let totalPrice = 0;
    if(!this.isMobile(this.commandOptions)){
      totalPrice =NumberUtil.parseNumber(document.querySelector('div.box.txtEm.txt16 span')!.textContent!.replace(/\D/g, ''));
    }else{
      totalPrice =NumberUtil.parseNumber(document.querySelector('div.total.order p strong')!.textContent!.replace(/\D/g, ''));
    }

    for (let i = 0; i < rawProductDataArr.length; i++) {
      this.parseCategory(rawProductDataArr[i]['category']);
      parsedProducts.push({
        'productCode': rawProductDataArr[i]['id'],
        'productName': rawProductDataArr[i]['name'],
        'qty': rawProductDataArr[i]['quantity'],
        'price': rawProductDataArr[i]['price'],
      });
      totalQty += NumberUtil.parseNumber(rawProductDataArr[i]['quantity']);
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice':totalPrice,
      'totalQty': totalQty
    };
  }
  
}
