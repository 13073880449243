/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-15
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-04-15
 * <p> jongjong - jongjong.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class JongjongDataParser extends Cafe24SmartDataParser {
  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      const priceObj = super.getProductPrice();
      priceObj['dcPrice'] = this.isMobile(this.commandOptions)
          ? document.querySelector('#span_product_price_mobile_text') ? NumberUtil.parseRationalNumber(document.querySelector('#span_product_price_mobile_text')!.textContent!.trim()) : 0
          : document.querySelector('#span_product_price_sale') ? NumberUtil.parseRationalNumber(document.querySelector('#span_product_price_sale')!.textContent!.trim()) : 0;
      return priceObj;
    }catch (e) {
      return super.getProductPrice();
    }
  }
}
