/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-21
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-07-21
 * <p> build - build.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WellicaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getConversionData(): JsonObject {
    const superData : JsonObject = super.getConversionData();
    superData.data['totalPrice'] = document.querySelector('div#mCafe24Order div div table tr:nth-child(2) td.right span')!.textContent!.trim().replace(/\D/g,'');
    return superData;
  }

}