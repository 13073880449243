/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-05
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-10-05
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class StylepariDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('dl.item dt a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('dl.item dt a')[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('dl.item > dd')[i].textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.childNodes[0].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div#productDetail div.thumb img')) {
      return (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src;
    } else if (document.querySelector('div#detail-item > .info-wrap > .items > img')) {
      return (document.querySelector('div#detail-item > .info-wrap > .items > img') as HTMLImageElement).src;
    } else {
      return super.getImageUrl();
    }
  }
}