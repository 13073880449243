/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-04-02.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NflexDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span, table.cart_box_a tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left:nth-child(1) > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('tfoot strong')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    if (document.querySelector('div#productDetail div.thumb-info div.thumb-wrap img')) {
      return (document.querySelector('div#productDetail div.thumb-info div.thumb-wrap img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }
}
