/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-22
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2020-10-22.
 * <p> junjewelry 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Junjewelry05DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    const priceObj = this.getPriceInfoAtProductPage();

    jsonObj.replaceData("price", priceObj['price']);
    jsonObj.replaceData("dcPrice", priceObj['dcPrice']);

    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();

    const priceObj = this.getPriceInfoAtProductPage();
    jsonObj['product'][0]['price'] = priceObj['price'];
    jsonObj['product'][0]['dcPrice'] = priceObj['dcPrice'];

    return jsonObj;
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const orderedProducts = document.querySelectorAll("#order > div.page-wrap > div.order-list > table > tbody tr");

    let totalQty = 0;
    const parsedProducts = jsonObj.getDataByName("product");

    for (let i = 0; i < orderedProducts.length; i++) {
      // 자식 Element 숫자로 상품항목의 TR Element인지 구분
      if (orderedProducts[i].childElementCount < 5) {
        continue;
      }

      const productAnchorElem = orderedProducts[i].querySelector("td:nth-child(2) > div > a") as HTMLAnchorElement;
      const qty = orderedProducts[i].querySelector("td:nth-child(3) > div")!.textContent!.trim().replace(/\D/g, "");

      parsedProducts.push({
        "productCode": new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        "productName": productAnchorElem.textContent!.trim(),
        "qty": qty,
        "price": orderedProducts[i].querySelector("td:nth-child(4) > div")!.textContent!.trim().replace(/\D/g, "")
      })

      totalQty += NumberUtil.parseInteger(qty);
    }

    jsonObj.replaceData("product", parsedProducts);
    jsonObj.replaceData("totalQty", totalQty);

    return jsonObj;
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('.font_basketD')[i] as HTMLParagraphElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseRationalNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('.font_basketA a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobileConversionData(): JsonObject {
    const jsonObj = super.getMobileConversionData();

    jsonObj.replaceData("product", window[this.ENP_OBJECT_NAME]['product']);
    jsonObj.addData("totalQty", window[this.ENP_OBJECT_NAME]['totalQty']);

    return jsonObj;
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents .font_shopdetailA')!.textContent!.trim()
            : document.querySelector('#form1 div.info > h3')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  private getPriceInfoAtProductPage(): {} {
    if (super.isMobile(this.commandOptions)) {
      return {
        price : (document.querySelector("#form1 > input[name=price]") as HTMLInputElement).value.replace(/\D/g, ""),
        dcPrice : (document.querySelector("#form1 > input[name=disprice]") as HTMLInputElement).value !== ""
            ? (document.querySelector("#form1 > input[name=disprice]") as HTMLInputElement).value.replace(/\D/g, "")
            : (document.querySelector("#form1 > input[name=price]") as HTMLInputElement).value.replace(/\D/g, "")
      }
    } else {
      return {
        price : (document.querySelector('form #price') as HTMLInputElement).value.replace(/\D/g, ''),
        dcPrice : (document.querySelector('form #disprice') as HTMLInputElement).value.replace(/\D/g, '')
      }
    }
  }
}
