/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-09-28
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";


/**
 * create on 2020-09-28.
 * <p> Jm057 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Jm057DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.detailImage_wrap > div.detailImage > div > img')) {
        // PC WEB
        return (document.querySelector('div.detailImage_wrap > div.detailImage > div > img') as HTMLImageElement).src;
    } else {
        // Mobile
        return (document.querySelector('div.thumb-info > div.thumb-wrap > div.thumb > img') as HTMLImageElement).src;
    }
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productElem = document.querySelectorAll('div#order > div.page-wrap > div.order-list > table > tbody tr');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productElem.length; i++) {
      const productAnchorElem = productElem[i].children[1].querySelector('div > a') as HTMLAnchorElement;
      const qty = (productElem[i].children[2].querySelector('div') as HTMLDivElement).textContent!.replace(/\D/g, '').trim();
      const price = (productElem[i].children[3].querySelector('div') as HTMLDivElement).textContent!.replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents > main > section > div.shopdetailInfoBottom > div.nameP')!.textContent!.trim()
            : document.querySelector('#productDetail > div.page-body > div.thumb-info > div.titleArea > h2')!.childNodes[0]!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }
}
