/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-09-09
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';



/**
 * create on 2020-09-09.
 * <p> Moonbanggu 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class MoonbangguDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();
    jsonObj.data['productCode'] = document.querySelector('form#form1')!['brandcode']['value'];
    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productCode'] = document.querySelector('form#form1')!['brandcode']['value'];
    }

    return jsonObj;
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productCode'] = (document.querySelectorAll('div.table-cart tbody > input[name="brandcode"]')[i] as HTMLInputElement).value;
    }

    jsonObj['totalPrice'] = document.querySelector('span.MK_total_price span')!.textContent!.replace(/\D/g, '').trim();

    return jsonObj;
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(3) b')[i]!.textContent!.replace(/\D/g, '').trim();
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="brandcode"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('form[name="forms"]>figure>p.titleT>a')[i]!.childNodes[2]!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.MK_total_price')!.textContent!.replace(/\D/g, '').trim(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      const price: string = document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['price'] = price;
    }

    return jsonObj;
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('dl.item dt a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('dl dt a')[i] as HTMLAnchorElement;
      const strList = document.querySelectorAll('dl.option dd')[i].textContent!.split('-');
      const qty = strList[strList.length - 1].replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
