/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-09-22
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";


/**
 * create on 2020-12-29.
 * <p> gen3289 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Gen3289DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.thumbs > div.proimg > p > img')) {
        return (document.querySelector('div.thumbs > div.proimg > p > img') as HTMLImageElement).src;
    } else {
        return (document.querySelector('#detail-item > div.info-wrap > div.items > img') as HTMLImageElement).src;
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('div.page-wrap > div.cart-ct > table > tbody tr td:nth-child(6)')[i]!.textContent!.trim();
      const rawQty = document.querySelectorAll('form[name="forms"]')[i]['amount'].value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': document.querySelectorAll('form[name="forms"]')[i]['branduid'].value,
        'productName': document.querySelectorAll('div.page-wrap > div.cart-ct > table > tbody tr td:nth-child(3) div a')[0]!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('#cart > div.page-wrap > div.cart-ct > table > tfoot > tr > td > div > strong')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.page-wrap > div.order-list > table > tbody tr.nobd').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.page-wrap > div.order-list > table > tbody tr td:nth-child(2) div a')[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('div.page-wrap > div.order-list > table > tbody tr td:nth-child(3)')[i]!.textContent!.trim();
      const price = document.querySelectorAll('div.page-wrap > div.order-list > table > tbody tr td:nth-child(4)')[i]!.textContent!.trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"] > dl > dd.price-info > ul > li:nth-child(1) > span.txt.original')[i].textContent!.trim();
      const rawQty = (document.querySelectorAll('form[name="forms"] > dl > dd.price-info > ul > li:nth-child(4) > span.txt > input')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]')[i]['branduid'] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('form[name="forms"] > dl > dd.pname > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('#basket > div.results > div > div.total-info > div.content-info-bottom > div > span')!.textContent!.trim()),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = window[this.ENP_OBJECT_NAME]['product'].length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const qty = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['product'][i]['qty']);

      parsedProducts.push({
        'productCode': window[this.ENP_OBJECT_NAME]['product'][i]['productCode'],
        'productName': window[this.ENP_OBJECT_NAME]['product'][i]['productName'],
        'qty': window[this.ENP_OBJECT_NAME]['product'][i]['qty'],
        'price': NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['product'][i]['price']) / qty,
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
