/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-24
 */

import {NotSupportedError} from "../error/NotSupportedError";
import {StringUtil} from "../lib/common/StringUtil";
import {PaySystemType} from "../types/GlobalEnums";
import {NumberUtil} from "../lib/common/NumberUtil";
import dayjs, {Dayjs} from "dayjs";
import {OrderedProduct} from "./OrderedProduct";
import {InvalidValueError} from "../error/InvalidValueError";

/**
 * todo refactoring
 * create on 2020-04-24.
 * <p> Conversion시 주문번호가 없을경우 임의의 주문번호를 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class OrderCodeManager {
  /* 브라우저의 세션 스토리지 지원 여부 */
  static sessionStorageSupported: boolean = OrderCodeManager.whetherSessionSorageSupported();
  /* 인스턴스 */
  private static instance: OrderCodeManager;
  /* Session Storage 인스턴스 */
  private readonly storage: Storage;
  /* 스토리지 key */
  private readonly key: { sessionKey: string; orderedProductList: string; } = {
    /* 현재 세션의 키 */
    'sessionKey': 'ENP_SESSION_KEY',
    /* 주문한 상품들 이력 */
    'orderedProductList': 'ENP_ORDER_PROD_LIST'
  };
  /* 시간 경과 기준 */
  private readonly expiryHour = 2;

  private constructor() {
    if (OrderCodeManager.sessionStorageSupported) {
      this.storage = window.sessionStorage;
    } else {
      throw new NotSupportedError('sessionStorage');
    }
  }

  /**
   * 인스턴스 생성
   * @return {StorageQueue}
   */
  static getInstance(): OrderCodeManager {
    if (!OrderCodeManager.instance) {
      OrderCodeManager.instance = new OrderCodeManager();
    }
    return OrderCodeManager.instance;
  }

  /**
   * 브라우저가 sessionStorage 지원하는지 확인
   * @return {boolean}
   * <p><code>true</code> - 지원</p><p><code>false</code> - 지원하지 않음</p>
   */
  static whetherSessionSorageSupported(): boolean {
    try {
      return !!window.sessionStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * 브라우저의 Session Storage에 세션 키를 생성 후 반환.
   * 세션 키가 없으면 새로 생성한다.
   * @return {string} 세션 키
   */
  createSessionKey(): string {
    let sessionKey: number | string = this.storage.getItem(this.key.sessionKey)!;

    // Session Storage에 세션키가 없으면 생성 후 저장
    if (StringUtil.isEmpty(sessionKey) || sessionKey === null) {
      sessionKey = Math.floor(Math.random() * 10000);
      this.storage.setItem(this.key.sessionKey, sessionKey.toString());
    }

    return sessionKey.toString();
  }

  /**
   * 임의의 주문번호를 생성 (에러 발생시 빈 값)
   * Step1 : 간편결제 유형에 맞게 prefix를 세팅(일반결제시 OrdNo_)
   * Step2 : 웹화면에서 상품코드+수량 데이터 코드 생성 후 Session Storage에 있는 값이랑 비교
   * Setp3 : 주문번호를 세팅 후 반환
   */
  createOrderCode(data: {}): string {
    try {
      const paySys = data['paySys'];
      let prefix: string = '';

      switch (paySys) {
        case PaySystemType.NAVER_PAY:
        case 'nPay':
          prefix = 'nPay_';
          break;
        case PaySystemType.PAYCO:
        case 'payco':
          prefix = 'payco_';
          break;
        case PaySystemType.KAKAO_PAY:
        case 'kakao':
          prefix = 'kakao_';
          break;
        default:
          prefix = data['convType'] === 'call' ? 'Call_' : 'OrdNo_';
          break;
      }

      const orderedProduct = this.getValidOrderedProduct(prefix, data);
      return this.getOrdCodeFromStorage(orderedProduct, prefix);
    } catch (e) {
      return StringUtil.EMPTY;
    }
  }

  /**
   * 유효한 주문 상품 정보를 반환.
   * @param {string} prefix - 주문번호 접두어
   * @param {{}} data - 서버에 전송하기 전의 데이터 객체
   * @return {OrderedProduct}
   */
  private getValidOrderedProduct(prefix: string, data: {}): OrderedProduct {
    /* 현재 주문한 상품 정보를 인코딩 */
    const encodedCurrProduct: string = this.getEncodedCurrProduct(data);
    /* 이전에 주문했던 상품들의 이력 */
    let orderedProductList: OrderedProduct[] = this.getOrderedProductList();
    /* 현재 시간 (밀리 초까지) */
    const currTimeString: string = this.getTimeStringMilli();
    /* 페이지가 로드되고 경과된 시간  */
    const elapsedTime: number = window.performance.now();
    /* 유효한 주문 상품 정보 */
    let validOrderedProduct: OrderedProduct = {
      'encodedProduct': encodedCurrProduct,
      'time': currTimeString,
      'elapsedTime': elapsedTime
    };

    /*
     * 간편결제는 버튼 클릭시 Conversion으로 잡히기 때문에 별도의 데이터 검사로직 없음
     * 비쇼핑의 경우 제외
     */
    if (!(prefix === 'OrdNo_' && data['convType'] === 'product')) {
      return validOrderedProduct;
    } else {
      let recentProduct = this.getRecentOrderedProduct(encodedCurrProduct, currTimeString);
      if (recentProduct === null) {
        /* 신규 주문 */
        if (this.isOrderedProductNull(orderedProductList)) {
          orderedProductList = [];
        }

        orderedProductList.push(validOrderedProduct);
        this.storage.setItem(this.key.orderedProductList, JSON.stringify(orderedProductList));
      } else {
        /* 이미 이력이 있을 때 최근 이력으로 초기화 */
        validOrderedProduct = recentProduct;
      }

      return validOrderedProduct;
    }
  }

  /**
   * storage에 저장된 주문번호를 반환.
   * @param {OrderedProduct} orderedProduct
   * @param {string} prefix
   * @return {string} 주문번호
   * @exception InvalidValueError 주문 정보가 무효한 경우
   */
  private getOrdCodeFromStorage(orderedProduct: OrderedProduct, prefix: string): string {
    if (!this.isValidOrderedProduct(orderedProduct)) {
      throw new InvalidValueError();
    }

    let orderedTimeCode: string = this.getOrderedTimeCode(orderedProduct['time'], orderedProduct['elapsedTime']);
    const uniqueKey: string = this.storage.getItem(this.key.sessionKey) + orderedTimeCode;

    return prefix + uniqueKey;
  }

  /**
   * 주문완료 페이지에 담긴 현재의 주문 상품 정보를 식별자를 추가해 인코딩하여 반환
   * 형식) "상품코드A&수량A&상품코드B&수량B..."
   * @param {{}} data 서버에 전송하기 전의 데이터 객체
   * @return {string} 인코딩된 상품 정보
   */
  private getEncodedCurrProduct(data: {}): string {
    let encodedProduct = '';
    const productArray: string[] = data['product'];

    for (let i = 0; i < productArray.length; i++) {
      const product: {} = productArray[i];
      if (StringUtil.isNotEmpty(encodedProduct)) {
        encodedProduct += "&";
      }

      encodedProduct += product['productCode'] + '&' + product['qty'];
    }

    return encodedProduct;
  }

  /**
   * 최근 주문한 이력을 반환.
   * @param {string} encodedCurrProduct  - 인코딩된 현재 주문 상품 정보
   * @param {string} currTimeString   - 현재 시간의 time string (밀리 초까지)
   * @return {OrderedProduct | null}  - 주문 이력
   * <p><code>null</code> - 새로운 주문</p><p><code>OrderedProduct</code> - 이미 저장된 주문</p>
   */
  private getRecentOrderedProduct(encodedCurrProduct: string, currTimeString: string): OrderedProduct | null {
    let orderedProductList = this.getOrderedProductList(); //Session Storage에 저장되어 있는 (상품코드+수량) 코드 및 현재 시간 리스트
    let orderedProduct: OrderedProduct | null = null;

    // Session Storage에 (상품코드+수량) 코드 및 현재 시간 리스트가 없으면 새로 추가해야 하므로 null 반환
    if (this.isOrderedProductNull(orderedProductList)) {
      return null;
    }

    for (let i = 0; i < orderedProductList.length; i++) {
      if (orderedProductList[i].encodedProduct === encodedCurrProduct) {
        orderedProduct = this.hasExpired(orderedProductList[i].time, currTimeString)
            ? null  // 새로운 주문
            : orderedProductList[i];  // 이미 주문했던 내역
      }
    }

    return orderedProduct;
  }

  /**
   * 현재 세션으로 구매한 모든 주문 정보들을 반환
   */
  private getOrderedProductList(): OrderedProduct[] {
    return JSON.parse(this.storage.getItem(this.key.orderedProductList)!);
  }

  /**
   * 현재 시간에 대한 time string (밀리초까지)
   * @return {string} time string
   */
  private getTimeStringMilli(): string {
    const currentDate: Date = new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);
    const hours: string = currentDate.getHours().toString();
    const minutes: string = NumberUtil.padZero(currentDate.getMinutes(), 2);
    const seconds: string = NumberUtil.padZero(currentDate.getSeconds(), 2);
    const milliSeconds: string = NumberUtil.padZero(currentDate.getMilliseconds(), 3);

    return year + month + date + hours + minutes + seconds + milliSeconds;
  }

  /**
   * 입력받은 시간값을 yyyy-mm-ddTHH:MM:SS 형식으로 변환
   * @param timeCode: string
   */
  private getTimeStr(timeCode: string): string {
    const year: string = timeCode.substr(0, 4);
    const month: string = timeCode.substr(4, 2);
    const date: string = timeCode.substr(6, 2);
    const hours: string = timeCode.substr(8, 2);
    const minutes: string = timeCode.substr(10, 2);
    const seconds: string = timeCode.substr(12, 2);

    return year + "-" + month + "-" + date + "T" + hours + ":" + minutes + ":" + seconds;
  }

  /**
   * 주문 이력 만료 여부
   * @param {string} orderedTimeString  - 주문 이력의 시간
   * @param {string} currentTimeString  - 현재 시간
   * @return {boolean}
   * <p><code>true</code> - 만료됨</p><p><code>false</code> - 만료되지 않음</p>
   */
  private hasExpired(orderedTimeString: string, currentTimeString: string): boolean {
    /* 주문한 시간 */
    const orderedDate: Dayjs = dayjs(this.getTimeStr(orderedTimeString));
    /* 현재 시간 */
    const currentDate: Dayjs = dayjs(this.getTimeStr(currentTimeString));

    const orderedDay: number = orderedDate.date();
    const currentDay: number = currentDate.date();
    const dateDiff: number = orderedDay > currentDay
        ? orderedDay - currentDay
        : currentDay - orderedDay;

    /* 같은 날짜 여부 */
    const isNotSameDate = dateDiff > 0;
    /* 클라이언트의 시스템 날짜가 잘못된 경우 (과거 날짜) */
    const isInvalidSystemTime = currentDate.diff(orderedDate, 'hour') < 0;
    /* 시간이 경과한 경우 */
    const hasElapsed = currentDate.diff(orderedDate, 'hour') > this.expiryHour;

    return isNotSameDate || isInvalidSystemTime || hasElapsed;
  }

  /**
   * Session Storage에 저장된 주문정보의 null 여부를 판단
   * @param orderedProducts: OrderedProduct[]
   */
  private isOrderedProductNull(orderedProducts: OrderedProduct[]): boolean {
    return orderedProducts === null || (orderedProducts.length === 1 && orderedProducts[0] === null);
  }

  /**
   * 주문 정보가 유효한지를 체크한다.
   * {@link OrderedProduct} 인터페이스의 각 멤버에 들어가는 값들 모두 유효한지 체크한다.
   * <ul>
   *   <li><code>undefined</code>가 아니어야 한다</li>
   *   <li><code>null</code>가 아니어야 한다</li>
   *   <li>빈 문자열이 아니어야 한다</li>
   * </ul>
   * @param {OrderedProduct} orderedProduct 주문 정보
   * @return {boolean}
   * <p><code>true</code> - 유효한 주문 정보</p><p><code>false</code> - 유효하지 않은 주문 정보</p>
   */
  private isValidOrderedProduct(orderedProduct: OrderedProduct): boolean {
    /* 주문 정보가 유효한지 */
    let valid = true;

    /* OrderedProduct 객체의 각 프로퍼티 값이 유효한지 체크 */
    Object.keys(orderedProduct).forEach((propertyKey) => {
      const isNotUndefined = typeof orderedProduct[propertyKey] !== 'undefined';
      const isNotNull = orderedProduct[propertyKey] !== null;
      const isNotEmptyString = StringUtil.isNotEmpty(orderedProduct[propertyKey]);
      const isPropertyValid = isNotUndefined && isNotNull && isNotEmptyString;

      /* 하나라도 false 이면 무효 */
      valid = valid && isPropertyValid;
    });

    return valid;
  }

  /**
   * 주문번호에 구매한 시간의 <code>timsetring</code>에 페이지 로드 후 경과시간을 더한 유니크 값을 반환.
   *
   * <p>
   *   경과 시간
   *   <ul>
   *     <li>경과 시간은 {@link window.performance}.now()를 사용한다.</li>
   *     <li>이 값의 단위는 밀리 초이다. 즉, 정수 부분은 밀리 초, 소수점 이하는 마이크로, 나노 등을 나타낸다.</li>
   *     <li><b>FireFox</b>와 같은 특정 브라우저에서는 정수만 표현된다.</li>
   *   </ul>
   * </p>
   * @param {string} timeString  - 현재시간
   * @param {number} elapsedTime - 페이지 로드 후 경과 시간
   * @return {string} 주문시간 코드
   * <p><b>형식:</b> <code>16진수 변환(timeString + performance.now의 밀리 초) + 나노 초(없으면 6자리의 랜덤값)</code></p>
   */
  private getOrderedTimeCode(timeString: string, elapsedTime: number): string {
    /* 6자리 난수 */
    const randNumber: string = NumberUtil.padZero(Math.floor(Math.random() * 1000000) - 1, 6);

    try {
      /* 경과 시간을 소수점을 기준으로 분리 (정수는 밀리초) */
      const elapsedTimeSplit: string[] = elapsedTime.toFixed(6).split('.');
      /* 경과 시간의 밀리초 */
      const elapsedMilliSecond: number = NumberUtil.parseInteger(elapsedTimeSplit[0]);
      /* 경과 시간의 나노초 */
      const elapsedNanoSecond = elapsedTimeSplit.length > 1
          ? elapsedTimeSplit[1]
          : randNumber;

      /* prefix로 사용될 16진수 값 */
      const hexadecimalPrefix: string = (NumberUtil.parseInteger(timeString) + elapsedMilliSecond).toString(16);
      return hexadecimalPrefix + elapsedNanoSecond;
    } catch (e) {
      /* 에러 발생시 timeString만 16진수로 변환하여 사용 */
      const hexadecimalPrefix: string = NumberUtil.parseInteger(timeString).toString(16);
      return hexadecimalPrefix + randNumber;
    }
  }
}
