/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-08
 */
import {DataParser} from "../DataParser";
import {DeviceType, HostingType} from "../../types/GlobalEnums";
import {JsonObject} from "../../lib/json/JsonObject";
import {GlobalVariables} from "../../types/GlobalVariables";
import {NumberUtil} from "../../lib/common/NumberUtil";
import {QueryStringer} from "../../lib/url/QueryStringer";

/**
 * create on 2020-01-08.
 * <p> 메이크샵_D2 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MakeshopD2DataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.MAKESHOP_D2);
  }

  protected isProductPage(): boolean {
    return this.isMobile(this.commandOptions)
        ? window.location.pathname === '/m/product.html'
        : window.location.pathname === '/shop/shopdetail.html';
  }

  protected isBasketPage(): boolean {
    return this.isMobile(this.commandOptions)
        ? window.location.pathname === '/m/basket.html'
        : window.location.pathname === '/shop/basket.html';
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  protected getShopCollectData(): JsonObject {
    const currCategoryElement: HTMLInputElement = document.querySelector('form#form1')!['mcode']
        ? document.querySelector('form#form1')!['mcode']
        : document.querySelector('form#form1')!['xcode'];
    this.parseCategory(currCategoryElement.value);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': window['product_name'],
      'price': document.querySelector('form#form1')!['price']['value'],
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('form#form1')!['disprice']['value'],
      'soldOut': window['is_exist'] ? 'N' : 'Y',
      'imageUrl': document.querySelectorAll('link[rel="image_src"]')[0]['href'],
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getConversionData(): JsonObject {
    return window.location.pathname === '/m/order_complete.html'
        ? this.getMobileConversionData()
        : this.getWebConversionData();
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const currCategoryElement: HTMLInputElement = document.querySelector('form#form1')!['mcode']
        ? document.querySelector('form#form1')!['mcode']
        : document.querySelector('form#form1')!['xcode'];
    this.parseCategory(currCategoryElement.value);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': window['product_name'],
      'price': document.querySelector('form#form1')!['price']['value'],
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('form#form1')!['disprice']['value'],
      'soldOut': window['is_exist'] ? 'N' : 'Y',
      'imageUrl': document.querySelectorAll('link[rel="image_src"]')[0]['href'],
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('#MK_p_total')!.textContent!.trim(),
      'totalQty': document.getElementById("MS_amount_basic_0")!['value'].trim()
    };
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions)
        ? this.getMobilePayConvDataFromBasketPage()
        : this.getWebPayConvDataFromBasketPage();
  }

  /**
   * 모바일 여부 판단
   * @param {{}} commandOptions
   * @return {boolean}
   */
  protected isMobile(commandOptions: {}): boolean {
    try {
      /* 커맨드옵션으로 입력된 값 */
      const deviceFromOpt: string = commandOptions['device'].toUpperCase();

      /* enum을 object로 변환 */
      const deviceTypes = Object(DeviceType);

      /* 입력한 값이 DeviceType에 포함되는지 확인 */
      const isValid = Object.keys(deviceTypes).filter((device: string) =>
          DeviceType[device] === deviceFromOpt).length > 0;

      return isValid
          ? deviceFromOpt === DeviceType.MOBILE
          : false;
    } catch (e) {
      return false;
    }
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value,
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': (document.querySelector('div#order-complete p.complete-price em.price-color') as HTMLEmbedElement).textContent,
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const price = (document.querySelectorAll('div.table-cart tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const qty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      totalPrice += NumberUtil.parseNumber(price);
      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const price = (document.querySelectorAll('form[name="forms"] div.basketBody p.price-sell')[i] as HTMLParagraphElement).textContent!.split(':')[1].trim().slice(0, -1);
      const qty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      totalPrice += NumberUtil.parseNumber(price);
      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname input[name="extra_item"]+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }
}
