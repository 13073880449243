/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-26
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-26
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class AppacompanyDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions) ? super.getPayConvDataFromBasketPage() : this.getWebPayConvDataFromBasketPage();
  }

  private getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('td.this-product > div > a:nth-child(2)');
    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseRationalNumber((document.querySelectorAll('td.count.this-product input')[i] as HTMLInputElement)!.value);
      parsedProducts.push({
        'productCode': new QueryStringer(productNameList[i].search.substring(1)).getParam('goodsNo'),
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.ta-c.this-product .price')[i].textContent!.trim())
      });

      totalQty += qty;
    }

    jsonObj['product'] = parsedProducts;
    jsonObj['totalQty'] = totalQty;
    return jsonObj;
  }
}