/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-06-22
 */

import {ProductNameValidRule} from "./ProductNameValidRule";

/**
 * create on 2021-06-22
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ConvPrdtNameValidRule extends ProductNameValidRule {
  protected applyRule(property: any): any {
    if(!property) return 'productName1';
    let productName = '';
    try{
      productName = decodeURIComponent(decodeURIComponent(property));
    }catch{
      productName = property;
    }
    productName = productName.replace(/\[/g, '').replace(/]/g, '')
    return super.applyRule(productName);
  }
}
