/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-08-03
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
 * create on 2020-08-03.
 * <p> hanstyle4543(한스스타일) 광고주 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class HanstyleDataParser extends MakeshopD4DataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected ENP_OBJECT_NAME = 'ENP_VAR';

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
   protected getMobilePayConvDataFromBasketPage(): {} {

    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;


    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('figcaption p.SMSbasketPrice')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('li.mainprd  input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('li.mainprd  input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('li form p.pname')[i].textContent!.trim().substr(5),
        'price': price / qty,
        'qty': qty
      });
    }


    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }


  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
   protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('div.quantity p')[i].textContent!.replace(/\D/g,'');
      const rawQty = (document.querySelectorAll('ul input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('ul form input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('div.center_area p.prd_name')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,      
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
   protected getMobileConversionData(): JsonObject {

    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('section.prd-wrap.wrap2 div.box.active table tr').length;
    let totalQty = 0;
    const targetObj: {} = window[this.ENP_OBJECT_NAME];
    const parsedProducts: Array<{}> = targetObj['product'];
    let newArr: Array<{}> = [];
    for (let i = 0; i < parsedProducts.length; i++) {
      const productAnchorElem = document.querySelectorAll('.prd-wrap.wrap2 .box.active table tr td div.title a')[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('.prd-wrap.wrap2 .box.active table tr td div.num')[i].textContent!.replace(/\D/g,'');
      const rawPricee =  parsedProducts[i]['totalPrice'].replace(/\D/g, '');

      newArr.push({
        'productCode': new QueryStringer(productAnchorElem.search!.substring(1)).getParam('branduid'),
        'productName': document.querySelectorAll('.prd-wrap.wrap2 .box.active table tr td div.title a')[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber(rawPricee) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'productCnt' : productInfoLength,
      'product': newArr,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
