/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-02-26
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-02-26.
 * <p> Pinkelephant 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PinkelephantDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 가격 및 할인가격 파싱이 기존과 다름
   */
  protected getShopCollectData(): JsonObject {
      const categoryCode = this.getCategoryCode();
      if(this.isMobile(this.commandOptions)){

      }
      const price = (document.querySelector('input[name="price"]') as HTMLInputElement).value.replace(/\D/g,'');
      const dcPrice = window['ENP_VAR']['dcPrice'].replace(/\D/g,'');

      this.parseCategory(categoryCode[0]);

      const parsedData: {} = {
        'adverId': this.adverId,
        'productCode': document.querySelector('form#form1')!['branduid']['value'],
        'productName': this.getProductName(),
        'price': price ,
        'productUrl': window.location.href,
        'dcPrice': dcPrice,
        'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
        'imageUrl': this.getImageUrl(),
        'topCategory': this.category['topCategory']
            ? this.category['topCategory']
            : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };

      return new JsonObject(parsedData);
    }

  /**
     * 주문완료 웹 버전에서 정보 파싱
     * @return {JsonObject} 파싱된 결과
     */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.order-list > table > tbody div.tb-left > a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.order-list > table > tbody div.tb-left > a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.order-list:nth-child(2) > table > tbody > tr.nobd > td:nth-child(3) > div')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.order-list:nth-child(2) > table > tbody > tr.nobd > td:nth-child(4) > div')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1).replace(',',''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const price = (document.querySelectorAll('div.table-cart tr td div.tb-price span.PHPS_priceSell')[i] as HTMLSpanElement).textContent!.trim();
      const qty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      totalPrice += NumberUtil.parseNumber(price);
      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('a.name')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('ul.listWrap li:nth-child(5)')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const price = (document.querySelectorAll('form[name="forms"]>figure figcaption>p:nth-child(2)')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const qty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      totalPrice += NumberUtil.parseNumber(price);
      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-child')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.total_price')!.textContent,
      'totalQty': totalQty.toString()
    };
  }

}
