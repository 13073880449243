/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-10-20
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-10-20
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class GoqualgoqualDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
    jsonObj['totalPrice'] = document.querySelector('tbody tr td.total strong:nth-child(2)')!.textContent!.replace(/\D/g, '');
    }
    return jsonObj;
  }
}
