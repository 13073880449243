/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-09-28
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-26.
 * <p> wellcindy77 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Wellcindy77DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productElem = document.querySelectorAll('div.tbl_head02 > table > tbody tr');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productElem.length; i++) {
      const productAnchorElem = productElem[i].querySelector('td:nth-child(2) > a') as HTMLAnchorElement;
      const qty = NumberUtil.parseNumber(productElem[i].querySelector('td:nth-child(3)')!.textContent!.trim());
      const price = NumberUtil.parseNumber(productElem[i].querySelector('td:nth-child(5)')!.textContent!.trim());

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getImageUrl(): string {
    if (this.isMobile(this.commandOptions)) {
      return (document.querySelector('img.response100.detail_image') as HTMLImageElement).src;
    } else {
      return (document.querySelector('#bigpic') as HTMLImageElement).src;
    }
  }
}
