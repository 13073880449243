/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-03-08
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";

/**
 * create on 2022-03-08
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class PinksislyDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      let superData = super.getWebPayConvDataFromBasketPage();
      superData['totalPrice'] = document.querySelector('.total_payment #SP_total')!.textContent!.replace(/\D/g, '');
      return superData;
    } catch {
      return super.getWebPayConvDataFromBasketPage();
    }
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    try {
      let superData = super.getMobilePayConvDataFromBasketPage();
      superData['totalPrice'] = document.querySelector('.price-total-info-bottom .txt-price')!.textContent!.replace(/\D/g, '');
      return superData;
    } catch {
      return super.getMobilePayConvDataFromBasketPage();
    }
  }
}