/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-03-22
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import { JsonObject } from "../../../lib/json/JsonObject";
import { NullParingData } from "../../../lib/ajax/NullParingData";

/**
 * create on 2022-03-22
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class EanddprDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  
  protected getShopCollectData(): JsonObject {
    try {
      const formElement: HTMLFormElement | null = this.isMobile(this.commandOptions) ? 
        document.querySelector('.goods_view .detail_info form') : document.querySelector('form#frmView');
      return this.isMobile(this.commandOptions)
          ? this.getMobileShopCollectData(formElement!)
          : this.getWebShopCollectData(formElement!);
    } catch (e) {
      return new NullParingData();
    }
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    let superData = super.getProductPriceObj(formElement);
    const price:number = NumberUtil.parseNumber(superData['price']);
    if(!price) superData['price'] = 1;
    return superData;
  }
}