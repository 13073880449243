/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-19
 */
import {ReviewSelector} from './dataType/ReviewSelector';
import {Review} from './dataType/Review';
import * as SelectorInfo from './json/review.selector.json';
import * as ObserverTarget from './json/review.observer.selector.json';
import {AJAXer} from '../../lib/ajax/AJAXer';
import {CallbackFunction} from '../../types/GlobalTypes';
import {DeviceType} from '../../types/GlobalEnums';
import {StringUtil} from '../../lib/common/StringUtil';

/**
 * create on 2020-05-27.
 * <p> 광고주 화면에서 리뷰 수집을 위한 클래스 </p>
 * - 크레마, 브이리뷰 등 별도 회사의 리뷰 솔루션을 사용하고 있는 경우
 *   또는 그 외에 iframe으로 HTML이 구성이 되어있는 경우 Script로는
*    파싱이 불가능 하기 떄문에 Java에서 파싱을 진행한다.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ReviewParser {
  private readonly adverId: string;
  private readonly deviceType: string;
  private _productCode: string;
  protected ajaxer: AJAXer;
  protected callback: CallbackFunction;

  private static instance: ReviewParser;

  // Iframe일 경우 JAVA에서 파싱을 진행하기 위한 API URL
  private static BASE_URL: string = "https://tk.mediacategory.com/parsing/review";

  private constructor(adverId: string, device: string, productCode: string) {
    this.adverId = adverId;
    this.deviceType = this.getDeviceType(device);
    this.ajaxer = AJAXer.getInstance();
    this._productCode = productCode;
    this.callback = (
      xhr: XMLHttpRequest,
      response: any,
      hasTransmitted: boolean
    ) => {
      // console.log(response);
    };
  }

  static getInstance(adverId?: string, device?: string, productCode?: string): ReviewParser {
    if (!ReviewParser.instance) {
      ReviewParser.instance = new ReviewParser(adverId!, device!, productCode!);
    }

    return ReviewParser.instance;
  }

  /**
   * selector json 파일에 광고주 ID의 Selector가 없으면 null로 리턴
   */
  advertiserValidator() {
    return SelectorInfo[this.adverId][this.deviceType];
  }

  /**
   * 광고주 화면에서 리뷰 솔루션의 Iframe 존재 여부
   * > true iframe 사용 / false: iframe 사용안함
   *   = iframe을 사용하는 경우 : gateway. service(Java API) 호출해서 파싱 진행
   *   = 사용 안하는 경우 : Agent에서 파싱 진행
   */
  checkIframe(): boolean {
    try {
      const iframeSelector = SelectorInfo[this.adverId].iframeSelector;

      if (StringUtil.isNotEmpty(iframeSelector)) {
        const iframeElement = document.querySelector(iframeSelector) as HTMLIFrameElement;
        if (iframeElement == null || !iframeElement) {
          /*
           * 최초 스크립트가 로드 중에 iframe Element가 null 이여도 뒤늦게 그려지는 경우가 있기 때문에
           * iframe.observer.selector.json에 등록된 광고주 ID면 Observer를 생성하게 분기처리
           */
          const isObserver = !!ObserverTarget[this.adverId];
          if (isObserver) {
            this.startingObservering();
          } else {
            return true;
          }
        } else {
          this.sendIframeParserAPI(iframeElement.src);
        }
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }

  /**
   * 광고주 페이지에서 리뷰를 파싱할 때 스크립트 파싱이 가능한지
   *  > IFrame을 사용하는 경우에는 Iframe 내부의 DOM에 접근이 불가능 하여서
   *    Script로는 파싱이 불가능하다
   */
  isScriptParsing(): boolean {
    try {
      const reviewParseData = SelectorInfo[this.adverId] != null && SelectorInfo[this.adverId]
          ? SelectorInfo[this.adverId][this.deviceType]
          : null;

      return reviewParseData != null
          ? !this.checkIframe()
          : false;
    } catch(e) {
      return false;
    }
  }

  /**
   * 크레마, 브이리뷰 등 별도 회사의 리뷰 솔루션을 사용하고 있는 경우
   * 또는 그 외에 iframe으로 HTML이 구성이 되어있는 경우 Script로는
   * 파싱이 불가능 하기 떄문에 Java에서 파싱을 진행한다.
   */
  sendIframeParserAPI(src: string) {
    // this.ajaxer.post(
    //     ReviewParser.BASE_URL,
    //     {
    //       'pageUrl': src,
    //       'adverId': this.adverId,
    //       'productCode': this._productCode,
    //       'selector': this.getElementSelector()
    //     },
    //     this.callback
    // );
  }

  /**
   * 화면에 존재하는 리뷰의 평균평점과 총 리뷰 개수파싱 후 반환
   */
  getReviewData(): Review {
    try {
      // 광고주 ID, deviceType에 따른 Selector
      const selector: ReviewSelector = this.getElementSelector();
      // 전체 리뷰의 평균 평점
      let reviewAverage: string = document.querySelector(selector.reviewAverage)
          ? document.querySelector(selector.reviewAverage)!.textContent!.trim().replace(/[^0-9.]/g, '')
          : '0';
      // 전체 리뷰의 개수
      let reviewCount: string = document.querySelector(selector.reviewCount)
          ? document.querySelector(selector.reviewCount)!.textContent!.trim().replace(/\D/g, '')
          : '0';

      return {
        adverId: this.adverId,
        productCode: this._productCode,
        reviewCount: reviewCount,
        reviewAverage: reviewAverage
      };
    } catch (e) {
      // Exception 발생시 데이터 0으로 세팅 후 리턴
      return {
        adverId: this.adverId,
        productCode: this._productCode,
        reviewCount: '0',
        reviewAverage: '0'
      };
    }
  }

  /**
   * selector.info.json 파일에서 selector을 가져옴
   */
  private getElementSelector(): ReviewSelector {
    return SelectorInfo[this.adverId][this.deviceType];
  }

  /**
   * Device Type에 따른 텍스트 값 반환
   * > wev / mobile / both
   */
  private getDeviceType(deviceType: string) {
    try {
      if (deviceType === null || !deviceType) {
        return 'web';
      }

      switch (deviceType.toUpperCase()) {
        case DeviceType.WEB:
          return 'web';
        case DeviceType.MOBILE:
          return 'mobile';
        case DeviceType.BOTH:
          return 'both';
        default:
          return 'web';
      }
    } catch (e) {
      return 'web';
    }
  }

  /**
  * iframe을 이용한 리뷰 솔루션을 사용하는 광고주 중 iframe이 complete 이휴에
  * 로드가 되는 광고주들이 존재한다. 그래서 iframe이 생성 되는 시점을
  *  MutationObserver로 확인 후 gateway에 리뷰 파싱 요청을 한다.
  */
  private startingObservering() {
    const targetElement = document.querySelector(ObserverTarget[this.adverId]);

    let observer: MutationObserver;
    if (targetElement != null && targetElement) {
      observer = new MutationObserver((mutations, obs) => {
        let iframe = (document.querySelector(SelectorInfo[this.adverId].iframeSelector) as HTMLIFrameElement);
        this.sendIframeParserAPI(iframe!.src);

        obs.disconnect();
      });

      observer.observe(targetElement, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });
    }
  }

  set productCode(productCode: string) {
    this._productCode = productCode;
  }
}
