/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-18
 */
import {Cafe24SmartDataValidator} from "../Cafe24SmartDataValidator";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2020-03-18.
 * <p> "Andersson" 광고주 데이터 검증 클래스 </p>
 * <p> {@link Cafe24SmartDataValidator} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class AnderssonDataValidator extends Cafe24SmartDataValidator {
    constructor() {
        super();
    }

    /**
     * 이전 페이지(referrer)가 상품상세페이지 인지 확인
     * 외부키워드의 경우 이전페이지가 상품상세페이지인 경우 전송을 안한다.
     * @return {boolean}
     * <p><code>true</code> - 상세페이지 </p><p><code>false</code> - 상세페이지 아님</p>
     */
    protected chkAfterPageIsDetail(): boolean {
      try {
        return StringUtil.getPathNameFromUrl(document.referrer).substr(1).split('/')[1] === "detail.html";
      } catch (error) {
        return false;
      }
    }
}
