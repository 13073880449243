/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-20
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-10-20
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class YamanadDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    try {
      return this.isMobile(this.commandOptions)
          ? this.getMobilePayConvDataFromBasketPage()
          : this.getWebPayConvDataFromBasketPage()
    } catch (e) {
      return super.getPayConvDataFromBasketPage()
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();

    const parsedProducts: Array<{}> = [];
    const productNameList: NodeListOf<HTMLInputElement> = document.querySelectorAll('td.gi.this-product>div>a');
    let totalQty = 0;

    for (let i = 0; i < productNameList.length; i++) {
      const qty = NumberUtil.parseInteger((document.querySelectorAll('input[name="goodsCnt[]"]')[i] as HTMLInputElement).value);
      const rawPrice = NumberUtil.parseNumber(document.querySelectorAll('.ta-c.this-product > .price')[i].textContent!.trim());
      const price = rawPrice / qty;

      parsedProducts.push({
        'productCode': new QueryStringer(productNameList[i].getAttribute('href')!.split('?')[1]).getParam('goodsNo'),
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': price,
        'dcPrice': price,
        'topCategory': this.category['topCategory'],
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      });

      totalQty += qty;
    }

    jsonObj['product'] = parsedProducts;
    jsonObj['totalQty'] = totalQty;
    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();

    const parsedProducts: Array<{}> = [];
    const productNameList: NodeListOf<HTMLInputElement> = document.querySelectorAll('.my_goods span.elp2');
    let totalQty = 0;

    for (let i = 0; i < productNameList.length; i++) {
      const qty = NumberUtil.parseInteger(document.querySelectorAll('div.itembody dd:nth-child(2)')[i].textContent!.split(':')[1].trim());
      const rawPrice = NumberUtil.parseNumber(document.querySelectorAll('div.itembody dd strong')[i].textContent!.trim());
      const price = rawPrice / qty;

      parsedProducts.push({
        'productCode': document.querySelectorAll('button[data-goodsno]')[i]!.getAttribute('data-goodsno'),
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': price,
        'dcPrice': price,
        'topCategory': this.category['topCategory'],
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      });

      totalQty += qty;
    }

    jsonObj['product'] = parsedProducts;
    jsonObj['totalQty'] = totalQty;
    return jsonObj;
  }
}