/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-12
 */
import {CategoryParser} from "../CategoryParser";
import {HostingType} from "../../types/GlobalEnums";
import {GeneralTree} from "../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../Category";
import {GTNode} from "../../lib/dataStructure/tree/general/GTNode";
import {QueryStringer} from "../../lib/url/QueryStringer";
import {StringUtil} from "../../lib/common/StringUtil";

/**
 * create on 2020-03-12.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GodomallSelfCategoryParser extends CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  protected parseAndMakeTree(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    if (hasExpired) {
      /* 로우 데이터 */
      const categories: Category[] = this.parseRawCategories();

      /* 각 카테고리를 노드로 변환 */
      const nodes: Array<GTNode<Category>> = this.createNodesFromCategories(categories);

      /* 노드를 이용해 트리를 생성 */
      const tree: GeneralTree<Category> = this.getTree(nodes);

      /* 외부에서 콜백으로 트리에 접근 */
      callback(tree);
    }
  }

  protected createCategoryHierarchyTree(rawCategory: any): GeneralTree<Category> {
    return new GeneralTree<Category>({code: '', value: '', parentCode: ''});
  }

  private parseRawCategories(): Category[] {
    return this.getDividedCategoryCode(this.getRawCategories());
  }

  private getRawCategories(): Array<{ rawCode: string; value: string; }> {
    const rawCategoryArr: Array<{ rawCode: string; value: string; }> = [];
    const anchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.gnb_menu_box li>a');

    anchorList.forEach((anchor: HTMLAnchorElement) => {
      const queryStringer: QueryStringer = new QueryStringer(anchor.search.substring(1));
      const code: string = queryStringer.getParam('cateCd');

      if (StringUtil.isNotEmpty(code)) {
        rawCategoryArr.push({
          rawCode: code,
          value: anchor.textContent!.trim()
        });
      }
    });

    return rawCategoryArr;
  }

  private getDividedCategoryCode(rawCategoryArr: Array<{ rawCode: string; value: string; }>): Category[] {
    const categoryArr: Category[] = [];
    rawCategoryArr.forEach((rawCategory) => {
      const depth: number = rawCategory.rawCode.length % 3 ? rawCategory.rawCode.length / 3 : 0;
      switch (depth) {
        case 1:
          categoryArr.push({
            parentCode: '',
            code: rawCategory.rawCode,
            value: rawCategory.value
          });
          break;
        case 2:
          categoryArr.push({
            parentCode: rawCategory.rawCode.substring(0, 3),
            code: rawCategory.rawCode,
            value: rawCategory.value
          });
          break;
        default:
          break;
      }
    });

    return categoryArr;
  }

  /**
   * 로우 데이터를 이용해 각 카테고리에 해당하는 노드 생성
   * @param {Category[]} categories
   * @return {Array<GTNode<Category>>}
   */
  private createNodesFromCategories(categories: Category[]): Array<GTNode<Category>> {
    const categoryNodes: Array<GTNode<Category>> = [];

    categories.forEach((category: Category) => {
      categoryNodes.push(new GTNode<Category>(category));
    });

    return categoryNodes;
  }


  /**
   * 각 부모와 자식 노드의 path를 연결.
   * @param {Array<GTNode<Category>>} nodes 생성된 노드 배열
   */
  private linkParentAndChild(nodes: Array<GTNode<Category>>): void {
    nodes.forEach((node: GTNode<Category>) => {
      const parentCode: string = node.value.parentCode;
      const parentNode: GTNode<Category> = nodes.filter((rawCate: GTNode<Category>) => rawCate.value.code === parentCode)[0];
      if (parentNode) {
        parentNode.insertNext(node);
      }
    });
  }

  /**
   * 모든 조상 노드를 탐색하여 반환.
   * @param {Array<GTNode<Category>>} nodes 생성한 노드의 배열
   * @return {Array<GTNode<Category>>}  탐색된 조상 노드로 이루어진 배열
   */
  private getAncestorNodes(nodes: Array<GTNode<Category>>): Array<GTNode<Category>> {
    const ancestorNodes: Array<GTNode<Category>> = [];
    this.linkParentAndChild(nodes);

    /* 모든 노드를 순회하여 부모 노드가 없는 노드들을 찾는다 */
    nodes.forEach((node: GTNode<Category>) => {
      let currNode: GTNode<Category> = node;
      while (currNode.parent) {
        currNode = currNode.parent;
      }

      /* 같은 조상 노드를 추가하지 않도록 체크 */
      if (ancestorNodes.filter((ancestor: GTNode<Category>) => ancestor.value.code === currNode.value.code).length === 0) {
        ancestorNodes.push(currNode);
      }
    });

    return ancestorNodes;
  }

  /**
   * 각 path가 연결된 노드들로 구성된 트리를 생성.
   * 트리의 루트 노드는 임의로 생성한 값을 갖는다. - <code>{code: 'ROOT', value: '', parentCode: ''}</code>
   * @param {Array<GTNode<Category>>} nodesWithPath path가 설정된 노드 배열
   * @return {GeneralTree<Category>}  트리
   */
  private getTree(nodesWithPath: Array<GTNode<Category>>): GeneralTree<Category> {
    const tree: GeneralTree<Category> = new GeneralTree<Category>({code: 'ROOT', value: '', parentCode: ''});
    this.getAncestorNodes(nodesWithPath).forEach((rootNode: GTNode<Category>) => {
      tree.createChildByNode(rootNode);
    });

    return tree;
  }
}
