/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-05-03
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GlobalVariables } from "../../../types/GlobalVariables";

/**
 * create on 2021-05-03
 * glowloudey - glowloudeycos.co.kr
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class GlowloudeyDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    if(this.isMobile(this.commandOptions)){
      this.parseCategory('');

      let productName: string = this.getProductName();
      const newLineIdx: number = productName.indexOf('\n');
      productName = newLineIdx === -1 ? productName : productName.substring(0, newLineIdx);

      const parsedData: {} = {
        'adverId': this.adverId,
        'productCode': window['gl_goods_seq'],
        'productName': productName,
        'price': this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer'])
            ? this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer'])!.textContent
            : window['gl_goods_price'],
        'productUrl': window.location.href,
        'dcPrice': window['gl_goods_price'],
        'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
        'imageUrl': document.querySelector('#slick-slide00 > a > img')!['src'],
        'topCategory': this.category['topCategory']
            ? this.category['topCategory']
            : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };

      return new JsonObject(parsedData);
    } else {
      const jsonObj = super.getShopCollectData();
      jsonObj.replaceData('imageUrl', document.querySelector('div#goods_thumbs div.slides_container img')!['src']);
      return jsonObj
    }
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    let totalQty = document.querySelector('form[name=goodsForm] input[name^=optionEa]')
      ? NumberUtil.stringToNumber(document.querySelector('form[name=goodsForm] input[name^=optionEa]')!['value'])
      : 1;
    let totalPrice = 0;

    let productName: string = this.getProductName();
    const newLineIdx: number = productName.indexOf('\n');
    productName = newLineIdx === -1 ? productName : productName.substring(0, newLineIdx);

    this.parseCategory('');
    parsedProducts.push({
      'productCode': window['gl_goods_seq'],
      'productName': productName,
      'qty': totalQty,
      'price': this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer, .dst_th_size>s>span.num'])
          ? this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer, .dst_th_size>s>span.num'])!.textContent
          : window['gl_goods_price'],
      'productUrl': window.location.href,
      'dcPrice': window['gl_goods_price'],
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
      'imageUrl': (document.querySelector('#slick-slide00 a img') as HTMLImageElement).src ,
    
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    parsedProducts.forEach((obj) => {
      totalPrice += typeof obj['price'] === 'string'
          ? NumberUtil.parseNumber(obj['price'])
          : obj['price'];
    });
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice * totalQty,
      'totalQty': totalQty
    };
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td');
        const productName = productInfo[0].querySelector('.order_name')!.textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = this.getSingleProductFrom(productInfo).qty;
        const productPrice = this.getSingleProductFrom(productInfo).price;

        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('div dd strong.blue')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

}
