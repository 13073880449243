/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-11-26
 */
import {JsonObject} from "../../../lib/json/JsonObject";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2021-11-26.
 * <p>
 *   <ul>
 *    <li>이 서브클래스는 'mobonbranding', 'adhubbranding', 'mobon', 'enp_brjang' 4개의 광고주에서 사용.</li>
 *   </ul>
 * </p>
 * <p> {@link SelfHostedDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class MobonDataParser extends SelfHostedDataParser {
  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    jsonData.addData('siteCode', this.getSiteCode(this.adverId));
    return jsonData;
  }

  protected getConversionData(): JsonObject {
    const jsonData: JsonObject = super.getConversionData();
    jsonData.addData('siteCode', this.getSiteCode(this.adverId));
    return jsonData;
  }

  private getSiteCode(adverId: string): string {
    let siteCode: string = '';
    switch (adverId) {
      case 'mobonbranding':
        siteCode = '8b09859022efcc63e01425a6bce44c68';
        break;
      case 'adhubbranding':
        siteCode = '5727c6e8f81aa5765a970fc626b35506';
        break;
      case 'mobon':
        siteCode = '0d12a3dcdabd4d6249706d6c5ec97718';
        break;
      case 'enp_brjang':
        siteCode = '3b2bfadefde53c3cb91b4f126268d82f';
        break;
    }
    return siteCode;
  }
}
