/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-10-02.
 * <p> 자료구조의 Queue를 구현 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Queue<T> {
  /* 큐의 데이터 */
  protected data: T[];

  constructor() {
    this.data = [];
  }

  /**
   * 현재 큐에 들어 있는 데이터의 갯수
   * @return {number}
   */
  size(): number {
    return this.data.length;
  }

  /**
   * 큐가 비어 있는지 확인
   * @return {boolean}
   */
  isEmpty(): boolean {
    return this.data.length === 0;
  }

  /**
   * 큐가 비어 있지 않은지 확인
   * @return {boolean}
   */
  isNotEmpty(): boolean {
    return this.data.length > 0;
  }

  peek(): T | undefined {
    return this.data[0];
  }

  /**
   * 큐 내용의 끝에서부터 데이터를 삽입
   * @param {T} value
   */
  enqueue(value: T): void {
    this.data.push(value);
  }

  /**
   * 큐 내용의 앞에서부터 데이터를 반환하고 제거
   * 큐가 비어 있으면 <code>undefined</code>
   * @return {T | undefined}
   */
  dequeue(): T | undefined {
    return this.data.shift();
  }

  /**
   * 큐의 모든 내용을 삭제
   */
  clear(): void {
    this.data = [];
  }

  /**
   * 현재 <code>Queue</code> 객체를 deep clone하여 새로운 <code>Queue</code> 객체를 생성.
   * @return {Queue<T>} - deep clone 된 객체
   */
  deepClone(): Queue<T> {
    const clonedQueue: Queue<T> = new Queue<T>();
    for (let i = 0; i < this.data.length; i++) {
      clonedQueue.enqueue(this.data[i]);
    }

    return clonedQueue;
  }
}
