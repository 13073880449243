/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2021-02-26
 */


import {JsonObject} from "../../lib/json/JsonObject";
import {AJAXer} from "../../lib/ajax/AJAXer";
import {AdBlockStorage} from "./AdBlockStorage";
/**
 * create on 2021-03-24.
 *
 * @version 1.0
 * @author sjoh
 */
export class AdBlockCtrl {
  private readonly domain: string = "tk.alpokat.kr";
  private readonly domain_mediacategory: string = "tk.mediacategory.com";

  private readonly commonUrl: string = "/aggregate/mssg/common";

  private static instance: AdBlockCtrl;

  /* Ajax 인스턴스 */
  private ajaxer: AJAXer;

  /* 서버로 전송될 JSON 데이터 */
  private jsonObject: JsonObject;
  private commonJsonObject: JsonObject;

  /* 스토리지 */
  private storage: AdBlockStorage;

  private instanceFlag: boolean;

  constructor() {
    this.jsonObject = new JsonObject();
    this.commonJsonObject = new JsonObject();
    this.ajaxer = AJAXer.getInstance();
    this.storage = new AdBlockStorage();
    this.instanceFlag = false;
  }

  /**
   * 인스턴스 생성
   * @return {AdBlockCtrl}
   */
  static getInstance(): AdBlockCtrl {
    if (!AdBlockCtrl.instance) {
      AdBlockCtrl.instance = new AdBlockCtrl();
    }
    return AdBlockCtrl.instance;
  }

  /**
   * 도메인 변경및 재전송을 위한
   * @param {JsonObject} jsonObject
   */
  public setJsonObject(jsonObject: JsonObject): void {
    this.jsonObject = jsonObject;
  }

  /**
   * 변경된 도메인에 AUID값을 기본값으로 설정하기 위해 전송에 필요한 JsonObject를 미리 셋팅
   * @param {JsonObject} jsonObject
   */
  public setCommonJsonObject(jsonObject: JsonObject): void {
    this.commonJsonObject = jsonObject;
  }

  public getDomain() : string {
    try{
      let retrunDomain = this.domain_mediacategory;
      if(this.instanceFlag || this.adBlockCheck()){
        retrunDomain = this.domain;
      }
    return retrunDomain;
    }catch (e) {
      this.instanceFlag = false;
      return this.domain_mediacategory;
    }
  }

  /**
   * Mediacategory 도메인이 비정상적으로 처리되었을때 변경된 도메인으로 전송
   * @param {string} protocol
   * @param {string} url
   */
  public reSend(protocol: string, url : string): void{
    if(!this.adBlockCheck()){
      this.ajaxer.options.timeout = 3000;
      this.ajaxer.post(protocol + this.domain + url, this.jsonObject.data, (xhr, response, hasTransmitted) => {
        if(xhr.status >= 200 && xhr.status < 400) {
          this.instanceFlag = true;
          this.storage.setAdBlockUsed(AdBlockType.ABP_USED);
        }
      });
    }
  }

  /**
   * True인 경우 서버전송 URL 변경
   * @returns {boolean}
   */
  public adBlockCheck(): boolean{
    if(this.storage.adBlockUseStorageCheck()){
      return true;
    }else{
      return false;
    }
  }

/**
   * Mediacategory 도메인이 정상적으로 처리되었을때 변경된 도메인에 기존 AUID를 설정하기 위한 요청
   * API 서버는 AdBlock 우회 도메인에 setCookie작업을 진행함
   * response Code인 "MOIF0000"값은 AgreeGateway가 정상 동작했을때 전달받음
   * @param {string} protocol
   * @param {string} response

  public auidSync(protocol: string, response: string): void{
    const jsonData = JSON.parse(response);
    if(jsonData["resCode"] === 'MOIF0000' && this.auidSyncCheck()){
      this.ajaxer.options.timeout = 3000;
      this.ajaxer.post(protocol + this.domain + this.commonUrl, this.commonJsonObject.data, (xhr, response, hasTransmitted) => {});
    }
  }

  public auidSyncCheck(): boolean{
    return false;
  }
*/


}
export enum AdBlockType {
  ABP_USED = 'ENP_ABP_USE',
  ABP_COMMON = 'ENP_ABP_COMMON'
}

