/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-10
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2021-08-10
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Jun8844DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    try{
      const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
      const parsedProducts: Array<{}> = targetObj['product'];
      const products: Array<{}> = [];
      let totalQty: number = 0;
      let totalPrice: number = 0;

      for (let i = 0; i < parsedProducts.length; i++) {
        const product = {
          'productCode': parsedProducts[i]['productCode'],
          'productName': parsedProducts[i]['productName'],
          'price': parsedProducts[i]['price'],
          'qty': parsedProducts[i]['qty']
        };

        products.push(product);

        /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
        totalPrice += NumberUtil.parseInteger(products[i]['price']);
        totalQty += NumberUtil.parseInteger(products[i]['qty']);
      }
      return new JsonObject({
        'product': products,
        'adverId': this.adverId,
        'ordCode': targetObj['ordCode'],
        'totalPrice': targetObj['totalPrice'],
        'totalQty': targetObj['totalQty']
      });
    }catch{
      return super.getConversionData();
    }
  }

}