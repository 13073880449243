/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-23
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2020-10-23
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class IlcommglDataParser extends GodomallRentDataParser {
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    if( this.isMobile(this.commandOptions) ) {
      return {
        'price' : document.querySelector('div.price_box del') ? document.querySelector('div.price_box del')!.textContent!.replace(/\D/g, '').trim() : 0,
        'dcPrice' :document.querySelector('div.price_box strong.price') ? document.querySelector('div.price_box strong.price')!.textContent!.replace(/\D/g, '').trim() : 0
      };
    }
    return super.getProductPriceObj(formElement);
  }
}