/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-15
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-12-15
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Yjfnb0110DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
      let price = 0, dcPrice = 0;
      if(this.isMobile(this.commandOptions)){
        price = NumberUtil.parseNumber(document.querySelector('.price_box del')!.textContent!.replace(/\D/g, ''));
        dcPrice = NumberUtil.parseNumber(document.querySelector('.price_box strong.price')!.textContent!.replace(/\D/g, ''));
      }else{
        price = NumberUtil.parseNumber(document.querySelector('.time_sale_price del')!.textContent!.replace(/\D/g, ''));
        dcPrice = NumberUtil.parseNumber(document.querySelector('.time_sale_price strong strong')!.textContent!.replace(/\D/g, ''));
      }
      return {
        price: price > dcPrice ? price : dcPrice,
        dcPrice: dcPrice
      }
    } catch (e) {
      return super.getProductPriceObj(formElement);
    }
  }
}