/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-27
 */

import * as TargetSelector from "../config/selector/button.observer.selector.json";
import {EventUtil} from "../../lib/event/EventUtil";
import {DeviceType, EventType, HostingType} from "../../types/GlobalEnums";

/**
 * create on 2020-03-27.
 * refactoring date 2021-03-25
 * <p>
 *   장바구니, 찜버튼 등 버튼에 이벤트를 주입되어야 하는 상황에서
 *   뒤늣게 로드되어 이벤트 바인딩이 안되는 상황을 위한 클래스
 * </p>
 * <pre>
 *   ※ 참고사항
 *   1. button.observer.selector.json에 맞게 데이터 정의 필요
 *    > Observer의 대상이 될 Target Element Selector 추가 필요
 * </pre>
 * <p> {@link MutationObserver } 관련 클래스 </p><br>
 * @version 1.0
 * @author mgpark
 */
export class BtnEventObserver {
  private hostingType: HostingType;
  private adverId: string;
  private eventType: EventType;
  private deviceType: string;

  private mutationOptions: {} = {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true
  };

  constructor(hostingType: HostingType, adverId: string, eventType: EventType, device: string) {
    this.hostingType = hostingType;
    this.adverId = adverId;
    this.eventType = eventType;
    this.deviceType = this.getDeviceType(device);
  }

  /**
   * MutationObserver를 통해 observing 시작
   * @param eventName
   * @param eventListener
   * @param buttonSelector
   */
  startingObserving(eventName: string, eventListener: EventListener, buttonSelector: string): void {
    try {
      const targetElementList: NodeListOf<Element> = document.querySelectorAll(TargetSelector[this.eventType][this.adverId][this.deviceType]);
      if (!targetElementList && targetElementList!.length > 0) return;

      targetElementList.forEach((target) => {
        new MutationObserver(() => this.btnObserveCallbackFunc(eventName, eventListener, buttonSelector)).observe(target, this.mutationOptions);
      })
    } catch (e) {
    }
  }

  /**
   * 버튼 클릭 이벤트의 MutationObserver에 대한 callback 함수
   * > callback 함수 STEP
   *   - STEP 1. 변경이 감지된 시점에 이벤트 바인딩 될 버튼이 존재하면 이벤트 주입
   * @param eventName 바인딩할 이벤트 이름(ex: click...)
   * @param eventListener 바인딩될 listener
   * @param buttonSelector  바인딩할 버튼의 HTML Selector
   * @private
   */
  private btnObserveCallbackFunc(eventName: string, eventListener: EventListener, buttonSelector: string): void {
    try {
      const btnArr: NodeListOf<Element> = document.querySelectorAll(buttonSelector)!;

      if (btnArr && btnArr.length > 0) {
        this.injectBtnEvent(btnArr, eventName, eventListener);
      }
    } catch (e) {
    }
  }

  /**
   * 버튼들에 이벤트를 주입
   * @param btnArr  버튼 List
   * @param eventName 주입시 이벤트 이름
   * @param eventListener EventListener
   * @private
   */
  private injectBtnEvent(btnArr: NodeListOf<Element>, eventName: string, eventListener: EventListener): void {
    btnArr.forEach((btn) => {
      EventUtil.addEvent(btn, eventName, eventListener);
    });
  }

  /**
   * 광고주가 Mutation 대상인지 확인
   */
  isMuatationAdvertiser(): boolean {
    try {
      return TargetSelector[this.eventType][this.adverId] && Object.keys(TargetSelector[this.eventType][this.adverId]).length > 0;
    } catch (e) {
      return false;
    }
  }

  /**
   * device에 따라 값을 리턴(기본값 web)
   * @param device
   * @private
   */
  private getDeviceType(device: string): string {
    switch (device) {
      case DeviceType.WEB:
        return "web";
      case DeviceType.MOBILE:
        return "mobile";
      case DeviceType.BOTH:
        return "both";
      default:
        return "web";
    }
  }
}
