/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-14
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-04-14.
 * <p> 다른 광고주 들과 다른 로직이 있어서 별도 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class TgrnmanDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /** TGRN은 이미지가 HTTP로 되어 있을경우 프로토콜 변경이 적용이 HTTPS로 이미지 변경 후 적용 */
  protected getImageUrl(): string {
    let imgSrc: string;

    if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      imgSrc = (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else if (document.querySelector('div#productDetail ul li.left_img>img')) {
      imgSrc = (document.querySelector('div#productDetail ul li.left_img>img') as HTMLImageElement).src;
    } else {
      return '';
    }

    return imgSrc.search('https') < 0 ? imgSrc.replace('http', 'https') : imgSrc;
  }
}
