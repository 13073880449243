/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CallbackFunction } from '../../../types/GlobalTypes';
import {AdBlockCtrl} from "../../adblock/AdBlockCtrl";

/**
 * TODO 주석, 불필요한 getter, setter 제거하고 접근지정자를 protected로 변경해야 한다.
 * create on 2019-08-02.
 * <p> 데이터 전송시 대상 서버 정보, 광고주 ID 등을 갖는 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Config {
  protected _domain: string;
  protected _adverId: string;
  protected _protocol: string;
  protected _baseUrl!: string;
  protected _url!: string;
  protected _callback?: CallbackFunction;
  protected options?: {};

  constructor(adverId: string, options?: {}) {
    this._adverId = adverId;
    this.options = options;
    this._protocol =
      typeof this.options === 'object' && this.options['buildTest'] === true
        ? 'http://'
        : 'https://';
    this._domain = AdBlockCtrl.getInstance().getDomain();
  }

  get adverId(): string {
    return this._adverId;
  }

  get protocol(): string {
    return this._protocol;
  }

  get baseUrl(): string {
    return this._baseUrl;
  }

  get url(): string {
    return this._url;
  }

  get callback(): CallbackFunction | undefined {
    return this._callback;
  }

  get domain(): string {
    return this._domain;
  }

}
