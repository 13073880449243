/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-26
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-10-26
 * <p> Eurohomme(유로옴므) </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class EurohommeDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();
    jsonObj.data = this.getOriginalProductData(jsonObj.data);
    return jsonObj;
  }

  protected getCartCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getCartCollectData();
    jsonObj.data = this.getOriginalProductData(jsonObj.data);
    return jsonObj;
  }

  protected getWishCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getWishCollectData();
    jsonObj.data = this.getOriginalProductData(jsonObj.data);
    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj: {} = super.getPayConvDataFromProductPage();

    const productList: Array<{}> = [];
    for (let i = 0; i < jsonObj['product'].length; i++) {
      productList.push(this.getOriginalProductData(jsonObj['product'][i]));
    }

    jsonObj['product'] = productList;
    return jsonObj;
  }

  private getOriginalProductData(jsonObj: {}): {} {
    jsonObj['dcPrice'] = document.querySelector('#span_product_coupon_dc_price')
        ? document.querySelector('#span_product_coupon_dc_price')!.textContent!.split(window['price_unit_tail'])[0]
        : 0;
    jsonObj['imageUrl'] = this.isMobile(this.commandOptions)
        ? jsonObj['imageUrl']
        : (document.querySelector('.detail-image > img') as HTMLImageElement).src;

    return jsonObj;
  }
}