/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-26
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-10-26
 * <p> Buyshu(바닐라슈) </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BuyshuDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if(this.isMobile(this.commandOptions)) {
      return super.getConversionData_prev();
    }

    const productNameAnchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('a.rm_p_name');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productNameAnchorList.length; i++) {
      const qty: number = NumberUtil.parseInteger(document.querySelectorAll('td.quantity.rm_p_amount')[i].textContent!.replace(/\D/g ,'').trim());

      parsedProducts.push({
        'productCode': new QueryStringer(productNameAnchorList[i].search.substring(1)).getParam('product_no'),
        'productName': productNameAnchorList[i].textContent!.trim(),
        'qty': qty,
        'price': document.querySelectorAll('td.sell.rm_p_price')[i].textContent!.replace(/\D/g, '').trim()
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(location.search.substring(1)).getParam('order_id'),
      'totalPrice': document.querySelector('div.orderList > table > tfoot > tr > td > strong.total')!.textContent!.split(':')[1].replace(/\D/g ,''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}