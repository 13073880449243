
/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-15
 */
import { JsonObject } from '../../../lib/json/JsonObject';
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";


/**
 * create on 2021-12-15.
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class AnnanblueDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
          ? document.querySelector('.shop_name_in span.name')!.textContent!.trim()
          : document.querySelector('#div_content #sangse_name h3')!.textContent!.trim()
      }
    } catch (e) {
      return '';
    }
  }
}
