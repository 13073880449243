/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-05
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-05.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Himohimall21DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 가격, 할인가격를 파싱하여 객체로 리턴
   * @return {{price: number dcPrice: number}} 파싱된 가격, 할인가격
   */
  protected getProductPrice(): { price: number; dcPrice: number; } {
    try {
      let priceObj = super.getProductPrice();
      priceObj['price'] = NumberUtil.parseNumber((document.querySelector('input.option_box_price') )!['value']);
      return priceObj;
    } catch (e) {
      return super.getProductPrice();
    }
  }
}
