/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-08
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-03-08.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class RichwellDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }


  protected getWebConversionData(): JsonObject{
    const jsonObj: JsonObject = super.getWebConversionData();

    const product = jsonObj.data['product'];

    for(let i = 0, len = product.length; i < len; i++){
      product[i]['qty'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(4)')[i].textContent!.replace(/\D/g, '').trim();
      product[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }

}
