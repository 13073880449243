/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {CartType, EventType, HostingType, ProductTargetingType} from '../../types/GlobalEnums';
import {InvalidValueError} from '../../error/InvalidValueError';
import * as TargetingConfigObj from './productTargeting.config.json';
import {EventUtil} from '../../lib/event/EventUtil';
import {Tracker} from '../../tracker/Tracker';

/**
 * create on 2019-10-19.
 * <p> 상품 타겟팅 이벤트 관리 (장바구니, 찜 버튼 등) </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ProductTargeting {
  private readonly adverId: string;
  private readonly eventType: EventType;
  private readonly hostingType: HostingType;
  private readonly targetingType: ProductTargetingType;
  private readonly options: {};

  /* 이용 가능한 이벤트 타입 */
  private static availableEvnetTypes: EventType[] = [EventType.CART, EventType.WISH];
  private readonly cartType: CartType;
  private readonly eventTargetList: NodeList;
  private readonly _eventName: string;
  private readonly _eventListener: EventListener;

  constructor(adverId: string, eventType: EventType, hostingType: HostingType, options: {}) {
    self = this; // this 바인딩 이슈 방지
    this.adverId = adverId;
    this.eventType = eventType;
    this.hostingType = hostingType;
    this.options = options;
    this.targetingType = ProductTargeting.getTargetingType(this.eventType);
    this.cartType = ProductTargeting.getCartType(this.eventType);

    this.eventTargetList = this.createEventTargetList(this.targetingType);
    this._eventName = 'click';
    this._eventListener = this.invokeProductTargetingCommand.bind(self);
  }

  get eventName(): string {
    return this._eventName;
  }

  get eventListener(): EventListener {
    return this._eventListener;
  }

  static isAvailableEventType(eventType: EventType): boolean {
    return (
        ProductTargeting.availableEvnetTypes.filter(value => value === eventType)
            .length > 0
    );
  }

  addTargetingEvent(): void {
    if (ProductTargeting.isAvailableEventType(this.eventType)) {
      for (let i = 0; i < this.eventTargetList.length; i++) {
        EventUtil.addEvent(this.eventTargetList[i], this._eventName, this._eventListener);
      }
    }
  }

  removeTargetingEvent(): void {
    for (let i = 0; i < this.eventTargetList.length; i++) {
      EventUtil.removeEvent(this.eventTargetList[i], this._eventName, this._eventListener);
    }
  }

  private static getTargetingType(eventType: EventType): ProductTargetingType {
    switch (eventType) {
      case EventType.CART:
        return ProductTargetingType.CART;
      case EventType.WISH:
        return ProductTargetingType.WISH;
      default:
        throw new InvalidValueError('eventType');
    }
  }

  private static getCartType(eventType: EventType): CartType {
    switch (eventType) {
      case EventType.CART:
        return CartType.OWN_CART;
      case EventType.WISH:
        return CartType.OWN_WISH;
      default:
        throw new InvalidValueError('eventType');
    }
  }

  private createEventTargetList(targetingType: ProductTargetingType): NodeList {
    try {
      if (
          typeof this.options !== 'undefined' &&
          typeof this.options['btnSelector'] !== 'undefined'
      ) {
        return document.querySelectorAll(this.options['btnSelector']);
      } else {
        let device: 'web' | 'mobile' = 'web';
        switch ((this.options['device'] as string).toUpperCase()) {
          case 'W':
            device = 'web';
            break;
          case 'M':
            device = 'mobile';
            break;
          default:
            device = 'web';
            break;
        }

        return document.querySelectorAll(TargetingConfigObj[this.hostingType][device][targetingType]['btnSelector']);
      }
    } catch (e) {
      throw new Error('Required property is missing such as "btnSelector" or "device" from options.');
    }
  }

  private invokeProductTargetingCommand(): void {
    try {
      /* 똑같은 백그라운드 작업을 수행하지 않도록 하여 커맨드 실행 */
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand(['set', this.eventType, {'cartType': this.cartType}]);
      tracker.executeCommand(['send', this.eventType, this.adverId]);
    } catch (e) {
      console.error(e);
    }
  }
}

/* 이벤트 등록 및 제거시 this 바인딩을 위한 변수 */
let self: ProductTargeting;
