/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-18
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2021-08-18
 * <p> http://www.petpang.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ConveniiDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    
    if(this.isMobile(this.commandOptions)){
      let img = (document.querySelector('#contents div.TopImg') as HTMLDivElement).style['backgroundImage'];
      jsonObj.replaceData('imageUrl', img.substring(img.indexOf('//'), img.lastIndexOf('"')));
    }
    
    return jsonObj;
  }
}
