/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-19
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-07-19
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class PackusDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    const superData = super.getProductPriceObj(formElement);
  
    let discount =  NumberUtil.parseNumber((document.querySelector('div form input[name="goodsDiscount"]') as HTMLInputElement).value);
    let price =  NumberUtil.parseNumber((document.querySelector('div form input[name="set_goods_price"]') as HTMLInputElement).value);
    const  discountUnit =  (document.querySelector('div form input[name="goodsDiscountUnit"]') as HTMLInputElement).value;

    if(discountUnit=="price"){
      let dcprice=  price - discount ;
      superData['dcPrice'] =  dcprice;
    } 
     
    return superData;
  }
}