/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-12
 */
import { JsonObject } from '../../../lib/json/JsonObject';
import { SelfHostedDataParser } from '../SelfHostedDataParser';

/**
 * create on 2019-11-12.
 * <p> 테스트를 위한 데이터 파싱 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class TesterDataParser extends SelfHostedDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCartCollectData(): JsonObject {
    return new JsonObject({
      'adverId': this.adverId,
      'event': 'cart'
    });
  }

  protected getConversionData(): JsonObject {
    return new JsonObject({
      'adverId': this.adverId,
      'event': 'conversion'
    });
  }

  protected getPayConvDataFromBasketPage(): {} {
    return {
      'adverId': this.adverId,
      'event': 'collect',
      'paySys': 'nPay',
      'fromPage': 'basket'
    };
  }

  protected getPayConvDataFromProductPage(): {} {
    return {
      'adverId': this.adverId,
      'event': 'collect',
      'paySys': 'nPay',
      'fromPage': 'product'
    };
  }

  protected getPayConversionData(): JsonObject {
    return super.getPayConversionData();
  }

  protected getShopCollectData(): JsonObject {
    return new JsonObject({
      'adverId': this.adverId,
      'event': 'collect'
    });
  }

  protected getCommonTraceData(): JsonObject {
    return new JsonObject({
      'adverId': this.adverId,
      'event': 'common'
    });
  }

  protected getWishCollectData(): JsonObject {
    return new JsonObject({
      'adverId': this.adverId,
      'event': 'wish'
    });
  }

  protected isBasketPage(): boolean {
    return false;
  }

  protected isProductPage(): boolean {
    return false;
  }

  private isDeploy(): boolean { return false; }
}
