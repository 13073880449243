import { InvalidData } from '../../../lib/ajax/InvalidData';
import { KeywordPair } from '../dataType/KeywordPair';

export abstract class InternalKeywordSelfHosted {
  
  protected abstract extract(): KeywordPair | InvalidData;

  /**
   * 키워드에 불필요한 문자들을 제거
   * @param {string} keyword - 키워드 문자열
   * @return {string} - 불필요한 문자들이 제거된 키워드 (에러 발생시 빈 문자열)
   */
  protected removeForbiddenChars(keyword: string): string {
    try {
      return keyword.replace(/[^-_\uAC00-\uD7A3xfe0-9a-zA-Z\u3131-\u314e\s]/g, '');
    } catch (e) {
      return '';
    }
  }
}