/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-11-16
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";

/**
 * create on 2022-11-16
 * <p>  </p>
 * <p> {@GodomallRentDataParser } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Vittz1DataParser extends MakeshopD4DataParser {

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
        ? document.querySelector('.detail_info .item_name')!.textContent!.trim()
        : this.getSelector(['#frmView p.p_name'])!.textContent!.trim();
    } catch {
      return super.getProductName();
    }
  }

  protected getImageUrl(): string {
    try {
      if (this.isMobile(this.commandOptions)) {
        return (document.querySelector('.goods_view li.slick-active img') as HTMLImageElement).src;
      }
      return super.getImageUrl();
    } catch {
      return '';
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll([
        '.cart_list td span.order_sum_txt.price'
      ])[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (this.getSelectorAll([
        'input[name=orgamount]'
      ])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll([
          'input[name=branduid]'
        ])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll([
          '.cart_list tr .pick_add_info em a'
        ])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['form[name="forms"] strong.prc .prdctPrc span'])[i] as HTMLSpanElement).textContent!;
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.name a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }
}