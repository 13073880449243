/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-04-28
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-04-28.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class CncoazipDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    try{
      if(this.isMobile(this.commandOptions)){
        return (document.querySelector('.shopdetailInfoTop > figure > .thumb-wrap img') as HTMLImageElement).src;
      }
      return super.getImageUrl();
    }catch{
      return super.getImageUrl();
    }
  }
}
