/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-02-09
 */
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";


/**
 * create on 2023-02-09.
 * <p> Asdasd1404 광고주 파싱 </p>
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *Cafe24SmartDataParser
 * @version 1.0
 * @author yjlee
 */
export class Asdasd1404DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  } 
   protected getPayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions))
      jsonObj['totalPrice'] = document.querySelector('table td:nth-child(5) div.box strong span')!.textContent!.replace(/\D/g,'');
    return jsonObj;
  }
}