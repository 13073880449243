/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-10-25
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on  2022-10-25
 * <p> Edenlx 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class EdenlxDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('#total_order_price_front')!.textContent!.replace(/\D/g, ''));
    return jsonObj;
  }
  
}
