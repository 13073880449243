/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-09-22
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";

/**
 * create on 2022-09-22.
 *
 * @version 1.0
 * @author sjchun
 */
export class AmocoblingDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('div#cart form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['div#cart table tbody td strong.prc_sell'])[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (this.getSelectorAll(['div#cart table tbody input[name="orgamount"]'])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll(['div#cart table tbody input[name="branduid"]'])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll(['div#cart table tbody .prd_name a'])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('#basket form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['form[name="forms"] .product-info .price-sell'])[i] as HTMLSpanElement).textContent!.replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('#basket form[name="forms"] input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('#basket form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('#basket form[name="forms"] .info-name.pname span:not(.Close_btn_cart)')[i] as HTMLSpanElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }
}
