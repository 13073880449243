/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-09-09
 */
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";


/**
 * create on 2020-09-09.
 * <p> Mulawear 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class MulawearDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"]>figure figcaption div.infoprice p')[i].textContent!.trim().replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': document.querySelectorAll('form[name="forms"]>figure figcaption p.cart-prdcode a')[i].textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    if (document.querySelector('span.MK_total_price span.MK_chg_total_price')) {
      totalPrice = NumberUtil.parseNumber(document.querySelector('span.MK_total_price span.MK_chg_total_price')!.textContent!.trim().replace(/\D/g, ''));
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('div.table-cart table tbody td div.item_quantity')[i].textContent!.trim().replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': document.querySelectorAll('div.table-cart table tbody td:nth-child(6) div')[i].textContent!.trim().replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart p.cart-prdcode > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    if (document.querySelector('span.MK_total_price > span.MK_chg_total_price')) {
      totalPrice = NumberUtil.parseNumber(document.querySelector('span.MK_total_price > span.MK_chg_total_price')!.textContent!.trim().replace(/\D/g, ''));
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 광고주 요청으로 인해 3번째 이미지 파싱
   * 단, 3번째 이미지가 없으면 첫번째 이미지 파싱
   * @return [description]
   */
  protected getImageUrl(): string {
    if (document.querySelector('div#productDetail div.thumb-box>ul.thumb-list>li:nth-child(3) img')) {
      return (document.querySelector('div#productDetail div.thumb-box>ul.thumb-list>li:nth-child(3) img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop div.swiper-slide[data-swiper-slide-index="2"] img')) {
      return (document.querySelector('div.shopdetailInfoTop div.swiper-slide[data-swiper-slide-index="2"] img') as HTMLImageElement).src;
    } else if (document.querySelector('div#productDetail div.thumb img')) {
      return (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop div.swiper-slide[data-swiper-slide-index="0"] img')) {
      return (document.querySelector('div.shopdetailInfoTop div.swiper-slide[data-swiper-slide-index="0"] img') as HTMLImageElement).src;
    } else {
      return super.getImageUrl();
    }
  }

}
