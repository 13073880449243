/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-01-15
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";

/**
 * create on 2021-01-15
 * <p> Styledump - http://www.styledump.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class StyledumpDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.data['price'] = document.querySelector('.org_price > span > s > .num')
        ? document.querySelector('.org_price > span > s > .num')!.textContent!.trim()
        : window['gl_goods_price'];
    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    jsonObj['product'][0]['price'] = document.querySelector('.org_price > span > s > .num')
        ? document.querySelector('.org_price > span > s > .num')!.textContent!.trim()
        : window['gl_goods_price'];
    return jsonObj;
  }

  protected getPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_list .cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productName = document.querySelectorAll('span.goods_name')[i].textContent!.trim();
        const productCode = document.querySelectorAll('input[name="cart_option_seq[]"]')[i].getAttribute('rel');
        const productQty = NumberUtil.parseNumber(document.querySelectorAll('div.cart_quantity')[i].childNodes[1]!.textContent!.trim());
        const productPrice = NumberUtil.parseRationalNumber(document.querySelectorAll('span.total_p span.num')[i].textContent!.trim());
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.sum_price .num')!.textContent!),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}