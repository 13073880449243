/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-03
 */
import {FunctionExecuteData} from "../data/FunctionExecuteData";
import {StringUtil} from "../../lib/common/StringUtil";
import {JsonObject} from "../../lib/json/JsonObject";
import {HostingType} from "../../types/GlobalEnums";

/**
 * create on 2020-04-03.
 * <p> 광고주 화면이 뒤늦게 로드되어서 function이 동작 안하는 경우
 * 해당 클래스에 Observer를 사용해서 로드 완료 된 뒤 function을 수행 </p>
 * <p> {@link MutationObserver } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class FunctionExecuteObserver {
  private targetElement: Element | null;
  private funcExeData: FunctionExecuteData | null;

  constructor(hostType: HostingType) {
    this.funcExeData = this.getObserverData(hostType);
    this.targetElement = this.funcExeData && !StringUtil.isEmpty(this.funcExeData.targetSelector)
        ? document.querySelector(this.funcExeData.targetSelector) as Element
        : null;
  }

  /**
   * function과 관련된 정보가 있는 HTML 객체를 Target으로 해서 Observe 동작 후 function 호출
   * @param objClass: function의 class 인스턴스
   * @param funcName: 수행될 function의 이름
   * @param extraData: function의 파라미터
   */
  startingObserving(objClass: any, funcName: string, extraData: JsonObject | undefined) {
    let observer: MutationObserver;

    if (this.targetElement && this.funcExeData) {
      observer = new MutationObserver((mutations, obs) => {
        let formLoadComplete = false;

        //form이 로드 완료 되었는지 form 안의 요소(input)를 감지 대상에서 찾음
        for (let i = 0; i < mutations.length; i++) {
          const element: Element = mutations[i].target as Element;
          const id = element.getAttribute(this.funcExeData!.attribute);

          if (!id) continue;
          if (element.tagName === this.funcExeData!.tagName && id.search(this.funcExeData!.regExp) > -1) {
            formLoadComplete = true;
            break;
          }
        }

        if (formLoadComplete && objClass[funcName]) {
          objClass[funcName](extraData);
          obs.disconnect();
        }
      });

      observer.observe(this.targetElement, {
        attributes: true,
        childList: false,
        subtree: true,
        characterData: true
      });
    }
  }

  /**
   * send() 함수에서 web파싱 과정에서 Element가 없어서 오류가 발생했을 경우
   * Element가 로드가 완료 된 후 Observer 동작을 위한 데이터 세팅
   * @param hostingType
   */
  private getObserverData(hostingType: string): FunctionExecuteData | null{
    let data: FunctionExecuteData | null = new FunctionExecuteData();

    switch (hostingType) {
      case HostingType.GODOMALL_RENT:
        data.targetSelector = "div.goods_view, div#content";
        data.tagName = "INPUT";
        // todo []는 \\가 두번 들어가 있음. 확인 필요
        data.regExp = /set[\\_\\]/;
        data.attribute = "id";
        break;
      default:
        data = null;
        break;
    }

    return data;
  }
}
