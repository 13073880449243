/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-31
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {GeneralTree} from "../../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../../../category/Category";
import {GTNode} from "../../../lib/dataStructure/tree/general/GTNode";
import {Queue} from "../../../lib/dataStructure/queue/Queue";

/**
 * create on 2020-03-31.
 * <p> 고니샵 데이터 파싱 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class GonyshopDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let type: string = window.location.pathname.indexOf('/m/') === 0 ? 'm' : 'w';

    //고니샵은 카테고리 코드가 중복이 되는것이 많아서 카테고리 트리에서 Node를 가져올때 code, parentCode 둘다 비교 후 가져온다
    let mcode: HTMLInputElement | null = document.querySelector('form#form1 input[name="mcode"]')! as HTMLInputElement;
    let xcode: HTMLInputElement | null = document.querySelector('form#form1 input[name="xcode"]')! as HTMLInputElement;
    let mcodeValue: string = "";
    let xcodeValue: string = "";

    if (mcode != null) {
      if (type === 'm' || mcode.value === '000') {
        mcodeValue = xcode.value;
      } else {
        xcodeValue = xcode.value;
        mcodeValue = mcode.value;
      }
    } else {
      mcodeValue = xcode.value;
    }

    this.gonyParseCategory(mcodeValue, xcodeValue);

    const discountType: HTMLInputElement | null = document.querySelector('input#discount_type');

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          : document.querySelector('form#form1')!['price']['value'],
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          : discountType != null && discountType.value != "1"
              ? "" : document.querySelector('form#form1')!['disprice']['value'],
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  /**
   * 상품정보를 담고있는 테이블이 기존과 다름
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('ul.clearfix dl.item a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('ul.clearfix dl.item a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('ul.clearfix dl.item dd')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.replace(/[\u25B6]/g, "").trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  /**
   * 고니샵은 이미지 DOM Element의 위치가 다름
   */
  protected getImageUrl(): string {
    if (document.querySelector('div#productDetail>div.page-body div.thumb>img')) {
      return (document.querySelector('div#productDetail>div.page-body div.thumb>img') as HTMLImageElement).src;
    } else if (document.querySelector('div#detail-item>div.info-wrap>div.items>img')) {
      return (document.querySelector('div#detail-item>div.info-wrap>div.items>img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }

  /**
   * 고니샵의 경우 상품명 HTML DOM 위치 정보가 다름
   */
  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#detail-item > h2')!.textContent!.trim()
            : document.querySelector('form#form1>div.info>div#sangse_name>h3')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  protected getMobileTotalPrice(): string {
    try {
      let totalPriceElement: Element | null = document.querySelector('table>tbody>tr>td>strong');
      let totalPriceValue: string = "";
      if (totalPriceElement != null && totalPriceElement.textContent != null) {
        totalPriceValue = totalPriceElement.textContent.trim();
      }
      if (totalPriceValue.indexOf("원") > -1) {
        totalPriceValue = totalPriceValue.replace("원", "");
      }
      return totalPriceValue;
    } catch (e) {
      return '0';
    }
  }

  protected gonyParseCategory(currentCategoryCode: string, parentCategoryCode: string): void {
    const tree: GeneralTree<Category> | undefined = this.receiveTreeFromStorage();
    this.gonyInitCategory(tree, currentCategoryCode, parentCategoryCode);
  }

  /**
   * 전송할 카테고리 데이터를 트리를 이용해 초기화.
   * <ul>
   *   <li>현재 상품에 대한 카테고리를 찾지 못하면 이름값이 아닌 코드값으로 초기화 한다.</li>
   *   <li><code>localStorage</code>의 데이터를 트리로 변환하지 못하면 카테고리 값은
   *   <code>undefined</code>로 초기화 된다.</li>
   * </ul>
   * @param {GeneralTree<Category> | undefined} tree  트리
   * @param {string} currentCategoryCode 현재 보고 있는 상품의 카테고리 코드
   * @param {string} parentCategoryCode 부모 카테고리 코드
   */
  protected gonyInitCategory(tree: GeneralTree<Category> | undefined, currentCategoryCode: string, parentCategoryCode: string): void {
    try {
      const leafNode: GTNode<Category> | undefined = this.gonySearchCategoryNode(tree, currentCategoryCode, parentCategoryCode);
      if (typeof leafNode === 'undefined') {
        this.category.topCategory = currentCategoryCode;
      } else {
        const hierarchyQueue: Queue<string> = this.createHierarchyQueue(leafNode);
        this.setAllOfCategoryData(this.category, hierarchyQueue);
      }
    } catch (e) {
      this.category.topCategory = GlobalVariables.unknownCategory;
      this.category.firstSubCategory = undefined;
      this.category.secondSubCategory = undefined;
      this.category.thirdSubCategory = undefined;
    }
  }

  /**
   * 코드에 해당되는 노드를 트리에서 탐색 후 반환. 찾지 못하면 <code>undefined</code>
   * ## 고니샵은 카테고리 코드가 중복되는게 많아 Tree Node의 code만 가져오면 잘못된 값을 가져오는 경우가 있음
   * @param {GeneralTree<Category> | undefined} tree  트리
   * @param {string} currentCategoryCode  현재 보고 있는 상품의 카테고리 코드
   * @param {string} parentCategoryCode 부모 카테고리 코드
   * @return {GTNode<Category> | undefined} 찾은 노드
   */
  protected gonySearchCategoryNode(tree: GeneralTree<Category> | undefined, currentCategoryCode: string, parentCategoryCode: string): GTNode<Category> | undefined {
    try {
      return tree!.search(currentNode => currentNode.value.code === currentCategoryCode && currentNode.value.parentCode === parentCategoryCode);
    } catch (e) {
      return undefined;
    }
  }
}
