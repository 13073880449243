/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="yjlee:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-13
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-07-13
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ParnellDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);``
  }

  protected isProductPage(): boolean {
    try {
      return window.location.pathname === '/shop/view';
    } catch (e) {
      return false;
    }
  }
  protected isBasketPage(): boolean {
    try {
      return window.location.pathname.replace(/.php/, '') === '/order/cart' ;
    } catch (e) {
      return false;
    }
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    const superData = super.getWebShopCollectData(formElement);

    superData.data['imageUrl'] = (document.querySelector('div.thumb-detail img.middle') as HTMLImageElement).src;
    superData.data['productName'] = Array.prototype.map.call(document.querySelectorAll('#frmView h2.font-garamond div'), function(t) { return t.textContent.trim(); }).join(',').replace(/\,/g,'');

    return superData;
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let superData = super.getWebPayConvDataFromProductPage(formElement);
    
    try{
      const tprice =NumberUtil.parseNumber(document.querySelector('dd strong.total_price')!.textContent!.replace(/\D/g,''));
      superData['product'][0]['productName'] =  Array.prototype.map.call(document.querySelectorAll('#frmView h2.font-garamond div'), function(t) { return t.textContent.trim(); }).join(',').replace(/\,/g,'');
      superData['product'][0]['qty'] = 1;
      superData['totalQty'] = 1;
      superData['totalPrice'] =  tprice ? tprice : super.getProductPriceObj(formElement)['dcPrice'];
            return superData;
    }catch{
      return superData;
    }

  }



}
