/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-15
 */
import { MakeshopD4DataParser } from '../MakeshopD4DataParser';

/**
 * create on 2023-03-15.
 * <p>  광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class BtmktDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getImageUrl(): string {
      try{
        if(this.isMobile(this.commandOptions)){
          return (document.querySelector('section.shopdetailInfo div.shopdetailInfoTop figure div img') as HTMLImageElement).src;
        }else{
          return super.getImageUrl();
        }
      }catch{
        return super.getImageUrl();
      }
    }
}
