/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-03-09.
 * <p> Cosrxebiz 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class CosrxebizDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
    }


  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('figcaption.rt_order p:nth-child(2)')[i] as HTMLSpanElement)!.textContent!.split('/')[1].trim().replace(/\D/, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': (document.querySelectorAll('figcaption.rt_order p.title')[i] as HTMLParagraphElement).textContent!.trim() ,
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': document.querySelector('.rs_price')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }




    /**
     * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
     * @return {{}} 파싱 결과
     */
    protected getMobilePayConvDataFromBasketPage(): {} {
      const parsedProducts: Array<{}> = [];
      const productAmount = document.querySelectorAll('form[name="forms"]').length;
      let totalPrice = 0;
      let totalQty = 0;

      for (let i = 0; i < productAmount; i++) {
        const rawPrice = (document.querySelectorAll('form[name="forms"] figcaption.basketRight p:nth-child(2) span.rs_price')[i] as HTMLParagraphElement).textContent!.trim().replace(/\D/g, '');
        const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

        const price = NumberUtil.parseNumber(rawPrice);
        const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

        totalPrice += price;
        totalQty += qty;

        parsedProducts.push({
          'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
          'productName': (document.querySelectorAll('p.pname>a:first-child, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
          'price': price / qty,
          'qty': qty
        });
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'totalPrice': totalPrice.toString(),
        'totalQty': totalQty.toString()
      };
    }

    /**
     * 주문완료 웹 버전에서 정보 파싱
     * @return {JsonObject} 파싱된 결과
     */
    protected getWebConversionData(): JsonObject {
      const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < productInfoLength; i++) {
        const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
        const qty = (document.querySelectorAll('div.table-cart>table tr td:nth-child(3)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

        parsedProducts.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': productAnchorElem.textContent,
          'qty': qty,
          'price': (document.querySelectorAll('div.table-cart>table tr td:nth-child(5)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
        });

        totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
      }

      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': document.querySelector('form[name="orderdetail"]')
            ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
            : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
        'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
        'totalQty': totalQty.toString()
      };

      return new JsonObject(parsedData);
    }

    protected getImageUrl(): string {
      if (document.querySelector('img.detail_image')) {
        return (document.querySelector('img.detail_image') as HTMLImageElement).src;
      } else if (document.querySelector('div.slick-slide:nth-child(2) div.thumb-slide img')) {
        return (document.querySelector('div.slick-slide:nth-child(2) div.thumb-slide img') as HTMLImageElement).src;
      } else if (document.querySelector('section.shopdetailInfo>div.shopdetailInfoTop>figure>img')) {
        return (document.querySelector('section.shopdetailInfo>div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
      } else {
        return '';
      }
    }
}
