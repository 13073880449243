/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
* create on 2020-04-02.
 * <p> Bosod 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BosodDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div#container ul.clearfix li dl.item dt a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div#container ul.clearfix li dl.item dt a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div#container ul.clearfix li dl.item dd')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': this.getMobildProductName(productAnchorElem),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': document.querySelector('strong#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
  
  private getMobildProductName(element: Element): string {
    let productName = '';
    if(element.querySelector('span') != null && element.querySelector('span') != undefined){
      productName = element!.textContent!.replace(element.querySelector('span')!.textContent!.trim(), '').trim();
    } else {
      productName = productName = element!.textContent!.trim();
    }
    return productName;
  }

  protected getImageUrl(): string {
    if (super.isMobile(this.commandOptions)) {
      return (document.querySelector('#detail-item > div.info-wrap > div.items > img') as HTMLImageElement).src;
    } else {
      return (document.querySelector('#productDetail > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > img') as HTMLImageElement).src;
    }
  }
}
