/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-30
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";


/**
 * create on 2021-07-30.
 * <p> Bluevivi 관련 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Withhome11DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      return this.isMobile(this.commandOptions)
        ? document.querySelector('dt.font_shopdetailA')!.textContent!.trim()
        : document.querySelector('#div_content #sangse_name h3')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }
}
