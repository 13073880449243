/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-02-28
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-02-28.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class OctoscapeDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (document.querySelector('#prddetailimg img')) {
      return (document.querySelector('#prddetailimg img') as HTMLImageElement).src;
    } else {
      /* 빈 문자열을 전송하면 서버에서 수집 안함 */
      return super.getImageSrc();
    }
  }
}
