/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-10-25
 */

import {JsonObject} from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-10-25
 * <p> Rdbcorp 광고주 관련 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class RdbcorpDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let superData: JsonObject = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
      const price =document.querySelector('span#span_product_price_text')!.textContent!.replace(/\D/g,'');
      const dcPrice = document.querySelector('span#span_product_price_mobile_text')!.textContent!.replace(/\D/g,'');
      superData.data['price'] = (price != dcPrice) ? price : (document.querySelector('tbody.priceArea tr td.custom_price')!.textContent!.replace(/\D/g,''));
      superData.data['dcPrice'] =dcPrice ;
    }else{
      superData.data['price'] = document.querySelector('div.detail_cont  tbody tr td div.line')!.textContent!.replace(/\D/g,'');
      superData.data['dcPrice'] = document.querySelector('div.detail_cont  tbody tr td div.sale_price')!.textContent!.replace(/\D/g,'');

    }
    return superData;
  }

}