/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-07-17
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-07-17
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ComzipDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {} = {};
    if(document.querySelector('#group_frm > input[type=hidden][name=gr_id]')){
      parsedData = {
        'adverId': this.adverId,
        'productCode': document.querySelector('#group_frm > input[type=hidden][name=gr_id]')!['value'],
        'productName': document.querySelector('#group_frm > div.title') ?  document.querySelector('#group_frm > div.title')!.textContent : document.querySelector('div.opt_box > div.title')!.textContent,
        'price': document.querySelector('#group_frm ul.item_info li span.price')!.textContent!.replace(/\D/g,''),
        'productUrl': window.location.href,
        'dcPrice': 0,
        'soldOut': !!window['is_soldout_icon']
            ? window['is_soldout_icon'] === 'T'
                ? 'Y'
                : 'N'
            : !!window['EC_FRONT_JS_CONFIG_SHOP']
                ? window['EC_FRONT_JS_CONFIG_SHOP']['bSoldout']
                    ? 'Y'
                    : 'N'
                : 'N',
        'imageUrl': document.querySelectorAll('div.sub_content div.wrap div.left img')[0]['src'],
        'topCategory': '',
        'firstSubCategory': '',
        'secondSubCategory': '',
        'thirdSubCategory': '',
      };
    }
    else{
      parsedData = {
        'adverId': this.adverId,
        'productCode':document.querySelector('form > input[type=hidden][name="it_id[]"]')!['value'],
        'productName': document.querySelector('div.item_detail_info  div.title') ?  document.querySelector('div.item_detail_info  div.title')!.textContent : document.querySelector('div.sit_ov_wr > div#sit_title')!.textContent,
        'price':document.querySelector('div input#it_price')!['value'],
        'productUrl': window.location.href,
        'dcPrice': 0,
        'soldOut': !!window['is_soldout_icon']
            ? window['is_soldout_icon'] === 'T'
                ? 'Y'
                : 'N'
            : !!window['EC_FRONT_JS_CONFIG_SHOP']
                ? window['EC_FRONT_JS_CONFIG_SHOP']['bSoldout']
                    ? 'Y'
                    : 'N'
                : 'N',
        'imageUrl': document.querySelector('div.detail_wrap > div.img > div > img') ? document.querySelector('div.detail_wrap > div.img > div > img')!['src'] : document.querySelector('div.item_detail div.item_img img')!['src'],
        'topCategory': '',
        'firstSubCategory': '',
        'secondSubCategory': '',
        'thirdSubCategory': '',
      };
    }
    return new JsonObject(parsedData);
  }
  protected getConversionData_prev(): JsonObject {

    const parsedProducts: Array<{}> = [];
    let totalQty = 0;
    let totalPrice = 0;
    const rawProductDataArr: NodeListOf<HTMLDivElement> = document.querySelectorAll('.order_list tr td div.item_wrap');
    if( document.querySelectorAll('.order_list tr td div.item_wrap').length>0){
    for (let i = 0; i < rawProductDataArr.length; i++) {
      const anchorElem: HTMLAnchorElement | null = rawProductDataArr[i].querySelector('div.sod_name>a');
      const productName: string = anchorElem!.textContent!.trim();
      let productCodeSize = document.querySelectorAll('#sod_fin_list  div > div.sod_img > img')[i]['src'].split('/').length;
      const productCode =document.querySelectorAll('#sod_fin_list  div > div.sod_img > img')[i]['src'].split('/')[productCodeSize-2];
      let qty = NumberUtil.parseNumber(document.querySelectorAll('.order_list td:nth-child(2)')[i].textContent!);
      let price = NumberUtil.parseNumber(document.querySelectorAll('.order_list td:nth-child(3)')[i].textContent!); 
      parsedProducts.push({
        'productCode':productCode,
        'productName': productName,
        'qty': qty,
        'price':price
      });

      totalQty += qty;
      totalPrice += price;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('#sod_fin_no > span')!.textContent,
      'totalPrice': document.querySelector('#sod_bsk_tot2 > li:nth-child(5) > div.content')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty
    };
    return new JsonObject(parsedData);

  }
  else{
    for (let i = 0; i < document.querySelectorAll('div#sod_fin ul#sod_list_inq li').length; i++) {
      let productCode = '';
      let productCodeSize = document.querySelectorAll('div#sod_fin ul#sod_list_inq li.sod_li > a div img')[i]['src'].split('/').length;
      if( document.querySelectorAll('#sod_list_inq > li > a')[0].querySelector('img[alt="NO IMAGE"]')){
        const productAnchorElem = document.querySelectorAll('#sod_list_inq > li > a')[i] as HTMLAnchorElement;
        productCode = new QueryStringer(productAnchorElem.search.substring(1)).getParam('it_id');
      }else{
        productCode =document.querySelectorAll('div#sod_fin ul#sod_list_inq li.sod_li > a div img')[i]['src'].split('/')[productCodeSize-2];
      }
      let qty = NumberUtil.parseNumber(document.querySelectorAll('#sod_list_inq div.contents div.values > div:nth-child(1) > span')[i].textContent!.replace(/\D/g,''));
      let price = NumberUtil.parseNumber(document.querySelectorAll('div.contents div.values > div:nth-child(2) > span')[i].textContent!.replace(/\D/g,'')); 
      parsedProducts.push({
        'productCode': productCode,
        'productName':  document.querySelectorAll('div#sod_fin ul#sod_list_inq li.sod_li .item_name')[i].textContent,
        'qty': qty,
        'price':price
      });

      totalQty += qty;
      totalPrice += price;
    }
    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('div#sod_fin div#sod_fin_no span')!.textContent,
      'totalPrice': document.querySelector('li div.sod_bsk_cnt.content span')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty
    };
    return new JsonObject(parsedData);

  }
  }
}
