/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {NaverTargeting} from "./NaverTargeting";
import {CartType} from "../../types/GlobalEnums";

/**
 * create on 2020-03-09.
 * <p> 클래스 설명 </p>
 * <p> {@link NaverTargeting} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NaverCartTargeting extends NaverTargeting {
  constructor(adverId: string) {
    super(adverId, CartType.NAVER_CART);
  }
}
