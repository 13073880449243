/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-22
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import json = Mocha.reporters.json;

/**
 * create on 2020-10-22
 * <p> Mayblue 광고주 DataParser </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class MayblueDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('span.snzCartSinglePrice')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-left > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('td.snz-cart-total-price')!.textContent!.replace(/\D/g, '').trim(),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      const rawPrice = NumberUtil.parseInteger(document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i].textContent!.split(':')[1].replace(/\D/g, '').trim());
      productList[i]['price'] = rawPrice / productList[i]['qty'];
    }

    jsonObj['totalPrice'] = document.querySelector('div.price-total-info-bottom span')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();
    const productAmount = document.querySelectorAll('div.table-cart tbody td:nth-child(2) a')

    const productTempList = new Array();
    let totalQty = 0;
    for (let i = 0; i < productAmount.length; i++) {
      const productAnchorElem: HTMLAnchorElement = productAmount[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('div.table-cart tbody td:nth-child(3)')[i].textContent!.replace(/\D/g, '').trim();

      productTempList.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': document.querySelectorAll('div.table-cart tbody td:nth-child(4)')[0].textContent!.replace(/\D/g, '').trim()
      });

      totalQty += NumberUtil.parseInteger(qty);
    }

    jsonObj.data['product'] = productTempList;
    jsonObj.data['totalQty'] = totalQty;
    return jsonObj;
  }

  protected getMobileConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getMobileConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div.product_info tr td div a')[i].childNodes[0].textContent!.trim();
    }

    return jsonObj;
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
          ? document.querySelector('div.sde-pname')!.textContent!.trim()
          : document.querySelector('#form1 div.info > h3')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }
}