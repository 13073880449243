/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-21
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2023-04-21
 * <p> zinsuinc - www.zinus.co.kr</p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ZinsuincDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try{
        return {
          'price' : NumberUtil.parseNumber(formElement['set_goods_price']!.value),
          'dcPrice' : NumberUtil.parseRationalNumber(formElement['set_coupon_dc_price']!.value) 
            };
    }catch{
      return super.getProductPriceObj(formElement);
    }
    
  }
}