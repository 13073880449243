/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-03
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-01-03
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class HisaengDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    superData['price'] = NumberUtil.parseNumber(document.querySelector('td span strong#span_product_price_text')!.textContent!.replace(/\D/g,''));
   return superData;
  }
}
