/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-06-02
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2022-06-02
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class HoyunDataParser extends GodomallRentDataParser {

  protected getConversionData(): JsonObject {
    return this.isMobile(this.commandOptions) ? this.getMobileConversionData() : this.getWebConversionData();
  }

  protected getWebConversionData(): JsonObject {
    if(super.isOrderPage()) return super.getConversionData();

    const parsedProducts: Array<{}> = [];
    const orderList = document.querySelectorAll('tr.order-goods-layout');
    let totalQty = 0;
    let qty = '';
    for(let i = 0; i < orderList.length; i++){
      qty = orderList[i].querySelector('.td_order_amount strong')!.textContent!.replace(/\D/g, '');
      parsedProducts.push({
        'productCode': orderList[i].getAttribute('data-goodsno'),
        'productName': orderList[i].querySelector('.pick_add_info em a')!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber(orderList[i].querySelector('strong.order_sum_txt.price')!.textContent!)
      });

      totalQty += NumberUtil.parseNumber(qty);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('estSno'),
      'totalPrice': document.querySelector('#totalSettlePrice')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobileConversionData(): JsonObject {
    if(super.isOrderPage()) return super.getConversionData();

    const parsedProducts: Array<{}> = [];
    const orderList = document.querySelectorAll('div.order-goods-layout');
    let totalQty = 0;
    let qty = '';
    for(let i = 0; i < orderList.length; i++){
      let priceInfo = JSON.parse((document.querySelectorAll('input[name*="priceInfo["]')[i] as HTMLInputElement).value);
      qty = priceInfo['goodsCnt'];
      parsedProducts.push({
        'productCode': orderList[i].getAttribute('data-goodsno'),
        'productName': orderList[i].querySelector('.tit')!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber(priceInfo['goodsPrice'])
      });

      totalQty += NumberUtil.parseNumber(qty);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('estSno'),
      'totalPrice': document.querySelector('#totalSettlePrice')!.textContent!.replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

}