import { NumberUtil } from "../../../lib/common/NumberUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-11-13
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-11-13.
 * <p> nuerx 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class KidsbookDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProducts: Array<{}> = jsonObj.getDataByName('product');

    for (let i = 0; i < parsedProducts.length; i++) {
      parsedProducts[i]['price'] = NumberUtil.parseNumber(document.querySelectorAll('div.table-cart > table > tbody tr')[i]!.querySelector('td:nth-child(5)')!.textContent!.trim());
    }

    jsonObj.replaceData('product', parsedProducts);

    return jsonObj;
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();

    jsonObj.replaceData('imageUrl', this.getImageSrc());

    return jsonObj;
  }

  protected getImageSrc(): string {
    if (super.isMobile(this.commandOptions)) {
      return (document.querySelector('div.thumb-wrap > img.detail_image') as HTMLImageElement).src;
    } else {
      return (document.querySelector('div.page-body > div.width1100 > div.thumb-info > div.thumb-wrap > img') as HTMLImageElement).src;
    }
  }

}
