/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-08
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-07-08.
 * <p> chaye 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class WherehouseDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if(this.isMobile(this.commandOptions)){
      if(document.querySelector('.multi-img__item.swiper-slide.swiper-slide-active img')){
        return (document.querySelector('.multi-img__item.swiper-slide.swiper-slide-active img') as HTMLImageElement).src;
      }
      return super.getImageUrl();
    }

    return super.getImageUrl();
  }
}
