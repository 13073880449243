/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {DetailImageParser} from "../../../detailImageParser/DetailImageParser";

/**
 * create on 2020-03-09.
 * <p> Chocomom 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ChocomomDataParser extends MakeshopD4DataParser {
  private detailImageParser: DetailImageParser;

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
    this.detailImageParser = new DetailImageParser();
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(3) div.tb-center')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(4) div.tb-center.tb-bold')[i].textContent!.replace(/\D/g, '').trim()
      totalQty += NumberUtil.stringToNumber(qty);
    }

    jsonObj.data['totalQty'] = totalQty;

    return jsonObj;
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.childNodes[0].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span, table.cart_box_a tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left > a, table.cart_box_a tr td div.tb-left > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('div.table-cart tfoot strong')!.textContent!.replace(/\D/g, '').trim(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:nth-child(2)')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.txt-price')!.textContent!.replace(/\D/g, '')!.trim(),
      'totalQty': totalQty.toString()
    };
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
          ? document.querySelector('main.shopdetail div.shop_de_name div.shop_name_in')!.childNodes[0]!.textContent!.trim()
          : document.querySelector('#form1 div.info div.name')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getShopCollectData(): JsonObject {
    const parsedJsonObject: JsonObject = super.getShopCollectData();
    this.detailImageParser.addDetailImageList(parsedJsonObject, 'div#detailImage>img');
    return parsedJsonObject;
  }
}
