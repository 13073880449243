/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-19
 */
import {ExternalKeywordExtractor} from "./external/ExternalKeywordExtractor";
import {InternalKeywordExtractor} from "./internal/InternalKeywordExtractor";
import {JsonObject} from "../../lib/json/JsonObject";
import {KeywordStorage} from "./KeywordStorage";
import {EventType, KeywordSessionType, KeywordType} from "../../types/GlobalEnums";
import {Keyword} from "./dataType/Keyword";
import {StorableKeyword} from "./dataType/StorableKeyword";
import {KeywordPair} from "./dataType/KeywordPair";
import {InvalidData} from "../../lib/ajax/InvalidData";
import * as InternalSelfHostedConfig from './internal/config/InternalSelfHosted.config.json';

/**
 * create on 2020-05-19.
 * <p> 전환 키워드의 추출, storage 저장, storage에서 가져오는 과정을 facade 패턴으로 통합 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class KeywordManager {
  /* 키워드의 세션타입 순서 */
  private readonly KeywordSessionTypeOrder: KeywordSessionType[];
  private storage: KeywordStorage;
  constructor() {
    this.KeywordSessionTypeOrder = [KeywordSessionType.SESSION, KeywordSessionType.INDIRECT];
    this.storage = new KeywordStorage();
  }

  /**
   * 외부키워드 추출 및 저장
   */
  storeExternalKeyword(): void {
    try {
      //외부키워드 저장
      const external: ExternalKeywordExtractor = new ExternalKeywordExtractor(document.referrer);
      const externalKeyword: KeywordPair | InvalidData = external.extract();

      this.storage.setItem(KeywordSessionType.SESSION, externalKeyword, KeywordType.EXTERNAL);
      this.storage.setItem(KeywordSessionType.INDIRECT, externalKeyword, KeywordType.EXTERNAL);

    } catch (e) { }
  }

  /**
   * 내부키워드 추출 및 저장
   * 상품코드가 없을시 세션스토리지에만 저장하여 공통내부키워드 저장
   */
  storeInternalKeyword(adverId: string, productCode: string) {
    try {
      //내부키워드 저장
      let internalKeyword: KeywordPair | InvalidData;

      if(InternalSelfHostedConfig[adverId]){
        const classPath = './internal/advertisers/';
        const className = InternalSelfHostedConfig[adverId];
        import(`${classPath + className}`).then((selfHosted) => {
          const parser = new selfHosted.StockcomInternalParser();
          internalKeyword = parser.extract();
          this.storage.setItem(KeywordSessionType.SESSION, internalKeyword, KeywordType.INTERNAL, productCode);
          this.storage.setItem(KeywordSessionType.INDIRECT, internalKeyword, KeywordType.INTERNAL, productCode);
        });
      }else{
        const internal: InternalKeywordExtractor = new InternalKeywordExtractor(document.referrer);
        internalKeyword = internal.extract(adverId);
        this.storage.setItem(KeywordSessionType.SESSION, internalKeyword, KeywordType.INTERNAL, productCode);
        this.storage.setItem(KeywordSessionType.INDIRECT, internalKeyword, KeywordType.INTERNAL, productCode);
      }
    } catch (e) { }
  }

  /**
   * 세션, 로컬스토리지에 저장된 외부키워드 데이터를 탐색 후 반환
   * @param  eventType
   * @return 저장된 외부 키워드 | undefined(못찾은 경우)  [description]
   */
  takeOutExternalKeyword(eventType: EventType): Keyword | undefined {
    try {
      let keyword: Keyword | undefined = undefined;

      for (let i = 0; i < this.KeywordSessionTypeOrder.length; i++) {
        const keywordJsonObj: JsonObject | string = this.storage.getItem(this.KeywordSessionTypeOrder[i]);
        let externalKeyword: StorableKeyword | null = keywordJsonObj[KeywordType.EXTERNAL] ? keywordJsonObj[KeywordType.EXTERNAL] : null;

        if (externalKeyword && externalKeyword.toString().length > 0) {
          keyword = this.getRefinedDataType(externalKeyword);
          break;
        }

        //상품정보 수집(collect)일때는 세션스토리지의 데이터만 전송 -> 유입처 수집을 위해 localStorage도 수집
        // if (eventType === EventType.COLLECT || eventType === EventType.COMMON) {
        //   break;
        // }
      }

      return keyword;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 세션, 로컬스토리지에 저장된 내부키워드를 탐색 후 반환
   * @param  eventType
   * @param  productCode
   * @return Keyword : 저장된 내부키워드 데이터 중 상품고드와 일치하는 데이터 | undefined(못찾은 경우)
   */
  takeOutInternalKeyword(eventType: EventType, productCode: string): Keyword | undefined {
    try {
      let keyword: Keyword | undefined = undefined;

      if (!productCode) {
        return undefined;
      }

      for (let i = 0; i < this.KeywordSessionTypeOrder.length; i++) {
        const keywordJsonObj: JsonObject | string = this.storage.getItem(this.KeywordSessionTypeOrder[i]);
        const internalKeyword: StorableKeyword[] = keywordJsonObj[KeywordType.INTERNAL];

        if (internalKeyword) {
          for (let i = 0; i < internalKeyword.length; i++) {
            if (internalKeyword[i]['productCode'] && internalKeyword[i]['productCode']!.toString() === productCode.toString()) {
              keyword = this.getRefinedDataType(internalKeyword[i]);
              break;
            }
          }
        }

        //상품정보 수집(collect)일때는 세션스토리지의 데이터만 전송
        if (keyword || eventType === EventType.COLLECT) {
          break;
        }
      }

      return keyword;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 세션 스토리지에서 공통유입 키워드 반환
   */
  takeOutCommonInternalKeyword(): Keyword | undefined {
    try {
      const keywordJsonObj: JsonObject | string = this.storage.getItem(KeywordSessionType.SESSION);
      const internalKeyword: StorableKeyword[] = keywordJsonObj[KeywordType.INTERNAL];

      if(internalKeyword && internalKeyword[0]['keywordType'] === KeywordType.COMMON) {
        return internalKeyword ? this.getRefinedDataType(internalKeyword[0]) : undefined;
      } else {
        return undefined;
      }
    } catch (e) {
      return undefined;
    }
  }

  /**
   * 저장가능한 형태의 키워드 데이터 형태로 변경
   * @param  keyword [description]
   * @return         [description]
   */
  private getRefinedDataType(keyword: StorableKeyword): Keyword {
    return {
      'keywordValue': (keyword as StorableKeyword).keywordValue,
      'keywordType': (keyword as StorableKeyword).keywordType,
      'keywordSessionType': (keyword as StorableKeyword).keywordSessionType,
      'keywordUrl': (keyword as StorableKeyword).keywordUrl
    };
  }
}
