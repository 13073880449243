/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-28
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2022-07-28
 * <p>  </p>
 * <p> {@GodomallRentDataParser } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WinwinstyleDataParser extends GodomallRentDataParser {

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);

    const price =document.querySelector('form#frmView')!['set_goods_price'].value;
    const discountPrice =document.querySelector('form#frmView')!['goodsDiscount'].value;
    const dcPrice = NumberUtil.parseRationalNumber(price) - NumberUtil.parseRationalNumber(discountPrice); 

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': document.querySelector('div.item_detail_tit h3')!.textContent!.trim(), 
      'price': document.querySelector('form#frmView')!['set_goods_price'].value,
      'dcPrice': dcPrice,
      'qty': this.getParsedTotalQty(formElement)
    });

    const totalPrice = dcPrice * this.getParsedTotalQty(formElement); 

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': this.getParsedTotalQty(formElement)
    };

  }
}