/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee1</a>
 * @since 2022-10-19
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-10-19.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class CentralDataParser extends Cafe24SmartDataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getShopCollectData(): JsonObject {
        const jsonObj = super.getShopCollectData();
        if (this.isMobile(this.commandOptions)) {
            jsonObj.data['price'] = document.querySelector('div.prd-price-custom')!.textContent!.replace(/\D/g,'');
            jsonObj.data['dcPrice'] = document.querySelector('span#span_product_price_text')!.textContent!.replace(/\D/g,'');
        }
        return jsonObj;
      }

      protected getPayConvDataFromProductPage(): {} {
        const jsonObj = super.getPayConvDataFromProductPage();
        if (this.isMobile(this.commandOptions)) {
            jsonObj['product'][0]['price'] = document.querySelector('span#span_product_price_text')!.textContent!.replace(/\D/g,'');
        }
        return jsonObj;
      }
}