/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-18
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2023-04-18.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class IitinfraDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    const parsedProducts: Array<{}> = [];
    const ordCode: string =  document.querySelector('#content table > tbody > tr:nth-child(2) > td')!.textContent!;
    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': document.querySelector(' #content > div.contents > div > div.table1 > table > tbody > tr:nth-child(10) > td > strong')!.textContent!.replace(/\D/g,''),
      'totalQty': 1
    };

    return new JsonObject(parsedData);
  }
 
}
