/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-07-11
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2022-07-11
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SuperioriDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    let superData: JsonObject = super.getWebShopCollectData(formElement);
    try{
      superData.data['productName'] = document.querySelector('.item_detail_tit h3')!.textContent;
      superData.data['imageUrl'] = (document.querySelector('meta[name="twitter:image"]') as HTMLMetaElement).content;
      return superData;
    }catch{
      return superData;
    }
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let superData = super.getWebPayConvDataFromProductPage(formElement);
    try{
      superData['product'][0]['productName'] = document.querySelector('.item_detail_tit h3')!.textContent;
      return superData;
    }catch{
      return superData;
    }
  }
}