/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */
import { GodomallRentDataParser } from '../GodomallRentDataParser';
import { QueryStringer } from '../../../lib/url/QueryStringer';
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { JsonObject } from '../../../lib/json/JsonObject';
import { GlobalVariables } from '../../../types/GlobalVariables';

/**
 * create on 2020-04-02.
 * <p> Cellnb01 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Cellnb01DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': document.querySelector('div.item_detail_tit h3')!.childNodes[0]!.textContent!.trim(),
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': (document.querySelector('div.content_box span.img_photo_big img') as HTMLImageElement).src,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': this.isMobile(this.commandOptions) ?
          (document.querySelector('div.cont_detail li>img') as HTMLImageElement).alt :
          document.querySelector('div.item_detail_tit h3')!.childNodes[0]!.textContent!.trim(),
      'price': priceObj['price'],
      'qty': this.getParsedTotalQty(formElement),
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
      'imageUrl': (document.querySelector('div.content_box span.img_photo_big img, div.cont_detail li>img') as HTMLImageElement).src,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'].value,
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }
}
