// 스토리지 타입
export enum CrmStorageType {
  SESSION = "session",
  LOCAL = "local",
}

// 스토리지 저장되는 데이터 Key
export enum CrmStorageKey {
  LOGIN_ID = 'mobtune_login_id',                    // 사용자 아이디 데이터 Key
  COMMON_EXTRA_DATA = 'mobtune_common_extra',       // 공통 추가 데이터 Key(결제시도, 로그인 시도 등 기타데이터)
  USER_EXTRA_DATA = 'mobtune_user_extra',           // 사용자 정보 추가 데이터 Key(아이디 등)
  MOBTUNE_CONVERSION_URL = 'mobtune_conversion_url' // 주문완료 URL이 담겨있는 Key
}

// mobtune 스크립트 import시 사용되는 주소 및 파일명
export enum ScriptUrl {
  DEV_URL = 'https://cdn.megadata.co.kr/crm_dist/dev/',
  BASE_URL = 'https://cdn.megadata.co.kr/crm_dist/prod/',
  CAFE24_SMART = 'mobtune_crm_cafe24.min.js',
  SELF_HOSTED = 'mobtune_crm_self_hosted.min.js',
  MAKESHOP = 'mobtune_crm_makeshop_d4.min.js',
  GODOMALL_RENT = 'mobtune_crm_godomall_rent.min.js',
}

// mobtune 스크립트 EventType 구분
// (mobtune에서는 eventType을 BehaviorsType으로 명명)
export enum BehaviorsType {
  COMMON = 'mobtuneCommon',   //공통
}
