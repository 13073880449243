/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-06
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2023-03-06
 * <p> emaxsol 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class EmaxsolDataParser  extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedObject: {} = super.getMobilePayConvDataFromBasketPage();
      parsedObject['totalPrice'] = NumberUtil.parseNumber(document.querySelector('main#basket div p.price-total-val strong')!.textContent!.trim().replace(/\D/g, '')).toString();
      return parsedObject;
  }
}