/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-07-16
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-07-16.
 * <p> spash1 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class Spash1DataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    /**
     * 주문완료 모바일 버전에서 정보 파싱
     * @return {JsonObject} 파싱된 결과
     */
    protected getMobileConversionData(): JsonObject {
        const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
        const productInfoLength = document.querySelectorAll('div#container ul.clearfix li dl.item dt a').length
        const parsedProducts: Array<{}> = [];
        let totalQty = 0;

        for (let i = 0; i < productInfoLength; i++) {
            const productAnchorElem = document.querySelectorAll('div#container ul.clearfix li dl.item dt a')[i] as HTMLAnchorElement;
            const qty = (document.querySelectorAll('div#container ul.clearfix li dl.item dd')[i] as HTMLSpanElement).textContent!.trim().replace(/\D/g,'');

            parsedProducts.push({
                'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
                'productName': this.getMobildProductName(productAnchorElem),
                'qty': qty,
                'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
            });

            totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
        }

        const parsedData: {} = {
            'product': parsedProducts,
            'adverId': this.adverId,
            'ordCode': ordCode,
            'totalPrice': document.querySelector('strong#mk_totalprice')!.textContent!.trim().replace(/\D/g, ''),
            'totalQty': totalQty
        };

        return new JsonObject(parsedData);
    }


    /**
     * 주문완료 웹 버전에서 정보 파싱
     * @return {JsonObject} 파싱된 결과
     */
    protected getWebConversionData(): JsonObject {
        const productInfoLength = document.querySelectorAll('div.order-list:nth-child(2) tbody tr a').length;
        const parsedProducts: Array<{}> = [];
        let totalQty = 0;

        for (let i = 0; i < productInfoLength; i++) {
            const productAnchorElem = document.querySelectorAll('div.order-list:nth-child(2) tbody tr a')[i] as HTMLAnchorElement;
            const qty = (document.querySelectorAll('div.order-list:nth-child(2) tbody tr td:nth-child(3)')[i] as HTMLTableElement).textContent!.trim().replace(/\D/g,'');
                parsedProducts.push({
                'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
                'productName': productAnchorElem.textContent,
                'qty': qty,
                'price': (document.querySelectorAll('div.order-list:nth-child(2) tbody tr td:nth-child(4)')[i] as HTMLTableElement).textContent!.trim().replace(/\D/g,''),
            });
            totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

        }

        const parsedData: {} = {
            'product': parsedProducts,
            'adverId': this.adverId,
            'ordCode': document.querySelector('form[name="orderdetail"]')
                ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
                : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
            'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
            'totalQty': totalQty.toString()
        };

        return new JsonObject(parsedData);
    }

    private getMobildProductName(element: Element): string {
        let productName = '';
        if(element.querySelector('span') != null && element.querySelector('span') != undefined){
            productName = element!.textContent!.replace(element.querySelector('span')!.textContent!.trim(), '').trim();
        } else {
            productName = productName = element!.textContent!.trim();
        }
        return productName;
    }

    protected getImageUrl(): string {
        if (document.querySelector('div.swiper-container > div.swiper-wrapper > div.swiper-slide-active img')) {
            return (document.querySelector('div.swiper-container > div.swiper-wrapper > div.swiper-slide-active img') as HTMLImageElement).src;
        }else if (document.querySelector('img.detail_image')) {
            return (document.querySelector('img.detail_image') as HTMLImageElement).src;
        } else if (document.querySelector('div#productDetail div.thumb img')) {
            return (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src;
        } else if (document.querySelector('div#productDetail ul.swiper-wrapper>li>img')) {
            return (document.querySelector('div#productDetail ul.swiper-wrapper>li>img') as HTMLImageElement).src;
        } else if (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child')) {
            return (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
        } else if (document.querySelector('div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child')) {
            return (document.querySelector('div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
        } else if (document.querySelector('div.detailThumb>img')) {
            return (document.querySelector('div.detailThumb>img') as HTMLImageElement).src;
        } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
            return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
        } else if (document.querySelector('main.shopdetail div.swiper-wrapper img')) {
            return (document.querySelector('main.shopdetail div.swiper-wrapper img') as HTMLImageElement).src;
        } else if (document.querySelector('div.product-detail div.thumbnail img')) {
            return (document.querySelector('div.product-detail div.thumbnail img') as HTMLImageElement).src;
        } else if(document.querySelector('div#productDetail ul li.left_img>img')){
            return (document.querySelector('div#productDetail ul li.left_img>img') as HTMLImageElement).src;
        } else if (document.querySelector('main.shopdetail div.swiper-container1 img')) {
            return (document.querySelector('main.shopdetail div.swiper-container1 img') as HTMLImageElement).src;
        } else if (document.querySelector('div.thumb-wrap div.ori img')) {
            return (document.querySelector('div.thumb-wrap div.ori img') as HTMLImageElement).src;
        } else if (document.querySelector('div.visual-rolling ul.swiper-wrapper li.swiper-slide img')) {
            return (document.querySelector('div.visual-rolling ul.swiper-wrapper li.swiper-slide img') as HTMLImageElement).src;
        } else {
            return '';
        }
    }

}
