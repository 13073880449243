/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-24
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";

/**
 * create on 2021-03-24
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class LifecareDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('table>tbody>tr>td');
        const productName = productInfo[0].querySelector('div.goods_name')!.textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[0].querySelector('div.cart_ea')!.textContent!.split(':')[1]!.trim().match(/^[\d,]+/)![0];
        const productPrice = productInfo[0].querySelector('span.cart_price_num')!.textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': '',
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('span.sum_price>span.settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}