/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-08
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-10-08
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class HuecalmSaDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    const jsonObj: JsonObject = super.getMobileShopCollectData(formElement);
    jsonObj.data['dcPrice'] =document.querySelectorAll('div.price_box strong.price')[1]!.textContent!.replace(/\D/g,'');
    return jsonObj;
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    let superData = super.getMobilePayConvDataFromProductPage(formElement);
    superData['product'][0]['dcPrice'] = document.querySelectorAll('div.price_box strong.price')[1]!.textContent!.replace(/\D/g,'');
    superData['totalPrice'] = document.querySelector('li.total dd.total_price')!.textContent!.replace(/\D/g,'');
    return superData;
  }


}
