/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-03
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2023-04-03
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ShowroomaresDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      let superData = super.getProductPrice();
      superData['dcPrice'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content);
      return superData;
    } catch (e) {
      return super.getProductPrice();
    }
  }
  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    jsonObj['product'][0]['price'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content);
   
   if(this.isMobile(this.commandOptions)){
    const totalPrice =NumberUtil.parseNumber(document.querySelector('#totalPrice > span > strong')!.textContent!)
    if(totalPrice<1){
      jsonObj['totalPrice'] =NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content);
    }
  }else{
    const totalPrice =NumberUtil.parseNumber(document.querySelector('#totalPrice > span > strong > em')!.textContent!)
    if(totalPrice<1){
      jsonObj['totalPrice'] =NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content);
    }
  }
    return jsonObj;
  }
}