/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-08-24
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {Value} from "../../../lib/value/Value";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-08-24.
 * <p> Jsnlab 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class JsnlabDataParser  extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = document.querySelectorAll('form[name="forms"] div.box>div.info>p>strong.product_price_sell')[i]!.textContent!.trim().replace(/\D/g, '');
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('form[name="forms"] div.box>div.info>p>a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    if(document.querySelector('span#total_order_price_cartlist')){
      totalPrice = NumberUtil.parseNumber(document.querySelector('span#total_order_price_cartlist')!.textContent!.trim().replace(/\D/g, ''));
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div#orderfin table.prd tr td.tal').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div#orderfin table.prd tr td.tal a')[i] as HTMLAnchorElement;
      const qty = document.querySelectorAll('div#orderfin table.prd tr td:nth-child(4)')[i].textContent!.trim()!.replace(/\D/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent!.trim(),
        'qty': qty,
        'price': document.querySelectorAll('div#orderfin table.prd tr td:nth-child(5)')[i]!.textContent!.trim()!.replace(/\D/g, ''),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#orderfin table.tbl_order2 tr:nth-child(4) td')!.textContent!.trim().replace(/\D/g, ''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div#cart table.prd tbody td.cart_prc p')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div#cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div#cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div#cart table.prd tbody td.tal>a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

      if(document.querySelector('strong>span#total_order_price_cartlist')){
        totalPrice = NumberUtil.parseNumber(document.querySelector('strong>span#total_order_price_cartlist')!.textContent!.trim().replace(/\D/g, ''));
      }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    if(document.querySelector('div.wrap_prd div.img img#mainImg')){
      return (document.querySelector('div.wrap_prd div.img img#mainImg') as HTMLImageElement).src;
    } else if (document.querySelector('div#mimg_div img')) {
      return (document.querySelector('div#mimg_div img') as HTMLImageElement).src;
    } else {
      return '';
    }
  }
}
