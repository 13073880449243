/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-09-20.
 * <p> 이벤트를 다루는 유틸 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class EventUtil {
  private constructor() {}

  /**
   * 타겟에 이벤트를 등록 (IE 구버전 지원)
   * 오래된 IE와 같이 브라우저가 <code>addEventListener()</code> 메소드를 지원하지 않으면,
   * <code>attachEvent()</code>를 사용한다.
   * @param {EventTarget} eventTarget 이벤트가 등록될 타겟
   * @param {string} eventName  등록할 이벤트의 이름
   * @param {EventListener} eventListener  이벤트 리스너
   */
  static addEvent(eventTarget: EventTarget, eventName: string, eventListener: EventListener) {
    if (!eventTarget) {
      return;
    }

    if (eventTarget.addEventListener) {
      eventTarget.addEventListener(eventName, eventListener);
    } else if (eventTarget['attachEvent']) {
      eventTarget['attachEvent'](
          /* IE 8 이하에서는 'DOMContentLoaded' 이벤트가 없음 */
          eventName === 'DOMContentLoaded' ? 'onreadystatechange' : 'on' + eventName,
          eventListener
      );
    }
  }

  /**
   * 타겟에 등록된 이벤트를 제거 (IE 구버전 지원)
   * 오래된 IE와 같이 브라우저가 <code>removeEventListener()</code> 메소드를 지원하지 않으면,
   * <code>detachEvent()</code>를 사용한다.
   * @param {EventTarget} eventTarget 제거할 이벤트가 등록된 타겟
   * @param {string} eventName  제거할 이벤트의 이름
   * @param {EventListener} eventListener  이벤트 리스너
   */
  static removeEvent(
    eventTarget: EventTarget,
    eventName: string,
    eventListener: EventListener
  ) {
    if (!eventTarget) {
      return;
    }

    if (eventTarget.removeEventListener) {
      eventTarget.removeEventListener(eventName, eventListener);
    } else if (eventTarget['detachEvent']) {
      eventTarget['detachEvent'](
          /* IE 8 이하에서는 'DOMContentLoaded' 이벤트가 없음 */
          eventName === 'DOMContentLoaded' ? 'onreadystatechange' : 'on' + eventName,
          eventListener
      );
    }
  }
}
