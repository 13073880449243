/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-14
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2022-12-14
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Kwj8527DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      let superData = super.getProductPrice();
      if(this.isMobile(this.commandOptions)){
      superData['price'] = NumberUtil.parseNumber(document.querySelector('div p.custom')!.textContent!.replace(/\D/g,''));
      superData['dcPrice'] =NumberUtil.parseNumber(document.querySelector('div p.price')!.textContent!.replace(/\D/g,''));
      }
      return superData;
    } catch (e) {
      return super.getProductPrice();
    }
  }

}