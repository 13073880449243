
/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-13
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { ImwebDataParser } from "../ImwebDataParser";

/**
 * create on 2023-04-13
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export abstract class ResleepDataParser extends ImwebDataParser {

  private duplicateEvent: Array<string> = [];
  protected getCommonTraceData(): JsonObject {
    const jsonData: JsonObject = super.getCommonTraceData();
    this.addEventNodes();
    this.duplicateEvent = [];
    return jsonData;
  }

  /**
   * web/mobile node 추가 감지
   * (mutaion observer로 감지가 불가능하여 setTimeout형식으로 개발)
   */

  private addEventNodes(): void {
    let selector = '';  //document.querySelector로 선택할 요소
    let eventAdd: boolean = false;  //감지한 요소가 있을 경우 true
      selector = '.txt_c>button'
      let selectNode = document.querySelector(selector);
      if (selectNode) {
        eventAdd = true;
        this.conversionTracker(selector);
      } else{
        setTimeout(() => {
          this.addEventNodes();
        }, 1000);
      }
  }


  /**
   * 요소가 있는 경우 해당 요소에 비쇼핑 tracker 추가
   * @param selector document.querySelector로 선택할 요소
   * @param productName selector로 선택한 요소의 productName
   */

  private conversionTracker(selector: string): void {
    if (!this.duplicateEvent.some((str) => str === selector)) {
      this.duplicateEvent.push(selector);
      const tracker: Tracker = Tracker.getInstance(false);
        tracker.executeCommand([
          'create',
          'conversion',
          this.adverId,
          { 'device': 'B', btnSelector: selector, 'convType': 'join', 'productName': '회원가입' }
        ]);
      }

    }
  }
