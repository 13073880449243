/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-02
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-08-02
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Atower2015DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    try{
      const jsonObj: JsonObject = super.getWebShopCollectData(formElement);

      jsonObj.data['price'] = NumberUtil.parseNumber(document.querySelector('.oriprice')!.textContent!.replace(/\D/g, ''));
      jsonObj.data['dcPrice'] = NumberUtil.parseNumber(document.querySelector('strong.prc_in')!.textContent!.replace(/\D/g, ''));

      return jsonObj;
    }catch{
      return super.getWebShopCollectData(formElement);
    }
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    try{
      const jsonObj: JsonObject = super.getMobileShopCollectData(formElement);

      jsonObj.data['price'] = NumberUtil.parseNumber(document.querySelector('.ori_price')!.textContent!.replace(/\D/g, ''));
      jsonObj.data['dcPrice'] = NumberUtil.parseNumber(document.querySelector('strong.price')!.textContent!.replace(/\D/g, ''));

      return jsonObj;
    }catch{
      return super.getMobileShopCollectData(formElement);
    }
  }
}