/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Config } from './Config';

/**
 * create on 2019-09-10.
 * <p> 테스트를 위한 Config 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class TesterConfig extends Config {
  constructor(adverId: string, options?: {}) {
    super(adverId, options);

    this._url = 'http://tester.com:8000/';
    this._callback = (
      xhr: XMLHttpRequest,
      response: any,
      hasTransmitted: boolean
    ) => {
      const enlipleGroupTitle: string =
        '/=================================\\\n' +
        '|   Enliple Agent Script Tester   |\n' +
        '|                                 |\n' +
        '|                    2019.09.11   |\n' +
        '\\=================================/';

      const transmissionGroupTitle: string =
        '+-------------------------+\n' +
        '|   Transmission Status   |\n' +
        '+-------------------------+';

      const responseGroupTitle: string =
        '+-------------------------+\n' +
        '|    Response Received    |\n' +
        '+-------------------------+';

      if (typeof this.options === 'object' && this.options['print'] === true) {
        console.group(enlipleGroupTitle);
        console.group(transmissionGroupTitle);
        console.log(hasTransmitted);
        console.groupEnd();
        console.group(responseGroupTitle);
        console.log(response);
        console.groupEnd();
        console.groupEnd();
        console.log('\n');
      }
    };
  }
}
