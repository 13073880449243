import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-30
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-10-30.
 * <p> madamboutique 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class MadamboutiqueDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();

    const price = document.querySelector('div.price01')
                ? NumberUtil.parseNumber(document.querySelector('div.price01')!.textContent!.trim())
                : NumberUtil.parseNumber(document.querySelectorAll('span.shopdetailInfoCont')[0]!.textContent!.trim());

    const dcPrice = document.querySelector('div.price02')
                  ? NumberUtil.parseNumber(document.querySelector('div.price02')!.textContent!.trim())
                  : NumberUtil.parseNumber(document.querySelectorAll('span.shopdetailInfoCont')[1]!.textContent!.trim());

    jsonObj.replaceData('price', price);
    jsonObj.replaceData('dcPrice', dcPrice);

    return jsonObj;
  }

}
