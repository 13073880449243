/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-29
 */
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-04-29.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class HnmanagerDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * todo 리팩토링 필요
   * @return {JsonObject}
   */
  protected getConversionData(): JsonObject {
    const parsedProducts: Array<{}> = [];
    const divList: NodeListOf<HTMLDivElement> = document.querySelectorAll(".in_item");
    const totalPrice: number = NumberUtil.parseNumber((document.querySelector(".cost.total") as Element).textContent!.trim());
    const ordCode: string = new QueryStringer(window.location.search.substring(1)).getParam('ordno');


    // const parsedTotalPrice: number = super.getParsedTotalPrice();

    let totalQty = 0;

    for (let i = 0; i < divList.length; i++) {
      const productName: string = (divList[i].querySelector('.prod_name') as Element).textContent!.trim();
      const qty: number = NumberUtil.parseInteger((divList[i].querySelector('.prod_opt_bot .l_con em') as Element).textContent!.trim());
      const rawPrice: number = NumberUtil.parseInteger((divList[i].querySelector('.prod_opt_bot .r_con em') as Element).textContent!.trim());
      const anchorElem: HTMLAnchorElement = divList[i].querySelector('.prod_name') as HTMLAnchorElement;
      const productCode: string = new QueryStringer(anchorElem.search.substring(1)).getParam('goodsno');
      const price: number = rawPrice / qty;

      parsedProducts.push({
        'productCode': productCode,
        'productName': productName,
        'qty': qty.toString(),
        'price': price.toString()
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': totalPrice,
      'totalQty': totalQty.toString()
    };
    return new JsonObject(parsedData);
  }
}
