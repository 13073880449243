/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-06
 */
import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {NumberUtil} from '../../../lib/common/NumberUtil';

/**
 * create on 2020-03-06.
 * <p> 목우촌 광고주 파싱 </p>
 * <p> {@link GodomallRentDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MoguloveDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문 완료, 장바구니 페이지에서 배송비가 포함된 주문금액을 파싱하여 리턴.
   * <b>NOTE: </b>필요한 element를 참조하지 못하면 0을 리턴.
   * @return {number} 배송비 포함 주문금액
   */
  protected getParsedTotalPrice(): number {
    const parsedTotalPrice = (): number => {
      /* 주문완료 페이지 */
      if (this.isOrderPage()) {
        const totalPrice: string = this.isMobile(this.commandOptions)
            ? (document.querySelector('.prc') as Element)!.textContent!.trim()
            : (document.querySelector('tr>td>strong.c-red') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 장바구니 페이지 */
      else if (this.isBasketPage()) {
        const totalPrice: string = (document.querySelector('#totalSettlePrice') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 다른 페이지는 0원으로 처리 */
      else {
        return 0;
      }
    };

    try {
      return parsedTotalPrice();
    } catch (e) {
      return 0;
    }
  }
}
