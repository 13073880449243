/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-09-16
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {DetailImageParser} from "../../../detailImageParser/DetailImageParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-09-16.
 * <p> ehddnsirh 광고주. 상품상세이미지 여러개 수집하는 테스트를 위해 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class EhddnsirhDataParser extends Cafe24SmartDataParser {
  private detailImageParser: DetailImageParser;

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
    this.detailImageParser = new DetailImageParser();
  }

  protected getShopCollectData(): JsonObject {
    const parsedJsonObject: JsonObject = super.getShopCollectData();
    const imageSelector: string = this.isMobile(this.commandOptions) ? 'div#prdDetail>div[id^=prdDetail] img' : 'div#prdDetail>div.cont img';
    this.detailImageParser.addDetailImageList(parsedJsonObject, imageSelector);
    return parsedJsonObject;
  }
}
