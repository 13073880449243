/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-02-22
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
* create on 2022-02-22
* <p> {@link } and {@link } 관련 클래스 </p>
*
* @version 1.0
* @author sjchun
*/
export class EoryDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number;dcPrice: number } {
    if (document.querySelector('#ec-product-price-info')) {
      let priceValue = NumberUtil.parseNumber(document.querySelector('#ec-product-price-info')!.getAttribute('ec-data-custom')!);
      let dcPriceValue = NumberUtil.parseNumber(document.querySelector('#ec-product-price-info')!.getAttribute('ec-data-price')!);
      return {
        price: priceValue,
        dcPrice: dcPriceValue
      };
    } else {
      return super.getProductPrice();
    }
  }
}