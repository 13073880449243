/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-11-13
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-11-13.
 * <p> foxstory01 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Foxstory01DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    if (super.isMobile(this.commandOptions)) {
      return document.querySelector('dt.font_shopdetailA')!.textContent!.trim();
    } else {
      return document.querySelector('div.infoArea > div.table-opt > table > tbody > tr.name > td > div.tb-left')!.textContent!.trim();
    }
  }
}
