/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-06-03
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-06-03.
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class DfcorpoDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superPrice = super.getProductPrice();
    try {
      if (!this.isMobile(this.commandOptions)) {
        superPrice['dcPrice'] = NumberUtil.parseNumber(document.querySelector('#span_product_price_sale')!.textContent!.replace(/\D/g, ''));
      }
      return superPrice;
    } catch {
      return superPrice;
    }

  }
}
