/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-12-02
 */

import {CategoryNameRule} from "./CategoryNameRule";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-12-02
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class TopCategoryNameRule extends CategoryNameRule {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    return property ? super.applyRule(property) : GlobalVariables.unknownCategory;
  }

}