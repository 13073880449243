/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-27
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-11-27
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class SilhadaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev() : JsonObject {
    const productNamesList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.prdInfo strong.prdName a.ec-product-name');
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    for (let i = 0; i < productNamesList.length; i++) {
      const qty = NumberUtil.parseRationalNumber(document.querySelectorAll('div[nspace="individual"] div.prdInfo strong.quantity')[i].textContent!.trim());
      parsedProducts.push({
        'productCode': new QueryStringer((document.querySelectorAll('div.prdInfo p.prdImg a[href*="product_no"]')[i] as HTMLAnchorElement).search.substring(1)).getParam('product_no'),
        'productName': productNamesList[i].textContent,
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('div[nspace="individual"] div.orderArea > div > div > ul > li.price > span > span:nth-child(1)')[i].textContent!.trim())
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(location.search.substring(1)).getParam('order_id'),
      'totalPrice': document.querySelector('.totalPay .price')!.textContent!.trim(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}