/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-08-24
 */

import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2021-08-24
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Yearceo100 extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getWebShopCollectData();

    const price = NumberUtil.parseNumber(window['ENP_VAR']['price']);
    const dcPrice = NumberUtil.parseNumber(window['ENP_VAR']['dcPrice']);


    jsonObj.data['price'] = price > dcPrice ? price : dcPrice;
    jsonObj.data['dcPrice'] = window['ENP_VAR']['dcPrice'];

    return jsonObj;
  }
}
