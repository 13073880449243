/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-09-15
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-09-15.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ZancaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (this.isMobile(this.commandOptions)) {
      return (document.querySelector('.prdImgView ul li:nth-of-type(2) img') as HTMLImageElement).src;
    } else {
      return (document.querySelector('.imgArea ul li:nth-of-type(2) img') as HTMLImageElement).src;
    }
  }
}