import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-22
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-22.
 * <p> monsterf 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class MonsterfDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromProductPage(): {} {
    const payConvData: {} = super.getPayConvDataFromProductPage();
    const parsedProducts: Array<{}> = payConvData['product'];

    for (let i = 0; i < parsedProducts.length; i++) {
      const price = document.querySelector('#form' + (i + 1) + ' > input[name=price]') as HTMLInputElement;
      parsedProducts[i]['price'] = price!.value.trim().replace(/\D/g, '');
    }

    return payConvData;
  }
}
