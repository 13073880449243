/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Config } from './Config';
import { CommonTracerConfig } from './CommonTracerConfig';
import { ShopCollectorConfig } from './ShopCollectorConfig';
import { ConverterConfig } from './ConverterConfig';
import { EventType } from '../../../types/GlobalEnums';
import { TesterConfig } from './TesterConfig';
import { PayConverterConfig } from './PayConverterConifg';
import { PaySystem } from '../../paySystem/PaySystem';
import { CartCollectorConfig } from './CartCollectorConfig';
import { WishCollectorConfig } from './WishCollectorConfig';
import { ReviewCollectorConfig } from './ReviewCollectorConfig';
/**
 * TODO: 주석
 * create on 2019-08-05.
 * <p> <code>Config</code>을 생성하는 위임 클래스 </p>
 * <p> {@link Config} and {@link SendCommand} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ConfigFactory {
  private constructor() {}

  static createConfig(eventType: EventType, adverId: string, options?: {}): Config {
    switch (eventType) {
      case EventType.COMMON:
        return new CommonTracerConfig(adverId, options);
      case EventType.COLLECT:
        return new ShopCollectorConfig(adverId, options);
      case EventType.CART:
        return new CartCollectorConfig(adverId, options);
      case EventType.WISH:
        return new WishCollectorConfig(adverId, options);
      case EventType.CONVERSION:
        return PaySystem.isPaySystemConv(options)
          ? new PayConverterConfig(adverId, options)
          : new ConverterConfig(adverId, options);
      case EventType.REVIEW:
        return new ReviewCollectorConfig(adverId, options);
      case EventType.TEST:
        return new TesterConfig(adverId, options);
      default:
        throw new Error(`Invalid event name = ${eventType}`);
    }
  }
}
