/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {AJAXer} from '../lib/ajax/AJAXer';
import {StorageQueue} from './StorageQueue';
import {GlobalVariables} from "../types/GlobalVariables";

/**
 * create on 2019-10-03.
 * <p> 큐의 내용을 Web Worker를 이용해 재전송 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class QueueManager {
  private static instance: QueueManager;
  private readonly workerJsPath: string;
  /* Conversion 수행시 중복으로 전송되는 상황을 막기 위해
     QueueManager의 동작여부를 통제하는 변수  */
  private _toggle: boolean;

  private constructor() {
    this.workerJsPath = (window['enp'] && window['enp']['cdnUrl']
        ? window['enp']['cdnUrl']
        : GlobalVariables.cdnInfo.host.https)+ '/dist/prod/enp_worker.min.js';
    this._toggle = true;
  }

  /**
   * 인스턴스 생성
   * @return {QueueManager}
   */
  static getInstance(): QueueManager {
    if (!QueueManager.instance) {
      QueueManager.instance = new QueueManager();
    }

    return QueueManager.instance;
  }

  get toggle() {
    return this._toggle;
  }

  set toggle(value) {
    this._toggle = value;
  }

  /**
   * 큐의 내용을 재전송 시도한다.
   * (재전송에 성공하면 해당 데이터를 큐에서 제거)
   */
  resend(): void {
    /*
     * Web Worker API가 구현된 JS 리소스를 cross-origin으로 로드하기 위해
     * Blob 객체를 이용해 AJAX 통신
     */
    if (!this._toggle) {
      return;
    }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', this.workerJsPath);
    xhr.onload = function () {
      if (xhr.status === 200) {
        const workerSrcBlob = new Blob([xhr.responseText], {
          type: 'text/javascript'
        });
        const workerBlobURL = window.URL.createObjectURL(workerSrcBlob);
        const worker = new Worker(workerBlobURL);

        worker.onmessage = (ev: MessageEvent) => {
          try {
            const storageQueue: StorageQueue = StorageQueue.getInstance();
            const queueSize: number = ev.data < storageQueue.size()
                ? ev.data
                : storageQueue.size();

            /* 큐의 내용을 재전송 시도 */
            for (let i = 0; i < queueSize; i++) {
              const data = storageQueue.peek()!;
              AJAXer.getInstance().post(
                  data['destUrl'],
                  data['data'],
                  (xhr, response, hasTransmitted) => {
                    if (hasTransmitted) {
                      storageQueue.dequeue();
                    }
                  }
              );
            }

            worker.terminate();
          } catch (e) {
            return;
          }
        };

        worker.postMessage(StorageQueue.getInstance().size());

        /* 메모리 누수 방지를 위해 URL 해제 */
        window.URL.revokeObjectURL(workerBlobURL);
      }
    };

    xhr.send();
  }
}
