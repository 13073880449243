/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 */

import { SelfHostedDataParser } from "../SelfHostedDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { DeviceType } from "../../../types/GlobalEnums";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class OurhomemallDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (this.commandOptions['device'] === DeviceType.WEB) {
      jsonObj.data['reviewCount'] = document.querySelector('.titArea h3 .count em') ? document.querySelector('.titArea h3 .count em')!.textContent!.replace(/\D/g, '') : 0;
      jsonObj.data['reviewAverage'] = document.querySelector('.totalScore .score .num strong') ? document.querySelector('.totalScore .score .num strong')!.textContent!.trim() : 0;
    } else {
      jsonObj.data['reviewCount'] = document.querySelector('.tabBar li:nth-of-type(4) a') ? document.querySelector('.tabBar li:nth-of-type(4) a')!.childNodes[2].textContent!.replace(/\D/g, '') : 0;
      jsonObj.data['reviewAverage'] = document.querySelector('.score .num strong') ? document.querySelector('.score .num strong')!.textContent!.trim() : 0
    }
    return jsonObj;
  }
}
