/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-07-19
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-07-19
 * <p> desart - desart.co.kr</p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class DesartDataParser extends MakeshopD4DataParser {
  protected getWebConversionData(): JsonObject {
    const jsonObject = super.getWebConversionData();
    const productList = jsonObject.getDataByName('product');

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i]!.textContent!.trim());
    }

    return jsonObject;
  }
}
