/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-09
 */
/**
 * create on 2020-01-09.
 * <p> 서비스의 전역에서 사용되는 변수 정의 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GlobalVariables {
  /*
   * 알 수 없는 카테고리
   * 카테고리를 가져올 수 없는 경우에는 이 값으로 초기화한다.
   * 이 값으로 수집되면 추후에 서버에서 배치로 카테고리 매칭하는 작업 예정.
   */
  static unknownCategory = '_unknown';

  /**
   * CDN 정보
   * @type {{pathName: {config: string}; host: {http: string; https: string}}}
   */
  static cdnInfo: {pathName: {config: string}; host: {http: string; https: string}} = {
    host: {
      http: 'http://cdn.megadata.co.kr',
      https: 'https://cdn.megadata.co.kr'
    },
    pathName: {
      config: '/dist/config/id/'
    }
  };

  private constructor() {}
}
