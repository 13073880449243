/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-16
 */
import {FirstmallDataParser} from "../FirstmallDataParser";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import { EventUtil } from "../../../lib/event/EventUtil";
import { Tracker } from "../../../tracker/Tracker";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2023-03-16
 * <p> Ivenet01 광고주 별도 생성 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */


export class DrleanDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileConversionData(): {} {
    try {
      return {
        'product': window[this.ENP_OBJECT_NAME]['product'],
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
          document.querySelector('#layout_body dd > strong')!.textContent!.replace(/\D/g,'')
        ),
        'totalQty': window[this.ENP_OBJECT_NAME]['totalQty']
      };
    } catch (e) {
      return new InvalidData();
    }
  }

}