/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-11
 */
import URLParse from 'url-parse';
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import {KeywordPair} from "../dataType/KeywordPair";
import {AdverConfigJsonMapper} from '../../../adverConfigJsonMapper/AdverConfigJsonMapper';
import {EncodingConverter} from '../../../lib/common/EncodingConverter';
import {EncodingList, HostingType} from '../../../types/GlobalEnums';

/**
 * create on 2020-05-11.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class InternalKeywordExtractor {
  /* referrer */
  private readonly referrer: string;

  /* 각 검색엔진의 검색 키워드와 키워드를 담는 파라미터를 맵핑 */
  private readonly searchParamPairs: {
    [fieldName: string]: string[];
  } = {
    'cafe24_smart': ['keyword'],
    'makeshop_d2': ['search'],
    'makeshop_d4': ['search'],
    'firstmall': ['osearchtext', 'search_text'],
    'godomall_rent': ['keyword'],
    'godomall_self': ['stx', 'sword'],
    'imweb': ['keyword']
  };

  /* 키워드로 허용되지 않은 문자. not을 붙였음에 유의. (한글은 "가"~"힇", "ㄱ"~"ㅎ"만 가능) */
  private readonly forbiddenRegExp: RegExp = /[^-_\uAC00-\uD7A3xfe0-9a-zA-Z\u3131-\u314e\s]/g;

  constructor(referrer: string) {
    this.referrer = referrer;
  }

  /**
   * 추출된 키워드와 유입 referrer URL 반환.
   * 오류 발생시 {#link InvalidData}
   * @return {KeywordPair | InvalidData}
   */
  extract(adverId: string): KeywordPair | InvalidData {
    try {
      /* 추출된 키워드 */
      let keyword: string = '';

      /* 현재 광고주의 호스팅 타입을 확인 */
      const hostingType: HostingType | null = this.getHostingType(adverId);
      if(hostingType === null) {
        return InvalidData;
      }

      /* 현재 광고주의 호스팅 타입에서 확인해야 할 query의 field 이름을 추출*/
      const fieldKey: string = this.getFieldNameInHostingType(hostingType);

      const extractUrl: string = this.getExtractorURL(hostingType);

      /* 파라미터에 해당하는 값이 있으면 하나만 가져온다 */
      for(let i=0; i < this.searchParamPairs[fieldKey].length; i++) {
        keyword = this.getKeywordByParam(this.searchParamPairs[fieldKey][i], extractUrl);
        if (keyword.length > 0) {
          break;
        }
      }

      /* 불필요한 문자열 제거 */
      keyword = this.removeForbiddenChars(keyword);

      return {
        'keyword': keyword,
        'url': this.referrer
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  private getExtractorURL(hostingType: HostingType): string {
    try {
      switch (hostingType) {
        case HostingType.MAKESHOP_D2:
        case HostingType.MAKESHOP_D4:
          return location.href;

        default:
          return document.referrer;
      }
    } catch (error) {
      return document.referrer;
    }
  }

  /**
   * URL에서 hostname 추출
   * @param {string} adverId  - 광고주아이디
   * @return {string} 추출된 hostname (에러 발생시 빈 문자열)
   */
  private getHostingType(adverId: string): HostingType | null {
    try {
      return AdverConfigJsonMapper.getInstance(adverId).getHostingType();
    } catch (e) {
      return null;
    }
  }

  /**
   * 검색 엔진과 파라미터를 맵핑한 객체에서 hostname에 해당하는 파라미터의 필드를 반환.
   * @param {string} hostingType - 광고주의 hostingType
   * @return {string} - 필드 명 (없는 경우 빈 문자열)
   */
  private getFieldNameInHostingType(hostingType: string): string {
    let fieldName: string = '';
    const fieldNames: string[] = Object.keys(this.searchParamPairs);
    fieldNames.forEach((name: string) => {
      if (hostingType === name) {
        fieldName = name;
        return;
      }
    });

    return fieldName;
  }

  /**
   * URL에서 특정 파라미터 필드가 갖는 검색 키워드를 UTF-8로 디코딩 후 반환.
   * @param {string} field  - 파라미터 필드
   * @param {string} url    - URL
   * @return {string}       - 검색 키워드 (없거나 에러 발생시 빈 문자열)
   */
  private getKeywordByParam(field: string, url: string): string {
    try {
      const qryStringer: QueryStringer = new QueryStringer(new URLParse(url)['query'].toString());
      return this.findKeywordByParam(qryStringer.queryString, field);
    } catch (e) {
      return '';
    }
  }

  /**
   * 키워드에 불필요한 문자들을 제거
   * @param {string} keyword - 키워드 문자열
   * @return {string} - 불필요한 문자들이 제거된 키워드 (에러 발생시 빈 문자열)
   */
  private removeForbiddenChars(keyword: string): string {
    try {
      return keyword.replace(this.forbiddenRegExp, '');
    } catch (e) {
      return '';
    }
  }

  /**
   * 기본에 QueryStringer의 기능중 queryString의 문자열로된 파라미터들을 배열로 나누어 저장하는
   * 함수를 인코딩의 문제로 인해 별도의 메소드를 생성
   * @param  queryStr URL에서 추출한 QueryString
   * @param  field    검색 기준이 되는 키워드 구분자
   * @return
   */
  private findKeywordByParam(queryStr: string | undefined, field: string): string {
    try {
      if(queryStr){
        const paramListArr: string[] = queryStr.split('&');

        for(let i=0; i<paramListArr.length; i++) {
          let key = paramListArr[i].split('=')[0];
          let value = paramListArr[i].split('=')[1];
          if(key === field) {
            return this.convertEncodingKeyword(value);
          }
        }
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  /**
   * 인코딩이 다른 키워드의 경우 변환후에 반환을 한다.
   *  - 인코딩이 안되어있는 경우 decodeURIComponent 해서 반환한다.
   * @param  keyword     추출된 키워드
   * @return 변환된 키워드
   */
  private convertEncodingKeyword(keyword: string): string {
    try {
      if(!keyword) {
        return keyword;
      }

      const converter = new EncodingConverter();
      switch (document.characterSet.toLowerCase()) {
        case EncodingList.UTF_8:
          return decodeURIComponent(keyword);
        case EncodingList.EUC_KR:
          return converter.hexToText(keyword, EncodingList.EUC_KR);
        default:
          return keyword;
      }
    } catch (e) {
      return keyword;
    }
  }
}
