/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-03
 */

import {MakeshopD4CategoryParser} from "../MakeshopD4CategoryParser";
import {HostingType} from "../../../types/GlobalEnums";
import {Category} from "../../Category";

/**
 * create on 2020-04-03.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class TgrnmanCategoryParser extends MakeshopD4CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  protected parseRawCategories(): Category[] {
    const topCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#header div.gnb_wrap ul.gnb li a');
    const subCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#header div.gnb_wrap ul.sub li a');
    return this.getCategories(topCategories).concat(this.getCategories(subCategories));
  }
}
