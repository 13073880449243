/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-08-03
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-08-03
 * <p> barangsori - barangsori.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BarangsoriDataParser extends MakeshopD4DataParser {
  protected getWebConversionData(): JsonObject {
    const jsonData = super.getWebConversionData();
    const productList = jsonData.getDataByName('product');

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-cart table tbody tr td:nth-of-type(5) div')[i].textContent!.trim());
    }

    jsonData.replaceData('product', productList);
    return jsonData;
  }
}
