/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-03
 */

/**
 * create on 2020-11-03
 * <p> 텔레그램에 추가될 text를 생성 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class TelegramHTMLBuilder {
  static readonly NEW_LINE = '%0A';
  private _text: string;

  constructor() {
    this._text = '';
  }

  text(text: string): TelegramHTMLBuilder {
    this._text = text;
    return this;
  }

  /**
   * 텍스트를 굵게 한다
   */
  bold(): TelegramHTMLBuilder {
    this._text = `<b>${this._text}</b>`;
    return this;
  }

  /**
   * 텍스트를 기울임꼴로 변경한다
   */
  italic(): TelegramHTMLBuilder {
    this._text = `<i>${this._text}</i>`;
    return this;
  }

  /**
   * 텍스트에 밑줄을 추가한다
   */
  underLine(): TelegramHTMLBuilder {
    this._text = `<u>${this._text}</u>`;
    return this;
  }

  /**
   * 텍스트 중간을 관통하는 줄(취소선)을 추가한다.
   */
  strikeThrough(): TelegramHTMLBuilder {
    this._text = `<s>${this._text}</s>`;
    return this;
  }

  /**
   * 하이퍼링크를 생성한다.
   * @param link : 이동될 주소
   */
  hyferLInk(link: string): TelegramHTMLBuilder {
    this._text = `<a href='${link}'>${this._text}</a>`;
    return this;
  }

  /**
   * 텍스트를 코드형식으로 변경한다.
   */
  code(): TelegramHTMLBuilder {
    this._text = `<code>${this._text}</code>`;
    return this;
  }

  /**
   * 텍스트를 작성된 그대로 표시한다.
   */
  pre(): TelegramHTMLBuilder {
    this._text = `<pre>${this._text}</pre>`;
    return this;
  }

  /**
   * 생성된 텍스트 반황
   * @param newLine 개행여부
   */
  build(newLine = true): string {
    return this._text + (newLine ? TelegramHTMLBuilder.NEW_LINE : '');
  }
}