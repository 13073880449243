/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-01
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2022-11-01.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WatermanDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div.title a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div.title a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div.amount_reserve')[i] as HTMLSpanElement).textContent!.split('|')[0].trim().replace(/[^0-9]/g, '');

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': this.getMobileConversionProdtName(productAnchorElem),
        'qty': qty,
        'price': NumberUtil.parseNumber(document.querySelectorAll('div.product_info tr td div.price_box')[i].textContent!.replace(/\D/g, '')) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('tr.total td div strong')!.textContent!.trim()),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getMobilePayConvDataFromBasketPage();
    const parsedProducts: Array<{}> = jsonObj['product'];

    for (let i = 0; i < parsedProducts.length; i++) {
      let rawPrice = NumberUtil.parseNumber(document.querySelectorAll('div.basketRight_bottom_div h1')[i]!.textContent!.trim().replace(/[^0-9]/g, ''));
      let rawQty = NumberUtil.parseNumber((document.querySelectorAll('div.basketRight_bottom_div input.txt-input.amount-input')[i] as HTMLInputElement).value);

      parsedProducts[i]['price'] = rawPrice/rawQty;
    }

    jsonObj['totalPrice'] = NumberUtil.parseNumber(document.querySelector('div.price_total div.price_bottom span')!.textContent!.trim());

    return jsonObj;
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
      let rawPrice = NumberUtil.parseNumber(document.querySelectorAll( 'div.table-cart.table-order-prd td:nth-child(5) div')[i]!.textContent!.trim().replace(/[^0-9]/g, ''));
      let rawQty =  NumberUtil.parseNumber(document.querySelectorAll( 'div.table-cart.table-order-prd td:nth-child(3) div')[i]!.textContent!.trim().replace(/[^0-9]/g, ''));


      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': rawQty,
        'price': rawPrice/rawQty,
      });

      totalQty += rawQty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('div.price_back span strong')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }
}
