/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-29
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-12-29.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class TigeradsDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
      jsonObj.data['price'] = document.querySelector('span.product_price_custom')!.textContent!.replace(/\D/g,'');
      jsonObj.data['dcPrice'] = document.querySelector('span.price_text')!.textContent!.replace(/\D/g,'');
    }      
    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    if (this.isMobile(this.commandOptions)) {
    const price =  NumberUtil.parseNumber(document.querySelector('span.product_price_custom')!.textContent!.replace(/\D/g,''));
    const dcPrice =  NumberUtil.parseNumber(document.querySelector('span.price_text')!.textContent!.replace(/\D/g,''));
    const total =  NumberUtil.parseNumber(document.querySelector('span.total strong')!.textContent!.replace(/\D/g, ''));
    jsonObj['product'][0]['price'] = price && dcPrice && price < dcPrice ? price : (dcPrice ? dcPrice : price);  
    jsonObj['totalPrice'] = total > 0 ? total : dcPrice;
    }
    return jsonObj;
  }
}