/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-29
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-06-29
 * <p> eccogolfkr 광고주 관련 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class EccogolfkrDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected isProductPage(): boolean {
    try {
      return (0 === window.location.pathname.indexOf('/product')
          || 0 === window.location.pathname.indexOf('/m/product'));
          ;
    } catch (e) {
      return false;
    }
  }
  protected isBasketPage(): boolean {
    try {
      return (0 === window.location.pathname.indexOf('/order/basket.html')
          || 0 === window.location.pathname.indexOf('/m/order/basket.html')
          );          
    } catch (e) {
      return false;
    }
  }
}