/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-03
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-02-03
 * <p> jinryoung - www.mamiz.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class JinryoungDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions) ? this.getMobilePayConvDataFromBasketPage() : this.getWebPayConvDataFromBasketPage();
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();

    const parsedProduct:Array<{}> = [];
    const prodcutNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.order-page table td.this-product > div > a');
    let totalQty = 0;
    for (let i = 0; i < prodcutNameList.length; i++) {
      let qty: number = NumberUtil.parseNumber((document.querySelectorAll('.order-page table td.count  input[name="goodsCnt[]"]')[i] as HTMLInputElement).value);
      parsedProduct.push({
        'productCode': new QueryStringer(prodcutNameList[i].search).getParam('goodsNo'),
        'productName': prodcutNameList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.order-page table td:nth-child(6) strong.price')[i].textContent!.trim())
      });
      totalQty += qty;
    }

    jsonObj['product'] = parsedProduct;
    jsonObj['totalQty'] = totalQty;
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('span.total strong#totalSettlePrice')!.textContent!.trim())
    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProduct:Array<{}> = [];
    const jsonObj = super.getPayConvDataFromBasketPage();

    let totalQty = 0;
    const prodcutNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('#frmCart div.info > a');
    for (let i = 0; i < prodcutNameList.length; i++) {
      let qty: number = NumberUtil.parseNumber(document.querySelectorAll('#frmCart div.itembody > dd:nth-child(3)')[i].textContent!.split(':')[1].trim());
      parsedProduct.push({
        'productCode': new QueryStringer(prodcutNameList[i].search).getParam('goodsNo'),
        'productName': document.querySelectorAll('#frmCart div.itembody span.elp2')[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('#frmCart div.info > a > strong.prc')[i].textContent!.trim())
      });
      totalQty += qty;
    }

    jsonObj['product'] = parsedProduct;
    jsonObj['totalQty'] = totalQty;
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('em#totalSettlePrice')!.textContent!.trim())
    return jsonObj;
  }
}