/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">hsju</a>
 * @since 2020-06-18
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

export class DrdifferentDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const qty: number = NumberUtil.stringToNumber(this.getProductQty());
    const priceInfo: { price: number, dcPrice: number } = this.getProductPrice();
    const totalPrice: number = (this.onSale(priceInfo) ? priceInfo['dcPrice'] : priceInfo['price']) * qty;

    this.parseCategory(window['iCategoryNo']);
    parsedProducts.push({
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'qty': qty,
      'price': priceInfo['price']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': qty
    };
  }


  protected getProductQty(): string {
    if (document.querySelectorAll('tbody.option_products>tr').length > 0) {
      return this.isMobile(this.commandOptions)
        ? document.querySelectorAll('tbody.option_products>tr')[0].querySelector('input[type="number"].quantity_opt')!['value']
        : document.querySelectorAll('tbody.option_products>tr')[0].querySelector('td:nth-child(2)>span.quantity>input')!['value'];
    } else if (document.querySelectorAll('div#totalProducts>table>tbody>tr').length > 0) {
      return this.isMobile(this.commandOptions)
        ? document.querySelectorAll('div#totalProducts>table>tbody>tr')[0].querySelector('input[type="number"].quantity_opt')!['value']
        : document.querySelectorAll('div#totalProducts>table>tbody>tr')[0].querySelector('td:nth-child(2)>span.quantity>input, td:nth-child(2) input#quantity')!['value'];
    } else if (document.querySelectorAll("div#contents div.mSpec input#quantity").length > 0) {
      return document.querySelectorAll("div#contents div.mSpec input#quantity")[0]!['value'];
    } else if (document.querySelectorAll('span#totalProducts>li.d_qty>input#quantity').length > 0) {
      return this.isMobile(this.commandOptions)
        ? document.querySelectorAll('tbody.option_products>tr')[0].querySelector('input[type="number"].quantity_opt')!['value']
        : document.querySelectorAll('span#totalProducts>li.d_qty>input#quantity')[0]!['value'];
    }

    else {
      /* 웹의 경우를 발견하면 여기에 추가할 것*/
      return (document.querySelectorAll('tr.quantity>td input#quantity')[0] as HTMLInputElement).value;
    }
  }

}
