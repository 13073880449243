/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-03-16
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2023-03-16
 * <p> </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Yhee1751DataParser extends MakeshopD4DataParser {
  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
      jsonObj.data['productName'] = document.querySelector('#detail-item > h2')!.textContent!.trim();
    }else{
    jsonObj.data['productName'] = document.querySelector('#form1 > div > div.dname > h3')!.textContent!.trim();
    }
    return jsonObj;
  }
}
