/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-25
 */
import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {Value} from "../../../lib/value/Value";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2020-04-25.
 * <p> 롯데 계열사 "엘롯데", "롭스" 등을 하나의 "롯데온" 사이트 통합으로 인한 서브클래스</p>
 * <p>
 *   <ul>
 *    <li>이 서브클래스는 "lotteon", "lohbs2", "lotteshop" 3개의 광고주에서 사용.</li>
 *    <li>롯데온은 통합된 하나의 사이트를 의미한다.</li>
 *    <li><code>adverId</code>는 유입몰에 대한 id로 전달받는다.</li>
 *    <li>"엘롯데", "롭스"의 상품들도 "롯데온" 전환으로 집계하기 위한 작업. </li>
 *   </ul>
 * </p>
 * <p> {@link SelfHostedDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class LotteonDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 상품 수집
   * @return {JsonObject}
   */
  protected getShopCollectData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['collect'];
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': targetObj['productCode'],
      'productName': targetObj['productName'],
      'price': targetObj['price'],
      'productUrl': window.location.href,
      'dcPrice': targetObj['dcPrice'],
      'soldOut': targetObj['soldOut'],
      'imageUrl': this.getValidatedImageUrl(targetObj['imageUrl']),
      'topCategory': Value.getValue(targetObj['topCategory'], this.category.topCategory),
      'firstSubCategory': Value.getValue(targetObj['firstSubCategory'], this.category.firstSubCategory),
      'secondSubCategory': Value.getValue(targetObj['secondSubCategory'], this.category.secondSubCategory),
      'thirdSubCategory': Value.getValue(targetObj['thirdSubCategory'], this.category.thirdSubCategory)
    };

    return new JsonObject(parsedData);
  }

  /**
   * 각 상품들이 속한 광고주의 모비온 id를 추출하여
   * 전환시 상품정보에 해당 계열사의 id를 같이 포함하여 전송하도록 하였음.
   * @return {JsonObject} - 전환 데이터
   */
  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];

    for (let i = 0; i < parsedProducts.length; i++) {
      products.push({
        'adverId': parsedProducts[i]['adverId'],
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'soldOut': parsedProducts[i]['soldOut'],
        'imageUrl': this.getValidatedImageUrl(parsedProducts[i]['imageUrl']),
        'topCategory': Value.getValue(parsedProducts[i]['topCategory'], this.category.topCategory),
        'firstSubCategory': Value.getValue(parsedProducts[i]['firstSubCategory'], this.category.firstSubCategory),
        'secondSubCategory': Value.getValue(parsedProducts[i]['secondSubCategory'], this.category.secondSubCategory),
        'thirdSubCategory': Value.getValue(parsedProducts[i]['thirdSubCategory'], this.category.thirdSubCategory)
      });
    }

    const parsedData: {} = {
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': targetObj['totalPrice'],
      'totalQty': targetObj['totalQty']
    };

    return new JsonObject(parsedData);
  }

  /**
   * 각 상품들이 속한 광고주의 모비온 id를 추출하여
   * 전환시 상품정보에 해당 계열사의 id를 같이 포함하여 전송하도록 하였음.
   * @return {{}} - 파싱된 데이터
   */
  protected getParsedPayConvData(): {} {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalPrice: number = 0;
    let totalQty: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'adverId': parsedProducts[i]['adverId'],
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
        'soldOut': parsedProducts[i]['soldOut'],
        'imageUrl': this.getValidatedImageUrl(parsedProducts[i]['imageUrl']),
        'topCategory': Value.getValue(parsedProducts[i]['topCategory'], this.category.topCategory),
        'firstSubCategory': Value.getValue(parsedProducts[i]['firstSubCategory'], this.category.firstSubCategory),
        'secondSubCategory': Value.getValue(parsedProducts[i]['secondSubCategory'], this.category.secondSubCategory),
        'thirdSubCategory': Value.getValue(parsedProducts[i]['thirdSubCategory'], this.category.thirdSubCategory)
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseRationalNumber(products[i]['price']);
      totalQty += NumberUtil.parseInteger(products[i]['qty']);
    }

    return {
      'product': products,
      'adverId': this.adverId,
      'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
      'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
    };
  }

  /**
   * 이미지 URL에 data 등 허용하지 않는 scheme이 포함되면 직접 이미지 URL을 파싱.
   * @param {string} imageUrl 입력받은 이미지 URL
   * @return {string} 전송할 최종 이미지 URL
   */
  private getValidatedImageUrl(imageUrl: string): string {
    try {
      const allowedSchemeRegex = /^(http:|https:)/;
      return StringUtil.contains(imageUrl, allowedSchemeRegex)
          ? imageUrl
          : (document.querySelector("div.productVisualWrap div.imgWrap > img") as HTMLImageElement).src.replace(/.\.jpg.*/, "M.jpg/dims/resize/250X250");
    } catch (e) {
      return StringUtil.EMPTY;
    }
  }
}
