/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">sjchun</a>
 * @since 2022-07-07
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Value } from "../../../lib/value/Value";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2022-07-07
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Kimchi8doDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalQty: number = 0;
    let totalPrice: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty']
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseInteger(products[i]['price']);
      totalQty += NumberUtil.parseInteger(products[i]['qty']);
    }
    return new JsonObject({
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
      'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
    });

  }
}
