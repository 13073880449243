/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-01
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-11-01.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ParkerDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }


  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      let rawPrice = NumberUtil.parseNumber(document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(2)')[i]!.textContent!.trim().replace(/[^0-9]/g, ''));
      let rawQty = NumberUtil.parseNumber((document.querySelectorAll('span.amoutWrap input.txt-input.amount-input')[i] as HTMLInputElement).value);

      totalQty += rawQty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': rawPrice / rawQty,
        'qty': rawQty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }
}
