/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-31
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2023-01-31
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class LamourDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected isBasketPage(): boolean {
    try {
      return window.location.pathname.replace(/.php/, '') === '/order/cart' ;
    } catch (e) {
      return false;
    }
  }
  protected getPayConvDataFromBasketPage(): {} {
    if(this.isMobile(this.commandOptions)){
      const jsonObj = super.getPayConvDataFromBasketPage();
      return jsonObj;
    }else{
      const parsedProducts: Array<{}> = [];
      const productNameList: NodeListOf<HTMLInputElement> = document.querySelectorAll('td.gi.this-product>div>a');
      let totalQty = 0;
      for (let i = 0; i < productNameList.length; i++) {
        const qty = NumberUtil.parseInteger((document.querySelectorAll('input[name="goodsCnt[]"]')[i] as HTMLInputElement).value);
        const rawPrice = NumberUtil.parseNumber(document.querySelectorAll('.ta-c.this-product > .price')[i].textContent!.trim());
        const price = rawPrice / qty;
        parsedProducts.push({
        'productCode': new QueryStringer(productNameList[i].getAttribute('href')!.split('?')[1]).getParam('goodsNo'),
        'productName': productNameList[i].textContent!.trim(),
        'qty': qty,
        'price': price,
      });
    totalQty += qty;
      }
    let parsedTotalPrice = document.querySelector('strong#totalSettlePrice')!.textContent!.replace(/\D/g,'').trim();
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': parsedTotalPrice,
      'totalQty': totalQty
      };
    }
  }
}