/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-10-20
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-10-20.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class AioincDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    jsonData.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonData;
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    try{
      superData['price'] = NumberUtil.parseNumber(document.querySelector('#span_product_price_custom strike')!.textContent!.replace(/\D/g, ''));
      superData['dcPrice'] = NumberUtil.parseNumber(document.querySelector('strong#span_product_price_text')!.textContent!.replace(/\D/g, ''));
      return superData;
    }catch{
      return superData;
    }
  }

  protected getConversionData(): JsonObject {
    const jsonData: JsonObject = super.getConversionData();
    jsonData.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonData;
  }

  private getSiteCodeByAdverId(): string {
    switch (this.adverId) {
      case 'aioinc':
        return '3f43844e02128d3a3edd66749fb756e1';
      case 'sogreat':
        return '9e4a1d47bd3b536e5a144fa122e833ae';
      default:
        return '';
    }
  }

  protected getImageSrc(): string {
    if(this.isMobile(this.commandOptions)){
      return super.getImageSrc();
    }

    return (document.querySelector('.keyImg [aria-hidden=false] img.ThumbImage') as HTMLImageElement).src;
  }
}
