/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-12
 */
import {DataParser} from "../DataParser";
import {HostingType} from "../../types/GlobalEnums";
import {JsonObject} from "../../lib/json/JsonObject";
import {NumberUtil} from "../../lib/common/NumberUtil";
import {QueryStringer} from "../../lib/url/QueryStringer";
import {GlobalVariables} from "../../types/GlobalVariables";
import {StringUtil} from "../../lib/common/StringUtil";

/**
 * create on 2020-03-12.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GodomallSelfDataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.GODOMALL_SELF);
  }

  protected isProductPage(): boolean {
    try {
      return this.isMobile(this.commandOptions)
          ? StringUtil.matchExactly(window.location.pathname, /^.*\/goods\/view.php$/)
          : StringUtil.matchExactly(window.location.pathname, /^.*\/goods\/goods_view.php$/);
    } catch (e) {
      return false;
    }
  }

  protected isBasketPage(): boolean {
    try {
      return this.isMobile(this.commandOptions)
          ? StringUtil.matchExactly(window.location.pathname, /^.*\/goods\/cart.php$/)
          : StringUtil.matchExactly(window.location.pathname, /^.*\/goods\/goods_cart.php$/);
    } catch (e) {
      return false;
    }
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  protected getShopCollectData(): JsonObject {
    return this.isMobile(this.commandOptions)
        ? this.getMobileShopCollectData()
        : this.getWebShopCollectData();
  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  /**
   * 고도몰의 경우 서브 카테고리가 붙어서 파싱(001002003) 되어 예외로 수집 로직적용
   * (카테고리 3개씩 짤라서 수집)
   * @param currentCategoryCode
   * @protected
   */
  protected parseCategory(currentCategoryCode: string) {
    try {
      super.parseCategory(currentCategoryCode);
      if(this.category.topCategory === currentCategoryCode) {
        this.category.topCategory = currentCategoryCode.substring(0,3);
        this.category.firstSubCategory = currentCategoryCode.substring(3,6);
        this.category.secondSubCategory = currentCategoryCode.substring(6,9);
        this.category.thirdSubCategory = currentCategoryCode.substring(9,12);
      }
    } catch (e) {
      this.category.topCategory = currentCategoryCode;
      this.category.firstSubCategory = undefined;
      this.category.secondSubCategory = undefined;
      this.category.thirdSubCategory = undefined;
    }
  }

  protected getConversionData(): JsonObject {
    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="naver-common-inflow-script-order-item"]');
    const ordCode: string = eval(`(${inputList[0].value})`)['ordno'];

    let totalQty = 0;
    let totalPrice = 0;

    const delivery = (window[this.ENP_OBJECT_NAME] && StringUtil.isNotEmpty(window[this.ENP_OBJECT_NAME]['delivery']))
        ? NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['delivery'])
        : 0;

    for (let i = 0; i < inputList.length; i++) {
      const convInfo: {} = eval(`(${inputList[i].value})`);
      const qty: number = convInfo['ea'];
      const rawPrice: number = NumberUtil.parseNumber(convInfo['price']);
      const price: number = rawPrice / qty;

      parsedProducts.push({
        'productCode': convInfo['goodsno'],
        'productName': convInfo['goodsnm'],
        'qty': qty.toString(),
        'price': price.toString()
      });

      totalQty += qty;
      totalPrice += rawPrice;
    }

    totalPrice += delivery;

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getPayConvDataFromProductPage(): {} {
    return this.isMobile(this.commandOptions)
        ? this.getMobilePayConvDataFromProductPage()
        : this.getWebPayConvDataFromProductPage();
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions)
        ? this.getMobilePayConvDataFromBasketPage()
        : this.getWebPayConvDataFromBasketPage();
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const cartDataArr: Array<{}> = window['nsGodo_CartAction']['data'];
    let totalPrice = 0;
    let totalQty = 0;

    const getProductName = (index: number): string => {
      try {
        const trElem: HTMLTableRowElement = document.querySelectorAll('table.orderitem-list>tbody>tr')[index] as HTMLTableRowElement;
        return (trElem.querySelector('td>div') as HTMLDivElement)!.textContent!.trim();
      } catch (e) {
        return '';
      }
    };

    const getProductCode = (index: number): string => {
      const anchorElem: HTMLAnchorElement = document.querySelectorAll('table.orderitem-list tr td>a')[index] as HTMLAnchorElement;
      let queryString: string = anchorElem.search.substring(1);

      /* querystring에 '?' 바로 뒤에 '&'가 붙은 경우가 있는 경우 방지 */
      queryString = queryString.indexOf('&') > 0
          ? queryString.substring(1)
          : queryString;

      return new QueryStringer(queryString).getParam('goodsno');
    };

    for (let i = 0; i < cartDataArr.length; i++) {
      const price = cartDataArr[i]['price'];
      const qty = cartDataArr[i]['ea'];

      totalQty += qty;
      totalPrice += (price * qty);

      parsedProducts.push({
        'productCode': getProductCode(i),
        'productName': getProductName(i),
        'qty': qty,
        'price': price
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': totalQty
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const cartDataArr: Array<{}> = window['nsGodo_CartAction']['data'];
    let totalPrice = 0;
    let totalQty = 0;

    const getProductName = (index: number): string => {
      try {
        const anchorElem: HTMLAnchorElement = document.querySelectorAll('div.goods-nm>a')[index] as HTMLAnchorElement;
        return anchorElem.textContent!.trim();
      } catch (e) {
        return '';
      }
    };

    const getProductCode = (index: number): string => {
      const anchorElem: HTMLAnchorElement = document.querySelectorAll('div.goods-list-img>a')[index] as HTMLAnchorElement;
      let queryString: string = anchorElem.search.substring(1);

      /* querystring에 '?' 바로 뒤에 '&'가 붙은 경우가 있는 경우 방지 */
      queryString = queryString.indexOf('&') > 0
          ? queryString.substring(1)
          : queryString;

      return new QueryStringer(queryString).getParam('goodsno');
    };

    for (let i = 0; i < cartDataArr.length; i++) {
      const price = cartDataArr[i]['price'];
      const qty = cartDataArr[i]['ea'];

      totalQty += qty;
      totalPrice += (price * qty);

      parsedProducts.push({
        'productCode': getProductCode(i),
        'productName': getProductName(i),
        'qty': qty,
        'price': price
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': totalQty
    };
  }

  /**
   * 모바일 여부 판단
   * @param {{}} commandOptions
   * @return {boolean}
   */
  protected isMobile(commandOptions: {}): boolean {
    try {
      const subDomain: string = location.hostname.split('.')[0].toLowerCase();
      const pathArr: string[] = location.pathname.substring(1).split('/');

      return subDomain === 'm'
          ? true
          : pathArr.length > 0 && StringUtil.matchExactly(pathArr[0], /^m\d*$/i);
    } catch (e) {
      return false;
    }
  }

  protected getWebShopCollectData(): JsonObject {
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': this.getProductCode(),
      'productName': window[this.ENP_OBJECT_NAME]['productName'],
      'price': this.getPriceInfo().price,
      'productUrl': window.location.href,
      'dcPrice': this.getPriceInfo().dcPrice,
      'soldOut': !!window[this.ENP_OBJECT_NAME]['soldOut']
          ? window[this.ENP_OBJECT_NAME]['soldOut'] === '1'
              ? 'Y'
              : 'N'
          : 'N',
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getMobileShopCollectData(): JsonObject {
    const getPriceProperty = (obj: {}): number => {
      try {
        const properties: string[] = Object.getOwnPropertyNames(obj);
        const property: string | undefined = properties.pop();

        if (property) {
          return obj[property === 'length' ? properties.pop() as string : property] as number;
        } else {
          return 0;
        }
      } catch (e) {
        return 0;
      }
    };

    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': this.getProductCode(),
      'productName': this.getProductName(),
      'price': getPriceProperty(window['consumer']) && StringUtil.isNotEmpty(getPriceProperty(window['consumer'])) && getPriceProperty(window['consumer']) > 0
          ? getPriceProperty(window['consumer']) : getPriceProperty(window['price']),
      'productUrl': window.location.href,
      'dcPrice': getPriceProperty(window['price']),
      'soldOut': !!window[this.ENP_OBJECT_NAME]['soldOut']
          ? window[this.ENP_OBJECT_NAME]['soldOut'] === '1'
              ? 'Y'
              : 'N'
          : 'N',
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);

    const qty = document.querySelector('input[name="ea"]')
        ? NumberUtil.parseInteger((document.querySelector('input[name="ea"]') as HTMLInputElement).value) : 1;

    parsedProducts.push({
      'productCode': this.getProductCode(),
      'productName': window[this.ENP_OBJECT_NAME]['productName'],
      'price': this.getPriceInfo().price,
      'dcPrice': this.getPriceInfo().dcPrice,
      'qty': qty
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getPriceInfo().price * qty,
      'totalQty': qty
    };
  }

  protected getMobilePayConvDataFromProductPage(): {} {
    const getPriceProperty = (obj: {}): number => {
      try {
        const properties: string[] = Object.getOwnPropertyNames(obj);
        const property: string | undefined = properties.pop();

        if (property) {
          return obj[property === 'length' ? properties.pop() as string : property] as number;
        } else {
          return 0;
        }
      } catch (e) {
        return 0;
      }
    };

    const getQty = (): number => {
      try {
        return NumberUtil.parseInteger((this.getSelector(['input[name="ea"]', 'input[name="goodsCnt[]"]']) as HTMLInputElement).value);
      } catch (e) {
        return 1;
      }
    }

    const parsedProducts: Array<{}> = [];
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);
    const qty = getQty() ? getQty() : 1;
    const price: number = getPriceProperty(window['consumer']) && StringUtil.isNotEmpty(getPriceProperty(window['consumer'])) && getPriceProperty(window['consumer']) > 0
        ? getPriceProperty(window['consumer']) : getPriceProperty(window['price']);
    const dcPrice: number = getPriceProperty(window['price']);

    parsedProducts.push({
      'productCode': this.getProductCode(),
      'productName': this.getProductName(),
      'price': price,
      'dcPrice': dcPrice,
      'qty': qty
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': price * qty,
      'totalQty': qty
    };
  }

  private getPriceInfo(): { price: number; dcPrice: number; } {
    let price: number = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['price']);
    let dcPrice: number = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['dcPrice']);

    price = NumberUtil.isNaN(price) || StringUtil.isEmpty(price) ? 0 : price;
    dcPrice = NumberUtil.isNaN(dcPrice) || StringUtil.isEmpty(dcPrice) ? 0 : dcPrice;

    return price === 0
        ? {price: dcPrice, dcPrice: price}
        : {price: price, dcPrice: dcPrice};
  }

  protected getImageUrl(): string {
    if (this.isMobile(this.commandOptions)) {
      if (document.querySelector('div.thumbnail-img img, img#imgComp')) {
        return (document.querySelector('div.thumbnail-img img, img#imgComp') as HTMLImageElement).src;
      } else if (document.querySelector('div.goods_view ul.slider-wrap li:first-child>img')) {
        return (document.querySelector('div.goods_view ul.slider-wrap li:first-child>img') as HTMLImageElement).src;
      } else if (document.querySelector('div.cont_detail ul.slider-wrap li.slick-slide:first-child img')) {
        return (document.querySelector('div.cont_detail ul.slider-wrap li.slick-slide:first-child img') as HTMLImageElement).src;
      } else if (document.querySelector('div.goods-image-list div.swiper-wrapper div.swiper-slide:first-child img')) {
        return (document.querySelector('div.goods-image-list div.swiper-wrapper div.swiper-slide:first-child img') as HTMLImageElement).src;
      }
    } else {
      if (document.querySelector('div.thumbnail-img img')) {
        return (document.querySelector('div.thumbnail-img img') as HTMLImageElement).src;
      } else if (document.querySelector('#mainImage>img')) {
        return (document.querySelector('#mainImage>img') as HTMLImageElement).src;
      } else if (document.querySelector('#objImg')) {
        return (document.querySelector('#objImg') as HTMLImageElement).src;
      } else if (document.querySelector('div.product-images img#productZoomImg')) {
        return (document.querySelector('div.product-images img#productZoomImg') as HTMLImageElement).src;
      }
    }

    return '';
  }

  protected getProductName(): string {
    if (this.isMobile(this.commandOptions)) {
      if (document.querySelector('div.goods_nm')) {
        return (document.querySelector('div.goods_nm') as HTMLDivElement).textContent!.trim();
      } else if (document.querySelector('.detail_info_top h3')) {
        return document.querySelector('.detail_info_top h3')!.textContent!.trim();
      } else if (document.querySelector('#goods_view .goodsMainTitle')) {
        return document.querySelector('#goods_view .goodsMainTitle')!.textContent!.trim();
      }
    } else {
      if (document.querySelector('div.goods_nm')) {
        return (document.querySelector('div.goods_nm') as HTMLDivElement).textContent!.trim();
      } else if (document.querySelector('div.detail_info_top>h3')) {
        return (document.querySelector('div.detail_info_top>h3') as HTMLHeadElement).textContent!.trim();
      } else if (document.querySelector('div#goods_spec div.goods-current-position')
          && document.querySelector('div#goods_spec div.goods-current-position')!.childNodes.length > 7) {
        return document.querySelector('div#goods_spec div.goods-current-position')!.childNodes[6].textContent!.trim();
      }
    }

    return '';
  }

  protected getProductCode(): string {
    let productCode = new QueryStringer(window.location.search.substring(1)).getParam('goodsNo');
    return productCode && StringUtil.isNotEmpty(productCode)
        ? productCode : new QueryStringer(window.location.search.substring(1)).getParam('goodsno');

  }
}
