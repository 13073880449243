/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-09-07
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-09-07
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Bigacesell01DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      const dataset = (document.querySelector('#PJ_percent, .PJ_percent') as HTMLDivElement).dataset
      return {
        price: NumberUtil.parseNumber(dataset['custom']!.toString()),
        dcPrice: NumberUtil.parseNumber(dataset['price']!.toString())
      }

    } catch (e) {
     return super.getProductPrice();
    }
  }
}