/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CallbackFunction } from '../../types/GlobalTypes';
import { AJAXerCore } from './AJAXerCore';
import { NotSupportedError } from '../../error/NotSupportedError';
import { AJAXerOption } from './AJAXerOption';

/**
 * create on 2019-08-19.
 * <p> <code>AJAXerCore</code> 클래스의 기능의 호출을 담당하는 클래스 (Facade 패턴) </p>
 * <p> AJAX로 통신할 때 이 클래스를 이용하면 된다 </p>
 * <p> {@link AJAXerCore} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class AJAXer {
  /* AJAXerCore 클래스의 옵션 */
  private _options: AJAXerOption;
  /* AJAXer 클래스의 인스턴스 */
  private static instance: AJAXer;

  /**
   * Singleton 패턴이므로 생성자 호출 막는다
   */
  private constructor() {
    this._options = {
      timeout: 1500,
      contentType: 'application/json;charset=UTF-8',
      withCredentials: true,
      setHeader: true
    };
  }

  get options(): AJAXerOption {
    return this._options;
  }

  set options(value: AJAXerOption) {
    this._options = value;
  }

  // setOptions(value: AJAXerOption): AJAXer {
  //   this._options = value;
  //   return AJAXer.getInstance();
  // }

  /**
   * 인스턴스 생성
   * @return {AJAXer}
   */
  static getInstance(): AJAXer {
    if (!AJAXer.instance) {
      AJAXer.instance = new AJAXer();
    }

    return AJAXer.instance;
  }

  get(url: string, callback?: CallbackFunction, async = true): void {
    const ajaxerCore = new AJAXerCore(this.options);
    ajaxerCore.get(url, callback, async);
  }

  post(url: string, data: {}, callback?: CallbackFunction, async = true): void {
    const ajaxerCore = new AJAXerCore(this.options);
    ajaxerCore.post(url, data, callback, async);
  }

  put(): void {
    throw new NotSupportedError('PUT method');
  }

  delete(): void {
    throw new NotSupportedError('DELETE method');
  }
}
