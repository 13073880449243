/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-09-23
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-09-23.
 * <p> merongshop 광고주. 다른 카페24와 달리 EC_FRONT_EXTERNAL_SCRIPT_VARIABLE_DATA 변수가 없음. </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MerongshopDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    try {
      if (this.isMobile(this.commandOptions)) {
        let totalQty = 0;
        const parsedProducts: Array<{}> = [];
        const rawProductDataArr: NodeListOf<HTMLDivElement> = document.querySelectorAll('div:not(.displaynone)>div.orderArea>div.prdInfo>div.description');
        for (let i = 0; i < rawProductDataArr.length; i++) {
          const anchorElem: HTMLAnchorElement | null = rawProductDataArr[i].querySelector('strong.prdName>a');
          const productName: string = anchorElem!.textContent!.trim();
          const productCode: string = new QueryStringer(anchorElem!.href.substring(anchorElem!.href.indexOf('?') + 1)).getParam('product_no');
          const qty: string = rawProductDataArr[i].querySelector('strong.quantity')!.textContent!;
          const price: string = rawProductDataArr[i].querySelector('li.price>span')!.childNodes[0].textContent!.replace(/\D/g, '');

          parsedProducts.push({
            'productCode': productCode,
            'productName': productName,
            'qty': qty,
            'price': price
          });

          totalQty += NumberUtil.parseInteger(qty);
        }

        const parsedData: {} = {
          'product': parsedProducts,
          'adverId': this.adverId,
          'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
          'totalPrice': document.querySelector('div.totalList tr.totalPay>td.price')!.childNodes[0].textContent!.trim().replace(/\D/g, ''),
          'totalQty': totalQty
        };

        return new JsonObject(parsedData);
      } else {
        return super.getConversionData();
      }
    } catch (e) {
      return super.getConversionData();
    }
  }
}
