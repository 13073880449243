/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-08-01
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-08-01.
 * <p> 클래스 설명 </p>
 * <p> {@MakeshopD4DataParser } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class HeirloomParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();
    const product = jsonObj.data['product'];

    for(let i = 0, len = product.length; i < len; i++){
      product[i]['price'] = NumberUtil.parseNumber(document.querySelectorAll('table tr td:nth-child(5) div')[i].textContent!.replace(/\D/g,'').trim());
  }
    return jsonObj;
  }
}
