/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-08
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { Value } from "../../../lib/value/Value";
import { GlobalVariables } from "../../../types/GlobalVariables";

/**
 * create on 2021-03-08.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ElephantUVDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();

    jsonObj.replaceData('price', document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''));
    jsonObj.replaceData('dcPrice', document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''));

    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }
  protected getWebConversionData(): JsonObject{
    const jsonObj: JsonObject = super.getWebConversionData();

    const product = jsonObj.data['product'];

    for(let i = 0, len = product.length; i < len; i++){
      product[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }
}
