/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-06-21
 */

import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2023-06-21
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class HvlandDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getWebShopCollectData();
    jsonObj.data['price'] =  NumberUtil.parseNumber(document.querySelector('div.goods_price')!.textContent!.replace(/\D/g,''));
    jsonObj.data['dcPrice'] =  NumberUtil.parseNumber(document.querySelector('div.goods_price')!.textContent!.replace(/\D/g,''));

    return jsonObj;
  }
  protected getMobilePayConvDataFromProductPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromProductPage();
    const productAmount =document.querySelectorAll('table tr div.order-contents-area div.buy-info-contents').length;

    let price =NumberUtil.parseNumber(document.querySelector('div.goods_price')!.textContent!.replace(/\D/g,''));
    jsonObj['product'][0]['dcPrice'] =price;
    jsonObj['product'][0]['price'] =price;
    let  totalQty = 1;
    let  totalPrice = price;
    if(productAmount>0){
      totalQty = 0;
      for (let i = 0; i < productAmount; i++) {
        const qtyList: NodeListOf<HTMLInputElement> =document.querySelectorAll('div.buy-info-title input');
        const qty = NumberUtil.parseInteger(qtyList[i].value);
        totalQty += qty;
      }
    totalPrice =NumberUtil.parseNumber(document.querySelector('#el-multi-option-total-price')!.textContent!.replace(/\D/g,'')); 
    }
    if(document.querySelectorAll('div.buy-info-item div input').length>0){
      totalPrice =NumberUtil.parseNumber(document.querySelector('div.buy-info-item div#order_price')!.textContent!.replace(/\D/g,'')); 
      totalQty =  NumberUtil.parseNumber((document.querySelector('div.buy-info-item div input') as HTMLInputElement).value);
    }
    jsonObj['product'][0]['qty'] = totalQty;
    jsonObj['totalQty'] = totalQty;
    jsonObj['totalPrice'] = totalPrice > 0 ? totalPrice : price ;
    return jsonObj;
  }
}
