/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020. 6. 17
 */

import {DataParser} from './DataParser';
import {HostingType} from "../types/GlobalEnums";
import {JsonObject} from "../lib/json/JsonObject";

/**
 * 비쇼핑 DataParser 클래스
 * create on 2020. 6. 17
 * <p> {@link DataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NonProductDataParser extends DataParser {
  constructor(adverId: string, commandOptions: {}, hostingType: HostingType) {
    super(adverId, commandOptions, hostingType);
  }

  protected getConversionData(): JsonObject {
    const products: Array<{}> = [];
    const productName: string = this.commandOptions['productName'];

    products.push({
      'productCode': 1,
      'productName': productName ? productName : this.commandOptions['convType'],
      'qty': 1,
      'price': 1
    });

    const parsedData: {} = {
      'product': products,
      'adverId': this.adverId,
      'totalPrice': 1,
      'totalQty': 1
      //,'convType': this.commandOptions['convType']
    };

    return new JsonObject(parsedData);
  }

  protected getCartCollectData(): JsonObject {
    return new JsonObject();
  }

  protected getCommonTraceData(): JsonObject {
    return new JsonObject();
  }

  protected getPayConvDataFromBasketPage(): {} {
    return {};
  }

  protected getPayConvDataFromProductPage(): {} {
    return {};
  }

  protected getShopCollectData(): JsonObject {
    return new JsonObject();
  }

  protected getWishCollectData(): JsonObject {
    return new JsonObject();
  }

  protected isBasketPage(): boolean {
    /*
     * isBasketPage()와 isProductPage()가 모두 false를 리턴하면
     * 분기에서 에러를 발생시키므로 true로 리턴한다.
     */
    return true;
  }

  protected isProductPage(): boolean {
    return false;
  }
}
