/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Saera1978DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if (document.querySelector('.swiper-container .swiper-wrapper > .thumb.swiper-slide.swiper-slide-active > img')) {
      return (document.querySelector('.swiper-container .swiper-wrapper > .thumb.swiper-slide.swiper-slide-active > img') as HTMLImageElement).src;
    }

    return super.getImageUrl();
  }
}
