/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import {MakeshopD4CategoryParser} from "../MakeshopD4CategoryParser";
import {Category} from "../../Category";
import {HostingType} from "../../../types/GlobalEnums";

/**
 * create on 2020-04-02.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BbglabCategoryParser extends MakeshopD4CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  protected parseRawCategories(): Category[] {
    const topCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#all_cate_area li.a_sub_title a, div.cboth>div.navCategory a');
    const subCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#all_cate_area li.a_sub_cate a');
    if (subCategories != null) {
      return this.getCategories(topCategories).concat(this.getCategories(subCategories));
    } else {
      return this.getCategories(topCategories);
    }
  }
}
