/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-03
 */

import {CrmDao} from "../CrmDao";
import {CrmStorageKey, CrmStorageType} from "../../types/CrmEnum";

/**
 * create on 2022-01-03
 * <p> 아임웹 CRM 데이터 Dao </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ImwebCrmDao extends CrmDao {
  constructor() {
    super(CrmStorageType.SESSION);
  }

  /**
   * 회원 아이디 스토리지에서 수집 후 반환
   * @return string : 회원 아이디
   */
  getUserId(): {} {
    try {
      const userExtraJson: {} = JSON.parse(localStorage!.getItem(CrmStorageKey.USER_EXTRA_DATA)!);
      return userExtraJson && Object.keys(userExtraJson).length > 0 ? userExtraJson : {};
    } catch (e) {
      return {};
    }
  }

  /**
   * 스토리지에서 주문완료 URL 반환
   */
  getOrderEndPageUrl(): string {
    try {
      return localStorage.getItem(CrmStorageKey.MOBTUNE_CONVERSION_URL)
          ? localStorage.getItem(CrmStorageKey.MOBTUNE_CONVERSION_URL)!.toString() : '';
    } catch (e) {
      return '';
    }
  }

  getBankInfo(): {} {
    return {};
  }

  /**
   * 결제수단 수집
   */
  getPayMethodInfo(): {} {
    return {};
  }

  setOrderFormSessionStorage(): void { }
}
