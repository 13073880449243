/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-30
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-03-30.
 * <p> Andersson 광고주 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class LavienDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (document.querySelector('p.prdImg > a > img')) {
      return (document.querySelector('p.prdImg > a >img') as HTMLImageElement).src;
    } else {
      const elements = document.querySelectorAll('#prdDetail > div.cont div');

      for (let i = 0; i < elements.length; i++) {
        if (elements[i].querySelector('p > img')) {
          return (elements[i].querySelector('p > img') as HTMLImageElement).src;
        }
      }
    }

    return '';
  }
}
