/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-03-09
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";

/**
 * create on 2021-03-09
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SkyeyeplusDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('.shipping_group_list .cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      let raw;
      for (let i = 0; i < rawProductList.length; i++) {
        raw = rawProductList[i];
        const productName = raw.querySelector('.goods_name')!.textContent!.trim();
        const params = raw.querySelector('.img_area a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = NumberUtil.parseNumber(raw.querySelector('.cart_quantity')!.childNodes[1].textContent!.replace(/\D/g, ''));
        const productPrice = NumberUtil.parseNumber(raw.querySelector('.cart_quantity')!.childNodes[2].textContent!.replace(/\D/g, ''));
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
          document.querySelector('#totalPrice span.num')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('.cart_list li.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productName = rawProductList[i].querySelector('.goods_name a')!.textContent!.trim();
        const params = (rawProductList[i].querySelector('.goods_name a') as HTMLAnchorElement).search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = rawProductList[i].querySelector('.cart_quantity')!.childNodes[2].textContent!.replace(/\D/g, '');
        const productPrice = rawProductList[i].querySelector('.cart_quantity')!.childNodes[3].textContent!.replace(/\D/g, '');
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('.price, .settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}