/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-05
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * @version 1.0
 * @author sjchun
 */
export class DrkimbioDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.getDataByName('product');
    let totalQty = 0;
    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-cart tbody tr td:nth-of-type(3) div')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-cart tbody tr td:nth-of-type(5) div')[i].textContent!.replace(/\D/g, '').trim();
      totalQty += NumberUtil.stringToNumber(qty);
    }

    jsonObj.data['totalQty'] = totalQty;

    return jsonObj;
  }
}
