/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import { JsonObject } from '../../../lib/json/JsonObject';
import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';


/**
 * create on 2020-03-09.
 * <p> Chopiee 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ChopieeDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getShopCollectData();
    jsonObj.data['productCode'] = document.querySelector('form#form1')!['brandcode']['value'];
    return jsonObj;
  }

  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productCode'] = document.querySelector('form#form1')!['brandcode']['value'];
    }

    return jsonObj;
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productCode'] = (document.querySelectorAll('div.table-cart tbody > input[name="brandcode"]')[i] as HTMLInputElement).value;
    }

    jsonObj['totalPrice'] = document.querySelector('div.totalprice-txt')!.textContent!.split('=')[1].replace(/\D/g, '').trim();

    return jsonObj;
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productCode'] = (document.querySelectorAll('form[name="forms"]>input[name="brandcode"]')[i] as HTMLInputElement).value;
    }

    jsonObj['totalPrice'] = document.querySelector('span.txt-price')!.textContent!.replace(/\D/g, '').trim();

    return jsonObj;
  }
}
