/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-03-09.
 * <p> Chungdamahm 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ChungdamahmDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
          : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart table > tbody > tr > td:nth-child(2) div.txt-l a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart table > tbody > tr > td:nth-child(2) div.txt-l a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.table-cart table > tbody > tr > td:nth-child(4) div')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': (document.querySelectorAll('div.table-cart table > tbody > tr > td:nth-child(5) div')[i] as HTMLDivElement).textContent!.trim().slice(0, -1),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': this.getMobileConverProductName(productAnchorElem),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  private getMobileConverProductName(element: Element) {
    try {
      let productName: string = element!.textContent!.trim();

      if(element.querySelector('span') != null && element.querySelector('span') != undefined) {
        productName = productName.replace(element.querySelector('span')!.textContent!.trim(), '');
      }

      return productName;
    } catch(e) {
      return element!.textContent!.trim();
    }
  }
}
