/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-08
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2021-12-08
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class HutechDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected isProductPage(): boolean {
    try {
      return (0 === window.location.pathname.indexOf('/product')
        || 0 === window.location.pathname.indexOf('//product')
        || 0 === window.location.pathname.indexOf('/surl/P')
        || 0 === window.location.pathname.indexOf('/front/php/product.php'));
    } catch (e) {
      return false;
    }
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    try {
      let superData = super.getProductPrice();
      superData['price'] = NumberUtil.parseNumber(document.querySelector('#prdInfo table tbody td strong.sale')!.textContent!.replace(/\D/g, ''));
      return superData;
    } catch {
      return super.getProductPrice();
    }
  }
}