/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-03
 */

import {CrmService} from "../CrmService";
import {HostingType} from "../../../../types/GlobalEnums";
import { ImwebCrmDao } from "../../dao/hosting/ImwebCrmDao";

/**
 * create on 2022-01-03
 * <p> 아임웹 Crm Service </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ImwebCrmService extends CrmService {
  constructor() {
    super(HostingType.IMWEB);
  }

  protected isLoginPage(): boolean {
    return false;
  }

  protected isOrderFormPage(): boolean {
    return false;
  }

  protected isOrderEndPage(): boolean {
    return location.href.search((this.crmDao! as ImwebCrmDao).getOrderEndPageUrl()) > -1;
  }
}
