/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-04-20
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2023-04-20
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class HyzinDataParser extends GodomallRentDataParser {
  
  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    let superData = super.getMobileShopCollectData(formElement);
    superData.data['imageUrl'] = (document.querySelector('meta[property="og:image"]') as HTMLMetaElement)['content'];
    return superData;
  }
  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    let superData = super.getWebShopCollectData(formElement);
    superData.data['imageUrl'] = (document.querySelector('meta[property="og:image"]') as HTMLMetaElement)['content'];
    return superData;
  }

}