/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-10
 */

import {CrmStorageKey, CrmStorageType, ScriptUrl} from "../types/CrmEnum";
import {StringUtil} from "../../../lib/common/StringUtil";
import {HostingType} from "../../../types/GlobalEnums";

/**
 * create on 2021-02-10
 * <p> CRM(회원정보) 데이터 저장 및 관리 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export abstract class CrmDao {
  protected storage: Storage | null;

  constructor(storageType: CrmStorageType) {
    this.storage = this.getStorage(storageType);
  }

  /**
   * 주문완료 페이지에서 구매시도 데이터 삭제
   *
   */
  removeOrderTryData(): void {
    const dataFromStorage = this.storage!.getItem(CrmStorageKey.COMMON_EXTRA_DATA);
    if(dataFromStorage && StringUtil.isNotEmpty(dataFromStorage)){
      const storageOrderJsonObj = JSON.parse(dataFromStorage);
      delete storageOrderJsonObj['orderTryRoot'];
      this.storage!.setItem(CrmStorageKey.COMMON_EXTRA_DATA, JSON.stringify(storageOrderJsonObj));
    }
  }

  /**
   * 세션스토리지에서 CRM 팀에서 세팅한 common에 추가될 기타 데이터들 반환
   */
  getCommonExtra(): {} {
    const dataFromStorage = this.storage!.getItem(CrmStorageKey.COMMON_EXTRA_DATA);
    if(dataFromStorage && StringUtil.isNotEmpty(dataFromStorage)){
      this.storage!.removeItem(CrmStorageKey.COMMON_EXTRA_DATA);
    }
    return dataFromStorage ? JSON.parse(dataFromStorage) : {};
  }

  /**
   * append될 CRM의 Script 주소를 반환
   * 21.12.30 - imweb 도 독립몰 호출하도록 요청
   * @param hostingType
   */
  getCrmScriptUrl(hostingType: HostingType): string {
    switch (hostingType) {
      case HostingType.CAFE24_SMART:
        return ScriptUrl.BASE_URL+ScriptUrl.CAFE24_SMART;
      case HostingType.GODOMALL_RENT:
        return ScriptUrl.BASE_URL+ScriptUrl.GODOMALL_RENT;
      case HostingType.MAKESHOP_D2:
      case HostingType.MAKESHOP_D4:
        return ScriptUrl.BASE_URL+ScriptUrl.MAKESHOP;
      case HostingType.SELF_HOSTED:
      case HostingType.IMWEB:
        return ScriptUrl.BASE_URL+ScriptUrl.SELF_HOSTED;
      default:
        return '';
    }
  }

  /**
   * create 22.10.12
   * append될 CRM의 dev 경로 script 주소 반환
   */
  getDevCrmScriptUrl(hostingType: HostingType): string {
    switch (hostingType) {
      case HostingType.CAFE24_SMART:
        return ScriptUrl.DEV_URL+ScriptUrl.CAFE24_SMART;
      case HostingType.GODOMALL_RENT:
        return ScriptUrl.DEV_URL+ScriptUrl.GODOMALL_RENT;
      case HostingType.MAKESHOP_D2:
      case HostingType.MAKESHOP_D4:
        return ScriptUrl.DEV_URL+ScriptUrl.MAKESHOP;
      case HostingType.SELF_HOSTED:
      case HostingType.IMWEB:
        return ScriptUrl.DEV_URL+ScriptUrl.SELF_HOSTED;
      default:
        return '';
    }
  }

  /**
   * 스토리지 구분값에 의해 사용할 스토리지를 가져옴
   * @param storageType
   * @private
   */
  private getStorage(storageType: CrmStorageType): Storage | null {
    switch (storageType) {
      case CrmStorageType.LOCAL:
        return localStorage;
      case CrmStorageType.SESSION:
        return sessionStorage;
      default :
        return null;
    }
  }

  /**
   * 회원 아이디 광고주에서 수집 후 반환
   * @return string : 회원 아이디
   */
  abstract getUserId(): {};

  /**
   * 무통장 주문 전환 시 계좌번호 정보, 입금자명 수집 후 반환
   * @returns 
   */
  abstract getBankInfo(): {};

  /**
   * 결제수단 수집
   */
  abstract getPayMethodInfo(): {};

  /**
   * 주문 작성 화면에서 sessionStorage에 정보 셋팅
   */
  abstract setOrderFormSessionStorage(): void;
}
