/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-28
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-07-28
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class GcosmoDataParser extends GodomallRentDataParser {
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try{
      if( this.isMobile(this.commandOptions) ) {
        return {
          'price' : NumberUtil.parseNumber(formElement['set_goods_price']!.value),
          'dcPrice' : NumberUtil.parseRationalNumber(formElement['set_goods_price'].value) * ((100 - NumberUtil.parseRationalNumber(formElement['goodsDiscount'].value))/100)
        };
      }
      return super.getProductPriceObj(formElement);
    }catch{
      return super.getProductPriceObj(formElement);
    }
    
  }
}