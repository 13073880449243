/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-08
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-12-23.
 * <p> beginning01 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class TrendgrDataParser extends MakeshopD4DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const parsedProduct = jsonObj.getDataByName('product');

    for (let i = 0; i < parsedProduct.length; i++) {
      parsedProduct[i]['price'] = NumberUtil.parseNumber(document.querySelectorAll('div.page-body > div.table-cart > table > tbody tr td:nth-child(5)')[i]!.textContent!.trim());
    }

    jsonObj.replaceData('product', parsedProduct);

    return jsonObj;
  }
}
