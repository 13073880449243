/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-10-18
 */
import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-10-18.
 * <p>
 *   <ul>
 *    <li>이 서브클래스는 'ssgcom', 'emssg', 'ssgmall', 'emartmall1' 4개의 광고주에서 사용.</li>
 *   </ul>
 * </p>
 * <p> {@link SelfHostedDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class SsgcomDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 각 상품들이 속한 광고주의 모비온 id를 추출하여
   * 전환시 상품정보에 해당 계열사의 id를 같이 포함하여 전송하도록 하였음.
   * @return {JsonObject} - 전환 데이터
   */
  protected getConversionData(): JsonObject {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];

    for (let i = 0; i < parsedProducts.length; i++) {
      products.push({
        'adverId': parsedProducts[i]['adverId'],
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice']
      });
    }

    const parsedData: {} = {
      'product': products,
      'adverId': this.adverId,
      'ordCode': targetObj['ordCode'],
      'totalPrice': targetObj['totalPrice'],
      'totalQty': targetObj['totalQty']
    };

    return new JsonObject(parsedData);
  }
}
