/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Punycode} from '../../lib/codec/Punycode';
import {Utf8} from '../../lib/codec/Utf8';
import {Base64} from '../../lib/codec/Base64';
import {Config} from './config/Config';
import {ConversionType, EventType} from '../../types/GlobalEnums';
import {StorageQueue} from '../../storageQueue/StorageQueue';
import {Transmitter} from './core/Transmitter';
import {DataParser} from '../../dataParser/DataParser';
import {StringUtil} from "../../lib/common/StringUtil";
import {ClientInfo} from "../../lib/common/ClientInfo";
import {DateUtil} from "../../lib/common/DateUtil";
import {ProductStorage} from "../productHistory/ProductStorage";
import {KeywordFetcher} from '../keyword/KeywordFetcher';
import {AdverConfigJsonMapper} from "../../adverConfigJsonMapper/AdverConfigJsonMapper";

/**
 * TODO 구현, 주석, 배열처리
 * create on 2019-08-02.
 * <p> 전환 </p>
 * <p> {@link Transmitter} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Converter extends Transmitter {
  protected readonly _punycode: Punycode;
  protected readonly _utf8: Utf8;
  protected readonly _base64: Base64;
  private options: {} | undefined;

  constructor(eventType: EventType, config: Config, dataParser: DataParser, options?: {}) {
    super(eventType, config, dataParser);
    this._punycode = new Punycode();
    this._utf8 = new Utf8();
    this._base64 = new Base64();
    this.options = options;
  }

  protected preSend(): void {
    const adverDomain = StringUtil.getDomainFromUrl(window.location.href);

    this.addExtraData(
        {
          'adverId': this.config.adverId,
          'domain': adverDomain,
          'charSet': document.characterSet,
          'referrer': document.referrer,
          'referrerDomain': StringUtil.getReferrerDomain(),
          'cookieEnabled': ClientInfo.getInstance().cookieEnabled ? ClientInfo.getInstance().cookieEnabled : true,
          'productCnt': this.getProductCodeCnt(this.jsonObject.data['product']),
          'fromUrl': window.location.href,
          'lsRegDate': DateUtil.getYYYYMMDDHHMISSString(),
          'convType': this.options && this.options['convType'] ? this.options['convType'] : ConversionType.PRODUCT,
          'siteCode': AdverConfigJsonMapper.getInstance(this.config.adverId).getSiteCode(),
          'solutionType': AdverConfigJsonMapper.getInstance(this.config.adverId).getHostingType()
        },
        false
    );

    this.addKeywordData();
  }

  /**
   * 큐에 데이터를 enqueue 한 후 전송한다.
   */
  protected finish(): void {
    /* localStorage에 상품코드 저장 */
    this.saveProductCodeArr(this.jsonObject.data['product']);

    /* 스토리지 큐 */
    if (ClientInfo.getInstance().cookieEnabled) {
      /* add to Storage Queue */
      StorageQueue.getInstance().enqueue(
          this.config.url,
          this.jsonObject.data
      );
    }

    super.finish();
  }

  /**
   * 구매한 상품 중에서 상품 코드 중복제거 후 개수 산정
   */
  private getProductCodeCnt(data: {}[]): number {
    try {
      let productCodeObj = {};
      if (data != null && data) {
        for (let i = 0; i < data.length; i++) {
          const obj = data[i];
          productCodeObj[obj['productCode']] = 1;
        }

        return Object.keys(productCodeObj).length;
      } else {
        return 1;
      }
    } catch (error) {
      return 1;
    }
  }

  /**
   * localStorage에 상품코드 저장
   * @param {Array<{}>} productArr  - 저장될 상품코드 배열
   */
  private saveProductCodeArr(productArr: Array<{}>): void {
    try {
      const productCodeArr: string[] = [];
      for (let i = 0; i < productArr.length; i++) {
        const productCode: string = productArr[i]['productCode'];
        if (productCode) {
          productCodeArr.push(productArr[i]['productCode'].toString());
        }
      }

      new ProductStorage().save(this.eventType, productCodeArr);
    } catch (e) {
    }
  }

  private addKeywordData() {
    //외부키워드 데이터 세팅
    this.addExtraData(new KeywordFetcher().getConversionExternalKeyword(this.eventType));
    this.addExtraData({'product': new KeywordFetcher().getConversionInternalKeyword(this.eventType, this.jsonObject.data['product'])}, true);
  }
}
