/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-23
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-23
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class BodydakDataParser extends GodomallRentDataParser {
  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions)
        ? this.getMobilePayConvDataFromBasketPage()
        : super.getPayConvDataFromBasketPage();
  }

  private getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();

    let totalQty = 0;
    const productList: Array<{}> = [];
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.goods-list-info a');
    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseNumber((document.querySelectorAll('input[name="goodsCnt[]"]')[i] as HTMLInputElement).value);
      productList.push({
        'productName': productNameList[i].textContent!.trim(),
        'productCode': new QueryStringer(productNameList[i].search.substring(1)).getParam('goodsNo'),
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('span.price')[i].textContent!.trim()),
        'qty': qty
      });
      totalQty += qty;
    }

    jsonObj['product'] = productList;
    jsonObj['totalQty'] = totalQty;
    return jsonObj;
  }
}