/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Converter} from './Converter';
import {EventType} from '../../types/GlobalEnums';
import {PayConverterConfig} from './config/PayConverterConifg';
import {DataParser} from '../../dataParser/DataParser';

/**
 * create on 2019-08-07.
 * <p> 간편 결제 시스템을 통한 전환 </p>
 * <p> <b>NOTE : </b>간편 결제를 통한 컨버전일 경우에 대해 별도 작업이 필요하면 여기서 정의. </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PayConverter extends Converter {
  constructor(eventType: EventType, config: PayConverterConfig, dataParser: DataParser, options?: {}) {
    super(eventType, config, dataParser, options);
  }

  protected getLagacyServletUrl(): string {
    const productInfo = this.jsonObject.getDataByName('product')[0];

    const sc = this.jsonObject.getDataByName('siteCode');
    const userid = this.jsonObject.getDataByName('adverId');
    const pcode = productInfo['productCode'] ? productInfo['productCode'] : '';
    const price = productInfo['price'] ? productInfo['price'] : '';
    const pnm = productInfo['productName'] ? encodeURIComponent(productInfo['productName']) : '';
    const img = productInfo['imageUrl'] ? encodeURIComponent(productInfo['imageUrl']) : '';
    const dcPrice = productInfo['dcPrice'] ? productInfo['dcPrice'] : '';
    const soldOut = productInfo['soldOut'] ? productInfo['soldOut'] : '';
    const cate1 = productInfo['topCategory'] ? productInfo['topCategory'] : '';
    const form = encodeURIComponent(this.jsonObject.getDataByName('referrer'));
    const purl = encodeURIComponent(window.location.href);
    const qty = this.jsonObject.getDataByName('totalQty');

    return `https://log.mediacategory.com/servlet/conv?sc=${sc}&userid=${userid}&pcode=${pcode}&price=${price}&pnm=${pnm}&img=${img}&dcPrice=${dcPrice}&soldOut=${soldOut}&mdPcode=&cate1=${cate1}&gb=02&form=${form}&purl=${purl}&qty=${qty}&viaPaySys=nPay&rdType=CONV`;
  }
}
