/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { SelfHostedDataValidator } from '../SelfHostedDataValidator';

/**
 * create on 2019-09-10.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class TesterDataValidator extends SelfHostedDataValidator {
  constructor() {
    super();
  }

  protected commonTraceValidate(data: {}): {} {
    return super.commonTraceValidate(data);
  }

  protected collectValidate(data: {}): {} {
    return super.collectValidate(data);
  }

  protected cartValidate(data: {}): {} {
    return super.cartValidate(data);
  }

  protected wishValidate(data: {}): {} {
    return super.wishValidate(data);
  }

  protected conversionValidate(data: {}): {} {
    return super.conversionValidate(data);
  }
}
