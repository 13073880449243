/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-14
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2022-12-14
 * <p> djh1658 - https://www.whitegown.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Djh1658DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    return this.isMobile(this.commandOptions) ? this.getMobilePayConvDataFromBasketPage() : this.getWebPayConvDataFromBasketPage();
  }

  private getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('ul li div.info a');
    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseRationalNumber(document.querySelectorAll('ul li div.info a dd')[i]!.textContent!.split(':')[1].trim());
      const rawPrice =NumberUtil.parseNumber(document.querySelectorAll('ul li div.info a .prc')[i].textContent!.replace(/\D/g,''));
      parsedProducts.push({
        'productCode': new QueryStringer(productNameList[i].search.substring(1)).getParam('goodsNo'),
        'productName': document.querySelectorAll('ul li div.info a span')[i].textContent!.trim(),
        'qty': qty,
        'price': rawPrice/qty
      });

      totalQty += qty;
    }

    jsonObj['product'] = parsedProducts;
    jsonObj['totalQty'] = totalQty;
    return jsonObj;
  }

  private getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getPayConvDataFromBasketPage();
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    const productNameList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('tbody td span a img');
    const productInfo: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('tbody td div a');
    for (let i = 0; i < productNameList.length; i++) {
      const qty: number = NumberUtil.parseRationalNumber((document.querySelectorAll('td.count.this-product input')[i] as HTMLInputElement)!.value);
      const rawPrice = NumberUtil.parseRationalNumber(document.querySelectorAll('.ta-c.this-product .price')[i].textContent!.trim());
      parsedProducts.push({
        'productCode': new QueryStringer(productInfo[i].search.substring(1)).getParam('goodsNo'),
        'productName': document.querySelectorAll('tbody td div a')[i].textContent!.trim(),
        'qty': qty,
        'price': rawPrice/qty
      });
      totalQty += qty;
    }
    jsonObj['product'] = parsedProducts;
    jsonObj['totalQty'] = totalQty;
    return jsonObj;
  }
}