/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-03-31
 */

import { SelfHostedDataParser } from "../SelfHostedDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
 * create on 2023-03-31
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class FastcampusDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();
    const domain = StringUtil.getDomainFromUrl(location.href);
    if (domain === 'plusxsharex.fastcampus.co.kr') {
      jsonData.addData('domain', 'fastcampus.co.kr');
    }
    return jsonData;
  }
}
