/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-15
 */

import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from '../../../lib/common/NumberUtil';
import { QueryStringer } from '../../../lib/url/QueryStringer';
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";


/**
 * create on 2021-12-15.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class WellnessteaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getConversionData_prev(): JsonObject {
    if(!this.isMobile(this.commandOptions)){
      const productInfo = document.querySelectorAll('.orderListArea .ec-base-table tbody tr.xans-record-');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;
  
      for (let i = 0; i < productInfo.length; i++) {
        const qty = NumberUtil.parseNumber(productInfo[i].querySelector('td:nth-of-type(4)')!.textContent!.replace(/\D/g, ''));
  
        parsedProducts.push({
          'productCode': new QueryStringer((productInfo[i].querySelector('.thumb a') as HTMLAnchorElement).search.substring(1)).getParam('product_no'),
          'productName': productInfo[i].querySelector('strong.name a')!.textContent!.trim(),
          'qty': qty,
          'price': NumberUtil.parseNumber(productInfo[i].querySelector('td:nth-of-type(3) strong')!.textContent!.replace(/\D/g, ''))
        });
  
        totalQty += qty;
      }
  
      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
        'totalPrice': document.querySelector('.totalArea td div.box.txtEm.txt16 strong span.txt23')!.textContent!.replace(/\D/g, ''),
        'totalQty': totalQty.toString()
      };
  
      return new JsonObject(parsedData);
    }else{
      return super.getConversionData_prev();
    }
  }
}
