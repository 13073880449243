/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-16
 */
import {GTNode} from "./GTNode";

/**
 * create on 2019-12-16.
 * <p> General Tree 구현 </p>
 * <p> {@link GTNode} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GeneralTree<T> {
  /* 루트 노드 */
  private _root: GTNode<T>;

  constructor(value: T | GTNode<T>, firstChild?: GTNode<T>, sibling?: GTNode<T>) {
    /* 타입이 노드가 아닌 값이면 노드 생성 후 루트 노드로 설정 */
    this._root = value instanceof GTNode ? value : new GTNode<T>(value);

    if (firstChild) {
      this._root.insertFirst(firstChild);

      if (sibling) {
        this._root.insertNext(sibling);
      }
    }
  }

  get root(): GTNode<T> {
    return this._root;
  }

  /*
  * TODO - 루트 노드 변경하면 child 노드들의 연결도 수정하는 로직 추가
  * */
  set root(value: GTNode<T>) {
    this._root = value;
  }

  /**
   * 현재 tree를 제거.
   * 현재 tree가 다른 tree에 대한 child 및 sub-tree이거나, 현재 tree의 child들이 다른 sub-tree의 루트인 경우
   * 현재 tree를 제거한 후 parent와 child가 갖고 있던 sub-tree들을 연결한다.
   */
  clear(): void {
    if (this.root.parent) {
      const parent: GTNode<T> = this.root.parent;
      const rightSiblingOfRoot: GTNode<T> | undefined = this.root.rightSibling;
      const grandChildren: Array<GTNode<T>> = this.getGrandChildren();

      for (let i = 0; i < grandChildren.length; i++) {
        grandChildren[i].parent = parent;

        if (i === 0) {
          parent.leftmostChild = grandChildren[i];
        } else {
          grandChildren[i - 1].rightSibling = grandChildren[i];
        }
      }

      grandChildren[grandChildren.length - 1].rightSibling = rightSiblingOfRoot;
    } else {
      throw new Error('Cannot clear the ancestor root');
    }
  }

  /**
   * 자식 노드의 유무 확인
   * @return {boolean}
   */
  hasChildren(): boolean {
    return typeof this.root !== 'undefined' && typeof this.root.leftmostChild !== 'undefined';
  }

  /**
   * 모든 자식 노드를 찾아 배열로 반환
   * @return {Array<GTNode<T>>} 자식 노드로 이루어진 배열
   */
  getChildren(): Array<GTNode<T>> {
    const children: Array<GTNode<T>> = [];

    if (this.root) {
      let child: GTNode<T> | undefined = this.root.leftmostChild;

      while (child) {
        children.push(child);
        child = child.rightSibling;
      }
    } else {
      throw new Error('Tree has no root node.');
    }

    return children;
  }

  /**
   * 트리의 루트 노드에 대해 모든 손자 노드를 찾아 배열로 반환한다.
   * @return {Array<GTNode<T>>} 손자 노드로 이루어진 배열
   */
  getGrandChildren(): Array<GTNode<T>> {
    const children: Array<GTNode<T>> = this.getChildren();
    let grandChildren: Array<GTNode<T>> = [];

    for (let i = 0; i < children.length; i++) {
      const child: GTNode<T> = children[i];
      const subTree: GeneralTree<T> = new GeneralTree<T>(child);
      grandChildren = grandChildren.concat(subTree.getChildren());
    }

    return grandChildren;
  }

  /**
   * child를 생성.
   * leftmost-child가 없으면 leftmost-child부터 생성한다
   * @param {T} value 노드의 값
   */
  createChild(value: T): void {
    this.createChildByNode(new GTNode<T>(value));
  }

  /**
   * left-child를 생성.
   * @param {T} value 노드의 값
   */
  createLeftChild(value: T): void {
    this.createLeftChildByNode(new GTNode<T>(value));
  }

  /**
   * 입력한 노드로 child를 생성.
   * leftmost-child가 없으면 leftmost-child부터 생성한다
   * @param {GTNode<T>} node  자식이 될 노드
   */
  createChildByNode(node: GTNode<T>): void {
    if (this.root.leftmostChild) {
      this.root.insertNext(node);
    } else {
      this.root.insertFirst(node);
    }
  }

  /**
   * 입력한 노드로 left-child를 생성.
   * @param {GTNode<T>} node  자식이 될 노드
   */
  createLeftChildByNode(node: GTNode<T>): void {
    this.root.insertFirst(node);
  }

  /**
   * 조건에 해당하는 노드를 트리에서 찾아 반환 (전위순회)
   * @param {(currentNode: GTNode<T>) => boolean} condition 찾을 노드의 조건. 해당 함수의 리턴이 true가 되는 노드를 찾는다
   * @return {GTNode<T> | undefined}  검색된 노드 (찾지 못하면 <code>undefined</code>를 반환한다)
   */
  search(condition: (currentNode: GTNode<T>) => boolean): GTNode<T> | undefined {
    let found: GTNode<T> | undefined = undefined;

    const internalPreorder = (rootNode: GTNode<T>): void => {
      if (condition(rootNode)) {
        found = rootNode;
        return;
      }

      if (!rootNode.isLeaf()) {
        let temp: GTNode<T> | undefined = rootNode.leftmostChild;
        while (temp) {
          internalPreorder(temp);
          temp = temp.rightSibling;
        }
      }
    };

    internalPreorder(this.root);
    return found;
  }

  /**
   * 전위순회. 순회를 하면서 노드를 방문할 때마다 입력된 콜백을 수행한다.
   * @param {GTNode<T>} rootNode  루트 노드
   * @param {(currentNode: GTNode<T>) => void} callback 노드를 방문할 때마다 수행할 콜백
   */
  preorder(rootNode: GTNode<T>, callback?: (currentNode: GTNode<T>) => void): void {
    if (callback) {
      callback(rootNode);
    }

    if (!rootNode.isLeaf()) {
      let temp: GTNode<T> | undefined = rootNode.leftmostChild;
      while (temp) {
        this.preorder(temp, callback);
        temp = temp.rightSibling;
      }
    }
  }
}
