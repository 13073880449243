/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {ValidationRuleImpl} from './ValidationRuleImpl';
import {ValidationRule} from './ValidationRule';
import {InvalidData} from "../../lib/ajax/InvalidData";

/**
 * create on 2019-08-02.
 * <p> 데이터를 검증하기 위한 규칙의 추가, 수행 기능 </p>
 * <p> {@link DataValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class FormatValidator {
  private static instance: FormatValidator;
  /* 검증을 통과하지 못했을 경우 data를 undefined로 설정할지 결정하는 flag */
  private setUndefinedWhenValidationFails!: boolean;
  /* validation을 수행할 목록 */
  private ruleList: ValidationRule[] = [];

  private constructor() {
  }

  /**
   * 인스턴스 생성
   * @param {boolean} setUndefinedWhenValidationFails - 데이터를 undefined로 설정할지
   * @return {FormatValidator}
   */
  static createRule(setUndefinedWhenValidationFails = false): FormatValidator {
    if (!FormatValidator.instance) {
      FormatValidator.instance = new FormatValidator();
    }

    this.prototype.setUndefinedWhenValidationFails = setUndefinedWhenValidationFails;
    return FormatValidator.instance;
  }

  /**
   * 검증 로직 추가
   * @param {ValidationRule} validationRule - 추가할 검증 규칙
   * @return {FormatValidator}
   */
  addRule(validationRule: ValidationRule): FormatValidator {
    this.ruleList.push(validationRule);
    return this;
  }

  /**
   * 검증 수행
   * @param {{}} data - 검증 대상 데이터
   * @return {{}}
   */
  validate(data: {}): {} {
    let valid = true;
    let validData = this.validNullData(data);
    while (this.ruleList.length > 0) {
      const rule: ValidationRuleImpl = this.ruleList.shift() as ValidationRuleImpl;
      const ruleValid = rule.validate(validData);
      valid = valid && ruleValid;
    }

    return valid
        ? validData
        : this.undefinedData(validData, this.setUndefinedWhenValidationFails);
    // : new InvalidData();
  }

  /**
   * <code>flag</code>에 따라 데이터를 <code>undefined</code>로 설정
   * @param {{}} data - undefined로 설정될 대상 데이터
   * @param {boolean} flag  - undefined로 설정 여부
   * @return {{}|undefined}
   */
  private undefinedData(data: {}, flag: boolean): {} {
    return flag ? new InvalidData() : data;
  }

  /**
   * data중 null 인 요소를 공백으로 변환
   * @param data
   * @private
   */
  private validNullData(data: {}): {} {
    try {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        let now = data[keys[i]];

        // json요소중 object 요소가 있으면 재귀함수 호출하여 내부 값 검사
        // ex) json, array 등
        if (now && typeof now === 'object') {
          now = this.validNullData(now);
        } else if (!now && now !== 0) {
          data[keys[i]] = '';
        }
      }

      return data;
    } catch (e) {
      return data;
    }
  }
}
