/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-08-23
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-08-23.
 * <p> www.metrocityworld.com </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class MetrocityDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    try {
      if (!this.isMobile(this.commandOptions)) {
        superData['price'] = NumberUtil.parseRationalNumber((document.querySelector('.detailArea .product-price-info.normal .product-custom-price') as HTMLSpanElement).getAttribute('value')!);
      }
      return superData;
    } catch {
      return superData;
    }
  }
}
