/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee1</a>
 * @since 2023-01-30
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-01-30.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SoundpandaDataParser extends Cafe24SmartDataParser {
    constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
    }
  
    protected getShopCollectData(): JsonObject {
      const jsonObj = super.getShopCollectData();
      let img ='';
      if(this.isMobile(this.commandOptions)){
        img = (document.querySelector('div.xans-product ul li img')  as HTMLImageElement).src;
      }else{
        img = (document.querySelector('div ul.xans-product li img') as HTMLImageElement).src;
      }
      jsonObj.replaceData('imageUrl',img);
      return jsonObj;
    }
}
  