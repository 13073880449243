/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-08-25
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2022-08-25.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class CoakoreaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if(this.isMobile(this.commandOptions)){
      return super.getConversionData_prev();
    }
    const orderList = document.querySelectorAll('tr.xans-record- td.thumb');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;
    let totalPrice = 0;

    for (let i = 0; i < orderList.length; i++) {
      const prodImgUrl = (document.querySelectorAll('tr.xans-record- td.thumb a')[i] as HTMLAnchorElement).search.substring(1);
      const productCode: string = new QueryStringer(prodImgUrl).getParam('product_no');
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('tr.xans-record- td:nth-child(5)')[i].textContent!.replace(/\D/g, ''));
      const price: number = NumberUtil.parseNumber(document.querySelectorAll('tr.xans-record- td:nth-child(4) div.displaynone strong')[i].textContent!.replace(/\D/g, ''));

      parsedProducts.push({
        'productCode': productCode,
        'productName': document.querySelectorAll('tr.xans-record- td:nth-child(3) strong')[i].textContent!.trim(),
        'qty': qty,
        'price': price
      });

      totalQty += qty;
      totalPrice += price;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': window['mi_order_num'],
      'totalPrice': NumberUtil.parseNumber(window['mi_val'].replace(/\D/g, '')),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
