/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-09-02.
 * <p> 유효하지 않는 값을 받은 경우에 대한 에러 처리 </p>
 * <p> {@link Error} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class InvalidValueError extends Error {
  constructor(variableName?: string, variableValue?: any) {
    let message: string;
    if (variableName !== undefined) {
      message = `Invalid ${variableName}`;

      if (variableValue !== undefined) {
        message += ` = ${variableValue}`;
      }
    } else {
      message = 'Invalid value';
    }
    super(message);
    this.name = "InvalidValueError";
  }
}
