/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-09-02
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { EventUtil } from "../../../lib/event/EventUtil";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2022-09-02
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class Romanson102DataParser extends SelfHostedDataParser {

  protected getShopCollectData(): JsonObject {
    if (location.hostname !== 'm.jestina.co.kr') {
      this.webCustomNpayCall();
    }
    return super.getShopCollectData();
  }

  //해당광고주만 npay custom으로 인해 임시 조치
  private webCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector('.product-detail-main__img-naverpay img')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    });
  }

}