/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-09
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2021-02-09.
 * <p> yufit1 - theyufit.co.kr </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Yufit1DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if (this.isMobile(this.commandOptions)) return super.getConversionData_prev();

    const productAnchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.ec-base-table .xans-order a.ec-product-name');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productAnchorList.length; i++) {
      const productCode: string = productAnchorList[i].href.split('/')[5];
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('.ec-base-table tbody tr.xans-record- td:nth-child(4)')[i].textContent!.trim());
      parsedProducts.push({
        'productCode': productCode,
        'productName': productAnchorList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.ec-base-table tbody tr.xans-record- td:nth-child(3) div.displaynone strong')[i].textContent!.trim())
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('table tr td div.box.txtEm.txt16 strong span')!.textContent!.trim()),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
