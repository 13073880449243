/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-19
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";


/**
 * create on 2022-01-19.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class GtgearDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageUrl(): string {
    if(document.querySelector('meta[property="og:image"]')){
      return (document.querySelector('meta[property="og:image"]') as HTMLMetaElement)['content'];
    }
    return super.getImageUrl();
  }
}
