/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {NumberUtil} from '../lib/common/NumberUtil';
import {Base64} from '../lib/codec/Base64';
import {Queue} from '../lib/dataStructure/queue/Queue';
import {InsertionSort} from '../lib/sort/InsertionSort';
import {Codec} from '../lib/codec/Codec';
import {Utf8} from '../lib/codec/Utf8';
import {NotSupportedError} from '../error/NotSupportedError';
import {Value} from '../lib/value/Value';

/**
 * create on 2019-10-02.
 * <p> 서버에 전송될 데이터의 큐 (localStorage 이용) </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class StorageQueue {
  /* 브라우저의 로컬 스토리지 지원 여부 */
  static localStorageSupported: boolean = StorageQueue.whetherLocalSorageSupported();
  /* 스토리지 key의 prefix */
  private static readonly KEY_PREFIX: string = '_ENP_';
  /* 만료 기간(일) */
  private expiry = 2;
  /* 인스턴스 */
  private static instance: StorageQueue;
  /* 스토리지 */
  private readonly storage: Storage;
  /* 스토리지 key에 대한 큐 */
  private keyQueue!: Queue<string>;
  /* 데이터의 인코딩/디코딩 방식 */
  // private codecRule: Codec = new Base64();
  private codecRules: Codec[] = [new Utf8(), new Base64()];

  private constructor() {
    if (StorageQueue.localStorageSupported) {
      this.storage = window.localStorage;
    } else {
      throw new NotSupportedError('localStorage');
    }
  }

  /**
   * 인스턴스 생성
   * @return {StorageQueue}
   */
  static getInstance(): StorageQueue {
    if (!StorageQueue.instance) {
      StorageQueue.instance = new StorageQueue();
    }

    /* 인스턴스를 가져올 때마다 스토리지에 있는 데이터를 keyQueue에 병합하고 만료된 키, 쓰레기 데이터를 제거한다 */
    StorageQueue.instance.keyQueue = new Queue();
    StorageQueue.instance.mergeStorageKeysToQueue();
    StorageQueue.instance.removeJunkItems();
    StorageQueue.instance.removeExpiredItems();
    return StorageQueue.instance;
  }

  /**
   * 브라우저가 localStorage를 지원하는지 확인
   * @return {boolean}
   * <p><code>true</code> - 지원</p><p><code>false</code> - 지원하지 않음</p>
   */
  static whetherLocalSorageSupported(): boolean {
    try {
      return !!window.localStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * 인코딩 순서에 따라 localStorage에 저장될 값을 인코딩
   * @param {string} stringVal  인코딩할 값
   * @return {string}           인코딩된 값
   */
  private encode(stringVal: string): string {
    for (let i = 0; i < this.codecRules.length; i++) {
      stringVal = this.codecRules[i].encode(stringVal);
    }

    return stringVal;
  }

  /**
   * 인코딩 순서에 따라 localStorage에 저장될 값을 디코딩
   * @param {string} stringVal  디코딩할 값
   * @return {string}           디코딩된 값
   */
  private decode(stringVal: string): string {
    for (let i = this.codecRules.length - 1; i >= 0; i--) {
      stringVal = this.codecRules[i].decode(stringVal);
    }

    return stringVal;
  }

  /**
   * {@link JsonData}를 JSON 형식의 문자열로 변환 후 인코딩
   * @param {JsonData} jsonData 데이터
   * @return {string}           인코딩된 값
   */
  private getEncodedStorageData(jsonData: JsonData): string {
    // return this.codecRule.encode(JSON.stringify(jsonData));
    // console.log(`jsonData=${JSON.stringify(jsonData)}`);
    return JSON.stringify(jsonData);
  }

  /**
   * localStorage에서 입력한 키에 대한 값을 디코딩 후 {@link JsonData}로 변환.
   * @param {string} storageKey 키
   * @return {JsonData | null}
   * <p><code>JsonData</code> - 변환된 값</p><p><code>null</code> - localStorage에서 키를 찾을 수 없을 때</p>
   */
  private getDecodedJsonData(storageKey: string): JsonData | null {
    // return <JsonData> JSON.parse(this.codecRule.decode(this.storage.getItem(storageKey)));
    const item: string | null = this.storage.getItem(storageKey);
    return item === null ? null : (JSON.parse(item) as JsonData);
  }

  /**
   * JsonData 타입인지 확인
   * @param {string} stringified  - <code>JSON.stringify()</code> 된 문자열
   * @return {boolean}
   * <p><code>true</code> - JsonData 타입</p><p><code>false</code> - JsonData 타입이 아님</p>
   */
  private typeOfJsonData(stringified: string): boolean {
    try {
      const obj: {} = JSON.parse(stringified);
      const propertiesOfObj: string[] = Object.getOwnPropertyNames(obj);
      const propertiesOfJsonData: string[] = Object.getOwnPropertyNames(new JsonData('', {}));

      /* JsonData 타입인지 */
      const isTypeOfJsonData = JSON.stringify(propertiesOfObj) === JSON.stringify(propertiesOfJsonData);
      /* 빈 객체인지 */
      const isNotEmptyObject = typeof obj['data'] === 'object' && Object.keys(obj['data']).length > 0;

      return isTypeOfJsonData && isNotEmptyObject;
    } catch (e) {
      return false;
    }
  }

  /**
   * 첫 번째 키에 해당하는 아이템을 조회
   * @return {JsonData | null}
   */
  peek(): JsonData | null {
    const storageKey: string | undefined = this.keyQueue.peek();
    return Value.isTypeOfUndefined(storageKey)
        ? null
        : this.getDecodedJsonData(storageKey!);
  }

  /**
   * 큐에 들어있는 내용의 갯수
   * @return {number}
   */
  size(): number {
    return this.keyQueue.size();
  }

  /**
   * <code>StorageKey</code>를 생성 후 큐에 데이터를 추가
   * @param {string} destUrl
   * @param {Object | Array<Object>} data
   * @return {string} - 생성된 <code>StorageKey</code>
   */
  enqueue(destUrl: string, data: {} | Array<{}>): string {
    const storageKey: string = this.createStorageKey();
    this.storage.setItem(
        storageKey,
        this.getEncodedStorageData(new JsonData(destUrl, data))
    );
    this.keyQueue.enqueue(storageKey);

    // TODO to remove comment
    // console.log(`ENQUEUE :key = ${this.keyQueue.peek()}`);
    // console.log(JSON.stringify(this.keyQueue));

    return storageKey;
  }

  /**
   * 큐에서 첫 번째 키에 해당하는 아이템을 제거
   * @return {JsonData | null}
   */
  dequeue(): JsonData | null {
    const storageKey: string | undefined = this.keyQueue.peek();

    if (Value.isTypeOfUndefined(storageKey)) {
      return null;
    }
    // TODO to remove comment
    // console.log(`Q size : ${this.keyQueue.size()}`);
    // console.log(`value : ${JSON.stringify(this.getDecodedJsonData(storageKey))}`);
    // console.log(`DEQUEUE : key = ${storageKey}`);
    const jsonData: JsonData | null = this.getDecodedJsonData(storageKey!);
    this.storage.removeItem(storageKey!);
    this.keyQueue.dequeue();

    return jsonData;
  }

  /**
   * 큐의 모든 내용을 제거
   */
  clear(): void {
    this.storage.clear();
    this.keyQueue.clear();
  }

  /**
   * 현재 시간으로 <code>StorageKey</code> 생성 (_ENP_YYYYMMDDhhmmssSSS_[<code>TimeString</code> 갯수 3자리])
   * <b>e.g. </b><code>_ENP_20191002182457627_001</code>, <code>_ENP_20191002182457627_002</code>
   * @return {number}
   */
  private createStorageKey(): string {
    const currentDate: Date = new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);
    const hours: string = currentDate.getHours().toString();
    const minutes: string = NumberUtil.padZero(currentDate.getMinutes(), 2);
    const seconds: string = NumberUtil.padZero(currentDate.getSeconds(), 2);
    const milliSeconds: string = NumberUtil.padZero(
        currentDate.getMilliseconds(),
        3
    );

    const timeString: string =
        year + month + date + hours + minutes + seconds + milliSeconds;
    const timeStringCount: number = this.getTimeStringCount(timeString) + 1;
    return (
        StorageQueue.KEY_PREFIX +
        timeString +
        '_' +
        NumberUtil.padZero(timeStringCount, 3).toString()
    );
  }

  /**
   * <code>StorageKey</code>의 <code>TimeString</code>이 같은 id의 갯수
   * @param {string} timeString
   * @return {number}
   */
  private getTimeStringCount(timeString: string): number {
    if (this.size() > 0) {
      const clonedKeyQueue: Queue<string> = this.keyQueue.deepClone();
      const keysArr: string[] = [];

      /* undefined 값을 제외한 모든 key를 배열로 생성 */
      for (let i = 0; i < this.keyQueue.size(); i++) {
        const key: string | undefined = clonedKeyQueue.dequeue();
        if (typeof key !== 'undefined') {
          keysArr.push(key);
        }
      }

      /* timeString과 같은 key를 찾는다 */
      const timeStringArr: string[] = keysArr.filter(value => {
        const start: number = StorageQueue.KEY_PREFIX.length;
        const end: number = value.length - start + 1;
        return value.substring(start, end) === timeString;
      });

      return timeStringArr.length;
    }

    return 0;
  }

  /**
   * localStorage의 키들 중 모비온과 관련된 키들을 큐에 병합
   */
  private mergeStorageKeysToQueue(): void {
    let storageKeyArr: string[] = this.sortedKeyArr(this.getValidStorageKeys());
    while (storageKeyArr.length > 0) {
      this.keyQueue.enqueue(storageKeyArr.shift()!);
    }
  }

  /**
   * localStorage의 키들 중 유효한 키들을 배열로 리턴.
   * <b>NOTE: </b>유효 여부는 키에 해당하는 값이 undefined가 아니고, 모비온과 관련된 키의 여부이다.
   * @return {string[]} 유요한 키들의 배열
   */
  private getValidStorageKeys(): string[] {
    /* storage 키와 같은 키인가 */
    const isStorageKey = (storageKey: string): boolean => storageKey.substring(0, StorageQueue.KEY_PREFIX.length) === StorageQueue.KEY_PREFIX;
    /* 값이 undefined가 아닌가 */
    const isNotUndefined = (storageKey: string): boolean => typeof this.storage.getItem(storageKey) !== 'undefined';

    return Object.keys(this.storage).filter(value => isStorageKey(value) && isNotUndefined(value));
  }

  /**
   * 키 배열을 삽입정렬 후 리턴.
   * @param {string[]} keyArray 정렬할 키 배열
   * @return {string[]}         정렬된 결과
   */
  private sortedKeyArr(keyArray: string[]): string[] {
    return InsertionSort.sort(keyArray, InsertionSort.CRITERIA.STRING.ASC);
  }

  /**
   * 만료된 키들을 큐와 localStorage에서 제거
   */
  private removeExpiredItems(): void {
    /* localStorage에서 가져온 키 배열 */
    let storageKeyArr: string[] = this.getValidStorageKeys();

    /* localStorage에 저장된 키들 중 만료된 키들의 배열 */
    let expiredKeyArr: string[] = storageKeyArr.filter((key) => {
      const timeString: string = key.replace(StorageQueue.KEY_PREFIX, '').split('_')[0];
      const date = new Date(timeString.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{3})$/,'$1-$2-$3 $4:$5:$6:$7'));
      return date.getTime() < (Date.now() - (1000 * 60 * 60 * 24 * this.expiry));
    });

    /* 만료되지 않은 키 배열 */
    let nonExpiredKeyArr: string[] = [];
    storageKeyArr.forEach((key) => {
      if (expiredKeyArr.filter(targetKey => key === targetKey).length === 0) {
        nonExpiredKeyArr.push(key);
      }
    });

    /* localStorage에서 만료된 키들을 제거 */
    expiredKeyArr.forEach(expiredKey => this.storage.removeItem(expiredKey));

    /* keyQueue를 만료되지 않은 키들로 초기화 */
    this.keyQueue.clear();
    this.sortedKeyArr(nonExpiredKeyArr).forEach(key => this.keyQueue.enqueue(key));
  }

  /**
   * 쓰레기 값을 갖고 있는 키들을 큐와 localStorage에서 제거
   */
  private removeJunkItems(): void {
    /* localStorage에서 가져온 키 배열 */
    const storageKeyArr: string[] = this.getValidStorageKeys();

    /* 쓰레기값이 아닌 키 배열 */
    const nonJunkKeyArr: string[] = [];

    /* 쓰레기값인 키 배열 */
    const junkKeyArr: string[] = [];

    storageKeyArr.forEach((key: string) => {
      if (this.typeOfJsonData(this.storage.getItem(key)!)) {
        nonJunkKeyArr.push(key);
      } else {
        junkKeyArr.push(key);
      }
    });

    /* localStorage에서 만료된 키들을 제거 */
    junkKeyArr.forEach(junkKey => this.storage.removeItem(junkKey));

    /* keyQueue를 쓰레기 값이 아닌 키들로 초기화 */
    this.keyQueue.clear();
    this.sortedKeyArr(nonJunkKeyArr).forEach(key => this.keyQueue.enqueue(key));
  }
}

/**
 * 데이터 큐에 들어갈 객체
 */
class JsonData {
  /* 데이터가 전송될 목적지의 URL */
  private readonly destUrl: string;
  /* 데이터(JSON) */
  private readonly data: {} | Array<{}>;

  constructor(destUrl: string, data: {} | Array<{}>) {
    this.destUrl = destUrl;
    this.data = data;
  }
}
