/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { CommandConfigBuilder } from './CommandConfigBuilder';
import { CommandType, EventType } from '../../../types/GlobalEnums';
import { DataParser } from '../../../dataParser/DataParser';
import { JsonObject } from '../../../lib/json/JsonObject';

/**
 * create on 2019-08-23.
 * <p> 실행되는 <code>Command</code> 객체에 대한 설정 </p>
 * <p> {@link Command}, {@link CommandFactory} and {@link CommandConfigBuilder}관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class CommandConfig {
  private readonly _commandType: CommandType;
  private readonly _eventType: EventType;
  private readonly _adverId: string;
  private readonly _jsonData: JsonObject;
  private readonly _dataParser: DataParser;
  private readonly _options: {};

  constructor(builder: CommandConfigBuilder) {
    this._commandType = builder.commandType;
    this._eventType = builder.eventType;
    this._adverId = builder.adverId;
    this._jsonData = builder.jsonData;
    this._dataParser = builder.dataParser;
    this._options = builder.options;
  }

  get commandType(): CommandType {
    return this._commandType;
  }

  get eventType(): EventType {
    return this._eventType;
  }

  get adverId(): string {
    return this._adverId;
  }

  get jsonData(): JsonObject {
    return this._jsonData;
  }

  get dataParser(): DataParser {
    return this._dataParser;
  }

  get options(): {} {
    return this._options;
  }
}
