/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-02-07
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2023-02-07
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class ImaliveDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      let superData = super.getProductPrice();
      superData['price'] = NumberUtil.parseNumber(document.querySelector('ul.product_custom_css li.w-cont:nth-child(2)')!.textContent!.replace(/\D/g,''));
      superData['dcPrice'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content);
      return superData;
    } catch (e) {
      return super.getProductPrice();
    }
  }
}