/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-23
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-03-23
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class KoimooiDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    const product: Array<{}> = jsonObj['product'];

    for (let i = 0; i < product.length; i++) {
      product[i]['dcPrice'] =  NumberUtil.parseRationalNumber(document.querySelectorAll('div:nth-child(3) div > div:nth-child(10) > strong')[i].textContent!.replace(/\D/g, ''));
      }
      jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('tr:nth-child(3) strong:nth-child(2) span')!.textContent!.replace(/\D/g, ''));
    return jsonObj;
  }
  
}
