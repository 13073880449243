
/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hsju@enliple.com">mgpark</a>
 * @since 2019. 5. 25
 */
import { EncodingList } from '../../types/GlobalEnums';
import { StringUtil } from './StringUtil';
import { TextDecoder } from 'text-encoding';
/**
 * TODO
 * create on 2019-09-27.
 * <p> Health Check를 통해 CDN의 경로를 설정해 반환 하는 클래스</p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class EncodingConverter {
  HostingTypeconstructor() {}

  /**
   * 16신수 형식으로 되어있는 인코딩을 원하는 encoding에 맞게 변환 후 반환
   *  - 변환가능한 인코딩 목록 : GlobalEnums class의 EncodingList 정의
   * @param  hexString 16진수의 문자열
   * @param  encoding  변환할 인코딩 타입
   * @return string: 변환된 텍스트
   */
  hexToText(hexString: string = '', encoding: string): string {
    let text = '';

    try {
      let hexArray = this.getHexArrayInText(hexString, encoding);

      if (hexArray === null || hexArray === undefined || hexArray === []) {
        return text;
      }

      return text = this.getDecodingText(hexArray, encoding);
    } catch (e) {
      return '';
    }
  }

  /**
   * 인코딩 형식에 따라 디코드해서 문자열 반환
   * @param  hexArray 인코딩된 문자열을 나눈 16진수 배열
   * @param  encoding 디코드할 인코딩 형식
   * @return string decode된 문자열
   */
  private getDecodingText(hexArray: string[], encoding: string): string {
    let text = '';
    try {
      if(hexArray && hexArray.length > 0) {
        switch (encoding) {
          case EncodingList.ASCII:
          case EncodingList.UNICODE:
            for(let i = 0; i < hexArray.length; i++) {
              const hex = hexArray[i];
              const code = parseInt(hex, 16);
              text += String.fromCodePoint(code);
            }
          break;
          default:
            const numberArray = hexArray.map(function(item) {
              return parseInt(item, 16);
            });
          	const bytes = new Uint8Array(numberArray);
          	text = new TextDecoder(encoding, {}).decode(bytes);
          break;
        }
      }
    } catch (e) {
      return '';
    }
    return text;
  }

  /**
   * 각자의 인코딩 형식의 맞게 16진수를 나누어서 배열로 반환
   * @param  hexString 16진수의 문자열
   * @param  encoding  변환할 인코딩 타입
   * @return string[]: 나누어진 16진수 배열 | null: 반환 실패 또는 hexString이 Null 일시
   */
  private getHexArrayInText(hexString: string, encoding: string): string[] | null {
    try {
      let arr: string[] | null = null;;

      if (StringUtil.isEmpty(hexString)) {
        return null;
      }

      switch (encoding) {
        case EncodingList.ASCII:
          hexString = hexString!.replace(/[^0-9A-Fa-f]/g, "");
          arr = hexString!.match(/[0-9A-Fa-f]{2}/g);
          break;
        default:
          const mathedString = hexString!.match(/[0-9A-Fa-f]*/g);
          if(mathedString) {
            arr = mathedString.filter(function(el) {
              return el != "";
            });
          }
          break;
      }

      return arr;
    } catch (e) {
      return null;
    }
  }
}
