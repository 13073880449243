/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-04-28
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2021-04-28.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Beautymon2DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
   protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('#order > div > div.base-table > table > tbody > tr > td:nth-child(2) > div > a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('#order > div > div.base-table > table > tbody > tr > td:nth-child(2) > div > a')[i] as HTMLAnchorElement;
      const qty = (this.getSelectorAll([
        '#order > div > div.base-table > table > tbody > tr > td:nth-child(3) > div',
      ])[i] as HTMLDivElement).textContent!.replace(/\D/g, '').trim();
      const price = (this.getSelectorAll([
        '#order > div > div.base-table > table > tbody > tr > td:nth-child(4) > div'
      ])[i] as HTMLElement).textContent!.replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
 protected getWebPayConvDataFromBasketPage(): {} {
  const parsedProducts: Array<{}> = [];
  const productAmount = document.querySelectorAll('form[name="forms"]').length;
  let totalPrice = 0;
  let totalQty = 0;

  for (let i = 0; i < productAmount; i++) {
    const rawPrice = (this.getSelectorAll([
      '#cart > div > div.base-table > table > tbody > tr.nobd > td:nth-child(8) > div > span'
    ])[i] as HTMLSpanElement).textContent!.trim();
    const rawQty = (this.getSelectorAll([
      'div.table-cart input[name="orgamount"]',
      'div.table-wrap input[name="orgamount"]',
      'div#cart div.base-table table input[name="orgamount"]',
      'div#cartWrap table input[name="orgamount"]',
      'div.orderListArea table input[name="orgamount"]',
      'div.cart-list input[name="orgamount"]'
    ])[i] as HTMLInputElement).value;

    const price = NumberUtil.parseNumber(rawPrice);
    const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

    totalPrice += price;
    totalQty += qty;

    parsedProducts.push({
      'productCode': (this.getSelectorAll([
        'div.table-cart input[name="branduid"]',
        'div.table-wrap input[name="branduid"]',
        'div#cart div.base-table table input[name="branduid"]',
        'div#cartWrap table input[name="branduid"]',
        'div.orderListArea table input[name="branduid"]',
        'div.cart-list input[name="branduid"]'
      ])[i] as HTMLInputElement).value,
      'productName': (this.getSelectorAll([
        '#cart > div > div.base-table > table > tbody > tr > td:nth-child(4) > div > a'
      ])[i] as HTMLAnchorElement).textContent!.trim(),
      'price': price / qty,
      'qty': qty
    });
  }

  return {
    'product': parsedProducts,
    'adverId': this.adverId,
    'totalPrice': totalPrice.toString(),
    'totalQty': totalQty.toString()
  };
}
}
