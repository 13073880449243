/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-02
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-02-02
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class CosmocosDataParser extends GodomallRentDataParser {
  
    /**
   * 주문 완료, 장바구니 페이지에서 배송비가 포함된 주문금액을 파싱하여 리턴.
   * <b>NOTE: </b>필요한 element를 참조하지 못하면 0을 리턴.
   * @return {number} 배송비 포함 주문금액
   */
  protected getParsedTotalPrice(): number {
    const parsedTotalPrice = (): number => {
      /* 주문완료 페이지 */
      if (this.isOrderPage()) {
        const totalPrice: string = (document.querySelectorAll('.order_payment_sum')[1] as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 장바구니 페이지 */
      else if (this.isBasketPage()) {
        const totalPrice: string = (document.querySelector('#totalSettlePrice') as Element)!.textContent!.trim();
        return NumberUtil.parseNumber(StringUtil.replace(totalPrice, StringUtil.currencyRegex, StringUtil.EMPTY));
      }
      /* 다른 페이지는 0원으로 처리 */
      else {
        return 0;
      }
    };

    try {
      return parsedTotalPrice();
    } catch (e) {
      return 0;
    }
  }

  /**
   * 상품 상세페이지에서 금액정보를 파싱후 리턴
   * @param formElement 상품정보가 담겨있는 formElement
   * @protected
   * @return {price:number, dcPrice: number}
   */
  // TODO 리팩토링 할 필요성이 있음
  protected getProductPriceObj(formElement: HTMLFormElement): {} {
    try {
        let price = NumberUtil.parseNumber(formElement!['set_goods_price'].value);
        const dcPer = NumberUtil.parseNumber(formElement['goodsDiscount'].value);
        let dcPrice = dcPer > 0 ? price * (1 - (dcPer / 100)) : 0;

        if(price < dcPrice){
            let tmpPrice = dcPrice;
            dcPrice = price;
            price = tmpPrice;
        }

      return {'price': price, 'dcPrice': dcPrice}
    } catch (e) {
      return {'price': formElement!['set_goods_price'].value, 'dcPrice': 0}
    }
  }
}