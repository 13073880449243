/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-15
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-07-15.
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class LabohDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getImageSrc(): string {
    if (!this.isMobile(this.commandOptions)) {
      // iframe 등 외부 페이지에서 스크립트 기능 호출 시 document의 이미지 src를 참조하기 위해 selector 변수로 분리
      const currDocument = window.parent.document === document
        ? document
        : window.parent.document;
      if (currDocument.querySelector('.prd_detail .prdDetailScroll .imgArea img')) {
        return (currDocument.querySelector('.prd_detail .prdDetailScroll .imgArea img') as HTMLImageElement).src;
      }

      return super.getImageSrc();
    }

    return super.getImageSrc();
  }
}
