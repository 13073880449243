/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Tracker} from '../tracker/Tracker';

/**
 * create on 2019-09-02.
 * <p> 배포용 Agent 스크립트 </p>
 * <p> {@link Tracker}관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
const today: Date = new Date();
const timeKey: string = `${today.getFullYear()}${today.getMonth() + 1}${today.getDate()}`;
window['enp_agent_' + timeKey] = window['enp_agent_' + timeKey] || Tracker.getInstance();

/**
 * @param {boolean} [isCloud = false] - 클라우드 사용 여부
 * @param {Function} callback - 콜백 함수
 */
(window['ENP_INVOKE'] = (callback: Function, isCloud = false) => {
  /* html에 스크립트 리소스가 2개 이상 로드되었을 때 해당하는 스크립트 태그를 제거. */
  const enpScriptElems = document.querySelectorAll('script[src*=enp_tracker]');
  if (enpScriptElems.length > 1) {
    const childElem = enpScriptElems[0];
    if (childElem.parentNode) {
      childElem.parentNode.removeChild(childElem);
    }
  }

  if(window['enp'] && window['enp'].q) {
    while (window['enp'].q.length > 0) {
      /* dequeue 된 요소 */
      const dequeuedItem = window['enp'].q.shift();
      /* 현재 요소의 커맨드가 컨버전인지 여부 */
      const isConversion: boolean = !!dequeuedItem[1] && dequeuedItem[1] === 'conversion';

      /* 클라우드가 아닌 경우 또는 클라우드일 때는 컨저번인 경우만 명령어 실행 */
      if ((isCloud && isConversion) || !isCloud) {
        window['enp_agent_' + timeKey].executeCommand(dequeuedItem);
      }
    }
  }

  if (callback) {
    callback();
  }
})
(() => {
  // agent = undefined;
  /*
    클라이언트 코드에서
    window.ENP_INVOKE()로 큐를 바로 처리할 수 있게 agent를 초기화하지 않음.
   */
  // agent = undefined;
}, false);
/*
client code 예시
이미 리소스를 호출했으면 ENP_INVOKE()를 호출하여 큐를 처리함


(function (a, g, e, n, t) {
  a.enp = a.enp || function () {
    (a.enp.q = a.enp.q || []).push(arguments)
  };
  n = g.createElement(e);
  n.defer = !0;
  n.src = "https://cdn.megadata.co.kr/dist/prod/enp_tracker_makeshop_d4.min.js";
  if (g.querySelectorAll("script[src*=enp_tracker]").length > 0 && a.ENP_INVOKE) {
    a.ENP_INVOKE();
  } else {
    t = g.getElementsByTagName(e)[0];
    t.parentNode.insertBefore(n, t)
  }
})(window, document, "script");

 */
