/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-14
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-03-14
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Cdw6311DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromBasketPage(): {} {
    const superData = super.getPayConvDataFromBasketPage();
    if (this.isMobile(this.commandOptions)) {      
      superData['totalPrice'] = document.querySelector('table tr:nth-child(3) td')!.textContent!.replace(/\D/g, '');  
    }else{
      superData['totalPrice'] = document.querySelector('li.total span')!.textContent!.replace(/\D/g, '');  
    }
    return superData;
  }
}