import { JsonObject } from "../../../lib/json/JsonObject";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-05
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-05.
 * <p> Kj675572 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Kj675572DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

    protected getImageUrl(): string {
        if (document.querySelector('#productDetail > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > #zoom_image > #lens_img')) {
            // PC WEB
            return (document.querySelector('#productDetail > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > #zoom_image > #lens_img') as HTMLImageElement).src;
        } else {
            // Mobile
            return (document.querySelector('#detail-item > div.info-wrap > div.items > img') as HTMLImageElement).src;
        }
    }

    protected getProductName(): string {
        try {
            if (window['product_name']) {
                const productName = window['product_name'];
                return productName.trim();
            } else {
                return window.location.pathname.indexOf('/m/') === 0
                    ? document.querySelector('#detail-item > div.info-wrap > h2')!.textContent!.trim()
                    : document.querySelector('#form1 div.info > h2')!.textContent!.trim();
            }
        } catch (e) {
            return '';
        }
    }
}
