import { NumberUtil } from "../../../lib/common/NumberUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-12-14
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-12-14.
 * <p> kottiuomo 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class KottiuomoDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const basketProduct = document.querySelectorAll('div.cart-ct > table > tbody tr.nobd')[i];

      const rawQty = (basketProduct.querySelector('td:nth-child(4) > div.tb-center > div.amount > input[name=amount]') as HTMLInputElement).value;
      const rawPrice = basketProduct.querySelector('td:nth-child(6) > div.tb-right > span')!.textContent!.trim();

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': new QueryStringer((basketProduct.querySelector('td:nth-child(3) > div.tb-left > a') as HTMLAnchorElement).search.substr(1)).getParam('branduid'),
        'productName': basketProduct.querySelector('td:nth-child(3) > div.tb-left > a')!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const orderedProducts = document.querySelectorAll('div.order-list > table > tbody tr.nobd');

    const parsedProduct: Array<{}> = [];

    let totalQty = 0;

    for (let i = 0; i < orderedProducts.length; i++) {
      const productAnchorElem = orderedProducts[i].querySelector('td:nth-child(2) > div.tb-left > a') as HTMLAnchorElement;

      const price = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(4) > div.tb-right')!.textContent!.trim());
      const qty = NumberUtil.parseNumber(orderedProducts[i].querySelector('td:nth-child(3) > div.tb-right')!.textContent!.trim());

      parsedProduct.push({
        'productCode': new QueryStringer(productAnchorElem.search.substr(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });

      totalQty += qty;
    }

    jsonObj.addData('productCnt', orderedProducts.length);
    jsonObj.addData('totalQty', totalQty);

    jsonObj.replaceData('product', parsedProduct);

    return jsonObj;
  }
}
