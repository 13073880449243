/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-02
 */

import { MakeshopD4DataParser } from "../MakeshopD4DataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
 * create on 2020-04-02.
 * <p> ilovej - http://ilovej.net/ </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class IlovejDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let superData: JsonObject = super.getShopCollectData();
    if(!(document.querySelector('[name=discount_type]')! as HTMLInputElement).value){
      superData.data['dcPrice'] = 0;
    }
    return superData;
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const price = NumberUtil.parseRationalNumber(StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
      ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
      : document.querySelector('form#form1')!['price']['value']);

    let dcPrice = 0;
    if ((document.querySelector('[name=discount_type]') as HTMLInputElement).value) {
      dcPrice = NumberUtil.parseRationalNumber(StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
        : document.querySelector('form#form1')!['disprice']['value']);
    }

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': price && dcPrice && price < dcPrice ? price : (dcPrice ? dcPrice : price),
      'qty': !!document.getElementById("MS_amount_basic_0")
        ? document.getElementById("MS_amount_basic_0")!['value']
        : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseNumber(document.querySelectorAll('.SMSbasketPrice b')[i].textContent!.trim());
    }

    return jsonObj;
  }
}
