/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-27
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2021-07-27
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class TbhglobalDataParser extends GodomallRentDataParser {
  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    const jsonObj: JsonObject = super.getWebShopCollectData(formElement);

    jsonObj.data['productName'] = window['ENP_VAR']['productName'];

    return jsonObj;
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    const jsonObj: JsonObject = super.getMobileShopCollectData(formElement);

    jsonObj.data['productName'] = window['ENP_VAR']['productName'];

    return jsonObj;
  }

  protected getImageFromWebDetail(): HTMLImageElement | undefined {
    try {
      return this.getSelector(['.item_photo_slide .slider_wrap.slider_goods_nav li.slick-active img']) as HTMLImageElement;
    } catch (e) {
      return undefined;
    }
  }
    
}