/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-30
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-11-30
 * <p> goldn - http://obelee.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class GoldnDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    return this.isMobile(this.commandOptions) ? this.getMobileShopCollectData() : super.getShopCollectData();
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productNamesList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.orderListArea td.product a')
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    for (let i = 0; i < productNamesList.length; i++) {
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('div.orderListArea td.quantity')[i].textContent!.trim());
      parsedProducts.push({
        'productCode': new QueryStringer(productNamesList[i].search.substring(1)).getParam('branduid'),
        'productName': productNamesList[i]!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('div.orderListArea td.total')[i].textContent!.trim()) / qty,
      });
      totalQty += qty;
    }

    jsonObj.replaceData('product', parsedProducts);
    jsonObj.replaceData('totalQty', totalQty);
    return jsonObj;
  }

  private getMobileShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(location.search.substring(1)).getParam('branduid'),
      'productName': this.getProductName(),
      'price': StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, ''),
      'productUrl': window.location.href,
      'dcPrice': StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, ''),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getImageUrl(): string {
    try {
      return this.isMobile(this.commandOptions)
          ? (document.querySelector('#prdDetailImg img') as HTMLImageElement).src
          : super.getImageUrl();
    } catch (e) {
      return super.getImageUrl();
    }
  }

  /**
   * MakeShop은 서브 카테고리가 없는 경우 mcode가 000으로 자동 매핑 됨 이에 관롼 메소드
   */
  protected getCategoryCode(): string[] {
    const mcode: string = new QueryStringer(location.search.substring(1)).getParam('mcode');
    const xcode: string = new QueryStringer(location.search.substring(1)).getParam('xcode');
    let mcodeValue = "";
    let xcodeValue = "";

    if (mcode != null) {
      if (mcode === '000') {
        mcodeValue = xcode;
      } else {
        xcodeValue = xcode;
        mcodeValue = mcode;
      }
    } else {
      mcodeValue = xcode;
    }

    return [mcodeValue, xcodeValue];
  }
}