/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-29
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";

import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2023-06-29
 * <p> djh1658 - https://m.mall.castelbajac.com </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Castelbajac1DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    if (this.isMobile(this.commandOptions)) {

      const parsedProducts: Array<{}> = [];
      const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[name="naver-common-inflow-script-order-item"]');
      const ordCode: string = this.getConvJsonData(inputList, 0)['ordno'];
       let totalQty = 0;
      let totalPrice = 0;
        for (let i = 0; i < inputList.length; i++) {
        const convInfo: {} = this.getConvJsonData(inputList, i);
        const qty: number = convInfo['ea'];
        const rawPrice: number = convInfo['price'];
        const price: number = rawPrice / qty;
  
        parsedProducts.push({
          'productCode': convInfo['goodsno'],
          'productName': convInfo['goodsnm'],
          'qty': qty.toString(),
          'price': price.toString()
        });
  
        totalQty += qty;
        totalPrice += rawPrice;
      }
      if(totalPrice<30000){
        totalPrice+=2500;
      }

      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': ordCode,
        'totalPrice': totalPrice.toString(),
        'totalQty': totalQty.toString()
      };
  
      return new JsonObject(parsedData);
    }
    return jsonObj;
  }
}