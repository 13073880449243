/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-09
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
import { Value } from "../../../lib/value/Value";

/**
 * create on 2020-03-09.
 * <p> Roompacker 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class RoompackerDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    try{
      let jsonData: JsonObject = super.getShopCollectData();
      if(this.isMobile(this.commandOptions) && !document.querySelector('#discount_type')!['value']){
        jsonData.data['imageUrl'] = (document.querySelector(".swiper-wrapper>.swiper-slide.swiper-slide-active>img") as HTMLImageElement).src;
      }
      else{
        jsonData.data['imageUrl'] = (document.querySelector(".swiper-slide.swiper-slide-duplicate.swiper-slide-duplicate-active:first-child>img") as HTMLImageElement).src;
      }
      return jsonData;
    }catch{
      return super.getShopCollectData();
    }
  }

  protected getConversionProductDataAuto(convPosition: string = ''): {} {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalQty: number = 0;
    let totalPrice: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'] ? parsedProducts[i]['productCode'] : parsedProducts[i]['productcode'],
        'productName': parsedProducts[i]['productName'] ? parsedProducts[i]['productName'] : parsedProducts[i]['productname'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseInteger(products[i]['price']);
      totalQty += NumberUtil.parseInteger(products[i]['qty']);
    }
   if(this.isMobile(this.commandOptions)){
    totalPrice =  document.querySelector('li.total > span.num > font') ? document.querySelector('li.total > span.num > font')!.textContent!.replace(/\D/g,'')  : targetObj['totalPrice'] ? targetObj['totalPrice'] : totalPrice;
    }else{
      totalPrice =  document.querySelector('#totalPriceArea > table.foot > tfoot > tr > td') ? document.querySelector('#totalPriceArea > table.foot > tfoot > tr > td')!.textContent!.replace(/\D/g,'')  : targetObj['totalPrice'] ? targetObj['totalPrice'] : totalPrice;
    }
      
    if(convPosition==='order') {
      return {
        'product': products,
        'adverId': this.adverId,
        'ordCode': targetObj['ordCode'],
        'totalPrice': totalPrice,
        'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
      };
    } else {
      return {
        'product': products,
        'adverId': this.adverId,
        'totalPrice': totalPrice,
        'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
      };
    }

  }
}