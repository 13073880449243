/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-06
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-06
 * <p> Dopamines - https://www.realkimchi.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class DopaminesDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const orderInfo: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    let totalQty = 0;

    for (let i = 0; i < orderInfo['product'].length; i++) {
      totalQty += NumberUtil.parseNumber(orderInfo['product'][i]['qty'], NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': orderInfo['product'],
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': orderInfo['totalPrice'],
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];
    let totalPrice = 0;

    for (let i = 0; i < productList.length; i++) {
      const price = NumberUtil.parseRationalNumber(document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i].textContent!.trim().split(':')[1].slice(0, -1));
      productList[i]['price'] = price;
      totalPrice += price;
    }

    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('span.txt-price')!.textContent!.trim());
    return jsonObj;
  }

  protected getMobileConversionData(): JsonObject {
    const jsonObj = super.getMobileConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div#sms_ordercom_name')[i].textContent!.trim();
    }

    return jsonObj;
  }
}