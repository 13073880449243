/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">mgpark</a>
 * @since 2020-08-27.
 */
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-08-27.
 * <p> Suunto(순토) PC 접속시 imageUrl 파싱문제 해결 </p>
 * <p> {@link GodomallSelfDataParser } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh1
 */
export class SuuntoDataParser extends GodomallSelfDataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getImageUrl(): string {
    if (this.isMobile(this.commandOptions)) {
      if (document.querySelector('div.thumbnail-img img, img#imgComp')) {
        return (document.querySelector('div.thumbnail-img img, img#imgComp') as HTMLImageElement).src;
      } else if (document.querySelector('div.goods_view ul.slider-wrap li:first-child>img')) {
        return (document.querySelector('div.goods_view ul.slider-wrap li:first-child>img') as HTMLImageElement).src;
      }
    } else {
      if (document.querySelector('div.goods-img img#objImg')) {
        return (document.querySelector('div.goods-img img#objImg') as HTMLImageElement).src;
      } else if (document.querySelector('div.thumbnail-img img')) {
        return (document.querySelector('div.thumbnail-img img') as HTMLImageElement).src;
      } else if (document.querySelector('#mainImage>img')) {
        return (document.querySelector('#mainImage>img') as HTMLImageElement).src;
      }
    }

    return '';
  }
}
