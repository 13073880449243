/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-15
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GlobalVariables } from "../../../types/GlobalVariables";

export class IcosvanitableDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {} = {};

    this.parseCategory(window['iCategoryNo']);
    const priceInfo: {} = this.getProductPrice();
    parsedData = {
    'adverId': this.adverId,
    'productCode': window['iProductNo'],
    'productName': window['product_name'],
    'price': priceInfo['price'],
    'productUrl': window.location.href,
    'dcPrice': priceInfo['dcPrice'],
    'soldOut': !!window['EC_FRONT_JS_CONFIG_SHOP']
            ? window['EC_FRONT_JS_CONFIG_SHOP']['bSoldout']
                ? 'Y'
                : 'N'
            : 'N',
    'imageUrl': this.getImageSrc(),
    'topCategory': this.category['topCategory']
        ? this.category['topCategory']
        : GlobalVariables.unknownCategory,
    'firstSubCategory': this.category['firstSubCategory'],
    'secondSubCategory': this.category['secondSubCategory'],
    'thirdSubCategory': this.category['thirdSubCategory']
    };
    
    return new JsonObject(parsedData);
  }
}