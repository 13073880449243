/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-07-28
 */

import {JsonObject} from "../../../lib/json/JsonObject";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";


/**
 * create on 2020-07-28.
 * <p> Gjhmc 광고주 데이터 파싱 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class GjhmcDataParser extends MakeshopD4DataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for(let i = 0; i < productList.length; i ++) {
        productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i]!.textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }
}
