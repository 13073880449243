import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-15
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-15.
 * <p> pretty 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class PrettyDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      if (window['product_name'] && StringUtil.isNotEmpty(window['product_name'])) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('div.SMS_buy_quick_r > div.SMS_buy_quick_replace_p > div.shopdetailInfoValue > div.fix_name_wrap')!.textContent!.trim()
            : document.querySelector('div.det_header_wrapper > div.det_wrap > div.spac_content > div.goods_name')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getMobilePayConvDataFromBasketPage();
    const parsedProducts: Array<{}> = jsonObj['product'];

    for (let i = 0; i < parsedProducts.length; i++) {
      parsedProducts[i]['price'] = document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i]!.textContent!.trim().replace(/\D/g, '');
    }

    return jsonObj;
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.detail_thumb > a#zoom_image > img.detail_image')) {
      // PC WEB - 단일 이미지
      return (document.querySelector('div.detail_thumb > a#zoom_image > img.detail_image') as HTMLImageElement).src;
    } else if (document.querySelector('div.detail_thumb > div.slick-slider > div.slick-list.draggable > div.slick-track')) {
      // PC WEB - 슬라이드 이미지
      return (document.querySelector('div.detail_thumb > div.slick-slider > div.slick-list.draggable > div.slick-track > img:nth-child(1)') as HTMLImageElement).src
    } else {
      // MO
      return (document.querySelector('div.SMS_ShopDetail_layout > div.SMS_ShopDetail_layout_left > div.shopdetailInfoTop > figure > img') as HTMLImageElement).src;
    }
  }
}
