/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee1</a>
 * @since 2023-05-08
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2023-05-08
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class YuhanmallDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = document.querySelector('#totalSettlePriceAdd')!.textContent!.replace(/\D/g, '');
    
    return jsonObj;
  }
}
