/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-02-26
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-02-26.
 * <p> monobarbie 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MonobarbieDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    return window.location.pathname === '/m/order_complete.html'
        ? this.getMobileConversionData()
        : this.getWebConversionData();
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart>ul>li').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    const productInfoElems: NodeListOf<Element> = document.querySelectorAll('div.table-cart>ul>li>dd');
    for (let i = 0; i < productInfoLength; i++) {
      const rawPrice = (productInfoElems[i].querySelector('div.tb-price') as HTMLDivElement).textContent!.split('/')[0].trim().slice(0, -1);
      const rawQty = (productInfoElems[i].querySelector('div.tb-price') as HTMLDivElement).textContent!.split('/')[1].trim().slice(0, -1);
      const price = NumberUtil.parseNumber(rawPrice) / NumberUtil.parseInteger(rawQty);

      parsedProducts.push({
        'productCode': new QueryStringer((productInfoElems[i].querySelector('div.tb-name>a') as HTMLAnchorElement).search.substring(1)).getParam('branduid'),
        'productName': (productInfoElems[i].querySelector('div.tb-name>a') as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': rawQty
      });

      totalQty += NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': (document.querySelector('div.admin-msg>span') as HTMLSpanElement).textContent,
      'totalPrice': document.querySelector('div.totalPrice>strong')!.textContent!.trim(),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div.title a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div.title a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div p.num')[i] as HTMLParagraphElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': (document.querySelector('div#contents>main#order-complete>p.complete-price>em') as HTMLEmbedElement).textContent,
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const price = (document.querySelectorAll('div.table-cart tr td div.tb-price span')[i] as HTMLSpanElement).textContent!.trim();
      const qty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      totalPrice += NumberUtil.parseNumber(price);
      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.nameBox > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const price = (document.querySelectorAll('form[name="forms"]>figure figcaption>p:nth-child(1)')[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const qty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      totalPrice += NumberUtil.parseNumber(price);
      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-child')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 상품 상세 페이지 이미지 url 파싱
   * @return {{}} 파싱 결과
   */
  protected getImageUrl(): string {
    if (document.querySelector('img.detail_image')) {
      return (document.querySelector('img.detail_image') as HTMLImageElement).src;
    } else if (document.querySelector('div#productDetail ul.swiper-wrapper>li>img')) { // 모노바비 웹 selector
      return (document.querySelector('div#productDetail ul.swiper-wrapper>li>img') as HTMLImageElement).src;
    } else if (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child')) { //모노바비 모바일 selector 1
      return (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
    } else if (document.querySelector('div.detailThumb>img')) { //모노바비 모바일 selector 2
      return (document.querySelector('div.detailThumb>img') as HTMLImageElement).src;
    } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
      return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
    } else if (document.querySelector('main.shopdetail div.swiper-wrapper img')) {
      return (document.querySelector('main.shopdetail div.swiper-wrapper img') as HTMLImageElement).src;
    } else if (document.querySelector('div.product-detail div.thumbnail img')) {
      return (document.querySelector('div.product-detail div.thumbnail img') as HTMLImageElement).src;
    } else if (document.querySelector("#productDetail div div.detailContArea div.thumb-wrap img")) {
      return (document.querySelector("#productDetail div div.detailContArea div.thumb-wrap img") as HTMLImageElement).src;
    } else {
      return '';
    }
  }
}
