/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-02-15
 */

import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2023-02-15
 * <p> </p>
 * <p> {@GodomallSelfDataParser } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class StyleenterDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromProductPage(): {} {
    const jsonObj = super.getWebPayConvDataFromProductPage();
    const productList = jsonObj['product'];
    jsonObj['totalPrice'] = productList[0]['dcPrice'] > 0 ? productList[0]['dcPrice']  * productList[0]['qty']: productList[0]['price'] * productList[0]['qty'];
    return jsonObj;
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj = super.getMobileShopCollectData();
    jsonObj.replaceData('price', document.querySelectorAll('.content-content.red')[1]!.textContent!.replace(/\D/g,''));
    jsonObj.replaceData('dcPrice', document.querySelectorAll('.content-content.red')[0]!.textContent!.replace(/\D/g,''));
    return jsonObj;
  }

   protected getMobilePayConvDataFromProductPage(): {} {
    const getQty = (): number => {
      try {
        return NumberUtil.parseInteger((this.getSelector(['div.buy-info-title input']) as HTMLInputElement).value);
      } catch (e) {
        return 1;
      }
    }
    const parsedProducts: Array<{}> = [];
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);
    const qty = getQty() ? getQty() : 1;
    const price= NumberUtil.parseInteger(document.querySelectorAll('.content-content.red')[1]!.textContent!.replace(/\D/g,''));
    const dcPrice= NumberUtil.parseInteger(document.querySelectorAll('.content-content.red')[0]!.textContent!.replace(/\D/g,''));
    parsedProducts.push({
      'productCode': this.getProductCode(),
      'productName': this.getProductName(),
      'price': price,
      'dcPrice': dcPrice,
      'qty': qty
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': dcPrice > 0 ? dcPrice * qty :price * qty,
      'totalQty': qty
    };
  }


  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    let  cartData = document.querySelectorAll('form[name="frmCart"] table tbody tr td table tr td input.line');
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < cartData.length; i++) {
      const price = NumberUtil.parseInteger(document.querySelectorAll('form[name="frmCart"] table tbody tr td:nth-child(4)')![i].textContent!.replace(/\D/g,''));
      const qty = NumberUtil.parseInteger(cartData[i]['value']);

      totalQty += qty;
      totalPrice += (price * qty);

      parsedProducts.push({
        'productCode':new QueryStringer(document.querySelectorAll('form[name="frmCart"] table tbody tr td a')[2*i].getAttribute('href')!.split('?')[1]).getParam('goodsNo'),
        'productName':document.querySelectorAll('form[name="frmCart"] table tbody tr td div')[i*2].textContent,
        'qty': qty,
        'price': price
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('table tbody tr td.red')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty
    };
  }

}
