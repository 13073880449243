/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-27
 */
import {ValidationRuleImpl} from "../ValidationRuleImpl";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2020-04-27.
 * <p> 품절여부 체크 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class SoldOutValidRule extends ValidationRuleImpl {
  private usableRegex = /[YN]/;

  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    const rawValue: string = (property as string).toUpperCase();
    return StringUtil.matchExactly(rawValue, this.usableRegex) ? rawValue : 'N' ;
  }
}
