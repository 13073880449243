/*
 * @author <a href="yjlee1:yjlee1@enliple.com">yjlee</a>
 * @since 2023-07-06
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2023-07-06.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class FulllayerDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
    let superData = super.getProductPrice();
    try {
      if (this.isMobile(this.commandOptions)) {
        superData['price'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement)['content']);
        superData['dcPrice'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement)['content']);
      }
      return superData;
    } catch (e) {
      return superData;
    }
  }
}
