/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-06-23
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2022-06-23
 * <p> grip_officia ( SPA - GTM ) 삽입 광고주 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */

export abstract class GripOfficiaDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  private readonly selectList = [{
    path: 'content',
    event: [{
      btnSelector: '.live-and-follow .MuiBox-root button.MuiButton-root',
      type: 'cart'
    }, {
      btnSelector: '.live-toolbox > div:last-child > div.MuiBox-root:nth-of-type(2) button.MuiButtonBase-root.MuiIconButton-root',
      type: 'wish'
    }]
  }, {
    path: 'gripper',
    event: [{
      btnSelector: '.MuiBox-root .MuiButton-root:first-child',
      type: 'cart'
    }]
  }, {
    path: '/w/event/500deal'
  }];

  private breakClick: boolean = false;
  //중복 방지를 위해 이벤트 추가한 btnSelector 저장
  private duplicateEvent: Array<string> = [];
  //select count
  private searchCount: number = 0;

  private setProductCode() {
    window['ENP_VAR'] = {
      collect: {
        productCode: 1
      }
    };
  }
  /** 
   * script 호출 시 URL 변경 감지
   */
  private createPreferences(): void {
    if (!this.breakClick) {
      this.breakClick = true;
      let detectUrl = location.pathname;
      document.body.addEventListener('click', (e) => {
        requestAnimationFrame(() => {
          if (detectUrl !== location.pathname) {
            this.commonTracker()
          }
          detectUrl = location.pathname;
        });
      });
    }
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData: JsonObject = super.getCommonTraceData();
    /**
     * event 추가 및 변수 초기화
     */
    this.createPreferences();
    this.callCollect();
    this.duplicateEvent = [];
    this.searchCount = 0;
    return jsonData;
  }

  protected getConversionData(): JsonObject {
    let superData: JsonObject = super.getConversionData();
    try{
      if(superData.getDataByName('ordCode').toString() === '1'){
        superData.removeData('ordCode');
      }
      return superData;
    }catch{
      return superData;
    }
  }

  protected callCollect(): void {
    for (let i = 0, len = this.selectList.length; i < len; i++) {
      if (new RegExp(this.selectList[i].path).test(location.pathname)) {
        this.setProductCode();
        this.collectTracker();
        this.addEventNodes(this.selectList[i].event);
        break;
      }
    }
  }

  /**
   * node 추가 감지
   * (mutaion observer로 감지가 불가능하여 setTimeout형식으로 개발)
   */
  private addEventNodes(eventList: Array<Object> | undefined): void {
    if(!eventList) return;
    let selector: string = '';  //document.querySelector로 선택할 요소
    let eventType: string = ''; //cart, wish type
    let eventAdd: boolean = false;  //감지한 요소가 있을 경우 true
    for (let i = 0, len = eventList.length; i < len; i++) {
      selector = eventList[i]['btnSelector'];
      eventType = eventList[i]['type'];
      let selectNode = document.querySelector(selector);
      if (selectNode) {
        eventAdd = true;
        this.cartTracker(selector, eventType);
      } else if (i === len - 1 && !eventAdd) {
        //감지된 요소가 없을 경우 재탐색 시도
        //여러번 시도시에도 없을 경우 중지
        if (this.searchCount++ === 5) {
          break;
        }
        setTimeout(() => {
          this.addEventNodes(eventList);
        }, 1000);
      }
    }
  }

  /**
   * url 변경시 common tracker 호출
   */
  private commonTracker(): void {
    const tracker: Tracker = Tracker.getInstance(false);
    tracker.executeCommand([
      'send',
      'common',
      this.adverId,
      { 'device': 'B' }
    ]);
  }

  private collectTracker(): void {
    const tracker: Tracker = Tracker.getInstance(false);
    tracker.executeCommand([
      'create',
      'collect',
      this.adverId,
      { 'device': 'B' }
    ]);
  }

  private cartTracker(selector: string, eventType: string): void {
    if (!this.duplicateEvent.some((str) => str === selector)) {
      this.duplicateEvent.push(selector);
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'create',
        eventType,
        this.adverId,
        { 'device': 'B', btnSelector: selector }
      ]);
    }
  }
}