/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-09-05
 */

import { SelfHostedDataParser } from "../SelfHostedDataParser";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-09-05
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class EccokoreaDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    let superData: JsonObject = super.getShopCollectData();
    superData.data['imageUrl'] = ((document.querySelectorAll('div.product-image')[0]) as HTMLElement)['dataset']['bg'];
    //상품 상세 호출 시 cart 이벤트 추가 생성
    window['enp']("create", "cart", "eccokorea", { device: "B", btnSelector: ".btn.sticky-add-to-cart" });
    return superData;
  }
}
