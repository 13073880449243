/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-08-01
 */

import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
 * create on 2022-08-01
 * <p> </p>
 * <p> {@GodomallSelfDataParser } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Blanzuu7DataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj = super.getMobileShopCollectData();
    const priceObject = this.getMobilePriceFromDetailPage();

    jsonObj.replaceData('price', priceObject.price);
    jsonObj.replaceData('dcPrice', priceObject.dcPrice);

    return jsonObj;
  }

  protected getMobilePayConvDataFromProductPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromProductPage();
    const priceObject = this.getMobilePriceFromDetailPage();

    const productList = jsonObj['product'];
    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = priceObject.price;
      productList[i]['dcPrice'] = priceObject.dcPrice;
    }

    jsonObj['totalPrice'] = priceObject.price > priceObject.dcPrice ? priceObject.dcPrice : priceObject.price;

    return jsonObj;
  }

  private getMobilePriceFromDetailPage(): { price: number, dcPrice: number } {
    const priceElement = document.querySelector('div.content-item:nth-child(2) div.content-content.red')!.textContent!.replace(/\D/g,'');
    const dcPriceElement = document.querySelector('div.content-item:nth-child(1) div.content-content.red')!.textContent!.replace(/\D/g,'');

    return {
      price: NumberUtil.parseRationalNumber(priceElement),
      dcPrice: NumberUtil.parseRationalNumber(dcPriceElement)
    };
  }

  
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const cartDataArr: Array<{}> = window['nsGodo_CartAction']['data'];
    let totalPrice = 0;
    let totalQty = 0;

    const getProductName = (index: number): string => {
      try {
        const anchorElem: HTMLAnchorElement = document.querySelectorAll('div.goods-nm>a')[index] as HTMLAnchorElement;
        return anchorElem.textContent!.trim();
      } catch (e) {
        return '';
      }
    };

    const getProductCode = (index: number): string => {
      const anchorElem: HTMLAnchorElement = document.querySelectorAll('div.goods-list-img>a')[index] as HTMLAnchorElement;
      let queryString: string = anchorElem.search.substring(1);

      queryString = queryString.indexOf('&') > 0
          ? queryString.substring(1)
          : queryString;

      return new QueryStringer(queryString).getParam('goodsno');
    };

    for (let i = 0; i < cartDataArr.length; i++) {
      const price = cartDataArr[i]['price'];
      const qty = cartDataArr[i]['ea'];

      totalQty += qty;
      totalPrice += (price * qty);

      parsedProducts.push({
        'productCode': getProductCode(i),
        'productName': getProductName(i),
        'qty': qty,
        'price': price
      });
    }
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': totalQty
    };
  }
  
  protected getConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getConversionData();
    if(this.isMobile(this.commandOptions)){
    let totalPrice = document.querySelector('table:nth-child(2) tr:nth-child(4) td')!.textContent!.replace(/\D/g, '');
    jsonObj.replaceData('totalPrice', totalPrice);
  }
    return jsonObj;
  }
}
