/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-01-08
 */

import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {StringUtil} from "../../../lib/common/StringUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-01-08
 * <p> Nnutri </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class NnutriDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected isProductPage(): boolean {
    return StringUtil.matchExactly(window.location.pathname, /^.*\/goods\/goods_view.php$/);
  }

  protected isBasketPage(): boolean {
    return StringUtil.matchExactly(window.location.pathname, /^.*\/order\/cart.php$/);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAnchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div.info > a');
    let totalQty = 0;

    for (let i = 0; i < productAnchorList.length; i++) {
      const qty = NumberUtil.parseNumber(document.querySelectorAll('div.info div.itembody p:nth-child(2)')[i].textContent!.trim());

      totalQty += qty;

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorList[i].search.substring(1)).getParam('goodsNo'),
        'productName': productAnchorList[i].querySelector('p.name')!.textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('strong.prc')[i].textContent!.trim())
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('#totalSettlePrice')!.textContent!.trim()),
      'totalQty': totalQty
    };
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAnchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.cart_cont_list table em a');
    let totalQty = 0;

    for (let i = 0; i < productAnchorList.length; i++) {
      const qty = NumberUtil.parseNumber(document.querySelectorAll('.cart_cont_list table .order_goods_num strong')[i].textContent!.trim());
      totalQty += qty;

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorList[i].search.substring(1)).getParam('goodsNo'),
        'productName': productAnchorList[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.cart_cont_list table .price')[i].textContent!.trim())
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.price_total #totalSettlePrice')!.textContent!.trim()),
      'totalQty': totalQty
    };
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj = super.getMobileShopCollectData();
    const formElement = document.querySelector('.goods_view form');
    jsonObj['data']['price'] = NumberUtil.parseRationalNumber((formElement!.querySelector('input[name="set_goods_fixedPrice"]') as HTMLInputElement)!.value.trim());
    jsonObj['data']['dcPrice'] = NumberUtil.parseRationalNumber((formElement!.querySelector('input[name="set_coupon_dc_price"]') as HTMLInputElement)!.value.trim());
    return jsonObj;
  }

  protected getMobilePayConvDataFromProductPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromProductPage();
    const formElement = document.querySelector('.goods_view form');
    jsonObj['product'][0]['price'] = NumberUtil.parseRationalNumber((formElement!.querySelector('input[name="set_goods_fixedPrice"]') as HTMLInputElement)!.value.trim());
    jsonObj['product'][0]['dcPrice'] = NumberUtil.parseRationalNumber((formElement!.querySelector('input[name="set_coupon_dc_price"]') as HTMLInputElement)!.value.trim());
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('dd.total_price')!.textContent!.trim());
    return jsonObj;
  }

  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    const totalPriceElement = this.isMobile(this.commandOptions)
        ? document.querySelector('strong.c_red')
        : document.querySelector('div.order_table_type tr:nth-child(10) .order_payment_sum');
    jsonObj.replaceData('totalPrice', NumberUtil.parseRationalNumber(totalPriceElement!.textContent!.trim()));
    return jsonObj;
  }
}