/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-01-25
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2020-04-02.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Nak21DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      const price: string = document.querySelectorAll('div.table-cart tr td:nth-child(5) div.tb-right.tb-bold')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['price'] = price;
    }

    return jsonObj;
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('div.shop_name_in2 span.name')!.textContent!.trim()
            : document.querySelector('.infoArea .info .dname h3')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }
}
