/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-15.
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-06-15.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class BuriedaliveDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getPayConvDataFromBasketPage(): {} {
    let jsonObj = super.getPayConvDataFromBasketPage();
    if(!this.isMobile(this.commandOptions)){
    jsonObj['totalPrice'] = document.querySelector('#contents td:nth-child(5) strong:nth-child(2) span')!.textContent!.replace(/\D/g, '');
    }
    return jsonObj;
  }
}
