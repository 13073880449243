/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-22
 */
import {CategoryParser} from "../CategoryParser";
import {HostingType} from "../../types/GlobalEnums";
import {GeneralTree} from "../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../Category";
import {QueryStringer} from "../../lib/url/QueryStringer";
import {StringUtil} from "../../lib/common/StringUtil";
import {GTNode} from "../../lib/dataStructure/tree/general/GTNode";

/**
 * create on 2020-01-22.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MakeshopD4CategoryParser extends CategoryParser {
  constructor(hostingType: HostingType) {
    super(hostingType);
  }

  // todo 카테고리 파싱은 모바일은 다름!!!
  protected parseAndMakeTree(callback: (tree: GeneralTree<Category>) => void, hasExpired: boolean): void {
    if (hasExpired) {
      /* 로우 데이터 */
      const categories: Category[] = this.parseRawCategories();

      /* 각 카테고리를 노드로 변환 */
      const nodes: Array<GTNode<Category>> = this.createNodesFromCategories(categories);

      /* 노드를 이용해 트리를 생성 */
      const tree: GeneralTree<Category> = this.getTree(nodes);

      /* 외부에서 콜백으로 트리에 접근 */
      callback(tree);
    }
  }

  protected createCategoryHierarchyTree(rawCategory: any): GeneralTree<Category> {
    return new GeneralTree<Category>({code: '', value: '', parentCode: ''});
  }

  protected parseRawCategories(): Category[] {
    const topCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#all_cate_area li.a_sub_title a');
    const subCategories: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('div#all_cate_area li.a_sub_cate a');
    return this.getCategories(topCategories).concat(this.getCategories(subCategories));
  }

  protected getCategories(anchorList: NodeListOf<HTMLAnchorElement>): Category[] {
    const categories: Category[] = [];
    for (let i = 0; i < anchorList.length; i++) {
      try {
        categories.push(this.getCategory(anchorList[i]));
      } catch (e) {
        /* 카테고리가 아닌 elment는 건너 뛴다 */
        continue;
      }
    }

    return categories;
  }

  protected getCategory(anchor: HTMLAnchorElement): Category {
    const queryString = new QueryStringer(anchor.search.substring(1));
    let category: Category;

    const mcode: string = queryString.getParam('mcode');
    const xcode: string = queryString.getParam('xcode');

    if (StringUtil.isNotEmpty(mcode) && StringUtil.isNotEmpty(xcode)) {
      /* 메인, 서브 카테고리 있음 */
      category = {
        code: queryString.getParam('mcode'),
        parentCode: queryString.getParam('xcode'),
        value: anchor.textContent!.trim()
      };
    } else if (StringUtil.isEmpty(mcode) && StringUtil.isNotEmpty(xcode)) {
      /* 서브 카테고리만 있음 */
      category = {
        code: queryString.getParam('xcode'),
        parentCode: '',
        value: anchor.textContent!.trim()
      };
    } else {
      /* 카테고리가 아님 */
      throw new Error('Invalid Category');
    }

    return category;
  }

  /**
   * 로우 데이터를 이용해 각 카테고리에 해당하는 노드 생성
   * @param {Category[]} categories
   * @return {Array<GTNode<Category>>}
   */
  protected createNodesFromCategories(categories: Category[]): Array<GTNode<Category>> {
    const categoryNodes: Array<GTNode<Category>> = [];

    categories.forEach((category: Category) => {
      categoryNodes.push(new GTNode<Category>(category));
    });

    return categoryNodes;
  }


  /**
   * 각 부모와 자식 노드의 path를 연결.
   * @param {Array<GTNode<Category>>} nodes 생성된 노드 배열
   */
  protected linkParentAndChild(nodes: Array<GTNode<Category>>): void {
    nodes.forEach((node: GTNode<Category>) => {
      const parentCode: string = node.value.parentCode;
      const parentNode: GTNode<Category> = nodes.filter((rawCate: GTNode<Category>) => rawCate.value.code === parentCode)[0];
      if (parentNode) {
        parentNode.insertNext(node);
      }
    });
  }

  /**
   * 모든 조상 노드를 탐색하여 반환.
   * @param {Array<GTNode<Category>>} nodes 생성한 노드의 배열
   * @return {Array<GTNode<Category>>}  탐색된 조상 노드로 이루어진 배열
   */
  protected getAncestorNodes(nodes: Array<GTNode<Category>>): Array<GTNode<Category>> {
    const ancestorNodes: Array<GTNode<Category>> = [];
    this.linkParentAndChild(nodes);

    /* 모든 노드를 순회하여 부모 노드가 없는 노드들을 찾는다 */
    nodes.forEach((node: GTNode<Category>) => {
      let currNode: GTNode<Category> = node;
      while (currNode.parent) {
        currNode = currNode.parent;
      }

      /* 같은 조상 노드를 추가하지 않도록 체크 */
      if (ancestorNodes.filter((ancestor: GTNode<Category>) => ancestor.value.code === currNode.value.code).length === 0) {
        ancestorNodes.push(currNode);
      }
    });

    return ancestorNodes;
  }

  /**
   * 각 path가 연결된 노드들로 구성된 트리를 생성.
   * 트리의 루트 노드는 임의로 생성한 값을 갖는다. - <code>{code: 'ROOT', value: '', parentCode: ''}</code>
   * @param {Array<GTNode<Category>>} nodesWithPath path가 설정된 노드 배열
   * @return {GeneralTree<Category>}  트리
   */
  protected getTree(nodesWithPath: Array<GTNode<Category>>): GeneralTree<Category> {
    const tree: GeneralTree<Category> = new GeneralTree<Category>({
      code: 'ROOT',
      value: '',
      parentCode: ''
    });
    this.getAncestorNodes(nodesWithPath).forEach((rootNode: GTNode<Category>) => {
      tree.createChildByNode(rootNode);
    });

    return tree;
  }
}