/**
 * create on 2021-03-24.
 * <p> AdBlock 정보를 localStorage에서 조회 </p>
 *
 * @version 1.0
 * @author sjoh1
 */
import {ConfigStorage} from "../../adverConfigJsonMapper/ConfigStorage";
import {NotSupportedError} from "../../error/NotSupportedError";
import {AdBlockCtrl, AdBlockType} from "./AdBlockCtrl";
import {KeywordSessionType} from "../../types/GlobalEnums";
import {DateUtil} from "../../lib/common/DateUtil";
import {StringUtil} from "../../lib/common/StringUtil";
import {NumberUtil} from "../../lib/common/NumberUtil";
import {JsonObject} from "../../lib/json/JsonObject";

export class AdBlockStorage {

  /* 스토리지 key의 prefix */
  private readonly commonKey: string = 'ENP_ADB_COMMON';
  /* 스토리지 저장 기간 (일) */
  private readonly expiryDay: number = 1;
  /* 스토리지 */
  private readonly storage: Storage;

  constructor() {
    //ConfigStorage에 localStorage 지원여부 체크하는 메소드가 존재하여 사용함
    if (ConfigStorage.whetherLocalSorageSupported()) {
      this.storage = window.localStorage;
    } else {
      throw new NotSupportedError('localStorage');
    }
  }

  setAdBlockUsed(adBlockType: AdBlockType): void {
    switch (adBlockType) {
      case AdBlockType.ABP_USED:
        const expiry: Date = new Date(Date.now() + (1000 * 60 * 60 * 24 * this.expiryDay));
        //추가적으로 저장되어야하는 정보가 있을 경우 추가
        const adBlockSaveData: {} = {
          'expiry': DateUtil.getYYYYMMDDString(expiry)
        };
        this.storage.setItem(adBlockType, JSON.stringify(adBlockSaveData));
        break;
    }
  }

  /**
   * 추후에 Type으로 받아서 처리하도록 변경해도됨
   * @returns {boolean}
   */
  adBlockUseStorageCheck(): boolean{
    try{
    const storedData = JSON.parse(this.storage.getItem(AdBlockType.ABP_USED)!);
    //adBlock사용 유효기간이 만료된 경우 제거 후 false 반환
    if(this.hasExpired(storedData['expiry'])){
      this.storage.local.removeItem(AdBlockType.ABP_USED);
      return false;
    }else{
      return true;
    }
    }catch (e) { return false;}
  }

  /**
   * 입력한 time string을 현재 날짜와 비교하여 만료되었는지 확인.
   * 오류 발생시 <code>true</code> 반환
   * @param {string} expiredTimestring  - 확인할 키워드의 만료 time string
   * @return {boolean}
   * <p><code>true</code> - 만료됨</p><p><code>false</code> - 만료되지 않음.</p>
   */
  private hasExpired(expiredTimestring: string): boolean {
    try {
      const today: string = this.getYYYYMMDDString();
      const isInvalidExpiry: boolean = !(expiredTimestring && expiredTimestring.length === 8 && StringUtil.isPositiveIntegerFormat(expiredTimestring));
      return isInvalidExpiry || NumberUtil.stringToNumber(expiredTimestring!) < NumberUtil.stringToNumber(today);
    } catch (e) {
      return true;
    }
  }

  /**
   * 현재 날짜에 대한 <code>YYYYMMDD</code> 문자열을 반환.
   * @return {string}
   */
  private getYYYYMMDDString(): string {
    const currentDate: Date = new Date();
    const year: string = currentDate.getFullYear().toString();
    const month: string = NumberUtil.padZero(currentDate.getMonth() + 1, 2);
    const date: string = NumberUtil.padZero(currentDate.getDate(), 2);

    return year + month + date;
  }

}
