/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-06
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2020-03-06.
 * <p> dmall2020 광고주 파싱 </p>
 * <p> 다른 카페24 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Dmall2020DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductQty(): string {
    return (document.querySelector('input#quantity') as HTMLInputElement).value;
  }
}
