/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-07-08
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-07-13.
 * <p> Tripoles 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class TripolesDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }





    /**
     * 주문완료 웹 버전에서 정보 파싱
     * @return {JsonObject} 파싱된 결과
     */
    protected getWebConversionData(): JsonObject {
        const productInfoLength = document.querySelectorAll('div.order-table tr td tr.order-item-top td h5').length;
        const parsedProducts: Array<{}> = [];
        let totalQty = 0;

        for (let i = 0; i < productInfoLength; i++) {
            const productAnchorElem = document.querySelectorAll('div.order-table tr td>a')[i] as HTMLAnchorElement;
            const qty = (document.querySelectorAll('div.order-table tr td.order-detail tr.order-item-top td.opt-number')[i] as HTMLTableElement).textContent!.trim()!.replace(/\D/g, '');

            parsedProducts.push({
                'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
                'productName': document.querySelectorAll('div.order-table tr td tr.order-item-top td h5')[i].textContent!.trim(),
                'qty': qty,
                'price': (document.querySelectorAll('div.order-table tr td.order-detail tr.order-item-top td:nth-child(3)')[i] as HTMLTableElement).textContent!.trim()!.replace(/\D/g, ''),
            });

            totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
        }

        const parsedData: {} = {
            'product': parsedProducts,
            'adverId': this.adverId,
            'ordCode': document.querySelector('form[name="orderdetail"]')
                ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
                : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
            'totalPrice': document.querySelector('tr.total-price td')!.textContent!.trim()!.replace(/\D/g, ''),
            'totalQty': totalQty.toString()
        };

        return new JsonObject(parsedData);
    }


    /**
     * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
     * @return {{}} 파싱 결과
     */
    protected getMobilePayConvDataFromBasketPage(): {} {
        const parsedProducts: Array<{}> = [];
        const productAmount = document.querySelectorAll('form[name="forms"]').length;
        let totalPrice = 0;
        let totalQty = 0;

        for (let i = 0; i < productAmount; i++) {
            const priceEl = document.querySelectorAll('form[name="forms"]>figure>figcaption div.basket-info>div.pprice')[i];
            const priceTxt = priceEl.textContent!;
            const strike = priceEl.querySelector('strike')!.textContent!;
            let priceFinal = '';
            if(strike != null){
                priceFinal = priceTxt.trim().replace(strike, '').replace(/\D/g, '');
            }else{
                priceFinal = priceTxt.trim().replace(/\D/g, '');
            }

            const rawPrice = priceFinal;
            const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

            const price = NumberUtil.parseNumber(rawPrice);
            const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

            totalPrice += price;
            totalQty += qty;

            parsedProducts.push({
                'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
                'productName': (document.querySelectorAll('div.pname>a.inner-pname')[i] as HTMLAnchorElement).textContent!.trim(),
                'price': price / qty,
                'qty': qty
            });
        }

        return {
            'product': parsedProducts,
            'adverId': this.adverId,
            'totalPrice': totalPrice.toString(),
            'totalQty': totalQty.toString()
        };
    }



    /**
     * 장바구니 페이지 웹버전에서 전환 데이터 파싱
     * @return {{}} 파싱 결과
     */
    protected getWebPayConvDataFromBasketPage(): {} {
        const parsedProducts: Array<{}> = [];
        const productAmount = document.querySelectorAll('form[name="forms"]').length;
        let totalPrice = 0;
        let totalQty = 0;

        for (let i = 0; i < productAmount; i++) {
            const rawPrice = (document.querySelectorAll('div.cart-table tr td.cart-detail tr.cart-item-top td:nth-child(3) div:nth-child(1)')[i] as HTMLSpanElement).textContent!.trim().replace(/\D/g, '');
            const rawQty = (document.querySelectorAll('div.cart-table input[name="orgamount"]')[i] as HTMLInputElement).value;

            const price = NumberUtil.parseNumber(rawPrice);
            const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

            totalPrice += price;
            totalQty += qty;

            parsedProducts.push({
                'productCode': (document.querySelectorAll('div.cart-table input[name="branduid"]')[i] as HTMLInputElement).value,
                'productName': (document.querySelectorAll('div.cart-table tr.cart-item td.cart-detail tr.cart-item-top td:nth-child(1) h5')[i] as HTMLHeadElement).textContent!.trim(),
                'price': price / qty,
                'qty': qty
            });
        }

        return {
            'product': parsedProducts,
            'adverId': this.adverId,
            'totalPrice': totalPrice.toString(),
            'totalQty': totalQty.toString()
        };
    }

    protected getImageUrl(): string {
        if (document.querySelector('img.detail_image')) {
            return (document.querySelector('img.detail_image') as HTMLImageElement).src;
        } else if (document.querySelector('div#productDetail div.thumb img')) {
            return (document.querySelector('div#productDetail div.thumb img') as HTMLImageElement).src;
        } else if (document.querySelector('div#productDetail ul.swiper-wrapper>li>img')) {
            return (document.querySelector('div#productDetail ul.swiper-wrapper>li>img') as HTMLImageElement).src;
        } else if (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child')) {
            return (document.querySelector('div.detailThumb ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
        } else if (document.querySelector('div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child')) {
            return (document.querySelector('div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child') as HTMLImageElement).src;
        } else if (document.querySelector('div.detailThumb>img')) {
            return (document.querySelector('div.detailThumb>img') as HTMLImageElement).src;
        } else if (document.querySelector('div.shopdetailInfoTop>figure>img')) {
            return (document.querySelector('div.shopdetailInfoTop>figure>img') as HTMLImageElement).src;
        } else if (document.querySelector('main.shopdetail div.swiper-wrapper img')) {
            return (document.querySelector('main.shopdetail div.swiper-wrapper img') as HTMLImageElement).src;
        } else if (document.querySelector('div.product-detail div.thumbnail img')) {
            return (document.querySelector('div.product-detail div.thumbnail img') as HTMLImageElement).src;
        } else if(document.querySelector('div#productDetail ul li.left_img>img')){
            return (document.querySelector('div#productDetail ul li.left_img>img') as HTMLImageElement).src;
        } else if (document.querySelector('main.shopdetail div.swiper-container1 img')) {
            return (document.querySelector('main.shopdetail div.swiper-container1 img') as HTMLImageElement).src;
        } else if (document.querySelector('div.thumb-wrap div.ori img')) {
            return (document.querySelector('div.thumb-wrap div.ori img') as HTMLImageElement).src;
        } else if (document.querySelector('div.visual-rolling ul.swiper-wrapper li.swiper-slide img')) {
            return (document.querySelector('div.visual-rolling ul.swiper-wrapper li.swiper-slide img') as HTMLImageElement).src;
        } else if (document.querySelector('div.detail-image div.tripoles-detail-title img')) {
            return (document.querySelector('div.detail-image div.tripoles-detail-title img') as HTMLImageElement).src;
        }

        else {
            return '';
        }
    }


}
