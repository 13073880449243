/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-05-11
 */
import URLParse from 'url-parse';
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import {KeywordPair} from "../dataType/KeywordPair";

/**
 * create on 2020-05-11.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ExternalKeywordExtractor {
  /* referrer */
  private readonly referrer: string;

  /* 각 검색엔진의 검색 키워드와 키워드를 담는 파라미터를 맵핑 */
  private readonly searchParamPairs: {
    [fieldName: string]: string[];
  } = {
    'google': ['q'],
    'daum': ['q'],
    'naver': ['q', 'query'],
    'yahoo': ['p', 'q'],
    'msn': ['q'],
    'bing': ['q'],
    'lycos': ['q', 'query'],
    'baidu': ['wd', 'word'],
    'zum': ['query']
  };

  /* 키워드로 허용되지 않은 문자. not을 붙였음에 유의. (한글은 "가"~"힇", "ㄱ"~"ㅎ"만 가능) */
  private readonly forbiddenRegExp: RegExp = /[^-_\uAC00-\uD7A3xfe0-9a-zA-Z\u3131-\u314e\s]/g;

  constructor(referrer: string) {
    const referrerHostname: string = this.getHostnameFromUrl(referrer);
    const currentHostname: string = this.getHostnameFromUrl(location.href);

    /* 현재의 hostname과 referrer의 hostname이 다를 때만 초기화 */
    if (referrerHostname !== currentHostname) {
      this.referrer = referrer;
    } else {
      this.referrer = '';
    }
  }

  /**
   * 추출된 키워드와 유입 referrer URL 반환.
   * 오류 발생시 {#link InvalidData}
   * @return {KeywordPair | InvalidData}
   */
  extract(): KeywordPair | InvalidData {
    try {
      /* 추출된 키워드 */
      let keyword: string = '';

      /* referrer에서 hostname 추출 */
      const hostname: string = this.getHostnameFromUrl(this.referrer);

      /* hostname에서 확인해야 할 query의 field 이름을 추출*/
      const fieldKey: string = this.getFieldNameInHostname(hostname);

      /* 파라미터에 해당하는 값이 있으면 하나만 가져온다 */
      this.searchParamPairs[fieldKey].forEach((field: string) => {
        keyword = this.getKeywordByParam(field, this.referrer);

        if (keyword.length > 0) {
          return;
        }
      });

      /* 불필요한 문자열 제거 */
      keyword = this.removeForbiddenChars(keyword);

      return {
        'keyword': keyword,
        'url': this.referrer
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  /**
   * URL에서 hostname 추출
   * @param {string} url  - URL
   * @return {string} 추출된 hostname (에러 발생시 빈 문자열)
   */
  private getHostnameFromUrl(url: string): string {
    try {
      return new URLParse(url).hostname;
    } catch (e) {
      return '';
    }
  }

  /**
   * 검색 엔진과 파라미터를 맵핑한 객체에서 hostname에 해당하는 파라미터의 필드를 반환.
   * @param {string} hostname - 찾을 검색엔진의 hostname
   * @return {string} - 필드 명 (없는 경우 빈 문자열)
   */
  private getFieldNameInHostname(hostname: string): string {
    let fieldName: string = '';
    const fieldNames: string[] = Object.keys(this.searchParamPairs);
    fieldNames.forEach((name: string) => {
      if (hostname.indexOf(name + '.') >= 0) {
        fieldName = name;
        return;
      }
    });

    return fieldName;
  }

  /**
   * URL에서 특정 파라미터 필드가 갖는 검색 키워드를 UTF-8로 디코딩 후 반환.
   * @param {string} field  - 파라미터 필드
   * @param {string} url    - URL
   * @return {string}       - 검색 키워드 (없거나 에러 발생시 빈 문자열)
   */
  private getKeywordByParam(field: string, url: string): string {
    try {
      const qryStringer: QueryStringer = new QueryStringer(new URLParse(url)['query'].toString());
      return decodeURIComponent(qryStringer.getParam(field) || '');
    } catch (e) {
      return '';
    }
  }

  /**
   * 키워드에 불필요한 문자들을 제거
   * @param {string} keyword - 키워드 문자열
   * @return {string} - 불필요한 문자들이 제거된 키워드 (에러 발생시 빈 문자열)
   */
  private removeForbiddenChars(keyword: string): string {
    try {
      return keyword.replace(this.forbiddenRegExp, '');
    } catch (e) {
      return '';
    }
  }
}
