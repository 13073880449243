/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun1@enliple.com">sjchun</a>
 * @since 2021-03-12
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';

/**
 * create on 2021-03-12.
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Bbia007DataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getProductName(): string {
        try {
          if (window['product_name']) {
            return window['product_name'];
          } else {
            return window.location.pathname.indexOf('/m/') === 0
                ? document.querySelector('#contents > main > div > h3')!.textContent!.trim()
                : document.querySelector('#infoArea > div.titlearea > h2')!.textContent!.trim();
          }
        } catch (e) {
          return '';
        }
    }

    protected getImageUrl(): string {
        if(this.isMobile(this.commandOptions)){
            return super.getImageUrl();
        }else{
            return document.querySelector('link[rel="image_src"]') ? document.querySelector('link[rel="image_src"]')!.getAttribute('href')!.trim() : super.getImageUrl();
        }
    }
}
