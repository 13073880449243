/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-08-09
 */
import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2023-08-09
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class NarostarDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
      jsonObj.data['imageUrl'] = (document.querySelector('#prdDetailImg > img') as HTMLImageElement).src;
    } else {
      jsonObj.data['imageUrl'] = (document.querySelector('div.keyImg.item > div.thumbnail > a > img') as HTMLImageElement).src;
    } return jsonObj
  }
}
