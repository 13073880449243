/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:hjjeon@enliple.com">hjjeon</a>
 * @since 2020-03-17
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-03-17.
 * <p> goodi7(굿아이향수) 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author hjjeon
 */
export class Goodi7DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 상품 상세 페이지 웹버전에서 정보 파싱
   * @return {{}}
   */
  protected getShopCollectData(): JsonObject {
    return window.location.pathname.indexOf('/m/') === 0
        ? new JsonObject(this.getMobileShopCollectData())
        : new JsonObject(this.getWebShopCollectData());
  }

  protected getPayConvDataFromProductPage(): {} {
    return window.location.pathname.indexOf('/m/') === 0
        ? this.getMobilePayConvDataFromProductPage()
        : this.getWebPayConvDataFromProductPage();
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"][action]').length;
    const rawProducts: Array<{}> = window[this.ENP_OBJECT_NAME]['conversion']['product'];

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (rawProducts[i]['price'] as string).trim();
      const rawQty = (document.querySelectorAll('input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(StringUtil.getNonHTMLStr(StringUtil.getMatchedString(rawPrice, StringUtil.htmlRegex.elementContent)));
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (rawProducts[i]['productName'] as string).trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const rawProducts: Array<{}> = window[this.ENP_OBJECT_NAME]['conversion']['product'];
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < rawProducts.length; i++) {
      parsedProducts.push({
        'productCode': rawProducts[i]['productCode'],
        'productName': rawProducts[i]['productName'],
        'qty': rawProducts[i]['qty'],
        'price': NumberUtil.parseNumber(rawProducts[i]['price']).toString(),
      });

      totalQty += NumberUtil.parseNumber(parsedProducts[i]['qty'], NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  private getWebShopCollectData(): {} {
    const currCategoryElement: HTMLInputElement = document.querySelector('form[name="form1"]')!['mcode']
        ? document.querySelector('form[name="form1"]')!['mcode']
        : document.querySelector('form[name="form1"]')!['xcode'];
    this.parseCategory(currCategoryElement.value);

    return {
      'adverId': this.adverId,
      'productCode': document.querySelector('form[name="form1"] input[name="branduid"]')!['value'],
      'productName': this.getProductName(),
      'price': NumberUtil.parseNumber(document.querySelector("#pricevalue")
          ? document.querySelector("#pricevalue")!.textContent!.trim()
          : document.querySelector("#mk_consumer_price")!.textContent!.trim().slice(0, -1)
      ),
      'productUrl': window.location.href,
      'dcPrice': NumberUtil.parseNumber(document.querySelector("#mk_price_value")
          ? document.querySelector("#mk_price_value")!.textContent!.trim()
          : '0'
      ),
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };
  }

  private getMobileShopCollectData(): {} {
    const currCategoryElement: HTMLInputElement = document.querySelector('form[name="form1"]')!['mcode']
        ? document.querySelector('form[name="form1"]')!['mcode']
        : document.querySelector('form[name="form1"]')!['xcode'];
    this.parseCategory(currCategoryElement.value);

    return {
      'adverId': this.adverId,
      'productCode': document.querySelector('form[name="form1"] input[name="branduid"]')!['value'],
      'productName': this.getProductName(),
      'price': document.querySelector(".price_consumer")
          ? NumberUtil.parseNumber(document.querySelector(".price_consumer")!.textContent!.trim())
          : NumberUtil.parseNumber(document.querySelector("#pricevalue")!.textContent!.trim()),
      'productUrl': window.location.href,
      'dcPrice': document.querySelector(".price_consumer")
          ? NumberUtil.parseNumber(document.querySelector("#pricevalue")!.textContent!.trim())
          : '0',
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };
  }

  private getWebPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const currCategoryElement: HTMLInputElement = document.querySelector('form[name="form1"]')!['mcode']
        ? document.querySelector('form[name="form1"]')!['mcode']
        : document.querySelector('form[name="form1"]')!['xcode'];
    this.parseCategory(currCategoryElement.value);

    parsedProducts.push({
      'productCode': document.querySelector('form[name="form1"] input[name="branduid"]')!['value'],
      'productName': this.getProductName(),
      'price': document.querySelector(".price_consumer")
          ? NumberUtil.parseNumber(document.querySelector(".price_consumer")!.textContent!.trim())
          : NumberUtil.parseNumber(document.querySelector("#pricevalue")!.textContent!.trim()),
      'dcPrice': document.querySelector(".price_consumer")
          ? NumberUtil.parseNumber(document.querySelector("#pricevalue")!.textContent!.trim())
          : '0',
      'qty': !!document.querySelector('input[name="amount"]')
          ? (document.querySelector('input[name="amount"]') as HTMLInputElement).value
          : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, null),
      'totalQty': this.getTotalQty()
    };
  }

  private getMobilePayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const currCategoryElement: HTMLInputElement = document.querySelector('form[name="form1"]')!['mcode']
        ? document.querySelector('form[name="form1"]')!['mcode']
        : document.querySelector('form[name="form1"]')!['xcode'];
    this.parseCategory(currCategoryElement.value);

    parsedProducts.push({
      'productCode': document.querySelector('form[name="form1"] input[name="branduid"]')!['value'],
      'productName': this.getProductName(),
      'price': document.querySelector(".price_consumer")
          ? NumberUtil.parseNumber(document.querySelector(".price_consumer")!.textContent!.trim())
          : NumberUtil.parseNumber(document.querySelector("#pricevalue")!.textContent!.trim()),
      'dcPrice': document.querySelector(".price_consumer")
          ? NumberUtil.parseNumber(document.querySelector("#pricevalue")!.textContent!.trim())
          : '0',
      'qty': !!document.querySelector('input[name="amount"]')
          ? (document.querySelector('input[name="amount"]') as HTMLInputElement).value
          : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, null),
      'totalQty': this.getTotalQty()
    };
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
          ? document.querySelector('div.tit')!.textContent!.trim()
          : document.querySelector("#MK_brandname")!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getTotalQty(): string {
    try {
      if (document.querySelector('input[name="amount"]')) {
        return (document.querySelector('input[name="amount"]') as HTMLInputElement).value;
      } else {
        return super.getTotalQty();
      }
    } catch (e) {
      return super.getTotalQty();
    }
  }
}

