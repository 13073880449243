/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-03-18
 */
import {ValidationRuleImpl} from "../ValidationRuleImpl";

/**
 * create on 2020-03-18.
 * <p> 문자열이 html element가 아닌지 검증 </p>
 * <p> html element인 경우 꺾쇠 괄호([])와 그 안의 내용을 제거하고, </p>
 * <p> 나머지 텍스트의 각 사이에 공백을 추가한 후 리턴 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NonHtmlElementRule extends ValidationRuleImpl {
    constructor(propertyName: string) {
        super(propertyName);
    }

    protected applyRule(property: any): any {
        const matchArr: string[] | null = (property as string).match(/[^><]+(?=<|$)/g);
        if (matchArr !== null && matchArr.length > 0) {
            return matchArr.join(' ');
        } else {
            return property;
        }
    }
}
