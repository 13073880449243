import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-10-19
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-10-15.
 * <p> pretty 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class BiionkoreaDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const convProduct = window[this.ENP_OBJECT_NAME]['conversion']['product'];
    const parsedProducts: Array<{}> = [];

    let totalQty = 0;
    for (let i = 0; i < convProduct.length; i++) {
        const qty = convProduct[i]['qty'];
        const price = convProduct[i]['price'];

        const parsedData: {} = {
          'productCode': convProduct[i]['productCode'],
          'productName': convProduct[i]['productName'],
          'price': convProduct[i]['price'],
          'qty': convProduct[i]['qty']
        }

        parsedProducts.push(parsedData);

        totalQty += qty;
    }

    const totalPrice = window[this.ENP_OBJECT_NAME]['conversion']['totalPrice'];

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
        ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
        : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': totalPrice,
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const basketItems = document.querySelectorAll('#shop_cart_list > div.table-wrap > table.shop-table > tbody tr.content');

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < basketItems.length; i++) {
      const rawPrice = basketItems[i].querySelector('td.price')!.textContent!.trim().replace(/\D/g, '');
      const rawQty = (basketItems[i].querySelector('td.amount > input[name=amount]') as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('table.shop-table input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (basketItems[i].querySelector('td.img > a.cart-item-wrap > p.cart-item-title') as HTMLParagraphElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productForms = document.querySelectorAll('form[name="forms"]');

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productForms.length; i++) {
      const rawPrice = document.querySelectorAll('#shop_cart_list > div.table-wrap > table.shop-table > tbody tr.content')[i].querySelector('td.price')!.textContent!.trim().replace(/\D/g, '');
      const rawQty = productForms[i]['orgamount'].value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': productForms[i]['branduid'].value,
        'productName': (document.querySelectorAll('#shop_cart_list > div.table-wrap > table.shop-table > tbody tr.content')[i].querySelector('td.img > a.cart-item-wrap > p.cart-item-title') as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    if (document.querySelector('#productDetail > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > img')) {
      // PC WEB
      return (document.querySelector('#productDetail > div.page-body > div.thumb-info > div.thumb-wrap > div.thumb > img') as HTMLImageElement).src;
    } else {
      // MO
      return (document.querySelector('#prod_image_list > div.slick-slider > div.slick-list.draggable > div.slick-track > div.slick-slide:nth-child(1) > img') as HTMLImageElement).src;
    }
  }
}
