/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-08
 */
import {DataParser} from "../DataParser";
import {DeviceType, HostingType} from "../../types/GlobalEnums";
import {JsonObject} from "../../lib/json/JsonObject";
import {GlobalVariables} from "../../types/GlobalVariables";
import {NumberUtil} from "../../lib/common/NumberUtil";
import {QueryStringer} from "../../lib/url/QueryStringer";
import {Value} from "../../lib/value/Value";
import {StringUtil} from "../../lib/common/StringUtil";
import {NotSupportedError} from "../../error/NotSupportedError";

/**
 * create on 2020-01-08.
 * <p> 메이크샵_D2 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class MakeshopD4DataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.MAKESHOP_D4);
  }

  protected isProductPage(): boolean {
    return this.isMobile(this.commandOptions)
        ? window.location.pathname === '/m/product.html'
        : window.location.pathname === '/shop/shopdetail.html';
  }

  protected isBasketPage(): boolean {
    return this.isMobile(this.commandOptions)
        ? window.location.pathname === '/m/basket.html'
        : window.location.pathname === '/shop/basket.html';
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  /*
   * 메이크샵 스크립트 자동화 요청으로 작업을 진행함
   * 제품 상세페이지의 데이터를 파싱하기 위한 메소드
   */
  protected getProductTargetObj(): {} {
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['collect']) {
      return window[this.ENP_OBJECT_NAME]['collect'];
    } else {
      return {};
    }
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {};
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);
    //if(1)
    if (Object.keys(this.getProductTargetObj()).length > 0) {
      const targetObj: {} = this.getProductTargetObj();
      parsedData = {
        'adverId': this.adverId,
        'productCode': targetObj['productCode'],
        'productName': targetObj['productName'],
        'price': NumberUtil.parseNumber(targetObj['price']),
        'productUrl': window.location.href,
        'dcPrice': NumberUtil.parseNumber(targetObj['dcPrice']),
        'soldOut': targetObj['soldOut'],
        'imageUrl': targetObj['imageUrl'],
        'secondImageUrl': targetObj['secondImageUrl'],
        'thirdImageUrl': targetObj['thirdImageUrl'],
        'fourthImageUrl': targetObj['fourthImageUrl'],
        'topCategory': this.category['topCategory']
            ? this.category['topCategory']
            : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
    } else {
      parsedData = {
        'adverId': this.adverId,
        'productCode': document.querySelector('form#form1')!['branduid']['value'],
        'productName': this.getProductName(),
        'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
            ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
            : document.querySelector('form#form1')!['price']['value'].replace(/\D/g, ''),
        'productUrl': window.location.href,
        'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
            ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent).replace(/\D/g, '')
            : document.querySelector('form#form1')!['disprice']['value'].replace(/\D/g, ''),
        'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
        'imageUrl': this.getImageUrl(),
        'topCategory': this.category['topCategory']
            ? this.category['topCategory']
            : GlobalVariables.unknownCategory,
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
    }

    return new JsonObject(parsedData);
  }

  /**
   * Conversion 이벤트 데이터 파싱
   * 제품상세, 장바구니, 주문완료 페이지에서 Converison 파싱 통합
   * @returns {{}}
   * @protected
   */
  protected getConversionProductDataAuto(convPosition: string = ''): {} {
    const targetObj: {} = window[this.ENP_OBJECT_NAME]['conversion'];
    const parsedProducts: Array<{}> = targetObj['product'];
    const products: Array<{}> = [];
    let totalQty: number = 0;
    let totalPrice: number = 0;

    for (let i = 0; i < parsedProducts.length; i++) {
      const product = {
        'productCode': parsedProducts[i]['productCode'],
        'productName': parsedProducts[i]['productName'],
        'price': parsedProducts[i]['price'],
        'qty': parsedProducts[i]['qty'],
        'dcPrice': parsedProducts[i]['dcPrice'],
      };

      products.push(product);

      /* 총 주문금액, 총 주문수량이 없을 경우를 대비해 직접 계산 */
      totalPrice += NumberUtil.parseInteger(products[i]['price']);
      totalQty += NumberUtil.parseInteger(products[i]['qty']);
    }

    if(convPosition==='order') {
      return {
        'product': products,
        'adverId': this.adverId,
        'ordCode': targetObj['ordCode'],
        'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
        'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
      };
    } else {
      return {
        'product': products,
        'adverId': this.adverId,
        'totalPrice': Value.getValue(targetObj['totalPrice'], totalPrice),
        'totalQty': Value.getValue(targetObj['totalQty'], totalQty)
      };
    }

  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getConversionData(): JsonObject {
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['conversion']) {
      return new JsonObject(this.getConversionProductDataAuto('order'));
    } else {
      return window.location.pathname === '/m/order_complete.html'
          ? this.getMobileConversionData()
          : this.getWebConversionData();
    }
  }

  /**
   * 간편결제(NPay, Payco 등)를 통한 전환 처리시 호출되는 메소드
   * TODO: Cafe24의 모든 광고주가 스크립트 자동화 이용시 else(1)을 제거하여 Conversion 통합을 해야함
   * TODO: if(1)의 조건을 제거하여 현재 메소드는 getConversionProductDataAuto만 리턴 할 수있도록 해야함
   * @returns {{}}
   * @protected
   */
  protected getParsedPayConvData(): {} {
    //if(1)
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['conversion']) {
      return this.getConversionProductDataAuto();
    }
    //else(1)
    else {
      if (this.isProductPage()) {
        return this.getPayConvDataFromProductPage();
      } else if (this.isBasketPage()) {
        return this.getPayConvDataFromBasketPage();
      } else {
        throw new NotSupportedError('PayConversion on current page');
      }
    }
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);

    const price = NumberUtil.parseRationalNumber(StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
        : document.querySelector('form#form1')!['price']['value']);

    const dcPrice = NumberUtil.parseRationalNumber(StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
        ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
        : document.querySelector('form#form1')!['disprice']['value']);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': price && dcPrice && price < dcPrice ? price : (dcPrice ? dcPrice : price),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  protected getPayConvDataFromBasketPage(): {} {
    if (window[this.ENP_OBJECT_NAME] && window[this.ENP_OBJECT_NAME]['conversion']) {
      return new JsonObject(this.getConversionProductDataAuto());
    } else {
      return this.isMobile(this.commandOptions)
          ? this.getMobilePayConvDataFromBasketPage()
          : this.getWebPayConvDataFromBasketPage();
    }
  }

  /**
   * 모바일 여부 판단
   * @param {{}} commandOptions
   * @return {boolean}
   */
  protected isMobile(commandOptions: {}): boolean {
    try {
      /* 커맨드옵션으로 입력된 값 */
      const deviceFromOpt: string = commandOptions['device'].toUpperCase();

      /* enum을 object로 변환 */
      const deviceTypes = Object(DeviceType);

      /* 입력한 값이 DeviceType에 포함되는지 확인 */
      const isValid = Object.keys(deviceTypes).filter((device: string) =>
          DeviceType[device] === deviceFromOpt).length > 0;

      return isValid
          ? deviceFromOpt === DeviceType.MOBILE
          : false;
    } catch (e) {
      return false;
    }
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a, .table-cart > table > tbody > tr > td.ta_left > a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a, #order > div > div.order-list > table > tbody > tr.nobd a, .table-cart > table > tbody > tr > td.ta_left > a')[i] as HTMLAnchorElement;
      const qty = (this.getSelectorAll([
        'div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'table.cart_box_a tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'div.table-cart>table tr td:nth-child(3), div.table-order-prd table tr td:nth-child(3)',
        '#order > div > div.order-list > table > tbody > tr td:nth-child(3) div'
      ])[i] as HTMLDivElement).textContent!.replace(/\D/g, '').trim();
      const price = (this.getSelectorAll([
        'div.table-cart tr td:nth-child(4) div.tb-right.tb-bold',
        'table.cart_box_a tr td:nth-child(4) div.tb-right.tb-bold',
        'div.table-cart>table tr td:nth-child(4)',
        'div.table-order-prd table tr td:nth-child(4)',
        '#order > div > div.order-list > table > tbody > tr td:nth-child(4) div'
      ])[i] as HTMLElement).textContent!.replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  /**
   * 주문완료 모바일 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('div.product_info tr td div a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.product_info tr td div a')[i] as HTMLAnchorElement;
      const qty = (document.querySelectorAll('div.product_info tr td div span.num')[i] as HTMLSpanElement).textContent!.split(':')[1].trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': this.getMobileConversionProdtName(productAnchorElem),
        'qty': qty,
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': this.getMobileTotalPrice(),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll([
        '.PHPS_cartWrap .PHPS_tableRow .PHPS_priceSell',
        'div.table-cart tr td div.tb-price span',
        'table.cart_box_a tr td div.tb-price span',
        'div.table-cart tr td>span.checkPrice',  // chicfox
        'div.table-wrap div.tb-price > span',  //sappun
        'div#cart div.base-table table tr td:nth-child(7) span', // greyraise
        'div#cartWrap table div.tb-price',
        'div.orderListArea table td.total',
        'div.cart-list > table tr td:nth-child(4) div.price',
        'div.table-cart tbody tr td:nth-child(5)',
        '.cart-ct tbody tr td:nth-of-type(7) div span'
      ])[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (this.getSelectorAll([
        'div.table-cart input[name="orgamount"]',
        'div.table-wrap input[name="orgamount"]',
        'div#cart div.base-table table input[name="orgamount"]',
        'div#cartWrap table input[name="orgamount"]',
        'div.orderListArea table input[name="orgamount"]',
        'div.cart-list input[name="orgamount"]',
        '.cart-ct tbody input[name="orgamount"]'
      ])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll([
          'div.table-cart input[name="branduid"]',
          'div.table-wrap input[name="branduid"]',
          'div#cart div.base-table table input[name="branduid"]',
          'div#cartWrap table input[name="branduid"]',
          'div.orderListArea table input[name="branduid"]',
          'div.cart-list input[name="branduid"]',
          '.cart-ct tbody input[name="branduid"]'
        ])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll([
          'div.table-cart tr td > div.tb-left > a',
          'div.table-cart tr td div.tb-left > a',
          'table.cart_box_a tr td div.tb-left > a',
          '.PHPS_cartWrap .PHPS_tableRow .name',
          'div.table-cart tr td div.tb-left a', // pippin11
          'div.table-cart tr td.goods_opt>div.goods_title>a', // chicfox
          'div.table-wrap div.cart-product li.name > a', // sappun
          'div#cart div.base-table table tr td:nth-child(3) > div > a',
          'div#cartWrap table a.name',
          'div.orderListArea table td.product a',
          'div#cartWrap table td:nth-child(4) > div > a',
          'div.cart-list > table a.name',
          'div.table-cart tbody tr td:nth-child(3) a.fc_bk',
          '.cart-ct tbody tr td:nth-of-type(4) div a'
        ])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (
          this.getSelectorAll(
              ['form[name="forms"]>figure>figcaption>p:nth-child(2)'
                , 'form[name="forms"]>figure>div.basketBody p.price-sell'
                , 'form[name="forms"]>figure>figcaption>p.SMSbasketPrice'
                , 'form[name="forms"]>figure>figcaption>div>p:nth-child(2)'
              ])[i] as HTMLParagraphElement).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  protected getImageUrl(): string {
    try {
      return (this.getSelector([
        'img.detail_image',
        'div#productDetail div.thumb img',
        'div#productDetail ul.swiper-wrapper>li>img',
        'div.detailThumb ul.swiper-wrapper>li>img:first-child',
        'div.shopdetailInfoTop ul.swiper-wrapper>li>img:first-child',
        'div.detailThumb>img',
        'div.shopdetailInfoTop>figure>img',
        'main.shopdetail div.swiper-wrapper img',
        'div.product-detail div.thumbnail img',
        'div#productDetail ul li.left_img>img',
        'main.shopdetail div.swiper-container1 img',
        'div.thumb-wrap div.ori img',
        'div.visual-rolling ul.swiper-wrapper li.swiper-slide img',
        '.swiper-container .swiper-wrapper .swiper-slide:first-child img',
        'p.prdImg img',
        'div.shopdetail div.img-goods img',
        '#imgArea li > img',
        'main.shopdetail #carousel-addImg img',
        'main.shopdetail div.big_thumb img',
        '#productDetail .prd-detail .s_img img',
        '#productDetail .thumb-wrap img',
        'ul.thmb_lst li img',
        '#contents > main > section > div.shopdetailInfoTop > div > img',
        '#mainImage img',
        '#detail-item > div.info-wrap > div.items > img',
        '.slick-slide > a > img:first-child',
        '#container > div.product-detail > div.pd-top.clear > div.pd-wrap > div > div.pdt-left > div.pdtl-photo > img',
        '#container > div > section.pd-photo.clear > div.pdp-img > div.pi-box > img',
        'main.shopdetail figure .thumb:not(.bx-clone) img',
        '.swiper-wrapper .swiper-slide-active img',
        '#productDetail .cover_image .multi-image-view img',
        'div.shopdetailInfoTop figure div img',
        'section.shopdetailInfo div.img img',
        '#contents > main > section > div.thumb > img'
      ]) as HTMLImageElement).src;
    } catch {
      return '';
    }
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
            : this.getSelector(['#form1 div.info > h3', 
              '#form1 > div > div.info_top > h3', 
              '#form1 > .infoArea > .table-opt > table > tbody > .name div',
              '#form1 div.info h3.tit-prd',
              '.info > .info_con > .dname h3'
            ])!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }

  protected getTotalPrice(parsedProducts: Array<{}>, totalPriceElement: Element | null): string {
    try {
      const price: number = parsedProducts[0]['dcPrice'] && NumberUtil.parseRationalNumber(parsedProducts[0]['dcPrice']) > 0
          ? NumberUtil.parseRationalNumber(parsedProducts[0]['dcPrice'])
          : NumberUtil.parseRationalNumber(parsedProducts[0]['price']);
      const qty: number = NumberUtil.parseInteger(parsedProducts[0]['qty'].toString());
      const computedTotalPrice: string = (price * qty).toString();

      if (totalPriceElement) {
        const totalPrice: string = totalPriceElement.textContent!.trim();
        return totalPrice && StringUtil.isNotEmpty(totalPrice) && NumberUtil.parseRationalNumber(totalPrice) > 0 ? totalPrice : computedTotalPrice;
      } else {
        return computedTotalPrice;
      }
    } catch (e) {
      return parsedProducts[0]['price'];
    }
  }

  protected getTotalQty(): string {
    try {
      if (document.getElementById("MS_amount_basic_0")) {
        return document.getElementById("MS_amount_basic_0")!['value'].trim();
      } else if (document.querySelector('input.MK_count')) {
        return document.querySelector('input.MK_count')!['value'].trim();
      } else {
        return '1';
      }
    } catch (e) {
      return '1';
    }
  }

  protected getBasketTotalPrice(price: number): number {
    try {
      const totalPrice = NumberUtil.parseNumber(
        this.getSelector([
          'span.MK_total_price span.MK_chg_total_price',
          '.table-cart tfoot .tb-right strong:nth-of-type(3)',
          '.table-cart tfoot .tb-right strong',
          '.table-cart tfoot .tb-right .total-pri span',
          '.price-total-info-bottom .txt-price',
          '.content-info-bottom .txt-price',
          '.table-cart tfoot div.hc_right strong',
          '.order-price-info .order-total-price dd',
          '#cartWrap .total_price .total_list .cont',
          '.basket-bottom .total .price',
          '.total-order-price #totalSettlePrice',
          '.price_total_buy .child3 span em',
          '.cart-ct tfoot div strong'
        ])!.textContent!.replace(/\D/g, ''));
      return price > totalPrice ? price : totalPrice;
    } catch {
      return price;
    }
  }

  protected getMobileTotalPrice(): string {
    try {
      return document.querySelector('div#contents>main#order-complete>p.complete-price>em, div#contents div#order-complete>p.complete-price>em, strong#mk_totalprice')!.textContent!.trim().replace(/\D/g, "");
    } catch (e) {
      return '0';
    }
  }

  /**
   * MakeShop은 서브 카테고리가 없는 경우 mcode가 000으로 자동 매핑 됨 이에 관롼 메소드
   */
  protected getCategoryCode(): string[] {
    const mcode: HTMLInputElement | null = document.querySelector('form#form1 input[name="mcode"]')! as HTMLInputElement;
    const xcode: HTMLInputElement | null = document.querySelector('form#form1 input[name="xcode"]')! as HTMLInputElement;
    let mcodeValue: string;
    let xcodeValue = "";

    if (mcode != null) {
      if (mcode.value === '000') {
        mcodeValue = xcode.value;
      } else {
        xcodeValue = xcode.value;
        mcodeValue = mcode.value;
      }
    } else {
      mcodeValue = xcode.value;
    }

    return [mcodeValue, xcodeValue];
  }

  protected getMobileConversionProdtName(element: Element) {
    try {
      return element.childNodes[0].textContent!.trim();
    } catch (e) {
      return element!.textContent!.trim()
    }
  }
}
