/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-03-31
 */

import {FirstmallDataParser} from "../FirstmallDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {InvalidData} from "../../../lib/ajax/InvalidData";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-03-31
 * <p> allfresh11 - allfresh.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Allfresh11DataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    try {
      const rawProductList: NodeListOf<HTMLElement> = document.querySelectorAll('.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productQty: number = NumberUtil.parseNumber(rawProductList[i].querySelector('.cart_quantity')!.childNodes[1].textContent!.trim());
        const productPrice: number = NumberUtil.parseRationalNumber(rawProductList[i].querySelector('.total_p span.num')!.textContent!.trim());
        const product = {
          'productCode': new QueryStringer((rawProductList[i].querySelector('.cart_goods li.img_area a') as HTMLAnchorElement)!.search.substring(1)).getParam('no'),
          'productName': rawProductList[i].querySelector('.cart_goods span.goods_name')!.textContent!.trim(),
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += productQty;
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': '',
        'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.sum_price.settle_price .num')!.textContent!.trim()),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.replaceData('imageUrl', (document.querySelector('div#goods_thumbs div.viewImgWrap img') as HTMLImageElement).src);
    return jsonObj
  }
}
