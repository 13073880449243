/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-02-01
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { GodomallRentDataParser } from "../GodomallRentDataParser";

/**
 * create on 2023-02-01
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class JsrlifeDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    if(this.isMobile(this.commandOptions))
    jsonObj.replaceData('totalPrice', NumberUtil.parseRationalNumber(document.querySelector('li:nth-child(2) span.prc')!.textContent!.replace(/\D/g,'')));
    return jsonObj;
  }
}