/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-01-12
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-01-12
 * <p> soogee77 - happy10.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Soogee77DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div.table-cart > table > tbody > tr > td:nth-child(3) > div > a')[i].textContent!.trim();
    }

    return jsonObj;
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-cart > table > tbody > tr  div.order_priceft')[i].textContent!.trim());
    }

    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    const productList = jsonObj['product'];

    for (let i = 0; i < productList.length; i++) {
      productList[i]['price'] = NumberUtil.parseRationalNumber(document.querySelectorAll('form[name="forms"]>figure>figcaption>p:nth-child(1)')[i].textContent!.trim());
    }
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(document.querySelector('.txt-price')!.textContent!.trim());
    return jsonObj;
  }

  protected getImageUrl(): string {
    return this.isMobile(this.commandOptions)
        ? (document.querySelector('#contents div.shopdetailInfoTop div.swiper-container img') as HTMLImageElement).src
        : super.getImageUrl();
  }
}
