/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-16
 */
/**
 * create on 2019-12-16.
 * <p> General Tree를 위한 Node 구현 </p>
 * <p> {@link GeneralTree} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class GTNode<T> {
  /* 부모 노드 */
  private _parent?: GTNode<T> = undefined;
  /* 맨 왼쪽에 위치한 자식 노드 */
  private _leftmostChild?: GTNode<T> = undefined;
  /* 현재 노드의 형제 노드 */
  private _rightSibling?: GTNode<T> = undefined;
  /* 노드의 값 */
  private _value: T;

  constructor(value: T) {
    this._value = value;
  }

  /* Getters and Setters */
  get parent(): GTNode<T> | undefined {
    return this._parent;
  }

  set parent(value: GTNode<T> | undefined) {
    this._parent = value;
  }

  get leftmostChild(): GTNode<T> | undefined {
    return this._leftmostChild;
  }

  set leftmostChild(value: GTNode<T> | undefined) {
    this._leftmostChild = value;
  }

  get rightSibling(): GTNode<T> | undefined {
    return this._rightSibling;
  }

  set rightSibling(value: GTNode<T> | undefined) {
    this._rightSibling = value;
  }

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    this._value = value;
  }

  /* Methods */
  /**
   * 말단 노드 여부
   * @return {boolean}
   * <p><code>true</code> - 말단 노드</p><p><code>false</code> - 말단 노드가 아님. (루트)</p>
   */
  isLeaf(): boolean {
    return typeof this.leftmostChild === 'undefined';
  }


  /**
   * 입력한 노드를 현재 노드의 leftmost-child 노드로 삽입한다
   * (이미 leftmost-child 노드가 존재하면 입력한 노드가 leftmost-child가 되고, 해당 노드는 입력한 노드의 형제가 된다)
   * @param {GTNode<T>} node  삽입할 노드
   */
  insertFirst(node: GTNode<T>): void {
    node.parent = this;
    node.rightSibling = this.leftmostChild;
    this.leftmostChild = node;
  }

  /**
   * 입력한 노드를 현재 노드의 leftmost-child 노드의 형제로 삽입한다.
   * (leftmost-child가 없으면 입력한 노드가 leftmost-child 노드가 된다)
   * @param {GTNode<T>} node  삽입할 노드
   */
  insertNext(node: GTNode<T>): void {
    if (this.leftmostChild) {
      node.parent = this;
      let rightmostSibling: GTNode<T> = this.leftmostChild;
      while (rightmostSibling.rightSibling) {
        rightmostSibling = rightmostSibling.rightSibling;
      }

      rightmostSibling.rightSibling = node;
    } else {
      this.insertFirst(node);
    }
  }

  /**
   * leftmost-child 노드를 제거한다.
   * leftmost-child가 자식 노드를 갖고 있으면 그 노드를 현재 노드의 자식으로 올린다.
   */
  removeFirst(): void {
    if (this.leftmostChild) {
      if (this.leftmostChild.leftmostChild) {
        this.leftmostChild.leftmostChild.rightSibling = this.leftmostChild.rightSibling;
        this.leftmostChild = this.leftmostChild.leftmostChild;
      } else {
        this.leftmostChild = this.leftmostChild.rightSibling;
      }
    } else {
      throw new Error('This node is leaf.');
    }
  }

  /**
   * rightmost-child 노드를 제거한다.
   * rightmost-child가 자식 노드를 갖고 있으면 그 노드를 현재 노드의 자식으로 올린다.
   */
  removeNext(): void {
    if (this.leftmostChild) {
      if (this.leftmostChild.rightSibling) {
        let prevSibling: GTNode<T> = this.leftmostChild;
        let rightmostSibling: GTNode<T> = this.leftmostChild;

        /* 현재 노드의 rightmost-child와 그 이전 child를 탐색 */
        while (rightmostSibling.rightSibling) {
          prevSibling = rightmostSibling;
          rightmostSibling = rightmostSibling.rightSibling;
        }

        /* rightmost-child가 자식 노드를 갖고 있으면 현재 노드의 자식 노드로 올린다. */
        let grandChild: GTNode<T> | undefined = rightmostSibling.leftmostChild;
        if (grandChild) {
          let prevGrandChild: GTNode<T> | undefined;
          prevSibling.rightSibling = grandChild;

          while (grandChild) {
            grandChild.parent = this;
            prevGrandChild = grandChild;
            grandChild = grandChild.rightSibling;
          }

          prevGrandChild!.rightSibling = rightmostSibling.rightSibling;
        } else {
          /* rightmost-child가 자식 노드가 없으면 연결을 지운다. */
          prevSibling.rightSibling!.parent = undefined;
          prevSibling.rightSibling = undefined;
        }
      } else {
        this.removeFirst();
      }
    } else {
      throw new Error('This node is leaf.');
    }
  }
}
