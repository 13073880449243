/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-15
 */

import {CrmController} from "../CrmController";
import {HostingType} from "../../../../types/GlobalEnums";

/**
 * create on 2021-02-15
 * <p> Cafe24의 CRM에 관한 클래스  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Cafe24CrmController extends CrmController {
  constructor() {
    super(HostingType.CAFE24_SMART);
  }

  /**
   * Crm 대상 광고주인지 판별
   * 분기 필요시 로직 추가
   */
  isCrmAdvertiser(): boolean {
    return true;
  }
}