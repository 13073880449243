/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-04
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * @version 1.0
 * @author sjchun
 */
export class JsoopDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll([
        '.child4 .prd_price.en_font'
      ])[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (this.getSelectorAll([
        'tbody input[name="orgamount"]'
      ])[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (this.getSelectorAll([
          'tbody input[name="orgamount"]'
        ])[i] as HTMLInputElement).value,
        'productName': (this.getSelectorAll([
          'tbody tr p.prd_name'
        ])[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['form[name="forms"] .b-prd-price .price .en_font'])[i] as HTMLSpanElement).textContent!
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('.b-info .b-prd-name')[i]).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getBasketTotalPrice(totalPrice),
      'totalQty': totalQty.toString()
    };
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table_order tbody tr').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
        parsedProducts.push({
            'productCode': (window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['productCode'],
            'productName': document.querySelectorAll('div.table_order tbody tr .prd_name')![i].textContent!.trim(),
            'qty': NumberUtil.parseNumber(document.querySelectorAll('div.table_order tbody tr td:nth-child(2)')![i].textContent!.trim()),
            'price': NumberUtil.parseNumber(document.querySelectorAll('div.table_order tbody tr td:nth-child(3)')![i].textContent!.trim())
        });
        totalQty += NumberUtil.parseNumber(document.querySelectorAll('div.table_order tbody tr td:nth-child(2)')![i].textContent!.trim());
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('.total_price .num.color_point')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getMobileConversionData(): JsonObject {
    const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
    const productInfoLength = document.querySelectorAll('ul.order_list li').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      parsedProducts.push({
        'productCode': (window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['productId'],
        'productName': (window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['productName'],
        'qty': (window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['productQty'],
        'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['productQty']),
      });

      totalQty += NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['productQty'], NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': ordCode,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('.pay_box .tr_pay em')!.textContent!),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
