/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-05-10
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-05-10.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SarhbellaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    if(!this.isMobile(this.commandOptions)){
      return {
        price: super.getProductPrice().price,
        dcPrice: window['product_sale_price'] ? window['product_sale_price'] : super.getProductPrice().dcPrice
      }
    }
    return super.getProductPrice();
  }
}
