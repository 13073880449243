/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-22
 */

import { InvalidData } from "../../../lib/ajax/InvalidData";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { FirstmallDataParser } from "../FirstmallDataParser";

/**
 * create on 2021-12-22.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class GrigoshopDataParser extends FirstmallDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('.cart_list .shipping_group_list');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productName = rawProductList[i].querySelector('.goods_name')!.textContent!.trim();
        const params = (rawProductList[i].querySelector('.img_area a') as HTMLAnchorElement).search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = rawProductList[i].querySelector('.cart_quantity')!.childNodes[1].textContent!.replace(/\D/g, '');
        const productPrice = rawProductList[i].querySelector('.total_p .num')!.textContent!.replace(/\D/g, '');
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': '',
        'totalPrice': NumberUtil.parseNumber(document.querySelector('#totalPrice .num')!.textContent!.replace(/\D/g, '')),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }
}