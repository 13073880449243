/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { PaySystem } from './PaySystem';
import {DeviceType, PaySystemType} from '../../types/GlobalEnums';

/**
 * create on 2019-10-10.
 * <p> <code>PaySystem</code> 객체를 생성하는 팩토리 클래스 </p>
 * <p> <b>Flyweight</b> 패턴으로 작성. </p>
 * <p> {@link PaySystem} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class PaySystemFactory {
  /**
   * 각 키 별로<code>PaySystem</code> 객체를 갖고 있는 풀.
   * @type {{}}
   */
  private static readonly pool: {
    [adverId: string]: { [paySystemType in PaySystemType]?: PaySystem };
  } = {};

  /**
   * 생성자. (외부에서 접근 불가)
   */
  private constructor() {}

  /**
   * <p> 인자값이 키가 되어 해당되는 <code>PaySystem</code> 객체를 반환. (없으면 생성) </p>
   * @param {string} adverId - 광고주 ID
   * @param {PaySystemType} paySystemType - 간편 결제 시스템 타입
   * @param deviceType  - 디바이스 타입
   * @return {PaySystem}  - 키에 해당하는 <code>PaySystem</code> 객체
   */
  static getPaySystem(adverId: string, paySystemType: PaySystemType, deviceType: DeviceType): PaySystem {
    if (!PaySystemFactory.pool[adverId]) {
      PaySystemFactory.pool[adverId] = {};
    }

    let paySystem: PaySystem | undefined =
      PaySystemFactory.pool[adverId][paySystemType];
    if (!paySystem) {
      paySystem = new PaySystem(paySystemType, adverId, deviceType);
      PaySystemFactory.pool[adverId][paySystemType] = paySystem;
    } else {
      /* reusable */
    }

    return paySystem;
  }
}
