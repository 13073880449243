/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-01
 */

import {JsonObject} from "../../../lib/json/JsonObject";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-07-01
 * <p> Vittz1 광고주 관련 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class BncmarketDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(3) div.tb-center')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(4) div.tb-center.tb-bold')[i].textContent!.replace(/\D/g, '').trim()
      totalQty += NumberUtil.stringToNumber(qty);
    }
    jsonObj.data['totalQty'] = totalQty;
    return jsonObj;
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice =  document.querySelectorAll('div > p.cnt > span')[i].textContent!.replace(/\D/g, '').trim();
      const rawQty = (document.querySelectorAll('div > p.cnt > input[name="amount"]')[i] as HTMLInputElement).value;
      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('span.txt-price > b > span')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = document.querySelector('#cartWrap tr > td > div > strong:nth-child(3)')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    if (this.isMobile(this.commandOptions)) {
        jsonObj.data['dcPrice'] =  
        NumberUtil.parseNumber( this.getSelector(['#disprice', '#price'])!['value'].replace(/\D/g,'')) <
        NumberUtil.parseNumber(document.querySelector('#price_wh')!['value'].replace(/\D/g,''))    
        ?
        NumberUtil.parseNumber(document.querySelector('#disprice')!['value'].replace(/\D/g,'')) :
        NumberUtil.parseNumber(document.querySelector('#price')!['value'].replace(/\D/g,'')) 
      }
    return jsonObj;
  }
}