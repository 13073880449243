/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-19
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {GodomallRentDataParser} from "../GodomallRentDataParser";

/**
 * create on 2023-01-19
 * <p> {@cureka } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class CurekaDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {

    let superData = super.getMobileShopCollectData(formElement);
    superData.data['dcPrice'] = document.querySelectorAll('div.price_box Strong.price')[1].textContent!.replace(/\D/g,'');

    return superData;
  }
}