/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {ArgumentType, CommandType, EventType} from '../../types/GlobalEnums';
import {NotSupportedError} from '../../error/NotSupportedError';
import {Value} from '../../lib/value/Value';
import {InvalidValueError} from '../../error/InvalidValueError';

/**
 * create on 2019-08-28.
 * <p> 클라이언트 코드로부터 메소드를 통해 받는 인자를 추출하여 객체로 생성 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ArgumentExtractor {
  private constructor() {}

  static createExtractedArgs(rawArguments: {}): {} {
    const extractedObj: {} = {};
    const commandType = rawArguments[0];

    switch (commandType) {
      case CommandType.CREATE:
        extractedObj[ArgumentType.COMMAND_TYPE] = commandType;
        extractedObj[ArgumentType.EVENT_TYPE] = this.getValidEventType(rawArguments[1]);
        extractedObj[ArgumentType.ADVER_ID] = rawArguments[2];
        extractedObj[ArgumentType.OPTIONS] = rawArguments[3];
        break;
      case CommandType.SET:
        extractedObj[ArgumentType.COMMAND_TYPE] = commandType;
        extractedObj[ArgumentType.EVENT_TYPE] = this.getValidEventType(rawArguments[1]);
        extractedObj[ArgumentType.DATA] = rawArguments[2];
        break;
      case CommandType.SEND:
        extractedObj[ArgumentType.COMMAND_TYPE] = commandType;
        extractedObj[ArgumentType.EVENT_TYPE] = this.getValidEventType(rawArguments[1]);
        extractedObj[ArgumentType.ADVER_ID] = rawArguments[2];
        extractedObj[ArgumentType.OPTIONS] = rawArguments[3];
        break;
      default:
        throw new NotSupportedError();
    }

    return extractedObj;
  }

  /**
   * 유효한 이벤트 타입을 리턴.
   * <b>NOTE : </b>잘못된 이벤트 타입을 받으면 오류 발생.
   * @param eventType - 클라이언트 코드에서 받은 이벤트 타입
   * @return {EventType}  - 검증된 이벤트 타입
   * @throws InvalidValueError  - 유효하지 않은 이벤트 타입을 받았을 경우.
   */
  private static getValidEventType(eventType: any): EventType {
    for (const key in EventType) {
      if (Value.isTypeOfString(eventType) && eventType === EventType[key]) {
        return eventType;
      }
    }

    throw new InvalidValueError('eventType', eventType);
  }
}
