/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-09
 */
import {JsonObject} from "../../../lib/json/JsonObject";
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";


/**
 * create on 2020-03-09.
 * <p> Cookiehouse 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class CookiehouseDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    jsonObj['totalPrice'] = document.querySelector('div.table-cart tfoot strong')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }

  /**
   * 장바구니 페이지 모바일 버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromBasketPage();
    jsonObj['totalPrice'] = document.querySelector('span.txt-price')!.textContent!.replace(/\D/g, '').trim();
    return jsonObj;
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getWebConversionData();
    const productList = jsonObj.data['product'];

    for(let i = 0; i < productList.length; i++) {
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-child(5)')[i].textContent!.replace(/\D/g, '').trim();
    }

    return jsonObj;
  }


  protected getProductName(): string {
    try {
      return this.isMobile(this.commandOptions)
          ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
          : document.querySelector('#form1 div.info h3')!.textContent!.trim();
    } catch (e) {
      return '';
    }
  }
}
