/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-02
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { Tracker } from "../../../tracker/Tracker";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-09-02
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class InjiactiveDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if(!this.isMobile(this.commandOptions)){
      const productInfo = document.querySelectorAll('.orderListArea .ec-base-table tbody tr.xans-record-');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;
  
      for (let i = 0; i < productInfo.length; i++) {
        const qty = NumberUtil.parseNumber(productInfo[i].querySelector('td:nth-of-type(4)')!.textContent!.replace(/\D/g, ''));
  
        parsedProducts.push({
          'productCode': new QueryStringer((productInfo[i].querySelector('.thumb a') as HTMLAnchorElement).search.substring(1)).getParam('product_no'),
          'productName': productInfo[i].querySelector('strong.name a')!.textContent!.trim(),
          'qty': qty,
          'price': NumberUtil.parseNumber(document.querySelectorAll('tr.xans-record- td:nth-child(3) div:nth-child(2) strong')[i].textContent!.replace(/\D/g, ''))
        });

        totalQty += qty;
      }
  
      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
        'totalPrice': document.querySelector('.totalArea td div.box.txtEm.txt16 strong span.txt23')!.textContent!.replace(/\D/g, ''),
        'totalQty': totalQty.toString()
      };
      return new JsonObject(parsedData);

    }else{

      const rawConvData: {} = window['EC_FRONT_EXTERNAL_SCRIPT_VARIABLE_DATA'];
      const rawProductDataArr: Array<{}> = rawConvData['order_product'];
      const parsedProducts: Array<{}> = [];
      const parsedTotalPrice: number = this.getParsedTotalPrice();
      let totalQty = 0;
      let totalPrice = 0;
  
      for (let i = 0; i < rawProductDataArr.length; i++) {
        const productCode: string = NumberUtil.isNumber(rawProductDataArr[i]['product_no'])
            ? rawProductDataArr[i]['product_no'].toString()
            : rawProductDataArr[i]['product_no'];
  
        parsedProducts.push({
          'productCode': productCode,
          'productName': rawProductDataArr[i]['product_name'],
          'qty': rawProductDataArr[i]['quantity'],
          'price': NumberUtil.parseNumber(document.querySelectorAll('div.ec-base-prdInfo.xans-record- li.price span:nth-child(2)')[i]!.textContent!.replace(/\D/g, ''))
        });
  
        totalQty += rawProductDataArr[i]['quantity'];
        totalPrice += rawProductDataArr[i]['product_price'];
      }
  
      const parsedData: {} = {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': rawConvData['order_id'],
        'totalPrice': parsedTotalPrice === 0 || NumberUtil.isNaN(parsedTotalPrice)
            ? (
                rawConvData['payed_amount'] 
                ? rawConvData['payed_amount'] : totalPrice
              )
            : parsedTotalPrice.toString(),
        'totalQty': totalQty
      };
      return new JsonObject(parsedData);
    }
  }

  protected getShopCollectData(): JsonObject {
    
    if(this.isMobile(this.commandOptions)){
      this.mobileCustomNpayCall();
      }else{
        this.webCustomNpayCall();
      }
    return super.getShopCollectData();

  }


  private mobileCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector('div.npay-wrap.active')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'M', 'paySys': 'naverPay' }
      ]);
    });
  }
  private webCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector('div.npay-wrap.active')!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    });
  }
}



  

