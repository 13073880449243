/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-03-18
 */
import { GodomallRentDataValidator } from '../GodomallRentDataValidator';
import { InvalidData } from '../../../lib/ajax/InvalidData';

/**
 * create on 2020-03-18.
 * <p> beautybetter3 - 일동몰 광고주 데이터 검증 클래스 </p>
 * <p> 일동몰의 경우 특정한 카테고리의 상품만 타겟팅을 진행한다. </p>
 * <p> {@link GodomallRentDataValidator} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */

export class Beautybetter3DataValidator extends GodomallRentDataValidator {
  private readonly NONE: string = 'none';

  constructor() {
    super();
  }

  protected collectValidate(data: {}): {} {
    const isNone: boolean = this.adverIdIsNone(data['adverId']);

    if (isNone) {
      return new InvalidData();
    }

    return super.collectValidate(data);
  }

  protected cartValidate(data: {}): {} {
    const isNone: boolean = this.adverIdIsNone(data['adverId']);

    if (isNone) {
      return new InvalidData();
    }

    return super.cartValidate(data);
  }

  protected wishValidate(data: {}): {} {
    const isNone: boolean = this.adverIdIsNone(data['adverId']);

    if (isNone) {
      return new InvalidData();
    }

    return super.wishValidate(data);
  }


  /**
   * 일동몰의 광고주의 경우 dataParser에서 세팅된 adverID가 none 여부 반환
   *  - 타겟팅 대상이 아닌 카테고리의 제품은 dataParser에서 none으로 세팅이 된다.
   * @param  adverId 광고주 아이디
   * @return true : none / flase : none 아님
   */
  private adverIdIsNone(adverId: string): boolean {
    return adverId === this.NONE;
  }
}
