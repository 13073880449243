/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-13
 */
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-01-13.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class BeaunessDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 가격, 할인가격를 파싱하여 객체로 리턴
   * @return {{price: number dcPrice: number}} 파싱된 가격, 할인가격
   */
  protected getProductPrice(): { price: number; dcPrice: number; } {
    try {
      const priceObj = super.getProductPrice();
      if (this.isMobile(this.commandOptions)) {
        const parsedPriceValue: number = document.querySelector('.priceArea .custom')
            ? NumberUtil.parseRationalNumber(document.querySelector('.priceArea .custom')!.textContent!.replace(/\D/g, ''))
            : 0
        priceObj['price'] = parsedPriceValue && parsedPriceValue > 0 ? parsedPriceValue : priceObj['price'];
      }

      return priceObj;
    } catch (e) {
      return super.getProductPrice();
    }
  }
}
