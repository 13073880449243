/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-23
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-10-23
 * <p> B2bworld 데이터파서 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class B2bworldDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.data['productName'] = document.querySelector('div.xans-product > table > tbody > tr:nth-child(1) > td > span, h1.name')!.textContent!.trim();
    jsonObj.data['productNo'] = new QueryStringer(window.location.search.substring(1)).getParam('product_no');
    return jsonObj;
  }
}