/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-01-29
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-01-29
 * <p> drdiary - http://mall.drdiary.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class DrdiaryDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    if (this.isMobile(this.commandOptions)) return jsonObj;
    jsonObj.replaceData('totalPrice', NumberUtil.parseRationalNumber(document.querySelector('.order-page .table1 tbody > tr:nth-child(10) strong')!.textContent!.trim()));
    return jsonObj;
  }
}