/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-23
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {StringUtil} from "../../../lib/common/StringUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-04-23.
 * <p> 스노우라인 광고주에 대한 재정의 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class SnowlineDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 기존 품절여부를 환경변수에서 세팅된 값으로 판단했지만 해당 광고주는 판매가격 정보에
   * 입고예정(4월) 이러한 데이터로 품절여부를 표시함
   */
  protected getShopCollectData(): JsonObject {
    this.parseCategory(window['iCategoryNo']);
    const priceInfo: {} = this.getProductPrice();
    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'price': priceInfo['price'],
      'productUrl': window.location.href,
      'dcPrice': priceInfo['dcPrice'],
      'soldOut': this.getSoldOut(priceInfo['dcPrice']),
      'imageUrl': this.getImageSrc(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  /**
   * 기존 품절여부를 환경변수에서 세팅된 값으로 판단했지만 해당 광고주는 판매가격 정보에
   * 입고예정(4월) 이러한 데이터로 품절여부를 표시함
   */
  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const qty: number = NumberUtil.stringToNumber(this.getProductQty());
    const priceInfo: { price: number, dcPrice: number } = this.getProductPrice();
    const totalPrice: number = (this.onSale(priceInfo) ? priceInfo['dcPrice'] : priceInfo['price']) * qty;

    this.parseCategory(window['iCategoryNo']);
    parsedProducts.push({
      'productCode': window['iProductNo'],
      'productName': window['product_name'],
      'qty': qty,
      'price': priceInfo['price']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice,
      'totalQty': qty
    };
  }

  /**
   * 기존의 Cafe24는 가격정보를 Cafe24에서 제공하는 가상테그에서 파싱을 했지만 해당 광고주는 가격정보를
   * 가상테그를 이용해 웹 화면에 표시를 안하기 때문에 웹화면에서 html 파싱으로 데이터 세팅
   * TODO - 화폐기호 제거하는 로직을 추후에 parseNumber()로 옮겨야 하지만 그렇게 되면 다른 의존된 부분 모두 확인 필요
   * @return {{price: number dcPrice: number soldOut: string}} 파싱된 가격, 할인가격, 품절여부
   */
  protected getProductPrice(): { price: number; dcPrice: number; } {
    let price: number = 0, dcPrice: number = 0;

    //모바일, 웹 유형에 따라 가격정보 파싱
    if(this.isMobile(this.commandOptions)){
      const priceElem = document.querySelectorAll('strong.sale');

      if(priceElem.length >= 2){
        price = NumberUtil.parseNumber(StringUtil.replace(priceElem[2].textContent!.trim(), StringUtil.currencyRegex, StringUtil.EMPTY));
        dcPrice = NumberUtil.parseNumber(StringUtil.replace(priceElem[0].textContent!.trim(), StringUtil.currencyRegex, StringUtil.EMPTY));
      } else {
        dcPrice = NumberUtil.parseNumber(StringUtil.replace(priceElem[0].textContent!.trim(), StringUtil.currencyRegex, StringUtil.EMPTY));
      }
    } else {
      const priceElem: Element = document.querySelector('strong.sale')!;
      const dcPriceElem: Element = document.querySelector('#span_product_price_text')!;

      if (priceElem && dcPriceElem) {
        price =  NumberUtil.parseNumber(StringUtil.replace(priceElem.textContent!.trim(), StringUtil.currencyRegex, StringUtil.EMPTY));
        dcPrice =  NumberUtil.parseNumber(StringUtil.replace(dcPriceElem.textContent!.trim(), StringUtil.currencyRegex, StringUtil.EMPTY));
      }
    }

    return {price: price, dcPrice: dcPrice};
  }

  /**
   * 기존 Cafe24 개수 파싱 Selector가 달라 재정의
   */
  protected getProductQty(): string {
    if (document.querySelector('#quantity') != null) {
      return (document.querySelector('#quantity') as HTMLInputElement)!.value;
    } else {
      return '0';
    }
  }

  protected getSoldOut(dcPrice: number): string{
    if(isNaN(dcPrice) || (window['is_soldout_icon'] != null && window['is_soldout_icon'] === 'T')){
      return "Y"
    } else {
      return "N"
    }
  }
}
