/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-15
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {GlobalVariables} from "../../../types/GlobalVariables";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
 * create on 2022-11-15.
 * <p> wishe 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class WisheDataParser  extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getCategoryCode(): string[] {
    const mcode: HTMLInputElement | null = document.querySelector('form input[name="mcode"]')! as HTMLInputElement;
    const xcode: HTMLInputElement | null = document.querySelector('form input[name="xcode"]')! as HTMLInputElement;
    let mcodeValue: string;
    let xcodeValue = "";

    if (mcode != null) {
      if (mcode.value === '000') {
        mcodeValue = xcode.value;
      } else {
        xcodeValue = xcode.value;
        mcodeValue = mcode.value;
      }
    } else {
      mcodeValue = xcode.value;
    }

    return [mcodeValue, xcodeValue];
  }

  protected getShopCollectData(): JsonObject {
    let parsedData: {};
    let dcPrice=0;
    const categoryCode = this.getCategoryCode();
    this.parseCategory(categoryCode[0]);
    const imageUrl = this.isMobile(this.commandOptions) ? (document.querySelector('div.img-goods img') as HTMLImageElement).src : (document.querySelector('div#goods_image_show img') as HTMLImageElement).src;
    let price=document.querySelector('input#price')!['value'].replace(/\D/g, '');
    if(document.querySelector('input#discount_type')!['value']!=''){
      dcPrice=document.querySelector('input#disprice')!['value'].replace(/\D/g, '');
    }

      const targetObj: {} = this.getProductTargetObj();
      parsedData = {
        'adverId': this.adverId,
        'productCode': window['product_uid'],
        'productName': window['product_name'],
        'price': price,
        'productUrl': window.location.href,
        'dcPrice': dcPrice,
        'soldOut': targetObj['soldOut'],
        'imageUrl': imageUrl,
        'secondImageUrl': targetObj['secondImageUrl'],
        'thirdImageUrl': targetObj['thirdImageUrl'],
        'fourthImageUrl': targetObj['fourthImageUrl'],
        'topCategory': categoryCode[0],
        'firstSubCategory': categoryCode[1],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };

    return new JsonObject(parsedData);
  }
  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    let dcPrice=0;
    let price=document.querySelector('input#price')!['value'].replace(/\D/g, '');
    if(document.querySelector('input#discount_type')!['value']!=''){
      dcPrice=document.querySelector('input#disprice')!['value'].replace(/\D/g, '');
    }
    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': price && dcPrice && price < dcPrice ? price : (dcPrice ? dcPrice : price),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }
  
}

