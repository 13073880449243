/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2022-11-17
 */
import {JsonObject} from "../../../lib/json/JsonObject";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";


/**
 * create on 2022-11-17.
 * <p> Ddasum 광고주 파싱 </p>
 * <p> {@link Cafe24SmartDataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class Yellow7803DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.data['price'] = document.querySelector('span#span_product_price_custom')!.textContent!.replace(/\D/g,'');
    return jsonObj;
  }
}
