/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-12-21
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-12-21.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Cellion01DataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    let superData = super.getPayConvDataFromBasketPage();
    const rawProductDataArr: Array<{}> = window['aBasketProductData'];
    for(let i = 0; i < superData['product'].length; i++){
      superData['product'][i].price = NumberUtil.parseNumber(rawProductDataArr[i]['product_sale_price']);
    }
    return superData;
  }
}