/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-04-20
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-04-20
 * <p> pengsamsung - samsung.com </p>
 * 삼성의 경우 특정 카테고리 페이지 접속시 공통 모수를 따로 수집
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export abstract class PengsamsungDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * 삼성의 경우 특정 카테로리 페이지 접속시 모수를 따로 측정하기 위해
   * 도메인을 samsung1.com으로 변경 후 수집
   * @protected
   */
  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();

    const samsungOneFilterList = [
      'www.samsung.com/sec/smartphones/all-smartphones/',
      'www.samsung.com/sec/tablets/all-tablets/',
      'www.samsung.com/sec/watches/all-watches/',
      'www.samsung.com/sec/buds/all-buds/',
      'www.samsung.com/sec/mobile-accessories/all-mobile-accessories/',
      'www.samsung.com/sec/desktop/all-desktop/',
      'www.samsung.com/sec/notebook/all-notebook/',
      'www.samsung.com/sec/pc-accessories/all-pc-accessories/',
      'www.samsung.com/sec/galaxybook/all-galaxybook/',
      'www.samsung.com/sec/pc/galaxybook/',
      'www.samsung.com/sec/monitors/all-monitors/',
      'www.samsung.com/sec/printers/all-printers/',
      'www.samsung.com/sec/memory-storage/all-memory-storage/'
    ];

    const samsungTwoFilterList = [
        'www.samsung.com/sec/galaxycampus/'
    ];

    const samsungThreeFilterList = [
        'www.samsung.com/sec/smartphones/all-smartphones/'
    ];

    if(samsungOneFilterList.some((url)=>url === location.hostname+location.pathname)){
      jsonData.addData('domain', 'samsung1.com');
    }

    if(samsungTwoFilterList.some((url)=>url === location.hostname+location.pathname)){
      jsonData.addData('domain', 'samsung2.com');
    }

    if(samsungThreeFilterList.some((url)=>url === location.hostname+location.pathname)){
      jsonData.addData('domain', 'samsung3.com');
    }

    return jsonData;
  }
}
