/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-10
 */
import {NotSupportedError} from "../error/NotSupportedError";
import {AdvertiserConfig} from "./AdvertiserConfig";
import {AJAXer} from "../lib/ajax/AJAXer";
import {GlobalVariables} from "../types/GlobalVariables";
import {StorageItemNotFoundError} from "../error/storageApi/StorageItemNotFoundError";
import {InvalidValueError} from "../error/InvalidValueError";
import {ItemNotFoundError} from "../error/ItemNotFoundError";
import {StringUtil} from "../lib/common/StringUtil";
import {NumberUtil} from "../lib/common/NumberUtil";
import {DateUtil} from "../lib/common/DateUtil";

/**
 * create on 2020-04-10.
 * <p> 광고주의 설정 정보를 localStorage에서 조회 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ConfigStorage {
  /* 브라우저의 로컬 스토리지 지원 여부 */
  private static localStorageSupported: boolean = ConfigStorage.whetherLocalSorageSupported();
  /* 스토리지 key의 prefix */
  private static readonly key: string = 'ENP_CONFIG';
  /* 스토리지 저장 기간 (일) */
  private static readonly expiryDay: number = 1;
  /* 스토리지 */
  private readonly storage: Storage;
  /* 광고주에 대한 config json 파일의 CDN path */
  private readonly cdnPath: string = (window['enp'] && window['enp']['cdnUrl'] ? window['enp']['cdnUrl'] : GlobalVariables.cdnInfo.host.https) + GlobalVariables.cdnInfo.pathName.config;

  constructor() {
    if (ConfigStorage.localStorageSupported) {
      this.storage = window.localStorage;
    } else {
      throw new NotSupportedError('localStorage');
    }
  }

  /**
   * 브라우저의 <code>localStorage</code> 지원 여부
   * <b>NOTE: </b>에러 발생시 <code>false</code>를 리턴.
   * @return {boolean}  지원 여부
   * <p><code>true</code> - 지원함</p><p><code>false</code> - 지원하지 않음</p>
   */
  static whetherLocalSorageSupported(): boolean {
    try {
      return !!window.localStorage;
    } catch (e) {
      return false;
    }
  }

  /**
   * 광고주 ID에 해당하는 설정 정보를 가져온다.
   * <ol>
   *   <li><code>localStorage</code>에서 조회하여 ID가 일치하면 그 정보를 리턴한다.</li>
   *   <li>조회되지 않거나 ID가 일치하지 않으면 CDN에서 json 파일을 로드하고 <code>localStorage</code>에 저장 후 정보를 리턴한다.</li>
   * </ol>
   * @param {string} adverIdFromClient  - 클라리언트에서 입력받은 ID
   * @return {AdvertiserConfig} - 광고주 정보
   * @throws ItemNotFoundError  - 광고주의 설정 정보를 가져오지 못할 경우
   */
  getConfig(adverIdFromClient: string): AdvertiserConfig {
    let adverConfig: AdvertiserConfig | undefined = undefined;

    try {
      /* localStorage에서 로우데이터 조회 */
      adverConfig = JSON.parse(this.getRawConfig());

      /* 설정 정보의 검증 */
      this.validateConfig(adverConfig!);
      return adverConfig!;
    } catch (e) {
      /* localStorage에서 찾지 못하거나 설정 정보가 검증되지 않으면 catch 문에 도달한다 */

      /* CDN에서 json 데이터 로드 */
      this.loadConfigJsonByUrl(adverIdFromClient, (config) => {
        adverConfig = config;
      });

      /* 로드된 config을 storage에 저장 후 반환 */
      if (adverConfig) {
        this.setConfig(adverConfig);
        return adverConfig!;
      } else {
        /* 찾지 못하면 에러 발생 */
        throw new ItemNotFoundError('adverConfig');
      }
    }
  }

  /**
   * <code>localStorage</code>에 광고주 정보를 JSON 형식 문자열로 저장
   * @param {AdvertiserConfig} adverConfig  - 광고주 정보
   */
  setConfig(adverConfig: AdvertiserConfig): void {
    /* 만료일 추가 */
    const expiry: Date = new Date(Date.now() + (1000 * 60 * 60 * 24 * ConfigStorage.expiryDay));
    adverConfig['expiry'] = DateUtil.getYYYYMMDDString(expiry);
    this.storage.setItem(ConfigStorage.key, JSON.stringify(adverConfig));
  }

  /**
   * <code>localStorage</code>에서 광고주 정보에 대한 JSON 형식 로우 데이터를 반환.
   * @return {string} - 광고주 정보의 로우 데이터 (JSON 형식)
   * @throws StorageItemNotFoundError - ID에 대한 광고주 정보를 찾지 못한 경우
   */
  private getRawConfig(): string {
    const rawConfig: string | null = this.storage.getItem(ConfigStorage.key);
    if (rawConfig) {
      return rawConfig;
    } else {
      throw new StorageItemNotFoundError(ConfigStorage.key, '광고주 설정 정보');
    }
  }

  /**
   * 설정 정보 검증.
   * - 클라리언트에서 입력받은 ID와 <code>localStorage</code>에서 추출한 {@link AdvertiserConfig}의 ID가 동일한지 체크.
   * - 만료되었는지 체크.
   * @param {AdvertiserConfig} advertiserConfig - <code>localStorage</code>에서 추출한 광고주 정보
   * @throws InvalidValueError  - 검증되지 않은 경우
   */
  private validateConfig(advertiserConfig: AdvertiserConfig): void {
    const hasDefined: boolean = typeof advertiserConfig !== 'undefined';
    const notExpired: boolean = !this.hasExpired(advertiserConfig);

    if (hasDefined && notExpired) {
      /* 검증됨 */
    } else {
      /* 검증되지 않음 */
      throw new InvalidValueError();
    }
  }

  /**
   * localStorage의 설정 정보가 만료되었는지 확인.
   * <b>만료 조건</b>
   * <ol>
   *   <li>날짜가 지남</li>
   *   <li>
   *     <code>localStorage</code>에 잘못된 만료 정보가 들어 있음.
   *     <ul>
   *       <li>만료 정보가 없음.</li>
   *       <li>YYYYMMDD 형태가 아님.</li>
   *       <li>만료 정보를 가져오는 과정에서 에러 발생.</li>
   *     </ul>
   *   </li>
   * </ol>
   * @param {AdvertiserConfig} advertiserConfig - <code>localStorage</code>에서 추출한 광고주 정보
   * @return {boolean}
   * <p><code>true</code> - 만료됨</p><p><code>true</code> - 만료되지 않음</p>
   */
  private hasExpired(advertiserConfig: AdvertiserConfig): boolean {
    try {
      const expiry: string = advertiserConfig['expiry'];
      const today: string = DateUtil.getYYYYMMDDString();
      const isInvalidExpiry: boolean = !(expiry && expiry.length === 8 && StringUtil.isPositiveIntegerFormat(expiry));

      return isInvalidExpiry || NumberUtil.stringToNumber(expiry) < NumberUtil.stringToNumber(today);
    } catch (e) {
      return true;
    }
  }

  /**
   * client에서 전달받은 광고주 ID에 해당하는 json 파일을 로드해서 콜백에서 처리.
   * @param {string} adverIdFromClient  - client에서 받은 광고주 ID void
   * @param {(adverConfig: AdvertiserConfig) => void} callback  - json 데이터를 처리할 콜백 함수
   */
  private loadConfigJsonByUrl(adverIdFromClient: string, callback: (adverConfig: AdvertiserConfig) => void): void {
    const ajaxer: AJAXer = AJAXer.getInstance();
    ajaxer.options.withCredentials = false;
    ajaxer.options.setHeader = false;

    /* json 파일 유무에 따라 분기가 처리되므로 동기로 가져온다 */
    ajaxer.get(`${this.cdnPath + adverIdFromClient}.json`, (xhr, response, hasTransmitted) => {
      if (hasTransmitted && callback && response) {
        callback(JSON.parse(response) as AdvertiserConfig);
      }
    }, false);
  }
}
