/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Punycode} from '../../lib/codec/Punycode';
import {Utf8} from '../../lib/codec/Utf8';
import {Base64} from '../../lib/codec/Base64';
import {Config} from './config/Config';
import {ApiName, EventType} from '../../types/GlobalEnums';
import {ClientInfo} from '../../lib/common/ClientInfo';
import {AdverConfigJsonMapper} from '../../adverConfigJsonMapper/AdverConfigJsonMapper';
import {Transmitter} from './core/Transmitter';
import {DataParser} from '../../dataParser/DataParser';
import {StringUtil} from "../../lib/common/StringUtil";
import {TimeOnPage} from "../timeOnPage/TimeOnPage";
import {ProductStorage} from "../productHistory/ProductStorage";
import {DepatureInspector} from "../../tracker/data/DepatureInspector";
import {KeywordFetcher} from '../keyword/KeywordFetcher';

/**
 * TODO 주석
 * create on 2019-08-05.
 * <p> 상품 수집 </p>
 * <p> {@link Transmitter} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ShopCollector extends Transmitter {
  protected readonly _punycode: Punycode;
  protected readonly _utf8: Utf8;
  protected readonly _base64: Base64;

  constructor(eventType: EventType, config: Config, dataParser: DataParser) {
    super(eventType, config, dataParser);
    this._punycode = new Punycode();
    this._utf8 = new Utf8();
    this._base64 = new Base64();
  }

  protected preSend(): void {
    const clientInfo: ClientInfo = ClientInfo.getInstance();
    const adverDomain = StringUtil.getDomainFromUrl(window.location.href);

    this.addExtraData(
      {
        'adverId': this.config.adverId,
        'domain': adverDomain,
        'charSet': document.characterSet,
        'referrer': document.referrer,
        'referrerDomain': StringUtil.getReferrerDomain(),
        'cookieEnabled': clientInfo.cookieEnabled ? clientInfo.cookieEnabled : true,
        'nation': clientInfo.nation,
        'siteCode': AdverConfigJsonMapper.getInstance(this.config.adverId).getSiteCode(),
        'solutionType': AdverConfigJsonMapper.getInstance(this.config.adverId).getHostingType()
      },
      false
    );

    this.addExtraData(new KeywordFetcher().getCollectKeyword(this.eventType, this.jsonObject.data['productCode']));
  }

  protected finish(): void {
    /* localStorage에 상품코드 저장 */
    this.saveProductCode(this.jsonObject.data['productCode']);

    /* gateway로 전송 */
    super.finish();

    /* 페이지 체류시간 기록 */
    // this.recordTimeOnPage();

    DepatureInspector.getInstance().collectState = true;
  }

  /**
   * 체류시간 기록
   */
  private recordTimeOnPage(): void {
    /*
     * 상품수집 호출은 이미 window 객체의 load 이벤트에 등록하였으므로
     * 방문 시점 기록은 이벤트를 이용하지 않고 직접 호출한다.
     */
    const timeOnPage: TimeOnPage = new TimeOnPage(this.config.adverId, this.jsonObject.data['productCode']);
    timeOnPage.invokeRecordHitTime();
    timeOnPage.addRecordBounceTimeEvent();
  }

  /**
   * localStorage에 상품코드 저장
   * @param {string} productCode  - 저장할 상품코드
   */
  private saveProductCode(productCode: string): void {
    if (productCode) {
      new ProductStorage().save(this.eventType, [productCode.toString()]);
    }
  }
}
