/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-12-16
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-12-16
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class AbmnymallDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  
  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();

    switch (location.href) {
      case 'https://shopping.namyangi.com/main/main':
      case 'https://mshopping.namyangi.com/main/main':
        jsonData.addData('domain', 'shopping.namyangi1.com');
        break;
      case 'https://shopping.namyangi.com/brand/detail?dpBrndId=12':
      case 'https://mshopping.namyangi.com/brand/detail?dpBrndId=12':
        jsonData.addData('domain', 'shopping.namyangi2.com');
        break;
      case 'https://shopping.namyangi.com/brand/detail?dpBrndId=17':
      case 'https://mshopping.namyangi.com/brand/detail?dpBrndId=17':
        jsonData.addData('domain', 'shopping.namyangi3.com');
        break;
      case 'https://shopping.namyangi.com/brand/detail?dpBrndId=11':
      case 'https://mshopping.namyangi.com/brand/detail?dpBrndId=11':
        jsonData.addData('domain', 'shopping.namyangi4.com');
        break;
      case 'https://shopping.namyangi.com/main/brand':
      case 'https://mshopping.namyangi.com/main/brand':
        jsonData.addData('domain', 'shopping.namyangi5.com');
        break;
      case 'https://shopping.namyangi.com/brand/detail?dpBrndId=16':
      case 'https://mshopping.namyangi.com/brand/detail?dpBrndId=16':
        jsonData.addData('domain', 'shopping.namyangi6.com');
        break;
      case 'https://shopping.namyangi.com/brand/detail?dpBrndId=8':
      case 'https://mshopping.namyangi.com/brand/detail?dpBrndId=8':
        jsonData.addData('domain', 'shopping.namyangi7.com');
        break;
    }

    return jsonData;
  }
}
