/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-02-28
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-02-28.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class MarieearleDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {

    if(super.isProductPage()){
      if(!this.isMobile(this.commandOptions)){
          this.webCustomNpayCall();
        }
    }
    return super.getCommonTraceData();
  }

  private webCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector("div.go_member a#npay_chk2")!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    });
  }


  protected getProductPrice(): { price: number; dcPrice: number; } {
    try{
      if(!this.isMobile(this.commandOptions)){
        return super.getProductPrice();
      }
      let superData: {price: number; dcPrice: number;} = super.getProductPrice();
      superData.dcPrice = NumberUtil.parseNumber(document.querySelector('#span_product_price_text')!.textContent!);
      return superData;
    }catch{
      return super.getProductPrice();
    }
  }
}
