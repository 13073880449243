/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-11-16
 */
import { EventUtil } from "../../../lib/event/EventUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2022-11-16
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class GlamdDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {

    if(super.isBasketPage()||super.isProductPage()){
      if(this.isMobile(this.commandOptions)){
        this.mobileCustomNpayCall();
        }else{
          this.webCustomNpayCall();
        }
    }
    return super.getCommonTraceData();
  }

  private mobileCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector("div.go_member a#npay_gopay")!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'M', 'paySys': 'naverPay' }
      ]);
    });
  }
  private webCustomNpayCall(): void {
    EventUtil.addEvent(document.querySelector("div.go_member a#npay_chk2")!, 'click', () => {
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'send',
        'conversion',
        this.adverId,
        { 'device': 'W', 'paySys': 'naverPay' }
      ]);
    });
  }

}



  

