/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-01-06
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-01-06.
 * <p> Attatt 데이터파서</p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class JoegrandeDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getProductPrice(): { price: number; dcPrice: number; } {
    try{
      return {
        price: NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content),
        dcPrice: NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content)
      }
    } catch{
      return super.getProductPrice();
    } 
  }
  protected getPayConvDataFromProductPage(): {} {
    const jsonObj = super.getPayConvDataFromProductPage();

    jsonObj['product'][0]['price'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:price:amount"]') as HTMLMetaElement).content);
    jsonObj['product'][0]['dcPrice'] =  NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content);

    return jsonObj;
  }
}
