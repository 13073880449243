/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee1</a>
 * @since 2023-04-13
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-04-13.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class BnrmallDataParser extends Cafe24SmartDataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected isProductPage(): boolean {
      try {
        return (0 === window.location.pathname.indexOf('/product')
            || 0 === window.location.pathname.indexOf('//product')
            || 0 === window.location.pathname.indexOf('/surl/P')
            || 0 === window.location.pathname.indexOf('/front/php/product.php'));
      } catch (e) {
        return false;
      }
    }
}