/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-03-31
 */
import { ValidationRuleImpl } from '../ValidationRuleImpl';
import { NumberUtil } from '../../../lib/common/NumberUtil';

/**
 * create on 2022-03-31
 * <p> total price 가격 포맷 검증 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class TotalPriceValidRule extends ValidationRuleImpl {
  constructor(propertyName: string) {
    super(propertyName);
  }

  protected applyRule(property: any): any {
    const validatedTotPrice = typeof property === 'string' ? NumberUtil.parseRationalNumber(property as string) : property;
    if (!property || NumberUtil.isNaN(validatedTotPrice)) {
      return 1;
    }

    return validatedTotPrice;
  }
}
