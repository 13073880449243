/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-11-29
 */

import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";

/**
 * create on 2021-11-29.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class WefrienDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    try{
      if(this.isMobile(this.commandOptions)){
        let jsonData: JsonObject = super.getShopCollectData();
        jsonData.data['price'] = NumberUtil.parseNumber(document.querySelector('.ec-base-table .priceArea .cu_cost')!.textContent!);
        return jsonData;
      }
      return super.getShopCollectData();
    }catch{
      return super.getShopCollectData();
    }
  }
}
