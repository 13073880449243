/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-02-10
 */

import {CrmService} from "../service/CrmService";
import {EventType, HostingType} from "../../../types/GlobalEnums";
import {CrmFactory} from "../factory/CrmFactory";

/**
 * create on 2021-02-10
 * <p> CRM(회원정보) 시스템에 데이터 전송을 위한 controller </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export abstract class CrmController {
  protected crmService: CrmService | undefined;

  constructor(hostingType: HostingType) {
    this.crmService = new CrmFactory().createService(hostingType);
  }

  /**
   * 부가적인 데이터 JsonObject 반환
   * @param eventType
   * @private
   */
  getExtraData(eventType: EventType): {} {
    try {
      switch (eventType) {
        case EventType.COMMON:
          return this.crmService!.getCommonExtraData();
        case EventType.CART:
        case EventType.WISH:
          return this.crmService!.getCartExtraData();
        case EventType.CONVERSION:
          return this.crmService!.getConversionExtraData();
        default:
          return {};
      }
    } catch (e) {
      return {};
    }
  }

  /**
   * CRM과 Mobon 외부적인 스크립트의 통합을 위해 CRM 스크립트의 공통 호출 
   * @param adverId 광고주 아이디
   * @param hostingType 호스팅 타입
   */
  executeScript(adverId: string, hostingType: HostingType): void {
    try {
      this.crmService!.excuteScript(adverId, hostingType);
    } catch (e) {

    }
  }

  /**
   * 호스팅 별 CRM 전송 대상 광고주인지 판단
   */
  abstract isCrmAdvertiser(): boolean;
}
