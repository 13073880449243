/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-10-24
 */

import { GodomallRentDataParser } from "../GodomallRentDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
 * create on 2022-10-24
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Samjin5468DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getPayConvDataFromBasketPage(): {} {
    if (this.isMobile(this.commandOptions)) return super.getPayConvDataFromBasketPage();

    const parsedProducts: Array<{}> = [];
    const inputList: NodeListOf<HTMLInputElement> = document.querySelectorAll('input[type="checkbox"][id^="cartSno"]');

    let totalQty = 0;

    for (let i = 0; i < inputList.length; i++) {
      const qty = NumberUtil.parseInteger((document.querySelectorAll('[name^=goodsCnt]')[i] as HTMLInputElement).value);
      const rawPrice = NumberUtil.parseNumber(inputList[i].dataset['price'] as string);
      const price = rawPrice / qty;

      parsedProducts.push({
        'productCode': new QueryStringer((document.querySelectorAll('.cart_info .order_goods_info a')[i] as HTMLAnchorElement).search.substring(1)).getParam('goodsNo'),
        'productName': document.querySelectorAll('.cart_info .order_goods_info a')[i].textContent!.trim(),
        'qty': qty,
        'price': price
      });

      totalQty += qty;
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      /* 배송비 포함된 금액을 가져오지 못하면 상품 가격들의 합으로 초기화 */
      'totalPrice': NumberUtil.parseNumber(document.querySelector('.cart_goods_calc_total p span')!.textContent!),
      'totalQty': totalQty
    };
  }
  
  protected getConversionData(): JsonObject {
    let superData = super.getConversionData();
    const totalPrice = this.isMobile(this.commandOptions) ? NumberUtil.parseNumber(document.querySelector('.jt_form li div p span')!.textContent!) :
      NumberUtil.parseNumber(document.querySelector('.order_view_data_total')!.textContent!);
    superData.replaceData('totalPrice', totalPrice);
    return superData;
  }
}