/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {Command} from './core/Command';
import {Config} from '../../service/transmitter/config/Config';
import {ConfigFactory} from '../../service/transmitter/config/ConfigFactory';
import {EventType} from '../../types/GlobalEnums';
import {TransmitterFactory} from '../../service/transmitter/core/TransmitterFactory';
import {CommandBuilder} from './core/CommandBuilder';
import {JsonObject} from '../../lib/json/JsonObject';
import {DataParser} from '../../dataParser/DataParser';
import {Transmitter} from '../../service/transmitter/core/Transmitter';

/**
 * create on 2019-08-25.
 * <p> 클래스 설명 </p>
 * <p> {@link ConfigFactory} and {@link CommandBuilder} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class SendCommand extends Command {
  private readonly adverId: string;
  private readonly options: {};
  private readonly config: Config;
  private readonly transmitter: Transmitter;
  private readonly jsonData: JsonObject;
  private readonly dataParser: DataParser;

  constructor(builder: CommandBuilder) {
    super(builder);
    this.adverId = builder.adverId;
    this.options = builder.options;
    this.jsonData = builder.jsonData;
    this.dataParser = builder.dataParser;
    this.config = this.createConfig(this.eventType, this.adverId, this.options);
    this.transmitter = this.createTransmitter(this.eventType, this.config, this.dataParser, this.options);
  }

  executeCommand(): void {
    this.transmitter.send(this.jsonData, this.config.callback);
  }

  private createConfig(eventType: EventType, adverId: string, options?: {}): Config {
    return ConfigFactory.createConfig(eventType, adverId, options);
  }

  private createTransmitter(eventType: EventType, config: Config, dataParser: DataParser, options?: {}): Transmitter {
    return TransmitterFactory.createTransmitter(eventType, config, dataParser, options);
  }
}
