/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-30
 */

import {TelegramAlarm} from "./telegram/TelegramAlarm";
import {DeviceType, EventType, TelegramParseMode} from "../../types/GlobalEnums";

/**
 * create on 2020-10-30
 * <p> data Parser에서 파싱에 실패했을 경우 텔레그렘을 이용해 알람을 전송 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ParsingExceptionAlarm extends TelegramAlarm {
  private _exceptionMessage: string;

  constructor(adverId: string, deviceType: DeviceType, eventType: EventType) {
    super(adverId, deviceType, eventType, TelegramParseMode.HTML);
    this._exceptionMessage = '';
  }

  set exceptionMessage(value: string) {
    this._exceptionMessage = value;
  }

  protected setMessage(): void {
    this.telegramMessage.setTitle(`${this.eventType} Parsing Exception Alarm`);
    this.telegramMessage.setInfoMessage({'AdverId': this.adverId, 'DeviceType': this.deviceType, 'UserAgent': navigator.userAgent});
    this.telegramMessage.setBodyMessage(this._exceptionMessage);
    this.telegramMessage.setPageUrl();
  }
}