/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-03-09
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";

/**
* create on 2023-03-09
* <p> {@link } and {@link } 관련 클래스 </p>
*
* @version 1.0
* @author yjlee
*/
export class ComebelloDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
      super(adverId, commandOptions);
  }


  protected getConversionData_prev(): JsonObject {
    if(this.isMobile(this.commandOptions)) {
      return super.getConversionData_prev();
    }
    const convProductName=document.querySelectorAll('div.orderListArea table:nth-child(2) tr td.product a');
    let totalQty = 0;
    const parsedProducts: Array<{}> = [];

    for (let i = 0; i < convProductName.length; i++) {
      const params = document.querySelectorAll('div.orderListArea table:nth-child(2) tr td.product')[i].querySelector('a')!.search.substring(1).split('&');
      const paramObj = {};
      for (let j = 0; j < params.length; j++) {
        const key = params[j].split('=')[0];
        const val = params[j].split('=')[1];
        paramObj[key] = val;
      }
      const productCode = paramObj['product_no'];
     let qty =NumberUtil.parseNumber(document.querySelectorAll('div.orderListArea table:nth-child(2) tr td.quantity')[i].textContent!.replace(/\D/,''));
      parsedProducts.push({
        'productCode': productCode,
        'productName': convProductName[i].textContent!.trim(),
        'qty': qty,
        'price': document.querySelectorAll('div.orderListArea table:nth-child(2) tr td.price')[i].textContent!.replace(/\D/,''),
      });
      totalQty += qty;
    }
    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('input#order_id')!['value'],
      'totalPrice': document.querySelector('div.totalArea div.summary td.price2 strong:nth-child(1)')!.textContent!.replace(/\D/,''),
      'totalQty':  totalQty
    };
    return new JsonObject(parsedData);
  }


}