/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-11-19
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2020-11-19
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class FivecoffeDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj = super.getWebPayConvDataFromBasketPage();
    const productList = jsonObj['product'];
    for (let i = 0; i < productList.length; i++) {
      productList[i]['productName'] = document.querySelectorAll('div.table-cart tr td div.tb-left > a:nth-child(1)')[i].textContent!.trim();
    }
    return jsonObj;
  }

  protected getWebConversionData(): JsonObject {
    const jsonObj = super.getWebConversionData();
    const productList = jsonObj.data['product'];
    let totalQty = 0;

    for (let i = 0; i < productList.length; i++) {
      const qty = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(3) div.tb-center')[i].textContent!.replace(/\D/g, '').trim();
      productList[i]['qty'] = qty;
      productList[i]['price'] = document.querySelectorAll('div.table-order-prd table tr td:nth-of-type(5) div.tb-center.tb-bold')[i].textContent!.replace(/\D/g, '').trim()
      totalQty += NumberUtil.stringToNumber(qty);
    }

    jsonObj.data['totalQty'] = totalQty;

    return jsonObj;
  }
}