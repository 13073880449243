/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2023-01-19
 */
import { NumberUtil } from "../../../lib/common/NumberUtil";
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2023-01-19.
 * <p> truedel 광고주 </p>
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class FolnuaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number; } {
    let superData = super.getProductPrice();
    superData['dcPrice'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement).content);
    return superData;
  }
}
