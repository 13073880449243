/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-04-06
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {GeneralTree} from "../../../lib/dataStructure/tree/general/GeneralTree";
import {Category} from "../../../category/Category";
import {GTNode} from "../../../lib/dataStructure/tree/general/GTNode";
import {Queue} from "../../../lib/dataStructure/queue/Queue";
import {GlobalVariables} from "../../../types/GlobalVariables";
import {JsonObject} from "../../../lib/json/JsonObject";
import {StringUtil} from "../../../lib/common/StringUtil";
import {Value} from "../../../lib/value/Value";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-04-06.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class ZiosongzioDataParser extends MakeshopD4DataParser{
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const categoryCode = this.getCategoryCode();
    this.zioParseCategory(categoryCode[0], categoryCode[1]);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          : document.querySelector('form#form1')!['price']['value'],
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          : document.querySelector('form#form1')!['disprice']['value'],
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a, table.cart_box_a tr td div.tb-left a, div.table-order-prd tr td div.tb-left a')[i] as HTMLAnchorElement;
      const rawQty = (this.getSelectorAll([
        'div.table-cart tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'table.cart_box_a tr td:nth-child(3) div.tb-right:not(.tb-bold)',
        'div.table-cart>table tr td:nth-child(3), div.table-order-prd table tr td:nth-child(3)'
      ])[i] as HTMLDivElement).textContent!.trim().slice(0, -1);
      const rawPrice: string = (document.querySelectorAll('div.table-cart tr td:nth-child(4) div.tb-right.tb-bold, table.cart_box_a tr td:nth-child(4) div.tb-right.tb-bold, div.table-cart>table tr td:nth-child(4), div.table-order-prd table tr td:nth-child(4)')[i] as HTMLDivElement).textContent!.trim().slice(0, -1);

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': rawQty,
        'price': NumberUtil.parseNumber(rawPrice) / NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER),
      });

      totalQty += NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    const categoryCode = this.getCategoryCode();
    this.zioParseCategory(categoryCode[0], categoryCode[1]);

    parsedProducts.push({
      'productCode': document.querySelector('form#form1')!['branduid']['value'],
      'productName': this.getProductName(),
      'price': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['price'], StringUtil.htmlRegex.elementContent)
          : document.querySelector('form#form1')!['price']['value'],
      'productUrl': window.location.href,
      'dcPrice': StringUtil.matchExactly(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          ? StringUtil.getMatchedString(window[this.ENP_OBJECT_NAME]['dcPrice'], StringUtil.htmlRegex.elementContent)
          : document.querySelector('form#form1')!['disprice']['value'],
      'soldOut': Value.getValue(window[this.ENP_OBJECT_NAME]['soldOut'], 'N'),
      'imageUrl': this.getImageUrl(),
      'qty': !!document.getElementById("MS_amount_basic_0")
          ? document.getElementById("MS_amount_basic_0")!['value']
          : 1,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': this.getTotalPrice(parsedProducts, document.querySelector('#MK_p_total')),
      'totalQty': this.getTotalQty()
    };
  }

  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;
    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (document.querySelectorAll('div.table-cart tr td div.tb-price span:not([class])')[i] as HTMLSpanElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('div.table-cart input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(StringUtil.replace(rawPrice, /\\/, StringUtil.EMPTY));
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('div.table-cart input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('div.table-cart tr td div.tb-left > a, table.cart_box_a tr td div.tb-left > a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

  protected zioParseCategory(currentCategoryCode: string, parentCategoryCode: string): void {
    const tree: GeneralTree<Category> | undefined = this.receiveTreeFromStorage();
    this.zioInitCategory(tree, currentCategoryCode, parentCategoryCode);
  }

  /**
   * 전송할 카테고리 데이터를 트리를 이용해 초기화.
   * <ul>
   *   <li>현재 상품에 대한 카테고리를 찾지 못하면 이름값이 아닌 코드값으로 초기화 한다.</li>
   *   <li><code>localStorage</code>의 데이터를 트리로 변환하지 못하면 카테고리 값은
   *   <code>undefined</code>로 초기화 된다.</li>
   * </ul>
   * @param {GeneralTree<Category> | undefined} tree  트리
   * @param {string} currentCategoryCode 현재 보고 있는 상품의 카테고리 코드
   * @param {string} parentCategoryCode 부모 카테고리 코드
   */
  protected zioInitCategory(tree: GeneralTree<Category> | undefined, currentCategoryCode: string, parentCategoryCode: string): void {
    try {
      const leafNode: GTNode<Category> | undefined = this.zioSearchCategoryNode(tree, currentCategoryCode, parentCategoryCode);
      if (typeof leafNode === 'undefined') {
        this.category.topCategory = currentCategoryCode;
      } else {
        const hierarchyQueue: Queue<string> = this.createHierarchyQueue(leafNode);
        this.setAllOfCategoryData(this.category, hierarchyQueue);
      }
    } catch (e) {
      this.category.topCategory = GlobalVariables.unknownCategory;
      this.category.firstSubCategory = undefined;
      this.category.secondSubCategory = undefined;
      this.category.thirdSubCategory = undefined;
    }
  }

  /**
   * 코드에 해당되는 노드를 트리에서 탐색 후 반환. 찾지 못하면 <code>undefined</code>
   * ## 고니샵은 카테고리 코드가 중복되는게 많아 Tree Node의 code만 가져오면 잘못된 값을 가져오는 경우가 있음
   * @param {GeneralTree<Category> | undefined} tree  트리
   * @param {string} currentCategoryCode  현재 보고 있는 상품의 카테고리 코드
   * @param {string} parentCategoryCode 부모 카테고리 코드
   * @return {GTNode<Category> | undefined} 찾은 노드
   */
  protected zioSearchCategoryNode(tree: GeneralTree<Category> | undefined, currentCategoryCode: string, parentCategoryCode: string): GTNode<Category> | undefined {
    try {
      return tree!.search(currentNode => currentNode.value.code === currentCategoryCode && currentNode.value.parentCode === parentCategoryCode);
    } catch (e) {
      return undefined;
    }
  }
}
