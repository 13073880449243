/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-29
 */
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {StringUtil} from "../../../lib/common/StringUtil";

/**
 * create on 2020-12-29.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee2
 */
export class ZeroneDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected isProductPage(): boolean {
    try {
      return this.isMobile(this.commandOptions)
          ? StringUtil.matchExactly(window.location.pathname, 'goods_view.php')
          : StringUtil.matchExactly(window.location.pathname, /^.*\/goods\/goods_view.php$/);
    } catch (e) {
      return false;
    }
  }

  protected isBasketPage(): boolean {
    try {
      return this.isMobile(this.commandOptions)
          ? StringUtil.matchExactly(window.location.pathname, 'cart.php')
          : StringUtil.matchExactly(window.location.pathname, /^.*\/order\/cart.php$/);
    } catch (e) {
      return false;
    }
  }

  protected getImageUrl(): string {
    if (document.querySelector('div.slick-track > li.slick-slide > img')) {
      return (document.querySelector('div.slick-track > li.slick-slide > img') as HTMLImageElement).src;
    } else if (document.querySelector('#mainImage>img')) {
      return (document.querySelector('#mainImage>img') as HTMLImageElement).src;
    }

    return '';
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj = super.getMobileShopCollectData();

    const price = 0 < window[this.ENP_OBJECT_NAME]['price']
        ? window[this.ENP_OBJECT_NAME]['price']
        : window[this.ENP_OBJECT_NAME]['dcPrice'];

    const dcPrice = window[this.ENP_OBJECT_NAME]['dcPrice'];

    jsonObj.addData('price', NumberUtil.parseNumber(price));
    jsonObj.addData('dcPrice', NumberUtil.parseNumber(dcPrice));
    jsonObj.addData('productName', window[this.ENP_OBJECT_NAME]['productName']);
    jsonObj.addData('productCode', new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'));

    return jsonObj;
  }

  protected getMobilePayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);
    const qty = NumberUtil.parseInteger((document.querySelector('input[name="goodsCnt[]"]') as HTMLInputElement).value);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': window[this.ENP_OBJECT_NAME]['productName'],
      'price': NumberUtil.parseRationalNumber(window[this.ENP_OBJECT_NAME]['price']),
      'dcPrice': NumberUtil.parseRationalNumber(window[this.ENP_OBJECT_NAME]['dcPrice']),
      'qty': qty,
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseRationalNumber(window[this.ENP_OBJECT_NAME]['price']) * qty,
      'totalQty': qty
    };
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const cartDataArr = document.querySelectorAll('ul.my_goods li');
    let totalQty = 0;

    for (let i = 0; i < cartDataArr.length; i++) {
      const price = NumberUtil.parseNumber(cartDataArr[i].querySelector('div.info > a > strong.prc')!.textContent!.trim());
      const qty = NumberUtil.parseNumber(cartDataArr[i].querySelector('div.info > a > div > div.itembody > dd')!.textContent!.trim());

      totalQty += qty;

      parsedProducts.push({
        'productCode': new QueryStringer((cartDataArr[i].querySelector('div.info > a') as HTMLAnchorElement).search.substr(1)).getParam('goodsNo'),
        'productName': (cartDataArr[i].querySelector('div.info > a > div > div > div > img') as HTMLImageElement)!.alt!.trim(),
        'qty': qty,
        'price': price
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('#totalSettlePrice')!.textContent!.trim()),
      'totalQty': totalQty
    };
  }

  protected getWebShopCollectData(): JsonObject {
    const jsonObj = super.getWebShopCollectData();

    jsonObj.addData('productCode', new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'));

    return jsonObj;
  }

  protected getWebPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(window[this.ENP_OBJECT_NAME]['category']);

    const price = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['price']);
    const dcPrice = NumberUtil.parseNumber(window[this.ENP_OBJECT_NAME]['price']);
    const qty = NumberUtil.parseInteger((document.querySelector('input[name="goodsCnt[]"]') as HTMLInputElement).value);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': window[this.ENP_OBJECT_NAME]['productName'],
      'price': price,
      'qty': qty,
      'dcPrice': dcPrice
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': price * qty,
      'totalQty': qty
    };
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const basketProducts = document.querySelectorAll("#frmCart > div > table > tbody tr:not(.op)");
    let totalQty = 0;

    for (let i = 0; i < basketProducts.length; i++) {
      const price = NumberUtil.parseNumber(basketProducts[i].querySelector('strong.price')!.textContent!.trim());
      const qty = NumberUtil.parseNumber((basketProducts[i].querySelector("input[name='goodsCnt[]']") as HTMLInputElement).value);

      totalQty += qty;

      parsedProducts.push({
        'productCode': new QueryStringer((basketProducts[i].querySelector('td.gi > div > a') as HTMLAnchorElement).search.substr(1)).getParam('goodsNo'),
        'productName': basketProducts[i].querySelector('td.gi > div > a')!.textContent!.trim(),
        'qty': qty,
        'price': price
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('#totalSettlePrice')!.textContent!.trim()),
      'totalQty': totalQty
    };
  }

}
