/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-30
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2022-12-30.
 * <p> honeyandmilk 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class HoneyandmilkDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebConversionData(): JsonObject {
    const jsonData = super.getWebConversionData();
    const productList = jsonData.getDataByName('product');

    for (let i = 0; i < productList.length; i++) {
      const rawPrice = NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-cart tr td:nth-child(5) div.tb-center')[i].textContent!.replace(/\D/g,'').trim());
      const qty =  NumberUtil.parseRationalNumber(document.querySelectorAll('div.table-cart tr td:nth-child(3) div.tb-center')[i].textContent!.replace(/\D/g,'').trim());
      productList[i]['price'] = rawPrice/qty;
    }
    jsonData.replaceData('product', productList);
    return jsonData;
  }
}