/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-02-18
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {JsonObject} from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";
import { StringUtil } from "../../../lib/common/StringUtil";

/**
 * @version 1.0
 * @author sjchun
 */
export class OrigoDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    if(this.isMobile(this.commandOptions)){
      let jsonObj = super.getShopCollectData();

      const dcPrice = document.querySelector('#change_discount_price_wh') ? 
      NumberUtil.parseNumber(document.querySelector('#change_discount_price_wh')!.textContent!) : jsonObj.getDataByName('dcPrice');

      jsonObj.replaceData('dcPrice', dcPrice);

      return jsonObj;
    }else{
      return super.getShopCollectData();
    }
  }

  /**
   * 주문완료 웹 버전에서 정보 파싱
   * @return {JsonObject} 파싱된 결과
   */
  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('div.order-list table tbody tr td div.tb-left a').length;
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 0; i < productInfoLength; i++) {
      const productAnchorElem = document.querySelectorAll('div.order-list table tbody tr td div.tb-left a')[i] as HTMLAnchorElement;
      const qty = (this.getSelectorAll([
        'div.order-list table tbody tr td:nth-child(3) .tb-right'
      ])[i] as HTMLDivElement).textContent!.replace(/\D/g, '').trim();
      const price = (this.getSelectorAll([
        'div.order-list table tbody tr td:nth-child(4) .tb-right'
      ])[i] as HTMLElement).textContent!.replace(/\D/g, '').trim();

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price': price,
      });

      totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('form[name="orderdetail"]')
          ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
          : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().slice(0, -1),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }
}
