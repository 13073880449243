/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-06-09
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import { Tracker } from "../../../tracker/Tracker";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2022-06-09
 * <p> peterpan_mkt ( SPA - GTM ) 삽입 광고주 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */

export abstract class PeterpanMktDataParser extends SelfHostedDataParser {
  //device type M: 모바일, W: 웹
  private readonly deviceType: string = location.pathname.indexOf('remobile') != -1 ? 'M' : 'W';
  //web select list
  private readonly webSelectList = [{
    btnSelector: '#contact',
    productName: 'sms01'
  }, {
    btnSelector: '#sendbird_chat_btn',
    productName: 'chatting01'
  }];
  //mobile select list
  private readonly moSelectList = [{
    btnSelector: 'div.direct-call',
    productName: 'Call'
  }, {
    btnSelector: 'div.contact.text-icon-btn',
    productName: 'M_sms01'
  }, {
    btnSelector: 'div.contact.icon-btn',
    productName: 'M_sms01'
  }, {
    btnSelector: 'div.chat.icon-btn',
    productName: 'M_chatting01'
  }, {
    btnSelector: 'div[data-gtag="mweb_peterVerified_sms1"].contact',
    productName: 'M_sms01'
  }];
  //중복 방지를 위해 이벤트 추가한 btnSelector 저장
  private duplicateEvent: Array<string> = [];
  //select count
  private searchCount: number = 0;

  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
    this.createPreferences();
  }

  /** 
   * script 호출 시 URL 변경 감지
   */
  private createPreferences(): void {
    let detectUrl = this.isMobile() ? location.href : location.pathname;
    document.body.addEventListener('click', () => {
      requestAnimationFrame(() => {
        if (this.isMobile() && detectUrl !== location.href) {
          this.commonTracker();
        } else if (!this.isMobile() && detectUrl !== location.pathname) {
          this.commonTracker()
        }
        detectUrl = this.isMobile() ? location.href : location.pathname;
      });
    }, true);
  }

  private isMobile(): boolean {
    return this.deviceType === 'M';
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData: JsonObject = super.getCommonTraceData();
    /**
     * event 추가 및 변수 초기화
     */
    this.addEventNodes();
    this.duplicateEvent = [];
    this.searchCount = 0;
    return jsonData;
  }

  /**
   * web/mobile node 추가 감지
   * (mutaion observer로 감지가 불가능하여 setTimeout형식으로 개발)
   */
  private addEventNodes(): void {
    let selector = '';  //document.querySelector로 선택할 요소
    let productName = ''; //selector로 선택한 요소의 productName
    let eventAdd: boolean = false;  //감지한 요소가 있을 경우 true
    const selectList = this.isMobile() ? this.moSelectList : this.webSelectList;
    for (let i = 0, len = selectList.length; i < len; i++) {
      selector = selectList[i].btnSelector;
      productName = selectList[i].productName;
      let selectNode = document.querySelector(selector);
      if (selectNode) {
        eventAdd = true;
        this.conversionTracker(selector, productName);
      } else if (i === len - 1 && !eventAdd) {
        //감지된 요소가 없을 경우 재탐색 시도
        //여러번 시도시에도 없을 경우 중지
        if(this.searchCount++ === 5){
          break;
        }
        setTimeout(() => {
          this.addEventNodes();
        }, 1000);
      }
    }
  }

  /**
   * url 변경시 common tracker 호출
   */
  private commonTracker(): void {
    const tracker: Tracker = Tracker.getInstance(false);
    tracker.executeCommand([
      'send',
      'common',
      this.adverId,
      { 'device': this.deviceType }
    ]);
  }

  /**
   * 요소가 있는 경우 해당 요소에 비쇼핑 tracker 추가
   * @param selector document.querySelector로 선택할 요소
   * @param productName selector로 선택한 요소의 productName
   */
  private conversionTracker(selector: string, productName: string): void {
    if (!this.duplicateEvent.some((str) => str === selector)) {
      this.duplicateEvent.push(selector);
      const tracker: Tracker = Tracker.getInstance(false);
      tracker.executeCommand([
        'create',
        'conversion',
        this.adverId,
        { 'device': this.deviceType, btnSelector: selector, 'convType': 'etc', 'productName': productName }
      ]);
    }
  }
}