/*
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-05-19
 */

import { StringUtil } from "../../lib/common/StringUtil";

/**
 * create on 2022-05-19
 * <p> refererDomain이 referrerDomainList.referrerDomain 값에 해당하는 경우를 체크 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class ReferrerDomain {
  private storage: Storage = window.sessionStorage;
  private readonly sessionKey: string = 'ENP_MEDIA';
  private readonly referrerDomainList: Array<{'referrerDomain','key'}> = [
    {referrerDomain: 'mediacategory.com', key: 'm'},
    {referrerDomain: 'naver.com', key: 'n'},
    {referrerDomain: 't1.daumcdn.net', key: 'k'},
    {referrerDomain: 'kn.acrosspf.com', key: 'a'},
    {referrerDomain: 'ad.doyouad.com', key: 'd'},
    {referrerDomain: 'api.dable.io', key: 't'}
  ];

  /**
   * 해당하는 값이 있을 경우 sessionStorage에 해당 도메인의 키 값 삽입
   */
  public setSessionKey(): void {
    const referrerKey = this.getReferrerKey();
    if (referrerKey) {
      this.storage.setItem(this.sessionKey, referrerKey);
    }
  }

  /**
   * - sessionStorage에서 값을 가져옴
   * 
   * - 2022-05-23
   *    sessionStorage에 값이 없을 경우 getReferrerKey 함수로 2차 검증
   * @returns 값이 있을 경우 찾은 도메인의 키 값
   *          없을 경우 빈('') 값
   */
  public getSessionKey(): string {
    try {
      if (this.storage.getItem(this.sessionKey)) {
        return this.storage.getItem(this.sessionKey)!
      } else if (this.getReferrerKey()) {
        return this.getReferrerKey();
      }
      return StringUtil.EMPTY;
    } catch {
      return StringUtil.EMPTY;
    }
  }

  /**
   * referrer 도메인이 referrerDomainKey.referrerDomain에 해당하는 값이면 해당하는 값을 return
   */
  private getReferrerKey(): string {
    const referrerDoamin = document.referrer;
    const referrerDomainList = this.referrerDomainList;
    for (let i = 0; i < referrerDomainList.length; i++) {
      if (StringUtil.contains(referrerDoamin, new RegExp(referrerDomainList[i].referrerDomain))) {
        return referrerDomainList[i].key;
      }
    }
    return StringUtil.EMPTY;
  }
}