import { NumberUtil } from "../../../lib/common/NumberUtil";
/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:jmlee2@enliple.com">jmlee</a>
 * @since 2020-11-13
 */
import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2020-11-13.
 * <p> influx 광고주 파싱 </p>
 * <p> 다른 메이크샵D4 광고주들과 상이하여 생성하였음 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author jmlee
 */
export class Ohj4865DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getMobilePayConvDataFromBasketPage();
    const parsedProducts: Array<{}> = jsonObj['product'];

    for (let i = 0; i < parsedProducts.length; i++) {
      let price = NumberUtil.parseNumber(document.querySelectorAll('p.SMSbasketPrice')[i]!.textContent!.trim().replace(/[^0-9]/g, ''));

      parsedProducts[i]['price'] = price;
    }

    jsonObj['totalPrice'] = NumberUtil.parseNumber(document.querySelector('div.price-total-info-bottom > p > span.txt-price')!.textContent!.trim());

    return jsonObj;
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    const jsonObj: {} = super.getWebPayConvDataFromBasketPage();

    jsonObj['totalPrice'] = NumberUtil.parseNumber(document.querySelector('div.table-cart > table > tfoot > tr > td > div.tb-right > strong')!.textContent!.trim());

    return jsonObj;
  }
}
