/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-01-10
 */
import {DataParser} from "../DataParser";
import {DeviceType, HostingType} from "../../types/GlobalEnums";
import {JsonObject} from "../../lib/json/JsonObject";
import {GlobalVariables} from "../../types/GlobalVariables";
import {NumberUtil} from "../../lib/common/NumberUtil";
import {InvalidData} from "../../lib/ajax/InvalidData";

/**
 * create on 2020-01-10.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 * todo 리팩토링
 *
 * @version 1.0
 * @author sghwang
 */
export class FirstmallDataParser extends DataParser {
  /* 스크립트로 수집할 정보가 담길 객체의 이름 (광고주가 직접 노출시킨다) */
  protected ENP_OBJECT_NAME = 'ENP_VAR';

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions, HostingType.FIRSTMALL);
  }

  protected parseCategory(currentCategoryCode: string): void {
    const cateLiList = document.querySelectorAll('div.category_depth>ul>li[id]');

    for (let i = 0; i < cateLiList.length; i++) {
      const code = cateLiList[i].id;
      const value = cateLiList[i].firstElementChild!['text'];
      const cate: { code: string; value: string; } = {
        code: code,
        value: value
      };

      this.category[this.categoryHierarchyLevel[i]] = cate.value;
    }
  }

  protected isProductPage(): boolean {
    try {
      return window.location.pathname === '/goods/view';
    } catch (e) {
      return false;
    }
  }

  protected isBasketPage(): boolean {
    try {
      return window.location.pathname === '/order/cart';
    } catch (e) {
      return false;
    }
  }

  /**
   * 모바일 여부 판단
   * @param {{}} commandOptions
   * @return {boolean}
   */
  protected isMobile(commandOptions: {}): boolean {
    try {
      /* 커맨드옵션으로 입력된 값 */
      const deviceFromOpt: string = commandOptions['device'].toUpperCase();

      /* enum을 object로 변환 */
      const deviceTypes = Object(DeviceType);

      /* 입력한 값이 DeviceType에 포함되는지 확인 */
      const isValid = Object.keys(deviceTypes).filter((device: string) =>
        DeviceType[device] === deviceFromOpt).length > 0;

      return isValid
        ? deviceFromOpt === DeviceType.MOBILE
        : false;
    } catch (e) {
      return false;
    }
  }

  protected getCommonTraceData(): JsonObject {
    const parsedData: {} = {
      'adverId': this.adverId
    };

    return new JsonObject(parsedData);
  }

  protected getShopCollectData(): JsonObject {
    this.parseCategory('');

    let productName: string = this.getProductName();
    const newLineIdx: number = productName.indexOf('\n');
    productName = newLineIdx === -1 ? productName : productName.substring(0, newLineIdx);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': window['gl_goods_seq'],
      'productName': productName,
      'price': this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer, .dst_th_size>s>span.num'])
          ? this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer, .dst_th_size>s>span.num'])!.textContent
          : window['gl_goods_price'],
      'productUrl': window.location.href,
      'dcPrice': window['gl_goods_price'],
      'soldOut': window[this.ENP_OBJECT_NAME]['soldOut'],
      'imageUrl': document.querySelectorAll('div#goods_thumbs img')[0]['src'],
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getCartCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getWishCollectData(): JsonObject {
    /* 상품 수집과 동일한 데이터를 전송한다 */
    return this.getShopCollectData();
  }

  protected getConversionData(): JsonObject {
    let parsedData: {};
    if (this.getWebConversionData() instanceof InvalidData) {
      parsedData = this.getMobileConversionData();
    } else {
      parsedData = this.getWebConversionData();
    }

    return new JsonObject(parsedData);
  }

  protected getPayConvDataFromProductPage(): {} {
    const parsedProducts: Array<{}> = [];
    let totalQty = document.querySelector('form[name=goodsForm] input[name^=optionEa]')
      ? NumberUtil.stringToNumber(document.querySelector('form[name=goodsForm] input[name^=optionEa]')!['value'])
      : 1;
    let totalPrice = 0;

    let productName: string = this.getProductName();
    const newLineIdx: number = productName.indexOf('\n');
    productName = newLineIdx === -1 ? productName : productName.substring(0, newLineIdx);

    this.parseCategory('');
    parsedProducts.push({
      'productCode': window['gl_goods_seq'],
      'productName': productName,
      'qty': totalQty,
      'price': this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer, .dst_th_size>s>span.num'])
          ? this.getSelector(['span.consumer>s', 'div.price_wrap span.consumer, .dst_th_size>s>span.num'])!.textContent
          : window['gl_goods_price'],
      'dcPrice': window['gl_goods_price']
    });

    parsedProducts.forEach((obj) => {
      totalPrice += typeof obj['price'] === 'string'
          ? NumberUtil.parseNumber(obj['price'])
          : obj['price'];
    });
    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': totalPrice * totalQty,
      'totalQty': totalQty
    };
  }

  protected getPayConvDataFromBasketPage(): {} {
    let parsedData: {};
    if (this.getWebPayConvDataFromBasketPage() instanceof InvalidData) {
      parsedData = this.getMobilePayConvDataFromBasketPage();
    } else {
      parsedData = this.getWebPayConvDataFromBasketPage();
    }
    return parsedData;
  }

  protected getConvOrderCode(): string {
    const queryString = window.location.search.substring(1);
    const params = queryString.split('&');
    const orderNoObj = {};

    for (let i=0; i<params.length; i++) {
      const key = params[i].split('=')[0];
      const val = params[i].split('=')[1];
      orderNoObj[key] = val;
    }

    return orderNoObj['no'];
  }

  protected getWebConversionData(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td');
        const productName = productInfo[0].textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = this.getSingleProductFrom(productInfo).qty;
        const productPrice = this.getSingleProductFrom(productInfo).price;
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('.price, .settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getMobileConversionData(): {} {
    try {
      return {
        'product': window[this.ENP_OBJECT_NAME]['product'],
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': this.getMobileConvTotalPrice(),
        'totalQty': window[this.ENP_OBJECT_NAME]['totalQty']
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('table.list_table_style>tbody>tr');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('td:not(:first-child)');
        const productName = productInfo[0].textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[1].firstElementChild!.textContent!.trim();
        const productPrice = productInfo[2].textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': this.getConvOrderCode(),
        'totalPrice': NumberUtil.parseNumber(this.getSelector(['.price','.clearbox dd.total > div.ft_red > span'])!.textContent!.match(/^[\d,]+/)![0]),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getMobilePayConvDataFromBasketPage(): {} {
    try {
      const rawProductList = document.querySelectorAll('div.cart_goods');
      const parsedProducts: Array<{}> = [];
      let totalQty = 0;

      for (let i = 0; i < rawProductList.length; i++) {
        const productInfo = rawProductList[i].querySelectorAll('table>tbody>tr>td');
        const productName = productInfo[1].querySelector('div.goods_name')!.textContent!.trim();
        const params = productInfo[0].querySelector('a')!.search.substring(1).split('&');
        const paramObj = {};
        for (let i = 0; i < params.length; i++) {
          const key = params[i].split('=')[0];
          const val = params[i].split('=')[1];
          paramObj[key] = val;
        }

        const productCode = paramObj['no'];
        const productQty = productInfo[1].querySelector('div.cart_ea')!.textContent!.split(':')[1]!.trim().match(/^[\d,]+/)![0];
        const productPrice = productInfo[1].querySelector('span.cart_price_num')!.textContent!.trim().match(/^[\d,]+/)![0];
        const product = {
          'productCode': productCode,
          'productName': productName,
          'qty': productQty,
          'price': productPrice
        };

        parsedProducts.push(product);
        totalQty += NumberUtil.stringToNumber(productQty);
      }

      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'ordCode': '',
        'totalPrice': NumberUtil.parseNumber(
            document.querySelector('span.sum_price>span.settle_price')!.textContent!.match(/^[\d,]+/)![0]
        ),
        'totalQty': totalQty
      };
    } catch (e) {
      return new InvalidData();
    }
  }

  protected getProductName(): string {
    /* 모바일에서 관심상품의 상품명을 가져오지 않도록 하기 위함 */
    if (this.isMobile(this.commandOptions)) {
      if (document.querySelector('div#goods_view_quick span.goods_name')) {
        return document.querySelector('div#goods_view_quick span.goods_name')!.textContent!.trim();
      } else if (document.querySelector('div#goods_view_quick div.goods_name')) {
        return document.querySelector('div#goods_view_quick div.goods_name')!.textContent!.trim();
      } else if (document.querySelector('form[name="goodsForm"] .name')) {
        return document.querySelector('form[name="goodsForm"] .name')!.textContent!.trim()
      } else if(document.querySelector('div.detail_title_area>h3')) {
        return document.querySelector('div.detail_title_area>h3')!.textContent!.trim();
      } else {
        return '';
      }
    } else {
      if (document.querySelector('form[name="goodsForm"] .goods_name')) {
        return document.querySelector('form[name="goodsForm"] .goods_name')!.textContent!.trim();
      } else if(document.querySelector('.goods_title .goods_name h3')) {
        return document.querySelector('.goods_title .goods_name h3')!.firstChild!.textContent!.trim();
      } else if (document.querySelector('form[name="goodsForm"] .name')) {
        return document.querySelector('form[name="goodsForm"] .name')!.textContent!.trim()
      } else if(document.querySelector('div.detail_title_area>h3')) {
        return document.querySelector('div.detail_title_area>h3')!.textContent!.trim();
      } else if (document.querySelector('.goods_name')) {
        return document.querySelector('.goods_name')!.textContent!.trim();
      } else {
        return '';
      }
    }
  }

  protected getSingleProductFrom(singleProductInfo: NodeListOf<HTMLElement>): { qty: string; price: string; } {
    const singleProduct: { qty: string; price: string; } = {
      qty: '',
      price: ''
    };

    singleProduct.qty = singleProductInfo[1].textContent!.trim() === ''
      ? singleProductInfo[3].textContent!.trim()
      : singleProductInfo[1].textContent!.trim();
    singleProduct.price = singleProductInfo[2].textContent!.trim().match(/^[\d,]+/)
      ? singleProductInfo[2].textContent!.trim().match(/^[\d,]+/)![0]
      : singleProductInfo[4].textContent!.trim().match(/^[\d,]+/)![0];

    return singleProduct;
  }

  protected getMobileConvTotalPrice(): number {
    if (document.querySelector('.tahoma')) {
      return NumberUtil.parseNumber(document.querySelector('.tahoma')!.textContent!.match(/^[\d,]+/)![0]);
    } else {
      let totalPrice = 0;
      const products: Array<{}> = window[this.ENP_OBJECT_NAME]['product'];
      for (let i = 0; i < products.length; i++) {
        totalPrice += NumberUtil.parseNumber(products[i]['price']);
      }

      return totalPrice;
    }
  }
}
