/*
 * COPYRIGHT (c) Enliple 2020
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjoh1@enliple.com">sjoh</a>
 * @since 2020-07-08
 */
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2020-07-08.
 * <p> Cosrxebiz 광고주 파싱 </p>
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjoh
 */
export class St699DataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }


    /**
     * 주문완료 웹 버전에서 정보 파싱
     * @return {JsonObject} 파싱된 결과
     */
    protected getWebConversionData(): JsonObject {
        const productInfoLength = document.querySelectorAll('div.table-cart tr td div.tb-left a').length;
        const parsedProducts: Array<{}> = [];
        let totalQty = 0;

        for (let i = 0; i < productInfoLength; i++) {
            const productAnchorElem = document.querySelectorAll('div.table-cart tr td div.tb-left a')[i] as HTMLAnchorElement;
            const qty = (document.querySelectorAll('div.table-cart>table tr td:nth-child(3)')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g,'');

            parsedProducts.push({
                'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
                'productName': productAnchorElem.textContent,
                'qty': qty,
                'price': (document.querySelectorAll('div.table-cart>table tr td:nth-child(4)')[i] as HTMLDivElement).textContent!.trim().replace(/\D/g,''),
            });

            totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
        }

        const parsedData: {} = {
            'product': parsedProducts,
            'adverId': this.adverId,
            'ordCode': document.querySelector('form[name="orderdetail"]')
                ? (document.querySelector('form[name="orderdetail"]') as HTMLFormElement).ordernum.value
                : new QueryStringer(window.location.search.substring(1)).getParam('ordernum'),
            'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.trim().replace(/\D/g,''),
            'totalQty': totalQty.toString()
        };

        return new JsonObject(parsedData);
    }

}
