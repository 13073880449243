/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-04-13
 */
import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";

/**
 * create on 2021-04-13.
 * <p> {@link Cafe24SmartDataParser} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class JjoyincDataParser extends Cafe24SmartDataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }
    
    protected getProductPrice(): { price: number; dcPrice: number; } {
        let price = window['product_price'];
        let dcPrice = window['product_sale_price'] ? window['product_sale_price'] : price;
        return {
            price: price,
            dcPrice: dcPrice

        }
    }
}
