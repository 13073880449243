/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-07-28.
 */
import { NumberUtil } from '../../../lib/common/NumberUtil';
import {MakeshopD4DataParser} from '../MakeshopD4DataParser';

/**
 * create on 2022-07-28.
 * <p> {@link MakeshopD4DataParser} and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class JoamomsDataParser extends MakeshopD4DataParser {
    constructor(adverId: string, commandOptions: {}) {
        super(adverId, commandOptions);
    }

    protected getWebPayConvDataFromBasketPage(): {} {
      const jsonObj = super.getWebPayConvDataFromBasketPage();
      const productList = jsonObj['product'];
  
      for (let i = 0; i < productList.length; i++) {
        productList[i]['productName'] = document.querySelectorAll('div.table-cart tr td div.tb-left a.tbname')[i].textContent!.trim();
      }
  
      return jsonObj;
    }

  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (this.getSelectorAll(['form[name="forms"]>figure>figcaption>p .PHPS_priceSell'])[i] as HTMLParagraphElement).textContent!.trim();
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': NumberUtil.parseNumber(document.querySelector('#total_price strong.txt-price.PHPS_price')!.textContent!),
      'totalQty': totalQty.toString()
    };
  }

}
