/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-04-29
 */
import {GodomallSelfDataParser} from "../GodomallSelfDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2020-04-29.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class LedstudioDataParser extends GodomallSelfDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  /**
   * todo 리팩토링 필요
   * @return {JsonObject}
   */
  protected getConversionData(): JsonObject {
    const jsonObj: JsonObject = super.getConversionData();
    const convData = window[this.ENP_OBJECT_NAME];
    const product = jsonObj.getDataByName('product');

    let totalPrice = NumberUtil.parseNumber(convData['delivery']);

    for (let i = 0; i < product.length; i++) {
      totalPrice += NumberUtil.parseNumber(product[i]['price']) * NumberUtil.parseNumber(product[i]['qty']);
    }

    jsonObj.replaceData('totalPrice', totalPrice);

    return jsonObj;
  }

  protected getMobileShopCollectData(): JsonObject {
    const jsonObj: JsonObject = super.getMobileShopCollectData();
    const priceElements: NodeListOf<HTMLElement> = document.querySelectorAll('.price-area .price-text .goods_price');

    if (priceElements.length > 2) {
      jsonObj.data['price'] = NumberUtil.parseRationalNumber(priceElements[0].querySelector('s')!.textContent!.trim());
      jsonObj.data['dcPrice'] = NumberUtil.parseRationalNumber(priceElements[1].childNodes[2].textContent!.trim());
    } else {
      jsonObj.data['price'] = NumberUtil.parseRationalNumber(document.querySelector('.goods_price')!.childNodes[1].textContent!.trim());
      jsonObj.data['dcPrice'] = 0;
    }

    return jsonObj;
  }

  protected getMobilePayConvDataFromProductPage(): {} {
    const jsonObj = super.getMobilePayConvDataFromProductPage();

    const priceElements: NodeListOf<HTMLElement> = document.querySelectorAll('.price-area .price-text .goods_price');
    if (priceElements.length > 2) {
      jsonObj['product'][0]['price'] = NumberUtil.parseNumber(priceElements[0].querySelector('s')!.textContent!.trim());
      jsonObj['product'][0]['dcPrice'] = NumberUtil.parseNumber(priceElements[1].childNodes[2].textContent!.trim())
    } else {
      jsonObj['product'][0]['price'] = NumberUtil.parseRationalNumber(document.querySelector('.goods_price')!.childNodes[1].textContent!.trim());
      jsonObj['product'][0]['dcPrice'] = 0;
    }

    const qtyInputElement: HTMLInputElement = (document.getElementById('ea') as HTMLInputElement);
    jsonObj['product'][0]['qty'] = NumberUtil.parseNumber(qtyInputElement.value.trim());
    jsonObj['totalPrice'] = NumberUtil.parseRationalNumber(qtyInputElement['dataset']['price']!) * NumberUtil.parseNumber(qtyInputElement.value.trim());

    return jsonObj;
  }
}
