/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-03-16
 */

import {JsonObject} from "../../../lib/json/JsonObject";
import { SelfHostedDataParser } from "../SelfHostedDataParser";

/**
 * create on 2022-03-16
 * <p> nstationmall.com </p>
 * 내셔널지오그래픽(motomoto)
 * 내셔널지오그래픽_키즈(thenature)
 * NFL (nflstyle)
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
 export abstract class MotomotoDataParser extends SelfHostedDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getShopCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.addData('siteCode', this.getSiteCodeByAdverId());
    return jsonObj;
  }

  private getSiteCodeByAdverId(): string {
    switch (this.adverId) {
      case 'motomoto':
        return 'f3fb7e5be318e165473334140faba8fe';
      case 'thenature':
        return '29e71373a5f1a350949dafdecf04a55f';
      case 'nflstyle':
        return 'c179c85069ed45d8d916aab4cfbe4f21';
      case 'junmart1':
        return 'c2ff4e7a3185019bd9abe238e8b4c4de';
      default:
        return '';
    }
  }
}