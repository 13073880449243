/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {ValidationRule} from './ValidationRule';
import {DataInvalidAlarm} from "../../lib/alarm/DataInvalidAlarm";

/**
 * create on 2019-09-30.
 * <p> 검증 수행 </p>
 * <p> {@link FormatValidator} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class ValidationRuleImpl implements ValidationRule {
  /* 검증을 수행할 객체 내 property의 이름 */
  private readonly propertyName: string;

  protected constructor(propertyName: string) {
    this.propertyName = propertyName;
  }

  /**
   * 검증 수행
   * @param {{}} data - 검증 대상 데이터
   * @return {boolean}  - 검증된 데이터
   */
  validate(data: {}): boolean {
    /* 존재하는 property만 검증을 수행 */
    if (this.hasProperty(data, this.propertyName)) {
      try {
        data[this.propertyName] = this.applyRule(data[this.propertyName]);
        return true;
      } catch (e) {
        const alarm: DataInvalidAlarm | null = DataInvalidAlarm.getInstance();
        if(alarm) alarm.addExtraData(this.propertyName, data[this.propertyName]);

        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * 규칙 적용. 검증할 규칙을 이 메소드로 구현.
   * @param property  - 검증 대상이 되는 객체의 프로퍼티.
   * @return {any}  - 검증된 프로퍼티.
   */
  protected abstract applyRule(property: any): any;

  /**
   * 데이터에 특정 이름의 property가 존재하는지 확인
   * @param {{}|undefined} data - 대상 데이터
   * @param {string} propertyName
   * @return {boolean}
   */
  private hasProperty(data: {} | undefined, propertyName: string): boolean {
    return typeof data === 'undefined'
      ? false
      : Object.getOwnPropertyNames(data).filter(name => name === propertyName)
          .length > 0;
  }
}
