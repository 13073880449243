/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-17
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";

export class StyletibaDataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      return window.location.pathname.indexOf('/m/') === 0
          ? document.querySelector('.SMS_ShopDetail_layout_right .name b')!.textContent!.trim()
          : document.querySelector('#div_content #sangse_name h3 b')!.textContent!.trim();
    } catch (e) {
      return super.getProductName();
    }
  }

  protected getWebPayConvDataFromBasketPage(): {} {
    let superData = super.getWebPayConvDataFromBasketPage();
    superData['totalPrice'] = document.querySelector('#cartWrap tfoot div.tb-right strong') ? 
      document.querySelector('#cartWrap tfoot div.tb-right strong')!.textContent!.replace(/\D/g, '')
      : superData['totalPrice'];
    
    return superData;
  }


  /**
   * 장바구니 페이지 웹버전에서 전환 데이터 파싱
   * @return {{}} 파싱 결과
   */
  protected getMobilePayConvDataFromBasketPage(): {} {
    const parsedProducts: Array<{}> = [];
    const productAmount = document.querySelectorAll('form[name="forms"]').length;

    let totalPrice = 0;
    let totalQty = 0;

    for (let i = 0; i < productAmount; i++) {
      const rawPrice = (
          this.getSelectorAll(['form[name="forms"]>figure>figcaption>p.SMSbasketPrice'])[i] as HTMLParagraphElement
        ).textContent!.split(':')[1]!.trim().slice(0, -1);
      const rawQty = (document.querySelectorAll('form[name="forms"]>input[name="orgamount"]')[i] as HTMLInputElement).value;

      const price = NumberUtil.parseNumber(rawPrice);
      const qty = NumberUtil.parseNumber(rawQty, NumberUtil.NUM_TYPE.INTEGER);

      totalPrice += price;
      totalQty += qty;

      parsedProducts.push({
        'productCode': (document.querySelectorAll('form[name="forms"]>input[name="branduid"]')[i] as HTMLInputElement).value,
        'productName': (document.querySelectorAll('p.pname>a:first-of-type, form[name="forms"]>figure p.pname input+a')[i] as HTMLAnchorElement).textContent!.trim(),
        'price': price / qty,
        'qty': qty
      });
    }

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': document.querySelector('.price-total-info-bottom span.txt-price') ? 
        document.querySelector('.price-total-info-bottom span.txt-price')!.textContent!.replace(/\D/g, '')
        : totalPrice.toString(),
      'totalQty': totalQty.toString()
    };
  }

}