/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2020-10-08
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {GlobalVariables} from "../../../types/GlobalVariables";

/**
 * create on 2020-10-08
 * <p>  </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class Onlife0903DataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getWebShopCollectData(formElement: HTMLFormElement): JsonObject {
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    const parsedData: {} = {
      'adverId': this.adverId,
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': document.querySelector('div.item_detail_tit h3')!.textContent!.trim(),
      'price': priceObj['price'],
      'productUrl': window.location.href,
      'dcPrice': priceObj['dcPrice'],
      'soldOut': this.getRefinedSoldOut(window[this.ENP_OBJECT_NAME]['soldOut']),
      'imageUrl': (document.querySelector('div.image > img') as HTMLImageElement).src,
      'topCategory': this.category['topCategory']
          ? this.category['topCategory']
          : GlobalVariables.unknownCategory,
      'firstSubCategory': this.category['firstSubCategory'],
      'secondSubCategory': this.category['secondSubCategory'],
      'thirdSubCategory': this.category['thirdSubCategory']
    };

    return new JsonObject(parsedData);
  }

  protected getWebPayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const parsedProducts: Array<{}> = [];
    this.parseCategory(formElement!['cateCd'].value);
    const priceObj = this.getProductPriceObj(formElement);

    parsedProducts.push({
      'productCode': new QueryStringer(window.location.search.substring(1)).getParam('goodsNo'),
      'productName': document.querySelector('div.item_detail_tit h3')!.textContent!.trim(),
      'price': priceObj['price'],
      'dcPrice': priceObj['dcPrice'],
      'qty': this.getParsedTotalQty(formElement),
    });

    return {
      'product': parsedProducts,
      'adverId': this.adverId,
      'totalPrice': formElement!['set_total_price'].value,
      'totalQty': this.getParsedTotalQty(formElement)
    };
  }

  protected getMobileShopCollectData(formElement: HTMLFormElement): JsonObject {
    const jsonObj: JsonObject = super.getMobileShopCollectData(formElement);
    jsonObj.data['productName'] = document.querySelector('div.item_detail_tit h3')!.textContent!.trim();
    return jsonObj;
  }

  protected getMobilePayConvDataFromProductPage(formElement: HTMLFormElement): {} {
    const jsonObj: {} = super.getMobilePayConvDataFromProductPage(formElement);
    jsonObj['product'][0]['productName'] = document.querySelector('div.item_detail_tit h3')!.textContent!.trim();
    return jsonObj;
  }
}
