/*
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2022-01-19
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2022-01-19.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class SoonsoojaaDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductPrice(): { price: number; dcPrice: number } {
    let superData = super.getProductPrice();
    try {
      if (this.isMobile(this.commandOptions)) {
        superData['dcPrice'] = NumberUtil.parseNumber((document.querySelector('meta[property="product:sale_price:amount"]') as HTMLMetaElement)['content']);
      }

      return superData;
    } catch (e) {
      return superData;
    }
  }
}
