/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">mgpark</a>
 * @since 2021-03-31
 */

import { Cafe24SmartDataParser } from "../Cafe24SmartDataParser";
import { NumberUtil } from "../../../lib/common/NumberUtil";
import { JsonObject } from "../../../lib/json/JsonObject";
import { QueryStringer } from "../../../lib/url/QueryStringer";

/**
 * create on 2021-03-31.
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class LolotenDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    if (this.isMobile(this.commandOptions)){
      return super.getConversionData_prev();
    }

    const rawProduct = document.querySelectorAll('#frm_order_result > div > div.orderListArea table > tbody > tr.xans-record-');

    const parsedProducts: Array<{}> = [];
    const parsedTotalPrice: number = this.getParsedTotalPrice();
    let totalQty = 0;

    for (let i = 0; i < rawProduct.length; i++) {
      
      parsedProducts.push({
        'productCode': new QueryStringer(rawProduct[i].querySelector('a')!.search.substring(1)).getParam('product_no'),
        'productName': rawProduct[i].querySelector('strong.name')!.textContent!.trim(),
        'qty': NumberUtil.parseNumber(rawProduct[i].querySelector('td:nth-child(4)')!.textContent!),
        'price': NumberUtil.parseNumber(rawProduct[i].querySelector('td:nth-child(3) strong')!.textContent!)
      });

      totalQty += NumberUtil.parseNumber(rawProduct[i].querySelector('td:nth-child(4)')!.textContent!);
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(window.location.search.substring(1)).getParam('order_id'),
      'totalPrice': NumberUtil.parseNumber(document.querySelector('div.totalArea tbody td:nth-child(3) strong span')!.textContent!),
      'totalQty': totalQty
    };

    return new JsonObject(parsedData);
  }
}
