/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-11-01
 */
import {InvalidValueError} from '../error/InvalidValueError';
import {HostingType} from '../types/GlobalEnums';
import {ConfigStorage} from "./ConfigStorage";
import {AdvertiserConfig} from "./AdvertiserConfig";

/**
 * create on 2019-11-01.
 * <p> json 파일에 정의된 광고주 정보를 객체로 맵핑 시켜주는 클래스 </p>
 * <p> json 파일을 가져오는 CDN 호출을 최소화 하기 위해 singleton 패턴 적용 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class AdverConfigJsonMapper {
  /* 인스턴스 */
  private static instance: AdverConfigJsonMapper;

  /**
   * 호스팅사 별 기본 클래스 이름
   * 2021-11-08
   * 네이버 스마트 스토어로 hostingType 설정 시 독립몰을 바라보도록 수정 ('../types/GlobalEnums')
   */
  private static readonly HOSTING_DEFAULT_CLASS_NAME: {
    dataParser: { [hostingType in HostingType]: string };
    dataValidator: { [hostingType in HostingType]: string };
    categoryParser: { [hostingType in HostingType]: string };
  } = {
    dataParser: {
      'cafe24_smart': 'Cafe24SmartDataParser',
      'makeshop_d2': 'MakeshopD2DataParser',
      'makeshop_d4': 'MakeshopD4DataParser',
      'firstmall': 'FirstmallDataParser',
      'godomall_rent': 'GodomallRentDataParser',
      'godomall_self': 'GodomallSelfDataParser',
      'imweb': 'ImwebDataParser',
      'self_hosted': 'SelfHostedDataParser',
      'etc': 'EtcDataParser',
      'naver_smart': 'SelfHostedDataParser',
      'shopby': 'SelfHostedDataParser'
    },
    dataValidator: {
      'cafe24_smart': 'Cafe24SmartDataValidator',
      'makeshop_d2': 'MakeshopD2DataValidator',
      'makeshop_d4': 'MakeshopD4DataValidator',
      'firstmall': 'FirstmallDataValidator',
      'godomall_rent': 'GodomallRentDataValidator',
      'godomall_self': 'GodomallSelfDataValidator',
      'imweb': 'ImwebDataValidator',
      'self_hosted': 'SelfHostedDataValidator',
      'etc': 'EtcDataValidator',
      'naver_smart': 'SelfHostedDataValidator',
      'shopby': 'SelfHostedDataParser'
    },
    categoryParser: {
      'cafe24_smart': 'Cafe24SmartCategoryParser',
      'makeshop_d2': 'MakeshopD2CategoryParser',
      'makeshop_d4': 'MakeshopD4CategoryParser',
      'firstmall': 'FirstmallDataCategoryParser',
      'godomall_rent': 'GodomallRentCategoryParser',
      'godomall_self': 'GodomallSelfCategoryParser',
      'imweb': 'ImwebCategoryParser',
      'self_hosted': 'SelfHostedCategoryParser',
      'etc': 'EtcCategoryParser',
      'naver_smart': 'SelfHostedCategoryParser',
      'shopby': 'SelfHostedDataParser'
    }
  };

  /* 같은 도메인을 사용하는 광고주 */
  private readonly sameDomainAdverIdlist = [
    'lotteon', 'lotteshop', 'lohbs2', 'lottesuper', 'wonandwon', 'parkga', 'wisihealthy', 'samjin1', 'toptoon', 'toptoon2', 'ssgcom', 'emssg', 'ssgmall', 'emartmall1',
    'mobonbranding', 'adhubbranding', 'mobon', 'enp_brjang', 
    'motomoto', 'thenature', 'nflstyle', 'junmart1',
    'aioinc', 'sogreat', 'PTKOREA23' , 'kolonmall', 'tproject', 'mproject'
  ];

  private readonly adverId: string;
  private readonly adverConfig: AdvertiserConfig;
  private readonly hostingType: HostingType;
  private readonly siteCode: string;
  private readonly parserClass: { className: string; classSubPath: string };
  private readonly validatorClass: { className: string; classSubPath: string };
  private readonly categoryParserClass: { className: string; classSubPath: string };

  private constructor(adverId: string) {
    this.adverId = adverId;
    this.adverConfig = this.parseAdverConfigFromJson(adverId);
    this.hostingType = this.parseHostingType();
    this.siteCode = this.parseSiteCode();
    this.parserClass = this.parseParserClass();
    this.validatorClass = this.parseValidatorClass();
    this.categoryParserClass = this.parseCategoryParserClass();
  }

  static getInstance(adverId: string): AdverConfigJsonMapper {
    if (!AdverConfigJsonMapper.instance) {
      AdverConfigJsonMapper.instance = new AdverConfigJsonMapper(adverId);
    }

    return AdverConfigJsonMapper.instance;
  }

  getParserClassInfo(): { className: string; classSubPath: string } {
    return this.parserClass;
  }

  /**
   * 광고주에 해당되는 <code>validator</code> 클래스의 정보를 가져온다
   * @return {{className: string, classSubPath: string}}  - 클래스 정보
   */
  getValidatorClassInfo(): { className: string; classSubPath: string } {
    return this.validatorClass;
  }

  /**
   *
   * @return {{className: string, classSubPath: string}}
   */
  getCategoryParserClassInfo(): { className: string; classSubPath: string } {
    return this.categoryParserClass;
  }

  /**
   * 호스팅사 반환.
   * @return {HostingType}  - 호스팅사
   */
  getHostingType(): HostingType {
    return this.hostingType;
  }

  /**
   * <code>siteCode</code> 반환.
   * @return {string} - <code>siteCode</code>
   */
  getSiteCode(): string {
    return this.siteCode;
  }

  /**
   * CDN의 json 파일에서 광고주 설정 정보를 객체로 파싱.
   * (CDN의 json 파일에서 먼저 가져오고, 파일이 없거나 잘못된 경우 프로젝트 내 config.json에서 가져온다)
   * @param {string} adverId  - 클라이언트 코드에서 넘어온 광고주 ID
   * @return {{}} - 광고주 설정 정보
   */
  private parseAdverConfigFromJson(adverId: string): AdvertiserConfig {
    try {
      /* 같은 도메인을 사용하지 않는 광고주 여부 */
      const notSameDomain = this.sameDomainAdverIdlist.indexOf(adverId) === -1;

      /* id가 분리된 솔루션 여부 */
      if (notSameDomain) {
        return new ConfigStorage().getConfig(adverId);
      } else {
        return this.getConfigFromInternalJson(adverId);
      }
    } catch (e) {
      return this.getConfigFromInternalJson(adverId);
    }
  }

  /**
   * 프로젝트 내 config.json에서 광고주 설정 정보를 가져온다
   * @param {string} adverId  - 광고주 id
   * @throws InvalidValueError  - JSON 객체 내에 <b>광고주 ID</b>에 해당하는 프로퍼티가 없을 때
   * @return {AdvertiserConfig} - 광고주 설정 정보
   */
  private getConfigFromInternalJson(adverId: string): AdvertiserConfig {
    const advertiserConfig: AdvertiserConfig = require('../../resource/advertiser.config.json')[adverId];
    if (advertiserConfig) {
      return advertiserConfig;
    } else {
      throw new InvalidValueError('Advertiser ID', adverId);
    }
  }

  /**
   * json 파일에서 광고주의 호스팅사를 파싱
   * @return {HostingType}  - 호스팅사
   * @throws InvalidValueError  - JSON 객체 내에 <b>광고주 ID</b>에 해당하는 프로퍼티가 없을 때
   */
  private parseHostingType(): HostingType {
    try {
      return this.adverConfig['hostingType'];
    } catch (e) {
      throw new InvalidValueError('Advertiser ID', this.adverId);
    }
  }

  /**
   * json 파일에서 광고주의 <code>siteCode</code>를 파싱
   * @return {string} - <code>siteCode</code>
   * @throws InvalidValueError  - JSON 객체 내에 <b>광고주 ID</b>에 해당하는 프로퍼티가 없을 때
   */
  private parseSiteCode(): string {
    try {
      return this.adverConfig.siteCode;
    } catch (e) {
      throw new InvalidValueError('Advertiser ID', this.adverId);
    }
  }

  private parseParserClass(): { className: string; classSubPath: string } {
    const className: string = this.getParserClassName();
    const classSubPath: string = this.getParserClassSubPath(className);
    return {
      className,
      classSubPath
    };
  }

  private getParserClassName(): string {
    return this.getClassName(this.hostingType, 'dataParser');
  }

  private getParserClassSubPath(parserClassName: string): string {
    return this.getClassSubPath(
      this.hostingType,
      ConfigClassType.dataParser,
      parserClassName
    );
  }

  /**
   * 광고주 ID에 해당하는 {@link DataValidator} 클래스 정보를 반환.
   * @return {{className: string, classSubPath: string}} - 클래스 정보
   */
  private parseValidatorClass(): { className: string; classSubPath: string } {
    const className: string = this.getValidatorClassName();
    const classSubPath: string = this.getValidatorClassSubPath(className);

    return {
      className,
      classSubPath
    };
  }

  /**
   * 광고주 ID에 해당되는 <code>validator</code> 클래스의 이름을 가져온다
   * @return {string} - 클래스 이름
   */
  private getValidatorClassName(): string {
    return this.getClassName(this.hostingType, 'dataValidator');
  }

  /**
   * 광고주 ID에 해당되는 <code>validator</code> 클래스의 SubPath를 가져온다
   * @param {string} validatorClassName - validator 클래스 이름
   * @return {string} - 경로
   */
  private getValidatorClassSubPath(validatorClassName: string): string {
    return this.getClassSubPath(
      this.hostingType,
      ConfigClassType.dataValidator,
      validatorClassName
    );
  }

  /**
   * 광고주 ID에 해당하는 {@link CategoryParser} 클래스 정보를 반환.
   * @return {{className: string, classSubPath: string}} - 클래스 정보
   */
  private parseCategoryParserClass(): { className: string; classSubPath: string; } {
    const className: string = this.getCategoryParserClassName();
    const classSubPath: string = this.getCategoryParserClassSubPath(className);

    return {
      className,
      classSubPath
    };
  }

  /**
   * 광고주 ID에 해당되는 <code>categoryParser</code> 클래스의 이름을 가져온다
   * @return {string} - 클래스 이름
   */
  private getCategoryParserClassName(): string {
    return this.getClassName(this.hostingType, 'categoryParser');
  }

  /**
   * 광고주 ID에 해당되는 <code>categoryParser</code> 클래스의 SubPath를 가져온다
   * @param {string} categoryParserClassName - categoryParser클래스 이름
   * @return {string} - 경로
   */
  private getCategoryParserClassSubPath(categoryParserClassName: string): string {
    return this.getClassSubPath(
      this.hostingType,
      ConfigClassType.categoryParser,
      categoryParserClassName
    );
  }

  /**
   * 등록된 json 파일을 이용해 <code>parser</code> 또는 <code>validator</code> 클래스의 이름을 가져온다.
   * json 객체에 광고주에 해당하는 클래스의 이름이 정의되어 있으면 그 클래스를,
   * 그렇지 않으면 호스팅사에 해당하는 클래스의 이름을 반환한다.
   *
   * @param {HostingType} hostingType - 호스팅사
   * @param {string} propertyName - json 파일에서 읽을 클래스에 해당하는 프로퍼티의 이름
   * @return {string}
   * <p>광고주에 해당하는 클래스 이름 - json 파일에 클래스 이름이 정의되어 있는 경우</p>
   * <p>호스팅사 해당하는 클래스 이름 - json 파일에 클래스 이름이 정의되어 있지 않은 경우</p>
   */
  private getClassName(hostingType: HostingType, propertyName: string): string {
    const classNameFromJson: string = this.adverConfig[propertyName];
    return classNameFromJson
      ? classNameFromJson
      : AdverConfigJsonMapper.HOSTING_DEFAULT_CLASS_NAME[propertyName][hostingType];
  }

  /**
   * json 파일에서 가져온 정보들을 이용해 parser, validator 클래스의 SubPath를 반환.
   * json에서 클래스 정보가 비어 있으면 호스팅사에 해당하는 클래스의 경로를 반환한다.
   * <b>NOTE : </b>반환되는 경로는 각 <code>parser</code>, <code>validator</code> 디렉토리 내의 경로이다.
   *
   * @param {HostingType} hostingType - json에서 가져온 호스팅사
   * @param {ConfigClassType} configClassType - 클래스 타입(<code>parser</code> or <code>validator</code>)
   * @param {string} className  - json에서 읽은 클래스의 이름
   * @return {string}
   * <p>광고주에 해당하는 클래스의 SubPath - json 파일에 클래스 이름이 정의되어 있는 경우</p>
   * <p>호스팅사 해당하는 클래스의 SubPath - json 파일에 클래스 이름이 정의되어 있지 않은 경우</p>
   */
  private getClassSubPath(
    hostingType: HostingType,
    configClassType: ConfigClassType,
    className: string
  ): string {
    return AdverConfigJsonMapper.HOSTING_DEFAULT_CLASS_NAME[configClassType][hostingType] === className
      ? './hosting/'
      : './hosting/advertisers/';
  }
}

/* json 파일에서 읽어올 클래스의 종류 */
enum ConfigClassType {
  dataParser = 'dataParser',
  dataValidator = 'dataValidator',
  categoryParser = 'categoryParser'
}
