/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-03-10
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";

/**
 * create on 2021-03-10
 * <p> myownist - ownist.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class MyownistDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData_prev(): JsonObject {
    let totalQty = 0;
    const parsedProducts: Array<{}> = [];

    const rawProductDataArr: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.orderArea .xans-order-normalresultlist .prdBox .thumbnail a');
    for (let i = 0; i < rawProductDataArr.length; i++) {
      const productCode: string = new QueryStringer(rawProductDataArr[i].search.substring(1)).getParam('product_no');
      const qty: number = NumberUtil.parseNumber(document.querySelectorAll('.orderArea .xans-order-normalresultlist .prdBox .description li:nth-child(4)')[i].textContent!.split(':')[1].trim());

      parsedProducts.push({
        'productCode': productCode,
        'productName': document.querySelectorAll('.ec-product-name')[i].textContent!.trim(),
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.orderArea .xans-order-normalresultlist .prdBox .description li:nth-child(5) span:first-child')[i].textContent!.split(':')[1].trim())
      });
      totalQty += qty;
    }

    return new JsonObject({
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': new QueryStringer(location.search.substring(1)).getParam('order_id'),
      'totalPrice': NumberUtil.parseRationalNumber(document.querySelector('.resultInfo tbody tr:nth-child(2) td > span')!.childNodes[0].textContent!.trim()),
      'totalQty': totalQty
    });
  }
}
