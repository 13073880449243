/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-07-09
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";

/**
 * create on 2021-07-09
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export class Linkyoues91DataParser extends MakeshopD4DataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductName(): string {
    try {
      if (window['product_name']) {
        return window['product_name'];
      } else {
        return window.location.pathname.indexOf('/m/') === 0
            ? document.querySelector('#contents > main > section h2')!.textContent!.trim()
            : document.querySelector('#form1 > div > div.info_inner > h3')!.textContent!.trim();
      }
    } catch (e) {
      return '';
    }
  }
}