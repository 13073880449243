/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import {DataValidator} from '../DataValidator';
import {HostingType} from '../../types/GlobalEnums';
import {StringUtil} from '../../lib/common/StringUtil';

/**
 * create on 2019-08-02.
 * <p> 카페24 스마트를 이용하는 광고주에 대한 데이터 검증 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class Cafe24SmartDataValidator extends DataValidator {
  protected constructor() {
    super(HostingType.CAFE24_SMART);
  }

  /**
   * 광고주의 소스를 수정하는 에디터 페이지인지 확인
   * @return {boolean}
   * <p><code>true</code> - 에디터 페이지</p><p><code>false</code> - 에디터 페이지 아님</p>
   */
  protected fromEditorPage(): boolean {
    /* 서브 도메인이 "skin-"으로 시작하면 에디터 페이지 */
    const fromEditorPage = window.location.hostname.indexOf('skin-') === 0;
    /*
     * 카페24 관리자 페이지의 미리보기 팝업이나 페이지 (상품코드가 "-"로 시작)
     * url 샘플 : http://dabainsang.cafe24.com/disp/admin/shop1/product/ProductPopupPreview
     */
    const containsAdmin = (location.hostname.indexOf('.cafe24.com') > -1 && location.pathname.indexOf('/admin') > -1);
    const startsWithShop = location.hostname.indexOf('shop1.') === 0;
    const fromPreview = containsAdmin || startsWithShop;

    return fromEditorPage || fromPreview;
  }

  /**
   * 이전 페이지(referrer)가 상품상세페이지 인지 확인
   * 외부키워드의 경우 이전페이지가 상품상세페이지인 경우 전송을 안한다.
   * @return {boolean}
   * <p><code>true</code> - 상세페이지 </p><p><code>false</code> - 상세페이지 아님</p>
   */
  protected chkAfterPageIsDetail(): boolean {
    try {
      const urlSplitArr:string[] = StringUtil.getPathNameFromUrl(document.referrer).substr(1).split('/');
      return urlSplitArr[0] === 'product' && urlSplitArr[1].search(/search(.*).html|list(.*).html/) < 0;
    } catch (error) {
      return false;
    }
  }

  /**
   * COLLECT 이벤트에 대한 검증.
   * super 클래스의 검증을 거친 후에 dcPrice가 price보다 크면 price를 dcPrice로 초기화.
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected collectValidate(data: {}): {} {
    /* 검증을 거친 데이터 */
    let validatedData = super.collectValidate(data);

    /* price가 0인지 */
    const zeroPrice: boolean = validatedData['price'] === 0;
    /* dcPrice가 price보다 큰지 */
    const greaterThanPrice: boolean = validatedData['dcPrice'] > validatedData['price'];

    if (zeroPrice && greaterThanPrice) {
      validatedData['price'] = validatedData['dcPrice'];
    }

    return validatedData;
  }
  /**
   * CART 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected cartValidate(data: {}): {} {
    return this.collectValidate(data);
  }

  /**
   * WISH 이벤트에 대한 검증
   * @param {{}} data - 검증할 데이터
   * @return {{}} - 검증된 결과 데이터
   */
  protected wishValidate(data: {}): {} {
    return this.collectValidate(data);
  }
}
