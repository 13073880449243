/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee@enliple.com">yjlee</a>
 * @since 2023-05-18
 */

import { JsonObject } from "../../../lib/json/JsonObject";
import {SelfHostedDataParser} from "../SelfHostedDataParser";

/**
 * @version 1.0
 * @author yjlee
 */
export abstract class AdefwizwiduvDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCartCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.addData('cartType', 'ownCart');
    return jsonObj;
  }
  protected getWishCollectData(): JsonObject {
    const jsonObj = super.getShopCollectData();
    jsonObj.addData('cartType', 'ownWish');
    return jsonObj;
  }
}
