/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:mgpark@enliple.com">mgpark</a>
 * @since 2021-06-17
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {NumberUtil} from "../../../lib/common/NumberUtil";

/**
 * create on 2021-06-17
 * <p> jbros - jbros.co.kr </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author mgpark
 */
export class JbrosDataParser extends MakeshopD4DataParser {
  protected getWebConversionData(): JsonObject {
    const jsonData = super.getWebConversionData();
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    const productAnchorList: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.table-cart tbody tr a')
    for (let i = 0; i < productAnchorList.length; i++) {
      const qty = NumberUtil.parseNumber(document.querySelectorAll('.table-cart tbody tr td:nth-child(5) div')[i]!.textContent!.trim());

      parsedProducts.push({
        'productCode': new QueryStringer(productAnchorList[i].search.substring(1)).getParam('branduid'),
        'productName': productAnchorList[i].textContent,
        'qty': qty,
        'price': NumberUtil.parseRationalNumber(document.querySelectorAll('.table-cart tbody tr td:nth-child(6) div')[i]!.textContent!.trim()),
      });

      totalQty += qty;
    }

    jsonData.addData('product', parsedProducts);
    jsonData.addData('totalQty', totalQty);
    return jsonData;
  }
}
