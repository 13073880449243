/*
 * COPYRIGHT (c) Enliple 2023
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2023-06-07
 */

import {Cafe24SmartDataParser} from "../Cafe24SmartDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";
import { NumberUtil } from "../../../lib/common/NumberUtil";

/**
 * create on 2023-06-07
 * <p> verabio 데이터파서 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class VerabioDataParser extends Cafe24SmartDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }
  protected getProductPrice(): { price: number; dcPrice: number } {
    try {
      let superData = super.getProductPrice();
      if (this.isMobile(this.commandOptions)) {
        superData['dcPrice'] =NumberUtil.parseNumber( document.querySelector('#product_price')!['value']);
      }
      else{
        superData['dcPrice'] = NumberUtil.parseNumber(document.querySelector('#span_product_price_text')!.textContent!.replace(/\D/g,''));
        } 
        return superData;
    } catch (e) {
      return super.getProductPrice();
    }
  }
}