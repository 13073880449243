/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019-12-23
 */
/**
 * create on 2019-12-23.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>실패한
 *
 * @version 1.0
 * @author sghwang
 */
export class InvalidData {

}
