/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-09-13
 */

import {MakeshopD4DataParser} from "../MakeshopD4DataParser";
import {NumberUtil} from "../../../lib/common/NumberUtil";
import {QueryStringer} from "../../../lib/url/QueryStringer";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2022-09-13
 * <p> amicogen - http://binibani.com/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class BinibaniDataParser extends MakeshopD4DataParser {

  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getProductTargetObj(): {} {
    if(!this.isMobile(this.commandOptions)){
      return window['mi_conv']['product'];
    } else {
      return super.getProductTargetObj();
    }
  }

  protected getShopCollectData(): JsonObject {
    if(this.isMobile(this.commandOptions)){
    const jsonObj: JsonObject = super.getShopCollectData();
    jsonObj.data['productName'] = document.querySelector('dd span.tit')!.textContent!.trim();
    return jsonObj; 
    }else{
    let parsedData: {};
    let soldOut = "N";
      const targetObj: {} = this.getProductTargetObj();
      if (document.querySelectorAll('tr td font a img').length==1) {
        soldOut = "Y";
      }
      parsedData = {
        'adverId': this.adverId,
        'productCode': targetObj['no'],
        'productName': targetObj['name'],
        'price': NumberUtil.parseNumber(targetObj['price']),
        'productUrl': window.location.href,
        'dcPrice': NumberUtil.parseNumber(targetObj['price']),
        'soldOut': soldOut,
        'imageUrl': targetObj['img_link'],
        'secondImageUrl': targetObj['secondImageUrl'],
        'thirdImageUrl': targetObj['thirdImageUrl'],
        'fourthImageUrl': targetObj['fourthImageUrl'],
        'topCategory': this.category['topCategory'],
        'firstSubCategory': this.category['firstSubCategory'],
        'secondSubCategory': this.category['secondSubCategory'],
        'thirdSubCategory': this.category['thirdSubCategory']
      };
      return new JsonObject(parsedData);
      }
  }

     protected getMobilePayConvDataFromBasketPage(): {} {
      const parsedProducts: Array<{}> = [];
      const productAmount = document.querySelectorAll('tbody tr td  h4.font_basketA a');
  
      let totalPrice = 0;
      let totalQty = 0;
  
      for (let i = 0; i < productAmount.length; i++) {
        const productAnchorElem = productAmount[i] as HTMLAnchorElement;
        const rawPrice =  NumberUtil.parseNumber(document.querySelectorAll('td dl dd.font_basketD')[i].textContent!.replace(/\D/g,''));
        const rawQty =  NumberUtil.parseNumber((document.querySelectorAll('dd div.input01 input')[i] as HTMLInputElement).value);
  
        const price = rawPrice/rawQty;
        const qty = rawQty;
  
        totalPrice += rawPrice;
        totalQty += qty;
  
        parsedProducts.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': this.getMobileConversionProdtName(productAnchorElem),
          'price': price,
          'qty': qty
        });
      }
  
      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'totalPrice': document.querySelector('div.summary p')!.textContent!.replace(/\D/g,''),
        'totalQty': totalQty.toString()
      };
    }

    protected getWebPayConvDataFromBasketPage(): {} {
      const parsedProducts: Array<{}> = [];
      const productAmount =document.querySelectorAll('tbody tr td div#mitalk_list div a');
     
      let totalPrice = 0;
      let rawTotalPrice = document.querySelector('table tbody tr:nth-child(2) td b')!.textContent!.split('=');
      if(rawTotalPrice.length>1){
        totalPrice = NumberUtil.parseNumber(rawTotalPrice[1].replace(/\D/g,''));
      }else{
        totalPrice = NumberUtil.parseNumber(rawTotalPrice[0].replace(/\D/g,''));
      }
      let totalQty = 0;

  
      for (let i = 0; i < productAmount.length; i++) {
        const productAnchorElem = productAmount[i] as HTMLAnchorElement;
        const rawPrice =  NumberUtil.parseNumber(document.querySelectorAll('td font span')[i].textContent!.replace(/\D/g,''));
        const rawQty =  NumberUtil.parseNumber((document.querySelectorAll('tr td input[name="amount"]')[i] as HTMLInputElement).value);
        const price = rawPrice/rawQty;
        const qty = rawQty;
  
        totalQty += qty;
  
        parsedProducts.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': this.getMobileConversionProdtName(productAnchorElem),
          'price': price,
          'qty': qty
        });
      }
  
      return {
        'product': parsedProducts,
        'adverId': this.adverId,
        'totalPrice': totalPrice,
        'totalQty': totalQty.toString()
      };
    }


    protected getMobileConversionData(): JsonObject {
      
      const ordCode = new QueryStringer(window.location.search.substring(1)).getParam('ordernum');
      const productInfoLength = document.querySelectorAll('div.order-page1 table tbody tr td:nth-child(1) a');
      const parsedProduct: Array<{}> = [];
      let totalQty = 0;
  
      for (let i = 0; i < productInfoLength.length; i++) {
        const productAnchorElem = productInfoLength[i] as HTMLAnchorElement;
        const qty = document.querySelectorAll('div.order-page1 table tbody tr td:nth-child(2)')[i].textContent!.replace(/\D/g,'');
  
        parsedProduct.push({
          'productCode': new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
          'productName': this.getMobileConversionProdtName(productAnchorElem),
          'qty': qty,
          'price': NumberUtil.parseNumber((window[this.ENP_OBJECT_NAME]['product'] as Array<{}>)[i]['totalPrice']) / NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER),
        });
  
        totalQty += NumberUtil.parseNumber(qty, NumberUtil.NUM_TYPE.INTEGER);
      }
  
      const parsedData: {} = {
        'product': parsedProduct,
        'adverId': this.adverId,
        'ordCode': ordCode,
        'totalPrice': this.getMobileTotalPrice(),
        'totalQty': totalQty
      };
  
      return new JsonObject(parsedData);
    }
    
    protected getPayConvDataFromProductPage(): {} {
      
    if(this.isMobile(this.commandOptions)){
      return super.getPayConvDataFromProductPage();
      }else{
        const parsedProduct: Array<{}> = [];
        const targetObj: {} = this.getProductTargetObj();
     
        parsedProduct.push({
          'productCode': targetObj['no'],
          'productName': targetObj['name'],
          'price': NumberUtil.parseNumber(targetObj['price']),
          'qty': document.querySelector('tr td font input')!['value']
        });
    
        return {
          'product': parsedProduct,
          'adverId': this.adverId,
          'totalPrice': this.getTotalPrice(parsedProduct, document.querySelector('#price_text')),
          'totalQty': document.querySelector('tr td font input')!['value']
        };
    }
  }

  protected getWebConversionData(): JsonObject {
    const productInfoLength = document.querySelectorAll('table tr td.woong b a');
    const parsedProducts: Array<{}> = [];
    let totalQty = 0;

    for (let i = 1; i < productInfoLength.length+1; i++) {
      const productAnchorElem = productInfoLength[i-1] as HTMLAnchorElement;

      const qty =NumberUtil.parseNumber((document.querySelectorAll('table tbody tr:nth-child(1) td:nth-child(3)[align="center"]')[i] as HTMLDivElement).textContent!.replace(/\D/g, ''));
      const price = NumberUtil.parseNumber((document.querySelectorAll('table tbody tr:nth-child(1) td:nth-child(4)[align="right"] font')[i] as HTMLElement).textContent!.replace(/\D/g, ''));

      parsedProducts.push({
        'productCode':new QueryStringer(productAnchorElem.search.substring(1)).getParam('branduid'),
        'productName': productAnchorElem.textContent,
        'qty': qty,
        'price':price/qty
      });

      totalQty += qty;
    }

    const parsedData: {} = {
      'product': parsedProducts,
      'adverId': this.adverId,
      'ordCode': document.querySelector('tr td.woong font font b')!.textContent,
      'totalPrice': document.querySelector('#mk_totalprice')!.textContent!.replace(/\D/g,''),
      'totalQty': totalQty.toString()
    };

    return new JsonObject(parsedData);
  }
}

