/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2020-02-04
 */
import {ApiName, EventType} from "../../types/GlobalEnums";
import {ApiInfo} from "./ApiInfo";
import {InvalidValueError} from "../../error/InvalidValueError";

/**
 * create on 2020-02-04.
 * <p> API 호출을 위한 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export abstract class Api {
  protected readonly apiInfo: ApiInfo;
  protected readonly adverId: string;

  protected constructor(apiName: ApiName, adverId: string) {
    this.apiInfo = this.createApiInfo(apiName);
    this.adverId = adverId;
  }

  /**
   * API의 유효성을 확인후 호출한다.
   * 유효성이나 호출 과정에서 에러가 발생하면 아무런 동작을 하지 않음.
   */
  call(): void {
    try {
      if (this.validateApi()) {
        this.callApi();
      }
    } catch (e) {}
  }

  /**
   * API 정보 가져오기
   * @param {ApiName} apiName - 가져올 API의 이름
   * @param {EventType} eventType - 이벤트 종류
   * @return {ApiInfo}  - API 정보
   */
  protected createApiInfo(apiName: ApiName, eventType?: EventType): ApiInfo {
    switch (apiName) {
      case ApiName.I_BOT:
        return {
          apiName: apiName,
          apiUrl: 'https://admin.istore.camp/api/v2/mobon/productUpdate'
        };
      case ApiName.I_ORB:
        return {
          apiName: apiName,
          apiUrl: 'https://api.mediacategory.com/rtb/redirectUrl'
        };
      default:
        throw new InvalidValueError('apiName', apiName);
    }
  }

  /**
   * API의 유효성 확인
   * @return {boolean}
   * <p><code>true</code> - 유효함</p><p><code>false</code> - 무효함</p>
   */
  protected abstract validateApi(): boolean;

  /**
   * API 호출
   */
  protected abstract callApi(): void;
}
