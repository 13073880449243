/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:yjlee1@enliple.com">yjlee</a>
 * @since 2022-12-12
 */

import {GodomallRentDataParser} from "../GodomallRentDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2022-12-12
 * <p> drdiary - https://sdhbanchan.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author yjlee
 */
export class SdhfoodDataParser extends GodomallRentDataParser {
  constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getConversionData(): JsonObject {
    const jsonObj = super.getConversionData();
    if (this.isMobile(this.commandOptions)) {
      jsonObj.replaceData('totalPrice', document.querySelector('div.order_end_box dl:nth-child(3) dd')!.textContent!.split('\n')[5].trim().replace(/\D/g,''));
    }else{
      jsonObj.replaceData('totalPrice', document.querySelector('div.order_cont  dd strong.order_payment_sum')!.textContent!.replace(/\D/g,''));
    }
    return jsonObj;
  }
}