/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-07-30.
 * <p> Object 관련 유틸 클래스 </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class ObjectUtil {
  private constructor() {}

  /**
   * 입력한 객체에 대해 deep clone 된 객체를 리턴.
   * @param {{}} from
   * @return {{}}
   */
  static deepClone(from: {}): {} {
    const to = {};
    for (const propertyName in from) {
      if (from.hasOwnProperty(propertyName)) {
        to[propertyName] = from[propertyName];
      }
    }

    return to;
  }

  /**
   * 입력 받은 property 이름으로 대상 객체 안에 빈 객체를 생성
   * @param {{}} targetObj  - 대상 객체
   * @param {string} propertyName - property 이름
   */
  static createEmptyObjectAt(targetObj: {}, propertyName: string): void {
    try {
      if (typeof targetObj === 'object'
          && propertyName.length > 0
          && !targetObj.hasOwnProperty(propertyName)
      ) {
        targetObj[propertyName] = {};
      }
    } catch (e) {
    }
  }
}
