/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sjchun@enliple.com">sjchun</a>
 * @since 2021-10-05
 */

import {SelfHostedDataParser} from "../SelfHostedDataParser";
import {JsonObject} from "../../../lib/json/JsonObject";

/**
 * create on 2021-10-05
 * <p> adjwy - yanadoo.co.kr/ </p>
 * <p> {@link } and {@link }관련 클래스 </p>
 *
 * @version 1.0
 * @author sjchun
 */
export abstract class AdjwyDataParser extends SelfHostedDataParser {
  protected constructor(adverId: string, commandOptions: {}) {
    super(adverId, commandOptions);
  }

  protected getCommonTraceData(): JsonObject {
    const jsonData = super.getCommonTraceData();

    const oneFilterList = [
      'www.yanadoo.co.kr/promotion-landing/yafitPremium.html'
    ];

    const twoFilterList = [
      'www.yanadoo.co.kr/promotion-landing/yafitLite.html'
    ];

    const threeFilterList = [
      'www.yanadoo.co.kr/promotion-landing/yafitFree.html'
    ];

    if(oneFilterList.some((url)=>url === location.hostname+location.pathname)){
      jsonData.addData('domain', 'yanadoo.co.kr/freepass/yafitCycleOfficial');
    }

    if(twoFilterList.some((url)=>url === location.hostname+location.pathname)){
      jsonData.addData('domain', 'yanadoo.co.kr/freepass/yafitCycleLight');
    }

    if(threeFilterList.some((url)=>url === location.hostname+location.pathname)){
      jsonData.addData('domain', 'yanadoo.co.kr/freepass/yafitCycleFree');
    }

    return jsonData;
  }
}
