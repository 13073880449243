/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
/**
 * create on 2019-08-19.
 * <p> 지원하지 않는 기능의 호출을 시도할 때 발생시키는 에러 </p>
 * <p> {@link Error} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class NotSupportedError extends Error {
  constructor(
    notSupportedFeature = '',
    message = notSupportedFeature
      ? 'Does not support ' + notSupportedFeature + ' yet.'
      : 'Does not support yet.'
  ) {
    super(message);
    this.name = "NotSupportedError";
  }
}
