/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:sghwang@enliple.com">sghwang</a>
 * @since 2019. 4. 23
 */
import { Codec } from './Codec';

/**
 * create on 2019-07-29.
 * <p> UTF-8 인코딩/디코딩 모듈 </p>
 * <p> {@link Codec} 관련 클래스 </p>
 *
 * @version 1.0
 * @author sghwang
 */
export class Utf8 implements Codec {
  /**
   * 인코딩.
   * <pre>
   *   encode('http://www.example.com?param1=value1'); // 'http%3A%2F%2Fwww.example.com%3Fparam1%3Dvalue1'
   * </pre>
   * @param {target} target - 인코딩할 문자열
   * @return {target} 인코딩 결과
   */
  encode(target: string): string {
    return encodeURIComponent(target);
  }

  /**
   * 디코딩
   * <pre>
   *   decode('http%3A%2F%2Fwww.example.com%3Fparam1%3Dvalue1'); // 'http://www.example.com?param1=value1'
   * </pre>
   * @param {target} target - 디코딩할 문자열
   * @return {target} 디코딩 결과
   */
  decode(target: string): string {
    return decodeURIComponent(target);
  }
}
